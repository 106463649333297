import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
 
@Injectable()
export class RewardPointMessage {
    private subject = new Subject<any>();
 
    sendRewardPoints(message: number) {
        this.subject.next({ points: message });
    }

    clearRewardPoints() {
        this.subject.next();
    }

    getRewardPoints(): Observable<any> {
        return this.subject.asObservable();
    }
}