import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-third-party-delivery-or-takeaway-status-modal',
  templateUrl: './third-party-delivery-or-takeaway-status-modal.component.html',
  styleUrls: ['./third-party-delivery-or-takeaway-status-modal.component.less']
})

export class ThirdPartyDeliveryOrTakeawayStatusModalComponent implements OnInit {
  decision: string;
  status: boolean = false;
  reason: any = "";
  title: any = "";
  
  constructor(public modalRef: BsModalRef,public messageService:MessageService) { }
  
  ngOnInit() {} 
  
  done(){
    if(!this.reason) {
      this.messageService.add({ severity: 'error', summary: "Please enter reason for status change.", detail: '' });
      return;
    }
    this.decision='done';
    this.modalRef.hide()
  }

}
