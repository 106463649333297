import { APIHelper } from './api-helper';
import { Router } from '@angular/router';
import { Component, OnInit, Output, ViewChild, EventEmitter, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { StateGuardService } from '../state-guard.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CancelKotItemReasonModal } from '../kot-item-cancel-reason-modal/kot-item-cancel-reason-modal.component';
import { OTModalComponent } from '../otmodal/otmodal.component';
import { KotDetailModalComponent } from '../kot-detail-modal/kot-detail-modal.component';
import { remove, forEach } from 'lodash-es';
import 'assets/js/epos-print-3.0.0.js';
import * as dot from 'dot';
import { BaseConfig } from './api-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RequestOptions } from '@angular/http';

declare var epson: any;

@Component({
    selector: 'kot-helper',
    template: ``,
})

export class KotHelper {

    public subscriptions: Subscription[] = [];
    public modalRef: BsModalRef;
    selectedIndex;
    builder;
    popupWin;

    printerUtility = false;
    private unsubscribe() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }

    constructor(private router: Router, private messageService: MessageService,
        private modalService: BsModalService, private stateGuard: StateGuardService, private http: HttpClient) {
        if (localStorage.getItem('PRINT_UTILITY')) {
            this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
        }
    }

    /* put a list into KOT */
    putKOT(cartItems: any, new_kot_id: any, previousKot: any, kot_num: any) {

        var result = previousKot || [];
        let tempKotItems = [];
        previousKot.forEach(k => {
            k.items.forEach((kotItem) => {
                let isFound = false;
                tempKotItems.forEach(tempKotItem => {
                    //if(tempKotItem.item_code == kotItem.item_code && (!kotItem.is_variant  || (kotItem.is_variant && tempKotItem.add_time == kotItem.add_time))){
                    if (tempKotItem.item_code == kotItem.item_code && tempKotItem.add_time == kotItem.add_time) {
                        if (kotItem.is_billed) {
                            tempKotItem.item_count += kotItem.item_count;
                        } else {
                            tempKotItem.item_count -= Math.abs(kotItem.item_count);
                        }
                        isFound = true;
                    }
                });
                if (!isFound) {
                    tempKotItems.push(JSON.parse(JSON.stringify(kotItem)));
                }
            })
        })
        remove(tempKotItems, item => {
            return item.item_count == 0;
        })

        var kot = tempKotItems;

        // console.log("kot : " + JSON.stringify(kot))

        if (kot === null || kot.length == 0) {
            if (cartItems.length > 0) {
                cartItems.forEach(item => {
                    item.is_billed = true;
                });
                result.push({ kot_id: new_kot_id, items: cartItems, kot_number: kot_num });
            }
            //console.log('RESULT 111')
            return result;
        } else {
            // then diff the list
            var filterList = cartItems.filter(function (c: any) {
                return kot.filter(function (k: any) {
                    //return (k.item_code == c.item_code && (!k.is_variant  || (k.is_variant && k.add_time == c.add_time)) && k.item_count === c.item_count);                    
                    return (k.item_code == c.item_code && k.add_time == c.add_time && k.item_count === c.item_count);
                }).length === 0;
            });

            filterList.forEach(item => { item.is_billed = true; });

            var diffList = JSON.parse(JSON.stringify(filterList));

            // console.log("111 diffList: " + JSON.stringify(diffList))

            var removeList: any = [];
            for (var k of kot) {
                var isFound = -1;
                var kq = -1;
                var cq = -1;
                for (var c of cartItems) {
                    //if(k.item_code == c.item_code && (!k.is_variant  || (k.is_variant && k.add_time == c.add_time))) {
                    if (k.item_code == c.item_code && k.add_time == c.add_time) {
                        isFound = kot.indexOf(k);
                        kq = k.item_count;
                        cq = c.item_count;
                    }
                }
                if (isFound < 0) {
                    k.is_billed = false;
                    k.item_count = -k.item_count;
                    removeList.push(k);
                } else {
                    //item is present in kot & cart but quantity may be different
                    if (cq != kq) {
                        //quantity changed, 
                        var it = kot[isFound];
                        //console.log("prev item_count : " + it.item_count);                        
                        if ((kq - cq) < 0) {
                            k.is_billed = true;
                        } else {
                            it.item_count = ((kq - cq) * -1);
                            k.is_billed = false;
                            removeList.push(it);
                        }
                    }
                }
            }

            // console.log("removeList: " + JSON.stringify(removeList))

            // set correct item count in diffList
            diffList = diffList.map(function (d: any) {
                var kd = kot.filter(function (k: any) {
                    //return (k.item_code === d.item_code && (!k.is_variant  || (k.is_variant && k.add_time == d.add_time)));
                    return (k.item_code === d.item_code && k.add_time == d.add_time);
                });
                var cd = cartItems.filter(function (c: any) {
                    //return (d.item_code === c.item_code && (!d.is_variant  || (d.is_variant && d.add_time == c.add_time)));
                    return (d.item_code === c.item_code && d.add_time == c.add_time);
                });

                if (kd.length === 0 || cd.length === 0) return d;

                d.item_count = cd[0].item_count - kd[0].item_count;
                d.item_count = cd[0].item_count - kd[0].item_count;
                if (d.item_count > 0) {
                    d.is_billed = true;
                } else {
                    d.is_billed = false;
                }
                return d;
            });

            // console.log("222 diffList: " + JSON.stringify(diffList))

            //check if removed items is added to diff list

            for (var r of removeList) {
                remove((diffList), d => {
                    //return  (d.item_code === r.item_code && (!d.is_variant  || (d.is_variant && d.add_time == r.add_time)));
                    return (d.item_code === r.item_code && d.add_time == r.add_time);
                });
            }

            // console.log("333 diffList: " + JSON.stringify(diffList))

            //combine both lists           
            diffList.push(...removeList);
            if (diffList.length > 0) {
                result.push({ kot_id: new_kot_id, items: diffList, kot_number: kot_num });
            }
            //console.log('RESULT 222')
            return result;
        } // end if
    }

    /* function to check if a new item has attributes and if yes then update attribute count of base item's attributes' count */
    /* updateModifierCount(baseItem,newItem){
        baseItem.attributes = Array.isArray(baseItem.attributes) && baseItem.attributes.length > 0 ? baseItem.attributes : []; 
        if(newItem.attributes.length > 0){
            newItem.attributes.forEach(attr => {
                if(!attr.is_variant_attribute){
                    let isFound = false;
                    baseItem.attributes.forEach(battr => {
                        if(!battr.is_variant_attribute){
                            if(attr.attribute_code == battr.attribute_code){
                                if(attr.is_billed){
                                    battr.count += attr.count; 
                                }else{
                                    battr.count -= Math.abs(attr.count);
                                }
                                isFound = true;
                            }
                        }
                    })
                    if(!isFound){
                        baseItem.attributes.push(attr)
                    }
                }
            })
        }
        return baseItem;
    }


    areModifierAttrsSame(kotItem, cartItem){
        var kItemModAttrs = Array.isArray(kotItem.attributes) ?  kotItem.attributes.filter(att => !att.is_variant_attribute) : [];
        var cItemModAttrs = Array.isArray(cartItem.attributes) ?  cartItem.attributes.filter(att => !att.is_variant_attribute) : [];
        var modCnt = 0;
        if(kItemModAttrs.length == cItemModAttrs.length){
            cItemModAttrs.forEach(cattr => {
                if(kItemModAttrs.filter(kattr => kattr.attribute_code == cattr.attribute_code && kattr.is_billed == cattr.is_billed && kattr.count == cattr.count).length == 1){
                    modCnt++;
                }
            })
            if(modCnt == cItemModAttrs.length){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }

    // put a list into KOT NEW
     putKOT(cartItems: any,new_kot_id:any,previousKot: any,kot_num:any) {
    
        var result = previousKot || [];
        let tempKotItems = []; 
        previousKot.forEach( k =>{
            k.items.forEach((kotItem) =>{
                let isFound = false;
                tempKotItems.forEach(tempKotItem => {                
                //if(tempKotItem.item_code == kotItem.item_code && (!kotItem.is_variant  || (kotItem.is_variant && tempKotItem.add_time == kotItem.add_time))){
                if(tempKotItem.item_code == kotItem.item_code && tempKotItem.add_time == kotItem.add_time){                    
                    if(kotItem.is_billed) {
                    tempKotItem.item_count += kotItem.item_count;
                    } else {
                    tempKotItem.item_count -= Math.abs(kotItem.item_count);
                    }
                    isFound = true;
                    tempKotItem = this.updateModifierCount(tempKotItem,kotItem);
                }
                });              
                if(!isFound) {                  
                    tempKotItems.push(JSON.parse(JSON.stringify(kotItem)));
                }
            })
        })
        remove(tempKotItems,item =>{
            return item.item_count == 0;
        })
        
        //remove modifier attributes having count 0
        tempKotItems.forEach(tempKotItem => {
            remove(tempKotItem.attributes,attr =>{
                return attr.count == 0;
            })
        })

        var kot = tempKotItems;  
        
        console.log("kot : " + JSON.stringify(kot))

        if (kot === null || kot.length == 0) {
            if(cartItems.length > 0) {                    
                cartItems.forEach(item => {
                    item.is_billed = true;
                });
                result.push({kot_id:new_kot_id,items:cartItems,kot_number:kot_num});
            }
            console.log('RESULT 111')
            return result;
        } else {
            var scope = this;
            // then diff the list
            var filterList = cartItems.filter(function (c: any) {                                 
                return kot.filter(function (k: any) { 
                    //return (k.item_code == c.item_code && (!k.is_variant  || (k.is_variant && k.add_time == c.add_time)) && k.item_count === c.item_count);                    
                    return (k.item_code == c.item_code && k.add_time == c.add_time && k.item_count === c.item_count && scope.areModifierAttrsSame(k,c));                    
                }).length === 0; 
            });

            filterList.forEach(item => {item.is_billed = true;});

            var diffList = JSON.parse(JSON.stringify(filterList));

            console.log("111 diffList: " + JSON.stringify(diffList))

            var removeList : any = [];
            for(var k of kot) {
                var isFound = -1;
                var kq = -1;
                var cq = -1;
                for(var c of cartItems) {                    
                    //if(k.item_code == c.item_code && (!k.is_variant  || (k.is_variant && k.add_time == c.add_time))) {
                    if(k.item_code == c.item_code && k.add_time == c.add_time) {
                        isFound = kot.indexOf(k);
                        kq = k.item_count;
                        cq = c.item_count; 
                    }
                }
                if(isFound < 0){
                    k.is_billed = false;
                    k.item_count = -k.item_count;
                    removeList.push(k);
                }else{
                    //item is present in kot & cart but quantity may be different
                    if(cq != kq){
                        //quantity changed, 
                        var it = kot[isFound];
                        //console.log("prev item_count : " + it.item_count);                        
                        if((kq - cq)<0) {                              
                            k.is_billed = true;
                        }else {     
                            it.item_count =  ((kq - cq)*-1);                                                   
                            k.is_billed = false;                                                        
                            removeList.push(it);
                        }
                    }
                }
            }

            console.log("removeList: " + JSON.stringify(removeList))

            // set correct item count in diffList
            diffList = diffList.map(function (d: any) {
                var kd = kot.filter(function (k: any) {                     
                    //return (k.item_code === d.item_code && (!k.is_variant  || (k.is_variant && k.add_time == d.add_time)));
                    return (k.item_code === d.item_code && k.add_time == d.add_time);
                });
                var cd = cartItems.filter(function (c: any) {                     
                    //return (d.item_code === c.item_code && (!d.is_variant  || (d.is_variant && d.add_time == c.add_time)));
                    return (d.item_code === c.item_code && d.add_time == c.add_time);
                });

                if (kd.length === 0 || cd.length === 0) return d;

                d.item_count = cd[0].item_count - kd[0].item_count;
                d.item_count = cd[0].item_count - kd[0].item_count;
                if(d.item_count > 0) {
                    d.is_billed = true;   
                } else {
                    d.is_billed = false;   
                }          
                return d;
            });

            console.log("222 diffList: " + JSON.stringify(diffList))

            //check if removed items is added to diff list

            for(var r of removeList) {
                remove((diffList), d => {                    
                    //return  (d.item_code === r.item_code && (!d.is_variant  || (d.is_variant && d.add_time == r.add_time)));
                    return  (d.item_code === r.item_code && d.add_time == r.add_time);
                });
            }

            console.log("333 diffList: " + JSON.stringify(diffList))

            //combine both lists           
            diffList.push(...removeList);
            if(diffList.length > 0) {                
                result.push({kot_id:new_kot_id,items:diffList,kot_number:kot_num});
            }
            console.log('RESULT 222')
            return result;
        } // end if
    } */

    onClickOT(isCheckForValidation, vindex) {

        return new Promise<any>((resolve, reject) => {
            let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
            let is_item_found_in_seat = false;
            let is_item_added = false;
            let is_added_item_default = false;
            let is_new_kot_created = false;
            if (isCheckForValidation) {
                for (var i = 0; i < tempUnit.visitors.length; i++) {
                    if (tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
                        is_item_added = true;
                    }
                    if (i != 0 && tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
                        is_item_found_in_seat = true;
                    }
                }
                if (!is_item_added) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No items in cart' });
                    return;
                }
                if (tempUnit.visitors.length > 1) {
                    if (tempUnit.visitors[0].dishes && tempUnit.visitors[0].dishes[0].length > 0 && is_item_found_in_seat) {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please assign default cart items to respective seat' });
                        return;
                    }
                }
            }

            this.getSeatNumber(is_item_found_in_seat).then((selectedIndex) => {

                if (!isCheckForValidation && vindex) {
                    selectedIndex = vindex;
                }
                this.selectedIndex = selectedIndex;

                if (!selectedIndex) {
                    return;
                }
                tempUnit.visitors.forEach((visitor, index) => {

                    index = parseInt(index);
                    let val = selectedIndex.indexOf(index);
                    let isAll = selectedIndex.indexOf('all');

                    if ((val != -1) || (isAll != -1)) {

                        visitor.kot = visitor.kot ? visitor.kot : [];
                        visitor.tempKot = [];
                        let kot = visitor.kot;
                        let id = JSON.parse(localStorage.getItem('device_key'));
                        let terminal_id = (id && id.terminal_id) ? id.terminal_id : 0;
                        let len = kot.length + 1;

                        if (!localStorage.getItem('kot_number')) {
                            localStorage.setItem('kot_number', '1');
                        }

                        let kot_number = (typeof localStorage.getItem('kot_number') == 'string') ? parseInt(localStorage.getItem('kot_number')) : localStorage.getItem('kot_number');
                        let tempKot_num = localStorage.getItem('kot_number');
                        let kotNo = parseInt(tempKot_num);
                        let newKotNo = kotNo + 1;
                        let newKotId = 'kot_' + terminal_id + '_' + index + '_' + len + "_" + (new Date()).getTime();
                        let items = [];
                        let reason = '';
                        let isReasonAvailable = false;
                        if (visitor.dishes && visitor.dishes[0]) {
                            var tempItems = JSON.parse(JSON.stringify(visitor.dishes[0]));
                            tempItems.forEach(item => {
                                if (item.selected_variant_item) {
                                    item.selected_variant_item.is_variant = true;
                                    items.push(item.selected_variant_item);
                                } else {
                                    items.push(item);
                                }
                            });
                        }

                        // console.log('Cart items: ' + JSON.stringify(items))
                        // console.log('previous_kot:' + JSON.stringify(kot))

                        var newKot: any = this.putKOT(items, newKotId, JSON.parse(JSON.stringify(kot)), kotNo);

                        // console.log('NEW_KOT: ' + JSON.stringify(newKot))

                        if (newKot.length != kot.length) {
                            is_new_kot_created = true;
                            remove((newKot[newKot.length - 1].items), it => !it.is_billed);
                            if (!visitor.deleted_items) {
                                visitor.deleted_items = [];
                            }
                            let fully_deleted_item = visitor.deleted_items.filter(it => it.is_fully_deleted == true);
                            if (fully_deleted_item && fully_deleted_item[0]) {
                                fully_deleted_item.forEach(data => {
                                    remove((newKot[newKot.length - 1].items), it => {
                                        it.item_code == data.item_code;
                                    });
                                })
                            }
                            newKot[newKot.length - 1].items = newKot[newKot.length - 1].items.concat(visitor.deleted_items);
                            if (newKot[newKot.length - 1].items && newKot[newKot.length - 1].items.length > 0) {
                                visitor.tempKot = newKot;
                            }
                            visitor.deleted_items = [];
                            localStorage.setItem('kot_number', JSON.stringify(newKotNo));
                        } else {
                            visitor.kot = newKot;
                            visitor.deleted_items = [];
                        }
                    }
                });
                tempUnit.isFromOnHold = true;
                var tempAllNewKot = [];
                tempUnit.visitors.forEach((visitor, index) => {
                    if (visitor.tempKot && visitor.tempKot.length > 0) {
                        tempAllNewKot.push({ vindex: index, kot: JSON.parse(JSON.stringify(visitor.tempKot)) });
                    }
                });
                this.getKotNotes(tempAllNewKot).then(latestAllSeatKot => {
                    tempUnit.visitors.forEach((visitor, index) => {
                        index = parseInt(index);
                        let val = selectedIndex.indexOf(index);
                        let isAll = selectedIndex.indexOf('all');
                        if ((val != -1) || (isAll != -1)) {
                            if (visitor.tempKot && visitor.tempKot.length > 0) {
                                var latestKot = latestAllSeatKot.filter(kot => { return kot.vindex == index; });
                                if (latestKot.length > 0 && latestKot[0].kot) {
                                    visitor.kot = JSON.parse(JSON.stringify(latestKot[0].kot));
                                } else {
                                    visitor.kot = JSON.parse(JSON.stringify(visitor.tempKot));
                                }
                            }
                            delete visitor.tempKot;
                        }
                    });
                    resolve({ is_new_kot_created: is_new_kot_created })
                });
            });
        });
    }

    getKotNotes(allSeatKot) {
        return new Promise<any>((resolve, reject) => {
            var local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
            if (allSeatKot.length > 0 && local_config && local_config.enable_kot_note) {
                var cSub: Subscription = this.modalService.onHide.subscribe(() => {
                    cSub.unsubscribe();
                    resolve(this.modalRef.content.allSeatKot);
                });
                this.modalRef = this.modalService.show(KotDetailModalComponent, { class: 'centred-modal ot-modal' });
                this.modalRef.content.allSeatKot = allSeatKot;
                this.modalRef.content.decision = "";
            } else {
                resolve(allSeatKot);
            }
        })
    }

    createOTforShopWithoutTables(isCheckForValidation, vindex) {

        return new Promise<any>((resolve, reject) => {
            let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
            let is_item_found_in_seat = false;
            let is_item_added = false;
            let is_added_item_default = false;
            let is_new_kot_created = false;
            // if(isCheckForValidation){
            for (var i = 0; i < tempUnit.visitors.length; i++) {
                if (tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
                    is_item_added = true;
                }
                if (i != 0 && tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
                    is_item_found_in_seat = true;
                }
            }
            if (!is_item_added) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No items in cart' });
                return;
            }
            if (tempUnit.visitors.length > 1) {
                if (tempUnit.visitors[0].dishes && tempUnit.visitors[0].dishes[0].length > 0 && is_item_found_in_seat) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please assign default cart items to respective seat' });
                    return;
                }
            }
            // }

            // this.getSeatNumber(is_item_found_in_seat).then( (selectedIndex) => {

            //     if(!isCheckForValidation && vindex){
            //         selectedIndex = vindex;
            //     }
            //     this.selectedIndex = selectedIndex;

            //     if(!selectedIndex){
            //         return;
            //     }
            tempUnit.visitors.forEach((visitor, index) => {

                index = parseInt(index);
                // let val = selectedIndex.indexOf(index);
                // let isAll = selectedIndex.indexOf('all');

                // if((val != -1) || (isAll != -1) ){

                visitor.kot = visitor.kot ? visitor.kot : [];
                visitor.tempKot = [];
                let kot = visitor.kot;
                let id = JSON.parse(localStorage.getItem('device_key'));
                let terminal_id = (id && id.terminal_id) ? id.terminal_id : 0;
                let len = kot.length + 1;

                if (!localStorage.getItem('kot_number')) {
                    localStorage.setItem('kot_number', '1');
                }

                let kot_number = (typeof localStorage.getItem('kot_number') == 'string') ? parseInt(localStorage.getItem('kot_number')) : localStorage.getItem('kot_number');
                let tempKot_num = localStorage.getItem('kot_number');
                let kotNo = parseInt(tempKot_num);
                let newKotNo = kotNo + 1;
                let newKotId = 'kot_' + terminal_id + '_' + index + '_' + len + "_" + (new Date()).getTime();
                let items = [];
                let reason = '';
                let isReasonAvailable = false;
                if (visitor.dishes && visitor.dishes[0]) {
                    var tempItems = JSON.parse(JSON.stringify(visitor.dishes[0]));
                    tempItems.forEach(item => {
                        if (item.selected_variant_item) {
                            item.selected_variant_item.is_variant = true;
                            items.push(item.selected_variant_item);
                        } else {
                            items.push(item);
                        }
                    });
                }


                var newKot: any = this.putKOT(items, newKotId, JSON.parse(JSON.stringify(kot)), kotNo);

                if (newKot.length != kot.length) {
                    is_new_kot_created = true;
                    remove((newKot[newKot.length - 1].items), it => !it.is_billed);
                    if (!visitor.deleted_items) {
                        visitor.deleted_items = [];
                    }
                    let fully_deleted_item = visitor.deleted_items.filter(it => it.is_fully_deleted == true);
                    if (fully_deleted_item && fully_deleted_item[0]) {
                        fully_deleted_item.forEach(data => {
                            remove((newKot[newKot.length - 1].items), it => {
                                it.item_code == data.item_code;
                            });
                        })
                    }
                    newKot[newKot.length - 1].items = newKot[newKot.length - 1].items.concat(visitor.deleted_items);
                    if (newKot[newKot.length - 1].items && newKot[newKot.length - 1].items.length > 0) {
                        visitor.tempKot = newKot;
                    }
                    visitor.deleted_items = [];
                    localStorage.setItem('kot_number', JSON.stringify(newKotNo));
                } else {
                    visitor.kot = newKot;
                    visitor.deleted_items = [];
                }
                // }       
            });
            tempUnit.isFromOnHold = true;
            tempUnit.visitors.forEach((visitor, index) => {
                index = parseInt(index);
                // let val = selectedIndex.indexOf(index);
                // let isAll = selectedIndex.indexOf('all');
                // if((val != -1) || (isAll != -1) ){
                if (visitor.tempKot && visitor.tempKot.length > 0) {
                    visitor.kot = JSON.parse(JSON.stringify(visitor.tempKot));
                }
                delete visitor.tempKot;
                // }
            });
            // resolve(true);
            var len = tempUnit.visitors[0].kot.length;
            resolve({ newKot: tempUnit.visitors[0].kot[len - 1], is_new_kot_created: is_new_kot_created })
            // });
        });
    }

    captainKot(tempUnit, kot_desciption) {

        return new Promise<boolean>((resolve, reject) => {

            tempUnit.visitors.forEach((visitor, index) => {
                let reasonList = visitor.deleted_items;

                visitor.kot = visitor.kot ? visitor.kot : [];
                visitor.tempKot = [];
                let kot = visitor.kot;
                let id = JSON.parse(localStorage.getItem('device_key'));
                let terminal_id = (id && id.terminal_id) ? id.terminal_id : 0;
                let len = kot.length + 1;

                if (!localStorage.getItem('kot_number')) {
                    localStorage.setItem('kot_number', '1');
                }

                let kot_number = (typeof localStorage.getItem('kot_number') == 'string') ? parseInt(localStorage.getItem('kot_number')) : localStorage.getItem('kot_number');
                let tempKot_num = localStorage.getItem('kot_number');
                let kotNo = parseInt(tempKot_num);
                let newKotNo = kotNo + 1;
                let newKotId = 'kot_' + terminal_id + '_' + index + '_' + len + "_" + (new Date()).getTime();
                let items = [];
                let reason = '';
                let isReasonAvailable = false;
                if (visitor.dishes && visitor.dishes[0]) {
                    var tempItems = JSON.parse(JSON.stringify(visitor.dishes[0]));
                    tempItems.forEach(item => {
                        if (item.selected_variant_item) {
                            item.selected_variant_item.is_variant = true;
                            items.push(item.selected_variant_item);
                        } else {
                            items.push(item);
                        }
                    });
                }
                var newKot: any = this.putKOT(items, newKotId, JSON.parse(JSON.stringify(kot)), kotNo);

                if (newKot && newKot.length > 0) {
                    newKot[newKot.length - 1].kot_description = (kot_desciption) ? kot_desciption : "";
                }

                if (newKot.length != kot.length) {
                    forEach(newKot[newKot.length - 1].items, function (item) {

                        if (!item.is_billed) {
                            let is_reason_found = false;

                            if (Array.isArray(reasonList) && reasonList[0]) {
                                for (var i = 0; i < reasonList.length; i++) {
                                    if ((reasonList[i].item_code == item.item_code) && (reasonList[i].item_count == Math.abs(item.item_count)) && !reasonList[i].is_used) {
                                        item.delete_reason = reasonList[i].delete_reason;
                                        reasonList[i].is_used = 1;
                                        is_reason_found = true;
                                        break;
                                    }
                                }
                            }
                            if (!is_reason_found) {
                                item.delete_reason = APIHelper.KOT_OTHER;
                            }
                            return;
                        }
                    });
                    visitor.tempKot = newKot;
                    localStorage.setItem('kot_number', JSON.stringify(newKotNo));

                } else {
                    visitor.kot = newKot;
                }
            });
            tempUnit.visitors.forEach((visitor, index) => {
                if (visitor.tempKot && visitor.tempKot.length > 0) {
                    visitor.kot = JSON.parse(JSON.stringify(visitor.tempKot));
                }
                delete visitor.tempKot;
            });
            resolve(true);
        });
    }

    getSeatNumber(is_item_found_in_seat) {
        return new Promise<any>((resolve, reject) => {
            if (is_item_found_in_seat) {
                this.subscriptions.push(this.modalService.onHide.subscribe(() => {
                    if (this.modalRef.content.decision === 'send') {
                        resolve(this.modalRef.content.selectedIndexList);
                    }
                    if (this.modalRef.content.decision === 'cancel') {
                        resolve(null);
                    }
                    this.unsubscribe();
                }));
                this.modalRef = this.modalService.show(OTModalComponent, { class: 'centred-modal' });
                this.modalRef.content.unit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
                this.modalRef.content.decision = "";
            } else {
                resolve(['all']);
            }
        });
    }

    /*getReasonForRemoveKotItem(selectedReason){

        return new Promise<string>((resolve,reject)=>{

           if(selectedReason){
               resolve(selectedReason);
           }else{
                this.modalRef = this.modalService.show(CancelKotItemReasonModal, {class: 'common-modal'});
                this.modalRef.content.decision = "";
                this.modalRef.content.reasonList = [
                    {
                      'text':'Damaged',
                      'id': APIHelper.KOT_DAMAGED
                    },
                    {
                      'text':'Customer Cancelled',
                      'id': APIHelper.KOT_CUSTOMER_CANCELLED
                    },
                    {
                      'text':'Delivery Delayed',
                      'id': APIHelper.KOT_DELIVERY_DELAYED
                    },
                    {
                      'text':'Other',
                      'id': APIHelper.KOT_OTHER
                    }
                ]
                this.subscriptions.push(this.modalService.onHide.subscribe(() => {
                    let data = '';
                    if (this.modalRef.content.decision === 'send') {
                        data = this.modalRef.content.selectedReason;
                    }
                    this.unsubscribe();
                    resolve(this.modalRef.content.selectedReason);
                    return;
                }));
            }
        })
    }*/

    // using logic to check if there are kitchen departments or no
    // IF no. of depts >= 1 then print KOT department-wise (KOT for items without a dept goes to default printer)
    // ELSE if no. of depts = 0 then print all items in one KOT
    prepareToPrintKOT() {
        try {
            //console.log("prepareToPrintKOT");
            let tempUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
            //console.log("currentUnit"+JSON.stringify(tempUnit)); 
            //  console.log("this.selectedIndex:"+this.selectedIndex);
            let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));

            let printerData = JSON.parse(localStorage.getItem('shop_printers'));
            if (printerData) {
                var printers_list = printerData;
                var allInOnePrinter;
                var kotPrintersCount = 0;
                printers_list.forEach(printer => {
                    if (printer.print_type.indexOf('kitchen') != -1 || printer.print_type.indexOf('self_service') != -1) {
                        kotPrintersCount++;
                        allInOnePrinter = printer;
                    }
                })

                if (kotPrintersCount > 0) {
                    this.selectedIndex.forEach(indx => {
                        if (indx == 'all') {
                            indx = 0;
                        }

                        var kotLen = tempUnit.visitors[indx].kot.length;
                        if (kotLen > 0) {
                            if (shop_config.shop_info.kitchen_departments.length > 0) { //kotPrintersCount > 1
                                //print KOT department wise
                                var departmentWiseItems = this.groupItemsDepartmentWise(tempUnit.visitors[indx].kot[kotLen - 1].items);
                                for (var dept in departmentWiseItems) {
                                    var printer = this.findRequiredPrinter(printers_list, dept);
                                    if (printer == "Printer not found") {
                                        this.messageService.add({ severity: 'success', summary: 'Printer not found', detail: 'Neither Wifi nor USB Printer not found for department ' + dept });
                                    } else {
                                        
                                        if (this.printerUtility) {
                                            this.printKotOnUtility(tempUnit.visitors[indx].kot[kotLen - 1], departmentWiseItems[dept], printer, tempUnit.covers, false);
                                        } else {
                                            if (printer.ip_address && printer.ip_address != "" && printer.ip_address != null) {
                                                //printer is a Wifi printer
                                                this.printKOT(tempUnit.visitors[indx].kot[kotLen - 1], departmentWiseItems[dept], printer);
                                            } else {
                                                //printer is a USB printer
                                                this.printKOTonUSBPrinter(tempUnit.visitors[indx].kot[kotLen - 1], departmentWiseItems[dept], false,printer);
                                            }
                                        }
                                    }
                                }
                            } else if (shop_config.shop_info.kitchen_departments.length == 0) { //kotPrintersCount == 1
                                if (allInOnePrinter.ip_address && allInOnePrinter.ip_address != "" && allInOnePrinter.ip_address != null) {
                                    //printer is a Wifi printer
                                    this.printKOT(tempUnit.visitors[indx].kot[kotLen - 1], tempUnit.visitors[indx].kot[kotLen - 1].items, allInOnePrinter);
                                } else {
                                    //printer is a USB printer
                                    this.printKOTonUSBPrinter(tempUnit.visitors[indx].kot[kotLen - 1], tempUnit.visitors[indx].kot[kotLen - 1].items, false,printer);
                                }
                            }
                        }
                    })
                } else {
                    //neither Wifi not usb printer found
                    this.messageService.add({ severity: 'success', summary: 'Wifi nor USB Printer found', detail: 'Neither Wifi nor USB printer found for KOT print' });
                }
            } else {
                this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
            }
            // });
        } catch (e) {
            console.log(e)
        }
    }

    groupItemsDepartmentWise(items) {
        var departmentWiseItems: any = {};
        items.forEach(item => {
            if (item.kitchen_department != "" && item.kitchen_department != null) {
                if (item.kitchen_department in departmentWiseItems) {
                    departmentWiseItems[item.kitchen_department].push(item);
                } else {
                    departmentWiseItems[item.kitchen_department] = [];
                    departmentWiseItems[item.kitchen_department].push(item);
                }
            } else {
                if ('default' in departmentWiseItems) {
                    departmentWiseItems['default'].push(item);
                } else {
                    departmentWiseItems['default'] = [];
                    departmentWiseItems['default'].push(item);
                }
            }
        })
        return departmentWiseItems;
    }

    findRequiredPrinter(printers_list, dept) {
        var printer_found = false;
        var search_default_printer = false;
        if (dept == 'default') {
            search_default_printer = true;
        }

        //search for department printer
        if (!search_default_printer) {
            for (var i = 0; i < printers_list.length; i++) {
                if (printers_list[i].kitchen_department.trim() == dept.trim() && (printers_list[i].print_type.indexOf('kitchen') != -1 || printers_list[i].print_type.indexOf('self_service') != -1)) {
                    printer_found = true;
                    return printers_list[i];
                }
            }
            search_default_printer = true;
        }

        //search for default printer if dept is 'default' or a printer for a dept could not be found
        if (search_default_printer) {
            for (var i = 0; i < printers_list.length; i++) {
                // a printer is a default printer if it has no kitchen_department
                if ((printers_list[i].kitchen_department.trim() == "" || printers_list[i].kitchen_department == null) && (printers_list[i].print_type.indexOf('kitchen') != -1 || printers_list[i].print_type.indexOf('self_service') != -1)) {
                    printer_found = true;
                    return printers_list[i];
                }
            }
        }

        if (!printer_found) {
            return "Printer not found"
        }
    }

    printKOT(fullkot, dept_items, dept_printer) {
        let printConfigs = JSON.parse(localStorage.getItem('print_configs'));
				let printConfigAvailable = false;
				let selectedPrintConfig;
				if(printConfigs && printConfigs instanceof Array){
                    if(dept_printer){
                        let printC = printConfigs.filter(item=> item.printer_id === dept_printer.id);
                        if(printC && printC instanceof Array && printC.length > 0){
                            printConfigAvailable = true;
                            selectedPrintConfig = printC[0].print_config
                        }
                    }
				}
        // console.log("items:"+JSON.stringify(dept_items));
        // console.log("printer:"+JSON.stringify(dept_printer));
        let tempUnit = this.stateGuard.obtain('selects-box.component:currentUnit');

        this.builder = new epson.ePOSBuilder();
        // this.canvas = document.getElementById('printQuick');
        var shopName = localStorage.getItem('shop_name');
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        if(selectedPrintConfig && selectedPrintConfig.outlet_name)
        this.builder.addText('\n' + shopName + '\n');
        //   this.builder.addText('KOT \n');
        this.builder.addText('KOT No: ' + fullkot.kot_number + '\n');
        var today = new Date();
        var date = new Date().toJSON().slice(0, 10);
        var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        this.builder.addText('Kot Date: ' + date + ' ' + time + '\n');
        // this.builder.addText('Kot Date : ' + this.kotDate + '\n');

        let partner;
        if (tempUnit && tempUnit.selected_partner_list && tempUnit.selected_partner_list[0] && tempUnit.selected_partner_list[0].name) {
            partner = tempUnit.selected_partner_list[0].name;
        }

        if (tempUnit && tempUnit.location) {
            if(selectedPrintConfig && selectedPrintConfig.section_garden_section)
            this.builder.addText('Section: ' + this.capitalizeFirstLetter(tempUnit.location) + ((partner) ? (" " + partner) : "") + '\n');
        }

        if (tempUnit && tempUnit.tables && tempUnit.tables.length > 0) {
            if(selectedPrintConfig && selectedPrintConfig?.table_number)
            this.builder.addText('Table No: ' + tempUnit.tables[0] + '\n');
        }

        if (fullkot.kot_id && fullkot.kot_id.split("_")[2] != 0) {
            this.builder.addText('Seat No: ' + fullkot.kot_id.split("_")[2] + '\n');
        }

        this.builder.addText('---------------------------------------------\n');

        // Add each line item to receipt (function below)
        var cartItems = dept_items;

        cartItems = this.groupSameItems(cartItems);

        for (var i = 0; i < cartItems.length; i++) {
            var attributes: any = [];
            if (cartItems[i].attributes.length > 0) {
                attributes = cartItems[i].attributes;
            }
            var combo_item_codes = [];
            if (cartItems[i].combo_item_codes && cartItems[i].combo_item_codes.length > 0) {
                combo_item_codes = cartItems[i].combo_item_codes;
            }
            var included_combo_items = [];
            if (cartItems[i].included_combo_items) {
                if (cartItems[i].included_combo_items.length > 0) {
                    included_combo_items = cartItems[i].included_combo_items;
                }
            }

            var name = this.capitalizeFirstLetter(cartItems[i].item_name);
            this.createKotItemLine(name, cartItems[i], attributes, combo_item_codes, included_combo_items);
        }
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        this.builder.addText('---------------------------------------------\n');

        if (fullkot.is_recent_order_kot) {
            this.builder.addTextAlign(this.builder.ALIGN_CENTER);
            if (fullkot.shipping_type_name) {
                this.builder.addText('Order Type: ' + fullkot.shipping_type_name + '\n');
            }
            if (fullkot.split_payment_details) {
                fullkot.split_payment_details.forEach(payment => {
                    this.builder.addText(payment.name + ': ' + fullkot.currencySymbol + payment.amount + '\n');
                })
            }

            if (fullkot.partner_ids) {
                fullkot.partner_ids.forEach(partner => {
                    this.builder.addText('Online Partner: ' + partner.name + '\n');
                })
            }
        }

        // Add 2 line feeds and cut
        this.builder.addFeed();
        this.builder.addFeed();
        this.builder.addCut(this.builder.CUT_FEED);
        var ipAddr = '192.168.1.199';
        if (dept_printer) {
            ipAddr = dept_printer.ip_address;
        } else {
            ipAddr = '192.168.1.201';
        }
        var address = 'http://' + ipAddr + '/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000';
        var epos = new epson.ePOSPrint(address);
        // Check if printer cover is open
        epos.oncoveropen = function () { alert('coveropen'); };
        // Send print job
        //console.log("KOT builder obj==>"+this.builder.toString());
        epos.send(this.builder.toString());
    }

    printKotOnUtility(fullkot, dept_items, dept_printer, covers, reprint:boolean) {
        var today = new Date();
        var date = new Date().toJSON().slice(0, 10);
        var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let tempUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
        let partner;
        if (tempUnit && tempUnit.selected_partner_list && tempUnit.selected_partner_list[0] && tempUnit.selected_partner_list[0].name) {
            partner = tempUnit.selected_partner_list[0].name;
        }
        var cartItems = dept_items;

        cartItems = this.groupSameItems(cartItems);
        const data: PrintKotUtility = {
            printerName: dept_printer.name,
            paperSize:dept_printer.paper_size,
            kotJson: {
                rePrint: reprint,
                shopName: localStorage.getItem('shop_name'),
                kotNumber: fullkot.kot_number,
                date: date,
                time: time,
                partner: partner ? partner : '',
                location: tempUnit.location,
                tableNo: tempUnit.tables.join(', '),
                seatNo: covers.toString(),
                cartItems: cartItems,
                isRecent: fullkot.is_recent_order_kot,
                orderType: fullkot.shipping_type_name,
                paymentDetails: fullkot.split_payment_details,
                partnerId: fullkot.partner_ids
            }
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        this.http.post(BaseConfig.config.PRINT_API + BaseConfig.config.PRINT_KOT, JSON.stringify(data), httpOptions).subscribe(data => {
            console.log(data);
        });

    }

    // create Items for kot
    createKotItemLine(productName: any, item: any, attributes: any, combo_item_codes: any, included_combo_items: any) {
        this.builder.addPageBegin();
        let itemNameAndCountString = item.item_count + " x " + productName;
        let dHeight = Math.ceil(itemNameAndCountString.length / 35) * 30; //we suppose 35 character will come in one line
        this.builder.addPageArea(60, 0, 436, dHeight);
        this.builder.addText(itemNameAndCountString);
        this.builder.addPageArea(436, 0, 100, dHeight);
        this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
        this.builder.addPageEnd();

        if (attributes && attributes.length > 0) {
            this.builder.addTextAlign(this.builder.ALIGN_LEFT);
            this.builder.addTextFont(this.builder.FONT_C);
            // this.builder.addText(notes + "\n");
            for (var attr = 0; attr < attributes.length; attr++) {
                this.builder.addPageBegin();
                // this.builder.addPageArea(62, 0, 412, 30);
                var attrName = this.capitalizeFirstLetter(attributes[attr].name);
                let attrNameAndCountString = attributes[attr].count + " x " + attrName;
                let adHeight = Math.ceil(attrNameAndCountString.length / 35) * 30;
                this.builder.addPageArea(106, 0, 456, adHeight);

                this.builder.addText(attrNameAndCountString);
                // this.builder.addPageArea(415, 0, 100, 30);
                // this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
                this.builder.addPageEnd();
            }
            this.builder.addTextFont(this.builder.FONT_A);
        }
        if (combo_item_codes.length > 0) {
            this.builder.addTextAlign(this.builder.ALIGN_LEFT);
            this.builder.addTextFont(this.builder.FONT_C);
            // this.builder.addText(notes + "\n");
            for (var attr = 0; attr < combo_item_codes.length; attr++) {
                this.builder.addPageBegin();
                // this.builder.addPageArea(62, 0, 412, 30);
                var attrName = this.capitalizeFirstLetter(combo_item_codes[attr].item_name);
                let attrNameAndCountString = combo_item_codes[attr].quantity_in_combo + " x " + attrName;
                let adHeight = Math.ceil(attrNameAndCountString.length / 35) * 30;
                this.builder.addPageArea(106, 0, 456, adHeight);

                this.builder.addText(attrNameAndCountString);
                // this.builder.addPageArea(415, 0, 100, 30);
                // this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
                this.builder.addPageEnd();
            }
            this.builder.addTextFont(this.builder.FONT_A);
        }
        if (included_combo_items.length > 0) {
            this.builder.addTextAlign(this.builder.ALIGN_LEFT);
            this.builder.addTextFont(this.builder.FONT_C);
            // this.builder.addText(notes + "\n");
            for (var attr = 0; attr < included_combo_items.length; attr++) {
                this.builder.addPageBegin();
                // this.builder.addPageArea(62, 0, 412, 30);
                var attrName = this.capitalizeFirstLetter(included_combo_items[attr].item_name);
                let attrNameAndCountString = included_combo_items[attr].quantity + " x " + attrName;
                let adHeight = Math.ceil(attrNameAndCountString.length / 35) * 30;
                this.builder.addPageArea(106, 0, 456, adHeight);

                this.builder.addText(attrNameAndCountString);
                // this.builder.addPageArea(415, 0, 100, 30);
                // this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
                this.builder.addPageEnd();
            }
            this.builder.addTextFont(this.builder.FONT_A);
        }
        if (item.note) {
            this.builder.addTextAlign(this.builder.ALIGN_LEFT);
            this.builder.addPageBegin();
            let noteString = "Note: " + item.note;
            let ndHeight = Math.ceil(noteString.length / 35) * 30;
            this.builder.addPageArea(106, 0, 456, ndHeight);
            this.builder.addText(noteString);
            // this.builder.addPageArea(408, 0, 172, 30);
            // this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
            this.builder.addPageEnd();
            this.builder.addTextFont(this.builder.FONT_A);
        }
    }

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    printKOTonUSBPrinter(fullkot, dept_items, isReprint, printer?) {
        let tempUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
        var kotInfo = {};
        //console.log("dd",tempUnit)
        if (localStorage.getItem('shop_name')) {
            kotInfo['shopName'] = localStorage.getItem('shop_name');
        }

        kotInfo['kotNumber'] = fullkot.kot_number;

        if (fullkot.is_recent_order_kot) {
            kotInfo['is_recent_order_kot'] = true
            kotInfo['shipping_type_name'] = fullkot.shipping_type_name
            kotInfo['split_payment_details'] = fullkot.split_payment_details
            kotInfo['partner_ids'] = fullkot.partner_ids
            kotInfo['currencySymbol'] = fullkot.currencySymbol
        }

        kotInfo['isReprint'] = isReprint;

        var today = new Date();
        var date = new Date().toJSON().slice(0, 10);
        var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        // this.builder.addText('Kot Date: ' + date + ' ' + time + '\n');
        kotInfo['dateTime'] = date + ' ' + time;

        if (tempUnit && tempUnit.location) {
            kotInfo['section'] = this.capitalizeFirstLetter(tempUnit.location);
        }

        if (tempUnit && tempUnit.selected_partner_list && tempUnit.selected_partner_list[0] && tempUnit.selected_partner_list[0].name) {
            kotInfo['partner'] = tempUnit.selected_partner_list[0].name;
        }

        if (tempUnit && tempUnit.tables && tempUnit.tables.length > 0) {
            kotInfo['tableNo'] = tempUnit.tables[0];
        }
        if (fullkot.kot_id && fullkot.kot_id.split("_")[2] != 0) {
            kotInfo['seatNo'] = fullkot.kot_id.split("_")[2];
        }

        kotInfo['cart_items'] = dept_items;

        //console.log("KOT cart_items: " + JSON.stringify(kotInfo['cart_items']))

        kotInfo['cart_items'] = this.groupSameItems(kotInfo['cart_items']);

        for (let index = 0; index < kotInfo['cart_items'].length; index++) {
            const element = kotInfo['cart_items'][index];
            if (!element.included_combo_items) {
                element.included_combo_items = [];
            }

        }

        let printConfigs = JSON.parse(localStorage.getItem('print_configs'));
				let printConfigAvailable = false;
				let selectedPrintConfig;
				if(printConfigs && printConfigs instanceof Array){
                    if(printer){
                        let printC = printConfigs.filter(item=> item.printer_id === printer.id);
                        if(printC && printC instanceof Array && printC.length > 0){
                            printConfigAvailable = true;
                            selectedPrintConfig = printC[0].print_config
                        }
                    }
				}

				kotInfo['print_config'] = selectedPrintConfig;

                var str1 = null
		if(printConfigAvailable){
             str1 = `
        <table style="text-align: center; width: 100%; font-family: sans-serif;">
            <tbody>
                <tr>
                  <td>
                    
                    <table style="text-align: left; width: 100%; max-width: 700px; border: 0px solid #ccc; margin: auto;">
                      <tbody>
                        <tr>
                          <td max-width: 700px;>
                            
                          <!-- Shop details begin -->
                            <table style="text-align: center; width: 100%">
                              <tbody>
                                <tr>
                                  <td>{{=it.shopName}}</td>
                                </tr>
                                <tr>
                                  {{? it.isReprint}}
                                    <td>KOT Reprint No: {{=it.kotNumber}}</td>
                                    {{?? !it.isReprint}}
                                         <td>KOT No: {{=it.kotNumber}}</td>
                                    {{??}}
                                  {{?}}
                                </tr>
                                <tr>
                                  <td>KOT Date: {{=it.dateTime}}</td>
                                </tr>
                                {{? it.print_config.section_garden_section}}  
                                    {{? it.section}}    
                                        <tr>
                                        <td>
                                            Section: {{=it.section}}  {{? it.partner}} {{=it.partner}} {{?}}
                                        </td>
                                        </tr> 
                                    {{?}}  
                                {{?}}
                                {{? it.print_config.table_number}}
                                    {{? it.tableNo}}
                                        <tr>
                                        <td>Table No: {{=it.tableNo}}</td>
                                        </tr>  
                                    {{?}} 
                                {{?}}
                                {{? it.seatNo}}
                                    <tr>
                                    <td>Seat No: {{=it.seatNo}}</td>
                                    </tr> 
                                {{?}}        
                                
                              </tbody>
                            </table>
                            <!-- Shop details end -->
                            <!-- Header Container -->

                            <!-- Receipt Container -->
          
                            <table style="width: 100%;">
                              <tbody>
                                <tr>
                                  <td colspan="3" height="5"></td><!-- margin -->
                                </tr>
                                <tr>
                                  <td style="border-bottom: 1px dashed #000;"></td>
                                </tr>
                                <tr>
                                  <td colspan="3" height="5"></td><!-- margin -->
                                </tr>
                              </tbody>
                            </table><!-- dotted hr-line -->



                            <!-- Cart items list begin -->
                            {{~it.cart_items :value:index}}
                                <table style="width: 100%; text-align: left;">
                                    <tr>
                                        <td width="30" style="text-align: center;">{{=value.item_count}}</td>
                                        <td colspan = "2"> x {{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}</td>
                                    </tr>
                                    {{? value.combo_item_codes && value.combo_item_codes.length > 0}}
                                    {{~ value.combo_item_codes :attr:indx}}
                                            <tr>
                                                <td></td>
                                                <td colspan="2" style="font-size: 12px;">{{=attr.quantity_in_combo}} x {{=attr.item_name.charAt(0).toUpperCase()+attr.item_name.substr(1)}} </td>
                                            </tr>
                                    {{~}} 
                                {{?}}
                                {{? value.included_combo_items && value.included_combo_items.length > 0}}
                                {{~ value.included_combo_items :attr:indx}}
                                        <tr>
                                            <td></td>
                                            <td colspan="2" style="font-size: 12px;">{{=attr.quantity}} x {{=attr.item_name.charAt(0).toUpperCase()+attr.item_name.substr(1)}} </td>
                                        </tr>
                                {{~}} 
                            {{?}}
                                    {{? value.attributes && value.attributes.length > 0}}
                                        {{~ value.attributes :attr:indx}}
                                                <tr>
                                                    <td></td>
                                                    <td colspan="2" style="font-size: 12px;">{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
                                                </tr>
                                        {{~}} 
                                    {{?}}

                                    {{? value.note }}
                                        <tr>
                                            <td></td>
                                            <td colspan="2" style="font-size: 12px;">Note: {{=value.note}}</td>
                                        </tr>
                                    {{?}}
                                </table>
                                <!-- info table -->
                            {{~}} 
                            <!-- Cart items list end -->


                            <table style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td colspan="3" height="5"></td>
                                        <!-- margin -->
                                    </tr>
                                    <tr>
                                        <td style="border-bottom: 1px dashed #000;"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" height="5"></td>
                                        <!-- margin -->
                                    </tr>
                                </tbody>
                            </table>
                            <!-- dotted hr-line -->

                            <!-- Delivery Type,Payment Details and Partner name for recent order KOT Starts -->
                            {{? it.is_recent_order_kot}}
                                <table style="width: 100%; text-align: left;">
                                    {{? it.shipping_type_name}}
                                        <tr>
                                            <td>Order Type: {{=it.shipping_type_name}}</td>
                                        </tr>
                                    {{?}}
                             
                                    {{? it.split_payment_details}}
                                        {{~ it.split_payment_details : val: i}}
                                            <tr>
                                                <td>{{=val.name}}: {{=it.currencySymbol}}{{=val.amount}}</td>
                                            </tr> 
                                        {{~}}
                                    {{?}}

                                    {{? it.partner_ids}}
                                        {{~ it.partner_ids : p_id}}
                                            <tr>
                                                 <td>Online Partner: {{=p_id.name}}</td>
                                            </tr> 
                                        {{~}}
                                    {{?}}

                                </table>
                                <!-- info table -->
                            {{?}} 
                            <!-- Delivery Type,Payment Details and Partner name for recent order KOT Ends -->

                  </td>
                </tr>
            </tbody>
        </table><!-- General Wrapper -->
        `;
        }else{
             str1 = `
        <table style="text-align: center; width: 100%; font-family: sans-serif;">
            <tbody>
                <tr>
                  <td>
                    
                    <table style="text-align: left; width: 100%; max-width: 700px; border: 0px solid #ccc; margin: auto;">
                      <tbody>
                        <tr>
                          <td max-width: 700px;>
                            
                          <!-- Shop details begin -->
                            <table style="text-align: center; width: 100%">
                              <tbody>
                                <tr>
                                  <td>{{=it.shopName}}</td>
                                </tr>
                                <tr>
                                  {{? it.isReprint}}
                                    <td>KOT Reprint No: {{=it.kotNumber}}</td>
                                    {{?? !it.isReprint}}
                                         <td>KOT No: {{=it.kotNumber}}</td>
                                    {{??}}
                                  {{?}}
                                </tr>
                                <tr>
                                  <td>KOT Date: {{=it.dateTime}}</td>
                                </tr>  
                                {{? it.section}}    
                                    <tr>
                                    <td>
                                        Section: {{=it.section}}  {{? it.partner}} {{=it.partner}} {{?}}
                                    </td>
                                    </tr> 
                                {{?}}  
                                {{? it.tableNo}}
                                    <tr>
                                    <td>Table No: {{=it.tableNo}}</td>
                                    </tr>  
                                {{?}} 
                                {{? it.seatNo}}
                                    <tr>
                                    <td>Seat No: {{=it.seatNo}}</td>
                                    </tr> 
                                {{?}}        
                                
                              </tbody>
                            </table>
                            <!-- Shop details end -->
                            <!-- Header Container -->

                            <!-- Receipt Container -->
          
                            <table style="width: 100%;">
                              <tbody>
                                <tr>
                                  <td colspan="3" height="5"></td><!-- margin -->
                                </tr>
                                <tr>
                                  <td style="border-bottom: 1px dashed #000;"></td>
                                </tr>
                                <tr>
                                  <td colspan="3" height="5"></td><!-- margin -->
                                </tr>
                              </tbody>
                            </table><!-- dotted hr-line -->



                            <!-- Cart items list begin -->
                            {{~it.cart_items :value:index}}
                                <table style="width: 100%; text-align: left;">
                                    <tr>
                                        <td width="30" style="text-align: center;">{{=value.item_count}}</td>
                                        <td colspan = "2"> x {{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}</td>
                                    </tr>
                                    {{? value.combo_item_codes && value.combo_item_codes.length > 0}}
                                    {{~ value.combo_item_codes :attr:indx}}
                                            <tr>
                                                <td></td>
                                                <td colspan="2" style="font-size: 12px;">{{=attr.quantity_in_combo}} x {{=attr.item_name.charAt(0).toUpperCase()+attr.item_name.substr(1)}} </td>
                                            </tr>
                                    {{~}} 
                                {{?}}
                                {{? value.included_combo_items && value.included_combo_items.length > 0}}
                                {{~ value.included_combo_items :attr:indx}}
                                        <tr>
                                            <td></td>
                                            <td colspan="2" style="font-size: 12px;">{{=attr.quantity}} x {{=attr.item_name.charAt(0).toUpperCase()+attr.item_name.substr(1)}} </td>
                                        </tr>
                                {{~}} 
                            {{?}}
                                    {{? value.attributes && value.attributes.length > 0}}
                                        {{~ value.attributes :attr:indx}}
                                                <tr>
                                                    <td></td>
                                                    <td colspan="2" style="font-size: 12px;">{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
                                                </tr>
                                        {{~}} 
                                    {{?}}

                                    {{? value.note }}
                                        <tr>
                                            <td></td>
                                            <td colspan="2" style="font-size: 12px;">Note: {{=value.note}}</td>
                                        </tr>
                                    {{?}}
                                </table>
                                <!-- info table -->
                            {{~}} 
                            <!-- Cart items list end -->


                            <table style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td colspan="3" height="5"></td>
                                        <!-- margin -->
                                    </tr>
                                    <tr>
                                        <td style="border-bottom: 1px dashed #000;"></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" height="5"></td>
                                        <!-- margin -->
                                    </tr>
                                </tbody>
                            </table>
                            <!-- dotted hr-line -->

                            <!-- Delivery Type,Payment Details and Partner name for recent order KOT Starts -->
                            {{? it.is_recent_order_kot}}
                                <table style="width: 100%; text-align: left;">
                                    {{? it.shipping_type_name}}
                                        <tr>
                                            <td>Order Type: {{=it.shipping_type_name}}</td>
                                        </tr>
                                    {{?}}
                             
                                    {{? it.split_payment_details}}
                                        {{~ it.split_payment_details : val: i}}
                                            <tr>
                                                <td>{{=val.name}}: {{=it.currencySymbol}}{{=val.amount}}</td>
                                            </tr> 
                                        {{~}}
                                    {{?}}

                                    {{? it.partner_ids}}
                                        {{~ it.partner_ids : p_id}}
                                            <tr>
                                                 <td>Online Partner: {{=p_id.name}}</td>
                                            </tr> 
                                        {{~}}
                                    {{?}}

                                </table>
                                <!-- info table -->
                            {{?}} 
                            <!-- Delivery Type,Payment Details and Partner name for recent order KOT Ends -->

                  </td>
                </tr>
            </tbody>
        </table><!-- General Wrapper -->
        `;
        }
        
        // console.log(str1);


        try {
            var tempFn = dot.template(str1);
            //console.log('kotInfo:'+JSON.stringify(kotInfo));
            var printContents = tempFn(kotInfo);
        } catch (e) {
            console.log(e);
        }

        try {

            this.popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
            this.popupWin.document.open();

            this.popupWin.document.write(`
                  <html>
                      <head>
                          <title>Print tab</title>
                          <style>
                    .marginValues{
                      margin-top:0px !important;
                    }
                  .item{
                    float:left !important;
                  }
                   .text{
                                text-transform: capitalize !important
                            }
                    .kot-modal-bg {
                        background-color: lightgray;
                    }
                  .print{
                    font-family:monospace;
                    text-align:center !important;
                  }
                  .kot-print {
                    font-size:20px;
                    font-family:monospace;
                  }
                  .align-right{
                    tax-align:right !important;
                  }
                          </style>
                            <script type="text/javascript">
                              function onload() {
                                setTimeout(function(){window.print(); window.close();}, 200); 
                                /*window.print();
                                setTimeout(function(){window.close();}, 1000);*/
                              }
                            </script>
                      </head>
                      <body onload='onload()'>${printContents}  
                      </body>
                  </html>`
            );
            // this.printPaymentMode();
            this.popupWin.document.close();

        } catch (e) {
            alert("please enable popup to show print")
        }
    }

    // function to club all items having same item code and modifiers (consider items having notes as separate items)
    groupSameItems(cart_items) {
        //console.log('in groupSameItems');
        var cartItems = [];
        var itemGroups = {};

        cart_items.forEach(item => {
            if (item.item_code in itemGroups) {
                var itemFound = false;
                var item_mod_attrs = [];
                item_mod_attrs = item.attributes.filter(attr => !attr.is_variant_attribute)
                // itemGroups[item.item_code].forEach(sameItem => {
                //console.log('item.note: ' + item.note + " " + !item.note)
                if (!item.note) {
                    for (var i in itemGroups[item.item_code]) {
                        var sameItem_mod_attrs = [];
                        sameItem_mod_attrs = itemGroups[item.item_code][i].attributes.filter(attr => !attr.is_variant_attribute)
                        // if(this.areDiscountsSame(item, itemGroups[item.item_code][i])){
                        if (this.areItemsWithoutNote(itemGroups[item.item_code][i], item)) {
                            if (this.areModifiersSame(item_mod_attrs, sameItem_mod_attrs)) {
                                // console.log("item.attributes: "+ JSON.stringify(item_mod_attrs))
                                // console.log("itemGroups[i].attributes: "+ JSON.stringify(sameItem_mod_attrs))
                                itemGroups[item.item_code][i].item_count += JSON.parse(JSON.stringify(item.item_count));

                                itemGroups[item.item_code][i].attributes.forEach(attr => {
                                    if (!attr.is_variant_attribute) {
                                        attr.count += item.attributes.filter(at => attr.attribute_code == at.attribute_code)[0] ? item.attributes.filter(at => attr.attribute_code == at.attribute_code)[0].count : 0;
                                    }
                                })

                                itemFound = true;
                                break;
                            }
                        }
                        // })
                    }
                }
                if (!itemFound) {
                    itemGroups[item.item_code].push(JSON.parse(JSON.stringify(item)));
                }
            } else {
                itemGroups[item.item_code] = [];
                itemGroups[item.item_code].push(JSON.parse(JSON.stringify(item)));
            }
        })

        for (var item_code in itemGroups) {
            cartItems = cartItems.concat(itemGroups[item_code])
        }
        //console.log("same items cart:" + JSON.stringify(cartItems))
        return cartItems;
    }


    //function to check if 2 items have notes or no
    areItemsWithoutNote(item1, item2) {
        if (item1.note || item2.note) {
            return false;
        } else {
            return true;
        }
    }


    //function to check if 2 modifier arrays are identical
    areModifiersSame(mod_array_1, mod_array_2) {
        if (mod_array_1.length != mod_array_2.length) {
            return false
        } else {
            var commonMods = []
            mod_array_1.forEach(mod1 => {
                mod_array_2.forEach(mod2 => {
                    //console.log(mod1.attribute_code + "==" + mod2.attribute_code + " && " + mod1.count + "==" + mod2.count + ' => '+ ((mod1.attribute_code == mod2.attribute_code) && (mod1.count == mod2.count)));
                    if ((mod1.attribute_code == mod2.attribute_code)) { // && (mod1.count == mod2.count)
                        commonMods.push(mod1);
                    }
                })
            })
            if (commonMods.length == mod_array_1.length) {
                //console.log("commonMods : "+ JSON.stringify(commonMods))
                //console.log("mod_array_1 : "+ JSON.stringify(mod_array_1))
                return true
            } else {
                return false
            }
        }
    }


}

export interface PrintKotUtility {
    printerName: string;
    paperSize: number;
    kotJson: KotJson;
}



export interface KotJson {
    shopName: string;
    rePrint: boolean;
    kotNumber: number;
    date: string;
    time: string;
    partner: string;
    location: string;
    tableNo: number;
    seatNo: number;
    cartItems: CartItems;
    isRecent: any;
    orderType: string;
    paymentDetails: any;
    partnerId: any;
}

export interface CartItems {
    item_name: string;
    item_count: number;
}