import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// import { RegisterComponent } from './register/register.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { PagesComponent } from './pages/pages.component';

// const routes: Routes = [
//   {
//     path: '',
//     redirectTo: '/login',
//     pathMatch: 'full'
//   },
//   {
//     path: 'login', component: LoginModalComponent
//   },
//   // {
//   //   path: 'pages',
//   //   redirectTo: '/pages',
//   //   pathMatch: 'full'
//   // },
//   {
//     path: 'pages',
//     loadChildren: 'app/pages/pages.module#PagesModule'
//   }
// ];

export const routes: Routes = [
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'verify-pin' }
];

// @NgModule({
//   imports: [
//     CommonModule,
//     RouterModule.forRoot(routes)
//   ],
//   declarations: [],
//   exports: [RouterModule]
// })
export const routing = RouterModule.forRoot(routes, { useHash: true });