import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LoginFormComponent } from './login-form/login-form.component';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { PagesComponent } from './pages/pages.component';
import { PagesModule } from './pages/pages.module';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpModule } from '@angular/http';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { APIHelper } from './pages/helpers/api-helper';

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    LoginModalComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    MessagesModule,
    MessageModule,
    ToastModule,
    AgmCoreModule.forRoot({
      apiKey: APIHelper.GKEY,
      libraries: ["places"]
    }),
    AgmDirectionModule,
    routing,
    PagesModule,
    
  ],
  providers: [BsModalRef,MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
