import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { orders_info } from './orders-info';
import { ActivatedRoute, Router } from '@angular/router';
import { RecentOrdersService } from '../helpers/services/recent-orders.service';
import { APIHelper } from '../helpers/api-helper';
import { BaseConfig } from '../helpers/api-config';
import { MessageService } from 'primeng/api';
import { sumBy } from 'lodash-es';

@Component({
  selector: 'app-recent-orders',
  templateUrl: './recent-orders.component.html',
  styleUrls: ['./recent-orders.component.less']
})
export class RecentOrdersComponent implements OnInit {

	body = document.getElementsByTagName("body")[0];

	//filter values
	search: string = '';
	sorting: string = '';
	beginDate: Date = new Date();
	endDate: Date = new Date();
  pageNo:number = 1;
  public loading = false;    
  limit:number = 10;
  selectedOrderType:string='ALL';
  isPrevFlag:boolean = false;
  isRestaurantFlag:boolean = true;
  loadingForDetail:boolean = false;
  isForNonCompleteOrders:boolean = false;
  deep_link_order_data:any;
  orders_list_flag:boolean = false;
  orders_list_detail_flag:boolean = false;
  isNextFlag:boolean = false;
  currencySymbol:any;
  alterStylesForNext:any = {};
  alterStylesForPrev:any = {
        'cursor': 'not-allowed',
        'opacity': '0.65'
      };
  orders_list:any=[];
	temp_orders_list:any=[];
  selectedOrderDetails: any;
  shop_start_time:any;
  shop_close_time:any;  
  search_by_list: any = ["ANY","BILL_NUMBER","DELIVERY_DATE"];
  search_by:any = "";
  edit_items: boolean;
  constructor(private route: ActivatedRoute, private router: Router, private cdRef:ChangeDetectorRef,
            private messageService:MessageService,private recentOrdersService:RecentOrdersService
            ) { 
  }

  getAllOrdersInfo(data){

    this.loading = true;
    if (navigator.onLine) {
        this.loading = true;
        this.orders_list = [];
        this.recentOrdersService.getAllOrdersInfo(data).subscribe((res) => {
          let ordersData = res.json();
          if(ordersData.result){            
            let temp_limit =  ordersData.result.length;//in non completed order we need to have only conditional orders

            if(this.isForNonCompleteOrders){

              this.orders_list = ordersData.result.filter(order => {
                order.is_order_sync = true;
                let delivery_date = new Date(order.delivery_date);
                if( /*(this.shop_start_time<delivery_date && delivery_date<this.shop_close_time) && */
                  (order.shipping_type == APIHelper.HOME_DELIVERY || order.shipping_type == APIHelper.PICK_UP) && 
                  (order.order_status == APIHelper.ORDER_APPROVED  || order.order_status == APIHelper.ORDER_DELIVERED || order.order_status == APIHelper.ORDER_NEW) && 
                  (order.order_id.indexOf('CAN') == -1)) {
                  return true;
                } else {
                  return false;
                }                
              });

              var self = this;
              if(this.orders_list.length == 0){
                this.loading = false;
                this.messageService.add({ severity: 'info', summary: 'Information', detail: (temp_limit>0) ? 'No pending order found on this page.visit next page for more orders.':'No orders found' });
              }else{
                this.orders_list.forEach(order => {                  
                  order.order_status_obj = this.getOrderStatus(order.order_status);
                })
                self.getSelectedOrderInfo(this.orders_list[0]);
                self.loading = false;
              }              
            }else{
              this.orders_list = ordersData.result;
              this.orders_list.forEach(order => {
                order.is_order_sync = true;
                order.order_status_obj = this.getOrderStatus(order.order_status);
              })
              if(this.orders_list.length>0){
                this.loading = false;
                this.getSelectedOrderInfo(this.orders_list[0]);
                //this.addOfflineOrders(false);
              }else{
                this.orders_list = [];
                this.loading = false;
                this.messageService.add({ severity: 'info', summary: 'Information', detail: 'No orders found' });
                //this.addOfflineOrders(true);
              }
            }
            if(temp_limit >= this.limit){
              this.alterStylesForNext={};
              this.isNextFlag = true;
            }else{
              this.isNextFlag = false;
              this.alterStylesForNext={
                'cursor': 'not-allowed',
                'opacity': '0.65'
              }
            }
          }else{
            this.loading = false;
          }
        },error => {
          this.loading = false;
          this.messageService.add({ severity: 'error', summary: 'Internal server error', detail: '' });
        });

    }else{
        this.addOfflineOrders(true);
    }
  }

  cancelOrderComplete(){
    var self = this;
    setTimeout(()=>{
      self.orders_list = [];
      self.initialiseData();
    },2000);
  }

  getShopOpenTiming(){
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    let date = new Date()
    let dd   = date.getDate()+'';
    let mm   = (date.getMonth()+1)+'';
    let yyyy = date.getFullYear();
    if(dd.length == 1)  { dd='0'+dd } 
    if(mm.length == 1)  { mm='0'+mm }
    let close_time = shop_config.shop_close_time;
    let start_time = shop_config.shop_open_time;

    let setCloseDate = yyyy+'/'+mm+'/'+dd+' '+close_time;
    let setStartDate = yyyy+'/'+mm+'/'+dd+' '+start_time;

    let shop_start_time = new Date(setStartDate);
    let shop_close_time = new Date(setCloseDate);
    let next_day_count = shop_config.next_days_count;
    shop_close_time.setDate(shop_close_time.getDate() + next_day_count);
    this.shop_start_time = shop_start_time;
    this.shop_close_time = shop_close_time;
  }

  initialiseData(){

    this.body.setAttribute("class", "scrolling-view");
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration')) || {};
    this.currencySymbol = shop_config.currency;
    this.getShopOpenTiming();

    if(localStorage.getItem('isRestaurant') == 'false'){
      this.isRestaurantFlag = false
    }

    if(this.deep_link_order_data && this.deep_link_order_data.order_id) {
      this.getSelectedOrderInfo({order_id:this.deep_link_order_data.order_id});
    } else if(this.isForNonCompleteOrders){
      this.orders_list = [];
      this.pageNo = 1;

      let sData = {
        'shop_code':localStorage.getItem('shop_code'),
        'page':this.pageNo,
        'limit':this.limit
      }
      sData['shipping_type_filter'] =  ''+APIHelper.HOME_DELIVERY+","+ APIHelper.PICK_UP;
      this.getAllOrdersInfo(sData);
    }else{      
      if(localStorage.getItem('temp_orders_list')){
        let todays_order_list = JSON.parse(localStorage.getItem('temp_orders_list'));
        this.orders_list = todays_order_list;            
      }

      if(this.orders_list.length>0){
        this.tempAddOfflineOrders(false);
      }else{
        this.tempAddOfflineOrders(true);
      }
    }
  }

  fetchNewRecords(num){
    this.pageNo = this.pageNo + num;
    if(this.pageNo > 1){
      this.isPrevFlag = true;
      this.alterStylesForPrev={}
    }else{
      this.isPrevFlag = false;
      this.alterStylesForPrev={
        'cursor': 'not-allowed',
        'opacity': '0.65'
      }
    }

    if(this.isForNonCompleteOrders){
      let sendData = {
        'shop_code':localStorage.getItem('shop_code'),
        'page':this.pageNo,
        'limit':this.limit
      }
      sendData['shipping_type_filter'] =  ''+APIHelper.HOME_DELIVERY+","+ APIHelper.PICK_UP;
      this.getAllOrdersInfo(sendData);
    }else{
      let sendData = this.getParamForFilter();
      this.getAllOrdersInfo(sendData);
    }
  }

  ngOnInit() {
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    if(!shop_config){
      this.router.navigate(['verify-pin']);
      return;
    }

    if(this.route.snapshot.params.isForNonCompleteOrders == 'true'){
      this.isForNonCompleteOrders = true;
    }else{
      this.isForNonCompleteOrders = false;
    }
    
    if(!this.route.snapshot.params.order_id){
      this.initialiseData();
    } else {
      this.checkForDeepLinkOrder();
    }
  }

  checkForDeepLinkOrder() {
    this.route.paramMap.subscribe(
      params => {        
        if(this.route.snapshot.params.order_id) {
          this.deep_link_order_data = {
            order_id: this.route.snapshot.params.order_id
          }  
          this.initialiseData();                 
        }
      }
    );
  }

  ngAfterViewChecked() {    
    // this is added for solve the issue of Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked     
    this.cdRef.detectChanges();
  }



  tempAddOfflineOrders(flag){

    let list = [];
    let offlineOrderList = JSON.parse(localStorage.getItem('bill_queue'));
    if(offlineOrderList && offlineOrderList.length>0){

        offlineOrderList.forEach(data=>{
          this.orders_list.unshift(data);
        });  
    }

    if(this.orders_list.length>0){
      if(flag){
          this.loading = false;
          //this.getSelectedOrderInfo(this.orders_list[0]);
      }
      this.loading = false;
      this.tempGetSelectedOrderInfo(this.orders_list[0]);
    }else{
      this.loading = false;
    }


    this.temp_orders_list = this.orders_list;

  }


  tempGetSelectedOrderInfo(orderInfo){
    let id = orderInfo.order_id;    
    this.selectedOrderDetails = orderInfo;
    this.orders_list_flag = true;
    this.orders_list_detail_flag = true;
    this.loading = false;
    this.loadingForDetail = false;
  }



  refreshPage(){
    this.isForNonCompleteOrders = false;
    this.orders_list = [];
    this.orders_list_flag = false;
    this.orders_list_detail_flag = false;
    this.selectedOrderDetails=null;
    this.pageNo = 1;
    let sendData = this.getParamForFilter();
    this.getAllOrdersInfo(sendData);
  }

  addOfflineOrders(flag){

    let list = [];
    let offlineOrderList = JSON.parse(localStorage.getItem('bill_queue'));
    let tempofflineOrderList = offlineOrderList;
    if(offlineOrderList && offlineOrderList.length>0){

      if(this.search && this.search!=""){
          list = offlineOrderList.filter((data) =>{
            if ((data.order_id && data.order_id.toLowerCase() == this.search.toLowerCase()) ||
              (data.user_name && data.user_name.toLowerCase() == this.search.toLowerCase())) {
              return true;
            }else{
              return false;
            }

          });
      }else{
        list = tempofflineOrderList;
      }

      let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
      let open_time = (shop_config.shop_open_time)? shop_config.shop_open_time : "00:00";
      let close_time = (shop_config.shop_close_time)? shop_config.shop_close_time : "23:59";
      let beginDate = new Date(this.beginDate);
      let endDate = new Date(this.endDate);
      let tempOpenList = (open_time.split(':').length==2)? open_time.split(':') : ["00","00"];
      let tempCloseList = (close_time.split(':').length==2)? close_time.split(':') : ["23","59"];

      tempOpenList.forEach(data=>{
        if(data.length == 1){
          data = "0"+data;
        }
      });

      tempCloseList.forEach(data=>{
        if(data.length == 1){
          data = "0"+data;
        }
      });

     /* beginDate.setHours(parseInt(tempOpenList[0]));
      beginDate.setMinutes(parseInt(tempOpenList[1]));
      endDate.setHours(parseInt(tempCloseList[0]));
      endDate.setMinutes(parseInt(tempCloseList[1]));*/
      beginDate.setHours(0);
      beginDate.setMinutes(0);
      endDate.setHours(23);
      endDate.setMinutes(59);

      let finalList:any = [];
      let startTime = beginDate.getTime();
      let endTime = endDate.getTime();

      if(list && list.length>0){
        finalList = list.filter(data=>{
          let time = (new Date(data.delivery_date)).getTime();

          if(time >= startTime && time <= endTime){
            return true;
          }else{
            return false;
          }
        });
      }
      if(finalList && finalList.length>0){
        finalList.forEach(data=>{
          this.orders_list.unshift(data);
        });  
      }
    }

    if(this.orders_list.length>0){
      this.loading = false;
      this.getSelectedOrderInfo(this.orders_list[0]);
    }else{
      this.loading = false;
    }
  }

  getTypeCode(name){
    return APIHelper[name];
  }

  getOrderStatus(code) {
    let order_status =  {
      name: "NEW",
      color:"black"
    }

    if(code == this.getTypeCode('ORDER_COMPLETED')) {
      order_status["name"] = "COMPLETED";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_NEW')) {
      order_status["name"] = "NEW";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_APPROVED')) {
      order_status["name"] = "APPROVED";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_DELIVERED')) {
      order_status["name"] = "DELIVERED";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_REJECTED')) {
      order_status["name"] = "REJECTED";
      order_status["color"] = "#ff4d00";
    } else if(code == this.getTypeCode('ORDER_EDITED')) {
      order_status["name"] = "EDITED";
      order_status["color"] = "#2c93ff";
    } else if(code == this.getTypeCode('ORDER_DRAFT')) {
      order_status["name"] = "DRAFT";
      order_status["color"] = "#2c93ff";
    } else if(code == this.getTypeCode('ORDER_READY')) {
      order_status["name"] = "READY TO PICKUP";
      order_status["color"] = "#2c93ff";
    } else if(code == this.getTypeCode('ORDER_PICKEDUP')) {
      order_status["name"] = "PICKED UP";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_ASSIGNED_TO_RIDER')) {
      order_status["name"] = "HAND OVER TO RIDER";
      order_status["color"] = "#63b12d";
    }
   
    return order_status;
  }

  setOrderInfoInOrderList(order) {
    let orderInfo = {
      "order_id":order.order.order_id,
      "user_name":order.user[0].name,
      "user_phone":order.user[0].phone,
      "valued_at":order.order.valued_at,
      "order_status":order.order.order_status,
      "total_item_count":1,
      "shipping_type":order.order.shipping_type,
      "delivery_date":order.order.delivery_date,
      "createdAt":order.order.createdAt,
      "payment_type_summary":[],
      "client_reference_code":order.order.client_reference_code
    }

    this.orders_list=[orderInfo];
  }

  getSelectedOrderInfo(orderInfo){
    this.edit_items = false;
    let id = orderInfo.order_id;

    if(orderInfo.isOfflineOrder || orderInfo.is_full_detail_available){
      this.selectedOrderDetails = orderInfo;
      this.orders_list_flag = true;
      this.orders_list_detail_flag = true;
      this.loading = false;
      this.loadingForDetail = false;      
    } else {

      /*let orderData = this.temp_orders_list.filter(order => order.order_id == id);
      if(orderData && orderData[0]){
        this.selectedOrderDetails = orderData[0];

        this.orders_list_flag = true;
        this.orders_list_detail_flag = true;
        this.loading = false;
        this.loadingForDetail = false;

      }else{*/
        let sendData = {
          'shop_code':localStorage.getItem('shop_code'),
          'order_id':id,
          'getLocalizeName':true
        }
         
        this.loadingForDetail = true;

        this.recentOrdersService.getSelectedOrderInfo(sendData).subscribe((res) => {
          let ordersData = res.json();
          if(ordersData.result){
            let shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
            if(typeof ordersData.result.order.comment == 'string' || ordersData.result.order.nocharge_comment){              
              if(ordersData.result.order.comment || ordersData.result.order.nocharge_comment){                
                if(ordersData.result.order.nocharge_comment) {
                  ordersData.result.order.comment = {noChargeNote:ordersData.result.order.nocharge_comment};
                  if(ordersData.result.order.employee_id && ordersData.result.order.employee_id != -1) {
                    ordersData.result.order.comment["employeeId"] = ordersData.result.order.employee_id;
                  }
                } else {
                  try {
                    ordersData.result.order.comment = (ordersData.result.order.comment) ? JSON.parse(ordersData.result.order.comment) : "";
                  } catch(e) {
                    ordersData.result.order.comment = "";
                  }
                }
                
                if(ordersData.result.order.comment.employeeId){
                  if(localStorage.getItem('shop_user_list')){
                    let userList = JSON.parse(localStorage.getItem('shop_user_list'));
                    for (var i = 0; i < userList.length; ++i) {
                      if(userList[i].id.toString() == ordersData.result.order.comment.employeeId.toString()){
                        ordersData.result.order.comment['emp_name'] = userList[i].name;
                        break;
                      }
                    }
                  }
                }
              }else{
                ordersData.result.order.comment = null;
              }              
            }else{
              ordersData.result.order.comment = (ordersData.result.order.comment);
            }
            let total_item_count = 0;
            let data = {
              'order':ordersData.result.order,
              'user':(ordersData.result.user.length>0)? ordersData.result.user[0] : {},
              'items':(ordersData.result.items.length>0)? ordersData.result.items : [],
              'discount_info':(ordersData.result.discount_info.length>0)? ordersData.result.discount_info : [],
              'diary_history':(ordersData.result.diary_history.length>0)? ordersData.result.diary_history : [],
              'rider_data':(ordersData.result.rider_data)? ordersData.result.rider_data : null
            }


            let groupTaxes = [];
            data.items.forEach(function(item : any) {
              total_item_count += (item.item_count) ? item.item_count : 0;
              item.tax_breakup.forEach(function(f : any) {
                /*if(!ordersData.result.order.is_order_cancelled){
                  f['breakup_name'] = f['breakup_name']//+' @'+f['rate'];
                }*/
                var present = false;
                for(var calcTax of groupTaxes) {
                  if(calcTax !== undefined) {
                    if(f['breakup_name'] === calcTax['breakup_name'] && f['rate'] === calcTax['rate']){
                      present = true;
                      if(!ordersData.result.order.is_order_cancelled){
                        calcTax['value'] = calcTax['value'] + f['value'];
                      }
                    }
                  }
                }
                if(!present) {
                  groupTaxes.push(JSON.parse(JSON.stringify(f)));
                }
              });
            });

            if(this.deep_link_order_data && this.deep_link_order_data.order_id) {
              ordersData.result.total_item_count = total_item_count;
              this.setOrderInfoInOrderList(ordersData.result);
            }

            data.order.group_tax = groupTaxes;
            if(!Array.isArray(data.order.tax_breakup) || data.order.tax_breakup.legnth==0) {
              data.order.tax_breakup = groupTaxes;  
            }            

            if(data.user.profile_img_url){
              data.user.profile_img_url = BaseConfig.config.API+data.user.profile_img_url;
            }

            data.user.phone = (data.user.alt_address && data.user.alt_address.length>0 && data.user.alt_address[data.user.alt_address.length - 1].contact_no)? (data.user.alt_address[data.user.alt_address.length - 1].contact_no) : (data.user.phone) ? data.user.phone  : null ;
            data.user.address = data.user.address ? data.user.address : (data.user.alt_address && data.user.alt_address.length>0)? (data.user.alt_address[data.user.alt_address.length - 1].address) : null;

            ordersData.result.diary_history.forEach(data=>{
              if(data.comment == 'credit' || data.comment == 'credit_due'){
                data['title'] = 'Credit Due Amount';
              }else if(data.comment == 'credit_void'){
                data['title'] = 'Credit Void';
              }else if(data.comment == 'used'  || data.comment == 'used_due'){
                data['title'] = 'Pay from diary';
              }else if(data.comment == 'used_void'){
                data['title'] = 'Pay from diary void';
              }else if(data.comment == 'outstanding' || data.comment == 'outstanding_due'){
                data['title'] ="Outstanding Amount";
              }else if(data.comment == 'advance' || data.comment == 'advance_due'){
                data['title'] ="Advance Amount";
              }else if(data.comment == 'credit_note_advance'){
                data['title'] ="Credit Note Amount";
              }else{
                data['title'] = data.comment;
              }
            })

            let payment_list = [];
            if(data.order.split_payment_details){
              for (var key in data.order.split_payment_details) {
                if (data.order.split_payment_details.hasOwnProperty(key)) {

                  if(Array.isArray(data.order.split_payment_details[key].payment_sub_class_info) && data.order.split_payment_details[key].payment_sub_class_info.length>0){
                    data.order.split_payment_details[key].payment_sub_class_info.forEach( data =>{
                      let tempPaymentDetail ={
                        title:data.sub_class_name,
                        amount:data.amount,
                        payment_code:key
                      }
                      if(key == APIHelper.SAVE_TO_DIARY_TYPE){
                        /*obj['comment'] = billInfo.save_to_diary_comment;
                        obj.title = "Save to Diary";
                        obj.payment_code = APIHelper.SAVE_TO_DIARY_TYPE;*/
                      }else{
                        payment_list.push(tempPaymentDetail);
                      }
                    });
                  }else {
                    let obj ={
                      title:data.order.split_payment_details[key].name,
                      amount:data.order.split_payment_details[key].amount,
                      payment_code:key
                    }
                    if(key == APIHelper.SAVE_TO_DIARY_TYPE){
                      /*obj['comment'] = data.order.save_to_diary_comment;
                      obj.title = "Save to Diary";
                      obj.payment_code = APIHelper.SAVE_TO_DIARY_TYPE;*/
                    }else{
                      payment_list.push(obj);
                    }
                  }
                }
              }
            }

            let is_clear_dairy_amount = false;
            ordersData.result.diary_history.forEach(data=>{
              if(data.comment == 'clear'){
                is_clear_dairy_amount = true;
                /*let list = data.payment_details;
                if(list){
                  for (var key in list) {
                    if (list.hasOwnProperty(key)) {
                      if(Array.isArray(list[key].payment_sub_class_info) && list[key].payment_sub_class_info.length>0){
                        list[key].payment_sub_class_info.forEach( data =>{
                          let tempPaymentDetail ={
                            title:data.sub_class_name,
                            amount:data.amount,
                            payment_code:key
                          }

                          let is_found = false;
                          payment_list.forEach(payment=>{
                            if(payment.title == tempPaymentDetail.title){
                              payment.amount += tempPaymentDetail.amount;
                              is_found = true;
                            }
                          })

                          if(!is_found){
                            payment_list.push(tempPaymentDetail);
                          }
                        });
                      }else {
                        let obj ={
                          title:list[key].name,
                          amount:list[key].amount,
                          payment_code:key
                        }

                        let is_found = false;
                        payment_list.forEach(paymentData=>{
                          if(paymentData.title == obj.title){
                            paymentData.amount += obj.amount;
                            is_found = true;
                          }
                        })
                        if(!is_found){
                          payment_list.push(obj);
                        }
                      }
                    }
                  }
                }*/
              } 
            })
            if(is_clear_dairy_amount){
              ordersData.result.diary_history.forEach(data=>{
                if(data.comment == 'used'){
                  let obj ={
                    title:'Pay from diary',
                    amount:data.diary_amount,
                    payment_code:'PAY_FROM_DAIRY'
                  }
                  payment_list.push(obj);
                }
              })
            }

            let assignToId = data.order.shop_user_id;
            if(assignToId == -1){
              data.order['assignTo'] = null;
            }else{
              let shop_user_list = JSON.parse(localStorage.getItem('shop_user_list'));
              for (var i = 0; i < shop_user_list.length; ++i) {
                if(shop_user_list[i].id == assignToId){
                  data.order['assignTo'] = {
                      name:shop_user_list[i].name,
                      id:shop_user_list[i].id
                    }
                }
              }
            }

            if(data.order.is_order_cancelled){
              data.order.order_status = APIHelper.ORDER_COMPLETED;
              data.order.shipping_type = APIHelper.IMMEDIATE;
            }

            data.order['assign'] = null;
              let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
              if(loggedInUser){
                data.order['assign'] = {
                  'id':loggedInUser.id,
                  'name':loggedInUser.name
                };
              }
            data.order.split_payment = payment_list;
            this.selectedOrderDetails = data;
            this.orders_list_detail_flag = true;

            this.orders_list_flag = true;
            this.loading = false;
            this.loadingForDetail = false;
          }
        });
      }
    /*}*/
    //console.log("$$ ",this.orders_list)
  }

  getSubTotalAmount(items){
    var total = 0;
    var totalItemDiscount = 0
    items.forEach(dish => { 
      total += dish.item_price * dish.item_count;
      if(dish.attributes){
        dish.attributes.forEach((attr) => {
          total += attr.price ;
        });        
      }
      /*if(dish.discount) {
        total -= parseFloat(dish.discount);
      }*/
    });
    return total;
  }

  sortRecentOrders(e:any){

    this.isForNonCompleteOrders = false;
    this.sorting = e;
    if(this.sorting == 'Delivery'){
      this.selectedOrderType = 'ST0002';
    }else if(this.sorting == 'Pick Up'){
      this.selectedOrderType = 'ST0001';
    }else if(this.sorting == 'In Store'){
      this.selectedOrderType = 'ST0003';
    }else{
      this.selectedOrderType = 'ALL';
    }
    this.isPrevFlag = false;
    this.alterStylesForPrev={
        'cursor': 'not-allowed',
        'opacity': '0.65'
    }
    this.pageNo = 1;
    let sendData = this.getParamForFilter();
    this.getAllOrdersInfo(sendData);
  }

  getParamForFilter(){

    let from_date = new Date(this.beginDate);
    let to_date = new Date(this.endDate);
    from_date.setHours(0);
    from_date.setMinutes(0);
    from_date.setSeconds(0);
    to_date.setHours(23);
    to_date.setMinutes(59);
    to_date.setSeconds(59);

    let sData = {
      'page':this.pageNo,
      'limit':this.limit,
      'from_date_filter': from_date,
      'to_date_filter': to_date,
      'search_by':this.search_by,
      'shop_code':localStorage.getItem('shop_code')
    }


    let shipping_type_code = null;

    if(this.sorting == 'Pick Up'){
      shipping_type_code = 'ST0001'
    }else if(this.sorting == 'Delivery'){
      shipping_type_code = 'ST0002'
    }else if(this.sorting == 'In Store'){
      shipping_type_code = 'ST0003'
    }

    if(shipping_type_code){
      if(this.sorting && this.sorting != ""){
        sData['shipping_type_filter'] = shipping_type_code;
      }  
    }
    
    if(this.search && this.search != ""){
      sData['search_filter'] = this.search;
    }
    return sData;
  }

  fetchOrderDetail(order_id) {
    this.getSelectedOrderInfo({order_id:order_id});
  }

  searchRecentOrders(e:any){
    this.isForNonCompleteOrders = false;
    this.search = e;
    this.isPrevFlag = false;
    this.alterStylesForPrev={
        'cursor': 'not-allowed',
        'opacity': '0.65'
    }
    if (navigator.onLine) {
      this.pageNo = 1;
      let sendData = this.getParamForFilter();
      this.getAllOrdersInfo(sendData);
    }else{

      let list = []
      let offlineOrderList = JSON.parse(localStorage.getItem('temp_orders_list'));
      let tempofflineOrderList = offlineOrderList;
    
      if(offlineOrderList && offlineOrderList.length>0){

        if(this.search && this.search!=""){
            list = offlineOrderList.filter((data) =>{
              if ((data.order_id && data.order_id.toLowerCase() == this.search.toLowerCase()) || 
                (data.order.client_reference_code && data.order.client_reference_code !='NA' && data.order.client_reference_code.split('/')[0] == this.search) ||
                (data.user_name && data.user_name.toLowerCase() == this.search.toLowerCase())) {
                return true;
              }else{
                return false;
              }

            });
        }else{
          list = tempofflineOrderList;
        }
        this.orders_list = list;
        if(this.orders_list && this.orders_list[0])
          this.selectedOrderDetails = this.orders_list[0];
        }
    }
  }

  changedFromAndToDate(e:any){
    this.isForNonCompleteOrders = false;
    this.beginDate = e.from_date;
    this.endDate = e.end_date;

    this.isPrevFlag = false;
    this.alterStylesForPrev={
        'cursor': 'not-allowed',
        'opacity': '0.65'
    }
    this.pageNo = 1;
    let sendData = this.getParamForFilter();
    this.getAllOrdersInfo(sendData);
  }

  getInitialData(){

    let from_date = new Date();
    let to_date = new Date();
    from_date.setHours(0);
    from_date.setMinutes(0);
    from_date.setSeconds(0);
    to_date.setHours(23);
    to_date.setMinutes(59);
    to_date.setSeconds(59);
    return {
      'page':this.pageNo,
      'limit':this.limit,
      'from_date_filter': from_date,
      'to_date_filter':to_date,
      'shop_code':localStorage.getItem('shop_code')
    }
  }

  sorting_list: string[] = [
  	'All', 'Pick Up', 'Delivery', 'In Store'
  ];

  dateFilter(date) {
    if(this.beginDate && this.endDate) {
      if(date > this.beginDate && date < this.endDate || date.toDateString() == this.beginDate.toDateString() || date.toDateString() == this.endDate.toDateString()) {
        return true;
      } else { return false; }
    } else if(this.beginDate) {
      if(date > this.beginDate || date.toDateString() == this.beginDate.toDateString()) {
        return true;
      } else { return false; }
    } else if(this.endDate) {
      if(date < this.endDate || date.toDateString() == this.endDate.toDateString()) {
        return true;
      } else { return false; }
    }
  }

  getBeforeHours(order_date:any){
    if(!order_date) {
      return "";
    }
    let date1 = new Date().getTime();
    let date2 = new Date(order_date).getTime();
    let diff = (date1 - date2);
    let min:any =(diff / (60000));
    let hour:any = Math.floor((min) / 60);
    let sendVal = hour+' hours';
    if(min < 60){
      sendVal = Math.floor(min)+' minutes';
    }
    return sendVal;
  }

  sortingFilter(order) {
    if(this.isForNonCompleteOrders){
      let delivery_date = new Date(order.delivery_date);
      //removed delivery date condition as said by @yogesh on 6th May 2019
      if(/*(this.shop_start_time<delivery_date && delivery_date<this.shop_close_time) && */
        (order.shipping_type == APIHelper.HOME_DELIVERY || order.shipping_type == APIHelper.PICK_UP) && 
        (order.order_status == APIHelper.ORDER_APPROVED || order.order_status == APIHelper.ORDER_DELIVERED || order.order_status == APIHelper.ORDER_NEW) && 
        (order.order_id.indexOf('CAN') == -1)) {
        return true;
      } else {
        return false;
      }
    }else{
      if(this.selectedOrderType == order.shipping_type || this.selectedOrderType == 'ALL') {
        return true;
      } else {
        return false;
      }
    }  
  }
    

}
