import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerInfoComponent } from './pages/customer-info/customer-info.component';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { StateGuardService } from './pages/state-guard.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  delType: number = 1;
  modalRef: BsModalRef;
  showSB: boolean = false;
  constructor(private route: ActivatedRoute,
    private stateGuard:StateGuardService,
    private modalService: BsModalService) {
  }

  public subscriptions: Subscription[] = [];
  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  ngOnInit() { 
  }

  showSidebar() {
    setTimeout(() => {
      this.showSB = true;
      console.log('slider');
    }, 100);
  }

}