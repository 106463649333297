import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-hold-order-modal',
  templateUrl: './hold-order-modal.component.html',
  styleUrls: ['./hold-order-modal.component.less']
})
export class HoldOrderModal implements OnInit {
  
  constructor(public modalRef: BsModalRef) { }
  public decision: string;
  public text: string;
  public type: string;
  public isRestaurant:boolean = (localStorage.getItem('isRestaurant') == 'true') ? true: false;
  
  ngOnInit() {
  	this.getData();
  }

  getData(){
  	var self = this;
  	setTimeout(()=>{
  		self.text = this.modalRef.content.text;
  		self.type = this.modalRef.content.type;
  	},100);
  }  
}
