import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CaptainAppService } from '../../../pages/helpers/services/captain-app.service';
import { CaptainAppHelper } from '../../helpers/captain-app-helper';
import { StateGuardService } from '../../state-guard.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription, of } from 'rxjs';
import { ConfirmationModal } from '../../confirmation-modal/confirmation-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ShopCategoriesService } from '../../helpers/services/shop-categories.service';
import { ShopCategoriesItemsService } from '../../helpers/services/shop-categories-items.service';
import { forEach } from 'lodash-es';
import { MenuComponent } from '../../menu/menu.component';
import { UpdateInventoryMessage } from '../../../pages/update-inventory-message.service';


// Import HttpClient and RxJS operators for scanning IPs
import { HttpClient } from '@angular/common/http';
import { timeout, catchError } from 'rxjs/operators';

@Component({
	selector: 'app-other-settings',
	templateUrl: './other-settings.component.html',
	styleUrls: ['./other-settings.component.less']
})
export class OthersSettingsComponent implements OnInit {

	shop_config:any;
	local_config:any;
	captain_server_url:any;
	is_captain_app_available:boolean = false;
	isLoadingShow:boolean = false;
	isEdit:boolean = false;
	idle_screen_timeout: any = 0;
	barcode_batch_separator: any = "";
	barcode_weight_separator: any = "";
	isEditIdleScreenTimeout: boolean = false;
	selectedMenuSmartSearch: any;
	menuSmartSearchList: any = [
		{'field':'item_name','match':'contain','type':'string','id':"item_name","name":"Item Name"},
		{'field':'bar_code','match':'exact','type':'number','id':"bar_code","name":"Barcode"},
		{'field':'short_key','match':'exact','type':'number','id':'short_key',"name":"Short Key"}		
	];
	menuSmartSearchSettings: any = {
        text: "Select Smart Search",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: "myclass custom-class",
        maxHeight:100,
        badgeShowLimit:5
	}
	public modalRef: BsModalRef;
  	public subscriptions: Subscription[] = [];

	constructor(private captainAppService:CaptainAppService,
				private captainAppHelper:CaptainAppHelper,
				private modalService: BsModalService,
				private messageService:MessageService,
				private shopCategoryService: ShopCategoriesService,
    			private shopCategoryItemService: ShopCategoriesItemsService,
    			private menuComponent:MenuComponent,
    			private updateInventoryService:UpdateInventoryMessage,
				private stateGuard:StateGuardService,
				private http: HttpClient  // <-- Inject HttpClient
			) { }

	ngOnInit() {
		this.shop_config = JSON.parse(localStorage.getItem('shop_configuration')) || {};
		this.local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};

		if(this.shop_config.shop_info && this.shop_config.shop_info.is_captain_app_available){
			this.is_captain_app_available = true;
		}
		this.captain_server_url = localStorage.getItem('captain_server_url');
		this.idle_screen_timeout = localStorage.getItem('idle_screen_timeout');
		this.barcode_batch_separator = localStorage.getItem('barcode_batch_separator');
		this.barcode_weight_separator = localStorage.getItem('barcode_weight_separator');

		try {
			let mssl = localStorage.getItem("menu_smart_search_list");
			mssl = JSON.parse(mssl);
			this.selectedMenuSmartSearch = mssl;
		} catch(e) {
			console.log(e);
		}
	}

	private unsubscribe() {
	    this.subscriptions.forEach((subscription: Subscription) => {
	      subscription.unsubscribe();
	    });
	    this.subscriptions = [];
	}

// ──────────────────────────────
	// New: Scan for Captain Server
	// ──────────────────────────────
	/**
	 * Loops through IP addresses 192.168.1.1 to 192.168.1.255,
	 * making an HTTP GET request (with a timeout of 1 second) to check for a running server.
	 * If a server is found, it updates captain_server_url.
	 */
	scanForCaptainServer(): void {
		this.isLoadingShow = true;
		const baseIP = '192.168.1.';
		const port = 3000;
		let foundServer: string | null = null;
		let completedRequests = 0;

		for (let i = 1; i <= 255; i++) {
			const ip = `${baseIP}${i}`;
			const url = `http://${ip}:${port}/`;

			this.http.get(url).pipe(
				timeout(1000), // 3 second timeout
				catchError(err => of(null)) // Continue even if an error occurs
			).subscribe(response => {
				completedRequests++;

				if (response && !foundServer) {
					foundServer = url;
					console.log(`✅ Server found at ${url}`);
					this.captain_server_url = url;
					localStorage.setItem('captain_server_url', url);
				
				} else {
					
					console.log(`❌ No response from ${url}`);
				}

				if (completedRequests === 255) {
					this.isLoadingShow = false;
					if (!foundServer) {
						console.log("No server found in the specified IP range.");
						this.messageService.add({ severity: 'warn', summary: 'No server found', detail: 'Could not locate a server in the IP range.' });
					} else {
						this.messageService.add({ severity: 'success', summary: 'Server found', detail: `Server found at ${foundServer}` });
					}
				}
			});
		}
	}

	onClickEnableCustomerAddressPrint() {
		if(this.local_config && this.local_config.enable_customer_address_print) {
			this.local_config.enable_customer_address_print = !this.local_config.enable_customer_address_print;
		} else {
			this.local_config.enable_customer_address_print = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	onClickDisableCheckPrint() {
		if(this.local_config && this.local_config.disable_check_print) {
			this.local_config.disable_check_print = !this.local_config.disable_check_print;
		} else {
			this.local_config.disable_check_print = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	onClickEnableHomeDeliveryPickupSelfService() {
		if(this.local_config && this.local_config.enable_home_delivery_pickup_self_service) {
			this.local_config.enable_home_delivery_pickup_self_service = !this.local_config.enable_home_delivery_pickup_self_service;
		} else {
			this.local_config.enable_home_delivery_pickup_self_service = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	onClickDisableResetBillNumber() {
		if(this.local_config && this.local_config.disable_reset_bill_number) {
			this.local_config.disable_reset_bill_number = !this.local_config.disable_reset_bill_number;
		} else {
			this.local_config.disable_reset_bill_number = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	onClickEnableGSTSummaryOnPrint() {
		if(this.local_config && this.local_config.show_gst_summary_on_print) {
			this.local_config.show_gst_summary_on_print = !this.local_config.show_gst_summary_on_print;
		} else {
			this.local_config.show_gst_summary_on_print = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}	

	onClickEnableGridViewItemSearch() {
		if(this.local_config && this.local_config.enable_grid_view_item_search) {
			this.local_config.enable_grid_view_item_search = !this.local_config.enable_grid_view_item_search;
		} else {
			this.local_config.enable_grid_view_item_search = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));	
	}

	onClickEnableOrderNote() {
		if(this.local_config && this.local_config.enable_order_note) {
			this.local_config.enable_order_note = !this.local_config.enable_order_note;
		} else {
			this.local_config.enable_order_note = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	onClickDisableMenuItemWithImageView() {
		if(this.local_config && this.local_config.disable_menu_item_with_image_view) {
			this.local_config.disable_menu_item_with_image_view = !this.local_config.disable_menu_item_with_image_view;
		} else {
			this.local_config.disable_menu_item_with_image_view = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));	
	}

	onClickDisableCoverEntry() {
		if(this.local_config && this.local_config.disable_cover_entry) {
			this.local_config.disable_cover_entry = !this.local_config.disable_cover_entry;
		} else {
			this.local_config.disable_cover_entry = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	onClickDisableCaptainNotifications() {
		if(this.local_config && this.local_config.disable_captain_notifications) {
			this.local_config.disable_captain_notifications = !this.local_config.disable_captain_notifications;
		} else {
			this.local_config.disable_captain_notifications = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	onClickDisableCaptainNotificationsSound() {
		if(this.local_config && this.local_config.disable_captain_notifications_sound) {
			this.local_config.disable_captain_notifications_sound = !this.local_config.disable_captain_notifications_sound;
		} else {
			this.local_config.disable_captain_notifications_sound = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	onClickEnableOTNote() {
		if(this.local_config && this.local_config.enable_kot_note) {
			this.local_config.enable_kot_note = !this.local_config.enable_kot_note;
		} else {
			this.local_config.enable_kot_note = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	onClickEnableDispatchPrint() {
		if(this.local_config && this.local_config.enable_dispatch_print) {
			this.local_config.enable_dispatch_print = !this.local_config.enable_dispatch_print;
		} else {
			this.local_config.enable_dispatch_print = true;
		}
		localStorage.setItem("local_configuration",JSON.stringify(this.local_config));
	}

	editUrl(){
		this.isEdit = true;
		this.scanForCaptainServer();
	}

	onChangeMenuItem(event) {		
		localStorage.setItem("menu_smart_search_list",JSON.stringify(this.selectedMenuSmartSearch));
	}

	saveUrl(){
		localStorage.setItem('captain_server_url',this.captain_server_url);
		this.isEdit = false;
		window.location.reload();
	}

	editIdleScreenTimeout(){
		this.isEditIdleScreenTimeout = true;
	}

	saveIdleScreenTimeout(){
		localStorage.setItem('idle_screen_timeout',this.idle_screen_timeout);
		this.isEditIdleScreenTimeout = false;
	}

	onChangeBarcodeBatchSeparator() {
		localStorage.setItem('barcode_batch_separator',this.barcode_batch_separator);
	}

	onChangeBarcodeWeightSeparator() {
		localStorage.setItem('barcode_weight_separator',this.barcode_weight_separator);		
	}

	onClickSyncShopCategories() {
		this.modalRef = this.modalService.show(ConfirmationModal, {class: 'centred-modal'});
        this.modalRef.content.decision = "";
        this.modalRef.content.text = "If you have re sync, syncing will change  or reset your current categories or categories items?";
        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
            if (this.modalRef.content.decision === 'yes') {
				let menuChapters = [];
				this.isLoadingShow = true;
				var cur_categories: any = localStorage.getItem('categories');
				if(cur_categories) {
					cur_categories = JSON.parse(cur_categories);
					cur_categories.forEach(cat => {
						localStorage.removeItem(cat.category_code);
					});
					localStorage.removeItem('categories');
				}

				localStorage.removeItem("shop_items");
			   	localStorage.removeItem("current_version_no")
				this.menuComponent.loadCategories().then((result) => {
					this.isLoadingShow = false;
					this.updateInventoryService.sendUpdateAvailableInfo(false,false);
			   		this.messageService.add({ severity: 'success', summary: 'categories synced successfully', detail: '' });
				})
				.catch((error) => {
			    	this.messageService.add({ severity: 'error', summary: 'Error while sync all categories and items', detail: '' });					
			    	this.isLoadingShow = false
				})

				/*this.shopCategoryService.categories(localStorage.getItem('shop_code')).subscribe((res) => {
			        let categories = res.json();
			        if (categories.status === 0) {
			          var catCode = [];
			          var catIndex = [];
			          for (var cIndx = 0; cIndx < categories.result.categories.length; cIndx++) {
            			categories.result.categories[cIndx].category_sequence = categories.result.categories[cIndx].category_sequence>=0 ? categories.result.categories[cIndx].category_sequence : 1000;
			            if (catCode.indexOf(categories.result.categories[cIndx].category_code) === -1 && !categories.result.categories[cIndx].is_ingredient_category && categories.result.categories[cIndx].show_category) {
			              catIndex.push(cIndx);
			              catCode.push(categories.result.categories[cIndx].category_code);
			            }
			          }

			          var tempArray: any = [];
			          catIndex.forEach(i => {
			            tempArray.push(categories.result.categories[i]);
			          });

			          categories.result.categories = tempArray;
			          menuChapters = categories.result.categories.sort((a: any, b: any) => {
			            if (a.category_sequence > b.category_sequence) return 1;
			            if (a.category_sequence < b.category_sequence) return -1;
			            return 0;
			          });

			          var membershipCategory = {
			            "category_code": "MEMBERSHIP",
			            "category_name": "Membership",
            			"category_sequence": 10000,
			            "category_image_url": "",
			            "category_image_md5": "",
			            "approval_request": null,
			            "rejection_comment": null,
			            "show_category": true,
			            "show_category_online": true,
			            "updatedAt": "2018-01-22T11:41:35.000Z",
			            "createdAt": "2018-01-22T11:41:35.000Z",
			            "is_ingredient_category": true,
			            "significance": 0
			          }
			          var membership_list:any = JSON.parse(localStorage.getItem("membership_list")) || [];

			          if(Array.isArray(membership_list)) {
			            menuChapters.push(membershipCategory);
			          }

			          localStorage.setItem('categories', JSON.stringify(menuChapters));
			          var nCategories = menuChapters.length;
			          var outerObject = this;

			          // try one time chache of all items
			          forEach(menuChapters, function (category, categoryIdx, arr) {
			            if(category.category_code == "MEMBERSHIP") {
			                nCategories--;
			                if (nCategories === 0) {
			                  	outerObject.messageService.add({ severity: 'success', summary: 'Category synced successfully', detail: '' });
			                  	localStorage.setItem('inventory_update_date', (new Date()).toString());                  
			                  	outerObject.isLoadingShow = false;
			                }
			            } else {
			              outerObject.shopCategoryItemService.items(localStorage.getItem('shop_code'), category.category_code).subscribe((res: any) => {
			                var items = res.json();
			                nCategories--;
			                if (items.status === 0) {
			                  var sortedItems = items.result.items.sort((a: any, b: any) => {
			                    if (a.item_name > b.item_name) return 1;
			                    if (a.item_name < b.item_name) return -1;
			                    return 0;
			                  });
			                  sortedItems = sortedItems.filter((itm: any) => itm.show_item !== false);

			                  sortedItems.forEach( itemInfo => {
			                    itemInfo['category_code'] = category.category_code
			                  })
			                  localStorage.setItem(category.category_code, JSON.stringify(sortedItems));
			                }
			                if (nCategories === 0) {      
			    				outerObject.messageService.add({ severity: 'success', summary: 'Category synced successfully', detail: '' });
			    				localStorage.removeItem("current_version_no")
			                  	localStorage.setItem('inventory_update_date', (new Date()).toString());                  
			                  	outerObject.isLoadingShow = false;
			                }
			              }, error => {
			              	outerObject.messageService.add({ severity: 'error', summary: 'Error while fetch items of category '+category.category_name, detail: '' });
			              	if (nCategories === 0) {      
			    				outerObject.messageService.add({ severity: 'success', summary: 'categories synced successfully', detail: '' });
			    				localStorage.removeItem("current_version_no")
			                  	localStorage.setItem('inventory_update_date', (new Date()).toString());                  
			                  	outerObject.isLoadingShow = false;
			                }
			              });
			            }
			          });
			        } else {
			          outerObject.isLoadingShow = false;
			        }
			    },error => {
			    	this.messageService.add({ severity: 'error', summary: 'Error while resync all categories and items', detail: '' });
			    });*/
			}
            this.unsubscribe();
        }));
	}

	onClickSyncTables(){
		this.modalRef = this.modalService.show(ConfirmationModal, {class: 'centred-modal'});
        this.modalRef.content.decision = "";
        this.modalRef.content.text = "If you have sync the tables, syncing will change  or reset your current tables?";
        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
            if (this.modalRef.content.decision === 'yes') {
            	this.syncTables();
           	}
            this.unsubscribe();
        }));
  	}


  	syncTables(){

  		let sendData = [];
	    var self = this;
	    let allPlaces = this.stateGuard.obtain('selects-box.component:places') || [];
	    if(Array.isArray(allPlaces) && allPlaces[0]){
	      allPlaces.forEach(placeDetails =>{
	        if(Array.isArray(placeDetails.units) && placeDetails.units[0]){
	          placeDetails.units.forEach(currentTable =>{

	            let customer_info = {
	              phone : (currentTable.userInfo && currentTable.userInfo.mobile_no)? currentTable.userInfo.mobile_no : '0000000000'
	            }

	            let temp_client_reference_code = (currentTable.visitors && currentTable.visitors[0] && currentTable.visitors[0].temp_client_reference_code)? currentTable.visitors[0].temp_client_reference_code : null;
	            let tempObject = {
	              section_name               : currentTable.location,
	              tables                     : currentTable.tables,
	              covers                     : currentTable.covers? currentTable.covers : 0,
	              started_at                 : currentTable.activePhase? currentTable.activePhase.timeStamp : null,
	              assigny                    : currentTable.assigny,
	              table_status               : currentTable.table_status? currentTable.table_status :'idle',
	              lock_index                 : currentTable.lock_index ? currentTable.lock_index : 0, 
	              customer_info              : customer_info,
	              temp_client_reference_code : temp_client_reference_code,
	              kot_request_description    : currentTable.kot_request_description ? currentTable.kot_request_description : "",
	              kot_request_number         : currentTable.kot_request_number ? currentTable.kot_request_number : 0,
	              kot_ready_number           : currentTable.kot_ready_number ? currentTable.kot_ready_number : 0,
	              is_checkout_call           : currentTable.is_checkout_call ? currentTable.is_checkout_call : false,
	            }

	            if(currentTable.location == "Garden" && currentTable.tables=="1") {
	            }

	            if(currentTable.isFromOnHold){
	            	tempObject['table_status'] = 'hold';
	            }/*else if(currentTable.isOnHold){
	              tempObject['table_status'] = 'hold';
	            }*/

	            if(currentTable.tables.length>1){
	            	for (var i = 0; i < currentTable.tables.length; ++i) {
	            		if(i!=0){
	            			let obj = {
				              section_name               : currentTable.location,
				              tables                     : [],
				              covers                     : 0,
				              started_at                 : null,
				              assigny                    : currentTable.assigny,
				              table_status               : 'idle',
				              lock_index                 : 0, 
				              customer_info              :  {
												              phone : '0000000000'
												            },
				              temp_client_reference_code : null,
				              kot_request_number         : 0,
				              kot_request_description    : "",
				              kot_ready_number           : 0,
				              is_checkout_call           : false,
				              visitors					 : []
				            }
				            obj['tables'].push(currentTable.tables[i]);
				            sendData.push(obj);
	            		}

	            	} 
	            }
	            if(Array.isArray(currentTable.visitors)){

	              let tempVisitors = [];
	              currentTable.visitors.forEach( visitor =>{

	                let tempDishes = [];
	                let tempKot = [];
	                if(Array.isArray(visitor.dishes) && visitor.dishes[0]){
	                  visitor.dishes[0].forEach( itemInfo =>{

	                    let tempAttributes = [];

	                    if(itemInfo.selected_variant_item){
	                      let tempItemData = {
	                         category_code : itemInfo.selected_variant_item.category_code,
	                         item_code : itemInfo.selected_variant_item.item_code,
	                         item_count : itemInfo.selected_variant_item.item_count,
	                         item_price : itemInfo.selected_variant_item.unit_price,
	                         discount : itemInfo.selected_variant_item.discount? itemInfo.selected_variant_item.discount : 0,
	                         discount_type : itemInfo.selected_variant_item.discountUnits? itemInfo.selected_variant_item.discountUnits : null,
	                         item_from : itemInfo.selected_variant_item.item_from,
	                      }
	                      if(Array.isArray(itemInfo.selected_variant_item.attributes)){
	                        itemInfo.selected_variant_item.attributes.forEach( attributeDetails =>{
	                          tempAttributes.push(
	                            {
	                              attribute_code : attributeDetails.attribute_code,
	                              count : attributeDetails.count,
	                            }
	                          )
	                        })
	                      }
	                      tempItemData['attributes'] = tempAttributes;
	                      tempDishes.push(tempItemData);

	                    }else{

	                      let tempItemData= {
	                         category_code : itemInfo.category_code,
	                         item_code : itemInfo.item_code,
	                         item_count : itemInfo.item_count,
	                         item_price : itemInfo.unit_price,
	                         discount : itemInfo.discount? itemInfo.discount : 0,
	                         discount_type : itemInfo.discountUnits? itemInfo.discountUnits : null,
	                         item_from : itemInfo.item_from,
	                      }
	                      if(Array.isArray(itemInfo.attributes)){
	                        itemInfo.attributes.forEach( attributeDetails =>{
	                          tempAttributes.push(
	                            {
	                              attribute_code : attributeDetails.attribute_code,
	                              count : attributeDetails.count,
	                            }
	                          )
	                        })
	                      }
	                      tempItemData['attributes'] = tempAttributes;
	                      tempDishes.push(tempItemData);
	                    }
	                  })
	                }

	                if(Array.isArray(visitor.kot) && visitor.kot[0]){

	                  visitor.kot.forEach( kotInfo =>{
	                    let tempKotData = {
	                       kot_id : kotInfo.kot_id,
                           kot_number: kotInfo.kot_number
	                    }
	                    let tempKotItems = [];
	                    if(Array.isArray(kotInfo.items) && kotInfo.items[0]){

	                      kotInfo.items.forEach( kotItemInfo =>{
	                        let tempKotItemAttributes = [];
	                        let tempKotItemData = {
	                          category_code : kotItemInfo.category_code,
	                          item_code : kotItemInfo.item_code,
	                          item_count : kotItemInfo.item_count,
	                          item_price : (kotItemInfo.unit_price)? parseFloat(kotItemInfo.unit_price) : parseFloat(kotItemInfo.item_price),
	                          discount : kotItemInfo.discount? kotItemInfo.discount : 0,
	                          discount_type : kotItemInfo.discountUnits? kotItemInfo.discountUnits : null,
	                          item_from : kotItemInfo.item_from,
	                          is_billed : kotItemInfo.is_billed,
	                          delete_reason : kotItemInfo.delete_reason ? kotItemInfo.delete_reason : null,
	                        }

	                        if(Array.isArray(kotItemInfo.attributes)){
	                          kotItemInfo.attributes.forEach( attributeDetails =>{
	                            tempKotItemAttributes.push(
	                              {
	                                attribute_code : attributeDetails.attribute_code,
	                                count : attributeDetails.count,
	                              }
	                            )
	                          })
	                        }
	                        tempKotItemData['attributes'] = tempKotItemAttributes;
	                        tempKotItems.push(tempKotItemData);

	                      })

	                      tempKotData['items'] = tempKotItems;

	                    }
	                    tempKot.push(tempKotData);
	                  })
	                }
	                tempVisitors.push(
	                  {
	                    items : tempDishes,
	                    kot : tempKot,
	                  }
	                )
	              })
	              tempObject['visitors'] = tempVisitors;
	            }
	            sendData.push(tempObject);
	          })
	        }
	      })
    	}
        self.syncTablesWithCaptainApp(sendData);
	    /*setTimeout(() =>{
	      self.syncTables(sendData);
	    },300)*/
  	}

  	syncTablesWithCaptainApp(data){
	    this.isLoadingShow = true;
	    let sendData = {
	      table_info:data
	    }
	    this.captainAppService.syncTables(sendData).subscribe((res) => {
	      var apiRes = res.json();
	      if (apiRes.status === 0) {
	      } else {
	        //this.messageService.add({ severity: 'error', summary: apiRes.message, detail: '' });
	      }
	      this.isLoadingShow = false;
	    },err =>{
	      this.isLoadingShow = false;
	      this.messageService.add({ severity: 'error', summary: 'Captain app has not been synced with the latest changes. Please sync the changes from Other Settings', detail: '' });
	    });
 	}

 	onClickSyncShopconfig(){
 		this.modalRef = this.modalService.show(ConfirmationModal, {class: 'centred-modal'});
        this.modalRef.content.decision = "";
        this.modalRef.content.text = "If you have sync the shop config, syncing will change  or reset your current shop config?";
        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
            if (this.modalRef.content.decision === 'yes') {
            	this.syncShopconfig();
           	}
            this.unsubscribe();
        }));
	}

	syncShopconfig(){
		this.isLoadingShow = true;
	    let sendData = JSON.parse(localStorage.getItem('shop_configuration')) || {};
	    sendData['shop_user_list'] = JSON.parse(localStorage.getItem('shop_user_list')) || [];
	    sendData['partner_list'] = [];
	    sendData['shop_printers'] = JSON.parse(localStorage.getItem('shop_printers')) || [];
	    sendData['pos_type'] = "web";

	    this.captainAppService.syncShopSetting(sendData).subscribe((res) => {
	        var apiRes = res.json();
	        if (apiRes.status === 0) {
	        } else {
	          this.messageService.add({ severity: 'error', summary: apiRes.message, detail: '' });
	        }
	        this.syncItemsCategories();
	    },err =>{
	        this.isLoadingShow = false;
	        this.messageService.add({ severity: 'error', summary: 'Captain app has not been synced with the latest changes. Please sync the changes from Other Settings', detail: '' });
	    });
	}

	syncItemsCategories(){

	    let categories = JSON.parse(localStorage.getItem('categories')) || [];
	    let sendData = {
	      shop_items_category:categories
	    }

	    this.captainAppService.syncItemsCategories(sendData).subscribe((res) => {
	        var apiRes = res.json();

	        if (apiRes.status === 0) {
	          
	        } else {
	          //this.messageService.add({ severity: 'error', summary: apiRes.message, detail: '' });
	        }
	        this.isLoadingShow = false;
	    },err =>{
	        this.isLoadingShow = false;
	        this.messageService.add({ severity: 'error', summary: 'Captain app has not been synced with the latest changes. Please sync the changes from Other Settings', detail: '' });

	    });
 	}

 	onClickSyncItems(){
 		this.modalRef = this.modalService.show(ConfirmationModal, {class: 'centred-modal'});
        this.modalRef.content.decision = "";
        this.modalRef.content.text = "If you have sync the items, syncing will change  or reset your current items?";
        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
            if (this.modalRef.content.decision === 'yes') {
            	this.syncItems();
           	}
            this.unsubscribe();
        }));
  	}

  	syncItems(){
  		this.isLoadingShow = true;
	    let categories = JSON.parse(localStorage.getItem('categories')) || [];
	    let categoriesLength = categories.length;
	    let sendItems = [];
	    if(Array.isArray(categories) && categories[0]){
	      	for (var i = 0; i < categoriesLength; i++) {
		        let items = JSON.parse(localStorage.getItem(categories[i].category_code)) || [];
		        if(Array.isArray(items)){
		          	let itemsLength = items.length;
		          	for (var k = 0; k < itemsLength; k++) {
			            items[k]["category_code"] = categories[i].category_code;
			            sendItems.push(items[k]);			            		            	
		          	}
		        }
	      	}
	      	this.isLoadingShow = false;
			this.captainAppHelper.syncItems(sendItems)
	    } else {
	    	this.isLoadingShow = false;
	    }
  	}

}

