import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
import { DemoModalServiceStaticComponent } from '../demo-modal-service-static/demo-modal-service-static.component';
import { CloseShopModal } from '../close-shop-modal/close-shop-modal.component';
import { CashRegisterService } from '../helpers/services/cash-register.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
  providers:[DemoModalServiceStaticComponent]
})
export class SidebarComponent implements OnInit {

  @Input('show') show: boolean = false;
  public subscriptions: Subscription[] = [];
  public modalRef: BsModalRef;

  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  @Output() state: EventEmitter<boolean> = new EventEmitter();

  constructor(private demoModalServiceStaticComponent:DemoModalServiceStaticComponent,private router: Router,private modalService: BsModalService,
    private cashRegisterService: CashRegisterService) { }

  ngOnInit() {

  }

  @HostListener('window:click') windowClick() {
    this.show = false;
    this.state.emit(this.show);
  }

  // @HostListener('click') elementClick() {
  // 	setTimeout(() => {
  // 		this.show = true;
  // 	}, 50);
  // 	this.state.emit(this.show);
  // }

  onClickLogout(){
    let billQueue = [];
    try { billQueue = JSON.parse(localStorage.getItem('bill_queue')) || []; } catch (e) {}

    if(billQueue.length>0) {
      alert("some orders are pending to sync. Please wait until all orders are sync");
      return;
    }
    
    if(localStorage.getItem('isCashRegister') && localStorage.getItem('isCashRegister')== 'true'){
      this.modalRef = this.modalService.show(ConfirmationModal, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.text = "Cash register is not closed yet. Do you want to close cash register?";

      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'yes') {
          this.unsubscribe();
          this.router.navigate(['pages/cash-register/open-close',{'type': 'logout'}]);
        }else{
          this.unsubscribe();
          var self = this;
          setTimeout(()=>{
            self.showLogoutModal();
          },200);
        }
      }));
    }else{
      this.showLogoutModal();
    }
  }

  showLogoutModal(){
    this.modalRef = this.modalService.show(ConfirmationModal, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.text = "Log out will clear all your local data and you will have to follow the registration process to log in again, Do you still want to log out?";

      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'yes') {
          this.demoModalServiceStaticComponent.logout();
        }
        this.unsubscribe();
    }));
  }

  closeShop(){
    let billQueue = [];
    try { billQueue = JSON.parse(localStorage.getItem('bill_queue')) || []; } catch (e) {}

    if(billQueue.length>0) {
      alert("some orders are pending to sync. Please wait until all orders are sync");
      return;
    }

    if(localStorage.getItem('isCashRegister') && localStorage.getItem('isCashRegister')== 'true'){
      this.modalRef = this.modalService.show(ConfirmationModal, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.text = "Cash register is not closed yet. Do you want to close cash register?";

      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'yes') {
          this.unsubscribe();
          this.router.navigate(['pages/cash-register/open-close',{'type': 'closeShop'}]);
        }else{
          this.unsubscribe();
          var self = this;
          setTimeout(()=>{
            self.showCloseShopModal();
          },200);
        }
      }));
    }else{
      this.showCloseShopModal();
    }
  }

  onShopCloseModal() {
    this.saveStockReport();
    var local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
    if(!local_config.disable_reset_bill_number) {
      let date = new Date()
      var dd   = date.getDate()+'';
      var mm   = (date.getMonth()+1)+'';
      var yyyy = date.getFullYear();
      if(dd.length == 1)  { dd='0'+dd } 
      if(mm.length == 1)  { mm='0'+mm }
      let str = yyyy+'/'+mm+'/'+dd;
      localStorage.setItem('ref_bill_update_date',date.toISOString());          
      localStorage.setItem('ref_bill_num','-1');          
      localStorage.setItem('kot_number','1');
      localStorage.setItem('check_print_number','1');
    }
    
    localStorage.setItem('temp_orders_list','[]');
    this.router.navigate(['verify-pin']);    
  }

  showCloseShopModal(){
    let billQueue = [];
    try { billQueue = JSON.parse(localStorage.getItem('bill_queue')) || []; } catch (e) {}

    if(billQueue.length>0) {
      alert("some orders are pending to sync. Please wait until all orders are sync");
      return;
    }

    var local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
    if(!local_config.disable_reset_bill_number) {
      this.modalRef = this.modalService.show(CloseShopModal, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'ok') {
          this.onShopCloseModal();
        }
        this.unsubscribe();
      }));
    } else {
      this.onShopCloseModal();
    }
  }

  saveStockReport(){
    let sData = {          
      "shop_code": localStorage.getItem('shop_code')
    }
    this.cashRegisterService.saveStockReport(sData).subscribe((res) => {
      let resData = res.json();
      if(resData.status === 0){}
    }, err =>{
      console.log(err);
    });
  }


  closeSidebar(routing: string) {
    if (routing !== '') this.router.navigate(['pages/' + routing]);
    setTimeout(() => {
      this.show = false;
      this.state.emit(this.show);
    }, 100);
  }

}
