import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'selectObjectFilter'})
export class SelectObjectFilterPipe implements PipeTransform {
	transform(items: any[], criteria: any): any {
		if(criteria === 'all' || !criteria) {
			return items;
		} else {
			return items.filter(item => {				
				if(item.name && item.name.toLowerCase().indexOf(criteria.toLowerCase()) != -1) {
					return item;
				}
			});
		}
	}	
}
