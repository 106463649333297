import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Dish } from '../dish';
import { remove,uniq,difference } from 'lodash-es';
import { GeneralHelper } from '../helpers/genral-helper';
import { ShopCategoriesItemsService } from '../helpers/services/shop-categories-items.service';
import { MessageService } from 'primeng/api';
import { StateGuardService } from '../state-guard.service';

@Component({
  selector: 'app-variants-modal',
  templateUrl: './variants-modal.component.html',
  styleUrls: ['./variants-modal.component.less'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        transform: 'translateY(-100%)'
      })),
      state('active', style({
        transform: 'translateY(0%)'
      })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ]),
    trigger('fadeOverlay', [
      state('inactive', style({
        opacity: '0'
      })),
      state('active', style({
        opacity: '1'
      })),
      transition('inactive => active', animate('150ms ease-in')),
      transition('active => inactive', animate('150ms ease-out'))
    ])
  ]
})
export class VariantsModalComponent implements OnInit {

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() doneEvent: EventEmitter<any> = new EventEmitter();
  @Output() addingDish: EventEmitter<Dish> = new EventEmitter();
  @Input('dish') dish: any;
  @Input('isOnlyModifiers') isOnlyModifiers: boolean;

  total_price:any;
  tempSelectedItemPrice:any;
  selectedModifierList:any;
  currencySymbol:any;
  isLoadingShow:boolean= false;
  quantity: string;

  add_modifier: boolean;
  is_selected_variant: boolean = false;

  anim_state: string = 'inactive';
  variants: any = [];
  modifiers: any = [];
  isBarcodeVariant:boolean = false;
  exclusive_selective_modifier_group = [];
  can_be_sold_loose: boolean = false;
  constructor(private shopCategoryItemService: ShopCategoriesItemsService,
              private messageService:MessageService,
              private stateGuard: StateGuardService) { }

  ngOnInit() {
    var self = this;
    setTimeout(() => {
      self.anim_state = 'active';
    }, 150);

    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shop_config.currency;

    if(!this.isOnlyModifiers){
      for (var k in this.dish.variant_attributes) {
        var variantObject = {
          name: k,
          selected: '',
          options: this.dish.variant_attributes[k]
        };

        if(this.dish.selected_variant_attributes && this.dish.isEditFromcart){
          for (var j in this.dish.selected_variant_attributes) {
            if(j == k){
              if(Array.isArray(this.dish.variant_attributes[k])){
                this.dish.variant_attributes[k].forEach( attr =>{
                  if(attr.attribute_code == this.dish.selected_variant_attributes[j].attribute_code){
                    this.is_selected_variant = true;
                    //this.selectVariantAttribute(this.dish,j,this.dish.selected_variant_attributes[j]);
                    variantObject.selected = this.dish.selected_variant_attributes[j].name;
                  }
                })
              }
            }
          };
        }
        this.variants.push(variantObject);
      }
    }

    // console.log("this.dish.modifier_attributes:: "+JSON.stringify(this.dish.modifier_attributes))

    this.getVariantSpecificModifiers(this.dish);
    if(this.dish.item_count>0) {
      this.quantity = JSON.parse(JSON.stringify(this.dish.item_count));
    }

    if(this.isOnlyModifiers){
      this.modifiers = [];
      this.can_be_sold_loose = (this.dish.can_be_sold_loose) ? true : false;
      for (var k in this.dish.modifier_attributes) {
        var modifierObject = {
          name: k,
          options: []
        };

        this.dish.modifier_attributes[k].forEach(mod => {
          mod.remove = false;
          mod.selected = false;
          mod.count = 0;
          mod.price = mod.unit_price;

          if(mod.is_selection_exclusive) {
            this.exclusive_selective_modifier_group.push(mod.group_name);
          }
            modifierObject.options.push(mod);
        });

          this.modifiers.push(modifierObject);
      }
    }


    if(this.dish.selected_variant_attributes && this.dish.isEditFromcart){
      this.total_price = 0;

      this.tempSelectedItemPrice = 0;
      if(this.dish.selected_variant_item && this.dish.isEditFromcart){
        this.can_be_sold_loose = (this.dish.selected_variant_item .can_be_sold_loose) ? true : false;

        this.quantity = this.dish.selected_variant_item.item_count;
        let item_count = parseFloat(this.quantity);
        this.total_price +=  (this.dish.selected_variant_item.unit_price * item_count);
        this.tempSelectedItemPrice = this.dish.selected_variant_item.unit_price;
        if(this.dish.selected_variant_item.attributes && this.dish.selected_variant_item.attributes[0]){
          this.dish.selected_variant_item.attributes.forEach( data =>{
            this.total_price +=  (data.price);
          });
        }
      }
    }
    //for variant items scanned using barcode
    if(this.stateGuard.obtain('autocomplete.component:isBarcodeVariant').isBarcodeVariant){
      this.isBarcodeVariant = true;
      let variantAttributes = this.stateGuard.obtain('autocomplete.component:isBarcodeVariant').variant_attributes;

      variantAttributes.forEach((attr_code) => {
        let selectedAttr;
        for(var x in this.dish.attributes) {
          if(Array.isArray(this.dish.attributes[x]) && variantAttributes && variantAttributes.length>0) {
            selectedAttr = this.dish.attributes[x].find((a:any) => variantAttributes.includes(a.attribute_code));
          }      
        }
      
        if(selectedAttr) {
          this.variants.forEach(variant => {
            if(variant.selected == "" && variant.name == selectedAttr.group_name){
              variant.options.forEach(option => {
                // console.log("option.name:"+option.name+ "  name:"+name);
                if(option.name == selectedAttr.name){
                  variant.selected = option.name;
                  this.selectVariantAttribute(this.dish,option.group_name,option);
                }
              })
            }
          })
        }
      })        

      if(this.modifiers.length > 0 && !this.isOnlyModifiers){
        //if variant has modifiers
        this.add_modifier = true;
      }else{
        //if variant doesn't have modifiers
        this.done();
      }

      this.stateGuard.entrust('autocomplete.component:isBarcodeVariant', {isBarcodeVariant:false , variant_name:""});
    }
    // console.log("updated isBarcodeVariant:"+this.isBarcodeVariant);

  }

  close() {
    setTimeout(() => {
      this.closeEvent.emit();
    }, 200);
    this.anim_state = 'inactive';
  }

  done() {
    var modifierArray = [];
    this.modifiers.forEach(element => {
      element.options.forEach(attr => {
        if (attr.count > 0 || attr.remove) {
          modifierArray.push(attr);
        }
      });
    });

    if(this.isOnlyModifiers){
      this.dish.attributes = modifierArray;
      this.dish.item_count = (this.quantity) ? parseFloat(this.quantity) : 1 ;
      if(this.dish.tempData) {
        this.dish.tempData.temp_item_count = JSON.parse(JSON.stringify( this.dish.item_count ));
      }

      if(this.dish.item_count>this.dish.tracking_batch_serial_item_quantity) {
        this.messageService.add({ severity: 'error', summary: 'only '+this.dish.tracking_batch_serial_item_quantity +" item available for tracking number " + this.dish.tracking_number, detail: '' });
      } else {
        this.checkExclusiveSelectiveAttributeAdded().then(result => {
          if(result) {
            this.checkMinimumMaximumAttribteCondition().then(mmcresult => {
              if(mmcresult) {
                var self = this;
                setTimeout(() => {
                  this.doneEvent.emit(this.dish);
                }, 200);
                this.anim_state = 'inactive';
              }
            });
          }
        })
      }
    }else{
      if(this.dish.selected_variant_item) { this.dish.selected_variant_item.attributes = modifierArray; this.dish.selected_variant_item.item_count = (this.quantity) ? parseFloat(this.quantity) : 1 ;}
      if(this.dish.selected_variant_item.item_count>this.dish.selected_variant_item.tracking_batch_serial_item_quantity) {
        this.messageService.add({ severity: 'error', summary: 'only '+this.dish.selected_variant_item.tracking_batch_serial_item_quantity +" item available for tracking number " + this.dish.selected_variant_item.tracking_number, detail: '' });
      } else {
        this.checkExclusiveSelectiveAttributeAdded().then(result => {
          if(result) {
            this.checkMinimumMaximumAttribteCondition().then(mmcresult => {
              if(mmcresult) {
                var self = this;
                setTimeout(() => {
                  this.doneEvent.emit(this.dish);
                }, 200);
                this.anim_state = 'inactive';
              }
            })
          }
        })
      }
    }
  }

  checkExclusiveSelectiveAttributeAdded() {
    return new Promise((resolve,reject)=> {
        var not_selected_group = JSON.parse(JSON.stringify(this.exclusive_selective_modifier_group));
        remove(not_selected_group,group_name => {
          var flag = false;
          var tempAttributes = (this.dish.selected_variant_item) ? this.dish.selected_variant_item.attributes : this.dish.attributes;

          if(Array.isArray(tempAttributes)) {
            var dalen = tempAttributes.length;
            for(var i=0;i<dalen;i++) {
              if(tempAttributes[i].group_name == group_name) {
                flag = true;
                break;
              }
            }
          }

          return flag;
        });

        if(not_selected_group.length>0) {
          this.messageService.add({ severity: 'error', summary: "select at least one modifier from group -> "+ not_selected_group.join(""), detail: '' });
          return resolve(false);
        } else {
          return resolve(true);
        }
    })
  }

  checkMinimumMaximumAttribteCondition() {
    return new Promise((resolve,reject)=> {
        let temp_dish_v = (this.dish.selected_variant_item) ? this.dish.selected_variant_item : this.dish;
        if(!temp_dish_v.minimum_unique_attribute_selection && !temp_dish_v.maximum_unique_attribute_selection) {
          return resolve(true);
        }

        var flag = false;
        var unique_attr = (Array.isArray(temp_dish_v.attributes)) ? uniq(temp_dish_v.attributes.map(attr => {return attr.attribute_code; })) : []
        if(temp_dish_v.minimum_unique_attribute_selection && unique_attr.length < temp_dish_v.minimum_unique_attribute_selection) {
          this.messageService.add({ severity: 'error', summary: "select  minimum "+ temp_dish_v.minimum_unique_attribute_selection +" modifier", detail: '' });
          return resolve(false);
        } else if(temp_dish_v.maximum_unique_attribute_selection && temp_dish_v.maximum_unique_attribute_selection > 0 && unique_attr.length > temp_dish_v.maximum_unique_attribute_selection) {
          this.messageService.add({ severity: 'error', summary: "select  maximum "+ temp_dish_v.maximum_unique_attribute_selection +" modifier", detail: '' });
          return resolve(false);
        } else {
          return resolve(true);
        }
    })
  }

  changeCountOnClickOfRect(event,option) {
    if (["increaseModifier","decreaseModifier"].indexOf(event.target.id) != -1) {
      return;
    }
    this.changeCount(option,'plus')
  }

  changeCount(option, value) {
    var self = this;
    if (value == 'plus') {
      option.count++;
      option.price = option.unit_price * option.count
    } else {
      if (option.count > 0) {
        option.count--;
      }
      if (option.count == 0) {
        setTimeout(() => {
          option.selected = false;
        }, 30);
      }
    }

    option.old_count = JSON.parse(JSON.stringify(option.count));

    setTimeout(() => {
      self.selectModifier(this.modifiers)
    }, 50);
  }

  // Function to add dish into cart
  addDish(item: any) {

    var modifierArray = [];
    this.modifiers.forEach(element => {
      element.options.forEach(attr => {
        if (attr.count > 0 || attr.remove) {
          modifierArray.push(attr);
        }
      });
    });
    //item.item_count = (this.quantity) ? this.quantity : 1
    if(item.selected_variant_item) {
      item['selected_variant_item'].item_count = parseFloat(this.quantity);
      if(modifierArray.length > 0){
        item['selected_variant_item'].attributes = modifierArray.sort((a,b) => {return (a.remove - b.remove)});
      }else{
        item['selected_variant_item'].attributes = [];
      }
    }
    //this.addingDish.emit(item);
    // }
  }

  changeQuantity(e:any){
    this.quantity = e;
    this.total_price = this.tempSelectedItemPrice * parseFloat(e);
    this.selectModifier(this.modifiers)
  }

  selectModifier(modifiers:any){

    if(Array.isArray(modifiers)){
      let total =0;
      modifiers.forEach(data =>{
        if(data.options && data.options.length>0){
          data.options.forEach(modifier =>{
            if(modifier.selected){
              let p = (typeof modifier.unit_price == 'string')? parseFloat(modifier.unit_price) :modifier.unit_price;
              let c = (typeof modifier.count == 'string')? parseFloat(modifier.count) :modifier.count;
              total = total + (p*c);
            }
          });
        }
      });

      let q = (typeof this.quantity == 'string')? parseFloat(this.quantity) : this.quantity;
      q = (typeof q == 'undefined' || typeof q === undefined)? 1 : q;

      if(this.isOnlyModifiers){
        let p = (typeof this.dish.unit_price == 'string')? parseFloat(this.dish.unit_price) : this.dish.unit_price;
        this.total_price = q * p + total;
      }else{
        this.total_price = q * this.tempSelectedItemPrice + total;
      }
    }
  }

  // select a variant attribute
  selectVariantAttribute(item, attributeGroup: any, attr: any) {
    this.is_selected_variant = true;
    if (typeof (item['selected_variant_attributes']) === 'undefined') {
      var data = {
        [attributeGroup]: attr
      };
      item['selected_variant_attributes'] = data;
    }
    var selectedAttributes = item['selected_variant_attributes'];
    selectedAttributes[attributeGroup] = attr;

    item['selected_variant_item'] = this.selectVariantItem(item);
    if(item.selected_variant_item) {
      item['selected_variant_item']['attributes'] = [];
    }
    if(item['selected_variant_item']) {
      this.getVariantSpecificModifiers(item);
      this.can_be_sold_loose = (item['selected_variant_item'].can_be_sold_loose) ? true : false;
      let unit_price:any = (item['selected_variant_item']['unit_price'])? item['selected_variant_item']['unit_price'] :0
      //this.addDish(item);
      let tempPrice = (typeof unit_price == 'string')? parseFloat(unit_price) : unit_price;
      this.tempSelectedItemPrice = tempPrice;
      let q = (typeof this.quantity == 'string')? parseFloat(this.quantity) : this.quantity;
      q = (typeof q == 'undefined' || typeof q === undefined)? 1 : q;
      this.total_price = q * tempPrice;
      this.selectModifier(this.modifiers);
    }
  }

  getVariantSpecificModifiers(item){

    // console.log("ITEM after selectVariantItem:: "+ JSON.stringify(item));
    // console.log("dish.modifier_attributes: " + JSON.stringify(this.dish.modifier_attributes))
    this.modifiers = [];
    if(item.selected_variant_item){
    var selected_variant_item_with_attrs = item.item_list.filter(itm => itm.item_code == item['selected_variant_item'].item_code)
    // if(selected_variant_item_with_attrs.length > 0){
      selected_variant_item_with_attrs = selected_variant_item_with_attrs[0]

      for (var k in this.dish.modifier_attributes) {
        var modifierObject = {
          name: k,
          options: []
        };

        this.dish.modifier_attributes[k].forEach(mod => {
          if(selected_variant_item_with_attrs.attributes.filter(attr => mod.attribute_code == attr.attribute_code).length > 0){

            if(item.isEditFromcart){
              var isModSelected = false;
              item.selected_variant_item.attributes.forEach( modifier =>{
                if(modifier.attribute_code == mod.attribute_code){
                  mod.remove = modifier.remove;
                  mod.selected = modifier.selected;
                  mod.count = modifier.count;
                  mod.price = modifier.price;
                  isModSelected = true;
                  // console.log("mod.count 111 in::: "+ mod.count)
                  // modifierObject.options.push(mod);
                }
              });
              if(!isModSelected){
                mod.remove = false;
                mod.selected = false;
                mod.count = 0;
                // console.log("mod.count 111 out::: "+ mod.count)
                mod.price = mod.unit_price;
              }
            }else{
              mod.remove = false;
              mod.selected = false;
              mod.count = 0;
              // console.log("mod.count 222::: "+ mod.count)
              mod.price = mod.unit_price;
            }
            if(mod.is_selection_exclusive) {
              this.exclusive_selective_modifier_group.push(mod.group_name);
            }
            modifierObject.options.push(mod)
          }
        })

        if(modifierObject.options.length > 0){
          this.modifiers.push(modifierObject);
        }
    }

    }

  }

  // select a variant item from the selected variant item list
  selectVariantItem(item) {
    try {
      var selectedAttributes = GeneralHelper.values(item['selected_variant_attributes']).map((itm: any) => itm.attribute_code);
      var selectedItem = item['item_list'].filter((itm: any) => {
        return difference(selectedAttributes, itm.attributes.filter((x: any) =>
          x.is_variant_attribute).map((x: any) => x.attribute_code)).length === 0;
      });

      //added logic to handle item in unbundled is true;
      //we get 2 items in selected item list so we need to sort it.
      if(selectedItem.length > 1){
        selectedItem = selectedItem.sort((a,b) => { return a.can_be_unbundled-b.can_be_unbundled });
      }

      if(selectedItem.length == 0) {
        selectedItem = item['item_list'];
      }

      if(this.stateGuard.obtain('autocomplete.component:isBarcodeVariant').isBarcodeVariant){
        let barcode = this.stateGuard.obtain('autocomplete.component:isBarcodeVariant').bar_code;
        for(let i = 0 ; i < selectedItem.length ; i++){
          if(selectedItem[i].bar_code == barcode){
            return JSON.parse(JSON.stringify(selectedItem[i]));
          }
        }
      }else{
        // console.log("selectedItem[0]==>"+JSON.stringify(selectedItem[0]))
        return JSON.parse(JSON.stringify(selectedItem[0]));
      }
    } catch (e) {
      console.log('to catch exception');
      return null;
    }
  }

  unbundleItem(item:any) {
    this.isLoadingShow = true;
    this.shopCategoryItemService.unbundleItem({shop_code:localStorage.getItem('shop_code'), item_code:item.item_code}).subscribe((res: any) => {
      this.isLoadingShow = false;
      let body = JSON.parse(res._body);
      if(body.status == 0 && body.result && body.result.unbundled_items && body.result.unbundled_items.length > 0)  {
        let newItemList = [];
        this.dish.item_list.forEach(tempItem => {
          body.result.unbundled_items.forEach(changedItem => {
            if(tempItem.item_code == changedItem.item_code) {
              tempItem.quantity = changedItem.quantity;
            }
          })
          newItemList.push(tempItem);
        });
        this.dish.item_list = newItemList;
        this.updateStockQuantity(body.result.unbundled_items);
        this.messageService.add({ severity: 'success', summary: 'Item has been unbundled successfully, stock updated!', detail: '' });
        return;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Item not unbundled successfully', detail: '' });

      }
    },err => {
      this.isLoadingShow = false;
      this.messageService.add({ severity: 'error', summary: 'Item not unbundled successfully', detail: '' });
    })
  }

  updateStockQuantity(soldItem: any) {
    var currentCategoryInfo = JSON.parse(localStorage.getItem('current_category_info')) || null;
    if(currentCategoryInfo && currentCategoryInfo.category_code && soldItem && Array.isArray(soldItem)) {
        var currentItems: any = JSON.parse(localStorage.getItem(currentCategoryInfo.category_code)) || [];
        if(Array.isArray(currentItems)){
          for (var tempItem of currentItems) {
            soldItem.forEach(tempSoldItem => {
              if (tempItem.item_code === tempSoldItem.item_code) {
                tempItem.quantity = tempSoldItem.quantity;
              }
            });
          }
        }
        localStorage.setItem(currentCategoryInfo.category_code, JSON.stringify(currentItems));
    }
  }

  getCSSOfVariantModifier(option) {
    if(option && option.name) {
      if(option.name.length < 20) {
        if(window.innerWidth <=1500) {
          return "col-sm-4 col-md-4";
        } else {
          return "col-sm-4 col-md-3";
        }        
      } else if(option.name.length < 30) {
        return "col-sm-6 col-md-4";
      } else if(option.name.length < 50) {
        return "col-sm-6 col-md-6";
      } else if(option.name.length < 60){
        return "col-sm-8 col-md-7";
      } else {
        return "col-sm-8 col-md-8";
      }
    } else {
      return "col-sm-4 col-md-3";
    }
  }
}