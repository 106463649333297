import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cash-register-closure-summary',
  templateUrl: './cash-register-closure-summary.component.html',
  styleUrls: ['./cash-register-closure-summary.component.less']
})
export class CashRegisterClosureSummaryComponent implements OnInit {

  public all_session_list:any[] =[];
  public isClosureSummaryShow:boolean = false;
  open_view: boolean[] = [];
  open_main_view: boolean[] = [];
  currencySymbol:any;

  constructor() { }

  ngOnInit() {
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration')) || {};
    this.currencySymbol = shop_config.currency;
    this.getAllSessionList();
  }

  getAllSessionList(){
    this.all_session_list = JSON.parse(localStorage.getItem('all_cash_register_sessions')) || [];
    if(this.all_session_list.length>0){
      this.isClosureSummaryShow = true;
    }
  }

  
}
