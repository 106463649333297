import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { RecentOrdersService } from '../../helpers/services/recent-orders.service';

import {CurrencyPipe} from '@angular/common'
import { HttpClient} from '@angular/common/http';
import { findLast,sumBy } from 'lodash-es';


@Component({
  selector: 'app-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.sass'],
  providers:[CurrencyPipe]
})
export class OrderInvoiceComponent implements OnInit {

  @Input('order') order: any;  
  @Input('currencySymbol') currencySymbol: any = "INR";

	@Output() closePopup: EventEmitter<any> = new EventEmitter();

  loading: boolean = false;  
	bodyTag = document.getElementsByTagName("body")[0];

  title_text: any = "";
  selected_invoice_type;
  isVehicleLabelSubmitted = false;
  vehicle_label = "";
  shop_name = localStorage.getItem("shop_name") || "";
  shop_gstin = "";
  shopSettings = JSON.parse(localStorage.getItem("shop_configuration")) || null;
  constructor(
    public recentOrdersService:RecentOrdersService,
    public http:HttpClient,
    private cp: CurrencyPipe) { }

  ngOnInit() {
  	this.bodyTag.setAttribute("class", "overflow-hidden");    
    this.shop_gstin = this.shopSettings && this.shopSettings.shop_info && this.shopSettings.shop_info.gstin  ? this.shopSettings.shop_info.gstin : "";
    
    this.order.isItemWiseTaxAvailable = (this.shopSettings&& this.shopSettings.item_wise_tax) ? true : false;
    this.order.taxNames = [];
    this.order.mergedTax = [];
    this.order.total_discount = (this.order.order.discount ? this.order.order.discount : 0) + (this.order.order.total_other_discount_amount ? this.order.order.total_other_discount_amount : 0)
    if(this.order.order.tax_breakup && this.order.order.tax_breakup.length > 0) {      
      this.order.mergedTax = this.order.order.tax_breakup;
      this.order.taxNames = this.order.order.tax_breakup.map(tax => tax.breakup_name);       
    } else if((this.order.order.tax_breakup || this.order.order.tax_breakup.length == 0) && (this.order.order.group_tax && this.order.order.group_tax.length > 0)) {
      this.order.mergedTax = this.order.order.group_tax;
      this.order.taxNames = this.order.order.group_tax.map(tax => tax.breakup_name); 
    }

    this.vehicle_label = this.order.order.vehicle_label ? this.order.order.vehicle_label : "";
    /*this.addChargeTotal();    
    this.calculateOrderValue();*/
    /*this.loading = true;*/
  }

  hidePopup() {
    this.closePopup.emit(this.order);
    this.bodyTag.setAttribute("class", "scrolling-view");
    this.selected_invoice_type="";
  }

  productTotal(item) {    
    var attr_total = 0;
    var tax_total = 0;
    if(item.attributes && Array.isArray(item.attributes)) {
      item.attributes.forEach(attr => {
        attr_total += attr.count && attr.unit_price ? (attr.count * attr.unit_price) : 0;
      });
    }

    if(item.tax_breakup && item.tax_breakup.length > 0) {
      item.tax_breakup.forEach(tax => {
        tax_total += tax.value;
      });
    }
    
    return (((item.item_price) ? parseFloat(item.item_price)  - ((item.discount) ? parseFloat(item.discount) : 0) : 0) * item.item_count) + tax_total + attr_total;    
  }

  getItemTaxableValue(tax_info) {
    var total = 0;
    if(tax_info && tax_info.breakup) {
      for(var x in tax_info.breakup) {
        total+= tax_info.breakup[x].value;
      }
    }
    return total;
  }


  getTotalTaxData(tax_name) {
    let taxInfo = findLast(this.order.mergedTax, (tax) => { return tax.breakup_name == tax_name; });
    if(taxInfo) {
      return parseFloat(taxInfo.value);
    } else{
      return "0";
    }
  }

  getItemTotalTaxValue(item) {
    var total = 0;
    if(item.tax_breakup && item.tax_breakup.length > 0) {
      item.tax_breakup.forEach(tax => {
        total += tax.value;
      });
    }
    return total;
  }

  getItemTaxData(item,tax_name) {
    var total = 0;
    if(item.tax_breakup && item.tax_breakup.length > 0) {
      let taxInfo = findLast(item.tax_breakup, (tax) => { return tax.breakup_name == tax_name; });
      if(taxInfo) {        
        return this.cp.transform(taxInfo.value,this.currencySymbol, 'symbol-narrow','2.2') + " @ " + taxInfo.rate + "%" ;
      } else{
        return "0";
      }
    }
    return total;
  }


  getTotalItemQuantity() {    
    var tic = sumBy(this.order.items, function(item:any) {return item.item_count});
    return tic;
  }

  saveVehiclLabel() {
    this.loading = true;
    this.recentOrdersService.changeOrderVehicleDetail({order_id:this.order.order.order_id,shop_code:localStorage.getItem('shop_code'),vehicle_label:this.vehicle_label})
    .subscribe((res:any) => {
        let result:any = res.json();
        this.isVehicleLabelSubmitted=true;
        this.loading = false;
        if (result.status!=0) {
          alert((result.message)? result.message : "internal server error while saving vehicle number");
        } else {
          this.order.order.vehicle_label = this.vehicle_label;
          this.chnageOrderDispatchNote(this.order.order.order_id,this.vehicle_label);
        }
      },
      err=> {
        this.isVehicleLabelSubmitted=true;
        this.loading = false;
        console.log(err);
        alert("internal server error while saving vehicle number.");
      }
    );
  }

  chnageOrderDispatchNote(id,note){
    let orders = JSON.parse(localStorage.getItem('temp_orders_list')) || [];
    orders.forEach(temp_order =>{
      if(temp_order.order.order_id == id){
        temp_order.order.vehicle_label = note;
      }
    });
    localStorage.setItem('temp_orders_list',JSON.stringify(orders));
  }


  onClickPrint() {
    let printContents, popupWin;
    printContents = document.getElementById('invoice-print').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Invoice</title>
          <link href="styles.css" rel="stylesheet" type="text/css" media="print" />          
          <link href="styles/components.css" rel="stylesheet" type="text/css" media="print" />
          <link href="styles/_print_page.css" rel="stylesheet" type="text/css" media="print" />          
          <script type="text/javascript">
            function onload() {
              setTimeout(function(){window.print(); window.close();}, 200); 
            }
          </script>
        </head>
        <body onload="onload()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  setTitleText() {        
    if(this.selected_invoice_type == "original") {
      this.title_text = 'Original Invoice';
    } else if(this.selected_invoice_type == "duplicate") {
      this.title_text = 'Duplicate for Transporter (Delivery Note)';
    } else if(this.selected_invoice_type == "triplicate") {
      this.title_text = 'Triplicate Invoice for Supplier';
    } else {
      this.title_text = "Invoice";
    }    
  }

}
