import { Component, OnInit, Output, EventEmitter, OnDestroy, NgZone, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentHelper } from '../helpers/payment-helper';
import { StateGuardService } from '../state-guard.service';
import { SaveOrderService } from '../helpers/services/save-order.service';
import { MakePaymentService } from '../helpers/services/make-payment.service';
import { MembershipService } from '../helpers/services/membership.service';
import { CashRegisterService } from '../helpers/services/cash-register.service';
import { SpecialOrderService } from '../helpers/services/special-order.service';

import { RecentOrdersService } from '../helpers/services/recent-orders.service';
import { DiaryService } from '../helpers/services/diary.service';
import { CustomerService } from '../helpers/services/customer.service';
import { Messenger } from '../messenger.service';
import { CustomerMessage } from '../customer-message.service';
import { APIHelper } from '../helpers/api-helper';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SaveDiaryModalComponent } from '../save-diary-modal/save-diary-modal.component';
import { ConfigsBoxComponent } from '../configs-box/configs-box.component';
import { BasketComponent } from '../basket/basket.component';
import { BillNumberModalComponent } from '../bill-number-modal/bill-number-modal.component';
import { OrderNoteModalComponent } from '../order-note-modal/order-note-modal.component';
import { KotHelper } from '../helpers/kot-helper';
import { CaptainAppHelper } from '../helpers/captain-app-helper';
import { RewardPointMessage } from '../../pages/reward-points.message.service';
import { Subscription } from 'rxjs';
import { CommonService } from '../helpers/services/common.service';
import { MessageService } from 'primeng/api';
import { BaseConfig } from '../helpers/api-config';
import { SeparateBillHelper } from '../helpers/Separate-bill-helper';
import { DiscountHelper } from '../helpers/discount-helper';
import { PrintHelper } from '../helpers/print-helper';
import { sumBy, min } from 'lodash-es';
import { GeneralHelper } from '../helpers/genral-helper';

import 'assets/js/epos-print-3.0.0.js';
import * as dot from 'dot';

declare var $: any;
declare var epson: any;

import { ScreenKeysDirective } from '../screen-keys.directive';
@Component({
  selector: 'app-payment-component',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit, OnDestroy {
  paymentWay: any;
  public paymentHelper: PaymentHelper;
  public modalRef: BsModalRef;
  public subscriptions: Subscription[] = [];
  public subscription: Subscription;
  orderDetail: any = {
    deducted_diary_amt: 0
  };
  currentUnit;
  userContactNo: any;
  currencySymbol: any;
  is_customer_found: boolean = false;
  isPaymentInstanceCalled: boolean = false;
  clearAmountFlag: boolean = false;
  currentSlideIndex: number = 0;
  currentSlide: any;
  comingSlide: any;
  currentConsumer: any = {
    diary_amt: 0,
    rewards_points: 0
  };
  rewards_points: any;
  clearSaveToDiaryOrderDetails: any;
  selectedOrderId: any;
  slider: any;
  isSingleCheckout: string = null;
  message: any = { text: '' };
  money: string = "";
  reward_amount: string = "";
  rewardReason: string;
  isRewardEnable: boolean = true;
  invoice = {
    amount: 1301
  }
  is_online: boolean = false;
  is_available_s_t_d: boolean = false;
  payedEnough: boolean = false;
  shopConfig: any = {};
  groupedPaymentTypes: any = [];
  defaulyDiaryAmount: number = 0;
  builder;
  shopConfiguration;
  merchantProfile;
  popupWin;
  printBillFlag = false;
  currentCustomerInfo;
  extraCharges = [];

  paymentTypes: any = [
    {
      name: 'cash',
      sub_class: [],
      code: APIHelper.CASH_IN_HAND_TYPE,
      img: 'assets/images/payments/cash.png',
      img_active: 'assets/images/payments/cash-active.png'
    },
    {
      name: 'card',
      sub_class: [],
      code: APIHelper.CARD_PAYMENT_TYPE,
      img: 'assets/images/payments/card.png',
      img_active: 'assets/images/payments/card-active.png'
    }];

  /*{
    name: 'reward',
    sub_class: [],
    code:"NA",
    img: 'assets/images/payments/reward.png'
  },{
    name: 'giftcard',
    sub_class: [],
    code:"NA",
    img: 'assets/images/payments/giftcard.png'
  },{
    name: 'coupon',
    sub_class: [],
    code:APIHelper.COUPON_PAYMENT_TYPE,
    img: 'assets/images/payments/coupon.png'
  },*/


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  printerUtility = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private basketComponent: BasketComponent,
    private stateGuard: StateGuardService,
    private messageService: Messenger,
    private ngZone: NgZone,
    private saveOrderService: SaveOrderService,
    private modalService: BsModalService,
    private makePaymentService: MakePaymentService,
    private diaryService: DiaryService,
    private cashRegisterService: CashRegisterService,
    private specialOrderService: SpecialOrderService,
    private rewardPointMessage: RewardPointMessage,
    private recentOrdersService: RecentOrdersService,
    private customerService: CustomerService,
    private configsBoxComponent: ConfigsBoxComponent,
    private customerMessageService: CustomerMessage,
    private commonService: CommonService,
    private messagingService: MessageService,
    private kotHelper: KotHelper,
    private captainAppHelper: CaptainAppHelper,
    private discountHelper: DiscountHelper,
    private printHelper: PrintHelper,
    private cdRef: ChangeDetectorRef,
    private membershipService: MembershipService) {
    if (localStorage.getItem('PRINT_UTILITY')) {
      this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
    }

    this.commonService.notifyPrinterObservable$.subscribe(result => {
      this.printerUtility = result;
    });
  }

  reactOnKeys(parcel) {
    switch (parcel.action) {
      case ('bill'):
        this.printBillFlag = false;
        this.putBillInBackground("other");
        break;
      case ('print'):
        this.printBillFlag = true;
        this.putBillInBackground("other");
        //console.log('print');
        break;
    }
  }

  getTypeCode(name) {
    return APIHelper[name];
  }

  isOtherPaymentType(paymentCode) {
    if (["PAYT0001", "PAYT0002", "PAYT0003", "PAYT0004", "PAYT0005", "PAYT0006"].indexOf(paymentCode) != -1) {
      return false;
    } else {
      return true;
    }
  }

  getRefBillNumAndUserInfo(num, token, phone_number, tempUnit) {
    return new Promise<boolean>((resolve, reject) => {
      this.getRefBillNum(num).then(refResult => {
        resolve(true)
        return;
        /*if(!navigator.onLine) {
          resolve(true);
          return;
        } else if(phone_number && token == APIHelper.DEFAULT_TOKEN){

          let sendData = {
            'mobile_no': ''+phone_number,
            'shop_code':localStorage.getItem('shop_code')
          }
          this.customerService.contact_search(sendData).subscribe((res) => {
            let userData = res.json();
            if(userData.result && userData.result.details && userData.result.details.length>0){
              token = userData.result.details[0].auth_token;
              tempUnit.token = token;
            }
            this.customerService.info(token).subscribe((res) => {
              var result = res.json();
              if (result.status === 0) {
                this.currentConsumer = Object.assign(this.currentConsumer,result.result);
                localStorage.setItem('current_customer_info', JSON.stringify(this.currentConsumer));
                resolve(true)
                return;
              } else {
                alert(result.message);
                this.clearCustomerInfo(false);
                resolve(true)
                return;
              }
            },err =>{
              this.clearCustomerInfo(false);
              resolve(true)
              return;
            });
          });

        } else {
          this.customerService.info(token).subscribe((res) => {
            var result = res.json();
            if (result.status === 0) {
              this.currentConsumer = Object.assign(this.currentConsumer,result.result);
              localStorage.setItem('current_customer_info', JSON.stringify(this.currentConsumer));
              resolve(true)
              return;
            } else {
              alert(result.message);
              this.clearCustomerInfo(false);
              resolve(true)
              return;
            }
          },err =>{
            this.clearCustomerInfo(false);
            resolve(true)
            return;
          });

        }*/
      })
        .catch(e => {
          resolve(true)
          return;
        })
    });
  }

  getRefBillNum(num) {
    return new Promise<boolean>((resolve, reject) => {
      if (!num || num == -1) {
        this.modalRef = this.modalService.show(BillNumberModalComponent, { class: 'centred-modal' });
        this.modalRef.content.decision = "";
        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
          //if (this.modalRef.content.decision === 'done') {
          let seletedNumber = (this.modalRef.content.selectedNumber) ? this.modalRef.content.selectedNumber : 1;
          localStorage.setItem('ref_bill_num', seletedNumber);
          localStorage.setItem('temp_orders_list', '[]');
          let date = new Date()
          var dd = date.getDate() + '';
          var mm = (date.getMonth() + 1) + '';
          var yyyy = date.getFullYear();
          if (dd.length == 1) { dd = '0' + dd }
          if (mm.length == 1) { mm = '0' + mm }
          /*let str = yyyy+'/'+mm+'/'+dd + " "+;*/
          localStorage.setItem('ref_bill_update_date', date.toISOString());
          //}
          this.unsubscribe();
          resolve(true)
          return;
        }));
      } else {
        resolve(true)
        return;
      }
    })
  }

  getOrderNotes() {
    return new Promise<any>((resolve, reject) => {
      var local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
      if (local_config && local_config.enable_order_note) {
        this.modalRef = this.modalService.show(OrderNoteModalComponent, { class: 'centred-modal' });
        this.modalRef.content.decision = "";
        this.modalRef.content.note = "";
        var cSub: Subscription = this.modalService.onHide.subscribe(() => {
          cSub.unsubscribe();
          resolve(this.modalRef.content.note)
          return;
        });
      } else {
        resolve("");
      }
    })
  }

  putBillInBackground(type) {
    return new Promise<boolean>((resolve, reject) => {

      if (type == "cash" || type == "card") {
        this.printBillFlag = true;
      }

      // CHECK if there are any printers for "self-service",
      //  IF YES then create a kot object and send the kot/s print to the self-service printer/s,
      //  ELSE do nothing


      if (!this.isPaymentInstanceCalled) {
        this.callPaymentInstance();
        this.isPaymentInstanceCalled = true;
      }

      if (this.clearAmountFlag) {

        this.clearAmountFlag = false;
        let amount = 0;
        let payment_details = {};
        this.orderDetail.payment.forEach(payment => {
          if (!payment.isRemovable) {
            if (payment_details[payment.code]) {
              payment_details[payment.code].amount += payment.total
            } else {
              if (payment.is_sub_class) {
                payment_details[payment.code] = { name: payment.type, amount: payment.total, payment_sub_class_info: [] }
              } else {
                payment_details[payment.code] = { name: payment.name, amount: payment.total, payment_sub_class_info: [] }
              }
            }

            if (payment.is_sub_class) {
              payment_details[payment.code]["payment_sub_class_info"].push({ "sub_class_name": payment.name, amount: payment.total })
            }
            amount += payment.total;
          }
        });

        let sendData = {
          'mobile_no': (typeof this.userContactNo == 'string') ? parseInt(this.userContactNo) : this.userContactNo,
          'shop_code': localStorage.getItem('shop_code')
        }

        let auth_token = ""
        this.recentOrdersService.contact_search(sendData).subscribe((res) => {
          let ordersData = res.json();

          if (ordersData.result) {
            if (ordersData.result.details.length > 0) {
              auth_token = ordersData.result.details[0].auth_token;
            } else {
              let current_customer = JSON.parse(localStorage.getItem('current_customer_info'));
              auth_token = current_customer.auth_token;
            }

            this.clearCustomerInfo(true);
            let loggedeInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};

            let sData = {
              'diary': {
                'diary_amount': (amount * (-1)),
                'comment': 'clear',
                'payment_details': payment_details,
                'is_clear': true,
                'shop_code': localStorage.getItem('shop_code'),
                'order_id': this.selectedOrderId,
                'auth_token': auth_token,
                'shop_user_id': loggedeInUser.id
              }
            }
            this.recentOrdersService.clearPendingAmount(sData).subscribe((res) => {
              let ordersData = res.json();
              if (ordersData.status === 0) {
                if (payment_details) {
                  for (var key in payment_details) {
                    if (payment_details.hasOwnProperty(key) && (key == APIHelper.CASH_ON_DELIVERY_TYPE || key == APIHelper.CASH_IN_HAND_TYPE)) {
                      let sendList = []
                      let obj = {
                        'payment_code': key,
                        'amount': payment_details[key].amount
                      }
                      sendList.push(obj)
                      this.addCashPaymentForCashRegister(sendList);
                    }
                  }
                }

                let temp_orders = JSON.parse(localStorage.getItem('temp_orders_list')) || [];
                for (var i = 0; i < temp_orders.length; ++i) {
                  if (temp_orders[i].order_id == this.selectedOrderId) {
                    let tempDairyHistory = {
                      order_id: this.selectedOrderId,
                      comment: 'clear',
                      title: 'Cleared amount',
                      diary_amount: (amount * (-1)),
                      is_clear: true,
                      payment_details: payment_details,
                    }
                    temp_orders[i].diary_history.push(tempDairyHistory);

                    delete temp_orders[i].order.split_payment_details[APIHelper.SAVE_TO_DIARY_TYPE];

                    if (payment_details) {
                      for (var key in payment_details) {
                        if (payment_details.hasOwnProperty(key)) {

                          if (Array.isArray(payment_details[key].payment_sub_class_info) && payment_details[key].payment_sub_class_info.length > 0) {

                            payment_details[key].payment_sub_class_info.forEach(data => {
                              let tempPaymentDetail = {
                                title: data.sub_class_name,
                                amount: data.amount,
                                payment_code: key
                              }

                              let is_found = false;
                              temp_orders[i].order.split_payment.forEach(payment => {
                                if (payment.title == tempPaymentDetail.title) {
                                  payment.amount += tempPaymentDetail.amount;
                                  is_found = true;
                                }
                              })

                              if (!is_found) {
                                temp_orders[i].order.split_payment.push(tempPaymentDetail);
                              }

                            });
                          } else {
                            let obj = {
                              title: payment_details[key].name,
                              name: payment_details[key].name,
                              amount: payment_details[key].amount,
                              payment_code: key
                            }

                            let is_found = false;
                            temp_orders[i].order.split_payment.forEach(paymentData => {
                              if (paymentData.title == obj.title) {
                                paymentData.amount += obj.amount;
                                is_found = true;
                              }
                            })
                            if (!is_found) {
                              temp_orders[i].order.split_payment.push(obj);
                            }
                          }
                        }

                        if (temp_orders[i].order.split_payment_details[key]) {
                          temp_orders[i].order.split_payment_details[key].amount += payment_details[key].amount
                          if (Array.isArray(payment_details[key].payment_sub_class_info) &&
                            payment_details[key].payment_sub_class_info.length > 0) {
                            if (Array.isArray(temp_orders[i].order.split_payment_details[key].payment_sub_class_info) &&
                              temp_orders[i].order.split_payment_details[key].payment_sub_class_info.length > 0) {
                              payment_details[key].payment_sub_class_info.forEach((pt) => {
                                let tLen = temp_orders[i].order.split_payment_details[key].payment_sub_class_info.length;
                                let isF = false;
                                for (var t = 0; t < tLen; t++) {
                                  if (temp_orders[i].order.split_payment_details[key].payment_sub_class_info[t].sub_class_name == pt.sub_class_name) {
                                    isF = true;
                                    temp_orders[i].order.split_payment_details[key].payment_sub_class_info[t].amount += pt.amount;
                                    break;
                                  }
                                }

                                if (!isF) {
                                  temp_orders[i].order.split_payment_details[key].payment_sub_class_info.push({ sub_class_name: pt.sub_class_name, amount: pt.amount });
                                }
                              })
                            } else {
                              temp_orders[i].order.split_payment_details[key].payment_sub_class_info = payment_details[key].payment_sub_class_info;
                            }
                          }
                        } else {
                          temp_orders[i].order.split_payment_details[key] = {
                            title: payment_details[key].name,
                            name: payment_details[key].name,
                            amount: payment_details[key].amount,
                            payment_code: key,
                            payment_sub_class_info: Array.isArray(payment_details[key].payment_sub_class_info) ? payment_details[key].payment_sub_class_info : []
                          };
                        }
                      }
                    }

                    temp_orders[i].diary_history.forEach(diary_payment => {
                      if (diary_payment.comment == 'used') {
                        let obj = {
                          title: 'Pay from diary',
                          amount: diary_payment.diary_amount,
                          payment_code: 'PAY_FROM_DIARY'
                        }
                        temp_orders[i].order.split_payment.push(obj);
                      }
                    })
                  }
                }
                resolve(true);
                localStorage.setItem('temp_orders_list', JSON.stringify(temp_orders));
                this.router.navigate(['/pages/recent-orders']);
              }
            });
          }
        });
        //this.recentOrderComponent.paidClearAmount(this.orderDetail.payment);
      } else {
        let tempUnitData = JSON.parse(JSON.stringify(this.stateGuard.obtain("selects-box.component:currentUnit") || []));
        var membership_item_info;
        if (tempUnitData.visitors && tempUnitData.visitors.length > 0 && tempUnitData.visitors[0].dishes[0].length > 0) {
          for (var i = 0; i < tempUnitData.visitors.length; i++) {
            if (tempUnitData.visitors[i].dishes && tempUnitData.visitors[i].dishes[0].length > 0) {
              tempUnitData.visitors[i].dishes[0].forEach(item => {
                if (item.category_code == "MEMBERSHIP") { membership_item_info = item; }
              })
              if (membership_item_info) { break; }
            }
          }
        }

        if (membership_item_info) {
          //console.log("go for membership enrollment")
          this.membershipPayment(membership_item_info, type).then(result => {
            resolve(result);
          });
        } else {
          // if(localStorage.getItem('isRestaurant') == 'true'){
          if (localStorage.getItem('isSingleCheckoutForCancellOrder') == 'true') {
            let sendIndex = ["all"];
            if (tempUnitData.index || tempUnitData.index == 0) {
              sendIndex = [];
              sendIndex.push(tempUnitData.index);
            }
            this.kotHelper.onClickOT(false, sendIndex).then((res) => {
              this.callBill(type);
            });
          } else {
            this.kotHelper.onClickOT(false, null).then((res) => {
              this.callBill(type);
            });
          }
          /*}else{
            this.callBill(type);
          }*/
          resolve(true);
        }
      }
    })
  }

  membershipPayment(membership_item, type) {
    return new Promise<boolean>((resolve, reject) => {
      let tempUnitData = JSON.parse(JSON.stringify(this.stateGuard.obtain("selects-box.component:currentUnit") || []));
      let tempOrderDetail = JSON.parse(JSON.stringify(this.stateGuard.obtain("payment.component:orderDetail") || {}))
      tempOrderDetail.payment = (tempOrderDetail && tempOrderDetail.payment) ? tempOrderDetail.payment : [];

      var primary_user_id = tempUnitData.userInfo.user_id;
      var secondary_user_id = (Array.isArray(membership_item.secondary_user_ids)) ? membership_item.secondary_user_ids : [];
      let deliveryType = tempUnitData.delivery_type;
      let pay_from_diary_amt = 0;
      let rewards_amount = 0;
      let rewards_pt = 0;
      let save_to_diary_amount = 0;
      let save_to_diary_comment = "";
      let amount = tempOrderDetail.cart_value + tempOrderDetail.cart_tax - tempOrderDetail.discount_amount - tempOrderDetail.total_other_discount_amount;

      if (tempOrderDetail.extraCharges) {
        tempOrderDetail.extraCharges.forEach(charge => {
          amount += charge.value;
        })
      }
      amount -= tempOrderDetail.cash_discount_amount;//cash discount add on post tax value 
      let totalAmount = GeneralHelper.toRound(amount);
      let splitPaymentDetail = {};
      if (type == "cash") {
        tempOrderDetail.payment.push({
          name: 'Cash',
          sub_class: [],
          code: APIHelper.CASH_IN_HAND_TYPE,
          total: Math.abs(totalAmount)
        });
      } else if (type == "card") {
        tempOrderDetail.payment.push({
          name: 'Card',
          sub_class: [],
          code: APIHelper.CARD_PAYMENT_TYPE,
          total: Math.abs(totalAmount)
        });
      }


      tempOrderDetail.payment.forEach(detail => {
        if (detail.code == APIHelper.REWARD_PAYMENT_TYPE) {
          let amount = Math.abs(detail.total);
          let points = 0;
          let ratio = this.shopConfig.rewards_redeemption_gain_unit / this.shopConfig.rewards_redemption_unit;
          points = amount / ratio;
          rewards_amount = amount;
          rewards_pt = points;
        }
      })
      tempOrderDetail.payment.forEach((payment, index) => {
        if (payment.code == "PAY_FROM_DIARY") {
          pay_from_diary_amt = payment.total;
        } else {
          if (payment.code == APIHelper.SAVE_TO_DIARY_TYPE) {
            save_to_diary_amount = payment.total;
            if (payment.decision) {
              save_to_diary_comment = payment.decision;
            } else {
              if (payment.total > 0) {
                if (deliveryType == APIHelper.IMMEDIATE) {
                  save_to_diary_comment = "credit";
                } else {
                  save_to_diary_comment = "outstanding";
                }
              } else {
                save_to_diary_comment = payment.decision;
              }
            }
          }

          if (splitPaymentDetail[payment.code]) {
            splitPaymentDetail[payment.code].amount += Math.abs(payment.total)
          } else {
            if (payment.is_sub_class) {
              splitPaymentDetail[payment.code] = { name: payment.type, amount: Math.abs(payment.total), payment_sub_class_info: [] }
            } else {
              splitPaymentDetail[payment.code] = { name: payment.name, amount: Math.abs(payment.total), payment_sub_class_info: [] }
            }
          }

          if (payment.is_sub_class) {
            splitPaymentDetail[payment.code]["payment_sub_class_info"].push({ "sub_class_name": payment.name, amount: Math.abs(payment.total) })
          }
        }
      });

      var tax_details = (Array.isArray(tempOrderDetail.group_tax)) ? JSON.parse(JSON.stringify(tempOrderDetail.group_tax)) : [];
      tax_details.forEach(td => {
        td.breakup_name = td.tax;
        delete td.tax;
      })

      var orderData = {
        pay_from_diary_amt: pay_from_diary_amt,
        save_to_diary_amount: save_to_diary_amount,
        save_to_diary_comment: save_to_diary_comment,
        customer_token: tempUnitData.token,
        splitPaymentDetail: splitPaymentDetail,
        tax_details: tax_details,
        shop_code: localStorage.getItem("shop_code"),
        item_price: membership_item.unit_price
      }

      if (membership_item.is_renew_plan_selected) {
        //renew membership payment

        //get existing membership info;
        this.membershipService.getUserMembershipInfo(primary_user_id).subscribe((res) => {
          var result = res.json();
          if (result.status === 0) {
            orderData["membership_id"] = result.result.user_membership.enroll_info.membership_id;
            //renew membership payment
            this.membershipService.renewUserMembershipPlan(orderData["membership_id"]).subscribe((renewRes) => {
              var renewResult = renewRes.json();
              if (renewResult.status === 0) {
                this.makePaymentForMembership(orderData).then(isPaymentDone => {
                  resolve(isPaymentDone)
                });
              } else {
                resolve(false);
                this.messagingService.add({ severity: 'error', summary: 'Error', detail: renewResult.message });
              }
            }, err => {
              console.log(err);
            });
          } else {
            this.messagingService.add({ severity: 'error', summary: 'Error', detail: result.message });
            resolve(false);
          }
        }, err => {
          console.log(err);
          resolve(false);
        });
      } else {
        //normal membership payment
        this.membershipService.enrollUserForMembershipPlan(membership_item.item_code, primary_user_id, secondary_user_id).subscribe((res) => {
          var result = res.json();
          if (result.status === 0) {
            orderData["membership_id"] = result.result.user_membership.membership_id;
            this.makePaymentForMembership(orderData).then(isPaymentDone => {
              resolve(isPaymentDone)
            });
          } else {
            this.messagingService.add({ severity: 'error', summary: 'Error', detail: result.message });
            resolve(false);
          }
        }, err => {
          console.log(err);
          resolve(false);
        });
      }
    });
  }

  makePaymentForMembership(data) {
    return new Promise<boolean>((resolve, reject) => {
      let loggedeInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};

      var diaryOrderData = {
        pay_from_diary_amt: data.pay_from_diary_amt,
        save_to_diary_amount: data.save_to_diary_amount,
        save_to_diary_comment: data.save_to_diary_comment,
        shopCode: data.shop_code,
        customer_auth_token: data.customer_token,
        shop_user_id: loggedeInUser.id
      }
      this.paymentHelper.saveToDiary(data.membership_id, diaryOrderData).then((res) => {
        if (res) {
          this.membershipService.makeMembershipPayment(data.shop_code, data.membership_id, parseFloat(data.item_price), data.splitPaymentDetail, data.tax_details).subscribe((paymentRes) => {
            var paymentResult = paymentRes.json();
            if (paymentResult.status === 0) {
              resolve(true);
              this.clearCustomerInfo(true);
              this.router.navigate(['/pages/register/place-selection/here']);
            } else {
              this.messagingService.add({ severity: 'error', summary: 'Error', detail: paymentResult.message });
              resolve(false);
            }
          }, err => {
            console.log(err);
            this.messagingService.add({ severity: 'error', summary: 'Error', detail: "something went wrong while make payment of membership" });
            resolve(false);
          });
        } else {
          this.messagingService.add({ severity: 'error', summary: 'Error', detail: "can't save in diary" });
          resolve(false);
        }
      });
    })
  }

  callBill(type) {
    let tempUnit = JSON.parse(JSON.stringify(this.stateGuard.obtain("selects-box.component:currentUnit") || []));
    let tempOrderDetail = JSON.parse(JSON.stringify(this.stateGuard.obtain("payment.component:orderDetail") || {}))
    tempOrderDetail.payment = (tempOrderDetail && tempOrderDetail.payment) ? tempOrderDetail.payment : [];

    if (tempUnit && tempUnit.visitors && tempUnit.visitors.length > 0) {
      //sync order with captain app only is tables not avilable
      if (!this.shopConfig.enable_tables) {
        this.captainAppHelper.syncOrder(JSON.parse(JSON.stringify(tempUnit)));
      }

      let deliveryType = tempUnit.delivery_type;

      let other_discount_list = tempUnit.other_discounts || [];
      let discount_codes = [];
      let discount_info = [];
      if (other_discount_list.length > 0) {
        other_discount_list.forEach(i => {
          if (discount_codes.indexOf(i.name) == -1) {
            discount_codes.push(i.name);
          }
          let tempObj = {
            claimed_value: (i.value * -1),
            discount_code: (i.name),
            type: (i.type),
            is_max_discount_val: (i.is_max_discount_val) ? 1 : 0,
          }
          if (i.type == "discount_on_order") {
            tempObj["discount_order_id"] = (i.discount_order_id);
          }
          discount_info.push(tempObj);
        });
      }

      var loggedInUser: any = { id: -1 }
      if (tempUnit.assigny && tempUnit.assigny != "") {
        loggedInUser = { id: parseInt(tempUnit.assigny) };
      } else {
        if (localStorage.getItem('loggedInUser')) {
          loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
        }
      }

      let comment;
      if (tempUnit && tempUnit.noChargeFlag && tempUnit.noChargeFlag == "true") {
        comment = tempUnit.noChargeNote;
      } else {
        comment = "";
      }

      let cancel_order_comment;
      if (tempUnit && tempUnit.cancel_order_flag && tempUnit.cancel_order_flag == "true") {
        cancel_order_comment = tempUnit.cancel_order_reason;
      } else {
        cancel_order_comment = null;
      }

      let delivaryDate = null;
      if (deliveryType == APIHelper.HOME_DELIVERY || deliveryType == APIHelper.PICK_UP) {
        let date = tempUnit.delivery_date;
        delivaryDate = new Date(date);
      }

      if (!delivaryDate) {
        delivaryDate = new Date();
      }

      let device_type = JSON.parse(localStorage.getItem('device_key'));
      let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
      let ref_bill = (typeof localStorage.getItem('ref_bill_num') == 'string') ? parseInt(localStorage.getItem('ref_bill_num')) : localStorage.getItem('ref_bill_num');

      let date = new Date()
      var dd = date.getDate() + '';
      var mm = (date.getMonth() + 1) + '';
      var yyyy = date.getFullYear();
      if (dd.length == 1) { dd = '0' + dd }
      if (mm.length == 1) { mm = '0' + mm }
      let close_time = shop_config.shop_close_time;
      let start_time = shop_config.shop_open_time;


      let setCloseDate = yyyy + '/' + mm + '/' + dd + ' ' + close_time;
      let shop_close_time = new Date(setCloseDate);
      // let next_day_count = shop_config.next_days_count;
      // shop_close_time.setDate(shop_close_time.getDate() + next_day_count);
      let setStartDate = yyyy + '/' + mm + '/' + dd + ' ' + start_time;
      let shop_start_time = new Date(setStartDate);
      let temp_ref_bill_update_date: any = localStorage.getItem("ref_bill_update_date") || new Date();
      let ref_bill_update_date = new Date(temp_ref_bill_update_date);
      let curr_time = new Date();

      if ((curr_time > shop_close_time && (curr_time < ref_bill_update_date || (curr_time > ref_bill_update_date && ref_bill_update_date < shop_start_time))) ||
        // (curr_time > ref_bill_update_date && ref_bill_update_date < shop_start_time && curr_time > shop_start_time )){
        (ref_bill_update_date < curr_time && ref_bill_update_date < shop_start_time && shop_start_time < curr_time)) {
        var local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
        if (!local_config.disable_reset_bill_number) {
          localStorage.setItem('ref_bill_num', '-1');
          localStorage.setItem('kot_number', '1');
          localStorage.setItem('check_print_number', '1');
        }
      }

      let ref_bill_num = (typeof localStorage.getItem('ref_bill_num') == 'string') ? parseInt(localStorage.getItem('ref_bill_num')) : localStorage.getItem('ref_bill_num');

      let token = (tempUnit && tempUnit.token) ? tempUnit.token : APIHelper.DEFAULT_TOKEN;

      let phone_number = null;
      if (token == APIHelper.DEFAULT_TOKEN) {
        if (tempUnit.customer_info && tempUnit.customer_info.phone) {
          phone_number = tempUnit.customer_info.phone;
        }
      }

      this.getOrderNotes().then((order_note) => {
        this.getRefBillNumAndUserInfo(ref_bill_num, token, phone_number, tempUnit).then((data) => {
          var is_gst_vat_flow = false;
          var bill_tax_class = "";
          if (this.shopConfig.enable_separate_bill_for_tax_classes) {
            let temp_cart_items = [];
            if (tempUnit && tempUnit.visitors && tempUnit.visitors.length > 0) {
              tempUnit.visitors.forEach(visitor => {
                if (Array.isArray(visitor.dishes)) {
                  visitor.dishes.forEach(dish => {
                    if (Array.isArray(dish)) {
                      dish.forEach(item => {
                        let titem = (item.selected_variant_item) ? item.selected_variant_item : item;
                        if (!bill_tax_class && this.getItemTaxClass(titem) == "VAT") {
                          bill_tax_class = "VAT";
                        }
                        temp_cart_items.push(titem);
                      });
                    }
                  });
                }
              })
            }
            if (SeparateBillHelper.cheackIsDifferentTaxClassesItemAvailable(temp_cart_items)) {
              is_gst_vat_flow = true;
            }
          }

          if (is_gst_vat_flow) {
            console.log("is_gst_vat_flow");
            this.callBillInVatAndGstCase({
              delivary_date: delivaryDate,
              delivery_type: deliveryType,
              comment: comment,
              shop_user_id: loggedInUser.id,
              order_note: order_note,
              type: type,
              discount_info: discount_info,
              discount_codes: discount_codes
            });
          } else {
            console.log("normal order flow");
            let tempBill = localStorage.getItem('ref_bill_num');
            let billNo = parseInt(tempBill);
            let terminal_name = (device_type && device_type.terminal_name) ? device_type.terminal_name : null;
            let terminal_id = (device_type && device_type.terminal_id) ? device_type.terminal_id : null;
            let tempOrderId = "";
            if (tempUnit.visitors && tempUnit.visitors.length > 0 && tempUnit.visitors[0].temp_client_reference_code) {
              tempOrderId = billNo + '/' + tempUnit.visitors[0].temp_client_reference_code;
            } else {
              tempOrderId = billNo + '/' + terminal_name + "-" + terminal_id + '/WBP' + (new Date()).getTime();
            }

            let newBillNo = billNo + 1;
            localStorage.setItem('ref_bill_num', JSON.stringify(newBillNo));



            let billInfo: any = {
              order_id: tempOrderId,
              shopCode: localStorage.getItem('shop_code'),
              paymentType: APIHelper.CASH_IN_HAND_TYPE,
              cart_value: tempOrderDetail.cart_value,
              cart_tax: tempOrderDetail.cart_tax,
              give_rewards: true,
              rewards_amount: 0,
              rewards_pt: 0,
              extraCharges: [],
              group_tax: [],
              partner_ids_list: tempUnit.selected_partner_list,
              check_prints: Array.isArray(tempUnit.check_prints) ? tempUnit.check_prints : [],
              table_number: tempUnit.tables,
              table_covers: (localStorage.getItem('isRestaurant') == 'true' && (!deliveryType || deliveryType == APIHelper.IMMEDIATE)) ? tempUnit.covers : 0,
              pay_from_diary_amt: 0,
              save_to_diary_amount: 0,
              splitPayment: {},
              client_reference_code: tempOrderId,
              shop_section: tempUnit.location,
              splitPaymentDetail: {},
              deliveryDate: delivaryDate,
              deliveryType: (deliveryType) ? deliveryType : APIHelper.IMMEDIATE,
              customer_auth_token: tempUnit.token || APIHelper.DEFAULT_TOKEN,
              cart_items: [],
              kot_items: [],
              save_order: {},
              is_order_sync: false,
              createdAt: new Date(),
              discount_amount: tempOrderDetail.discount_amount,
              total_other_discount_amount: tempOrderDetail.total_other_discount_amount,
              cash_discount_amount: tempOrderDetail.cash_discount_amount,
              discount_reason: tempOrderDetail.discount_reason,
              discount_phone_no: tempOrderDetail.discount_phone_no,
              is_cancel: false,
              discount_codes: discount_codes,
              discount_info: discount_info,
              non_chargeable: (tempUnit.noChargeFlag) ? tempUnit.noChargeFlag : 'false',
              comment: comment,
              shop_user_id: loggedInUser.id,
              no_charge_comment: comment,
              cancel_order_flag: (tempUnit.cancel_order_flag) ? tempUnit.cancel_order_flag : 'false',
              cancel_order_comment: cancel_order_comment,
              record_item_wise_discount: false,
              previousClientReferenceCode: null,
              prev_order_id: null,
              note: order_note,
              bill_tax_class: (bill_tax_class) ? bill_tax_class : (shop_config.enable_separate_bill_for_tax_classes) ? "GST" : "",
              separate_bill_for_tax_classes: (shop_config.enable_separate_bill_for_tax_classes) ? 1 : 0,
              is_separate_bill_order_flow: 0,
            }

            if (billInfo.check_prints.length > 0) {
              billInfo.last_check_print = billInfo.check_prints[billInfo.check_prints.length - 1];
            }
            if (tempUnit.selected_employer && tempUnit.selected_employer.employer_code) {
              billInfo["employer_code"] = tempUnit.selected_employer.employer_code;
              billInfo["tax_number"] = tempUnit.selected_employer.tax_number;
            }
            let loggedInUserData = JSON.parse(localStorage.getItem('loggedInUser'));

            let amount = tempOrderDetail.cart_value + billInfo.cart_tax - tempOrderDetail.discount_amount - tempOrderDetail.total_other_discount_amount;

            if (tempOrderDetail.extraCharges) {
              tempOrderDetail.extraCharges.forEach(charge => {
                amount += charge.value;
              })
            }
            amount -= tempOrderDetail.cash_discount_amount;//cash discount always add on post tax
            let totalAmount = GeneralHelper.toRound(amount);

            if (type == "cash") {
              tempOrderDetail.payment.push({
                name: 'Cash',
                sub_class: [],
                code: APIHelper.CASH_IN_HAND_TYPE,
                total: (totalAmount)
              });
            } else if (type == "card") {
              tempOrderDetail.payment.push({
                name: 'Card',
                sub_class: [],
                code: APIHelper.CARD_PAYMENT_TYPE,
                total: (totalAmount)
              });
            }


            tempOrderDetail.payment.forEach(detail => {
              if (detail.code == APIHelper.REWARD_PAYMENT_TYPE) {
                let amount = Math.abs(detail.total);
                let points = 0;
                let ratio = this.shopConfig.rewards_redeemption_gain_unit / this.shopConfig.rewards_redemption_unit;
                points = amount / ratio;
                billInfo['rewards_amount'] = amount;
                billInfo['rewards_pt'] = points;
              }
            })
            tempOrderDetail.payment.forEach((payment, index) => {
              if (payment.code == "PAY_FROM_DIARY") {
                billInfo.pay_from_diary_amt = payment.total;
              } else {
                if (index == 0) { billInfo.paymentType = payment.code; }
                if (payment.code == APIHelper.SAVE_TO_DIARY_TYPE) {
                  billInfo.save_to_diary_amount = payment.total;
                  if (payment.decision) {
                    billInfo.save_to_diary_comment = payment.decision;
                  } else {
                    if (payment.total > 0) {
                      if (deliveryType == APIHelper.IMMEDIATE) {
                        billInfo.save_to_diary_comment = "credit";
                      } else {
                        billInfo.save_to_diary_comment = "outstanding";
                      }
                    } else {
                      billInfo.save_to_diary_comment = payment.decision;
                    }
                  }
                }

                if (billInfo.splitPaymentDetail[payment.code]) {
                  billInfo.splitPaymentDetail[payment.code].amount += payment.total
                } else {
                  if (payment.is_sub_class) {
                    billInfo.splitPaymentDetail[payment.code] = { name: payment.type, amount: payment.total, payment_sub_class_info: [] }
                  } else {
                    billInfo.splitPaymentDetail[payment.code] = { name: payment.name, amount: payment.total, payment_sub_class_info: [] }
                    if (billInfo.save_to_diary_comment && payment.code == APIHelper.SAVE_TO_DIARY_TYPE) {
                      billInfo.splitPaymentDetail[payment.code]["comment"] = billInfo.save_to_diary_comment;
                    }
                  }
                }

                if (payment.is_sub_class) {
                  billInfo.splitPaymentDetail[payment.code]["payment_sub_class_info"].push({ "sub_class_name": payment.name, amount: payment.total })
                }

                if (billInfo.splitPayment[payment.code]) {
                  let amt = billInfo.splitPayment[payment.code];
                  billInfo.splitPayment[payment.code] = amt + payment.total;
                } else {
                  billInfo.splitPayment[payment.code] = payment.total;
                }
              }
            });

            let countItems = 0;
            let isSingleCheckout = false;
            let index = 0;

            if (tempUnit.index) {
              isSingleCheckout = true;
              index = tempUnit.index;
            }

            if (isSingleCheckout && index) {

              var tempUnitInfo: any = JSON.parse(JSON.stringify(tempUnit.visitors[index]));
              var tempKot = tempUnitInfo.kot;
              var tempReferenceCode = "";
              if (tempUnitInfo && tempUnitInfo.temp_client_reference_code) {
                tempReferenceCode = billNo + '/' + tempUnitInfo.temp_client_reference_code;
              } else {
                tempReferenceCode = billNo + '/' + terminal_name + "-" + terminal_id + '/WBP' + (new Date()).getTime();
              }
              billInfo["order_id"] = tempReferenceCode;
              billInfo["table_covers"] = 1;
              tempKot.forEach(kot_item => {
                if (kot_item.items && kot_item.items.length > 0) {
                  kot_item.items.forEach(item => {
                    item.item_price = item.unit_price;
                    item.discount_type = item.discountUnits;
                  });
                  billInfo.kot_items.push(JSON.parse(JSON.stringify(kot_item)));
                }
              });
            }
            let tempOrderNote = "";
            let tempOrderIsSpecial = 0;
            let discountTotal = 0;
            let itemwiseTotalDiscount = 0;
            discountTotal += tempOrderDetail.discount_amount;

            let isDiscountFound = false;
            tempUnit.visitors.forEach(visitor => {
              if (visitor.dishes && visitor.dishes[0]) {
                visitor.dishes[0].forEach(item => {
                  if (item.special_order_information) {
                    tempOrderIsSpecial = 1;
                    tempOrderNote = item.note;
                  } else if (item.selected_variant_item && item.selected_variant_item.special_order_information) {
                    tempOrderIsSpecial = 1;
                    tempOrderNote = item.selected_variant_item.note;
                  }
                  //adding a cart items
                  if (item.selected_variant_item) {
                    item.selected_variant_item.item_price = item.selected_variant_item.unit_price;
                    countItems = countItems + ((item.selected_variant_item.item_count) ? item.selected_variant_item.item_count : 1);
                    item.selected_variant_item.discount_type = item.selected_variant_item.discountUnits;
                    if (item.selected_variant_item.discount) {
                      isDiscountFound = true;

                      if (item.selected_variant_item.discount_type == '%' && item.selected_variant_item.discount) {
                        if (typeof item.selected_variant_item.discount == 'string') {
                          item.selected_variant_item.discount = parseFloat(item.selected_variant_item.discount);
                        }
                        if (typeof item.selected_variant_item.item_price == 'string') {
                          item.selected_variant_item.item_price = parseFloat(item.selected_variant_item.item_price);
                        }
                        //let discount = ((parseInt(item.selected_variant_item.item_count) * item.selected_variant_item.item_price * item.selected_variant_item.discount)/100);
                        //discountTotal += parseFloat(discount);
                        //itemwiseTotalDiscount += parseFloat(discount);

                      } else if (item.selected_variant_item.discount) {
                        if (typeof item.selected_variant_item.discount == 'string') {
                          item.selected_variant_item.discount = parseFloat(item.selected_variant_item.discount);
                        }
                        //discountTotal += item.selected_variant_item.discount;
                        //itemwiseTotalDiscount += item.selected_variant_item.discount;
                      }


                    }
                    billInfo.cart_items.push(JSON.parse(JSON.stringify(item.selected_variant_item)));
                  } else {
                    item.item_price = item.unit_price;
                    countItems = countItems + ((item.item_count) ? item.item_count : 1);
                    item.discount_type = item.discountUnits;
                    if (item.discount) {
                      isDiscountFound = true;

                      if (item.discount_type == '%' && item.discount) {
                        if (typeof item.discount == 'string') {
                          item.discount = parseFloat(item.discount);
                        }
                        if (typeof item.item_price == 'string') {
                          item.item_price = parseFloat(item.item_price);
                        }
                        //let discount = ((parseInt(item.item_count) * item.item_price * item.discount)/100);
                        //discountTotal += parseFloat(discount);
                        //itemwiseTotalDiscount += parseFloat(discount);
                      } else if (item.discount) {
                        if (typeof item.discount == 'string') {
                          item.discount = parseFloat(item.discount);
                        }
                        //discountTotal += item.discount;
                        //itemwiseTotalDiscount += item.discount;
                      }

                    }
                    billInfo.cart_items.push(JSON.parse(JSON.stringify(item)));
                  }
                });

                if (!isSingleCheckout) {
                  if (visitor.kot && visitor.kot[0]) {
                    //adding kot items
                    var tempKot: any = JSON.parse(JSON.stringify(visitor.kot));
                    tempKot.forEach(kot_item => {
                      if (kot_item.items && kot_item.items.length > 0) {
                        kot_item.items.forEach(item => {
                          item.discount_type = item.discountUnits;
                          item.item_price = item.unit_price;
                        });
                      }
                      billInfo.kot_items.push(JSON.parse(JSON.stringify(kot_item)));
                    });
                  }
                }
              }
            });
            billInfo.record_item_wise_discount = isDiscountFound;
            billInfo['item_count'] = countItems;
            billInfo['discount_amount'] = discountTotal;
            billInfo['cash_discount_amount'] = tempOrderDetail.cash_discount_amount;
            billInfo['cart_value'] = tempOrderDetail.cart_value//itemwiseTotalDiscount + tempOrderDetail.cart_value;
            if (this.shopConfig.enable_extra_charges) {
              if (tempOrderDetail.extraCharges) {

                billInfo.extraCharges = tempOrderDetail.extraCharges;

                /*tempOrderDetail.extraCharges.forEach(extraCharge => {
                  billInfo.extraCharges.push({charge_name:extraCharge.charge_name,value:extraCharge.value});
                });*/
              }
            }

            if (tempOrderDetail.group_tax && tempOrderDetail.group_tax.length > 0) {
              billInfo.tax_breakup = [];

              tempOrderDetail.group_tax.forEach(tax => {
                
                billInfo.group_tax.push({ breakup_name: tax.tax, rate: parseFloat(tax.rate) , value: tax.value });
                billInfo.tax_breakup.push({ breakup_name: tax.tax, rate: parseFloat(tax.rate) , value: tax.value });
              });
            }

            if (!this.shopConfig.item_wise_tax && this.shopConfig.extra_tax_components && this.shopConfig.extra_tax_components[0]) {
              var extra_tax = this.shopConfig.extra_tax_components[0];

              if (extra_tax[this.shopConfig.shop_info.country] && extra_tax[this.shopConfig.shop_info.country].breakup) {
                billInfo.tax_type = extra_tax[this.shopConfig.shop_info.country].main_tax_name;
                var temp_tax_rate = 0;
                var temp_tax_value = 0;
                if (Array.isArray(billInfo.tax_breakup)) {
                  billInfo.tax_breakup.forEach(taxb => {
                    if (taxb.rate) { temp_tax_rate += taxb.rate; }
                    if (taxb.value) { temp_tax_value += taxb.value; }
                  })
                }
                billInfo.tax_rate = temp_tax_rate;
                billInfo.tax_value = temp_tax_value;
              }
            } else {
              billInfo.tax_breakup = [];
            }

            var billQueue: any = [];
            try {
              billQueue = JSON.parse(localStorage.getItem('bill_queue')) || [];
            } catch (e) {
              billQueue = [];
            }

            if (billQueue === null) billQueue = [];
            let temptotalAmount = tempOrderDetail.cart_value + billInfo.cart_tax - tempOrderDetail.discount_amount - tempOrderDetail.total_other_discount_amount - billInfo.rewards_amount;

            if (tempOrderDetail.extraCharges) {
              tempOrderDetail.extraCharges.forEach(charge => {
                temptotalAmount += charge.value;
              })
            }

            temptotalAmount -= tempOrderDetail.cash_discount_amount;//cash discount applied on post tax value.
            let total = GeneralHelper.toRound(temptotalAmount);
            let rounding_value = (total - temptotalAmount);

            if (tempUnit && tempUnit.noChargeFlag && tempUnit.noChargeFlag == "true") {
              billInfo.splitPayment = {
                'PAYT0003': total + rounding_value
              }
            }

            let payment_list = [];

            if (billInfo.splitPaymentDetail) {
              for (var key in billInfo.splitPaymentDetail) {
                if (billInfo.splitPaymentDetail.hasOwnProperty(key)) {

                  if (Array.isArray(billInfo.splitPaymentDetail[key].payment_sub_class_info) && billInfo.splitPaymentDetail[key].payment_sub_class_info.length > 0) {
                    billInfo.splitPaymentDetail[key].payment_sub_class_info.forEach(data => {
                      let tempPaymentDetail = {
                        title: data.sub_class_name,
                        amount: data.amount,
                        payment_code: key
                      }

                      if (key == APIHelper.SAVE_TO_DIARY_TYPE) {
                        /*obj['comment'] = billInfo.save_to_diary_comment;
                        obj.title = "Save to Diary";
                        obj.payment_code = APIHelper.SAVE_TO_DIARY_TYPE;*/
                      } else {
                        payment_list.push(tempPaymentDetail);
                      }
                    });
                  } else {
                    let obj = {
                      title: billInfo.splitPaymentDetail[key].name,
                      amount: billInfo.splitPaymentDetail[key].amount,
                      payment_code: key
                    }
                    if (key == APIHelper.SAVE_TO_DIARY_TYPE) {
                      /*obj['comment'] = billInfo.save_to_diary_comment;
                      obj.title = "Save to Diary";
                      obj.payment_code = APIHelper.SAVE_TO_DIARY_TYPE;*/
                    } else {
                      payment_list.push(obj);
                    }
                  }
                }
              }
            }
            tempOrderDetail.payment.forEach(payment => {
              if (payment.code == "PAY_FROM_DIARY") {
                let obj = {
                  title: 'Pay from diary',
                  amount: payment.total,
                  payment_code: payment.code
                }
                payment_list.push(obj);
              } else if (payment.code == "PAYT0001" && payment.name == "Save to diary" && payment.decision == "advance") {
                let obj = {
                  title: 'Advance Amount',
                  amount: payment.total,
                  payment_code: payment.code
                }
                payment_list.push(obj);
              }
            })

            if (billInfo.save_to_diary_amount > 0 && ['credit_due', 'outstanding'].indexOf(billInfo.save_to_diary_comment) != -1) {
              let title = billInfo.save_to_diary_comment == "credit_due" ? "Credit Due Amount" : "Outstanding Amount"
              let obj = {
                title: title,
                amount: billInfo.save_to_diary_amount,
                payment_code: APIHelper.SAVE_TO_DIARY_TYPE
              }
              payment_list.push(obj);
            }

            billInfo['splitPaymentList'] = payment_list;
            billInfo['delivery_date'] = billInfo.deliveryDate;
            billInfo['valued_at'] = total;
            billInfo['rounding_value'] = rounding_value;
            billInfo['shipping_type'] = billInfo.deliveryType;
            billInfo['total_item_count'] = billInfo.item_count;

            let uname = 'Guest';

            if (this.currentConsumer.consumer_name) {
              uname = this.currentConsumer.consumer_name;
            } else if (tempUnit && tempUnit.userInfo && tempUnit.userInfo.consumer_name) {
              uname = tempUnit.userInfo.consumer_name;
              this.currentConsumer["consumer_name"] = tempUnit.userInfo.consumer_name;
              this.currentConsumer["profile_img_url"] = tempUnit.userInfo.profile_img_url;
              this.currentConsumer["phone"] = tempUnit.userInfo.mobile_no;
              this.currentConsumer["rewards_pts"] = tempUnit.userInfo.rewards_points;
              this.currentConsumer["alt_address"] = (Array.isArray(tempUnit.userInfo.alt_address)) ? tempUnit.userInfo.alt_address : [];
            }

            billInfo['user_name'] = uname;

            billInfo['register_customer_info_flag'] = tempUnit.register_customer_info_flag;

            if (billInfo['register_customer_info_flag']) {
              billInfo['register_customer_data'] = tempUnit.register_customer_data;
            }

            this.addCashPaymentForCashRegister(billInfo.splitPaymentList);
            if (tempUnit.is_cancel_order_from_cart) {
              this.handleCancelOrderFromCart(tempUnit, billInfo);
            } else {
              billInfo.kot_items.forEach(kot_item => {
                kot_item.items.forEach(item => {
                  item.item_count = Math.abs(item.item_count);
                })
              })
              let order = {
                'order_id': billInfo.order_id,
                'order_status': 'offline',
                'shipping_type': billInfo.deliveryType,
                'payment_type': billInfo.paymentType,
                'shop_section': billInfo.shop_section,
                'delivery_date': billInfo.deliveryDate,
                'valued_at': billInfo.valued_at,
                'createdAt': billInfo.createdAt,
                'discount': billInfo.discount_amount,
                'other_discount': billInfo.total_other_discount_amount,
                'cash_discount': billInfo.cash_discount_amount,
                'discount_reason': billInfo.discount_reason,
                'discount_phone_no': billInfo.discount_phone_no,
                'rounding_value': rounding_value,
                'total_item_count': billInfo.item_count,
                'extra_charges': billInfo.extraCharges,
                'group_tax': billInfo.group_tax,
                'tax_breakup': billInfo.tax_breakup,
                'split_payment': billInfo.splitPaymentList,
                'split_payment_details': billInfo.splitPaymentDetail,
                'partner_ids': billInfo.partner_ids_list,
                'check_prints': billInfo.check_prints,
                'last_check_print': billInfo.last_check_print ? billInfo.last_check_print : null,
                'employer_code': billInfo.employer_code,
                'tax_number': billInfo.tax_number,
                'table_number': billInfo.table_number,
                'table_covers': billInfo.table_covers,
                'note': billInfo.note,
                'comment': (billInfo.no_charge_comment) ? JSON.parse(billInfo.no_charge_comment) : "",
                'cancel_order_comment': billInfo.cancel_order_comment
              }

              let assignToId = billInfo.shop_user_id;
              if (assignToId == -1) {
                order['assignTo'] = null;
              } else {
                let shop_user_list = JSON.parse(localStorage.getItem('shop_user_list'));
                for (var i = 0; i < shop_user_list.length; ++i) {
                  if (shop_user_list[i].id == assignToId) {
                    order['assignTo'] = {
                      name: shop_user_list[i].name,
                      id: shop_user_list[i].id
                    }
                  }
                }
              }

              order['assign'] = null;
              if (loggedInUserData) {
                order['assign'] = {
                  'id': loggedInUserData.id,
                  'name': loggedInUserData.name
                };
              }

              if (order.comment.employeeId) {
                if (localStorage.getItem('shop_user_list')) {
                  let userList = JSON.parse(localStorage.getItem('shop_user_list'));
                  for (var i = 0; i < userList.length; ++i) {
                    if (userList[i].id.toString() == order.comment.employeeId.toString()) {
                      order.comment['emp_name'] = userList[i].name;
                      break;
                    }
                  }
                }
              }


              let user = {
                'name': this.currentConsumer.consumer_name,
                'profile_img_url': this.currentConsumer.profile_img_url,
                'phone': this.currentConsumer.phone,
                'rewards_pts': this.currentConsumer.rewards_points,
                'address': (this.currentConsumer.alt_address && this.currentConsumer.alt_address.length > 0) ? this.currentConsumer.alt_address[this.currentConsumer.alt_address.length - 1].address : ""
              }


              let items = billInfo.cart_items;
              let diary_history = [];


              if (this.printBillFlag) {
                this.commonPrint(billInfo);
              }

              if (this.printBillFlag) {
                let printerData = JSON.parse(localStorage.getItem('shop_printers'));
                if (printerData) {
                  var printers_list = printerData;
                  var printerFound = false;

                  let isHPSelfServiceEnabled = false;
                  if (['ST0001', 'ST0002'].includes(billInfo.deliveryType)) {
                    let local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
                    if (local_config && local_config.enable_home_delivery_pickup_self_service) {
                      isHPSelfServiceEnabled = true;
                    }
                  }


                  for (var i = 0; i < printers_list.length; i++) {
                    if (printers_list[i].print_type.indexOf('self_service') != -1 || isHPSelfServiceEnabled) {
                      this.createKOTobject();
                      break;
                    }
                  }
                }
              }

              billInfo['discount_info'] = discount_info;
              if (tempOrderIsSpecial) {
                order.note = tempOrderNote;
              }
              if ((type == 'card' || type == 'cash') && billInfo.cart_items.length > 0) {
                this.getConsumerInfo(true, billQueue, billInfo, order, items, diary_history);
                this.router.navigate(['/pages/register/place-selection/here']);
              } else if (billInfo.cart_items.length > 0) {
                if (billInfo['register_customer_info_flag']) {
                  if (billInfo["register_customer_data"] && billInfo["register_customer_data"]["customer_update_data"]) {
                    user = billInfo["register_customer_data"]["customer_update_data"]["user"];
                  }
                }

                billInfo['order'] = order;
                billInfo['user'] = user;
                billInfo['items'] = items;
                billInfo['diary_history'] = diary_history;
                billInfo['isOfflineOrder'] = true;
                // billInfo['createdAt'] = new Date();
                billQueue.push(billInfo);
                localStorage.setItem('bill_queue', JSON.stringify(billQueue));
                this.updateStockQuantity(billInfo.cart_items);
                if (type == 'other') {
                  this.clearCustomerInfo(true);
                }
                this.router.navigate(['/pages/register/place-selection/here']);
              } else {
                this.messagingService.add({ severity: 'error', summary: 'Items not available in cart ', detail: '' });
              }
            }
          }
        })
          .catch(e => {
            console.log(e)
          })
      })
    }
  }

  callBillInVatAndGstCase(params) {
    let tempUnit = JSON.parse(JSON.stringify(this.stateGuard.obtain("selects-box.component:currentUnit") || []));
    let tempOrderDetail = JSON.parse(JSON.stringify(this.stateGuard.obtain("payment.component:orderDetail") || {}))
    let order_all_payment = (tempOrderDetail && tempOrderDetail.payment) ? JSON.parse(JSON.stringify(tempOrderDetail.payment)) : [];
    var taxGroupWiseItem = {
      vat: {
        cart_items: [],
        kot_items: [],
        total_item_count: 0,
        record_item_wise_discount: false,
        sub_total: 0,
        total_discount: 0,
        total_other_discount: 0
      },
      gst: {
        cart_items: [],
        kot_items: [],
        total_item_count: 0,
        record_item_wise_discount: false,
        sub_total: 0,
        total_discount: 0,
        total_other_discount: 0
      }
    }
    tempUnit.visitors.forEach(visitor => {
      if (visitor.dishes && visitor.dishes[0]) {
        visitor.dishes[0].forEach(item => {

          var tempItem = (item.selected_variant_item) ? JSON.parse(JSON.stringify(item.selected_variant_item)) : JSON.parse(JSON.stringify(item));
          if (tempItem) {
            tempItem = this.prepareItemFields(JSON.parse(JSON.stringify(tempItem)));
            var item_tax_class = this.getItemTaxClass(tempItem);

            var temp_item_price = tempItem.unit_price * tempItem.item_count
            var temp_total_discount = 0;
            var temp_total_other_discount = 0;
            if (tempItem.isOnlyModifier || tempItem.is_multi_group_combo) {
              if (tempItem.attributes) {
                tempItem.attributes.forEach(attr => {
                  temp_item_price += attr.unit_price * attr.count;
                });
              }
            }
            if (tempItem.discount && tempItem.discount > 0) {
              temp_total_discount += (tempItem.discountUnits == "%") ? ((tempItem.unit_price * tempItem.item_count * tempItem.discount) / 100) : tempItem.discount;
              temp_item_price -= (tempItem.discountUnits == "%") ? ((tempItem.unit_price * tempItem.item_count * tempItem.discount) / 100) : tempItem.discount;
            }

            taxGroupWiseItem[((item_tax_class == "VAT") ? "vat" : "gst")].total_item_count += parseFloat(tempItem.item_count);
            taxGroupWiseItem[((item_tax_class == "VAT") ? "vat" : "gst")].cart_items.push(tempItem);
            taxGroupWiseItem[((item_tax_class == "VAT") ? "vat" : "gst")].sub_total += temp_item_price;
            taxGroupWiseItem[((item_tax_class == "VAT") ? "vat" : "gst")].total_discount += temp_total_discount;
            //taxGroupWiseItem[((item_tax_class == "VAT")? "vat": "gst" )].total_other_discount += temp_total_other_discount;
            if (tempItem.discount) {
              taxGroupWiseItem[((item_tax_class == "VAT") ? "vat" : "gst")].record_item_wise_discount = true;
            }
          }
        });

        if (visitor.kot && visitor.kot[0]) {
          var tempKot: any = JSON.parse(JSON.stringify(visitor.kot));
          tempKot.forEach(kot_item => {
            var temp_kot_vat_items = [];
            var temp_kot_gst_items = [];

            if (kot_item.items && kot_item.items.length > 0) {
              kot_item.items.forEach(item => {
                item.discount_type = item.discountUnits;
                item.item_price = item.unit_price;
                item.item_count = Math.abs(item.item_count);
                var item_tax_class = this.getItemTaxClass(item);

                if (item_tax_class == "VAT") {
                  temp_kot_vat_items.push(item);
                } else {
                  temp_kot_gst_items.push(item);
                }
              });
            }

            if (temp_kot_gst_items.length > 0) {
              taxGroupWiseItem.gst.kot_items.push({
                kot_id: kot_item.kot_id,
                kot_number: kot_item.kot_number,
                items: temp_kot_gst_items
              });
            }

            if (temp_kot_vat_items.length > 0) {
              taxGroupWiseItem.vat.kot_items.push({
                kot_id: kot_item.kot_id,
                kot_number: kot_item.kot_number,
                items: temp_kot_vat_items
              });
            }
          });
        }
      }
    });

    var total_st = (taxGroupWiseItem['vat'].sub_total + taxGroupWiseItem['gst'].sub_total);
    var tgwiLen = Object.keys(taxGroupWiseItem).length;
    var totalValuedAt = 0;
    for (var taxclass in taxGroupWiseItem) {
      if (taxGroupWiseItem[taxclass].cart_items.length > 0) {
        let isIGSTEnabled = false;
        if (taxclass == "gst" && tempUnit.delivery_type == APIHelper.HOME_DELIVERY && this.shopConfig &&
          this.shopConfig.shop_info && this.shopConfig.shop_info.state) {
          if (
            tempUnit.userInfo && tempUnit.userInfo.state &&
            tempUnit.userInfo.state.toUpperCase() != this.shopConfig.shop_info.state.toUpperCase()
          ) {
            isIGSTEnabled = true;
          }
        }

        let ctpData = this.basketComponent.calculateTaxesAndPrices(taxGroupWiseItem[taxclass].cart_items, { isIGSTEnabled: isIGSTEnabled });
        let device_type = JSON.parse(localStorage.getItem('device_key'));
        let billNo = parseInt(localStorage.getItem('ref_bill_num'));
        let terminal_name = (device_type && device_type.terminal_name) ? device_type.terminal_name : null;
        let terminal_id = (device_type && device_type.terminal_id) ? device_type.terminal_id : null;
        let tempOrderId = billNo + '/' + terminal_name + "-" + terminal_id + '/WBP' + (new Date()).getTime();

        localStorage.setItem('ref_bill_num', JSON.stringify(billNo + 1));

        let billInfo: any = {
          order_id: tempOrderId,
          shopCode: localStorage.getItem('shop_code'),
          paymentType: APIHelper.CASH_IN_HAND_TYPE,
          cart_value: ctpData.cart_value,
          cart_tax: ctpData.cart_tax,
          give_rewards: true,
          rewards_amount: 0,
          rewards_pt: 0,
          extraCharges: [],
          group_tax: [],
          tax_breakup: [],
          tax_value: 0,
          tax_rate: 0,
          partner_ids_list: tempUnit.selected_partner_list,
          check_prints: Array.isArray(tempUnit.check_prints) ? tempUnit.check_prints : [],
          table_number: tempUnit.tables,
          table_covers: (localStorage.getItem('isRestaurant') == 'true' && (!params.delivery_type || params.delivery_type == APIHelper.IMMEDIATE)) ? tempUnit.covers : 0,
          pay_from_diary_amt: 0,
          save_to_diary_amount: 0,
          splitPayment: {},
          client_reference_code: tempOrderId,
          shop_section: tempUnit.location,
          splitPaymentDetail: {},
          deliveryDate: params.delivary_date,
          deliveryType: (params.delivery_type) ? params.delivery_type : APIHelper.IMMEDIATE,
          customer_auth_token: tempUnit.token || APIHelper.DEFAULT_TOKEN,
          cart_items: taxGroupWiseItem[taxclass].cart_items,
          kot_items: taxGroupWiseItem[taxclass].kot_items,
          save_order: {},
          is_order_sync: false,
          createdAt: new Date(),
          discount_amount: (taxGroupWiseItem[taxclass].total_discount) ? taxGroupWiseItem[taxclass].total_discount : 0,
          total_other_discount_amount: 0,
          cash_discount_amount: 0,
          discount_reason: "",
          discount_phone_no: "",
          is_cancel: false,
          discount_codes: [],
          non_chargeable: (tempUnit.noChargeFlag) ? tempUnit.noChargeFlag : 'false',
          comment: params.comment,
          shop_user_id: params.shop_user_id,
          no_charge_comment: params.comment,
          cancel_order_flag: false,
          cancel_order_comment: "",
          record_item_wise_discount: taxGroupWiseItem[taxclass].record_item_wise_discount,
          previousClientReferenceCode: null,
          prev_order_id: null,
          note: params.order_note,
          item_count: taxGroupWiseItem[taxclass].total_item_count,
          bill_tax_class: taxclass.toUpperCase(),
          separate_bill_for_tax_classes: 1,
          is_separate_bill_order_flow: 1
        }

        if (billInfo.check_prints.length > 0) {
          billInfo.last_check_print = billInfo.check_prints[billInfo.check_prints.length - 1];
        }

        //below field are duplicated and use for offline order only
        billInfo['delivery_date'] = billInfo.deliveryDate;
        billInfo['shipping_type'] = billInfo.deliveryType;
        billInfo['total_item_count'] = billInfo.item_count;
        billInfo['discount_info'] = JSON.parse(JSON.stringify(this.discountHelper.getDiscountInfoForSeparateTaxClasses(total_st, taxGroupWiseItem[taxclass].sub_total, taxGroupWiseItem[taxclass].cart_items, params.discount_info)));
        billInfo['discount_codes'] = billInfo['discount_info'].map(di => { return di.discount_code });
        billInfo['total_other_discount_amount'] = sumBy(billInfo['discount_info'], di => { return (di.claimed_value && di.type != "cashback" && !di.is_item_wise_discount) ? Math.abs(di.claimed_value) : 0 });
        billInfo['discount_info'].forEach(dci => {
          delete dci.is_item_wise_discount;
          delete dci.type;
        })
        //tax handling for offline order // presume only item wise tax available with this order.
        if (ctpData.group_tax && ctpData.group_tax.length > 0) {
          ctpData.group_tax.forEach(tax => {
            billInfo.group_tax.push({ breakup_name: tax.tax, rate: parseFloat(tax.tax_rate) , value: tax.value });
          });
        }

        //set user info for offline order
        let uname = 'Guest';
        if (this.currentConsumer.consumer_name) {
          uname = this.currentConsumer.consumer_name;
        } else if (tempUnit && tempUnit.userInfo && tempUnit.userInfo.consumer_name) {
          uname = tempUnit.userInfo.consumer_name;
          this.currentConsumer["consumer_name"] = tempUnit.userInfo.consumer_name;
          this.currentConsumer["profile_img_url"] = tempUnit.userInfo.profile_img_url;
          this.currentConsumer["phone"] = tempUnit.userInfo.mobile_no;
          this.currentConsumer["rewards_pts"] = tempUnit.userInfo.rewards_points;
          this.currentConsumer["alt_address"] = (Array.isArray(tempUnit.userInfo.alt_address)) ? tempUnit.userInfo.alt_address : [];
        }

        billInfo['user_name'] = uname;
        billInfo['user'] = JSON.parse(JSON.stringify(this.currentConsumer));
        billInfo['register_customer_info_flag'] = tempUnit.register_customer_info_flag;
        if (billInfo['register_customer_info_flag']) {
          billInfo['register_customer_data'] = tempUnit.register_customer_data;
        }

        if (tempUnit.selected_employer && tempUnit.selected_employer.employer_code) {
          billInfo["employer_code"] = tempUnit.selected_employer.employer_code;
          billInfo["tax_number"] = tempUnit.selected_employer.tax_number;
        }

        var total_extra_charge_amount = 0;
        //extra charge handling
        if (tempOrderDetail.extraCharges && ctpData.extraCharges) {
          var tempExtraCharges = [];
          var orderECLen = tempOrderDetail.extraCharges.length;
          ctpData.extraCharges.forEach(charge => {
            for (var i = 0; i < orderECLen; i++) {
              if (tempOrderDetail.extraCharges[i].charge_name == charge.charge_name && charge.is_value_in_percentage) {
                total_extra_charge_amount += parseFloat(charge.value);
                tempExtraCharges.push({ charge_name: charge.charge_name, value: charge.value });
                break;
              }
            }
          })

          billInfo.extraCharges = tempExtraCharges;
        }

        //calculate percentage manaul discount
        if (tempOrderDetail.discountUnit == '%' && tempOrderDetail.discount) {
          billInfo['discount_amount'] += ((ctpData.cart_value * tempOrderDetail.discount) / 100);
        }
        let amount = ctpData.cart_value + ctpData.cart_tax + total_extra_charge_amount - billInfo.discount_amount - billInfo.total_other_discount_amount;
        //calculate  percentage cash discount
        if (tempOrderDetail.cashDiscountUnit == '%' && tempOrderDetail.cash_discount) {
          billInfo['cash_discount_amount'] = ((JSON.parse(JSON.stringify(amount)) * tempOrderDetail.cash_discount) / 100);
          amount -= (billInfo['cash_discount_amount']) ? billInfo['cash_discount_amount'] : 0;//cash discount applied on post tax value
        }

        //total amount and rounding value finding
        let totalAmount = GeneralHelper.toRound(amount);
        let tempTotalAmount = JSON.parse(JSON.stringify(totalAmount));
        let rounding_value = (totalAmount - amount);
        billInfo['rounding_value'] = rounding_value;
        billInfo['valued_at'] = totalAmount;
        totalValuedAt = totalValuedAt + billInfo['valued_at'];
        if (tempUnit && tempUnit.noChargeFlag && tempUnit.noChargeFlag == "true") {
          billInfo.splitPayment = { 'PAYT0003': totalAmount }
        }

        //make a list of payment types
        if (params.type == "cash") {
          order_all_payment.push({
            name: 'Cash',
            sub_class: [],
            code: APIHelper.CASH_IN_HAND_TYPE,
            total: (totalAmount)
          });
        } else if (params.type == "card") {
          order_all_payment.push({
            name: 'Card',
            sub_class: [],
            code: APIHelper.CARD_PAYMENT_TYPE,
            total: (totalAmount)
          });
        }

        let payment_list = [];
        order_all_payment.forEach((payment, index) => {
          if (tempTotalAmount > 0 && payment.total != 0) {

            let used_total = (tempTotalAmount >= Math.abs(payment.total)) ? Math.abs(payment.total) : tempTotalAmount;
            tempTotalAmount -= JSON.parse(JSON.stringify(used_total));
            if (payment.total < 0) { used_total = -used_total };
            payment.total -= JSON.parse(JSON.stringify(used_total));

            if (payment.code == "PAY_FROM_DIARY") {
              billInfo.pay_from_diary_amt = used_total;
              payment_list.push({
                title: 'Pay from diary',
                amount: used_total,
                payment_code: payment.code
              });
            } else {
              if (index == 0) { billInfo.paymentType = payment.code; }
              if (payment.code == APIHelper.REWARD_PAYMENT_TYPE) {
                let amount = Math.abs(used_total);
                let points = 0;
                let ratio = this.shopConfig.rewards_redeemption_gain_unit / this.shopConfig.rewards_redemption_unit;
                points = amount / ratio;
                billInfo['rewards_amount'] = amount;
                billInfo['rewards_pt'] = points;
              } else if (payment.code == APIHelper.SAVE_TO_DIARY_TYPE) {
                billInfo.save_to_diary_amount = used_total;
                if (payment.decision) {
                  billInfo.save_to_diary_comment = payment.decision;
                  if (payment.decision == "credit_due" && used_total > 0) {
                    payment_list.push({
                      title: 'Credit Due Amount',
                      amount: used_total,
                      payment_code: payment.code
                    });
                  } else if (payment.decision == "outstanding" && used_total > 0) {
                    payment_list.push({
                      title: 'Outstanding Amount',
                      amount: used_total,
                      payment_code: payment.code
                    });
                  }
                } else {
                  if (used_total > 0) {
                    if (params.delivery_type == APIHelper.IMMEDIATE) {
                      billInfo.save_to_diary_comment = "credit";
                    } else {
                      billInfo.save_to_diary_comment = "outstanding";
                    }
                  } else {
                    billInfo.save_to_diary_comment = payment.decision;
                  }
                }
              }

              if (billInfo.splitPaymentDetail[payment.code]) {
                billInfo.splitPaymentDetail[payment.code].amount += used_total
              } else {
                if (payment.is_sub_class) {
                  billInfo.splitPaymentDetail[payment.code] = { name: payment.type, amount: used_total, payment_sub_class_info: [] }
                } else {
                  billInfo.splitPaymentDetail[payment.code] = { name: payment.name, amount: used_total, payment_sub_class_info: [] }
                }
              }

              if (payment.is_sub_class) {
                billInfo.splitPaymentDetail[payment.code]["payment_sub_class_info"].push({ "sub_class_name": payment.name, amount: used_total })
              }

              if (billInfo.splitPayment[payment.code]) {
                let amt = billInfo.splitPayment[payment.code];
                billInfo.splitPayment[payment.code] = amt + used_total;
              } else {
                billInfo.splitPayment[payment.code] = used_total;
              }
            }
          } else if (payment.code == APIHelper.SAVE_TO_DIARY_TYPE && payment.decision == "advance" && payment.total != 0) {
            //for only advance payment
            billInfo.save_to_diary_comment = "advance";
            billInfo.save_to_diary_amount = JSON.parse(JSON.stringify(payment.total));
            payment.total = 0;
            payment_list.push({
              title: 'Advance Amount',
              amount: billInfo.save_to_diary_amount,
              payment_code: payment.code
            });
          }
        });

        if (billInfo.splitPaymentDetail) {
          for (var key in billInfo.splitPaymentDetail) {
            if (billInfo.splitPaymentDetail.hasOwnProperty(key) && key != APIHelper.SAVE_TO_DIARY_TYPE) {

              if (Array.isArray(billInfo.splitPaymentDetail[key].payment_sub_class_info) && billInfo.splitPaymentDetail[key].payment_sub_class_info.length > 0) {
                billInfo.splitPaymentDetail[key].payment_sub_class_info.forEach(data => {
                  payment_list.push({
                    title: data.sub_class_name,
                    amount: data.amount,
                    payment_code: key
                  });
                });
              } else {
                payment_list.push({
                  title: billInfo.splitPaymentDetail[key].name,
                  amount: billInfo.splitPaymentDetail[key].amount,
                  payment_code: key
                });
              }
            }
          }
        }

        billInfo['splitPaymentList'] = payment_list;
        this.addCashPaymentForCashRegister(billInfo.splitPaymentList);
        this.setOfflineOrderData(billInfo, params).then(result => {
          var billQueue: any = [];
          try {
            billQueue = JSON.parse(localStorage.getItem('bill_queue')) || [];
          } catch (e) {
            billQueue = [];
          }

          if (billQueue === null) billQueue = [];

          billQueue.push(billInfo);
          localStorage.setItem('bill_queue', JSON.stringify(billQueue));
          this.updateStockQuantity(billInfo.cart_items);
          tgwiLen--;
          if (tgwiLen == 0) {
            billInfo['gst_vat_final_total'] = totalValuedAt
          } else {
            billInfo['gst_vat_final_total'] = 0;
          }

          if (this.printBillFlag) {
            this.commonPrint(JSON.parse(JSON.stringify(billInfo)));
          }

          // tgwiLen--;
          if (tgwiLen == 0) {
            this.clearCustomerInfo(true);
            this.router.navigate(['/pages/register/place-selection/here']);
          }
        });
      } else {
        tgwiLen--;
        if (tgwiLen == 0) {
          this.clearCustomerInfo(true);
          this.router.navigate(['/pages/register/place-selection/here']);
        }
      }
    }
  }


  setOfflineOrderData(billInfo, params) {
    return new Promise((resolve, reject) => {

      let order = {
        'order_id': billInfo.order_id,
        'order_status': 'offline',
        'shipping_type': billInfo.deliveryType,
        'payment_type': billInfo.paymentType,
        'shop_section': billInfo.shop_section,
        'delivery_date': billInfo.deliveryDate,
        'valued_at': billInfo.valued_at,
        'createdAt': billInfo.createdAt,
        'discount': billInfo.discount_amount,
        'cash_discount': billInfo.cash_discount_amount,
        'other_discount': billInfo.total_other_discount_amount,
        'discount_reason': billInfo.discount_reason,
        'discount_phone_no': billInfo.discount_phone_no,
        'rounding_value': billInfo.rounding_value,
        'total_item_count': billInfo.item_count,
        'extra_charges': billInfo.extraCharges,
        'group_tax': billInfo.group_tax,
        'tax_breakup': billInfo.tax_breakup,
        'split_payment': billInfo.splitPaymentList,
        'split_payment_details': billInfo.splitPaymentDetail,
        'partner_ids': billInfo.partner_ids_list,
        'check_prints': billInfo.check_prints,
        'last_check_print': billInfo.last_check_print,
        'employer_code': billInfo.employer_code,
        'tax_number': billInfo.tax_number,
        'table_number': billInfo.table_number,
        'table_covers': billInfo.table_covers,
        'note': billInfo.note,
        'comment': (billInfo.no_charge_comment) ? JSON.parse(billInfo.no_charge_comment) : "",
        'cancel_order_comment': billInfo.cancel_order_comment
      }

      let loggedInUserData = JSON.parse(localStorage.getItem('loggedInUser'));
      let assignToId = billInfo.shop_user_id;
      if (assignToId == -1) {
        order['assignTo'] = null;
      } else {
        let shop_user_list = JSON.parse(localStorage.getItem('shop_user_list'));
        for (var i = 0; i < shop_user_list.length; ++i) {
          if (shop_user_list[i].id == assignToId) {
            order['assignTo'] = {
              name: shop_user_list[i].name,
              id: shop_user_list[i].id
            }
          }
        }
      }

      order['assign'] = null;
      if (loggedInUserData) {
        order['assign'] = {
          'id': loggedInUserData.id,
          'name': loggedInUserData.name
        };
      }

      if (order.comment && order.comment.employeeId) {
        if (localStorage.getItem('shop_user_list')) {
          let userList = JSON.parse(localStorage.getItem('shop_user_list'));
          for (var i = 0; i < userList.length; ++i) {
            if (userList[i].id.toString() == order.comment.employeeId.toString()) {
              order.comment['emp_name'] = userList[i].name;
              break;
            }
          }
        }
      }

      let user = {
        'name': this.currentConsumer.consumer_name,
        'profile_img_url': BaseConfig.config.API + this.currentConsumer.profile_img_url,
        'phone': this.currentConsumer.phone,
        'rewards_pts': this.currentConsumer.rewards_points,
        'address': (this.currentConsumer.alt_address && this.currentConsumer.alt_address.length > 0) ? this.currentConsumer.alt_address[this.currentConsumer.alt_address.length - 1].address : ""
      }

      if (billInfo['register_customer_info_flag']) {
        if (billInfo["register_customer_data"] && billInfo["register_customer_data"]["customer_update_data"]) {
          user = billInfo["register_customer_data"]["customer_update_data"]["user"];
        }
      }

      billInfo['order'] = order;
      billInfo['user'] = user;
      billInfo['items'] = billInfo.cart_items;
      billInfo['diary_history'] = [];
      billInfo['isOfflineOrder'] = true;

      resolve(billInfo);
    });
  }


  getItemTaxClass(item) {
    var tax_class = "main";
    if (item.tax_settings.length > 0) {
      if (item.tax_settings[0].tax_class && item.tax_settings[0].rate > 0) {
        tax_class = item.tax_settings[0].tax_class;
      }
    }
    return tax_class;
  }

  prepareItemFields(item) {
    item.discount_type = item.discountUnits;
    item.unit_price = parseFloat(item.unit_price);
    item.item_price = item.unit_price;

    if (item.discount) {
      if (item.discount_type == '%' && item.discount) {
        if (typeof item.discount == 'string') {
          item.discount = parseFloat(item.discount);
        }
        //let discount = ((parseInt(item.item_count) * item.item_price * item.discount)/100);
      } else if (item.discount) {
        if (typeof item.discount == 'string') {
          item.discount = parseFloat(item.discount);
        }
      }
    }
    return item;
  }

  addCashPaymentForCashRegister(listData) {
    if (listData && listData.length > 0) {
      listData.forEach(data => {
        if (data.payment_code == APIHelper.CASH_ON_DELIVERY_TYPE || data.payment_code == APIHelper.CASH_IN_HAND_TYPE) {
          let amount = data.amount;
          if (localStorage.getItem('cash_register')) {
            let cash_register = JSON.parse(localStorage.getItem('cash_register'));
            if (cash_register && cash_register[0]) {
              cash_register[0].cash_sale = amount + cash_register[0].cash_sale;
            }
            localStorage.setItem('cash_register', JSON.stringify(cash_register));
          }
        }
      });
    }
  }


  handleCancelOrderFromCart(tempUnit, billInfo) {
    let cart = [];
    if (billInfo.kot_items && billInfo.kot_items[0]) {
      billInfo.kot_items.forEach((kot) => {
        kot.items.forEach((item) => {
          item.tax_value = 0;
          item.tax_breakup = [];
          item.tax_type = null;
          item.tax_rate = null;
          item.other_discounts = [];
          item.discount = 0;
          if (!item.is_billed) {
            let is_item_found = false;
            cart.forEach((cart_item) => {
              if (cart_item.item_code == item.item_code && (!cart_item.is_variant || (cart_item.is_variant && cart_item.add_time == item.add_time))) {
                is_item_found = true;
                cart_item.item_count += item.item_count;
              }
            })
            if (!is_item_found) {
              cart.push(JSON.parse(JSON.stringify(item)));
            }
          }
        })
      })
    }

    billInfo.discount_codes = [];
    billInfo.discount_info = [];
    billInfo.kot_items.forEach(kot_item => {
      kot_item.items.forEach(item => {
        item.item_count = Math.abs(item.item_count);
      })
    });

    cart.forEach(item => {
      item.item_count = Math.abs(item.item_count);
    });

    let data = this.basketComponent.calculateTaxesAndPrices(cart, { isIGSTEnabled: false });
    cart.forEach(item => {
      item.tax_value = 0;
      item.tax_breakup = [];
      item.tax_type = null;
      item.tax_rate = null;
    });
    billInfo.item_count = cart.length;
    billInfo.cart_items = cart;
    billInfo.cart_tax = 0;
    billInfo.discount_amount = 0;
    billInfo.rewards_amount = 0;

    billInfo.rounding_value = 0;
    billInfo.cart_value = data.cart_value;
    billInfo.valued_at = data.cart_value;
    billInfo.cart_tax = 0;
    billInfo.extraCharges = [];
    billInfo.group_tax = [];
    billInfo.splitPaymentList = [{
      title: "VOID",
      amount: data.cart_value,
      payment_code: APIHelper.VOID_PAYMENT_TYPE
    }];
    billInfo.splitPaymentDetail = {};
    billInfo.splitPaymentDetail[APIHelper.VOID_PAYMENT_TYPE] = { name: "VOID", amount: data.cart_value, payment_sub_class_info: [] };
    billInfo.splitPayment = {};
    billInfo.splitPayment[APIHelper.VOID_PAYMENT_TYPE] = data.cart_value;
    billInfo.paymentType = APIHelper.VOID_PAYMENT_TYPE;

    let order = {
      'order_id': billInfo.order_id,
      'order_status': 'offline',
      'shipping_type': billInfo.deliveryType,
      'payment_type': billInfo.paymentType,
      'shop_section': billInfo.shop_section,
      'delivery_date': billInfo.deliveryDate,
      'valued_at': data.cart_value,
      'createdAt': billInfo.createdAt,
      'discount': billInfo.discount_amount,
      'other_discount': billInfo.total_other_discount_amount,
      'rewards_amount': billInfo.rewards_amount,
      'rounding_value': 0,
      'total_item_count': billInfo.item_count,
      'extra_charges': billInfo.extraCharges,
      'group_tax': billInfo.group_tax,
      'split_payment': billInfo.splitPaymentList,
      'split_payment_details': billInfo.splitPaymentDetail,
      'table_number': billInfo.table_number,
      'table_covers': billInfo.table_covers,
      'comment': (billInfo.no_charge_comment) ? JSON.parse(billInfo.no_charge_comment) : "",
      'cancel_order_comment': billInfo.cancel_order_comment
    }

    /*if(billInfo.deliveryType == APIHelper.HOME_DELIVERY || billInfo.deliveryType == APIHelper.PICK_UP){
      order.order_status = 'ORDER_APPROVED'
    }*/

    if (order.comment.employeeId) {
      if (localStorage.getItem('shop_user_list')) {
        let userList = JSON.parse(localStorage.getItem('shop_user_list'));
        for (var i = 0; i < userList.length; ++i) {
          if (userList[i].id.toString() == order.comment.employeeId.toString()) {
            order.comment['emp_name'] = userList[i].name;
            break;
          }
        }
      }
    }

    let user = {
      'name': this.currentConsumer.consumer_name,
      'profile_img_url': this.currentConsumer.profile_img_url,
      'phone': this.currentConsumer.phone,
      'rewards_pts': this.currentConsumer.rewards_points,
      'address': (this.currentConsumer.alt_address && this.currentConsumer.alt_address.length > 0) ? this.currentConsumer.alt_address[this.currentConsumer.alt_address.length - 1].address : ""
    }

    let items = billInfo.cart_items;
    let diary_history = [];

    var billQueue: any = [];
    try {
      billQueue = JSON.parse(localStorage.getItem('bill_queue')) || [];
    } catch (e) {
      billQueue = [];
    }


    billInfo['order'] = order;
    billInfo['user'] = user;
    billInfo['items'] = items;
    billInfo['diary_history'] = diary_history;
    billInfo['isOfflineOrder'] = true;
    billInfo['createdAt'] = new Date();
    billQueue.push(billInfo);
    localStorage.setItem('bill_queue', JSON.stringify(billQueue));
    this.updateStockQuantity(billInfo.cart_items);
    this.clearCustomerInfo(true);
    if (!tempUnit.index) {
      this.router.navigate(['/pages/register/place-selection/here']);
    }
  }

  updateStockQuantity(soldItem: any) {
    if (soldItem && Array.isArray(soldItem)) {
      let totalSoldItemCount = 0;
      let unbundle_list = JSON.parse(localStorage.getItem('all_unbundle_items_list')) || [];

      let categories = JSON.parse(localStorage.getItem('categories'));
      for (var i = 0; i < categories.length; ++i) {
        var currentItems: any = JSON.parse(localStorage.getItem(categories[i].category_code));
        if (Array.isArray(currentItems)) {
          for (var tempItem of currentItems) {
            soldItem.forEach(tempSoldItem => {

              if (tempItem.item_code === tempSoldItem.item_code) {
                let isItemInBundleList = false;
                let update_items = [];


                if (Array.isArray(unbundle_list) && unbundle_list[0]) {
                  let unbundleItems = unbundle_list[0];
                  for (let key in unbundleItems) {
                    if (key && Array.isArray(unbundleItems[key])) {
                      let list = unbundleItems[key];
                      list.forEach((code) => {
                        if (code == tempSoldItem.item_code) {
                          isItemInBundleList = true;
                          unbundleItems[key].forEach((itemCode) => {
                            update_items.push(itemCode);
                          })
                        }
                      })
                    }
                  }
                }

                if (isItemInBundleList) {
                  this.updateQuantityForUnbundleItems(categories[i].category_code, tempSoldItem, update_items);
                } else {
                  if (Array.isArray(tempItem.batch_serial_stock_info) && tempItem.batch_serial_stock_info.length > 0) {
                    var bssiLen = tempItem.batch_serial_stock_info.length;
                    for (var j = 0; j < bssiLen; j++) {
                      if (tempItem.batch_serial_stock_info[j].tracking_number == tempSoldItem.tracking_number &&
                        tempItem.batch_serial_stock_info[j].tracking_mode == tempSoldItem.tracking_mode) {
                        tempItem.batch_serial_stock_info[j].quantity -= tempSoldItem.item_count;
                        break;
                      }
                    }
                  }
                  tempItem.quantity -= tempSoldItem.item_count;
                  totalSoldItemCount++;
                }
              }
            });
          }
        }
        localStorage.setItem(categories[i].category_code, JSON.stringify(currentItems));
        if (totalSoldItemCount == soldItem.length) {
          break;
        }
      }
      this.updateComboSubItems(soldItem)
    }
  }

  updateQuantityForUnbundleItems(category_code, tempSoldItem, update_items) {
    var currentItems: any = JSON.parse(localStorage.getItem(category_code)) || [];
    if (Array.isArray(currentItems)) {
      currentItems.forEach(item => {
        if (update_items.indexOf(item.item_code) !== -1) {
          item.quantity = (((item.quantity * item.unit_value) - (tempSoldItem.item_count * tempSoldItem.unit_value)) / item.unit_value);
          if (Array.isArray(item.batch_serial_stock_info) && item.batch_serial_stock_info.length > 0) {
            var bssiLen = item.batch_serial_stock_info.length;
            for (var j = 0; j < bssiLen; j++) {
              if (item.batch_serial_stock_info[j].tracking_number == tempSoldItem.tracking_number &&
                item.batch_serial_stock_info[j].tracking_mode == tempSoldItem.tracking_mode) {
                item.batch_serial_stock_info[j].quantity = (((item.batch_serial_stock_info[j].quantity.quantity * item.unit_value) - (tempSoldItem.item_count * tempSoldItem.unit_value)) / item.unit_value)
                break;
              }
            }
          }
        }
      });
      setTimeout(() => {
        localStorage.setItem(category_code, JSON.stringify(currentItems));
      }, 300)
    }
  }

  updateComboSubItems(soldItem) {
    soldItem.forEach(tempSoldItem => {
      if (tempSoldItem.is_combo) {
        var combo_sub_item = tempSoldItem.combo_item_codes;
        if (combo_sub_item && Array.isArray(combo_sub_item)) {
          let totalComboSubItemCount = 0;

          let categories = JSON.parse(localStorage.getItem('categories'));
          for (var i = 0; i < categories.length; ++i) {
            var currentItems: any = JSON.parse(localStorage.getItem(categories[i].category_code));
            if (Array.isArray(currentItems)) {
              for (var tempMainItem of currentItems) {
                combo_sub_item.forEach(temp_combo_item => {
                  if (tempMainItem.item_code === temp_combo_item.item_code) {
                    tempMainItem.quantity -= temp_combo_item.quantity_in_combo;
                    totalComboSubItemCount++;
                  }
                });
              }
            }
            if (totalComboSubItemCount == combo_sub_item.length) {
              localStorage.setItem(categories[i].category_code, JSON.stringify(currentItems));
              break;
            }
          }
        }
      }
    })
    this.updateComboItem(soldItem)
  }


  updateComboItem(soldItem) {
    soldItem.forEach(tempSoldItem => {
      let categories = JSON.parse(localStorage.getItem('categories'));
      for (var i = 0; i < categories.length; ++i) {
        var currentItems: any = JSON.parse(localStorage.getItem(categories[i].category_code));
        var is_updated = false;
        if (Array.isArray(currentItems)) {
          for (var tempMainItem of currentItems) {
            if (tempMainItem.is_combo) {
              var combo_sub_item = tempMainItem.combo_item_codes;
              if (combo_sub_item && Array.isArray(combo_sub_item)) {
                var combo_item_codes = combo_sub_item.map(item => { return item.item_code });
                if (combo_item_codes.indexOf(tempSoldItem.item_code) != -1) {
                  let combo_item_quantity_array = [];
                  combo_sub_item.forEach((temp_combo_item: any) => {
                    if (temp_combo_item.quantity_in_combo) {
                      let fitem: any = this.findItem(temp_combo_item);
                      if (fitem && fitem.quantity >= 0) {
                        let t_d: any = fitem.quantity / temp_combo_item.quantity_in_combo;
                        combo_item_quantity_array.push(Math.floor(t_d));
                      }
                    }
                  });

                  if (combo_item_quantity_array.length > 0) {
                    let minimum_quantity = min(combo_item_quantity_array);
                    if (minimum_quantity >= 0) {
                      tempMainItem.quantity = minimum_quantity;
                      is_updated = true;
                    }
                  }
                }
              }
            }
          }

          if (is_updated) {
            localStorage.setItem(categories[i].category_code, JSON.stringify(currentItems));
          }
        }
      }
    })
  }

  findItem(fitemData) {
    let categories = JSON.parse(localStorage.getItem('categories'));
    let found_item = null
    for (var i = 0, cLen = categories.length; i < cLen; ++i) {
      var currentItems: any = JSON.parse(localStorage.getItem(categories[i].category_code));
      if (Array.isArray(currentItems)) {
        for (var j = 0, ciLen = currentItems.length; j < ciLen; j++) {
          if (currentItems[j] && currentItems[j].item_code == fitemData.item_code) {
            found_item = currentItems[j];
            break
          }
        }
      }
      if (found_item) {
        break;
      }
    }
    return found_item;
  }

  setOfflineOrders(billQueue, billInfo, order, items, diary_history) {

    let customer_info = JSON.parse(JSON.stringify(this.currentConsumer));
    billInfo['customer_auth_token'] = billInfo.customer_auth_token;
    billInfo['user_name'] = customer_info.consumer_name;
    let user = {
      'name': customer_info.consumer_name,
      'profile_img_url': customer_info.profile_img_url,
      'phone': customer_info.phone,
      'rewards_pts': customer_info.rewards_points,
      'address': (customer_info.alt_address && customer_info.alt_address.length > 0) ? customer_info.alt_address[customer_info.alt_address.length - 1].address : ""
    }

    if (typeof order.comment != 'string') {
      order.comment = (order.comment);
    } else {
      if (order.comment) {
        order.comment = (order.comment) ? JSON.parse(order.comment) : "";
        if (order.comment.employeeId) {
          if (localStorage.getItem('shop_user_list')) {
            let userList = JSON.parse(localStorage.getItem('shop_user_list'));
            for (var i = 0; i < userList.length; ++i) {
              if (userList[i].id.toString() == order.comment.employeeId.toString()) {
                order.comment['emp_name'] = userList[i].name;
                break;
              }
            }
          }
        }
      } else {
        order.comment = null;
      }
    }
    if (billInfo['register_customer_info_flag']) {
      if (billInfo["register_customer_data"] && billInfo["register_customer_data"]["customer_update_data"]) {
        user = billInfo["register_customer_data"]["customer_update_data"]["user"];
      }
    }
    billInfo['order'] = order;
    billInfo['user'] = user;
    billInfo['items'] = items;
    billInfo['diary_history'] = diary_history;
    billInfo['isOfflineOrder'] = true;
    billInfo.note = order.note;
    // billInfo['createdAt'] = new Date();
    billQueue.push(billInfo);
    localStorage.setItem('bill_queue', JSON.stringify(billQueue));
    this.updateStockQuantity(billInfo.cart_items);
    //this.router.navigate(['/pages/register/place-selection/here']);
    this.clearCustomerInfo(true);
  }

  clearCustomerInfo(isPaymentCase): void {

    let sData: any = {
      auth_token: APIHelper.DEFAULT_TOKEN,
      name: "",
      consumer_name: "",
      email: "",
      mobile_no: "",
      zip_code: "",
      address_1: "",
      city: "",
      lastVisit: "",
      lastCheck: 0,
      lastCheckCurrency: "INR",
      points: 0,
      place: "here",
      distance: ' - kms',
      places: [
        { id: 1, name: 'here' },
        { id: 2, name: 'home' },
        { id: 3, name: 'pickup' }
      ]
    };


    if (isPaymentCase) {
      this.checkForSingleCheckoutOrHold()
    }

    localStorage.setItem("current_customer_info", JSON.stringify(sData));
    localStorage.setItem("delivery_type", APIHelper.IMMEDIATE);
    this.customerMessageService.sendCustomerInfo(sData, false);
  }

  checkForSingleCheckoutOrHold() {
    let isSingleCheckout = localStorage.getItem('isSingleCheckoutForCancellOrder');
    let checkForHold = localStorage.getItem('checkForHold');

    if (this.isSingleCheckout || isSingleCheckout == 'true' || checkForHold == 'true') {
      this.basketComponent.singleCheckPaymentDone();
      localStorage.setItem('isSingleCheckoutForCancellOrder', 'false');
      localStorage.setItem('checkForHold', 'false');
    } else {
      localStorage.removeItem('single_checkout_cart_items');
      this.basketComponent.clearCart(true);
    }
  }

  changeMoney(newMoney) {
    this.money = newMoney;
  }

  changeRewardPoints(newPoints) {
    if (parseFloat(this.rewards_points) < parseFloat(newPoints)) {
      let self = this;
      this.ngZone.runOutsideAngular(() => {
        this.ngZone.run(() => {
          this.reward_amount = JSON.parse(JSON.stringify(this.rewards_points));
          this.cdRef.detectChanges();
          let amount = JSON.parse(JSON.stringify(self.rewards_points));
          amount = amount + '';
          self.reward_amount = amount;
        })
      });
    } else {
      this.ngZone.runOutsideAngular(() => {
        this.reward_amount = newPoints;
      });
    }
  }

  compute() {
    if (this.orderDetail) {
      let tempTotal = this.orderDetail.orderTotal || 0;
      if (this.orderDetail.rounding_off_amount != 0) {
        tempTotal += this.orderDetail.rounding_off_amount;
      }
      this.orderDetail.deducted_diary_amt = 0;

      if (this.orderDetail.payment && this.orderDetail.payment.length > 0 && this.orderDetail.changeAmount) {
        tempTotal += this.orderDetail.changeAmount;
      } else {
        this.orderDetail.changeAmount = 0;
      }

      if (this.orderDetail.payment) {
        this.orderDetail.payment.forEach(payment => {

          if (payment.code == "PAY_FROM_DIARY") {
            this.orderDetail.deducted_diary_amt -= payment.total;
            tempTotal -= parseFloat(payment.total);
          } else if (payment.code == APIHelper.REWARD_PAYMENT_TYPE) {
            tempTotal += parseFloat(payment.total);
          } else if (payment.code == APIHelper.SAVE_TO_DIARY_TYPE && payment.total < 0 && (this.orderDetail.changeAmount >= 0 || this.orderDetail.changeAmount < 0)) {
            tempTotal += Math.abs(parseFloat(payment.total));
          } else {
            tempTotal -= parseFloat(payment.total);
          }
        })
      }
      tempTotal = parseFloat(tempTotal);
      /*if(tempTotal > 0 && (this.orderDetail.changeAmount >= 0 || this.orderDetail.changeAmount < 0)) {
        tempTotal -= this.orderDetail.changeAmount;
        this.orderDetail.changeAmount = 0;
      }*/
      //tempTotal = Math.round(tempTotal);
      this.messageService.sendMessage(tempTotal + "");
      tempTotal -= parseFloat(this.money) || 0;
      this.payedEnough = ((tempTotal + (parseFloat(this.money) || 0)) <= 0) ? true : false;
      this.orderDetail.tempTotal = tempTotal;
      return tempTotal;
    } else {
      return 0;
    }
  }

  deleteRewardsAmount() {
    this.orderDetail = this.stateGuard.obtain("payment.component:orderDetail") || {};
    this.compute();
  }

  movesDetected(is_payment_removed) {
    this.compute();
  }

  setDefaultPaymentWay() {
    if (this.paymentTypes && this.paymentTypes[0]) {
      this.paymentWay = {
        type: this.paymentTypes[0].type,
        name: this.paymentTypes[0].name,
        code: this.paymentTypes[0].code,
        is_sub_class: this.paymentTypes[0].is_sub_class
      }
    }
  }

  closePayment() {
    this.orderDetail.payment = [];
    if (this.isSingleCheckout == 'singleCheckout') {
      let cart = JSON.parse(localStorage.getItem('single_checkout_cart_items'));
      if (cart) {
        this.stateGuard.entrust("selects-box.component:currentUnit", cart);
        localStorage.removeItem('single_checkout_cart_items');
      }
    } else {
      localStorage.removeItem('single_checkout_cart_items');
    }
    this.router.navigate(['/pages/register/food-ordering']);
  }

  payAmount() {
    if (localStorage.getItem('selectedOrderDetail')) {
      this.clearAmountFlag = true;
      let orderDetail = JSON.parse(localStorage.getItem('selectedOrderDetail')) || {};
      let tempOrderDetail = orderDetail.order;
      let diary_history = orderDetail.diary_history;
      this.clearSaveToDiaryOrderDetails = JSON.parse(localStorage.getItem('selectedOrderDetail'));
      this.userContactNo = (tempOrderDetail.userDetail && tempOrderDetail.userDetail.length > 0) ? tempOrderDetail.userDetail[tempOrderDetail.userDetail.length - 1].contact_no : "";
      this.selectedOrderId = tempOrderDetail.order_id;
      let paymentDetail = tempOrderDetail.split_payment;
      this.orderDetail.payment = (this.orderDetail && this.orderDetail.payment) ? this.orderDetail.payment : [];

      paymentDetail.forEach(paymentData => {
        this.paymentTypes.forEach(payment_detail => {
          if (payment_detail.code == paymentData.payment_code) {
            payment_detail['total'] = parseFloat(paymentData.amount);
            payment_detail['isRemovable'] = this.clearAmountFlag;
            this.orderDetail.payment.push(payment_detail)
          }
        });
        if (paymentData.payment_code == APIHelper.SAVE_TO_DIARY_TYPE && paymentData.comment == 'used') {
          this.orderDetail.payment.push({
            name: 'Save to diary',
            sub_class: [],
            isRemovable: this.clearAmountFlag,
            code: APIHelper.SAVE_TO_DIARY_TYPE,
            total: parseFloat(paymentData.amount)
          });
        }
      });
      diary_history.forEach(dairy => {
        if (dairy.comment == 'used') {

          let temp = {
            name: 'Pay from diary',
            sub_class: [],
            code: "PAY_FROM_DIARY",
            total: parseFloat(dairy.diary_amount),
            isRemovable: this.clearAmountFlag
          }
          this.orderDetail.payment.push(temp)
        }
      })
      localStorage.removeItem('selectedOrderDetail');
    } else {
      this.clearAmountFlag = false;
    }
  }

  callPaymentInstance() {
    this.shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
    this.paymentHelper = PaymentHelper.getInstance(this.shopConfig, this.customerService, this.saveOrderService, this.makePaymentService, this.diaryService, this.cashRegisterService, this.specialOrderService);
  }

  initialsePaymentTypes() {


    let paymentTypes = this.shopConfig.payment_types;

    if (localStorage.getItem('payment_types')) {
      let tempPaymentTypes = JSON.parse(localStorage.getItem('payment_types'));
      if (tempPaymentTypes && tempPaymentTypes[0]) {
        let finalList: any = [
          {
            name: 'cash',
            sub_class: [],
            code: APIHelper.CASH_IN_HAND_TYPE,
            img: 'assets/images/payments/cash.png',
            img_active: 'assets/images/payments/cash-active.png'
          },
          {
            name: 'card',
            sub_class: [],
            code: APIHelper.CARD_PAYMENT_TYPE,
            img: 'assets/images/payments/card.png',
            img_active: 'assets/images/payments/card-active.png'
          }, {
            name: 'reward',
            sub_class: [],
            code: APIHelper.REWARD_PAYMENT_TYPE,
            img: 'assets/images/payments/reward.png',
            img_active: 'assets/images/payments/reward-active.png'
          }];

        tempPaymentTypes.forEach((type) => {

          if (!(type.payment_type_code == APIHelper.SAVE_TO_DIARY_TYPE) && !(type.payment_type_code == APIHelper.CASH_IN_HAND_TYPE) &&
            !(type.payment_type_code == APIHelper.CASH_ON_DELIVERY_TYPE) && paymentTypes.indexOf(type.payment_type_code) != -1) {
            let detail = {
              name: type.payment_type,
              code: type.payment_type_code,
              sub_class: []
            };
            if (type.payment_type_code == APIHelper.COUPON_PAYMENT_TYPE) {
              detail['img'] = 'assets/images/payments/coupon.png';
              detail['img_active'] = 'assets/images/payments/coupon.png';
            } else if (type.payment_type_code == APIHelper.CASH_ON_DELIVERY_TYPE || type.payment_type_code == APIHelper.CASH_IN_HAND_TYPE) {
              detail['img'] = 'assets/images/payments/cash.png';
              detail['img_active'] = 'assets/images/payments/cash-active.png';
            } else if (type.payment_type_code == APIHelper.CARD_PAYMENT_TYPE) {
              detail['img'] = 'assets/images/payments/card.png';
              detail['img_active'] = 'assets/images/payments/card-active.png';
            } else {
              detail['img'] = 'assets/images/payments/cash.png';
              detail['img_active'] = 'assets/images/payments/cash-active.png';
            }
            finalList.push(detail);
          }
        });
        this.paymentTypes = finalList;
        this.loadPaymentClass();

      }
    }

  }

  ngOnInit() {
    let self = this;
    document.querySelector("#payment").addEventListener("keyup", function (event) {
      if (event["which"] === 27) {
        self.closePayment();
      }
    });

    this.isSingleCheckout = this.route.snapshot.params.type || null;
    this.shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));

    this.initialsePaymentTypes();

    this.subscription = this.rewardPointMessage.getRewardPoints().subscribe(message => {
      this.rewards_points = JSON.parse(JSON.stringify(message.points));
      this.cdRef.detectChanges();
    });

    this.currencySymbol = this.shopConfig.currency;
    this.is_available_s_t_d = (this.shopConfig.is_available_s_t_d) ? this.shopConfig.is_available_s_t_d : false;
    this.checkForOnlineOffline();

    setTimeout(function () {
      self.slider = document.querySelector("#payment-options");
      self.slideIn(document.querySelector("#payment-options .item.active"), "left");
      self.orderDetail = self.stateGuard.obtain("payment.component:orderDetail") || {};
      self.orderDetail.changeAmount = 0;
      self.orderDetail.payment = [];
      self.orderDetail.deducted_diary_amt = 0;
      self.getConsumerInfo(false, null, null, null, null, null);
      self.paymentHelper = PaymentHelper.getInstance(self.shopConfig, self.customerService, self.saveOrderService, self.makePaymentService, self.diaryService, self.cashRegisterService, self.specialOrderService);
      self.isPaymentInstanceCalled = true;
      self.payAmount();
    }, 100)

    //this.messageService.sendMessage(this.money);


  }
  ngAfterViewChecked() {
    // this is added for solve the issue of Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    this.cdRef.detectChanges();
  }


  payFromDiary() {

    if (!(this.is_available_s_t_d && !this.clearAmountFlag && this.is_online && this.orderDetail.changeAmount >= 0 &&
      this.currentConsumer && this.is_customer_found && ((this.currentConsumer.diary_amt - this.orderDetail.deducted_diary_amt) < 0))) {
      return false;
    }
    //here diary_amt always nagative
    if (this.currentConsumer.diary_amt) {
      let temp_diary_amt = this.compute();
      if (temp_diary_amt > Math.abs(this.currentConsumer.diary_amt)) {
        temp_diary_amt = Math.abs(this.currentConsumer.diary_amt);
      }
      if (temp_diary_amt > 0) {
        this.orderDetail.payment = (this.orderDetail && this.orderDetail.payment) ? this.orderDetail.payment : [];
        let isPaymentFound = 0;
        this.orderDetail.payment.forEach(payment_detail => {
          if (payment_detail.code == "PAY_FROM_DIARY") {
            isPaymentFound = 1;
            payment_detail.total = temp_diary_amt;
          }
        });

        if (!isPaymentFound) {
          this.orderDetail.payment.push({
            name: 'Pay from diary',
            sub_class: [],
            code: "PAY_FROM_DIARY",
            total: temp_diary_amt
          });
        }
        this.movesDetected(false);
      }
    }
  }

  saveToDiary() {

    if (!(this.is_available_s_t_d && !this.clearAmountFlag && this.is_customer_found && this.is_online && this.currentConsumer && this.orderDetail.tempTotal != 0 &&
      (((this.currentConsumer.diary_amt - this.orderDetail.deducted_diary_amt) >= 0)) || this.orderDetail.changeAmount < 0 || this.orderDetail.tempTotal < 0)) {
      return false;
    } else if (parseFloat(this.currentConsumer.credit_limit) > 0 && this.currentConsumer.diary_amt >= this.currentConsumer.credit_limit) {
      this.messagingService.add({ severity: 'error', summary: 'Customer credit limit reached', detail: 'Customer credit limit is full (' + this.currentConsumer.credit_limit + '), please make normal payment to place order. Available credit ' + Math.round(this.currentConsumer.credit_limit - this.currentConsumer.diary_amt) });
      return false;
    }

    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      let temp_diary_amt = this.compute();
      if (typeof temp_diary_amt != "undefined" && temp_diary_amt != 0) {
        let is_decision_taken = false;
        let decision = this.modalRef.content.decision;
        if (this.modalRef.content.decision === 'advance' || this.modalRef.content.decision === 'credit_note_advance') {
          //temp_diary_amt = -temp_diary_amt;
          is_decision_taken = true;
        } else if (this.modalRef.content.decision === 'credit_due') {
          //temp_diary_amt = temp_diary_amt;
          is_decision_taken = true;
        } else if (this.modalRef.content.decision === 'outstanding') {
          //temp_diary_amt = -this.currentConsumer.diary_amt;
          is_decision_taken = true;
        }

        if (is_decision_taken) {
          this.orderDetail.payment = (this.orderDetail && this.orderDetail.payment) ? this.orderDetail.payment : [];
          let isPaymentFound = 0;
          this.orderDetail.payment.forEach(payment_detail => {
            if (payment_detail.code == APIHelper.SAVE_TO_DIARY_TYPE) {
              isPaymentFound = 1;
              payment_detail.decision = decision;
              payment_detail.total = parseFloat(temp_diary_amt);
            }
          });
          if (!isPaymentFound) {
            this.orderDetail.payment.push({
              name: 'Save to diary',
              sub_class: [],
              decision: decision,
              code: APIHelper.SAVE_TO_DIARY_TYPE,
              total: parseFloat(temp_diary_amt)
            });
          }
          this.movesDetected(false);
        }
      }
      this.unsubscribe();
    }));
    let delivery_type = localStorage.getItem("delivery_type");
    this.modalRef = this.modalService.show(SaveDiaryModalComponent,
      { class: 'spartan-modal covers' });
    this.modalRef.content.decision = "";
    this.modalRef.content.isOutStandingOrder = delivery_type != APIHelper.IMMEDIATE;
    this.modalRef.content.amount = this.compute();
  }

  getConsumerInfo(flag, billQueue, billInfo, order, items, diary_history) {
    let token = (billInfo && billInfo.customer_auth_token) ? billInfo.customer_auth_token : APIHelper.DEFAULT_TOKEN;
    if (!navigator.onLine) {
      if (token && flag) {
        this.setOfflineOrders(billQueue, billInfo, order, items, diary_history);
      }
    } else if (token && flag) {
      /*this.customerService.info(token).subscribe((res) => {
        var result = res.json();
        if (result.status === 0) {
          this.currentConsumer = Object.assign(this.currentConsumer,result.result);
          localStorage.setItem('current_customer_info', JSON.stringify(this.currentConsumer));
          if(flag){
            this.setOfflineOrders(billQueue,billInfo,order,items,diary_history)
          }
        } else {*/
      this.setOfflineOrders(billQueue, billInfo, order, items, diary_history)
      //this.messagingService.add({ severity: 'error', summary: 'Error', detail: result.message });
      /*}
    },err =>{
        this.setOfflineOrders(billQueue,billInfo,order,items,diary_history);
    });*/
    } else {
      var tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
      token = tempUnit.token ? tempUnit.token : APIHelper.DEFAULT_TOKEN
      this.customerService.info(token).subscribe((res) => {
        var result = res.json();
        if (result.status === 0) {
          this.currentConsumer = result.result;
          if (token != APIHelper.DEFAULT_TOKEN) {
            this.is_customer_found = true;
            this.rewardPointMessage.sendRewardPoints(this.currentConsumer.rewards_points);
          }
        } else {
          this.messagingService.add({ severity: 'error', summary: 'Error', detail: result.message });
        }
      }, err => {

      });
    }
  }

  calculateRewardsAmount() {
    if (this.reward_amount == '') {
      return;
    }
    this.money = this.eqRewardsAmount() + '';
    let amount = this.rewards_points - parseFloat(this.reward_amount)
    this.rewardPointMessage.sendRewardPoints(amount);
    this.addPayment();
    this.compute();
  }


  movesDetectedRewardsPoint() {
    if (parseFloat(this.reward_amount) > this.rewards_points) {
      this.reward_amount = this.rewards_points;
    }
  }

  eqRewardsAmount() {
    let amount = 0;
    let ratio = this.shopConfig.rewards_redeemption_gain_unit / this.shopConfig.rewards_redemption_unit;
    amount = parseFloat(this.reward_amount) * ratio;
    return amount ? amount : 0;
  }

  letsPayWith(paymentDetail) {

    if (paymentDetail.type == 'reward') {
      if (!navigator.onLine) {
        this.isRewardEnable = false;
        this.rewardReason = "Internet connection is not available";
      }

      if (this.rewards_points < this.shopConfig.rewards_redemption_limit) {
        this.isRewardEnable = false;
        this.rewardReason = "Minimum redeem point shoud be greater than " + this.shopConfig.rewards_redemption_limit;
      }

      if (this.shopConfig.rewards_redemption_unit == 0) {
        this.isRewardEnable = false;
        this.rewardReason = "rewards_redemption_unit can't be zero";
      }
    }

    this.paymentWay = {
      type: paymentDetail.type,
      name: paymentDetail.name,
      code: paymentDetail.code,
      is_sub_class: paymentDetail.is_sub_class,
      rewards_points: this.currentConsumer.rewards_points,
    }
    this.money = "";
    setTimeout(() => {
      //this.messageService.sendMessage(this.compute() + "");
    }, 0)
  }


  addPayment() {
    if (parseFloat(this.money)) {
      this.orderDetail.payment = (this.orderDetail && this.orderDetail.payment) ? this.orderDetail.payment : [];
      let isPaymentFound = 0;
      let current_value = parseFloat(this.money);
      let current_total = this.compute();

      let total = current_value;
      if (current_total < 0) {
        total = (current_value + current_total);
        this.orderDetail.changeAmount = current_total;
      }
      if (this.paymentWay.code == APIHelper.REWARD_PAYMENT_TYPE) {
        total = -total;
      }
      this.orderDetail.payment.forEach(payment_detail => {
        if (this.clearAmountFlag) {
          if (!payment_detail.isRemovable && payment_detail.name == this.paymentWay.name && payment_detail.code == this.paymentWay.code) {
            isPaymentFound = 1;
            payment_detail.total += total;
          }
        } else {
          if (payment_detail.name == this.paymentWay.name && payment_detail.code == this.paymentWay.code) {
            isPaymentFound = 1;
            payment_detail.total += total;
          }
        }
      });
      if (!isPaymentFound) {
        this.paymentWay.total = total;
        this.orderDetail.payment.push(this.paymentWay);

      }
      this.money = "";
      this.reward_amount = "";
      this.checkForRewardsPoint();
    }

  }

  checkForRewardsPoint() {
    if (this.rewards_points == 0) {
      this.isRewardEnable = false;
    }
  }
  checkForOnlineOffline() {
    this.is_online = navigator.onLine ? true : false;
    function updateOnlineStatus(event) {
      this.is_online = navigator.onLine ? true : false;
    }
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  }

  loadPaymentClass() {
    let payment_sub_class = this.shopConfig.payment_class;
    if (payment_sub_class) {
      this.paymentTypes.forEach(payment_type => {
        for (var x in payment_sub_class) {
          if (payment_type.code == x) {
            payment_type.sub_class = payment_sub_class[x];
          }
        }
      });
    }

    let tempPaymentTypes = [];
    this.paymentTypes.forEach(payment_type => {
      if (tempPaymentTypes.length == 5) {
        this.groupedPaymentTypes.push(tempPaymentTypes);
        tempPaymentTypes = [];
      }

      if (payment_type.sub_class && payment_type.sub_class.length > 0) {

        let count = 0;
        payment_type.sub_class.forEach(sub_class => {
          if (tempPaymentTypes.length == 5) {
            this.groupedPaymentTypes.push(tempPaymentTypes);
            tempPaymentTypes = [];
          }
          if (sub_class.is_active) {
            tempPaymentTypes.push({
              name: sub_class.name,
              type: payment_type.name,
              is_sub_class: true,
              code: payment_type.code,
              img: payment_type.img,
              img_active: payment_type.img_active
            });
          } else {
            count++;
          }
        });

        if (count == payment_type.sub_class.length) {
          tempPaymentTypes.push({
            name: payment_type.name,
            type: payment_type.name,
            code: payment_type.code,
            img: payment_type.img,
            img_active: payment_type.img_active,
            is_sub_class: false,
          });
        }
      } else {
        tempPaymentTypes.push({
          name: payment_type.name,
          type: payment_type.name,
          code: payment_type.code,
          img: payment_type.img,
          img_active: payment_type.img_active,
          is_sub_class: false,
        });
      }
    })

    if (tempPaymentTypes.length > 0) {
      this.groupedPaymentTypes.push(tempPaymentTypes);
      tempPaymentTypes = [];
    }
    this.setDefaultPaymentWay();
  }

  slideTo(newIndex: number, slides) {
    if (newIndex === this.currentSlideIndex) { return; }
    let direction = this.currentSlideIndex > newIndex ? "left" : "right";

    this.slider = slides;
    this.currentSlide = slides.children[this.currentSlideIndex];
    this.currentSlideIndex = newIndex;
    while (slides.querySelector(".item .active")) {
      slides.querySelector(".item .active").classList.remove('active');
    }
    this.comingSlide = slides.children[newIndex];
    this.comingSlide.classList.add("active");
    this.slideOut(this.currentSlide, direction);
    this.slideIn(this.comingSlide, direction);
  }
  private slideIn(el, dir) {
    if (!el) {
      console.warn("No elemen provided to slide in. El is: ", el);
      return;
    }
    var c = this.slider.clientWidth,
      time = 4096 / c,
      prep = dir === "left" ? "-" : "";

    el.style.left = "0px";
    /*function run() {
      c -= 16;
      if (c >= 0){
        setTimeout(function() {
          el.style.left = prep + c + "px";
          run();
        }, 100);
      } else {
        el.style.left = "0px";
      }
    }
    run();*/
  }
  private slideOut(el, dir) {
    if (!el) {
      console.warn("No elemen provided to slide out. El is: ", el);
      return;
    }
    var screen = this.slider.clientWidth,
      time = 4096 / screen,
      c = 1, prep = dir === "left" ? "" : "-";

    el.style.left = prep + 100 + "%";
    /*function run () {
      c += 16;
      if (c <= screen) {
        setTimeout(function() {
          el.style.left = prep + c + "px";
          run();
        }, 100);
      } else {
        el.style.left = prep + 100 + "%";
        return;
      }
    }
    run();*/
  }

  detectCartSections(cartItems) {
    //console.log("cartItems in detectCartSections"+JSON.stringify(cartItems));

    var sectionAlreadyPresentFlag = false;
    var cartSections = [];
    var cartSectionsObj = {
      sectionName: "",
      itemCount: 0,
      subTotal: 0,
      items: []
    };

    if (cartItems) {
      for (var i = 0; i < cartItems.length; i++) {

        var item;
        if (cartItems[i].selected_variant_item) {
          item = JSON.parse(JSON.stringify(cartItems[i].selected_variant_item));
        } else {
          item = JSON.parse(JSON.stringify(cartItems[i]));
        }

        // console.log("ITEM"+JSON.stringify(item));
        // if((item.tags && item.tags.length > 0) || (item.receipt_sections && item.receipt_sections.length > 0)){
        //   var tags = (item.tags && item.tags.length > 0) ? item.tags : item.receipt_sections;
        // }
        // console.log("Tags:"+JSON.stringify(tags));

        cartSectionsObj = {
          sectionName: "",
          itemCount: 0,
          subTotal: 0,
          items: []
        };

        sectionAlreadyPresentFlag = false;
        for (var j = 0; j < cartSections.length; j++) {
          if (this.findSection(item.tags) == cartSections[j].sectionName) {
            sectionAlreadyPresentFlag = true;
            cartSections[j].itemCount = cartSections[j].itemCount + item.item_count;

            cartSections[j].subTotal = cartSections[j].subTotal + this.getCartItemValue(item);

            // cartSections[j].items.push(cartItems[i]);
            cartSections[j].items.push(item);
            // console.log("Old this.cartSections["+j+"] :"+JSON.stringify(this.cartSections[j]));
            //console.log("cart item value!!! "+this.getCartItemValue(cartItems[i]));
          }
        }
        if (!sectionAlreadyPresentFlag) {
          cartSectionsObj.sectionName = this.findSection(item.tags);
          cartSectionsObj.itemCount = item.item_count;
          cartSectionsObj.subTotal = this.getCartItemValue(item);

          // cartSectionsObj.items.push(cartItems[i]);
          cartSectionsObj.items.push(item);
          cartSections.push(cartSectionsObj);
          // console.log("New this.cartSections"+ JSON.stringify(cartSectionsObj));
        }
      }
    }
    return cartSections;
  }

  getCartItemValue(item) {
    var sellPrice = 0;
    //adding item price
    sellPrice = item.unit_price * item.item_count;
    if (this.shopConfiguration.show_post_tax_price && this.shopConfiguration.item_wise_tax && item.tax_value) {
      sellPrice = sellPrice + item.tax_value;
    }

    //adding modifier price to item price
    if (item.attributes.length > 0) {
      for (var attr = 0; attr < item.attributes.length; attr++) {
        if (item.attributes[attr].unit_price && item.attributes[attr].count) {
          sellPrice = sellPrice + (item.attributes[attr].unit_price * item.attributes[attr].count);
        }
      }
    }

    if (item.discount && (item.discount_type || item.discountUnits)) {
      let discType = item.discount_type ? item.discount_type : item.discountUnits;
      if (discType != '%') {
        sellPrice -= item.discount;
      } else {
        sellPrice -= (item.unit_price * item.item_count) * (item.discount / 100);
      }
    }

    sellPrice -= item.other_discounts_total;

    return sellPrice;
  }

  findSection(tags: any) {
    /*var receipt_sections:any = {
                'bar':["wine","beer","whisky","alcohol","drink"],
                'food':["burger","fries"]
                 };*/

    var shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    var receipt_sections: any = shop_config["shop_info"]["receipt_sections"];
    var section;

    //localStorage.receipt_sections
    for (var i = 0; i < tags.length; i++) {
      for (section in receipt_sections) {
        for (var j = 0; j < receipt_sections[section].length; j++) {
          // console.log("receipt_sections[section][j]"+receipt_sections[section][j] + " tags["+i+"] :"+tags[i]);
          if (receipt_sections[section][j].toLowerCase() === tags[i].toLowerCase()) {
            // console.log(receipt_sections[section][j]+"==="+tags[i] + "   "+(receipt_sections[section][j] === tags[i]));
            // console.log("returning section:"+ section);
            // console.log("items of section "+section+ " "+this.getCartItemsOfGivenSection(section));
            return section;
          }
        }
      }
    }
    return "Section Not Found";
  }

  getCurrencySymbol() {
    //var shopConfigData = JSON.parse(localStorage.getItem('shop_config'));
    if (this.shopConfiguration.currency) {
      var currency = this.shopConfiguration.currency;
      if (currency.toUpperCase() == 'INR') {
        return 'Rs.';
      } else {
        var currency_symbol = this.shopConfiguration.currency_symbol;
        var symbol = currency_symbol.toLowerCase();
        if (symbol.charAt(0) === 'u') {
          symbol = symbol.split('u')[1];
          symbol = String.fromCharCode(parseInt(symbol, 16));
        }
        // console.log(symbol);
        return symbol;
      }
    } else {
      return "";
    }
  }


  //method to print address in proper format (i.e on separate lines and without breaking any words midway)
  printAddress(fullAddress: string) {
    //console.log("fullAddress:"+fullAddress);
    var addressArray = fullAddress.split(' ');
    //console.log("addressArray"+addressArray);
    var addressLine1 = '';
    var addressLine2 = '';
    var addressLine3 = '';
    var j = -1;
    var k = -1;

    //creating address line 1
    addressArray.some((element, i) => {
      if (addressLine1.length < 48 && (addressLine1.length + element.length) <= 48) {
        addressLine1 = addressLine1 + element + ' ';
        return false;
      } else {
        j = i;
        return true;
      }
    })
    this.builder.addText(addressLine1.trim() + '\n');

    //creating address line 2 (if line 1 can't accomodate the entire address)
    if (j > 0) {
      for (; j < addressArray.length; j++) {
        if (addressLine2.length < 48 && (addressLine2.length + addressArray[j].length) <= 48) {
          addressLine2 = addressLine2 + addressArray[j] + ' ';
        } else {
          k = j;
          break;
        }
      }
      this.builder.addText(addressLine2.trim() + '\n');
    }

    //creating address line 3 (if line 1 & 2 can't accomodate the entire address)
    if (k > 0) {
      for (; k < addressArray.length; k++) {
        if (addressLine3.length < 48 && (addressLine3.length + addressArray[k].length) <= 48) {
          addressLine3 = addressLine3 + addressArray[k] + ' ';
        }
      }
      this.builder.addText(addressLine3.trim() + '\n');
    }
  }

  capitalizeFirstLetter(string: string) {
    return string.trim().charAt(0).toUpperCase() + string.trim().slice(1).toLowerCase();
  }



  checkIsSpecialOrder(item) {
    return item.special_order_information && item.special_order_information.supplier_id;
  }

  commonPrint(tBillInfo) {
    let billInfo = JSON.parse(JSON.stringify(tBillInfo));
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    this.shopConfiguration = JSON.parse(localStorage.getItem('shop_configuration'));
    let itemWiseDiscount = 0.0;
    let itemWiseOtherDiscount = 0.0;
    let itemWiseOtherDiscounts = []
    billInfo.cart_items.forEach(item => {
      if (item.item_price) {
        item.item_price = parseFloat(item.item_price);
        item.unit_price = item.item_price;
      }

      if (item.discount && (item.discount_type || item.discountUnits)) {
        let discType = item.discount_type ? item.discount_type : item.discountUnits;
        if (discType != '%') {
          item['discount_amount'] = item.discount;
        } else {
          item['discount_amount'] = (item.item_price * item.item_count) * (item.discount / 100);
        }
        itemWiseDiscount += item['discount_amount'];
      }

      if (!item['other_discounts']) {
        item['other_discounts'] = []
      }

      var totalOtherDiscForItem = 0.0
      item['other_discounts'].forEach(disc => {
        totalOtherDiscForItem += disc.amount;
        itemWiseOtherDiscount += disc.amount;

        if (itemWiseOtherDiscounts.filter(discCode => discCode == disc.discount_code).length == 0) {
          itemWiseOtherDiscounts.push(disc.discount_code)
        }
      })

      item['other_discounts_total'] = totalOtherDiscForItem;
    });
    let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};
    let printData = {
      assigny: this.currentUnit && this.currentUnit.assigny ? this.currentUnit.assigny : null,
      user_name: loggedInUser.name,
      section: this.currentUnit && this.currentUnit.location ? this.currentUnit.location : null,
      seat_no: this.currentUnit && this.currentUnit.index ? this.currentUnit.index : 0,
      table_no: this.currentUnit && this.currentUnit.tables && this.currentUnit.tables.length>0 ? this.currentUnit.tables.join(', ') : '',
      delivery_date: billInfo.deliveryDate ? billInfo.deliveryDate : null,
      createdAt: new Date(),
      client_reference_code: billInfo.order_id ? billInfo.order_id : "N/A",
      cart_items: billInfo.cart_items,
      cart_tax: 0,//added below      
      cart_value: billInfo.cart_value,//added below,
      totalPrice: billInfo.valued_at ? billInfo.valued_at : 0,
      discount: 0,//added belo//added beloww
      discountUnit: "",
      discount_amount: 0,//added below    
      cash_discount: 0,//added below    
      cashDiscountUnit: "",//added below      
      cash_discount_amount: 0,//added below    
      on_subtotal_other_discounts: [],//added below    
      group_taxes: billInfo.group_tax ? billInfo.group_tax : [],
      extraCharges: billInfo.extra_charges ? billInfo.extra_charges : [],
      rounding_value: billInfo.rounding_value ? billInfo.rounding_value : 0,
      total_discount: 0,//added below     
      customer_gstin: this.currentUnit && this.currentUnit.userInfo ? this.currentUnit.userInfo.GSTIN : null,
      customer_name: this.currentUnit && this.currentUnit.userInfo ? this.currentUnit.userInfo.consumer_name : null,
      customer_address_1: this.currentUnit && this.currentUnit.userInfo ? this.currentUnit.userInfo.address_1 : null,
      customer_mobile_no: this.currentUnit && this.currentUnit.userInfo ? this.currentUnit.userInfo.mobile_no : null,
      discountReason: "",//added below      
      overall_discount: 0,
      note: billInfo.note ? billInfo.note : "",
      splitPaymentList: billInfo.splitPaymentList ? billInfo.splitPaymentList : []
    }
    printData.splitPaymentList.forEach(sp => {
      sp.name = sp.title;
    });

    let otherDiscounts = 0.0;
    if (this.currentUnit && this.currentUnit.other_discounts && this.currentUnit.other_discounts.length > 0) {
      this.currentUnit.other_discounts.forEach(discount => {
        if (!discount.item_discounts_info || (discount.item_discounts_info && discount.item_discounts_info.length == 0)) {
          var discCopy = JSON.parse(JSON.stringify(discount));
          if (discount.type.toLowerCase() == "cashback") {
            discCopy.name = "Cashback to be received:"
          } else {
            if (discCopy.name.toLowerCase().indexOf('discount') < 0) {
              discCopy.name = discCopy.name + " (DISCOUNT)"
            }
          }
          discCopy.value = Math.abs(discCopy.value);
          printData['on_subtotal_other_discounts'].push(discCopy)
        }
        otherDiscounts += Math.abs(discount.value);
      })
    }

    let overAllDiscount = 0.0;
    let orderDetail = this.stateGuard.obtain("payment.component:orderDetail");
    if (orderDetail) {
      if (orderDetail.cart_tax) {
        printData["cart_tax"] = orderDetail.cart_tax;
      }

      if (orderDetail.discountReason) {
        printData["discountReason"] = orderDetail.discountReason;
      }

      if (orderDetail.discountUnit && orderDetail.discount) {
        printData.discount = orderDetail.discount;
        printData.discountUnit = orderDetail.discountUnit;
        printData.discount_amount = orderDetail.discount_amount;
        overAllDiscount += orderDetail.discount_amount;
      }

      if (orderDetail.cashDiscountUnit && orderDetail.cash_discount) {
        printData.cash_discount = orderDetail.cash_discount,
          printData.cashDiscountUnit = orderDetail.cashDiscountUnit,
          printData.cash_discount_amount = orderDetail.cash_discount_amount,
          overAllDiscount += orderDetail.cash_discount_amount;
      }
    }

    if (this.shopConfiguration && this.shopConfiguration.show_post_tax_price) {
      printData["cart_value"] += printData.cart_tax;
    }
    printData["cart_value"] -= itemWiseDiscount + itemWiseOtherDiscount;
    // total discount (for "you saved")
    printData.total_discount = itemWiseDiscount + overAllDiscount + otherDiscounts;
    this.printHelper.firePrint(printData, 'bill');
  }

  createKOTobject() {
    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    // Pratik Sir's change, which avoids new KOT generation
    if (tempUnit && tempUnit.visitors[0]) {
      var tempVisitors: any = JSON.parse(JSON.stringify(tempUnit.visitors[0]))
      var newkot = tempVisitors.kot.slice(tempVisitors.kot.length - 1, tempVisitors.kot.length);
      if (newkot.length > 0) {
        this.prepareToPrintKOT(newkot[0], tempUnit.covers);
      }
    }
  }

  prepareToPrintKOT(kot, covers) {
    let tempUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));

    let printerData = JSON.parse(localStorage.getItem('shop_printers'));
    if (printerData) {
      var printers_list = printerData;
      var selfServicePrinter;

      //TODO chk if self service printer is usb printer
      printers_list.forEach(printer => {
        if (printer.print_type.indexOf('self_service') != -1 || printer.print_type.indexOf('kitchen') != -1) {
          selfServicePrinter = printer;
        }
      })

      if (shop_config.shop_info.kitchen_departments.length > 0) {
        //print KOT department wise
        var departmentWiseItems = this.groupItemsDepartmentWise(kot.items);
        for (var dept in departmentWiseItems) {
          var printer = this.findRequiredPrinter(printers_list, dept);
          if (printer == "Printer not found") {
            this.messagingService.add({ severity: 'success', summary: 'Printer not found', detail: 'Neither Wifi nor USB Printer not found for department ' + dept });
          } else {
            if (localStorage.getItem('PRINT_UTILITY')) {
              this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
            }
            if (this.printerUtility) {
              this.kotHelper.printKotOnUtility(kot, departmentWiseItems[dept], printer, covers, false);
            } else {
              if (printer.ip_address && printer.ip_address != "" && printer.ip_address != null) {
                //printer is a Wifi printer
                this.printKOT(kot, departmentWiseItems[dept], printer);
              } else {
                //printer is a USB printer
                this.kotHelper.printKOTonUSBPrinter(kot, departmentWiseItems[dept], false);
              }
            }
          }
        }
      } else if (shop_config.shop_info.kitchen_departments.length == 0) {
        if (localStorage.getItem('PRINT_UTILITY')) {
          this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
        }
        if (this.printerUtility) {
          this.kotHelper.printKotOnUtility(kot, departmentWiseItems[dept], printer, covers, false);
        } else {
          if (selfServicePrinter.ip_address && selfServicePrinter.ip_address != "" && selfServicePrinter.ip_address != null) {
            //printer is a Wifi printer
            this.printKOT(kot, kot.items, selfServicePrinter);
          } else {
            //printer is a USB printer
            this.kotHelper.printKOTonUSBPrinter(kot, kot.items, false);
          }
        }
      }

    } else {
      //console.log("Printer data could not be fetched");
      this.messagingService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
    }
  }

  groupItemsDepartmentWise(items) {
    var departmentWiseItems: any = {};
    items.forEach(item => {
      if (item.kitchen_department != "" && item.kitchen_department != null) {
        if (item.kitchen_department in departmentWiseItems) {
          departmentWiseItems[item.kitchen_department].push(item);
        } else {
          departmentWiseItems[item.kitchen_department] = [];
          departmentWiseItems[item.kitchen_department].push(item);
        }
      } else {
        if ('default' in departmentWiseItems) {
          departmentWiseItems['default'].push(item);
        } else {
          departmentWiseItems['default'] = [];
          departmentWiseItems['default'].push(item);
        }
      }
    })
    return departmentWiseItems;
  }

  findRequiredPrinter(printers_list, dept) {
    var printer_found = false;
    if (dept && dept != 'default') {
      for (var i = 0; i < printers_list.length; i++) {
        if ((printers_list[i].kitchen_department == dept.trim() || printers_list[i].print_type.indexOf('self_service') != -1) && (printers_list[i].print_type.indexOf('kitchen') != -1 || printers_list[i].print_type.indexOf('self_service') != -1)) {
          printer_found = true;
          return printers_list[i];
        }
      }
    }
    if (!printer_found) {
      return "Printer not found"
    }
  }


  printKOT(fullkot, dept_items, dept_printer) {
    let tempUnit = this.stateGuard.obtain('selects-box.component:currentUnit');

    this.builder = new epson.ePOSBuilder();
    var shopName = localStorage.getItem('shop_name');
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText('\n' + shopName + '\n');
    this.builder.addText('KOT No: ' + fullkot.kot_number + '\n');
    var today = new Date();
    var date = new Date().toJSON().slice(0, 10);
    var time = (today.getHours().toString().length == 1 ? '0' + today.getHours() : today.getHours()) + ':' + (today.getMinutes().toString().length == 1 ? '0' + today.getMinutes() : today.getMinutes()) + ':' + (today.getSeconds().toString().length == 1 ? '0' + today.getSeconds() : today.getSeconds());
    this.builder.addText('Kot Date: ' + date + ' ' + time + '\n');

    if (tempUnit && tempUnit.location) {
      this.builder.addText('Section: ' + this.capitalizeFirstLetter(tempUnit.location) + '\n');
    }
    if (tempUnit && tempUnit.tables && tempUnit.tables.length > 0) {
      this.builder.addText('Table No: ' + tempUnit.tables[0] + '\n');
    }
    if (fullkot.kot_id.split("_")[2] != 0) {
      this.builder.addText('Seat No: ' + fullkot.kot_id.split("_")[2] + '\n');
    }

    this.builder.addText('---------------------------------------------\n');

    // Add each line item to receipt (function below)
    var cartItems = dept_items;

    cartItems = this.kotHelper.groupSameItems(cartItems);

    for (var i = 0; i < cartItems.length; i++) {
      var attributes: any = [];
      if (cartItems[i].attributes.length > 0) {
        attributes = cartItems[i].attributes;
      }
      var combo_item_codes = [];
      if (cartItems[i].combo_item_codes.length > 0) {
        combo_item_codes = cartItems[i].combo_item_codes;
      }
      var included_combo_items = [];
      if (cartItems[i].included_combo_items) {
        if (cartItems[i].included_combo_items.length > 0) {
          included_combo_items = cartItems[i].included_combo_items;
        }
      }
      var name = this.capitalizeFirstLetter(cartItems[i].item_name);
      this.createKotItemLine(name, cartItems[i], attributes, combo_item_codes, included_combo_items);
    }
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText('---------------------------------------------\n');

    // Add 2 line feeds and cut
    this.builder.addFeed();
    this.builder.addFeed();
    this.builder.addCut(this.builder.CUT_FEED);
    var ipAddr = '192.168.1.199';
    if (dept_printer) {
      ipAddr = dept_printer.ip_address;
    } else {
      ipAddr = '192.168.1.201';
    }
    var address = 'http://' + ipAddr + '/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000';
    var epos = new epson.ePOSPrint(address);
    // Check if printer cover is open
    epos.oncoveropen = function () { alert('coveropen'); };
    // Send print job
    try {
      epos.send(this.builder.toString());
    } catch (e) {
      console.log(e)
    }
  }


  // create Items for kot
  createKotItemLine(productName: any, item: any, attributes: any, combo_item_codes: any, included_combo_items: any) {
    this.builder.addPageBegin();
    let itemNameAndCountString = item.item_count + " x " + productName;
    let dHeight = Math.ceil(itemNameAndCountString.length / 35) * 30; //we suppose 35 character will come in one line
    this.builder.addPageArea(60, 0, 436, dHeight);
    this.builder.addText(itemNameAndCountString);
    this.builder.addPageArea(436, 0, 100, dHeight);
    this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
    this.builder.addPageEnd();

    if (attributes.length > 0) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addTextFont(this.builder.FONT_C);
      for (var attr = 0; attr < attributes.length; attr++) {
        this.builder.addPageBegin();

        var attrName = this.capitalizeFirstLetter(attributes[attr].name);
        let attrNameAndCountString = attributes[attr].count + " x " + attrName;
        let adHeight = Math.ceil(attrNameAndCountString.length / 35) * 30;
        this.builder.addPageArea(106, 0, 456, adHeight);
        this.builder.addText(attrNameAndCountString);
        this.builder.addPageEnd();
      }
      this.builder.addTextFont(this.builder.FONT_A);
    }
    if (combo_item_codes.length > 0) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addTextFont(this.builder.FONT_C);
      for (var attr = 0; attr < combo_item_codes.length; attr++) {
        this.builder.addPageBegin();

        var item_name = this.capitalizeFirstLetter(combo_item_codes[attr].item_name);
        let attrNameAndCountString = combo_item_codes[attr].quantity_in_combo + " x " + item_name;
        let adHeight = Math.ceil(attrNameAndCountString.length / 35) * 30;
        this.builder.addPageArea(106, 0, 456, adHeight);
        this.builder.addText(attrNameAndCountString);
        this.builder.addPageEnd();
      }
      this.builder.addTextFont(this.builder.FONT_A);
    }
    if (included_combo_items.length > 0) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addTextFont(this.builder.FONT_C);
      for (var attr = 0; attr < included_combo_items.length; attr++) {
        this.builder.addPageBegin();

        var item_name = this.capitalizeFirstLetter(included_combo_items[attr].item_name);
        let attrNameAndCountString = included_combo_items[attr].quantity + " x " + item_name;
        let adHeight = Math.ceil(attrNameAndCountString.length / 35) * 30;
        this.builder.addPageArea(106, 0, 456, adHeight);
        this.builder.addText(attrNameAndCountString);
        this.builder.addPageEnd();
      }
      this.builder.addTextFont(this.builder.FONT_A);
    }


    if (item.note) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addPageBegin();
      let noteString = "Note: " + item.note;
      let ndHeight = Math.ceil(noteString.length / 35) * 30;
      this.builder.addPageArea(106, 0, 456, ndHeight);
      this.builder.addText(noteString);
      this.builder.addPageEnd();
      this.builder.addTextFont(this.builder.FONT_A);
    }
  }

  // function to club all items having same item code,modifiers and discount
  groupSameItems(cart_items) {
    var cartItems = [];
    var itemGroups = {};

    cart_items.forEach(item => {
      if (item.item_code in itemGroups) {
        var itemFound = false;
        var item_mod_attrs = [];
        item_mod_attrs = item.attributes.filter(attr => !attr.is_variant_attribute)
        for (var i in itemGroups[item.item_code]) {
          var sameItem_mod_attrs = [];
          sameItem_mod_attrs = itemGroups[item.item_code][i].attributes.filter(attr => !attr.is_variant_attribute)
          if (this.areDiscountsSame(item, itemGroups[item.item_code][i])) {
            if (this.areModifiersSame(item_mod_attrs, sameItem_mod_attrs)) {
              itemGroups[item.item_code][i].item_count += item.item_count;

              if (item.discount && (item.discount_type || item.discountUnits)) {
                let discType = item.discount_type ? item.discount_type : item.discountUnits;
                if (discType != '%') {
                  itemGroups[item.item_code][i]['discount_amount'] += item.discount;
                  itemGroups[item.item_code][i].discount += item.discount;
                } else {
                  itemGroups[item.item_code][i]['discount_amount'] += (item.unit_price * item.item_count) * (item.discount / 100);
                }
              }

              if (itemGroups[item.item_code][i]['other_discounts'].length > 0 && item['other_discounts'].length > 0) {
                itemGroups[item.item_code][i]['other_discounts'].forEach(disc => {
                  var itemDisc = item['other_discounts'].filter(d => d.discount_code == disc.discount_code)
                  disc.amount += itemDisc[0] && itemDisc[0].amount ? itemDisc[0].amount : 0
                  itemGroups[item.item_code][i]['other_discounts_total'] += itemDisc[0] && itemDisc[0].amount ? itemDisc[0].amount : 0
                })
              }

              if (this.shopConfiguration.item_wise_tax) {
                itemGroups[item.item_code][i].tax_value = itemGroups[item.item_code][i].item_count * (item.tax_value / item.item_count)
              }

              //todo discount amount subtraction
              itemGroups[item.item_code][i].attributes.forEach(attr => {
                if (!attr.is_variant_attribute) {
                  attr.count += item.attributes.filter(at => attr.attribute_code == at.attribute_code)[0] ? item.attributes.filter(at => attr.attribute_code == at.attribute_code)[0].count : 0;
                }
              })

              itemFound = true;
              break;
            }
          }
        }
        if (!itemFound) {
          itemGroups[item.item_code].push(item);
        }
      } else {
        itemGroups[item.item_code] = [];
        itemGroups[item.item_code].push(item);
      }
    })

    for (var item_code in itemGroups) {
      cartItems = cartItems.concat(itemGroups[item_code])
    }
    return cartItems;
  }


  areDiscountsSame(item1, item2) {
    return this.areItemWiseDiscountSame(item1, item2) && this.areOtherDiscountsSame(item1, item2);
  }

  areItemWiseDiscountSame(item1, item2) {
    if (item1.discount_amount && item2.discount_amount) { //Condition 1
      if ((item1.discountUnits == "%" && item2.discountUnits == "%") || (item1.discountUnits != "%" && item2.discountUnits != "%")) { //Condition 2
        if ((item1.discountUnits == "%" && item2.discountUnits == "%")) { //Condition 3
          return item1.discount == item2.discount;
        } else {
          //Condition 3 else
          return true
        }
      } else {
        //Condition 2 else
        return false
      }
    } else {
      //Condition 1 else
      return !item1.discount_amount && !item2.discount_amount
    }
  }

  areOtherDiscountsSame(item1, item2) {

    if (item1.other_discounts.length > 0 && item2.other_discounts.length > 0) { //Condition 1

      if (item1.other_discounts.length == item2.other_discounts.length) { //Condition 2

        item1.other_discounts.forEach(disc1 => {
          var commonDisc = item2.other_discounts.filter(disc2 => disc2.discount_code == disc1.discount_code)
          if (commonDisc.length > 0) { //Condition 3
            var disc2 = commonDisc[0];

            if ((disc1.discount_type == "%" && disc2.discount_type == "%") || (disc1.discount_type != "%" && disc2.discount_type != "%")) { //Condition 4
              if ((disc1.discount_type == "%" && disc2.discount_type == "%") && (disc1.discount != disc2.discount)) {
                return false;
              }
            } else {
              //Condition 4 else
              return false
            }


          } else {
            //Condition 3 else
            return false
          }

        })
        return true // returns true if all conditions are satisfied
      } else {
        //Condition 2 else
        return false
      }

    } else {
      //Condition 1 else
      return !(item1.other_discounts.length > 0) && !(item2.other_discounts.length > 0)
    }
  }

  //function to check if 2 modifier arrays are identical
  areModifiersSame(mod_array_1, mod_array_2) {
    if (mod_array_1.length != mod_array_2.length) {
      return false
    } else {
      var commonMods = []
      mod_array_1.forEach(mod1 => {
        mod_array_2.forEach(mod2 => {
          //console.log(mod1.attribute_code + "==" + mod2.attribute_code + " && " + mod1.count + "==" + mod2.count + ' => '+ ((mod1.attribute_code == mod2.attribute_code) && (mod1.count == mod2.count)));
          if ((mod1.attribute_code == mod2.attribute_code)) { // && (mod1.count == mod2.count)
            commonMods.push(mod1);
          }
        })
      })
      if (commonMods.length == mod_array_1.length) {
        //console.log("commonMods : "+ JSON.stringify(commonMods))
        //console.log("mod_array_1 : "+ JSON.stringify(mod_array_1))
        return true
      } else {
        return false
      }
    }
  }
}
