import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls:['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  shop_name:any = localStorage.getItem("shop_name") || "NA";
  is_online_partner_available = localStorage.getItem("is_online_partner_available");
    
  constructor(private route:Router) { 
  }

  ngOnInit(){
  }
}