import { Component, OnInit, Input } from '@angular/core';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AssignStaffModalComponent } from '../assign-staff-modal/assign-staff-modal.component';
import { MenuComponent } from '../menu/menu.component';
import { BasketComponent } from '../basket/basket.component';
import { CoversModalComponent } from '../covers-modal/covers-modal.component';
import { TableTransferModalComponent } from '../table-transfer-modal/table-transfer-modal.component';
import { Subscription } from 'rxjs';

import { APIHelper } from '../helpers/api-helper';
import { CustomerMessage } from '../customer-message.service';
import { CustomerService } from '../helpers/services/customer.service';
import { MembershipService } from '../helpers/services/membership.service';

import { Router, Event, NavigationStart } from '@angular/router';
import { StateGuardService } from '../state-guard.service';
import { MessageService } from 'primeng/api';
import { PlacesMessage } from '../places-message.service';
import { CaptainAppHelper } from '../helpers/captain-app-helper';


@Component({
  selector: 'app-selects-box',
  templateUrl: './selects-box.component.html',
  styleUrls: ['./selects-box.component.css']/*,
  providers:[MenuComponent,BasketComponent]*/
})
export class SelectsBoxComponent implements OnInit {
  @Input() deliveryType: number;
  // @ViewChild('coversModal') public coversModal: ModalDirective;
  // , TemplateRef, ViewChild
  public modalRef: BsModalRef;
  public subscriptions: Subscription[] = [];
  public messages: string[] = [];
  public activeTableCount: number = 0;
  private activePhases = [];
  currentPlace: any;
  public isCaptainAppAvailable = false;
  public places = [];
  public loggedInUserRole: any = "";  
  /*public places = this.stateGuard.obtain('selects-box.component:places') || [];*/

  constructor( private customerMessageService:CustomerMessage,private modalService: BsModalService, private router: Router,
    private stateGuard: StateGuardService,private customerService:CustomerService,private messageService:MessageService,private placesMessage:PlacesMessage,
    private captainAppHelper: CaptainAppHelper,private membershipService:MembershipService) { }

  ngOnInit() {
    try {
      let loggedInUser: any = JSON.parse(localStorage.getItem("loggedInUser")) || null;
      if(loggedInUser && loggedInUser.role) {
        this.loggedInUserRole = loggedInUser.role;
      }
    } catch(e) {}
    var self = this;  
    this.placesMessage.getPlacesInfo().subscribe(message => {
      //this.places = this.stateGuard.obtain('selects-box.component:places') || [];    
      //this.selectPlace(this.places[0]);
      this.initialGetData()
    });      
    self.initialGetData();  
    this.isCaptainAppAvailable = this.captainAppHelper.isCaptainAppAvailable();
  }

  initialGetData(){
    this.places = this.stateGuard.obtain('selects-box.component:places') || [];    
    if(this.places && this.places.length == 0){
      this.createTableSections().then((data)=>{
      });
    }

    if(!this.places || !this.places[0]) {
      return;
    }
    
    this.activeTableCount=0;
    this.selectPlace(this.places[0]);
    this.places.forEach(place => {
      place.units.forEach(unit => {
        if (unit["activePhase"]) {         
          this.activeTableCount++;
          this.startActivePhase(unit,new Date());
        }
      });
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {        
        //this.stateGuard.entrust('selects-box.component:places', this.places);
      }
    });
  }


  openTableTransferModal(){

    this.subscriptions.push(this.modalService.onHide.subscribe(() => {

      if (this.modalRef.content.decision == 'transfer') {
        let selectedFromTable = JSON.parse(JSON.stringify(this.modalRef.content.selecetedFromTable));
        let selectedToTable = JSON.parse(JSON.stringify(this.modalRef.content.selecetedToTable));
        let AllPlaces = this.stateGuard.obtain('selects-box.component:places') || [];
        AllPlaces.forEach(place => {
          if(place.name == selectedFromTable.location || place.name == selectedToTable.location){
            place.units.forEach(unit => {
              if (place.name == selectedFromTable.location && unit.tables.join(',') === selectedFromTable.tables.join(',')) {
                if(unit.covers){
                  delete unit.covers;
                }
                if(unit.visitors){
                  delete unit.visitors;
                }
                if(unit.activePhase){
                  if(unit.activePhase.ticker){
                    clearInterval(unit.activePhase.ticker);
                  }
                  if(unit.activePhase.timeOut){
                    clearTimeout(unit.activePhase.timeOut);
                  }
                  delete unit.activePhase;
                }
                if(unit.groupedTaxes){
                  delete unit.groupedTaxes;
                }
                if(unit.isOnHold){
                  delete unit.isOnHold;
                }

                if(unit.isFromOnHold){
                  delete unit.isFromOnHold;
                }

                if(unit.chosen){
                  unit.chosen = false;
                }

                if(unit.index){
                  delete unit.index;
                }

                let sendData = {
                  section_name               : unit.location,
                  tables                     : unit.tables,
                  covers                     : 0,
                  assigny                    : unit.assigny,
                  default_assigny            : unit.default_assigny,
                  table_status               : 'idle',
                  lock_index                 : unit.lock_index ? unit.lock_index : 0, 
                  temp_client_reference_code : null,
                  started_at                 : null, 
                  kot_request_number         : 0,
                  is_clear                   : true,
                  is_checkout                : false,
                  kot_ready_number           : 0,
                  kot_request_description    : "",
                  is_checkout_call           : false,
                  visitors                   : []
                }
                this.captainAppHelper.syncParticularTable(sendData,false,false);
              }
              if(place.name == selectedToTable.location && unit.tables.join(',') === selectedToTable.tables.join(',')){
                  unit['visitors'] = selectedFromTable.visitors;
                  unit['covers'] = selectedFromTable.covers;
                  unit['activePhase'] = selectedFromTable.activePhase;
                  unit['groupedTaxes'] = selectedFromTable.groupedTaxes;
                  unit['isFromOnHold'] = selectedFromTable.isFromOnHold;
                  unit['isOnHold'] = selectedFromTable.isOnHold;
                  unit['index'] = selectedFromTable.index;
                  unit['delivery_type'] = (selectedFromTable.delivery_type)? selectedFromTable.delivery_type : APIHelper.IMMEDIATE;
                  unit['lock_index'] = selectedFromTable.lock_index;
                  unit = this.setItemPrice(unit);
                  this.captainAppHelper.syncTable(unit,false);
              }
              if(unit.fromTransferTableIndex){
                delete unit.fromTransferTableIndex;
              }
              if(unit.toTransferTableIndex){
                delete unit.toTransferTableIndex;
              }
            });
            
            if(selectedFromTable.tables.length>1){
              let result = [];
              place.units.forEach(placeUnit => {

                if ( placeUnit.tables.length > 1  && selectedFromTable.tables.join(',') == placeUnit.tables.join(',') &&!placeUnit.visitors) {
                  placeUnit.tables.forEach(num => {
                    result.push({ tables: [num], default_assigny: placeUnit.default_assigny, assigny: placeUnit.assigny, chosen: false, location: placeUnit.location });
                  });
                } else {
                  result.push(placeUnit);
                }
              });
              result = result.sort((a: any, b: any) => {
                return Number(a.tables[0]) > Number(b.tables[0]) ? 1 : -1;
              });
              place.units = result;
            }
          }
        });
      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(TableTransferModalComponent,
      { class: 'spartan-modal covers' });

    let section_list = [];
    let shop_configuration = JSON.parse(localStorage.getItem('shop_configuration'));
    if(shop_configuration && shop_configuration.table_configuration[0]){
      shop_configuration.table_configuration.forEach(section =>{
        section_list.push(section.section);
      });
    }
    this.modalRef.content.decision = "";
    this.modalRef.content.sectionList = section_list;
    this.modalRef.content.places = this.places;
  }

  //change price as per section wise price
  public setItemPrice(unit) {
    if(localStorage.getItem('isRestaurant') == 'true' && unit && unit.location && Array.isArray(unit.visitors)) {
      unit.visitors.forEach(visitor => {
        if(visitor && visitor.dishes[0]) {
          visitor.dishes[0].forEach(it => {
            if(it.selected_variant_item && Array.isArray(it.selected_variant_item.section_wise_price)) {
              it.selected_variant_item.section_wise_price.forEach(swp => {
                if(unit.location == swp.section) {
                  it.selected_variant_item.unit_cost = (swp.unit_cost || swp.unit_cost==0) ? parseFloat(swp.unit_cost) : it.selected_variant_item.unit_cost;
                  it.selected_variant_item.unit_price = (swp.unit_price || swp.unit_price == 0) ? parseFloat(swp.unit_price) : it.selected_variant_item.unit_price;
                  it.tempData["temp_unit_price"] = it.selected_variant_item.unit_price;
                  it.tempData["unit_price"] = it.selected_variant_item.unit_price;
                }
              });
            } else if(Array.isArray(it.section_wise_price)) {
              it.section_wise_price.forEach(swp => {
                if(unit.location == swp.section) {
                  it.unit_cost = (swp.unit_cost || swp.unit_cost == 0) ? parseFloat(swp.unit_cost) : it.unit_cost;
                  it.unit_price = (swp.unit_price || swp.unit_price == 0) ? parseFloat(swp.unit_price) : it.unit_price;
                  it.tempData["temp_unit_price"] = it.unit_price;
                  it.tempData["unit_price"] = it.unit_price;
                }
              });
            }
          })
        }        
      })      
    }
    return unit;
  }

  public openAssignStaffModal(newState) {

    this.currentPlace.assign = newState;
    if(!newState){
      var chosenUnits = [],
        chosenPlaces = this.currentPlace.units.filter(unit => {
          if (unit.chosen) {
            chosenUnits = [...chosenUnits, ...unit.tables];
          }

          return unit.chosen;
        });
      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'assign') {
          this.messageService.add({ severity: 'success', summary: 'User assigned to selected tables', detail: '' });
          this.assignStaff(this.modalRef.content.assigny);

        } else {
          chosenPlaces.forEach(place => {
            place.chosen = false;
          })
        }
        this.unsubscribe();
      }));
      this.modalRef = this.modalService.show(AssignStaffModalComponent,
        { class: 'spartan-modal assign-stuff' });
        this.modalRef.content.placesToAssaign = chosenPlaces;
        this.modalRef.content.unitsStr = chosenUnits.join(", ");
        this.modalRef.content.assignStaff = this.assignStaff;
        this.modalRef.content.decision = "";
        this.modalRef.content.assigny = null;
        let staffList = [];

        if(localStorage.getItem('shop_user_list')){
          let list = JSON.parse(localStorage.getItem('shop_user_list'));
          if(list.length>0){
            list.forEach(staff=>{
              staffList.push({id:staff.id,name:staff.name});
            });
          }
        }
        this.modalRef.content.staffList = staffList;


    }
  }
  clearCustomerInfo(): void {
    let sData:any = {
      auth_token : APIHelper.DEFAULT_TOKEN,
      name: "",
      consumer_name:"",
      email: "",
      mobile_no: "",
      zip_code: "",
      address_1: "",
      city: "",
      lastVisit: "",
      lastCheck: 0,
      lastCheckCurrency: "INR",
      points: 0,
      place: "here",
      distance: ' - kms',
      available_membership_order_balance: null,
      available_membership_order_count: null,
      membership_name: null,
      places : [
        {id: 1, name: 'here'},
        {id: 2, name: 'home'},
        {id: 3, name: 'pickup'}
      ]
    };
    localStorage.setItem("current_customer_info",JSON.stringify(sData));
    localStorage.setItem("delivery_type",APIHelper.IMMEDIATE);
    localStorage.setItem('register_customer_info_flag','false');
    localStorage.removeItem('register_customer_data');
    localStorage.removeItem("order_employer_detail");
    localStorage.removeItem("order_partner_detail");
    localStorage.removeItem("order_staff_user_detail");
    //this.customerMessageService.sendCustomerInfo(sData,false);
  }

  setUserInfo(token,place,GSTIN){
    this.customerService.info(token).subscribe((res) => {
      var result = res.json(); 
      var sData:any = {}    
      if (result.status === 0) {
          sData = {
            auth_token: token,
            user_id: (result.result.user_id)? result.result.user_id : "",
            consumer_name: (result.result.consumer_name)? result.result.consumer_name : "",
            email: (result.result.email)? result.result.email : "",
            mobile_no: result.result.phone ? result.result.phone : "",
            zip_code: result.result.zip_code ? result.result.zip_code : null,
            address_1: result.result.address ? result.result.address : "",
            alt_address: (result.result.alt_address && result.result.alt_address.length>0) ? result.result.alt_address : [],
            city: result.result.city,
            state: result.result.state,
            last_purchase: (result.result.last_purchase)? result.result.last_purchase :0,
            last_visited: (result.result.last_visited)? result.result.last_visited : new Date(),
            rewards_points: (result.result.rewards_points)? result.result.rewards_points:0,
            diary_amt: (result.result.diary_amt)? result.result.diary_amt:0,
            profile_img_url: (result.result.profile_img_url)? result.result.profile_img_url : null,
            GSTIN: (GSTIN)? GSTIN:null,
            points: (result.result.rewards_points)? result.result.rewards_points:0,
            lastCheck: (result.result.last_purchase)? result.result.last_purchase :0,
            lastVisit: (result.result.last_visited)? result.result.last_visited : new Date(),
          }
          if(place == APIHelper.IMMEDIATE){
            sData['place'] = 'here';
          }else if(place == APIHelper.PICK_UP){
            sData['place'] = 'pickup';
          }else if(place == APIHelper.HOME_DELIVERY){
            sData['place'] = 'home';
          }
		      localStorage.removeItem('GSTIN');          
          let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
          tempUnit.userInfo = sData;
          localStorage.setItem("current_customer_info",JSON.stringify(sData));

          let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));    
          if(shop_config && shop_config.enable_membership) {
            this.membershipService.getUserMembershipInfo(result.result.user_id).subscribe((membershipInfo) => {
              var memResult = membershipInfo.json();                         
              if (memResult.status === 0) {
                  var available_balance = memResult.result.user_membership.payment_info.available_balance;
                  var membership_name = memResult.result.user_membership.membership_info.membership_name;
                  var membership_status = memResult.result.user_membership.enroll_info.membership_status;
                    
                  sData["membership_name"] = membership_name;
                  sData["available_membership_order_balance"] = available_balance;
                  sData["membership_status"] = membership_status;
                  if(available_balance && memResult.result.user_membership.membership_info.is_fee_order_linked) {
                      var discount_list = JSON.parse(localStorage.getItem("discount_list")) || [];
                      if(Array.isArray(discount_list)) {
                        for(var i=0;i<discount_list.length;i++) {
                          if(Array.isArray(discount_list[i].discount_specifics.restrictions.membership_plans) && 
                            discount_list[i].discount_specifics.restrictions.membership_discount_type == "NUMBER_OF_ORDER" &&
                            discount_list[i].discount_specifics.restrictions.membership_plans.indexOf(memResult.result.user_membership.membership_info.id) != -1) {
                            sData["available_membership_order_count"] = available_balance/discount_list[i].discount_specifics.amount;
                          }
                        }
                      }
                  }
              }
              this.customerMessageService.sendCustomerInfo(sData,false);            
            },err => {
              this.customerMessageService.sendCustomerInfo(sData,false);
            });   
          } else {
            this.customerMessageService.sendCustomerInfo(sData,false);
          }
      }
    });

  }


  public openCoversModal(targetUnit) {
    var self = this;
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'done') {
        this.afterAddCovers(targetUnit,this.modalRef.content.coversAmount);
      } else {
        targetUnit.chosen = false;
      }
      this.unsubscribe();
    }));

    this.modalRef = this.modalService.show(CoversModalComponent,
      { class: 'spartan-modal covers' });
    this.modalRef.content.decision = "";
    this.modalRef.content.coversAmount = "";
    this.modalRef.content.targetUnit = targetUnit;
  }

  public afterAddCovers(targetUnit,covers) {
    targetUnit.covers = covers;
    targetUnit.visitors = [];

    let info = JSON.parse(localStorage.getItem('current_customer_info'));
    let flag = localStorage.getItem('register_customer_info_flag');
    let delivery_type = localStorage.getItem("delivery_type");
    let order_employer_detail = JSON.parse(localStorage.getItem("order_employer_detail")) || null ;
    let order_staff_user_detail = JSON.parse(localStorage.getItem("order_staff_user_detail")) || null ;
    let order_partner_detail = JSON.parse(localStorage.getItem("order_partner_detail")) || [];
    let GSTIN = localStorage.getItem("GSTIN");
    
    if(order_staff_user_detail && order_staff_user_detail.id){
      targetUnit.assigny = order_staff_user_detail.id
    } else if(targetUnit.default_assigny) {
      targetUnit.assigny = targetUnit.default_assigny
    }

    if(flag == 'true'){
      let data = JSON.parse(localStorage.getItem('register_customer_data'));
      targetUnit.register_customer_info_flag = true;
      targetUnit.register_customer_data = data;
      targetUnit.selected_partner_list = order_partner_detail;
      targetUnit.selected_employer = order_employer_detail;
      targetUnit.delivery_type = delivery_type;
      targetUnit.lock_index = 0;
      targetUnit.userInfo = info ? info : null;

      this.clearCustomerInfo();
      localStorage.removeItem('GSTIN');
    }else{
      targetUnit.token = info.auth_token;
      targetUnit.selected_partner_list = order_partner_detail;
      targetUnit.selected_employer = order_employer_detail;
      targetUnit.delivery_type = delivery_type;
      targetUnit.lock_index = 0;
      if(navigator.onLine && info.auth_token && info.auth_token != "00000000000000000000000000000000") {
        this.setUserInfo(info.auth_token,delivery_type,GSTIN);
      }
      this.clearCustomerInfo();
    }
    
    let device_type = JSON.parse(localStorage.getItem('device_key'));              
    let terminal_name = (device_type && device_type.terminal_name)? device_type.terminal_name: null;
    let terminal_id = (device_type && device_type.terminal_id)? device_type.terminal_id: null;
    for (var i = targetUnit.covers; i >= 0; i--) {
      let temp_client_reference_code = terminal_name+"-"+terminal_id+'/WBP' + (new Date()).getTime()+i;            
      targetUnit.visitors.push(Object.assign({}, { dishes: [[], []],kot:[],deleted_items:[],temp_client_reference_code:temp_client_reference_code}));
    }
    this.startActivePhase(targetUnit,new Date());
    this.stateGuard.entrust('selects-box.component:currentUnit', targetUnit);
    setTimeout(() => { this.router.navigate(['pages/register/food-ordering']) }, 0);
  }
  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  public startActivePhase(unit,started_from) {    
    var ticker, previousePhasing = null,
      timeOut = setTimeout(function () {        
        autoSwitchPhase(unit);
      }, 600000),
      autoSwitchPhase = function (unit) {
        unit.activePhase.name = "red";
      },
      timerPipe = function (time) {
        var hours = Math.floor(time / 3600),
          minutes = Math.floor((time - hours * 3600) / 60),
          seconds = time - hours * 3600 - minutes * 60;

        return ("00" + hours + ":").substr(-3) + ("00" + minutes + ":").substr(-3) + ("00" + seconds).substr(-2);
      },
      runTimer = function () {
        var theUnit = unit;
        return function () {
          ticker = setInterval(function () {
          var time = Math.round((new Date().valueOf() - theUnit.activePhase.timeStamp) / 1000);
            theUnit.activePhase.timerText = timerPipe(time);
          }, 1000);
          unit.activePhase.ticker = ticker;
        }
      };

    if (unit.activePhase) {
      previousePhasing = JSON.parse(JSON.stringify(unit.activePhase));
      this.interruptActivePhase(unit);
    }    
    if(!started_from) {started_from = new Date()}
    unit.activePhase = {
      lastCPNumber: previousePhasing && previousePhasing.lastCPNumber ? previousePhasing.lastCPNumber : null,
      name: previousePhasing ? previousePhasing.name : "green",
      timeOut,
      timeStamp: previousePhasing ? previousePhasing.timeStamp : new Date(started_from).valueOf(),
      timerText: previousePhasing ? previousePhasing.timerString : "00:00:00"
    };
    
    runTimer()();
  }

  assignStaff(assigny) {
    this.currentPlace.units.forEach(unit => {
      if (unit.chosen) {
        unit.assigny = assigny;
        unit.default_assigny = assigny;
        unit.chosen = false;
      }
    });
  }
  private interruptActivePhase(unit) {
    clearInterval(unit.activePhase.ticker);
    clearTimeout(unit.activePhase.timeOut);
    delete unit.activePhase;
  }
  getEngagment(place) {
    var engaged = 0, all = 0;

    place.units.forEach(unit => {
      engaged += unit.activePhase? 1 : 0;
      all += unit.tables.length;
    });

    return engaged + "/" + all;
  }

  no_of_checkout_tables(place) {
    var no_of_checkout_tables = 0;

    place.units.forEach(unit => {
      no_of_checkout_tables += unit.is_checkout_call? 1 : 0;
    });
    return no_of_checkout_tables;
  }

  // Function to create table and section data
  createTableSections() {
    return new Promise<boolean>((resolve,reject) => {
      var shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
      var tablesConfig = [];
      if(shopConfig && shopConfig.table_configuration)  {
        shopConfig.table_configuration.forEach(table => {
          var count=0;
          var tables = {
            name: table.section,
            chosen: false,
            units: [],
            mergeOpen: false,
            assign:false
          };

          if(table.tableData){

            table.tableData.forEach(i => {
             var unit = {
                location: table.section,
                tables: [i.name],
                //covers: i.covers,
                assigny: '',
                default_assigny:'',
                lock_index: 0,
                chosen:false
              };
              count+=1;
              tables.units.push(unit);
            });

          }else{

           var unit = {
              location: table.section,
              tables:  [count+1],
              //covers: i.covers,
              assigny: '',
              default_assigny: '',
              lock_index: 0,
              chosen:false
            };
            count+=1;
            tables.units.push(unit);

          }
          tablesConfig.push(tables);
        });
      }

      this.stateGuard.entrust("selects-box.component:places", tablesConfig);
      this.places = this.stateGuard.obtain('selects-box.component:places') || [];

      resolve(true);
    });
  }


  public selectPlace(newPlace) {
    this.places.forEach(place => {
      place.chosen = false;
    });
    newPlace.chosen = true;
    this.currentPlace = newPlace || {};
  }
  toggleMergeState(newState) {
    this.currentPlace.mergeOpen = newState;
    //if(!this.currentPlace.mergeOpen) {
      this.currentPlace.units.forEach(unit => {
        if (unit.chosen) { unit.chosen = false; }
      });
    //}
  }
  handleUnitClick(unit) {
    unit.chosen = !unit.chosen;
    if (!this.currentPlace.mergeOpen && !this.currentPlace.assign) {
      if (unit.covers || unit.activePhase) {
        //unit.isOnHold = false;
        this.stateGuard.entrust("selects-box.component:currentUnit", unit);
        let token = (unit && unit.token)? unit.token : APIHelper.DEFAULT_TOKEN;
        let place = (unit && unit.delivery_type)? unit.delivery_type : APIHelper.IMMEDIATE;
        let userInfo = (unit && unit.userInfo)? unit.userInfo : null;
        if(userInfo){
          localStorage.setItem("current_customer_info",JSON.stringify(userInfo));
          this.customerMessageService.sendCustomerInfo(userInfo,false);
        }else if(token && token != "00000000000000000000000000000000"){
          this.getCustomerInfo(token,place);
        }

        setTimeout(() => { this.router.navigate(['pages/register/food-ordering']) }, 0);
      } else {       
        let userInfo = (unit && unit.userInfo)? unit.userInfo : null; 
        if(userInfo){ this.customerMessageService.sendCustomerInfo(userInfo,false); }
        var local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
        if(local_config && (local_config.disable_cover_entry == true || local_config.disable_cover_entry == "true")) {
          this.afterAddCovers(unit,0);
        } else {
          this.openCoversModal(unit);
        }
      }
    }
  }


  getCustomerInfo(token,place){
    if(navigator.onLine) {
      this.customerService.info(token).subscribe((res) => {
          var result = res.json(); 
          var sData:any = {}    
          if (result.status === 0) {
            if(token != APIHelper.DEFAULT_TOKEN){
              sData = {
                auth_token: token,
                consumer_name: (result.result.consumer_name)? result.result.consumer_name : "",
                email: (result.result.email)? result.result.email : "",
                mobile_no: result.result.phone ? result.result.phone : "",
                zip_code: result.result.zip_code ? result.result.zip_code : null,
                address_1: result.result.address ? result.result.address : "",
                city: result.result.city,
                state: result.result.state,
                last_purchase: (result.result.last_purchase)? result.result.last_purchase :0,
                last_visited: (result.result.last_visited)? result.result.last_visited : new Date(),
                rewards_points: (result.result.rewards_points)? result.result.rewards_points:0,
                diary_amt: (result.result.diary_amt)? result.result.diary_amt:0,
                points: (result.result.rewards_points)? result.result.rewards_points:0,
                lastCheck: (result.result.last_purchase)? result.result.last_purchase :0,
                lastVisit: (result.result.last_visited)? result.result.last_visited : new Date(),
              }
              if(place == APIHelper.IMMEDIATE){
                sData['place'] = 'here';
              }else if(place == APIHelper.PICK_UP){
                sData['place'] = 'pickup';
              }else if(place == APIHelper.HOME_DELIVERY){
                sData['place'] = 'home';
              }
              localStorage.setItem("current_customer_info",JSON.stringify(sData));
              this.customerMessageService.sendCustomerInfo(sData,false);
            }
          }
      });
    }
  }

  merge(newState) {
    let mergedUnit = { tables: [], default_assigny:'', assigny: '', chosen: true,location:this.currentPlace.name },
      result = [];

    //count how many table has order on hold and included in merge
    let onHoldTableCount = 0;
    this.currentPlace.units.forEach(unit => {
      if (unit.chosen && (unit.isFromOnHold || (unit.visitors && unit.visitors.lenth>0))) {        
        mergedUnit = JSON.parse(JSON.stringify(unit));
        onHoldTableCount++;
      }
    });

    if(onHoldTableCount > 1) {
        this.messageService.add({ severity: 'error', summary: "You can't merge 2 active tables", detail: '' });
        return;
    } 
    
    //mergedUnit.tables = [];
    this.currentPlace.units.forEach(unit => {
      if (unit.chosen) {
        if (unit.activePhase) {
          //this.interruptActivePhase(unit);
        }
        
        unit.tables.forEach(ta => {
          if(mergedUnit.tables.indexOf(ta) == -1) {
            mergedUnit.tables.push(ta);
          }
        })        
      } else {
        result.push(unit);
      }
    });
    if (mergedUnit.tables.length < 2) {
      return;
    }
    // mergedUnit.tables.sort((a, b) => {
    //   return a > b ? 1 : -1;
    // });
    result.push(mergedUnit);
    result = result.sort((a: any, b: any) => {
      return Number(a.tables[0]) > Number(b.tables[0]) ? 1 : -1;
    });

    this.currentPlace.mergeOpen = newState;
    this.currentPlace.units = result;

    this.updateMergeTable(mergedUnit);
    if(onHoldTableCount == 0){
      this.handleUnitClick(mergedUnit);
    }

    return result;
  }

  updateMergeTable(currentTable){        
    var sendData = JSON.parse(JSON.stringify(currentTable))
    this.captainAppHelper.syncTable(sendData,false);
  }

  unmerge(newState) {
    let result = [];

    let isTableHasOrder = false;
    this.currentPlace.units.forEach(unit => {
      if (unit.chosen && unit.tables.length > 1 && (unit.isFromOnHold || (unit.visitors && unit.visitors.lenth>0))) {
        isTableHasOrder = true;
      }
    });

    if(isTableHasOrder) {
        this.messageService.add({ severity: 'error', summary: "You can't split active table", detail: '' });
        return;
    } 

    this.currentPlace.units.forEach(unit => {
      if (unit.chosen && unit.tables.length > 1) {
        unit.tables.forEach(num => {
          result.push({ tables: [num],default_assigny: unit.default_assigny, assigny: unit.assigny, chosen: false, location: unit.location});
          if (unit.chosen && unit.activePhase) {
            this.interruptActivePhase(unit);
          }
        });
      } else {
        result.push(unit);
      }
    });    
    
    result = result.sort((a: any, b: any) => {
      return Number(a.tables[0]) > Number(b.tables[0]) ? 1 : -1;
    });
    
    this.currentPlace.mergeOpen = newState;
    this.currentPlace.units = result;
    return result;
  }

}
