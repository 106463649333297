
import {map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import {Http, Headers} from '@angular/http';
import { APIHelper } from '../api-helper';


@Injectable()
export class CommonService {
    /*private notify = new Subject<any>();*/
    /**
     * Observable string streams
     */
    /*notifyObservable$ = this.notify.asObservable();*/

    private notifyPrinter = new Subject<boolean>();
    notifyPrinterObservable$ = this.notifyPrinter.asObservable();
    constructor(
        private http:Http
        ) { }
/*
    public notifyOther(data: any) {
        if (data) {
            this.notify.next(data);
        }
    }*/

    updatePrinterSetting(data:boolean){
        this.notifyPrinter.next(data);
    }

    getLattitudeLongitudeCode(Zipcode){
        return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address='+Zipcode+"&key="+APIHelper.GKEY).pipe(
              map(res => res.json()));
    }

    getCurrentVersionInfo(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CURRENT_VERSION,data);
    }


    getInventoryUpdate(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_INVENTORY_UPDATE,data);
    }

    getItemStock(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_ITEM_STOCK_LIST,data);
    }


    getPrinters(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_PRINTER, data);
    }

    validateLicense(shopCode: string) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_LICENCE_VALIDITY, 'shop_code=' + shopCode);
    }

    getPrintersSections(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_PRINTER_SECTIONLIST, data);
    }

    addPrinter(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_ADD_PRINTER, data);
    }

    updatePrinter(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_UPDATE_PRINTER, data);
    }

    deletePrinter(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_DELETE_PRINTER, data);
    }

    renewLicence(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_RENEW_LICENCE, data);
    }
    
    verifyMobile(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_VERFIY_MOBILE, data);
    }
    

}