import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIHelper } from '../../helpers/api-helper';
import { ReportService } from '../../helpers/services/report.service';
import { ReportHelper } from '../../helpers/report-helper';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-item-cancellation-report',
    templateUrl: './item-cancellation-report.component.html',
    styleUrls: ['./item-cancellation-report.component.css'],
    providers: [ReportService]
})
export class ItemCancellationReportComponent implements OnInit {

    public reportData: any;
    public currencySymbol: any;
    public isDataLoad: boolean = false;
    totalCancellationAmount: any;
    public reportHelper: ReportHelper = new ReportHelper(this.http);
    public shopConfiguration: any;
    public merchantProfile: any;
    printerUtility: boolean;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private report: ReportService,
        private http: HttpClient,
        private messageService: MessageService) {
    }

    ngOnInit() {

        this.shopConfiguration = JSON.parse(localStorage.getItem('shop_configuration'));
        if (!this.shopConfiguration) {
            this.router.navigate(['verify-pin']);
            return;
        }
        this.merchantProfile = JSON.parse(localStorage.getItem('merchant_profile'));

        let date = new Date();
        let mm: any = new Date(date.toDateString()).getMonth();
        mm = mm + 1;
        if (mm.toString().length === 1) {
            mm = '0' + mm;
        }
        let dd: any = new Date(date.toDateString()).getDate();
        let yy: any = new Date(date.toDateString()).getFullYear();
        if (dd.toString().length === 1) {
            dd = '0' + dd;
        }
        let shop_code = localStorage.getItem('shop_code');
        let from_date = yy + '-' + mm + '-' + dd + ' 00:00:00';
        let to_date = yy + '-' + mm + '-' + dd + ' 23:59:59';
        let report_type = 'kot_deleted_items_detail';
        let tags: any[];
        let sub_report_type = "";

        let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
        this.currencySymbol = shop_config.currency;


        if (!navigator.onLine) {
            this.getOfflineReport().then(items_summary => {
                this.showReportData(items_summary);
            });
        } else {
            this.report.getReport(shop_code, from_date, to_date, report_type, sub_report_type, tags).subscribe((report) => {
                var res = report.json();
                if (res.status == 0) {
                    if (res.result) {
                        this.showReportData(res.result.items_summary);
                    }
                    if (res.date) {
                        if (res.date.from_date) {
                            var tempDate = new Date(res.date.from_date);
                            res.date.from_date = tempDate.toLocaleDateString() + " " + tempDate.toLocaleTimeString();
                        }
                        if (res.date.to_date) {
                            var tempDate = new Date(res.date.to_date);
                            res.date.to_date = tempDate.toLocaleDateString() + " " + tempDate.toLocaleTimeString();
                        }
                    }
                }
            });
        }
    }

    showReportData(item_summary) {
        let reportDataList = [];
        this.totalCancellationAmount = 0;
        for (let key in item_summary) {
            let tempObj = {};

            let obj = {
                'KOT0001': 'KOT_DAMAGED',
                'KOT0002': 'KOT_CUSTOMER_CANCELLED',
                'KOT0003': 'KOT_DELIVERY_DELAYED',
                'KOT0004': 'KOT_OTHER',
            }

            tempObj['name'] = obj[key];
            tempObj['total_price'] = item_summary[key].total_price;
            tempObj['item_list'] = item_summary[key].item_list;

            this.totalCancellationAmount += item_summary[key].total_price;
            reportDataList.push(tempObj);
        }

        this.reportData = reportDataList;
        this.isDataLoad = true;
    }

    getReportData() {
        return this.reportData;
    }

    getOfflineReport() {
        let self = this;
        return new Promise<any>(function (resolve, reject) {
            let shopConfig: any = JSON.parse(localStorage.getItem("shop_configuration")) || {};
            let placedOrder: any = JSON.parse(localStorage.getItem("temp_orders_list")) || [];
            let offlineOrder: any = JSON.parse(localStorage.getItem("bill_queue")) || [];
            let paymentTypes = [
                { payment_type: "SAVE_TO_DIARY_TYPE", payment_type_code: APIHelper.SAVE_TO_DIARY_TYPE },
                { payment_type: "CASH_ON_DELIVERY_TYPE", payment_type_code: APIHelper.CASH_ON_DELIVERY_TYPE },
                { payment_type: "CASH_IN_HAND_TYPE", payment_type_code: APIHelper.CASH_IN_HAND_TYPE },
                { payment_type: "CARD_PAYMENT_TYPE", payment_type_code: APIHelper.CARD_PAYMENT_TYPE },
                { payment_type: "COUPON_PAYMENT_TYPE", payment_type_code: APIHelper.COUPON_PAYMENT_TYPE },
            ];

            if (!Array.isArray(placedOrder)) { placedOrder = []; }
            if (!Array.isArray(offlineOrder)) { offlineOrder = []; }
            placedOrder = placedOrder.concat(offlineOrder);

            let kotItems = [];

            placedOrder.forEach(order => {
                if (order.kot_items && order.kot_items.length > 0) {
                    order.kot_items.forEach(kot_item => {
                        if (kot_item.items && kot_item.items.length > 0) {
                            kot_item.items.forEach(item => {
                                if (!item.is_billed) {
                                    console.log(item.is_billed)
                                    kotItems.push(item);
                                }
                            });
                        }
                    });
                }
            });

            if (kotItems.length == 0) {
                resolve([]);
            } else {
                self.reportHelper.getKotDeletedItemDetail(kotItems).then(result => {
                    resolve(result);
                })
                    .catch(error => {
                        console.log(error);
                        resolve([]);
                    })
            }
        })
    }

    prepareToPrintCancellationReport() {

        var printerObj = this.reportHelper.checkPrinters();
        if (localStorage.getItem('PRINT_UTILITY')) {
            this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
        }
        if (this.printerUtility) {
            var cancellationObject = { 'total_cancellation_amt': this.totalCancellationAmount, 'report_data': this.getReportData() };
               
            if (cancellationObject) {
                this.reportHelper.printCancellationReportOnUtility(cancellationObject);
            } else {
                this.messageService.add({ severity: 'success', summary: 'Report data not available at this time', detail: 'Report data not available at this time' });
            }
        }
        else if (printerObj) {
            if (printerObj.wifi_present) {
                var cancellationObject = { 'total_cancellation_amt': this.totalCancellationAmount, 'report_data': this.getReportData() };
                if (cancellationObject) {
                    this.reportHelper.printCancellationReportonWifi(cancellationObject);
                } else {
                    this.messageService.add({ severity: 'success', summary: 'Report data not available at this time', detail: 'Report data not available at this time' });
                }
            } else if (printerObj.USB_present) {
                let printContents = document.getElementById('itemCancellationReportPrintDiv').innerHTML;
                this.reportHelper.printUSBReport(printContents);
            } else {
                console.log("Neither USB not Wifi printer available for printing the report");
                alert('Neither USB not Wifi printer available for printing the report');
            }
        } else {
            console.log("Printer data could not be fetched");
            alert('Printer data could not be fetched at this time');
            // this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
        }
    }

    getCurrencySymbol() {
        if (this.shopConfiguration.currency) {
            var currency = this.shopConfiguration.currency;
            if (currency.toUpperCase() == 'INR') {
                return 'Rs.';
            } else {
                var currency_symbol = this.shopConfiguration.currency_symbol;
                var symbol = currency_symbol.toLowerCase();
                if (symbol.charAt(0) === 'u') {
                    symbol = symbol.split('u')[1];
                    symbol = String.fromCharCode(parseInt(symbol, 16));
                }
                return symbol;
            }
        } else {
            return "";
        }
    }

}
