
import { groupBy, sumBy, forOwn, values } from 'lodash-es';
import "assets/js/epos-print-3.0.0.js";
import { MessageService } from "primeng/api";
import { GeneralHelper } from './genral-helper';
import { HttpHeaders } from "@angular/common/http";
import { BaseConfig } from "./api-config";
import { HttpClient } from "@angular/common/http";

declare var epson: any;

export interface PrintItemReportUtility {
  printerName: string;  
  paperSize: number;
  itemReport: any;
  shopName: string;
  phone: string;
  address: string;
}

export interface PrintCancellationReportUtility {
  printerName: string;
  paperSize: number;
  cancellation: any;
  shopName: string;
  phone: string;
  address: string;
}

export interface PrintSUmmaryReportUtility {
  printerName: String;
  paperSize: number;
  summary: any;
  consumerReportDate: any;
  flag: any,
  shopName: String;
  phone: String;
  address: String;
  currencySymbol: string;
}
export class ReportHelper {
  printSummaryReportOnUtility(consumerReportData: any, consumerReportDate: any, flag: any, currencySymbol) {
    this.shopConfiguration = JSON.parse(
      localStorage.getItem("shop_configuration")
    );
    let printerData = JSON.parse(localStorage.getItem("shop_printers"));
    if (printerData) {
      var printers_list = printerData;
      var printerFound = false;
      printers_list.forEach(printerObj => {
        if (
          (printerObj.kitchen_department.trim() == "" ||
            printerObj.kitchen_department == null) &&
          (printerObj.print_type.indexOf("receipt") != -1 ||
            printerObj.print_type.indexOf("self_service") != -1)
        ) {
          printerFound = true;
          const data: PrintSUmmaryReportUtility = {
            printerName: printerObj.name,
            paperSize:printerObj.paper_size,
            summary: consumerReportData,
            consumerReportDate: consumerReportDate,
            flag: flag,
            shopName: this.shopConfiguration.shop_info.name,
            phone: this.shopConfiguration.shop_info.shop_phone_number,
            address: this.shopConfiguration.shop_info.address,
            currencySymbol: currencySymbol
          }

          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            })
          };

          this.http.post(BaseConfig.config.PRINT_API + BaseConfig.config.PRINT_SUMMARY_REPORT, JSON.stringify(data), httpOptions).subscribe(data => {
            console.log(data);
          });

        }
      });
      if (!printerFound) {
        console.log("Printer not found");
        alert("Wifi Printer not found to print report");
        // this.messageService.add({ severity: 'success', summary: 'Wifi Printer not found', detail: 'Wifi Printer not found to print report' });
      }
    } else {
      console.log("Printer data could not be fetched");
      alert("Printer data could not be fetched at this time");
      // this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
    }
  }

  constructor(private http: HttpClient) { }


  printSectionWiseReportOnUtility(reportDataArray: any) {
    this.shopConfiguration = JSON.parse(
      localStorage.getItem("shop_configuration")
    );
    let printerData = JSON.parse(localStorage.getItem("shop_printers"));
    if (printerData) {
      var printers_list = printerData;
      var printerFound = false;
      printers_list.forEach(printerObj => {
        if (
          (printerObj.kitchen_department.trim() == "" ||
            printerObj.kitchen_department == null) &&
          (printerObj.print_type.indexOf("receipt") != -1 ||
            printerObj.print_type.indexOf("self_service") != -1)
        ) {
          printerFound = true;
          const data: PrintItemReportUtility = {
            printerName: printerObj.name,
            paperSize:printerObj.paper_size,
            itemReport: reportDataArray,
            shopName: this.shopConfiguration.shop_info.name,
            phone: this.shopConfiguration.shop_info.shop_phone_number,
            address: this.shopConfiguration.shop_info.address
          }

          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            })
          };

          this.http.post(BaseConfig.config.PRINT_API + BaseConfig.config.PRINT_ITEM_REPORT, JSON.stringify(data), httpOptions).subscribe(data => {
            console.log(data);
          });

        }
      });
      if (!printerFound) {
        console.log("Printer not found");
        alert("Wifi Printer not found to print report");
        // this.messageService.add({ severity: 'success', summary: 'Wifi Printer not found', detail: 'Wifi Printer not found to print report' });
      }
    } else {
      console.log("Printer data could not be fetched");
      alert("Printer data could not be fetched at this time");
      // this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
    }

  }

  printCancellationReportOnUtility(cancellationObject: any) {
    this.shopConfiguration = JSON.parse(
      localStorage.getItem("shop_configuration")
    );
    let printerData = JSON.parse(localStorage.getItem("shop_printers"));
    if (printerData) {
      var printers_list = printerData;
      var printerFound = false;
      printers_list.forEach(printerObj => {
        if (
          (printerObj.kitchen_department.trim() == "" ||
            printerObj.kitchen_department == null) &&
          (printerObj.print_type.indexOf("receipt") != -1 ||
            printerObj.print_type.indexOf("self_service") != -1)
        ) {
          printerFound = true;
          const data: PrintCancellationReportUtility = {
            printerName: printerObj.name,
            paperSize:printerObj.paper_size,
            cancellation: cancellationObject,
            shopName: this.shopConfiguration.shop_info.name,
            phone: this.shopConfiguration.shop_info.shop_phone_number,
            address: this.shopConfiguration.shop_info.address
          }

          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            })
          };

          this.http.post(BaseConfig.config.PRINT_API + BaseConfig.config.PRINT_CANCELLATION_REPORT, JSON.stringify(data), httpOptions).subscribe(data => {
            console.log(data);
          });

        }
      });
      if (!printerFound) {
        console.log("Printer not found");
        alert("Wifi Printer not found to print report");
        // this.messageService.add({ severity: 'success', summary: 'Wifi Printer not found', detail: 'Wifi Printer not found to print report' });
      }
    } else {
      console.log("Printer data could not be fetched");
      alert("Printer data could not be fetched at this time");
      // this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
    }

  }

  private builder;
  private shopConfiguration;
  private merchantProfile;
  private messageService: MessageService = new MessageService();

  getUserExpensesByOrderIdS(orders): Promise<any> {
    return new Promise<any>(function (resolve, reject) {
      var totalExpenses = {
        diary_amount: 0,
        reward_points: 0,
        advanced: 0,
        outstanding: 0,
        discount_amount: 0
      };
      orders.forEach(order => {
        totalExpenses["discount_amount"] += Math.abs(order.discount);
        order.diary_history.forEach(data => {
          if (data.comment == "credit" || data.comment == "credit_due") {
            totalExpenses["diary_amount"] += Math.abs(
              parseFloat(data.diary_amount)
            );
          } else if (data.comment == "used" || data.comment == "used_due") {
            totalExpenses["advanced"] += Math.abs(
              parseFloat(data.diary_amount)
            );
          } else if (
            data.comment == "outstanding" ||
            data.comment == "outstanding_due"
          ) {
            totalExpenses["outstanding"] += Math.abs(
              parseFloat(data.diary_amount)
            );
          } else if (
            data.comment == "advance" ||
            data.comment == "advance_due"
          ) {
            totalExpenses["advanced"] += Math.abs(
              parseFloat(data.diary_amount)
            );
          }
        });
      });
      resolve(totalExpenses);
    });
  }

  orderSummaryPaymentTypes(orders, paymentTypes): Promise<any> {
    return new Promise<any>(function (resolve, reject) {
      var totalValue = orders
        .map(o => o.valued_at)
        .reduce((a, b) => a + b, 0.0);
      var customers = new Set(orders.map(o => o.user_id)).size;

      var paymentSummary = paymentTypes.map(function (pT) {
        var paymentTypeName = pT["payment_type"];
        var paymentType = pT["payment_type_code"];
        var pSum = {};
        var oSum = [];
        try {
          orders.forEach(o => {
            oSum = oSum.concat(
              o.split_payment
                .filter(p => paymentType == p.payment_code)
                .map(q => q.amount)
            );
          });
        } catch (e) {
          oSum = [];
        }

        pSum[paymentType] = [
          paymentTypeName,
          oSum.reduce((a, b) => a + b, 0.0),
          oSum.length
        ];

        return pSum;
      });

      resolve({
        total: totalValue,
        payment_summary: paymentSummary,
        customer_count: customers
      });
    });
  }

  orderSummaryPaymentSubTypes(orders, paymentTypes): Promise<any> {
    return new Promise(function (resolve, reject) {
      var sub_class_wise_payment_summary = {};
      try {
        paymentTypes.forEach(function (pT) {
          var paymentTypeName = pT["payment_type"];
          var paymentType = pT["payment_type_code"];

          var orderSubClassDetails = [];
          var tempSubClassDetails = {};
          try {
            orderSubClassDetails = orders
              .map(o => o.split_payment_details)
              .filter(p => {
                return (
                  paymentType in p && p[paymentType].payment_sub_class_info
                );
              })
              .map(q => q[paymentType].payment_sub_class_info);
          } catch (e) {
            console.log("e", e);
            orderSubClassDetails = [];
          }

          try {
            orderSubClassDetails.forEach(subClassDetails => {
              if (Array.isArray(subClassDetails)) {
                subClassDetails.forEach(paymentDetail => {
                  if (paymentDetail.sub_class_name) {
                    if (!tempSubClassDetails[paymentDetail.sub_class_name]) {
                      tempSubClassDetails[
                        paymentDetail.sub_class_name
                      ] = parseFloat(paymentDetail.amount);
                    } else {
                      tempSubClassDetails[
                        paymentDetail.sub_class_name
                      ] += parseFloat(paymentDetail.amount);
                    }
                  }
                });
              }
            });
            sub_class_wise_payment_summary[paymentType] = tempSubClassDetails;
          } catch (e) {
            console.log("e", e);
            return resolve({});
          }
        });
      } catch (e) {
        console.log("e", e);
        return resolve({});
      }

      return resolve(sub_class_wise_payment_summary);
    });
  }

  sanitize(sanitizeObject: any, sanitizeDefault: any) {
    if (
      sanitizeObject == null ||
      typeof sanitizeObject === "undefined" ||
      sanitizeObject === ""
    ) {
      sanitizeObject = JSON.stringify(sanitizeDefault);
    }

    try {
      sanitizeObject = JSON.parse(sanitizeObject);
    } catch (e) {
      sanitizeObject = sanitizeDefault;
    }

    return sanitizeObject;
  }

  getItemsCounts(orderItemsRes: any): Promise<any> {
    var self = this;
    return new Promise<any>(function (resolve, reject) {
      if (orderItemsRes.length == 0) {
        return resolve({
          item_sold: 0,
          total_earnings: 0,
          total_discount: 0,
          item_tax_details: [],
          profit: 0
        });
      } else if (orderItemsRes.length != 0) {
        var item_tax_details = [];

        var total_item_sold = 0;
        var total_unit_cost = 0;
        var total_profit = 0;
        var total_item_price = 0;

        var item_price = 0;
        var item_total_discount = 0;
        var itemLock = orderItemsRes.length;
        orderItemsRes.forEach(function (item, itemIdx, itemArr) {
          total_item_sold += item.item_count;
          total_unit_cost += item.item_count * item.unit_cost;
          total_item_price += item.item_count * item.item_price;
          item_total_discount += item.discount ? parseFloat(item.discount) : 0;
          item_tax_details = item.tax_breakup
            ? item_tax_details.concat(item.tax_breakup)
            : item_tax_details;
          total_profit +=
            item.unit_cost == 0
              ? 0
              : (item.item_price - item.unit_cost) * item.item_count;
          itemLock--;
          if (itemLock === 0) {
            var itemTaxGroupDetail = groupBy(item_tax_details, function (
              itemTaxDetail
            ) {
              return itemTaxDetail.breakup_name;
            });
            var tax_details = [];
            forOwn(itemTaxGroupDetail, (itemTaxDetail, tax_name) => {
              var tax_sum = sumBy(itemTaxDetail, function (itemTax) {
                return itemTax.value ? parseFloat(itemTax.value) : 0;
              });
              tax_details.push({
                breakup_name: tax_name,
                total_tax: tax_sum
              });
            });
            return resolve({
              item_sold: total_item_sold,
              total_earnings: total_item_price,
              item_tax_details: tax_details,
              total_discount: item_total_discount,
              profit: total_profit
            });
          }
        });
      }
    });
  }

  getKotDeletedItemDetail(kotItems: any): Promise<any> {
    return new Promise<any>(function (resolve, reject) {
      if (kotItems.length == 0) {
        resolve({});
      } else {
        let kot_delete_item_summary = {};
        var deleteReasonWiseGroupedItems = groupBy(kotItems, function (
          itemRes
        ) {
          return itemRes.delete_reason;
        });
        var deleteItemLock = Object.keys(deleteReasonWiseGroupedItems).length;

        forOwn(
          deleteReasonWiseGroupedItems,
          (deletedItemDetail, delete_reason) => {
            var groupedKotOrderItems = values(
              groupBy(deletedItemDetail, function (itemDetail) {
                return itemDetail.item_code;
              })
            );
            var groupItemsLock = groupedKotOrderItems.length;
            var itemsList = [];
            var total_price = 0;
            groupedKotOrderItems.forEach(function (items, itemsIdx, itemsArr) {
              var itemLock = items.length;
              var total_item_price = 0;
              let total_item_count = 0;
              items.forEach(function (item, itemIdx, itemArr) {
                itemLock -= 1;
                item.attributes = item.attributes || [];
                item.item_price = item.item_price ? item.item_price : 0;
                item.tax_value = item.tax_value ? item.tax_value : 0;
                item.attributes.forEach(attr => {
                  item.item_price +=
                    parseFloat(attr.unit_price) * parseFloat(attr.count);
                });
                total_item_price +=
                  parseFloat(item.item_price) * parseFloat(item.item_count) +
                  parseFloat(item.tax_value);
                total_item_count += parseFloat(item.item_count);
                if (itemLock === 0) {
                  total_price += total_item_price;
                  itemsList.push({
                    item_total_price: total_item_price,
                    item_name: item.item_name,
                    item_count: total_item_count,
                    item_price: parseFloat(item.item_price),
                    item_tax_value: parseFloat(item.tax_value),
                    item_tags: item.item_tags
                  });
                  kot_delete_item_summary[delete_reason] = {
                    total_price: total_price,
                    item_list: itemsList
                  };
                  groupItemsLock -= 1;
                  if (groupItemsLock === 0) {
                    return resolve(kot_delete_item_summary);
                  }
                }
              });
            });
          }
        );
      }
    });
  }

  /**************Sales summary report begins****************/

  printSalesSummaryReportonWifi(
    consumerReportData,
    consumerReportDate,
    printSalesReportFlags
  ) {
    //check if printer is usb or wifi in this method an create 2 new methods to print
    this.builder = new epson.ePOSBuilder();
    this.shopConfiguration = JSON.parse(
      localStorage.getItem("shop_configuration")
    );
    this.merchantProfile = JSON.parse(localStorage.getItem("merchant_profile"));
    if (this.shopConfiguration || this.merchantProfile) {
      this.builder.addTextStyle(false, false, true);
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText("\n" + this.shopConfiguration.shop_info.name + "\n");
      this.printAddress(this.shopConfiguration.shop_info.address);
      if (this.shopConfiguration.shop_info.shop_phone_number) {
        this.builder.addText(
          "PH : " + this.shopConfiguration.shop_info.shop_phone_number + "\n"
        );
      } else if (this.merchantProfile.merchant.phone) {
        this.builder.addText(
          "PH : " + this.merchantProfile.merchant.phone + "\n"
        );
      }
      this.builder.addTextStyle(false, false, false);
    }

    if (consumerReportDate) {
      this.builder.addText("From:" + consumerReportDate.from_date + "\n");
      this.builder.addText("To:" + consumerReportDate.date + "\n");
    }

    var ReportDataArray = [];

    if (printSalesReportFlags.salesDetailsFlag) {
      //Sales Details Header
      ReportDataArray.push({
        title: "Sales Details (Online)",
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });

      // <!-- Gross Details -->
      if (printSalesReportFlags.grossDetails) {
        ReportDataArray.push({
          title: "Sales",
          value: consumerReportData.normal_order_total,
          isMoney: true,
          isHeader: false
        });
        if (consumerReportData.grand_discount_total > 0)
          ReportDataArray.push({
            title: "Sales + Cash Discount",
            value:
              consumerReportData.normal_order_total +
              consumerReportData.grand_cash_discount_total,
            isMoney: true,
            isHeader: false
          });
      } // <!-- /Gross Details -->

      // <!-- Discount -->
      if (printSalesReportFlags.discount) {
        if (consumerReportData.grand_discount_total > 0) {
          ReportDataArray.push({
            title: "Total Discount",
            value: consumerReportData.grand_discount_total,
            isMoney: true,
            isHeader: false
          });
        }
        if (
          consumerReportData.grand_discount_total -
          consumerReportData.grand_cash_discount_total !=
          0
        ) {
          ReportDataArray.push({
            title: "Pre Tax Discount",
            value:
              consumerReportData.grand_discount_total -
              consumerReportData.grand_cash_discount_total,
            isMoney: true,
            isHeader: false
          });
        }
        if (consumerReportData.grand_cash_discount_total > 0) {
          ReportDataArray.push({
            title: "Cash Discount (On final Total)",
            value: consumerReportData.grand_cash_discount_total,
            isMoney: true,
            isHeader: false
          });
        }
      } //<!--/ Discount -->

      //<!-- Total Tax -->
      if (printSalesReportFlags.totalTax) {
        if (this.shopConfiguration.item_wise_tax) {
          consumerReportData.tax_details.forEach(tax => {
            if (tax.total_tax > 0) {
              ReportDataArray.push({
                title: tax.breakup_name,
                value: tax.total_tax,
                isMoney: true,
                isHeader: false
              });
            }
          });
        } else {
          if (consumerReportData.grand_shop_wise_tax_total > 0) {
            ReportDataArray.push({
              title: "Total Tax",
              value: consumerReportData.grand_shop_wise_tax_total,
              isMoney: true,
              isHeader: false
            });
          }
        }
      } //<!-- /Total Tax -->

      //<!-- Net Sale -->
      if (printSalesReportFlags.netSale && consumerReportData.net_sales > 0) {
        ReportDataArray.push({
          title: "Net Sale",
          value: consumerReportData.net_sales,
          isMoney: true,
          isHeader: false
        });
      } //  <!-- /Net Sale -->

      //<!-- Total Items Sold -->
      if (printSalesReportFlags.totalItemsSold) {
        ReportDataArray.push({
          title: "Total Items Sold",
          value: consumerReportData.total_unique_sold_items,
          isMoney: false,
          isHeader: false
        });
      } //  <!-- /Total Items Sold -->

      //<!-- Total Quantity Sold -->
      if (printSalesReportFlags.totalQuantitySold) {
        ReportDataArray.push({
          title: "Total Quantity Sold",
          value: consumerReportData.total_items_sold,
          isMoney: false,
          isHeader: false
        });
      } //  <!-- /Total Quantity Sold -->

      //<!-- Gross Sale Rounding Value -->
      if (printSalesReportFlags.grossDetails) {
        ReportDataArray.push({
          title: " Rounding Value",
          value: GeneralHelper.toFloatFixedDecimal(consumerReportData.grand_rounding_total),
          isMoney: true,
          isHeader: false
        });
      } // <!-- Gross Sale Rounding Value -->

      //<!-- Check Print Value -->
      if (printSalesReportFlags.checkPrints) {
        ReportDataArray.push({
          title: " Check print total",
          value: GeneralHelper.toFloatFixedDecimal(consumerReportData.check_print_total),
          isMoney: true,
          isHeader: false
        });
      } // <!-- Check Print Value -->

      //<!-- Check Print Value -->
      if (printSalesReportFlags.checkPrints) {
        ReportDataArray.push({
          title: " Check print count",
          value: GeneralHelper.toFloatFixedDecimal(consumerReportData.check_print_count),
          isMoney: true,
          isHeader: false
        });
      } // <!-- Check Print Value -->
    }
    //<!--Payment Details -->
    if (printSalesReportFlags.showAllPaymentTypes) {
      //Header
      ReportDataArray.push({
        title: "Payment Details",
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });

      //<!-- All Payment Types -->
      consumerReportData.normal_order_payment_summary.forEach(paymentDetail => {
        if (paymentDetail.amount && paymentDetail.amount != 0) {
          ReportDataArray.push({
            title: paymentDetail.name,
            value: paymentDetail.amount,
            isMoney: true,
            isHeader: false
          });
        }
      });
      this.createDetailLines(ReportDataArray);
      ReportDataArray = [];
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText("---------------------------------------------\n");
      ReportDataArray.push({
        title: "TOTAL",
        value: printSalesReportFlags.TotalCasherSum,
        isMoney: true,
        isHeader: false
      });
    }
    //<!-- /All Payment Types -->

    //<!-- Cashier Details -->
    if (
      printSalesReportFlags.openingDrawerCash ||
      printSalesReportFlags.cashinDrawer
    ) {
      //Header
      ReportDataArray.push({
        title: "Cashier Details",
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });
      // <!-- Opening Drawer Cash -->
      if (printSalesReportFlags.openingDrawerCash) {
        ReportDataArray.push({
          title: "Opening Drawer Cash",
          value: consumerReportData.shop_open_close_data.open_cash,
          isMoney: true,
          isHeader: false
        });
      }
      //<!-- /Opening Drawer Cash -->

      //<!-- Cash in Drawer -->
      if (printSalesReportFlags.cashinDrawer) {
        if (consumerReportData.shop_open_close_data.cash_in_drawer)
          ReportDataArray.push({
            title: "Cash in Drawer",
            value: consumerReportData.shop_open_close_data.cash_in_drawer,
            isMoney: true,
            isHeader: false
          });
      } //<!-- /Cash in Drawer -->
    } //<!-- /Cashier Details -->
    //<!--/Payment Details -->

    // <!-- Clear Diary Details -->
    if (printSalesReportFlags.clearDiaryDetails && printSalesReportFlags.totalClearDairyAmt > 0) {
      //Header
      ReportDataArray.push({
        title: "Clear Diary Details",
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });
      consumerReportData.clear_diary_summary.forEach(diaryDetail => {
        if (diaryDetail.amount && diaryDetail.amount != 0) {
          ReportDataArray.push({
            title: diaryDetail.name,
            value: diaryDetail.amount,
            isMoney: true,
            isHeader: false
          });
        }
      });
      this.createDetailLines(ReportDataArray);
      ReportDataArray = [];
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText("---------------------------------------------\n");
      ReportDataArray.push({
        title: "Total Clear Diary Amount",
        value: printSalesReportFlags.totalClearDairyAmt,
        isMoney: true,
        isHeader: false
      });
    }
    // <!-- /Clear Diary Details -->


    // <!-- Advance Diary Details -->
    if (printSalesReportFlags.advanceDiaryDetails && printSalesReportFlags.totalAdvanceDairyAmt > 0) {
      //Header
      ReportDataArray.push({
        title: "Advance Diary Details",
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });
      consumerReportData.advance_diary_summary.forEach(diaryDetail => {
        if (diaryDetail.amount && diaryDetail.amount != 0) {
          ReportDataArray.push({
            title: diaryDetail.name,
            value: diaryDetail.amount,
            isMoney: true,
            isHeader: false
          });
        }
      });
      this.createDetailLines(ReportDataArray);
      ReportDataArray = [];
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText("---------------------------------------------\n");
      ReportDataArray.push({
        title: "Total Advance Diary Amount",
        value: printSalesReportFlags.totalAdvanceDairyAmt,
        isMoney: true,
        isHeader: false
      });
    }
    // <!-- /Advance Diary Details -->

    //<!-- Section Wise Pricing -->
    if (printSalesReportFlags.sectionWisePricing) {
      //Header
      let temp = "Section (Including N.C Orders)";
      if (!this.shopConfiguration.item_wise_tax)
        temp = temp + "(Including Tax)";
      ReportDataArray.push({
        title: temp,
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });
      if (consumerReportData.section_wise_summary.length > 0) {
        consumerReportData.section_wise_summary.forEach(section => {
          if (section.total != 0)
            ReportDataArray.push({
              title: section.shop_section,
              value: section.total,
              isMoney: true,
              isHeader: false
            });
        });
      }
    } // <!--/ Section Wise Pricing -->

    // <!-- Department -->
    if (printSalesReportFlags.department) {
      let temp = "Department";
      if (!this.shopConfiguration.item_wise_tax)
        temp = temp + "(Including Tax)";
      ReportDataArray.push({
        title: temp,
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });

      consumerReportData.item_summary_by_kitchen_department.forEach(
        ikd_summary => {
          if (ikd_summary.total != 0)
            ReportDataArray.push({
              title: ikd_summary.kitchen_department,
              value: ikd_summary.total,
              isMoney: true,
              isHeader: false
            });
        }
      );
    } //    <!-- /Department -->

    //<!-- Category -->
    if (printSalesReportFlags.categoryWiseSale) {
      //Header
      ReportDataArray.push({
        title: "Category",
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });
      consumerReportData.item_summary_by_category.forEach(category => {
        ReportDataArray.push({
          title: category.category_name,
          value: category.total,
          isMoney: true,
          isHeader: false
        });
      });
    } //    <!-- /Category -->

    //<!-- Item Wise Sale -->
    if (printSalesReportFlags.itemWiseSale) {
      //Header
      ReportDataArray.push({
        title: "Item",
        value: "Quantity  Price",
        isMoney: false,
        isHeader: true
      });
      this.createDetailLines(ReportDataArray);
      ReportDataArray = [];
      consumerReportData.item_wise_summary.forEach(item => {
        this.createItemLineV2(
          item.item_name,
          item.item_count,
          item.item_total_price
        );
      });
    }

    //<!-- Item Attribute Details  -->
    if (printSalesReportFlags.itemAtrribute) {
      //Header
      ReportDataArray.push({
        title: "Attribute",
        value: "Price",
        isMoney: false,
        isHeader: true
      });
      consumerReportData.item_attribute_summary.forEach(attr => {
        ReportDataArray.push({
          title: attr.name,
          value: attr.total_price,
          isMoney: true,
          isHeader: false
        });
      });
    }

    //<!-- Staff Summary -->
    if (printSalesReportFlags.staffSummary) {
      //Header
      ReportDataArray.push({
        title: "Staff Summary",
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });
      consumerReportData.shop_users_summary.forEach(shop_user_summary => {
        if (shop_user_summary.total != 0)
          ReportDataArray.push({
            title: shop_user_summary.name,
            value: shop_user_summary.total,
            isMoney: true,
            isHeader: false
          });
      });
    } //    <!-- /Staff Summary -->

    // <!-- Orders Altered -->
    if (printSalesReportFlags.ordersAltered) {
      //Header
      ReportDataArray.push({
        title: "Orders Altered",
        value: this.getCurrencySymbol(),
        isMoney: false,
        isHeader: true
      });
      if (consumerReportData.cancel_order_total > 0)
        ReportDataArray.push({
          title: "Cancelled",
          value: consumerReportData.cancel_order_total,
          isMoney: true,
          isHeader: false
        });
      if (consumerReportData.no_charge_order_total > 0)
        ReportDataArray.push({
          title: "No Charged",
          value: consumerReportData.no_charge_order_total,
          isMoney: true,
          isHeader: false
        });
    } //    <!-- /Orders Altered -->
    /*
        ReportDataArray.push({'title':'Total Orders' ,'value':consumerReportData.total_order_count ,'isMoney':false});
        ReportDataArray.push({'title':'Total sales (Including Delivery/Pickup orders, excluding cancel/NC/EA)' ,'value':consumerReportData.grand_total ,'isMoney':true});
       */
    this.createDetailLines(ReportDataArray);

    //greeting
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addTextStyle(false, false, true);
    this.builder.addText("---------------------------------------------\n");
    this.builder.addText("Thank You :-)\n");
    this.builder.addText("--With love, OneGreenDiary--\n");

    // Add 2 line feeds and cut
    this.builder.addFeed();
    this.builder.addFeed();
    this.builder.addCut(this.builder.CUT_FEED);

    let printerData = JSON.parse(localStorage.getItem("shop_printers"));
    if (printerData) {
      var printers_list = printerData;
      var printerFound = false;
      printers_list.forEach(printerObj => {
        if (
          printerObj.ip_address &&
          printerObj.ip_address != "" &&
          (printerObj.kitchen_department.trim() == "" ||
            printerObj.kitchen_department == null) &&
          (printerObj.print_type.indexOf("receipt") != -1 ||
            printerObj.print_type.indexOf("self_service") != -1)
        ) {
          printerFound = true;
          // var ipAddr = '192.168.1.199';
          // ipAddr = '192.168.1.201';
          var ipAddr = printerObj.ip_address;
          console.log("ipAddr:" + ipAddr);
          var address =
            "http://" +
            ipAddr +
            "/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000";
          console.log("ADDRESS :: " + address);
          var epos = new epson.ePOSPrint(address);
          // Check if printer cover is open
          epos.oncoveropen = function () {
            alert("coveropen");
          };
          // Send print job
          console.log("builder object " + this.builder.toString());
          try {
            epos.send(this.builder.toString());
          } catch (e) {
            console.log(e);
          }
        }
      });
      if (!printerFound) {
        console.log("Printer not found");
        alert("Wifi Printer not found to print report");
        // this.messageService.add({ severity: 'success', summary: 'Wifi Printer not found', detail: 'Wifi Printer not found to print report' });
      }
    } else {
      console.log("Printer data could not be fetched");
      alert("Printer data could not be fetched at this time");
      // this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
    }
  }

  //method to print address in proper format (i.e on separate lines and without breaking any words midway)
  printAddress(fullAddress: string) {
    //FOR ADDRESS
    var addressArray = fullAddress.split(" ");
    var addressLine1 = "";
    var addressLine2 = "";
    var addressLine3 = "";
    var j = -1;
    var k = -1;

    //creating address line 1
    addressArray.some((element, i) => {
      if (
        addressLine1.length < 48 &&
        addressLine1.length + element.length <= 48
      ) {
        addressLine1 = addressLine1 + element + " ";
        return false;
      } else {
        j = i;
        return true;
      }
    });
    this.builder.addText(addressLine1.trim() + "\n");

    //creating address line 2 (if line 1 can't accomodate the entire address)
    if (j > 0) {
      for (; j < addressArray.length; j++) {
        if (
          addressLine2.length < 48 &&
          addressLine2.length + addressArray[j].length <= 48
        ) {
          addressLine2 = addressLine2 + addressArray[j] + " ";
        } else {
          k = j;
          break;
        }
      }
      this.builder.addText(addressLine2.trim() + "\n");
    }

    //creating address line 3 (if line 1 & 2 can't accomodate the entire address)
    if (k > 0) {
      for (; k < addressArray.length; k++) {
        if (
          addressLine3.length < 48 &&
          addressLine3.length + addressArray[k].length <= 48
        ) {
          addressLine3 = addressLine3 + addressArray[k] + " ";
        }
      }
      this.builder.addText(addressLine3.trim() + "\n");
    }
  }

  createDetailLines(ReportDataArray) {
    ReportDataArray.forEach((reportItem, indx) => {
      if (reportItem.isHeader) {
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        this.builder.addTextStyle(false, false, true);
        this.builder.addText("---------------------------------------------\n");
      } else {
        this.builder.addTextStyle(false, false, false);
      }
      this.builder.addPageBegin();
      // this.builder.addPageArea(20, 0, 364, 90);
      this.printTitle(this.capitalizeFirstLetter(reportItem.title));
      // this.builder.addText(reportItem.title);
      var width = 14;

      if (reportItem.isHeader) {
        this.builder.addPageArea(350, 0, 200, 30);
        width = 16;
      } else this.builder.addPageArea(376, 0, 172, 30);
      this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
      var value = reportItem.value;

      if (reportItem.isMoney) {
        value = GeneralHelper.toFloatFixedDecimal(value);
      }

      do {
        value = " " + value;
      } while (value.length < width);
      this.builder.addText(value);
      this.builder.addPageEnd();
      if (reportItem.isHeader) {
        // this.builder.addTextStyle(false, false, true);
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        this.builder.addText("---------------------------------------------\n");
      }
    });
  }

  createItemLineV2(productName, quantity, sellPrice) {
    this.builder.addTextStyle(false, false, false);
    this.builder.addPageBegin();
    let dHeight = Math.ceil(productName.length / 28) * 30; //we suppose 20 character will come in one line
    this.builder.addPageArea(20, 0, 390, dHeight);
    this.builder.addText(this.capitalizeFirstLetter(productName));

    this.builder.addPageArea(400, 0, 50, dHeight);
    this.builder.addText(quantity);
    this.builder.addPageArea(450, 0, 100, dHeight);

    sellPrice = GeneralHelper.toFloatFixedDecimal(sellPrice);
    while (sellPrice.length < 8) {
      sellPrice = " " + sellPrice;
    }

    this.builder.addText(sellPrice);
    this.builder.addPageEnd();
  }

  //method to print title in proper format (i.e on separate lines and without breaking any words midway)
  printTitle(fullAddress: string) {
    // FOR TITLE
    var titleArray = fullAddress.split(" ");
    var titleLine1 = "";
    var titleLine2 = "";
    var titleLine3 = "";
    var j = -1;
    var k = -1;

    //creating title line 1
    titleArray.some((element, i) => {
      if (titleLine1.length < 30 && titleLine1.length + element.length <= 30) {
        titleLine1 = titleLine1 + element + " ";
        return false;
      } else {
        j = i;
        return true;
      }
    });
    // this.builder.addText(titleLine1.trim() + '\n');

    //creating title line 2 (if line 1 can't accomodate the entire title)
    if (j > 0) {
      for (; j < titleArray.length; j++) {
        if (
          titleLine2.length < 30 &&
          titleLine2.length + titleArray[j].length <= 30
        ) {
          titleLine2 = titleLine2 + titleArray[j] + " ";
        } else {
          k = j;
          break;
        }
      }
      // this.builder.addText(titleLine2.trim() + '\n');
    }

    //creating title line 3 (if line 1 & 2 can't accomodate the entire title)
    if (k > 0) {
      for (; k < titleArray.length; k++) {
        if (
          titleLine3.length < 30 &&
          titleLine3.length + titleArray[k].length <= 30
        ) {
          titleLine3 = titleLine3 + titleArray[k] + " ";
        }
      }
      // this.builder.addText(titleLine3.trim() + '\n');
    }

    if (j == -1) {
      this.builder.addPageArea(20, 0, 364, 30);
      this.builder.addText(titleLine1.trim() + "\n");
    } else if (k == -1) {
      this.builder.addPageArea(20, 0, 364, 60);
      this.builder.addText(titleLine1.trim() + "\n");
      this.builder.addText(titleLine2.trim() + "\n");
    } else {
      this.builder.addPageArea(20, 0, 364, 90);
      this.builder.addText(titleLine1.trim() + "\n");
      this.builder.addText(titleLine2.trim() + "\n");
      this.builder.addText(titleLine3.trim() + "\n");
    }
  }

  getCurrencySymbol() {
    if (this.shopConfiguration.currency) {
      var currency = this.shopConfiguration.currency;
      if (currency.toUpperCase() == "INR") {
        return "Rs.";
      } else {
        var currency_symbol = this.shopConfiguration.currency_symbol;
        var symbol = currency_symbol.toLowerCase();
        if (symbol.charAt(0) === "u") {
          symbol = symbol.split("u")[1];
          symbol = String.fromCharCode(parseInt(symbol, 16));
        } else {
          symbol = symbol.toUpperCase();
        }
        return symbol;
      }
    } else {
      return "";
    }
  }

  /**************Sales summary report ends****************/

  /**************Item cancellation report begins****************/
  printCancellationReportonWifi(cancellationObject) {
    //check if printer is usb or wifi in this method an create 2 new methods to print
    this.builder = new epson.ePOSBuilder();
    this.shopConfiguration = JSON.parse(
      localStorage.getItem("shop_configuration")
    );
    this.merchantProfile = JSON.parse(localStorage.getItem("merchant_profile"));
    if (this.shopConfiguration || this.merchantProfile) {
      this.builder.addTextStyle(false, false, true);
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText("\n" + this.shopConfiguration.shop_info.name + "\n");
      this.printAddress(this.shopConfiguration.shop_info.address);
      if (this.shopConfiguration.shop_info.shop_phone_number) {
        this.builder.addText(
          "PH : " + this.shopConfiguration.shop_info.shop_phone_number + "\n"
        );
      } else if (this.merchantProfile.merchant.phone) {
        this.builder.addText(
          "PH : " + this.merchantProfile.merchant.phone + "\n"
        );
      }
      this.builder.addTextStyle(false, false, false);
    }

    this.builder.addText("_____________________________________________\n");

    cancellationObject.report_data.forEach(items => {
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText(items.name + "\n");
      this.builder.addText("---------------------------------------------\n");
      // this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      // this.builder.addText("QTY" + "                     " + "ITEM" + "     " + "PRICE" + '\n');
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      items.item_list.forEach(itemDetail => {
        this.builder.addPageBegin();
        this.builder.addPageArea(20, 0, 364, 30);
        this.builder.addText(
          itemDetail.item_count +
          " x " +
          this.capitalizeFirstLetter(itemDetail.item_name)
        );
        this.builder.addPageArea(376, 0, 172, 30);
        this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
        var totalPrice = 0;

        var sellPrice =
          this.getCurrencySymbol() + GeneralHelper.toFloatFixedDecimal(itemDetail.item_price);
        do {
          sellPrice = " " + sellPrice;
        } while (sellPrice.length < 14);
        this.builder.addText(sellPrice);
        this.builder.addPageEnd();
      });

      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText("---------------------------------------------\n");

      this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
      this.builder.addText(
        "TOTAL: " +
        this.getCurrencySymbol() +
        GeneralHelper.toFloatFixedDecimal(items.total_price) +
        "  \n"
      );
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText("_____________________________________________\n");
    });

    //greeting
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText("Thank You :-)\n");
    this.builder.addText("--With love, OneGreenDiary--\n");

    let printerData = JSON.parse(localStorage.getItem("shop_printers"));
    if (printerData) {
      var printers_list = printerData;
      var printerFound = false;
      printers_list.forEach(printerObj => {
        if (
          printerObj.ip_address &&
          printerObj.ip_address != "" &&
          (printerObj.kitchen_department.trim() == "" ||
            printerObj.kitchen_department == null) &&
          (printerObj.print_type.indexOf("receipt") != -1 ||
            printerObj.print_type.indexOf("self_service") != -1)
        ) {
          printerFound = true;
          // var ipAddr = '192.168.1.199';
          // ipAddr = '192.168.1.201';
          var ipAddr = printerObj.ip_address;
          console.log("ipAddr:" + ipAddr);
          var address =
            "http://" +
            ipAddr +
            "/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000";
          console.log("ADDRESS :: " + address);
          var epos = new epson.ePOSPrint(address);
          // Check if printer cover is open
          epos.oncoveropen = function () {
            alert("coveropen");
          };
          // Send print job
          console.log("builder object " + this.builder.toString());
          try {
            epos.send(this.builder.toString());
          } catch (e) {
            console.log(e);
          }
        }
      });
      if (!printerFound) {
        console.log("Printer not found");
        alert("Wifi Printer not found to print report");
        // this.messageService.add({ severity: 'success', summary: 'Wifi Printer not found', detail: 'Wifi Printer not found to print report' });
      }
    } else {
      console.log("Printer data could not be fetched");
      alert("Printer data could not be fetched at this time");
      // this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
    }
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  /**************Item cancellation report ends****************/

  /**************Sectionwise item detail report begins****************/

  printSectionwiseDetailReportonWifi(reportDataArray) {
    //check if printer is usb or wifi in this method an create 2 new methods to print
    this.builder = new epson.ePOSBuilder();
    this.shopConfiguration = JSON.parse(
      localStorage.getItem("shop_configuration")
    );
    this.merchantProfile = JSON.parse(localStorage.getItem("merchant_profile"));
    if (this.shopConfiguration || this.merchantProfile) {
      this.builder.addTextStyle(false, false, true);
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText("\n" + this.shopConfiguration.shop_info.name + "\n");
      this.printAddress(this.shopConfiguration.shop_info.address);
      if (this.shopConfiguration.shop_info.shop_phone_number) {
        this.builder.addText(
          "PH : " + this.shopConfiguration.shop_info.shop_phone_number + "\n"
        );
      } else if (this.merchantProfile.merchant.phone) {
        this.builder.addText(
          "PH : " + this.merchantProfile.merchant.phone + "\n"
        );
      }
      this.builder.addTextStyle(false, false, false);
    }

    reportDataArray.forEach(itm => {
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText("_____________________________________________\n");
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      //restaurant section
      this.builder.addTextStyle(false, false, true);
      this.builder.addText(itm.group_type.toUpperCase() + "\n");
      this.builder.addTextStyle(false, false, false);
      this.builder.addText("---------------------------------------------\n");
      itm.items.tag_wise_item_list.forEach(item => {
        //receipt section
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        this.builder.addText(this.capitalizeFirstLetter(item.tag) + "\n");
        // this.builder.addText('---------------------------------------------\n');
        //receipt secton items
        item.item_details.forEach(itemDetail => {
          this.builder.addPageBegin();
          this.builder.addPageArea(20, 0, 364, 30);
          this.builder.addText(
            itemDetail.item_count +
            " x " +
            this.capitalizeFirstLetter(itemDetail.item_name)
          );
          this.builder.addPageArea(376, 0, 172, 30);
          this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
          var totalPrice = 0;

          var sellPrice =
            this.getCurrencySymbol() + GeneralHelper.toFloatFixedDecimal(itemDetail.item_price);
          do {
            sellPrice = " " + sellPrice;
          } while (sellPrice.length < 14);
          this.builder.addText(sellPrice);
          this.builder.addPageEnd();
        });
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        this.builder.addText("---------------------------------------------\n");
        this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
        this.builder.addText(
          this.capitalizeFirstLetter(item.tag) +
          " TOTAL: " +
          this.getCurrencySymbol() +
          GeneralHelper.toFloatFixedDecimal(item.total_tag_item_price) +
          "  \n"
        );
        this.builder.addTextAlign(this.builder.ALIGN_CENTER);
        this.builder.addText("---------------------------------------------\n");
      });
      //restaurant section total
      this.builder.addTextStyle(false, false, true);
      this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
      this.builder.addText(
        itm.group_type.toUpperCase() +
        " TOTAL: " +
        this.getCurrencySymbol() +
        GeneralHelper.toFloatFixedDecimal(itm.total) +
        "  \n\n"
      );
      this.builder.addTextStyle(false, false, false);
    });

    this.builder.addText("_____________________________________________\n");

    //greeting
    this.builder.addTextAlign(this.builder.ALIGN_CENTER);
    this.builder.addText("Thank You :-)\n");
    this.builder.addText("--With love, OneGreenDiary--\n");

    let printerData = JSON.parse(localStorage.getItem("shop_printers"));
    if (printerData) {
      var printers_list = printerData;
      var printerFound = false;
      printers_list.forEach(printerObj => {
        if (
          printerObj.ip_address &&
          printerObj.ip_address != "" &&
          (printerObj.kitchen_department.trim() == "" ||
            printerObj.kitchen_department == null) &&
          (printerObj.print_type.indexOf("receipt") != -1 ||
            printerObj.print_type.indexOf("self_service") != -1)
        ) {
          printerFound = true;
          // var ipAddr = '192.168.1.199';
          // ipAddr = '192.168.1.201';
          var ipAddr = printerObj.ip_address;
          console.log("ipAddr:" + ipAddr);
          var address =
            "http://" +
            ipAddr +
            "/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000";
          console.log("ADDRESS :: " + address);
          var epos = new epson.ePOSPrint(address);
          // Check if printer cover is open
          epos.oncoveropen = function () {
            alert("coveropen");
          };
          // Send print job
          console.log("builder object " + this.builder.toString());
          try {
            epos.send(this.builder.toString());
          } catch (e) {
            console.log(e);
          }
        }
      });
      if (!printerFound) {
        console.log("Printer not found");
        alert("Wifi Printer not found to print report");
        // this.messageService.add({ severity: 'success', summary: 'Wifi Printer not found', detail: 'Wifi Printer not found to print report' });
      }
    } else {
      console.log("Printer data could not be fetched");
      alert("Printer data could not be fetched at this time");
      // this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
    }
  }
  /**************Sectionwise item detail report ends****************/

  /***************USB PRINTING BEGINS********************************/
  printUSBReport(printContents) {
    try {
      let popupWin;
      // printContents = document.getElementById('reportDiv').innerHTML;
      popupWin = window.open(
        "",
        "_blank",
        "top=0,left=0,height=100%,width=auto"
      );
      popupWin.document.open();

      popupWin.document.write(`
            <html>
                <head>
                    <title>Print tab</title>
                    <link href="styles/sales_report.css" rel="stylesheet" type="text/css" media="print" />          
                    <script type="text/javascript">
                      function onload() {
                        setTimeout(function(){window.print(); window.close();}, 200); 
                      }
                    </script>
                </head>
                <body onload="onload()">${printContents}
                </body>
            </html>`);
      popupWin.document.close();
    } catch (e) {
      console.log(e);
      alert("There was a problem while printing. Please try again.");
    }
  }

  checkPrinters() {
    var printerDetails = { USB_present: false, wifi_present: false };
    let printerData = JSON.parse(localStorage.getItem("shop_printers"));
    if (printerData) {
      var printers_list = printerData;
      printers_list.forEach(printerObj => {
        if (
          (printerObj.kitchen_department.trim() == "" ||
            printerObj.kitchen_department == null) &&
          (printerObj.print_type.indexOf("receipt") != -1 ||
            printerObj.print_type.indexOf("self_service") != -1)
        ) {
          if (printerObj.ip_address && printerObj.ip_address != "") {
            printerDetails.wifi_present = true;
          } else {
            printerDetails.USB_present = true;
          }
        }
      });
      return printerDetails;
    } else {
      return null;
    }
  }
  /***************USB PRINTING ENDS********************************/
}
