import { Injectable } from '@angular/core';
import { Http } from '@angular/http';



import { APIHelper } from '../api-helper';

@Injectable()
export class ThirdPartyOrderService {
    constructor(private http: Http) { }

    getThirdPartyNewOrderCount(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_THIRD_PARTY_NEW_ORDER_COUNT,data);
    }

    getZomatoDeliveryStatus(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_ZOMATO_DELIVERY_STATUS,data);
    }

    getZomatoTakeawayStatus(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_ZOMATO_TAKEAWAY_STATUS,data);
    }

    updateZomatoDeliveryStatus(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_UPDATE_ZOMATO_DELIVERY_STATUS,data);
    }

    updateZomatoTakeawayStatus(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_UPDATE_ZOMATO_TAKEAWAY_STATUS,data);
    }
    getAllDeliveryPartner(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_ACTIVE_DELIVERY_PARTNER_LIST,data);
    }
    getEstimate(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_DUNZO_ESTIMATE,data);
    }
    createTask(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CREATE_DUNZO_TASK,data);
    }  
}