import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-sales-print-checks-modal",
  templateUrl: "./sales-print-checks-modal.component.html",
  styleUrls: ["./sales-print-checks-modal.component.less"]
})
export class SalesPrintChecksModalComponent implements OnInit {
  constructor(public modalRef: BsModalRef) {}
  public decision: string;
  public Flag: any = {};

  ngOnInit() {}

  setAllFalse() {
    this.Flag.salesDetails = false;
    this.Flag.grossDetails = false;
    this.Flag.totalTax = false;
    this.Flag.netSale = false;
    this.Flag.discount = false;
    this.Flag.totalItemsSold = false;
    this.Flag.totalQuantitySold = false;
    this.Flag.checkPrints = false;
    this.Flag.sectionWisePricing = false;
    this.Flag.categoryWiseSale = false;
    this.Flag.itemWiseSale = false;
    this.Flag.staffSummary = false;
    this.Flag.clearDiaryDetails = false;
    this.Flag.advanceDiaryDetails=false,
    this.Flag.cashierDetails = false;
    this.Flag.showAllPaymentTypes = false;
    this.Flag.openingDrawerCash = false;
    this.Flag.cashinDrawer = false;
    this.Flag.department = false;
    this.Flag.ordersAltered = false;
    this.Flag.itemAtrribute = false;
    // this.Flag.billWiseReports = false;
  }

  checkAllSalesDetails() {
    this.Flag.grossDetails = this.Flag.salesDetails;
    this.Flag.totalTax = this.Flag.salesDetails;
    this.Flag.netSale = this.Flag.salesDetails;
    this.Flag.discount = this.Flag.salesDetails;
    this.Flag.totalItemsSold = this.Flag.salesDetails;
    this.Flag.totalQuantitySold = this.Flag.salesDetails;
    this.Flag.checkPrints = this.Flag.checkPrints;
    return;
  }
  checkAllCashierDetails() {
    this.Flag.showAllPaymentTypes = this.Flag.cashierDetails;
    this.Flag.openingDrawerCash = this.Flag.cashierDetails;
    this.Flag.cashinDrawer = this.Flag.cashierDetails;
    return;
  }

  checkForAllSalesDetails() {
    return (this.Flag.salesDetails =
      this.Flag.grossDetails &&
      this.Flag.totalTax &&
      this.Flag.netSale &&
      this.Flag.discount &&
      this.Flag.totalItemsSold &&
      this.Flag.totalQuantitySold &&
      this.Flag.checkPrints);
  }

  checkForAllCashierDetails() {
    return (this.Flag.cashierDetails =
      this.Flag.showAllPaymentTypes &&
      this.Flag.openingDrawerCash &&
      this.Flag.cashinDrawer);
  }
  
  cancel() {
    this.decision = "cancel";
    this.modalRef.hide();
  }
  save() {
    this.decision = "ok";
    this.modalRef.hide();
    // localStorage.setItem("printSalesReportFlags", this.Flag);
  }
}
