/**
 * (c) OneGreenDiary Software Pvt. Ltd.
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform,
 *    solely reside with the third-party.
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Once OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without
 *    prior agreement with OneGreenDiary Software Pvt. Ltd.
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */

import { Component, OnInit } from '@angular/core';
import { StateGuardService } from '../state-guard.service';
import { MultiLanguageTitles } from '../helpers/multi-language-titles';
import { MessageService } from 'primeng/api';

import 'assets/js/epos-print-3.0.0.js';
import { remove, findLast } from 'lodash-es';


//dot reference https://olado.github.io/doT/index.html
import * as dot from 'dot';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseConfig } from './api-config';
import { CommonService } from './services/common.service';

declare var epson: any;

@Component({
	selector: 'print-helper',
	template: `<canvas id='canvas' width='500' height='250' style='visibility:hidden;position: absolute;top: 0;' crossorigin="anonymous"></canvas>`,
})

export class PrintHelper {
	printerUtility = false;
	constructor(public stateGuard: StateGuardService, private messageService: MessageService,
		private http: HttpClient, private commonService: CommonService) {
		if (localStorage.getItem('PRINT_UTILITY')) {
			this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
		}

		this.commonService.notifyPrinterObservable$.subscribe(result => {
			this.printerUtility = result;
		});
	}

	firePrint(billInfo, printType) {
		console.log("Printing billInfo:", billInfo);
		console.log("total price : ", billInfo.totalPrice);
		return new Promise((resolve, reject) => {
			if (billInfo && billInfo.splitPaymentList && billInfo.splitPaymentList.length > 0) {
				billInfo.splitPaymentList.forEach((p: any) => {
					if (p.comment) {
						if (p.comment == 'credit' || p.comment == 'credit_due') {
							p['name'] = 'Credit Due Amount';
						} else if (p.comment == 'credit_void') {
							p['name'] = 'Credit Void';
						} else if (p.comment == 'used' || p.comment == 'used_due') {
							p['name'] = 'Pay from diary';
						} else if (p.comment == 'used_void') {
							p['name'] = 'Pay from diary void';
						} else if (p.comment == 'outstanding' || p.comment == 'outstanding_due') {
							p['name'] = "Outstanding Amount";
						} else if (p.comment == 'advance' || p.comment == 'advance_due') {
							p['name'] = "Advance Amount";
						} else if (p.comment == 'credit_note_advance') {
							p['name'] = "Credit Note Amount";
						} else {
							p['name'] = p.comment;
						}
					}
				});
			}

			let printerData = JSON.parse(localStorage.getItem('shop_printers'));
			var local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
			//flags to check if there is/are Wifi and/or USB printer/s with receipt or self-service flags on
			let wifiPrinterPresent = false;
			let usbPrinterPresent = false;
			let selectedUSBPrinter;
			if (printerData) {
				printerData.forEach(printerObj => {
					if (printerObj.ip_address && printerObj.ip_address != "" && printerObj.ip_address != null && (printerObj.print_type.indexOf('receipt') != -1 || printerObj.print_type.indexOf('self_service') != -1)) {
						wifiPrinterPresent = true;
						if (!selectedUSBPrinter) { selectedUSBPrinter = printerObj; }
					} else if ((!printerObj.ip_address || printerObj.ip_address == "" || printerObj.ip_address == null) && (printerObj.print_type.indexOf('receipt') != -1 || printerObj.print_type.indexOf('self_service') != -1)) {
						if (!selectedUSBPrinter) { selectedUSBPrinter = printerObj; }
						usbPrinterPresent = true;
					}
				})

				//var status = this.addToSingleCheckoutForCheckPrint(this.stagedVisitor);

				//if(status == 'success'){
				//// var printer_type = "wifi";
				let shopConfiguration = JSON.parse(localStorage.getItem('shop_configuration'));
				let merchantProfile = JSON.parse(localStorage.getItem('merchant_profile'));
				let staff_name = localStorage.getItem('selected_staff_user');
				let printConfigs = JSON.parse(localStorage.getItem('print_configs'));
				console.log("printConfigs: " + printConfigs);
				let printConfigAvailable = false;
				let selectedPrintConfig;
				if(selectedUSBPrinter){
					if(printConfigs && printConfigs instanceof Array){

						let printC = printConfigs.filter(item=> item.printer_id === selectedUSBPrinter.id);
						if(printC && printC instanceof Array && printC.length > 0){
							printConfigAvailable = true;
							selectedPrintConfig = printC[0].print_config
						}
					}
				}
				let printData: any = {
					print_logo_on_bill: shopConfiguration.shop_info.print_logo_on_bill,
					//imgSrc:"", //added below
					//user_name:""//added below
					shop_name: shopConfiguration.shop_info.name ? shopConfiguration.shop_info.name : "NA",
					shop_address: shopConfiguration.shop_info.address,
					shop_zip_code: shopConfiguration.shop_info.zip_code,
					shop_phone_number: shopConfiguration.shop_info.shop_phone_number || merchantProfile.merchant.phone,
					item_wise_tax: shopConfiguration.item_wise_tax,
					enable_tables: shopConfiguration.enable_tables,
					print_sectionwise: shopConfiguration.shop_info.print_sectionwise,
					show_post_tax_price: shopConfiguration.show_post_tax_price,
					enabled_language_code: shopConfiguration.enabled_language_code || null,
					localize_titles: null,
					isTwoLanguageShown: false,
					currencySymbol: this.getCurrencySymbol(shopConfiguration),
					decimalCount: shopConfiguration.currency == "BHD" ? 3 : 2,
					tax_remark: this.getGstRemark(shopConfiguration),
					gstin: shopConfiguration.shop_info.gstin || null,
					print_extra_field: shopConfiguration.shop_info.print_extra_field || null,
					extra_field_value: shopConfiguration.shop_info.extra_field_value,
					extra_field_name: shopConfiguration.shop_info.extra_field_name,
					//deliveryDateTime:null,//added below
					//transactionDateTime:null,//added below
					//cartSections:null,//added below
					//taxCodeWiseItems:null,//added below
					//print_footer_note:"",//add only in usb print
					//print_footer_note_enabled:"",//add only in usb print
					staff_name : staff_name
				}
				//multi language labels
				if (printData.enabled_language_code) {
					printData.localize_titles = {
						"particulars": MultiLanguageTitles.titles["particulars"][printData.enabled_language_code] || "",
						"rate": MultiLanguageTitles.titles["rate"][printData.enabled_language_code] || "",
						"qty": MultiLanguageTitles.titles["qty"][printData.enabled_language_code] || "",
						"amount": MultiLanguageTitles.titles["amount"][printData.enabled_language_code] || "",
						"sub total": MultiLanguageTitles.titles["sub total"][printData.enabled_language_code] || "",
						"sub_total": MultiLanguageTitles.titles["sub total"][printData.enabled_language_code] || "",
						"total": MultiLanguageTitles.titles["total"][printData.enabled_language_code] || "",
						"round off": MultiLanguageTitles.titles["round off"][printData.enabled_language_code] || "",
						"round_off": MultiLanguageTitles.titles["round off"][printData.enabled_language_code] || "",
						"phone number": MultiLanguageTitles.titles["phone number"][printData.enabled_language_code] || "",
						"phone_number": MultiLanguageTitles.titles["phone number"][printData.enabled_language_code] || "",
						"pin code": MultiLanguageTitles.titles["pin code"][printData.enabled_language_code] || "",
						"pin_code": MultiLanguageTitles.titles["pin code"][printData.enabled_language_code] || "",
						"user name": MultiLanguageTitles.titles["user name"][printData.enabled_language_code] || "",
						"user_name": MultiLanguageTitles.titles["user name"][printData.enabled_language_code] || "",
						"receipt": MultiLanguageTitles.titles["receipt"][printData.enabled_language_code] || "",
						"transaction date": MultiLanguageTitles.titles["transaction date"][printData.enabled_language_code] || "",
						"transaction_date": MultiLanguageTitles.titles["transaction date"][printData.enabled_language_code] || "",
						"delivery date": MultiLanguageTitles.titles["delivery date"][printData.enabled_language_code] || "",
						"delivery_date": MultiLanguageTitles.titles["delivery date"][printData.enabled_language_code] || "",
						"discount": MultiLanguageTitles.titles["discount"][printData.enabled_language_code] || "",
						"cash discount": MultiLanguageTitles.titles["cash discount"][printData.enabled_language_code] || "",
						"cash_discount": MultiLanguageTitles.titles["cash discount"][printData.enabled_language_code] || "",
						"name": MultiLanguageTitles.titles["name"][printData.enabled_language_code] || "",
						"address": MultiLanguageTitles.titles["address"][printData.enabled_language_code] || "",
						"thank you": MultiLanguageTitles.titles["thank you"][printData.enabled_language_code] || "",
						"thank_you": MultiLanguageTitles.titles["thank you"][printData.enabled_language_code] || "",
						"ref id": MultiLanguageTitles.titles["ref id"][printData.enabled_language_code] || "",
						"ref_id": MultiLanguageTitles.titles["ref id"][printData.enabled_language_code] || "",
						"terminal": MultiLanguageTitles.titles["terminal"][printData.enabled_language_code] || "",
						"table no": MultiLanguageTitles.titles["table no"][printData.enabled_language_code] || "",
						"table_no": MultiLanguageTitles.titles["table no"][printData.enabled_language_code] || "",
						"seat no": MultiLanguageTitles.titles["seat no"][printData.enabled_language_code] || "",
						"seat_no": MultiLanguageTitles.titles["seat no"][printData.enabled_language_code] || "",
						"section": MultiLanguageTitles.titles["section"][printData.enabled_language_code] || "",
						"credit note amount": MultiLanguageTitles.titles["credit note amount"][printData.enabled_language_code] || "",
						"credit_note_amount": MultiLanguageTitles.titles["credit note amount"][printData.enabled_language_code] || "",
					};

					if (["ar-qa", "ar-kw"].indexOf(printData.enabled_language_code) != -1) {
						printData.isTwoLanguageShown = true;
					}
				}

				if (local_config.show_gst_summary_on_print) {
					printData.show_gst_summary_on_print = true;
				}

				if (localStorage.getItem('shop_image')) {
					var shop_image = JSON.parse(localStorage.getItem('shop_image'));
					printData.imgSrc =  shop_image.src;
					printData.imgHeight = shop_image.height;
					printData.imgWidth = shop_image.width;
				}

				if (billInfo.client_reference_code && billInfo.client_reference_code != "NA") {

					let splitCRCData = billInfo.client_reference_code.split("/");
					printData["bill_no"] = splitCRCData[0];
					printData["terminal"] = splitCRCData[1];
					printData["bill_ref_id"] = splitCRCData[2];
				} else {
					printData["bill_no"] = billInfo.order_id;
					printData["terminal"] = null;
					printData["bill_ref_id"] = null;
				}

				//for user name	   
				if (billInfo.user_name) {
					printData["user_name"] = billInfo.user_name;
				} else {
					let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')) || null;
					if (billInfo.assigny) {
						let shopUserList = JSON.parse(localStorage.getItem('shop_user_list')) || null;
						if (shopUserList && shopUserList.length > 0) {
							let staffUser = findLast(shopUserList, (shopUser) => { return shopUser.id == billInfo.assigny });
							printData.user_name = staffUser ? staffUser.name : null;
						}
					} else if (loggedInUser) {
						printData.user_name = loggedInUser.name;
					}
				}

				//delivery date and time
				if (billInfo.delivery_date) {
					var dDate = new Date(billInfo.delivery_date);
					var dateArr = dDate.toJSON().slice(0, 10).split('-');
					if (dateArr && dateArr.length > 0) {
						var date = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
						var time = (dDate.getHours().toString().length == 1 ? '0' + dDate.getHours() : dDate.getHours()) + ':' + (dDate.getMinutes().toString().length == 1 ? '0' + dDate.getMinutes() : dDate.getMinutes()) + ':' + (dDate.getSeconds().toString().length == 1 ? '0' + dDate.getSeconds() : dDate.getSeconds());
						printData.deliveryDateTime = date + ' ' + time;
					}
				}

				//transcation date and time
				if (billInfo.createdAt) {
					let dDate = new Date();  // Get the system's current date and time
					let date = `${dDate.getDate().toString().padStart(2, '0')}-${(dDate.getMonth() + 1).toString().padStart(2, '0')}-${dDate.getFullYear()}`;
					let time = [dDate.getHours(), dDate.getMinutes(), dDate.getSeconds()]
						.map(unit => unit.toString().padStart(2, '0'))
						.join(':');

					printData.dateTime = `${date} ${time}`;
					console.log("printData.dateTime:", printData.dateTime);

				}

				var cart_items = [];
				billInfo.cart_items.forEach(itm => {
					let item = (itm.selected_variant_item) ? JSON.parse(JSON.stringify(itm.selected_variant_item)) : JSON.parse(JSON.stringify(itm));
					if (item.discount && (item.discount_type || item.discountUnits)) {
						let discType = item.discount_type ? item.discount_type : item.discountUnits;
						item['discount_amount'] = (discType != '%') ? item.discount : (item.unit_price * item.item_count) * (item.discount / 100);
					}

					if (!item['other_discounts']) { item['other_discounts'] = [] }

					let totalOtherDiscForItem = 0.0;
					item['other_discounts'].forEach(disc => {
						totalOtherDiscForItem += disc.amount;
					})
					item['other_discounts_total'] = totalOtherDiscForItem;

					if (item.unit && this.checkIsSpecialOrder(item)) {
						item.item_name += " " + item.unit_value + " " + item.unit;
					}

					if (printData.enabled_language_code && item.localize_names && item.localize_names[printData.enabled_language_code]) {
						item.localize_item_name = item.localize_names[printData.enabled_language_code];
						if (wifiPrinterPresent && ["ar-qa", "ar-kw"].indexOf(printData.enabled_language_code) != -1) {
							item.localize_item_name = item.localize_item_name.split("").reverse().join("");
						}
					}

					var present = false;
					if (billInfo.group_taxes && billInfo.group_taxes.length > 0 && item.tax_breakup && item.tax_breakup.length > 0) {
						for (var i = 0, fLen = billInfo.group_taxes.length; i < fLen; i++) {
							for (var j = 0, jLen = item.tax_breakup.length; j < jLen; j++) {
								if (billInfo.group_taxes[i].breakup_name == item.tax_breakup[j].breakup_name &&
									billInfo.group_taxes[i].rate == item.tax_breakup[j].rate) {
									if (billInfo.group_taxes[i].item_count > 0) {
										billInfo.group_taxes[i].item_count += item.item_count;
									} else {
										billInfo.group_taxes[i].item_count = item.item_count;
									}
								}
							}
						}
					}

					cart_items.push(item);
				});

				cart_items = this.groupSameItems(cart_items, shopConfiguration);

				if (printData.print_sectionwise) {
					//for sectionwise receipt printing
					printData.cartSections = this.detectCartSections([...cart_items], shopConfiguration);
					if (shopConfiguration.item_wise_tax) {
						//for tax code wise item grouping
						printData.cartSections.forEach(section => {
							section.taxCodeWiseItems = this.groupItemsByTaxCode(section.items);
						})
					}
				} else {
					//for normal printing
					// billInfo.cart_items = this.stagedVisitor.dishes[0];
					billInfo.cart_items = [...cart_items];
					if (shopConfiguration.item_wise_tax) {
						//for tax code wise item grouping
						printData.taxCodeWiseItems = this.groupItemsByTaxCode(billInfo.cart_items);
					}
				}

				if (shopConfiguration.item_wise_tax && printData.show_gst_summary_on_print) {
					printData.taxesByTaxCode = this.groupTaxByTaxCode(billInfo.cart_items);
					let totalBreakup = { code: "Total GST", rate: "", tax_breakup: { SGST: 0, CGST: 0, IGST: 0 } };
					printData.taxesByTaxCode.forEach((t: any) => {
						t.code = t.code.replace("HSN", "");
						t.code = t.code.replace("SAC", "");
						totalBreakup["tax_breakup"]["SGST"] += t.tax_breakup["SGST"] ? t.tax_breakup["SGST"] : 0;
						totalBreakup["tax_breakup"]["CGST"] += t.tax_breakup["CGST"] ? t.tax_breakup["CGST"] : 0;
						totalBreakup["tax_breakup"]["IGST"] += t.tax_breakup["IGST"] ? t.tax_breakup["IGST"] : 0;
					});

					remove(printData.taxesByTaxCode, (t: any) => { return t.rate == 0; });
					if (printData.taxesByTaxCode.length > 0) {
						printData.taxesByTaxCode.push(totalBreakup);
					}
				} else {
					printData.taxesByTaxCode = [];
				}

				let printObj = Object.assign(printData, billInfo);
				if (localStorage.getItem('PRINT_UTILITY')) {
					this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
				}
				if (this.printerUtility) {
					printObj.print_footer_note = selectedUSBPrinter.print_footer_note;
					printObj.print_footer_note_enabled = selectedUSBPrinter.print_footer_note_enabled;
					printObj.print_header = selectedUSBPrinter.print_header ? selectedUSBPrinter.print_header : "Receipt / Tax Invoice";
					printObj.tax_number_title = selectedUSBPrinter.tax_number_title ? selectedUSBPrinter.tax_number_title : "";
					this.printOnUtility(printObj, selectedUSBPrinter, printType);
				} else {
					if (wifiPrinterPresent) {
						this.printCheckPrintOnWifiPrinter(selectedUSBPrinter, printObj);
					} else if (usbPrinterPresent) {
						this.printCheckPrintOnUSBPrinter(selectedUSBPrinter, printObj);
					}
				}

				if (!wifiPrinterPresent && !usbPrinterPresent) {
					alert("No printer found for check print");
					return resolve(false);

				}
				/*} else {
					return resolve(true);
				}*/
			} else {
				alert("Printer data could not be fetched at this time. Please logout and login again.");
				return resolve(false);
			}
		})
	}
	printOnUtility(printObj, selectedPrinter, printType) {
		let data = {};
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			})
		};
		if (printType === 'bill') {
			data = {
				printerName: selectedPrinter.name,
				paperSize:selectedPrinter.paper_size,
				billJson: printObj
			}
			this.http.post(BaseConfig.config.PRINT_API + BaseConfig.config.PRINT_BILL, JSON.stringify(data), httpOptions).subscribe(data => {
				console.log(data);
			});
		}

		if (printType === 'recent') {
			data = {
				printerName: selectedPrinter.name,
				paperSize:selectedPrinter.paper_size,
				billJson: printObj
			}
			this.http.post(BaseConfig.config.PRINT_API + BaseConfig.config.PRINT_RECENT_BILL, JSON.stringify(data), httpOptions).subscribe(data => {
				console.log(data);
			});
		}
	}


	getCurrencySymbol(shopConfiguration) {
		if (shopConfiguration.currency) {
			var currency = shopConfiguration.currency;
			if (currency.toUpperCase() == 'INR') {
				return 'Rs.';
			} else {
				var currency_symbol = shopConfiguration.currency_symbol;
				var symbol = currency_symbol.toLowerCase();
				if (symbol.charAt(0) === 'u') {
					symbol = symbol.split('u')[1];
					symbol = String.fromCharCode(parseInt(symbol, 16));
				} else {
					symbol = symbol.toUpperCase();
				}

				return symbol;
			}
		} else {
			return "";
		}
	}

	getGstRemark(shopConfiguration) {
		var GSTRemarks = [];
		if (shopConfiguration.shop_info.country) {
			var country = shopConfiguration.shop_info.country;
			if (shopConfiguration.tax_remark && shopConfiguration.tax_remark.length > 0) {
				shopConfiguration.tax_remark.forEach(obj => {
					if (obj[country]) {
						var tax_ids = Object.keys(obj[country]);
						if (Array.isArray(tax_ids)) {
							tax_ids.forEach(key => {
								var temp: any = {};
								temp['remark'] = key;
								temp['code'] = obj[country][key];
								GSTRemarks.push(temp);
							})
						}
					}
				})
			}
		}
		return GSTRemarks;
	}

	checkIsSpecialOrder(item) {
		return item.special_order_information && item.special_order_information.supplier_id;
	}

	areDiscountsSame(item1, item2) {
		return this.areItemWiseDiscountSame(item1, item2) && this.areOtherDiscountsSame(item1, item2);
	}

	areItemWiseDiscountSame(item1, item2) {
		if (item1.discount_amount && item2.discount_amount) { //Condition 1
			if ((item1.discountUnits == "%" && item2.discountUnits == "%") || (item1.discountUnits != "%" && item2.discountUnits != "%")) { //Condition 2
				if ((item1.discountUnits == "%" && item2.discountUnits == "%")) { //Condition 3
					return item1.discount == item2.discount;
				} else {
					//Condition 3 else
					return true
				}
			} else {
				//Condition 2 else
				return false
			}
		} else {
			//Condition 1 else
			return !item1.discount_amount && !item2.discount_amount
		}
	}

	areOtherDiscountsSame(item1, item2) {

		if (item1.other_discounts.length > 0 && item2.other_discounts.length > 0) { //Condition 1

			if (item1.other_discounts.length == item2.other_discounts.length) { //Condition 2

				item1.other_discounts.forEach(disc1 => {
					var commonDisc = item2.other_discounts.filter(disc2 => disc2.discount_code == disc1.discount_code)
					if (commonDisc.length > 0) { //Condition 3
						var disc2 = commonDisc[0];

						if ((disc1.discount_type == "%" && disc2.discount_type == "%") || (disc1.discount_type != "%" && disc2.discount_type != "%")) { //Condition 4
							if ((disc1.discount_type == "%" && disc2.discount_type == "%") && (disc1.discount != disc2.discount)) {
								return false;
							}
						} else {
							//Condition 4 else
							return false
						}


					} else {
						//Condition 3 else
						return false
					}

				})
				return true // returns true if all conditions are satisfied
			} else {
				//Condition 2 else
				return false
			}

		} else {
			//Condition 1 else
			return !(item1.other_discounts.length > 0) && !(item2.other_discounts.length > 0)
		}
	}

	//function to check if 2 modifier arrays are identical
	areModifiersSame(mod_array_1, mod_array_2) {
		if (mod_array_1.length != mod_array_2.length) {
			return false
		} else {
			var commonMods = [];
			mod_array_1.forEach(mod1 => {
				mod_array_2.forEach(mod2 => {
					if ((mod1.attribute_code == mod2.attribute_code)) { // && (mod1.count == mod2.count)
						commonMods.push(mod1);
					}
				})
			})
			if (commonMods.length == mod_array_1.length) {
				return true;
			} else {
				return false;
			}
		}
	}

	//function to check if 2 item notes are identical
	areItemNoteSame(item1, item2) {
		return (item1.note == item2.note);
	}

	// function to club all items having same item code,modifiers and discount
	groupSameItems(cart_items, shopConfiguration) {
		var cartItems = [];
		var itemGroups = {};

		cart_items.forEach(item => {
			if (item.item_code in itemGroups) {
				var itemFound = false;
				var item_mod_attrs = [];
				item_mod_attrs = item.attributes.filter(attr => !attr.is_variant_attribute)
				for (var i in itemGroups[item.item_code]) {
					var sameItem_mod_attrs = [];
					sameItem_mod_attrs = itemGroups[item.item_code][i].attributes.filter(attr => !attr.is_variant_attribute)
					if (this.areItemNoteSame(item, itemGroups[item.item_code][i]) && this.areDiscountsSame(item, itemGroups[item.item_code][i]) && this.areModifiersSame(item_mod_attrs, sameItem_mod_attrs)) {
						itemGroups[item.item_code][i].item_count += item.item_count;
						if (item.discount && (item.discount_type || item.discountUnits)) {
							let discType = item.discount_type ? item.discount_type : item.discountUnits;
							if (discType != '%') {
								itemGroups[item.item_code][i]['discount_amount'] += item.discount;
								itemGroups[item.item_code][i].discount += item.discount;
							} else {
								itemGroups[item.item_code][i]['discount_amount'] += (item.unit_price * item.item_count) * (item.discount / 100);
							}
						}

						if (itemGroups[item.item_code][i]['other_discounts'].length > 0 && item['other_discounts'].length > 0) {
							itemGroups[item.item_code][i]['other_discounts'].forEach(disc => {
								var itemDisc = item['other_discounts'].filter(d => d.discount_code == disc.discount_code)
								disc.amount += itemDisc[0] && itemDisc[0].amount ? itemDisc[0].amount : 0
								itemGroups[item.item_code][i]['other_discounts_total'] += itemDisc[0] && itemDisc[0].amount ? itemDisc[0].amount : 0
							})
						}

						if (shopConfiguration.item_wise_tax) {
							itemGroups[item.item_code][i].tax_value = itemGroups[item.item_code][i].item_count * (item.tax_value / item.item_count)
						}

						//todo discount amount subtraction
						itemGroups[item.item_code][i].attributes.forEach(attr => {
							if (!attr.is_variant_attribute) {
								attr.count += item.attributes.filter(at => attr.attribute_code == at.attribute_code)[0] ? item.attributes.filter(at => attr.attribute_code == at.attribute_code)[0].count : 0;
							}
						})

						itemFound = true;
						break;
					}
				}
				if (!itemFound) {
					itemGroups[item.item_code].push(item);
				}
			} else {
				itemGroups[item.item_code] = [];
				itemGroups[item.item_code].push(item);
			}
		})

		for (var item_code in itemGroups) {
			cartItems = cartItems.concat(itemGroups[item_code])
		}
		return cartItems;
	}

	findSection(tags: any, shopConfiguration) {
		let receipt_sections: any = shopConfiguration["shop_info"]["receipt_sections"];
		let found_section;

		if (tags) {
			//localStorage.receipt_sections
			for (var i = 0; i < tags.length; i++) {
				for (var section in receipt_sections) {
					for (var j = 0; j < receipt_sections[section].length; j++) {
						if (receipt_sections[section][j].toLowerCase() === tags[i].toLowerCase()) {
							found_section = section;
							break;
						}
					}
					if (found_section) { break; }
				}
				if (found_section) { break; }
			}
		}
		return found_section ? found_section : "Section Not Found";
	}

	getCartItemValue(item, shopConfiguration) {
		var sellPrice = 0;

		//adding item price
		sellPrice = item.unit_price * item.item_count;
		if (shopConfiguration.show_post_tax_price && shopConfiguration.item_wise_tax && item.tax_value) {
			sellPrice = sellPrice + item.tax_value;
		}

		//adding modifier price to item price
		if (item.attributes.length > 0) {
			for (var attr = 0; attr < item.attributes.length; attr++) {
				if (item.attributes[attr].unit_price && item.attributes[attr].count) {
					sellPrice = sellPrice + (item.attributes[attr].unit_price * item.attributes[attr].count);
				}
			}
		}
		//deducting item wise discount
		if (item.discount && (item.discount_type || item.discountUnits)) {
			let discType = item.discount_type ? item.discount_type : item.discountUnits;
			if (discType != '%') {
				sellPrice -= item.discount;
			} else {
				sellPrice -= (item.unit_price * item.item_count) * (item.discount / 100);
			}
		}

		sellPrice -= item.other_discounts_total;

		return sellPrice;
	}

	groupTaxByTaxCode(items) {
		var taxCodeWiseTax = [];
		items.forEach(itm => {
			var item;
			var findCode = "", foundRate = 0;

			if (itm.selected_variant_item) {
				item = JSON.parse(JSON.stringify(itm.selected_variant_item));
			} else {
				item = JSON.parse(JSON.stringify(itm));
			}

			if (!item.tax_settings && item.tax_type && item.tax_rate != null) {
				item.tax_settings = [{ code: item.tax_type, rate: parseFloat(item.tax_rate) }];
			}

			if (item.tax_settings && item.tax_settings.length > 0) {
				//item has tax
				if (item.tax_settings[0].code && item.tax_settings[0].rate) {
					findCode = item.tax_settings[0].code;
					foundRate = item.tax_settings[0].rate;
				}
			} else {
				//item doesn't have tax
				findCode = "no_tax";
				foundRate = 0;
			}

			if (taxCodeWiseTax.filter(taxGroup => taxGroup.code == findCode && taxGroup.rate == foundRate).length > 0) {
				//if tax code already present
				var taxGrIndex = taxCodeWiseTax.indexOf(taxCodeWiseTax.filter(taxGroup => taxGroup.code == findCode && taxGroup.rate == foundRate)[0]);
				if (Array.isArray(item.tax_breakup) && taxGrIndex >= 0) {
					item.tax_breakup.forEach((taBreakup: any) => {
						if (taBreakup.breakup_name && ["SGST", "CGST", "IGST"].indexOf(taBreakup.breakup_name.toUpperCase()) != -1) {
							if (taxCodeWiseTax[taxGrIndex] && taxCodeWiseTax[taxGrIndex].tax_breakup) {
								taxCodeWiseTax[taxGrIndex].tax_breakup[taBreakup.breakup_name.toUpperCase()] += taBreakup.value;
							} else {
								taxCodeWiseTax[taxGrIndex].tax_breakup[taBreakup.breakup_name.toUpperCase()] = taBreakup.value;
							}
						}
					});
				}
			} else {
				//if tax code not already present
				var taxGroup = { 'code': findCode, 'rate': 0.0, 'tax_breakup': {} };
				if (item.tax_settings && item.tax_settings.length > 0) {
					taxGroup.rate = item.tax_settings[0].rate;
				}

				if (Array.isArray(item.tax_breakup)) {
					item.tax_breakup.forEach((taBreakup: any) => {
						if (taBreakup.breakup_name && ["SGST", "CGST", "IGST"].indexOf(taBreakup.breakup_name.toUpperCase()) != -1) {
							taxGroup.tax_breakup[taBreakup.breakup_name.toUpperCase()] = taBreakup.value;
						}
					});
				}
				taxCodeWiseTax.push(taxGroup);
			}
		});
		return taxCodeWiseTax;
	}

	groupItemsByTaxCode(items) {
		var taxCodeWiseItems = [];
		items.forEach(itm => {
			var item;
			var findCode = "", foundRate = 0;

			if (itm.selected_variant_item) {
				item = JSON.parse(JSON.stringify(itm.selected_variant_item));
			} else {
				item = JSON.parse(JSON.stringify(itm));
			}

			if (!item.tax_settings && item.tax_type && item.tax_rate != null) {
				item.tax_settings = [{ code: item.tax_type, rate: parseFloat(item.tax_rate) }];
			}

			if (item.tax_settings && item.tax_settings.length > 0) {
				//item has tax
				if (item.tax_settings[0].code && item.tax_settings[0].rate) {
					findCode = item.tax_settings[0].code;
					foundRate = item.tax_settings[0].rate;
				}
			} else {
				//item doesn't have tax
				findCode = "no_tax";
				foundRate = 0;
			}

			if (taxCodeWiseItems.filter(taxGroup => taxGroup.code == findCode && taxGroup.rate == foundRate).length > 0) {
				//if tax code already present
				var taxGrIndex = taxCodeWiseItems.indexOf(taxCodeWiseItems.filter(taxGroup => taxGroup.code == findCode && taxGroup.rate == foundRate)[0]);
				taxCodeWiseItems[taxGrIndex].items.push(JSON.parse(JSON.stringify(item)));
			} else {
				//if tax code not already present
				var taxGroup = { 'code': findCode, 'rate': 0.0, 'items': [] };
				if (item.tax_settings && item.tax_settings.length > 0) {
					taxGroup.rate = item.tax_settings[0].rate;
				}

				taxGroup.items.push(JSON.parse(JSON.stringify(item)));
				taxCodeWiseItems.push(taxGroup);
			}
		});
		return taxCodeWiseItems;
	}

	detectCartSections(cartItems, shopConfiguration) {
		let sectionAlreadyPresentFlag = false;
		let cartSections = [];
		let cartSectionsObj = {
			sectionName: "",
			itemCount: 0,
			subTotal: 0,
			items: []
		};

		if (cartItems) {
			for (var i = 0; i < cartItems.length; i++) {
				let item = cartItems[i];
				cartSectionsObj = {
					sectionName: "",
					itemCount: 0,
					subTotal: 0,
					items: []
				};

				sectionAlreadyPresentFlag = false;
				for (var j = 0; j < cartSections.length; j++) {
					if (this.findSection(item.tags, shopConfiguration) == cartSections[j].sectionName) {
						sectionAlreadyPresentFlag = true;
						cartSections[j].itemCount = cartSections[j].itemCount + item.item_count;
						cartSections[j].subTotal = cartSections[j].subTotal + this.getCartItemValue(item, shopConfiguration);
						cartSections[j].items.push(item);
					}
				}
				if (!sectionAlreadyPresentFlag) {
					cartSectionsObj.sectionName = this.findSection(item.tags, shopConfiguration);
					cartSectionsObj.itemCount = item.item_count;
					cartSectionsObj.subTotal = this.getCartItemValue(item, shopConfiguration);
					cartSectionsObj.items.push(item);
					cartSections.push(cartSectionsObj);
				}
			}
		}
		return cartSections;
	}

	printCheckPrintOnUSBPrinter(printerObj, printObj) {
		console.log("printerObj:", printerObj);
		console.log("printObj:", printObj);
		let printConfigs = JSON.parse(localStorage.getItem('print_configs'));
				let printConfigAvailable = false;
				let selectedPrintConfig;
				if(printConfigs && printConfigs instanceof Array){
					let printC = printConfigs.filter(item=> item.printer_id === printerObj.id);
					if(printC && printC instanceof Array && printC.length > 0){
						printConfigAvailable = true;
						selectedPrintConfig = printC[0].print_config
					}
				}

				printObj['print_config'] = selectedPrintConfig;
				printObj['print_staff_name'] = printerObj?.print_staff_name
				// printObj['printConfigAvailable'] = printConfigAvailable;

		printObj.print_footer_note = printerObj.print_footer_note;
		printObj.print_footer_note_enabled = printerObj.print_footer_note_enabled;
		printObj.print_header = printerObj.print_header ? printerObj.print_header : "Receipt / Tax Invoice";
		printObj.tax_number_title = printerObj.tax_number_title ? printerObj.tax_number_title : "";
		for (let index = 0; index < printObj.cart_items.length; index++) {
			const element = printObj.cart_items[index];
			if (!element.included_combo_items) {
				element.included_combo_items = [];
			}
			if (!element.combo_item_codes) {
				element.combo_item_codes = [];
			}
		}
		var str2 = null
		var paymentCode = printObj.splitPaymentList[0].payment_code;
		var diary_balance_available = false;
		if(paymentCode == 'PAYT0001' || paymentCode == 'PAY_FROM_DIARY'){
			var current_diary_balance = parseInt(localStorage.getItem('current_diary_balance'));
			if(current_diary_balance){
				console.log('Diary Balance: before adding total price: ' + current_diary_balance + ' \n');
				let total_price = parseInt(printObj.totalPrice) || 0;
				current_diary_balance += total_price;
				console.log("diary balance: after adding total price: " + current_diary_balance + ' \n');
				// Inject updated diary balance into str2
				diary_balance_available = true;
			}
		}
		

		if(printConfigAvailable){
				console.log("print_config:", printConfigs)
				str2 = `
					<table style="text-align: center; width: 100%; font-family: sans-serif;">
					<tbody>
						<tr>
						<td>

							<table style="text-align: left; width: 100%; max-width: 700px; border: 0px solid #ccc; margin: auto;">
							<tbody>
								<tr>
								<td max-width: 700px;>

								<!-- Shop details begin -->
									<table style="text-align: center; width: 100%">
									<tbody>

									{{? it.print_config.shop_logo}}
										{{? it.print_logo_on_bill && it.imgSrc}}
										<tr>
											<td>
												<img src ={{=it.imgSrc}}>
											<td>
										</tr>
										{{?}}
									{{?}}

										{{? !it.print_logo_on_bill || it.print_config.outlet_name}}
										<tr>
											<td style="font-size: 20px; font-weight: 600;">{{=it.shop_name}}</td>
										</tr>
										{{?}}

										{{? it.print_config.shop_address}}
											{{? it.shop_address && it.shop_address != "NA"}}
												<tr>
												<td>
												{{=it.shop_address}}                          
												</td>
												</tr>
											{{?}}
										{{?}}
										{{? it.print_config.shop_zip_code}}
											{{? it.shop_zip_code && it.shop_zip_code != "NA"}}
												<tr>
												<td>
													Pin Code:
													{{=it.shop_zip_code}}
													{{? it.localize_titles && it.localize_titles['pin code']}}:{{=it.localize_titles['pin code']}} {{?}}
												</td>
												</tr>
											{{?}}
										{{?}}
									</tbody>
									</table>
									<!-- Shop details end -->
									<!-- Header Container -->

									<!-- Merchant details begin -->
									<table style="text-align: center; width: 100%; font-size: 14px;">
									<tbody>
										{{? it.print_config.outlet_phone_number}}
											<tr>
											{{? it.shop_phone_number && it.shop_phone_number != "NA"}}
											<td style="text-align: center;">
												PH:
												{{=it.shop_phone_number}}
												{{? it.localize_titles && it.localize_titles['phone number']}}:{{=it.localize_titles['phone number']}} {{?}}
											</td>		                         
											{{?}}
											</tr>
										{{?}}
										{{? it.print_config.outlet_gst_no}}
										<tr>
											<td style="text-align: center;">
												{{? it.tax_number_title}}
													{{=it.tax_number_title}}: {{=it.gstin}}
												{{?}}
												{{? !it.tax_number_title}}	
													Outlet GST No: {{=it.gstin}}
												{{?}}
											</td>
										</tr>
										{{?}}	
										{{? it.print_extra_field && it.extra_field_name && it.extra_field_value}}
										<tr>
											<td style="text-align: center;">
												{{=it.extra_field_name}}: {{=it.extra_field_value}}
											</td>
										</tr>
										{{?}}	
										
										<tr>
										{{? it.staff_name && it.staff_name != "NA" && it.print_staff_name}}
										<td style="text-align: center;">
										Staff:
										{{=it.staff_name}}
										
										</td>		                         
										{{?}}
									</tr>
									</tbody>
									</table>
									<!-- Merchant details end -->
									<!-- Address Container -->

									<table style="width: 100%;">
									<tbody>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
										<tr>
										<td style="border-bottom: 1px dashed #000;"></td>
										</tr>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
									</tbody>
									</table><!-- dotted hr-line -->

									<!-- Order details begin -->
									<table style="text-align: center; width: 100%">
									<tbody>
										{{? it.print_config.header_receipt_tax_invoice}}
											<tr>
												<td colspan = "5" style="text-align: center; font-size: 20px; font-weight: 600;">
													{{=it.print_header}}
													{{? it.localize_titles && it.localize_titles['receipt']}} <div style="text-align:center;"> {{=it.localize_titles['receipt']}} </div>{{?}}
												</td>
											</tr>
										{{?}}
										{{? it.print_config.transaction_date}}
											<tr>
											<td colspan="5" style="text-align: center;">
												{{? it.deliveryDateTime}} Transaction Date: {{?}} {{=it.dateTime}}
												{{? it.deliveryDateTime && it.localize_titles && it.localize_titles['transaction date']}}:{{=it.localize_titles['transaction date']}} {{?}}
											</td>
											</tr>
										{{?}}
										{{? it.print_config.delivery_date}}
											{{? it.deliveryDateTime}}
												<tr>
													<td colspan="5" style="text-align: center;">
														Delivery Date: {{=it.deliveryDateTime}}
														{{? it.localize_titles && it.localize_titles['delivery date']}}:{{=it.localize_titles['delivery date']}} {{?}}
													</td>
												</tr>
											{{?}}
										{{?}}
										{{? it.user_name || it.print_config.bill_number}}                        
											<tr>
												
													<td colspan="5" style="text-align: center;">
													{{? it.bill_no && it.bill_no != "NA" && it.print_config.bill_number}}
														<span>Bill No: {{=it.bill_no}} &nbsp; &nbsp;</span>
													{{?}}
													{{? it.user_name && it.print_config.user_name}}
														<span>User: {{=it.user_name}}</span>
													{{?}}
													</td>
												
											</tr>
											{{?}}

										{{? it.checkPrintNumber}}
										<tr>
											<td colspan="5" style="text-align: center;">
												Check Print Number: {{=it.checkPrintNumber}}
											</td>
										</tr>
										{{?}}	
									</tbody>
									</table>
									<!-- Order details end -->

									<!-- Customer information begins -->
									{{?it.customer_name && it.customer_mobile_no != "0000000000"}}
										<table style="width: 100%;">
										<tbody>
											<tr>
											<td colspan="3" height="5"></td><!-- margin -->
											</tr>
											<tr>
											<td style="border-bottom: 1px dashed #000;"></td>
											</tr>
											<tr>
											<td colspan="3" height="5"></td><!-- margin -->
											</tr>
										</tbody>
										</table><!-- dotted hr-line -->
										<table style="text-align: center; width: 100%">
											<tbody>
											<tr>
												<td style="font-weight: 600;">
												CUSTOMER INFORMATION
												{{? it.localize_titles && it.localize_titles['customer information']}}<div style="text-align:right;">{{=it.localize_titles['customer information']}}</div> {{?}}
												</td>
											</tr>
											{{? it.customer_name && it.print_config.customer_name}}
												<tr>
												<td>
													<b>Name:</b> 
													{{=it.customer_name}}
													{{? it.localize_titles && it.localize_titles['name']}}<b>:{{=it.localize_titles['name']}}</b>{{?}}
												</td>
												</tr>
											{{?}}
											{{? it.print_config.customer_address}}
											{{? it.customer_address_1}}
												<tr>
												<td>
													<b>Address:</b> 
													{{=it.customer_address_1}}
													{{? it.localize_titles && it.localize_titles['address']}}<b>:{{=it.localize_titles['address']}}</b>{{?}}
												</td>
												</tr>
											{{?}}
											{{?}}

											{{? it.customer_mobile_no && it.customer_mobile_no != "NA" && it.print_config.customer_number}}
												<tr>
												<td>
													<b>PH:</b> 
													{{=it.customer_mobile_no}}
													{{? it.localize_titles && it.localize_titles['phone number']}}<b>:{{=it.localize_titles['phone number']}}</b>{{?}}
												</td>
												</tr>
											{{?}}
											
												{{? it.print_config.customer_gst_no}}
													{{? it.customer_gstin && it.customer_gstin != "NA" }}
													<tr>
														<td style="text-align: center;">
															<b>Customer GSTIN:</b> {{=it.customer_gstin}}
														</td>
													</tr>
													{{?}}
												{{?}}
											</tbody>
										</table>
										<table style="width: 100%;">
										<tbody>
											<tr>
											<td colspan="3" height="5"></td><!-- margin -->
											</tr>
											<tr>
											<td style="border-bottom: 1px dashed #000;"></td>
											</tr>
											<tr>
											<td colspan="3" height="5"></td><!-- margin -->
											</tr>
										</tbody>
										</table><!-- dotted hr-line -->
									{{?}}
									<!-- Customer information ends -->

									{{? it.enable_tables}}
										<table style="width: 100%;">
											<tbody>
												<tr>
													{{? it.print_config.shipping_type || it.print_config.section_garden_section}}
														<td colspan="2" style="text-align: left;">
															Section: {{=it.section}}
															{{? it.localize_titles && it.localize_titles['section']}}:{{=it.localize_titles['section']}} {{?}}
														</td>
													{{?}}
													<td colspan="1">
													{{? it.seat_no}}
														Seat No: {{=it.seat_no}}
														{{? it.localize_titles && it.localize_titles['seat no']}}:{{=it.localize_titles['seat no']}} {{?}}
													{{?}}
													</td>
													{{? it.print_config.table_number }}
														<td colspan="1" style="text-align: right;">
															Table No: {{=it.table_no}}
														{{? it.localize_titles && it.localize_titles['table no']}}:{{=it.localize_titles['table no']}} {{?}}
														</td>
													{{?}}
												</tr>
											</tbody>
										</table>
									{{?}}

									<!-- Receipt Container -->

									<table style="width: 100%;">
									<tbody>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
										<tr>
										<td style="border-bottom: 1px dashed #000;"></td>
										</tr>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
									</tbody>
									</table><!-- dotted hr-line -->


									<table style="width: 100%;">
									<tbody>
										<tr>
										<td colspan="2" style="text-align: left;" height="5">
											Particulars
											{{? it.localize_titles && it.localize_titles['particulars']}}<div style="text-align:right">{{=it.localize_titles['particulars']}}</div> {{?}}
										</td>
										<td colspan="1" style="text-align: right;" height="5">
											Rate
											{{? it.localize_titles && it.localize_titles['rate']}}<div style="text-align:right">{{=it.localize_titles['rate']}}</div> {{?}}
										</td>
										<td colspan="1" style="text-align: center;" height="5">
											Qty
											{{? it.localize_titles && it.localize_titles['qty']}}<div style="text-align:right">{{=it.localize_titles['qty']}}</div> {{?}}
										</td>
										<td colspan="2" style="text-align: right;" height="5">
											Amt
											{{? it.localize_titles && it.localize_titles['amount']}}<div style="text-align:right">{{=it.localize_titles['amount']}}</div> {{?}}
										</td>
										</tr>
									</tbody>
									</table>

									<table style="width: 100%;">
									<tbody>
									<tr>
										<td colspan="3" height="5"></td><!-- margin -->
									</tr>
									<tr>
										<td style="border-bottom: 1px dashed #000;"></td>
									</tr>
									<tr>
										<td colspan="3" height="5"></td><!-- margin -->
									</tr>
									</tbody>
								</table><!-- dotted hr-line -->



									<!-- Cart items list begin -->
									{{? !it.print_sectionwise}}

									{{? !it.item_wise_tax}}
									{{~it.cart_items :value:index}}
									<table style="width: 100%; text-align: left;table-layout:fixed;">
										<tr>
											<td width="30%" style="text-align: left;  word-wrap: break-word;">
											{{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}
											{{? value.localize_item_name }}<div style="text-align:right">{{=value.localize_item_name}}</div>{{?}}
											</td>
											<td width="10%" style="text-align: right;">{{=parseFloat(value.unit_price).toFixed(it.decimalCount)}}</td>
											<td width="10%" style="text-align: center;">{{=value.item_count}}</td>
											{{? !value.discount_amount}}
											<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
											{{?}}
											{{? value.discount_amount}}
											<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
											{{?}}
										</tr>

										{{? value.loose_item_name}}
											<tr>
												<td width="30%" style="font-size: 12px;text-align: left;">{{=value.loose_item_name.charAt(0).toUpperCase()+value.loose_item_name.substr(1)}} </td>
												<td></td>
												<td></td>	                                    
											</tr>
										{{?}}

										{{? value.attributes.length > 0}}
											{{~ value.attributes :attr:indx}}
												<tr>
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
													<td></td>
													<td></td>
													{{? (attr.unit_price * attr.count) != 0}}
													<td width="70%" style="font-size: 12px;text-align: right !important;">{{= (attr.unit_price * attr.count).toFixed(it.decimalCount)}}</td>
													{{?}}
												</tr>
											{{~}}
										{{?}}
										{{? value.combo_item_codes && value.combo_item_codes.length > 0}}
										{{~ value.combo_item_codes :attr:indx}}
										<tr>
											<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.quantity_in_combo}} x {{=attr.item_name.charAt(0).toUpperCase()+attr.item_name.substr(1)}} </td>
											<td></td>
											<td></td>
											<td></td>
											
										</tr>
									{{~}}
								{{?}}
								{{? value.included_combo_items && value.included_combo_items.length > 0}}
										{{~ value.included_combo_items :attr:indx}}
										<tr>
											<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.quantity}} x {{=attr.item_name.charAt(0).toUpperCase()+attr.item_name.substr(1)}} </td>
											<td></td>
											<td></td>
											<td></td>
											
										</tr>
									{{~}}
								{{?}}
										{{? value.discount_amount}}
										<tr>
											{{? value.discountUnits == "%"}}
											<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount @ {{= value.discount}}%</td>
											{{?}}
											{{? value.discountUnits != "%"}}
											<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount </td>
											{{?}}
											<td></td>
											<td></td>
											<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (value.discount_amount).toFixed(it.decimalCount)}}</td>
										</tr>
										{{?}}


										{{~ value.other_discounts: attr}}
										<tr>
											{{? attr.discount_type == "%"}}
												<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} @ {{=attr.discount}}%</td>
											{{?}}
											{{? attr.discount_type != "%"}}
												<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} </td>
											{{?}}
											<td></td>
											<td></td>
											<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (attr.amount).toFixed(it.decimalCount)}}</td>
										</tr>
									{{~}}

									{{? value.special_order_charges}}
											<tr>                            
												<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Sp. order charge </td>                            
												<td></td>
												<td></td>
												<td width="70%" style="font-size: 12px;text-align: right !important;">+{{= (value.special_order_charges).toFixed(it.decimalCount)}}</td>
											</tr>
										{{?}}


										{{? value.note}}
										<tr>
											<td width = "100%" style="font-size: 12px;text-align: left;">&nbsp;--{{=value.note}}</td>
										</tr>
										{{?}}
									</table>
									<!-- info table -->
									{{~}}



									<!--open else -->
									{{?? it.item_wise_tax}}
										{{~ it.taxCodeWiseItems :taxType:i}}
										<table style="width: 100%; text-align: left;table-layout:fixed;">
											<tr>
												{{? taxType.code != "no_tax"}}
													<td colspan="2" style="text-align: left;">{{=taxType.code}} @ {{=taxType.rate}}% </td>
												{{?}}
											</tr>
										</table>
										{{~ taxType.items :value:index}}
										<table style="width: 100%; text-align: left;table-layout:fixed;">
											<tr>
												<td width="30%" style="text-align: left;  word-wrap: break-word;">
													{{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}
													{{? value.localize_item_name }}<div style="text-align:right">{{=value.localize_item_name}}</div>{{?}}
												</td>
												{{? it.show_post_tax_price && value.tax_value}}
													<td width="10%" style="text-align: right;">{{=(Number(value.unit_price)).toFixed(it.decimalCount)}}</td>
												{{?}}
												{{? !it.show_post_tax_price || !value.tax_value}}
													<td width="10%" style="text-align: right;">{{=parseFloat(value.unit_price).toFixed(it.decimalCount)}}</td>
												{{?}}
												<td width="10%" style="text-align: center;">{{=value.item_count}}</td>
												{{? it.show_post_tax_price && value.tax_value}}
													{{? !value.discount_amount}}
													<td width="10%" style="text-align: right;">{{=((value.item_count * (Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count)))) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? value.discount_amount}}
													<td width="10%" style="text-align: right;">{{=((value.item_count * (Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count)))) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
													{{?}}
												{{?}}
												{{? !it.show_post_tax_price || !value.tax_value}}
													{{? !value.discount_amount}}
													<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? value.discount_amount}}
													<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
													{{?}}
												{{?}}
											</tr>
											{{? value.loose_item_name}}
													<tr>
														<td width="30%" style="font-size: 12px;text-align: left;">{{=value.loose_item_name.charAt(0).toUpperCase()+value.loose_item_name.substr(1)}} </td>
														<td></td>
														<td></td>	                                    
													</tr>
											{{?}}
											{{? value.attributes.length > 0}}
												{{~ value.attributes :attr:indx}}
														<tr>
															<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
															<td></td>
															<td></td>
															{{? (attr.unit_price * attr.count) != 0}}
																<td width="70%" style="font-size: 12px;text-align: right !important;">{{= (attr.unit_price * attr.count).toFixed(it.decimalCount)}}</td>
															{{?}}
														</tr>
												{{~}}
											{{?}}



											{{? value.discount_amount}}
												<tr>
													{{? value.discountUnits == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount @ {{=value.discount}}%</td>
													{{?}}
													{{? value.discountUnits != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount </td>
													{{?}}
													<td></td>
													<td></td>
													<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (value.discount_amount).toFixed(it.decimalCount)}}</td>
												</tr>
											{{?}}


											{{~ value.other_discounts: attr}}
												<tr>
													{{? attr.discount_type == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} @ {{=attr.discount}}%</td>
													{{?}}
													{{? attr.discount_type != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} </td>
													{{?}}
													<td></td>
													<td></td>
													<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (attr.amount).toFixed(it.decimalCount)}}</td>
												</tr>
											{{~}}

											{{? value.special_order_charges}}
												<tr>                            
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Sp. order charge </td>                            
													<td></td>
													<td></td>
													<td width="70%" style="font-size: 12px;text-align: right !important;">+{{= (value.special_order_charges).toFixed(it.decimalCount)}}</td>
												</tr>
											{{?}}


											{{? value.note}}
												<tr>
												<td width = "100%" style="font-size: 12px;text-align: left;">&nbsp;--{{=value.note}}</td>
												</tr>
											{{?}}

										</table>
										<!-- info table -->
										{{~}}
										{{? i!= (it.taxCodeWiseItems.length - 1)}}
											<table style="width: 100%; text-align: left;table-layout:fixed;">
												<tr>
													<td colspan="5" style="text-align: center;">---------------</td>
												</tr>
											</table>
										{{?}}
										{{~}}
									{{?}}<!--close else -->
									{{?}} <!--close !sectionwise_receipt if-->

									{{? it.print_sectionwise}}
									{{~ it.cartSections :section:index}}
									<!-- <table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td style='text-align: center;'>
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td style="text-align: center; font-weight: 600;">{{=section.sectionName.toUpperCase()}} ITEMS</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table> -->
									<!-- check table -->


									{{? !it.item_wise_tax}} <!-- i.e. no need to check show_post_tax_price -->
										{{~ section.items :value}}
										<table style="width: 100%; text-align: left;table-layout:fixed;">
											<tr>
												<td width="30%" style="text-align: left;  word-wrap: break-word;">
												{{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}
												{{? value.localize_item_name }}<div style="text-align:right">{{=value.localize_item_name}}</div>{{?}}
												</td>
												<td width="10%" style="text-align: right;">{{=parseFloat(value.unit_price).toFixed(it.decimalCount)}}</td>
												<td width="10%" style="text-align: center;">{{=value.item_count}}</td>
												{{? !value.discount_amount}}
												<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
												{{?}}
												{{? value.discount_amount}}
												<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
												{{?}}
											</tr>
											{{? value.loose_item_name}}
												<tr>
													<td width="30%" style="font-size: 12px;text-align: left;">{{=value.loose_item_name.charAt(0).toUpperCase()+value.loose_item_name.substr(1)}} </td>
													<td></td>
													<td></td>	                                    
												</tr>
											{{?}}
											{{? value.attributes && value.attributes.length > 0}}
												{{~ value.attributes :attr:ind}}
														<tr>
															<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
															<td></td>
															<td></td>
															{{? (attr.unit_price * attr.count) != 0}}
															<td width="70%" style="font-size: 12px;text-align: right !important;">{{= (attr.unit_price * attr.count).toFixed(it.decimalCount)}}</td>
															{{?}}
														</tr>
												{{~}}
											{{?}}


											{{? value.discount_amount}}
											<tr>
												{{? value.discountUnits == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount @ {{=value.discount}}%</td>
												{{?}}
												{{? value.discountUnits != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount </td>
												{{?}}
												<td></td>
												<td></td>
												<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (value.discount_amount).toFixed(it.decimalCount)}}</td>
											</tr>
											{{?}}


											{{~ value.other_discounts: attr}}
											<tr>
												{{? attr.discount_type == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} @ {{=attr.discount}}%</td>
												{{?}}
												{{? attr.discount_type != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} </td>
												{{?}}
												<td></td>
												<td></td>
												<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (attr.amount).toFixed(it.decimalCount)}}</td>
											</tr>
											{{~}}


											{{? value.note}}
											<tr>
												<td width = "100%" style="font-size: 12px;text-align: left;">&nbsp;--{{=value.note}}</td>
											</tr>
											{{?}}
										</table>
										<!-- info table -->
										{{~}}
									{{?}}



										{{? it.item_wise_tax}}
										{{~ section.taxCodeWiseItems :taxType:j}}
										<table style="width: 100%; text-align: left;table-layout:fixed;">
											<tr>
												{{? taxType.code != "no_tax"}}
													<td colspan="2" style="text-align: left;">{{=taxType.code}} @ {{=taxType.rate}}% </td>
												{{?}}
											</tr>
										</table>
											{{~ taxType.items :value}}
											<table style="width: 100%; text-align: left;table-layout:fixed;">
												<tr>
													<td width="30%" style="text-align: left;  word-wrap: break-word;">
													{{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}
													{{? value.localize_item_name }}<div style="text-align:right">{{=value.localize_item_name}}</div>{{?}}
													</td>
													{{? it.show_post_tax_price && value.tax_value}}
													<td width="10%" style="text-align: right;">{{=(Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count))).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? !it.show_post_tax_price || !value.tax_value}}
													<td width="10%" style="text-align: right;">{{=parseFloat(value.unit_price).toFixed(it.decimalCount)}}</td>
													{{?}}
													<td width="10%" style="text-align: center;">{{=value.item_count}}</td>
													{{? it.show_post_tax_price && value.tax_value}}
													{{? !value.discount_amount}}
														<td width="10%" style="text-align: right;">{{=((value.item_count * (Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count)))) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? value.discount_amount}}
														<td width="10%" style="text-align: right;">{{=((value.item_count * (Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count)))) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{?}}
													{{? !it.show_post_tax_price || !value.tax_value}}
													{{? !value.discount_amount}}
														<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? value.discount_amount}}
														<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{?}}
												</tr>
												{{? value.loose_item_name}}
													<tr>
														<td width="30%" style="font-size: 12px;text-align: left;">{{=value.loose_item_name.charAt(0).toUpperCase()+value.loose_item_name.substr(1)}} </td>
														<td></td>
														<td></td>	                                    
													</tr>
												{{?}}
												{{? value.attributes.length > 0}}
													{{~ value.attributes :attr:indx}}
															<tr>
																<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
																<td></td>
																<td></td>
																{{? (attr.unit_price * attr.count) != 0}}
																<td width="70%" style="font-size: 12px;text-align: right !important;">{{= (attr.unit_price * attr.count).toFixed(it.decimalCount)}}</td>
																{{?}}
															</tr>
													{{~}}
												{{?}}


												{{? value.discount_amount}}
													<tr>
														{{? value.discountUnits == "%"}}
														<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount @ {{=value.discount}}%</td>
														{{?}}
														{{? value.discountUnits != "%"}}
														<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount </td>
														{{?}}
														<td></td>
														<td></td>
														<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (value.discount_amount).toFixed(it.decimalCount)}}</td>
													</tr>
												{{?}}


											{{~ value.other_discounts: attr}}
												<tr>
													{{? attr.discount_type == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} @ {{=attr.discount}}%</td>
													{{?}}
													{{? attr.discount_type != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} </td>
													{{?}}
													<td></td>
													<td></td>
													<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (attr.amount).toFixed(it.decimalCount)}}</td>
												</tr>
											{{~}}


											{{? value.note}}
												<tr>
												<td width = "100%" style="font-size: 12px;text-align: left;">&nbsp;--{{=value.note}}</td>
												</tr>
											{{?}}

											</table>
											<!-- info table -->
											{{~}}
											{{? j!= (section.taxCodeWiseItems.length - 1)}}
												<table style="width: 100%; text-align: left;table-layout:fixed;">
													<tr>
														<td colspan="5" style="text-align: center;">---------------</td>
													</tr>
												</table>
											{{?}}
										{{~}}
										{{?}}

									<table style="width: 100%;">
										<tbody>
										<tr>
											<td colspan="3" height="5"></td>
											<!-- margin -->
										</tr>
										<tr>
											<td style="border-bottom: 1px dashed #000;"></td>
										</tr>
										<tr>
											<td colspan="3" height="5"></td>
											<!-- margin -->
										</tr>
										</tbody>
									</table>
									<!-- dotted hr-line -->

									<!-- Section subtotal begins-->
									<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left; font-weight: 600;">{{=section.sectionName.toUpperCase()}} Subtotal</td>
															<td colspan="2" style="text-align: right; font-weight: 600;">{{=section.subTotal.toFixed(it.decimalCount)}}</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
									</table>
									<!-- Section subtotal end-->

									{{? index != (it.cartSections.length - 1)}}
										<table style="width: 100%;">
											<tbody>
												<tr>
													<td colspan="3" height="5"></td>
													<!-- margin -->
												</tr>
												<tr>
													<td style="border-bottom: 1px dashed #000;"></td>
												</tr>
												<tr>
													<td colspan="3" height="5"></td>
													<!-- margin -->
												</tr>
											</tbody>
										</table>
										<!-- dotted hr-line -->
									{{?}}
									{{~}} {{?}}

									<!-- Cart items list end -->


									<table style="width: 100%;">
									<tbody>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
										<tr>
										<td style="border-bottom: 1px solid #000;"></td>
										</tr>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
									</tbody>
									</table><!-- solid hr-line -->

									<!-- Subtotal begin-->
									<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left;">
															Subtotal
															{{? it.localize_titles && it.localize_titles['sub total']}}<div style="text-align:right;">{{=it.localize_titles['sub total']}}</div> {{?}}
															</td>
															{{? it.item_wise_tax && it.show_post_tax_price }}
															<td colspan="2" style="text-align: right;">{{= (it.cart_value).toFixed(it.decimalCount)}}</td> <!--  + it.cart_tax-->
															{{?}}
															{{? !it.item_wise_tax || !it.show_post_tax_price}}
															<td colspan="2" style="text-align: right;">{{=it.cart_value.toFixed(it.decimalCount)}}</td>
															{{?}}
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
									</table>
									<!-- Subtotal end -->	                    

									<!-- Tax breakup begin-->
									{{? it.group_taxes && it.group_taxes.length > 0}}
									{{~ it.group_taxes :value:idx}}
									<table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td colspan="5">
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td colspan="3" style="text-align: left;"> 
																	{{? value.item_count}}
																		{{=value.item_count}} Item{{? value.item_count != 1 && value.item_count != -1 }}s{{?}} with
																	{{?}}
																	{{=value.breakup_name}}@{{=value.rate}} % 
																</td>
																<td colspan="2" style="text-align: right;"> {{=value.value.toFixed(it.decimalCount)}}</td>
															</tr>		                                          
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									{{~}}
									{{?}}
									<!-- Tax breakup end-->

									<!-- Total begin -->
									<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left; font-size: 18px; font-weight: 600;">
															TOTAL
															{{? it.localize_titles && it.localize_titles['total']}}<div style="text-align:right;">{{=it.localize_titles['total']}}</div> {{?}}
															</td>
															<td colspan="2" style="text-align: right; font-size: 18px; font-weight: 600">{{=it.currencySymbol}} {{=it.totalPrice.toFixed(it.decimalCount)}}</td>
														</tr>
														{{? !it.isTwoLanguageShown}}
														<tr style="text-align: left; font-size: 12px;">
															<td>*The total amount includes the following:</td>
														</tr>
														{{? }}
														<!-- Add updated diary balance directly without modifying printObj -->
														{{? it.print_config.diary_balance}}
															${diary_balance_available ? `
																<tr>
																	<td colspan="3" style="text-align: left; font-size: 18px; font-weight: 400;">
																		Diary Balance:
																	</td>
																	<td colspan="2" style="text-align: right; font-size: 18px; font-weight: 400">
																	Rs. ${current_diary_balance}
																	</td>
																</tr>
															` : ''}
														{{? }}
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
									</table>
									<!-- Total end-->

									<!-- Split payment list begin i.e CASH/CARD breakup-->
										{{? it.splitPaymentList && it.splitPaymentList.length > 0}} {{~ it.splitPaymentList :value:ind}}
										<table style="width: 100%; font-size: 14px;">
											<tbody>
												<tr>
													<td colspan="5">
														<table style="width: 100%;">
															<tbody>
																{{? value.name }}
																	<tr>
																		<td colspan="3" style="text-align: left;">{{=value.name.toUpperCase()}} </td>
																		<td colspan="2" style="text-align: right;">{{=value.amount.toFixed(it.decimalCount)}}</td>
																	</tr>
																{{?}}
																{{? !value.name}}
																<tr>
																	<td colspan="3" style="text-align: left;">{{=value.sub_class_name.toUpperCase()}} </td>
																	<td colspan="2" style="text-align: right;">{{=value.amount.toFixed(it.decimalCount)}}</td>
																</tr>
																{{?}}
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
										{{~}}{{?}}
									<!-- Split payment list end-->

								<!-- Overall Discount begins -->
								{{? it.discount && it.discountUnit}}
								<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														{{? it.discountUnit == '%'}}
															<tr>
																<td colspan="3" style="text-align: left;">
																DISCOUNT @{{=it.discount}} % 
																{{? it.localize_titles && it.localize_titles['discount']}}<div style="text-align:right;">{{=it.localize_titles['discount']}}</div> {{?}}
																</td>
																<td colspan="2" style="text-align: right;"> {{=it.discount_amount.toFixed(it.decimalCount)}}</td>
															</tr>
														{{?}}
														{{? it.discountUnit != '%'}}
															<tr>
																<td colspan="3" style="text-align: left;">
																DISCOUNT
																{{? it.localize_titles && it.localize_titles['discount']}}<div style="text-align:right;">{{=it.localize_titles['discount']}}</div> {{?}}
																</td>
																<td colspan="2" style="text-align: right;"> {{=it.discount_amount.toFixed(it.decimalCount)}}</td>
															</tr>
														{{?}}
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
								{{?}}
								<!-- Overall Discount ends -->


								<!-- Other discounts on Sub Total begins-->
								{{~ it.on_subtotal_other_discounts :value}}
									<table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td colspan="5">
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td colspan="3" style="text-align: left;">{{=value.name}}</td>
																<td colspan="2" style="text-align: right;"> {{=value.value.toFixed(it.decimalCount)}}</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								{{~}}
								<!-- Other discounts on Sub Total ends-->

								<!-- Extra charges begin-->
								{{? it.extraCharges && it.extraCharges.length > 0}}
								{{~ it.extraCharges :value:ind}}
									{{? value.value > 0}}
									<table style="width: 100%; font-size: 14px;">
									<tbody>
											<tr>
												<td colspan="5">
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td colspan="3" style="text-align: left;">{{=value.charge_name.toUpperCase()}} </td>
																<td colspan="2" style="text-align: right;"> {{=value.value.toFixed(it.decimalCount)}}</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									{{?}}
								{{~}}
								{{?}}
								<!-- Extra charges end-->		                

								<!-- Cash Discount begins -->
								{{? it.cash_discount && it.cashDiscountUnit}}
									<table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td colspan="5">
													<table style="width: 100%;">
														<tbody>
															{{? it.cashDiscountUnit == '%'}}
															<tr>
																<td colspan="3" style="text-align: left;">
																CASH DISCOUNT @{{=it.cash_discount}} % 
																{{? it.localize_titles && it.localize_titles['cash discount']}}<div style="text-align:right;">{{=it.localize_titles['cash discount']}}</div> {{?}}
																</td>
																<td colspan="2" style="text-align: right;"> {{=it.cash_discount_amount.toFixed(it.decimalCount)}}</td>
															</tr>
															{{?}}
															{{? it.cashDiscountUnit != '%'}}
															<tr>
																<td colspan="3" style="text-align: left;">
																	CASH DISCOUNT
																	{{? it.localize_titles && it.localize_titles['cash discount']}}<div style="text-align:right;">{{=it.localize_titles['cash discount']}}</div> {{?}}
																</td>
																<td colspan="2" style="text-align: right;"> {{=it.cash_discount_amount.toFixed(it.decimalCount)}}</td>
															</tr>
															{{?}}
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								{{?}}
								<!-- Cash Discount ends -->

								<!-- Roundoff value begin-->
								{{? it.rounding_value && it.rounding_value != 0}}
								<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left;">
															Round off 
															{{? it.localize_titles && it.localize_titles['round off']}}<div style="text-align:right;">{{=it.localize_titles['round off']}}</div> {{?}}
															</td>
															<!-- {{? it.rounding_value > 0}}
																<td colspan="2" style="text-align: right;"> +  {{=it.rounding_value.toFixed(it.decimalCount)}}</td>
															{{?? it.rounding_value < 0}}
																<td colspan="2" style="text-align: right;"> -  {{=it.rounding_value.toFixed(it.decimalCount)}}</td>
															{{??}}
															{{?}} -->
															<td colspan="2" style="text-align: right;"> {{=it.rounding_value.toFixed(it.decimalCount)}}</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
								{{?}}
								<!-- Roundoff value end-->

								<!-- Credit note amount begin-->
								{{? it.totalPrice && it.totalPrice < 0}}
								<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left;">
															Credit note amount
															{{? it.localize_titles && it.localize_titles['credit note amount']}}<div style="text-align:right;">{{=it.localize_titles['credit note amount']}}</div> {{?}}
															</td>		                                            
															<td colspan="2" style="text-align: right;"> {{=it.totalPrice.toFixed(it.decimalCount)}}</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
								{{?}}
								<!-- Credit note amount end-->

								<table style="width: 100%; font-size: 14px;">
								<tbody>
								<tr>
									<td style='text-align: right;'>
									<table style="width: 100%;">
										<tbody>
										<tr>
											<td style="border-bottom: 1px solid #000;"></td>
										</tr>
										</tbody>
									</table><!-- solid hr-line -->
									</td>
								</tr>
								</tbody>
							</table><!-- check table -->
							
							<!-- Order note begins -->
							{{? it.note }}
								<table style="width: 100%; font-size: 14px;">
								<tbody>
									<tr>
									<td style='text-align: center;'>
										<table style="width: 100%;">
										<tbody>
											<tr>
											<td style="text-align: center; font-weight: 600;">{{=it.note}}</td>
											</tr>
										</tbody>
										</table>
									</td>
									</tr>
								</tbody>
								</table>
							{{?}}
							<!-- Order note ends -->

							<!-- Discount begins -->
							{{? it.total_discount > 0}}
								<table style="width: 100%; font-size: 14px;">
								<tbody>
									<tr>
									<td style='text-align: center;'>
										<table style="width: 100%;">
										<tbody>
											<tr>
											<td style="text-align: center; font-weight: 600;">You saved  {{=it.total_discount.toFixed(it.decimalCount)}}</td>
											</tr>
										</tbody>
										</table>
									</td>
									</tr>
								</tbody>
								</table>
							{{?}}
							<!-- Discount ends -->

								<!-- GST taxes information begins -->
								{{?it.show_gst_summary_on_print && it.taxesByTaxCode && it.taxesByTaxCode.length>0}}
									<table style="width: 100%;>
										<tbody>
											<tr>
												<td> 
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td><b>GST Receipt Summary</b></td>
															</tr>
															<tr>
																<td style="border-bottom: 1px solid #000;"></td>
															</tr>
														</tbody>
													</table><!-- solid hr-line -->
												</td>
											</tr>		              				
										</tbody>
									</table>	
									
									<table style="text-align: center; width: 100%">
										<tbody>
											<tr>
												<th> 
													HSN/SAC Code
												</th>
												<th>
													GST %
												</th>
												<th>
													SGST Amt
												</th>
												<th>
													CGST Amt
												</th>
												<th>
													IGST Amt
												</th>
											</tr>
										</tbody>
										<tbody>
											{{~ it.taxesByTaxCode :tax:indtx}}
												<tr>
													<td> 
														{{=tax.code}}
													</td>
													<td>
														{{=tax.rate}}
													</td>
													<td>
														{{? tax.tax_breakup.SGST}}
															{{=tax.tax_breakup.SGST.toFixed(it.decimalCount)}}
														{{?}}
														{{? !tax.tax_breakup.SGST}}
															0
														{{?}}
													</td>
													<td>
														{{? tax.tax_breakup.CGST}}
															{{=tax.tax_breakup.CGST.toFixed(it.decimalCount)}}
														{{?}}
														{{? !tax.tax_breakup.CGST}}
															0
														{{?}}
													</td>
													<td>
														{{? tax.tax_breakup.IGST}}
															{{=tax.tax_breakup.IGST.toFixed(it.decimalCount)}}
														{{?}}
														{{? !tax.tax_breakup.IGST}}
															0
														{{?}}
													</td>
												</tr>
											{{~}}
										</tbody>
									</table>

									<table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td style='text-align: right;'>
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td style="border-bottom: 1px solid #000;"></td>
															</tr>
														</tbody>
													</table><!-- solid hr-line -->
												</td>
											</tr>
										</tbody>
									</table><!-- check table -->
								{{?}}
								<!-- GST taxes information ends -->

								<table style="width: 100%;>
								<tbody>
									<tr>
									<td colspan="3" height="20"></td><!-- margin -->
									</tr>
									{{? it.discountReason && it.discountReason != ""}}
									<tr>
										<td colspan="5" style="text-align: center; font-size: 12px;">Discount Reason: {{=it.discountReason}}</td>
									</tr>
									{{?}}
									{{? it.client_reference_code && it.terminal && it.bill_ref_id && it.print_config.terminal_details}}
										<tr>
										<td colspan="5" style="text-align: center; font-size: 12px;">			                      	
											Terminal: {{=it.terminal}} 
											{{? it.localize_titles && it.localize_titles['terminal']}}:{{=it.localize_titles['terminal']}}{{?}}
											&nbsp;&nbsp; 
											Ref ID: {{=it.bill_ref_id}}
											{{? it.localize_titles && it.localize_titles['ref id']}}:{{=it.localize_titles['ref id']}}{{?}}
										</td>
										</tr>
									{{?}}
									{{? it.print_config.footer}}
										{{? !it.print_footer_note_enabled || !it.print_footer_note }}
											<tr>
												<td style='text-align: center; font-size: 12px;'>Thank you for your business!</td>
											</tr>
											<tr>
												<td style='text-align: center; font-size: 12px;'>With love from OneGreenDiary..</td>
											</tr>
										{{?}}
										{{? it.print_footer_note_enabled && it.print_footer_note }}
											<tr>
												<td style='text-align: center; font-size: 12px;'>{{=it.print_footer_note}}</td>
											</tr>                      
										{{?}}
									{{?}}
									<tr>
									<td colspan="3" height="10"></td><!-- margin -->
									</tr>
								</tbody>
								</table><!-- thanks container -->

							</td>
							</tr>

							</tbody>
							</table><!-- Email Wrapper -->

						</td>
						</tr>
					</tbody>
					</table><!-- Generall Wrapper -->
				`;
			}else{
				str2 = `
					<table style="text-align: center; width: 100%; font-family: sans-serif;">
					<tbody>
						<tr>
						<td>

							<table style="text-align: left; width: 100%; max-width: 700px; border: 0px solid #ccc; margin: auto;">
							<tbody>
								<tr>
								<td max-width: 700px;>

								<!-- Shop details begin -->
									<table style="text-align: center; width: 100%">
									<tbody>
										{{? it.print_logo_on_bill && it.imgSrc}}
										<tr>
											<td>
												<img src ={{=it.imgSrc}}>
											<td>
										</tr>
										{{?}}
										{{? !it.print_logo_on_bill}}
										<tr>
											<td style="font-size: 20px; font-weight: 600;">{{=it.shop_name}}</td>
										</tr>
										{{?}}
										{{? it.shop_address && it.shop_address != "NA"}}
											<tr>
											<td>
											{{=it.shop_address}}                          
											</td>
											</tr>
										{{?}}
										{{? it.shop_zip_code && it.shop_zip_code != "NA"}}
											<tr>
											<td>
												Pin Code:
												{{=it.shop_zip_code}}
												{{? it.localize_titles && it.localize_titles['pin code']}}:{{=it.localize_titles['pin code']}} {{?}}
											</td>
											</tr>
										{{?}}
									</tbody>
									</table>
									<!-- Shop details end -->
									<!-- Header Container -->

									<!-- Merchant details begin -->
									<table style="text-align: center; width: 100%; font-size: 14px;">
									<tbody>
										<tr>
										{{? it.shop_phone_number && it.shop_phone_number != "NA"}}
										<td style="text-align: center;">
											PH:
											{{=it.shop_phone_number}}
											{{? it.localize_titles && it.localize_titles['phone number']}}:{{=it.localize_titles['phone number']}} {{?}}
										</td>		                         
										{{?}}
										</tr>
										{{? it.gstin}}
										<tr>
											<td style="text-align: center;">
												Outlet GST No: {{=it.gstin}}
											</td>
										</tr>
										{{?}}

										{{? it.print_extra_field && it.extra_field_name && it.extra_field_value}}
										<tr>
											<td style="text-align: center;">
												{{=it.extra_field_name}}: {{=it.extra_field_value}}
											</td>
										</tr>
										{{?}}	

										<tr>
										{{? it.staff_name && it.staff_name != "NA" && it.print_staff_name}}
										<td style="text-align: center;">
										Staff:
										{{=it.staff_name}}
										
										</td>		                         
										{{?}}
									</tr>
									</tbody>
									</table>
									<!-- Merchant details end -->
									<!-- Address Container -->

									<table style="width: 100%;">
									<tbody>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
										<tr>
										<td style="border-bottom: 1px dashed #000;"></td>
										</tr>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
									</tbody>
									</table><!-- dotted hr-line -->

									<!-- Order details begin -->
									<table style="text-align: center; width: 100%">
									<tbody>
										<tr>
										<td colspan = "5" style="text-align: center; font-size: 20px; font-weight: 600;">
											{{=it.print_header}}
											{{? it.localize_titles && it.localize_titles['receipt']}} <div style="text-align:center;"> {{=it.localize_titles['receipt']}} </div>{{?}}
										</td>
										</tr>
										<tr>
										<td colspan="5" style="text-align: center;">
											{{? it.deliveryDateTime}} Transaction Date: {{?}} {{=it.dateTime}}
											{{? it.deliveryDateTime && it.localize_titles && it.localize_titles['transaction date']}}:{{=it.localize_titles['transaction date']}} {{?}}
										</td>
										</tr>
										{{? it.deliveryDateTime}}
										<tr>
										<td colspan="5" style="text-align: center;">
											Delivery Date: {{=it.deliveryDateTime}}
											{{? it.localize_titles && it.localize_titles['delivery date']}}:{{=it.localize_titles['delivery date']}} {{?}}
										</td>
										</tr>
										{{?}}		                        
										<tr>
											{{? it.bill_no && it.bill_no != "NA" && it.user_name}}
											<td colspan="5" style="text-align: center;">Bill No: {{=it.bill_no}} &nbsp; &nbsp;User: {{=it.user_name}}</td>
											{{?}}
											{{? it.bill_no && it.bill_no != "NA" && !it.user_name}}
											<td colspan="5" style="text-align: center;">Bill No: {{=it.bill_no}}</td>
											{{?}}
											{{? (!it.bill_no || it.bill_no == "NA")  && it.user_name}}
												<td colspan="5" style="text-align: center;">
													User: {{=it.user_name}} 
													{{? it.localize_titles && it.localize_titles['user name']}}:{{=it.localize_titles['user name']}} {{?}}
												</td>
											{{?}}
											{{? it.checkPrintNumber }}
												
											{{?}}
										</tr>
										{{? it.checkPrintNumber}}
										<tr>
											<td colspan="5" style="text-align: center;">
												Check Print Number: {{=it.checkPrintNumber}}
											</td>
										</tr>
										{{?}}	
									</tbody>
									</table>
									<!-- Order details end -->

									<!-- Customer information begins -->
									{{?it.customer_name && it.customer_mobile_no != "0000000000"}}
										<table style="width: 100%;">
										<tbody>
											<tr>
											<td colspan="3" height="5"></td><!-- margin -->
											</tr>
											<tr>
											<td style="border-bottom: 1px dashed #000;"></td>
											</tr>
											<tr>
											<td colspan="3" height="5"></td><!-- margin -->
											</tr>
										</tbody>
										</table><!-- dotted hr-line -->
										<table style="text-align: center; width: 100%">
											<tbody>
											<tr>
												<td style="font-weight: 600;">
												CUSTOMER INFORMATION
												{{? it.localize_titles && it.localize_titles['customer information']}}<div style="text-align:right;">{{=it.localize_titles['customer information']}}</div> {{?}}
												</td>
											</tr>
											{{? it.customer_name}}
												<tr>
												<td>
													<b>Name:</b> 
													{{=it.customer_name}}
													{{? it.localize_titles && it.localize_titles['name']}}<b>:{{=it.localize_titles['name']}}</b>{{?}}
												</td>
												</tr>
											{{?}}
					
											{{? it.customer_address_1}}
												<tr>
												<td>
													<b>Address:</b> 
													{{=it.customer_address_1}}
													{{? it.localize_titles && it.localize_titles['address']}}<b>:{{=it.localize_titles['address']}}</b>{{?}}
												</td>
												</tr>
											{{?}}
											{{? it.customer_mobile_no && it.customer_mobile_no != "NA"}}
												<tr>
												<td>
													<b>PH:</b> 
													{{=it.customer_mobile_no}}
													{{? it.localize_titles && it.localize_titles['phone number']}}<b>:{{=it.localize_titles['phone number']}}</b>{{?}}
												</td>
												</tr>
											{{?}}
											{{? it.customer_gstin && it.customer_gstin != "NA" }}
												<tr>
													<td style="text-align: center;">
														<b>Customer GSTIN:</b> {{=it.customer_gstin}}
													</td>
												</tr>
											{{?}}

											</tbody>
										</table>
										<table style="width: 100%;">
										<tbody>
											<tr>
											<td colspan="3" height="5"></td><!-- margin -->
											</tr>
											<tr>
											<td style="border-bottom: 1px dashed #000;"></td>
											</tr>
											<tr>
											<td colspan="3" height="5"></td><!-- margin -->
											</tr>
										</tbody>
										</table><!-- dotted hr-line -->
									{{?}}
									<!-- Customer information ends -->

									{{? it.enable_tables}}
										<table style="width: 100%;">
											<tbody>
												<tr>
													<td colspan="2" style="text-align: left;">
													Section: {{=it.section}}
													{{? it.localize_titles && it.localize_titles['section']}}:{{=it.localize_titles['section']}} {{?}}
													</td>
													<td colspan="1">
													{{? it.seat_no}}
														Seat No: {{=it.seat_no}}
														{{? it.localize_titles && it.localize_titles['seat no']}}:{{=it.localize_titles['seat no']}} {{?}}
													{{?}}
													</td>
													<td colspan="1" style="text-align: right;">
													Table No: {{=it.table_no}}
													{{? it.localize_titles && it.localize_titles['table no']}}:{{=it.localize_titles['table no']}} {{?}}
													</td>
												</tr>
											</tbody>
										</table>
									{{?}}

									<!-- Receipt Container -->

									<table style="width: 100%;">
									<tbody>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
										<tr>
										<td style="border-bottom: 1px dashed #000;"></td>
										</tr>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
									</tbody>
									</table><!-- dotted hr-line -->


									<table style="width: 100%;">
									<tbody>
										<tr>
										<td colspan="2" style="text-align: left;" height="5">
											Particulars
											{{? it.localize_titles && it.localize_titles['particulars']}}<div style="text-align:right">{{=it.localize_titles['particulars']}}</div> {{?}}
										</td>
										<td colspan="1" style="text-align: right;" height="5">
											Rate
											{{? it.localize_titles && it.localize_titles['rate']}}<div style="text-align:right">{{=it.localize_titles['rate']}}</div> {{?}}
										</td>
										<td colspan="1" style="text-align: center;" height="5">
											Qty
											{{? it.localize_titles && it.localize_titles['qty']}}<div style="text-align:right">{{=it.localize_titles['qty']}}</div> {{?}}
										</td>
										<td colspan="2" style="text-align: right;" height="5">
											Amt
											{{? it.localize_titles && it.localize_titles['amount']}}<div style="text-align:right">{{=it.localize_titles['amount']}}</div> {{?}}
										</td>
										</tr>
									</tbody>
									</table>

									<table style="width: 100%;">
									<tbody>
									<tr>
										<td colspan="3" height="5"></td><!-- margin -->
									</tr>
									<tr>
										<td style="border-bottom: 1px dashed #000;"></td>
									</tr>
									<tr>
										<td colspan="3" height="5"></td><!-- margin -->
									</tr>
									</tbody>
								</table><!-- dotted hr-line -->



									<!-- Cart items list begin -->
									{{? !it.print_sectionwise}}

									{{? !it.item_wise_tax}}
									{{~it.cart_items :value:index}}
									<table style="width: 100%; text-align: left;table-layout:fixed;">
										<tr>
											<td width="30%" style="text-align: left;  word-wrap: break-word;">
											{{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}
											{{? value.localize_item_name }}<div style="text-align:right">{{=value.localize_item_name}}</div>{{?}}
											</td>
											<td width="10%" style="text-align: right;">{{=parseFloat(value.unit_price).toFixed(it.decimalCount)}}</td>
											<td width="10%" style="text-align: center;">{{=value.item_count}}</td>
											{{? !value.discount_amount}}
											<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
											{{?}}
											{{? value.discount_amount}}
											<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
											{{?}}
										</tr>

										{{? value.loose_item_name}}
											<tr>
												<td width="30%" style="font-size: 12px;text-align: left;">{{=value.loose_item_name.charAt(0).toUpperCase()+value.loose_item_name.substr(1)}} </td>
												<td></td>
												<td></td>	                                    
											</tr>
										{{?}}

										{{? value.attributes.length > 0}}
											{{~ value.attributes :attr:indx}}
												<tr>
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
													<td></td>
													<td></td>
													{{? (attr.unit_price * attr.count) != 0}}
													<td width="70%" style="font-size: 12px;text-align: right !important;">{{= (attr.unit_price * attr.count).toFixed(it.decimalCount)}}</td>
													{{?}}
												</tr>
											{{~}}
										{{?}}
										{{? value.combo_item_codes && value.combo_item_codes.length > 0}}
										{{~ value.combo_item_codes :attr:indx}}
										<tr>
											<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.quantity_in_combo}} x {{=attr.item_name.charAt(0).toUpperCase()+attr.item_name.substr(1)}} </td>
											<td></td>
											<td></td>
											<td></td>
											
										</tr>
									{{~}}
								{{?}}
								{{? value.included_combo_items && value.included_combo_items.length > 0}}
										{{~ value.included_combo_items :attr:indx}}
										<tr>
											<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.quantity}} x {{=attr.item_name.charAt(0).toUpperCase()+attr.item_name.substr(1)}} </td>
											<td></td>
											<td></td>
											<td></td>
											
										</tr>
									{{~}}
								{{?}}
										{{? value.discount_amount}}
										<tr>
											{{? value.discountUnits == "%"}}
											<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount @ {{= value.discount}}%</td>
											{{?}}
											{{? value.discountUnits != "%"}}
											<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount </td>
											{{?}}
											<td></td>
											<td></td>
											<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (value.discount_amount).toFixed(it.decimalCount)}}</td>
										</tr>
										{{?}}


										{{~ value.other_discounts: attr}}
										<tr>
											{{? attr.discount_type == "%"}}
												<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} @ {{=attr.discount}}%</td>
											{{?}}
											{{? attr.discount_type != "%"}}
												<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} </td>
											{{?}}
											<td></td>
											<td></td>
											<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (attr.amount).toFixed(it.decimalCount)}}</td>
										</tr>
									{{~}}

									{{? value.special_order_charges}}
											<tr>                            
												<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Sp. order charge </td>                            
												<td></td>
												<td></td>
												<td width="70%" style="font-size: 12px;text-align: right !important;">+{{= (value.special_order_charges).toFixed(it.decimalCount)}}</td>
											</tr>
										{{?}}


										{{? value.note}}
										<tr>
											<td width = "100%" style="font-size: 12px;text-align: left;">&nbsp;--{{=value.note}}</td>
										</tr>
										{{?}}
									</table>
									<!-- info table -->
									{{~}}



									<!--open else -->
									{{?? it.item_wise_tax}}
										{{~ it.taxCodeWiseItems :taxType:i}}
											<table style="width: 100%; text-align: left;table-layout:fixed;">
												<tr>
													{{? taxType.code != "no_tax"}}
														<td colspan="2" style="text-align: left;">{{=taxType.code}} @ {{=taxType.rate}}% </td>
													{{?}}
												</tr>
											</table>
										{{~ taxType.items :value:index}}
										<table style="width: 100%; text-align: left;table-layout:fixed;">
											<tr>
												<td width="30%" style="text-align: left;  word-wrap: break-word;">
													{{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}
													{{? value.localize_item_name }}<div style="text-align:right">{{=value.localize_item_name}}</div>{{?}}
												</td>
												{{? it.show_post_tax_price && value.tax_value}}
													<td width="10%" style="text-align: right;">{{=(Number(value.unit_price)).toFixed(it.decimalCount)}}</td>
												{{?}}
												{{? !it.show_post_tax_price || !value.tax_value}}
													<td width="10%" style="text-align: right;">{{=parseFloat(value.unit_price).toFixed(it.decimalCount)}}</td>
												{{?}}
												<td width="10%" style="text-align: center;">{{=value.item_count}}</td>
												{{? it.show_post_tax_price && value.tax_value}}
													{{? !value.discount_amount}}
													<td width="10%" style="text-align: right;">{{=((value.item_count * (Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count)))) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? value.discount_amount}}
													<td width="10%" style="text-align: right;">{{=((value.item_count * (Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count)))) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
													{{?}}
												{{?}}
												{{? !it.show_post_tax_price || !value.tax_value}}
													{{? !value.discount_amount}}
													<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? value.discount_amount}}
													<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
													{{?}}
												{{?}}
											</tr>
											{{? value.loose_item_name}}
													<tr>
														<td width="30%" style="font-size: 12px;text-align: left;">{{=value.loose_item_name.charAt(0).toUpperCase()+value.loose_item_name.substr(1)}} </td>
														<td></td>
														<td></td>	                                    
													</tr>
											{{?}}
											{{? value.attributes.length > 0}}
												{{~ value.attributes :attr:indx}}
														<tr>
															<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
															<td></td>
															<td></td>
															{{? (attr.unit_price * attr.count) != 0}}
																<td width="70%" style="font-size: 12px;text-align: right !important;">{{= (attr.unit_price * attr.count).toFixed(it.decimalCount)}}</td>
															{{?}}
														</tr>
												{{~}}
											{{?}}



											{{? value.discount_amount}}
												<tr>
													{{? value.discountUnits == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount @ {{=value.discount}}%</td>
													{{?}}
													{{? value.discountUnits != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount </td>
													{{?}}
													<td></td>
													<td></td>
													<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (value.discount_amount).toFixed(it.decimalCount)}}</td>
												</tr>
											{{?}}


											{{~ value.other_discounts: attr}}
												<tr>
													{{? attr.discount_type == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} @ {{=attr.discount}}%</td>
													{{?}}
													{{? attr.discount_type != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} </td>
													{{?}}
													<td></td>
													<td></td>
													<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (attr.amount).toFixed(it.decimalCount)}}</td>
												</tr>
											{{~}}

											{{? value.special_order_charges}}
												<tr>                            
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Sp. order charge </td>                            
													<td></td>
													<td></td>
													<td width="70%" style="font-size: 12px;text-align: right !important;">+{{= (value.special_order_charges).toFixed(it.decimalCount)}}</td>
												</tr>
											{{?}}


											{{? value.note}}
												<tr>
												<td width = "100%" style="font-size: 12px;text-align: left;">&nbsp;--{{=value.note}}</td>
												</tr>
											{{?}}

										</table>
										<!-- info table -->
										{{~}}
										{{? i!= (it.taxCodeWiseItems.length - 1)}}
											<table style="width: 100%; text-align: left;table-layout:fixed;">
												<tr>
													<td colspan="5" style="text-align: center;">---------------</td>
												</tr>
											</table>
										{{?}}
										{{~}}
									{{?}}<!--close else -->
									{{?}} <!--close !sectionwise_receipt if-->

									{{? it.print_sectionwise}}
									{{~ it.cartSections :section:index}}
									<!-- <table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td style='text-align: center;'>
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td style="text-align: center; font-weight: 600;">{{=section.sectionName.toUpperCase()}} ITEMS</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table> -->
									<!-- check table -->


									{{? !it.item_wise_tax}} <!-- i.e. no need to check show_post_tax_price -->
										{{~ section.items :value}}
										<table style="width: 100%; text-align: left;table-layout:fixed;">
											<tr>
												<td width="30%" style="text-align: left;  word-wrap: break-word;">
												{{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}
												{{? value.localize_item_name }}<div style="text-align:right">{{=value.localize_item_name}}</div>{{?}}
												</td>
												<td width="10%" style="text-align: right;">{{=parseFloat(value.unit_price).toFixed(it.decimalCount)}}</td>
												<td width="10%" style="text-align: center;">{{=value.item_count}}</td>
												{{? !value.discount_amount}}
												<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
												{{?}}
												{{? value.discount_amount}}
												<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
												{{?}}
											</tr>
											{{? value.loose_item_name}}
												<tr>
													<td width="30%" style="font-size: 12px;text-align: left;">{{=value.loose_item_name.charAt(0).toUpperCase()+value.loose_item_name.substr(1)}} </td>
													<td></td>
													<td></td>	                                    
												</tr>
											{{?}}
											{{? value.attributes && value.attributes.length > 0}}
												{{~ value.attributes :attr:ind}}
														<tr>
															<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
															<td></td>
															<td></td>
															{{? (attr.unit_price * attr.count) != 0}}
															<td width="70%" style="font-size: 12px;text-align: right !important;">{{= (attr.unit_price * attr.count).toFixed(it.decimalCount)}}</td>
															{{?}}
														</tr>
												{{~}}
											{{?}}


											{{? value.discount_amount}}
											<tr>
												{{? value.discountUnits == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount @ {{=value.discount}}%</td>
												{{?}}
												{{? value.discountUnits != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount </td>
												{{?}}
												<td></td>
												<td></td>
												<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (value.discount_amount).toFixed(it.decimalCount)}}</td>
											</tr>
											{{?}}


											{{~ value.other_discounts: attr}}
											<tr>
												{{? attr.discount_type == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} @ {{=attr.discount}}%</td>
												{{?}}
												{{? attr.discount_type != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} </td>
												{{?}}
												<td></td>
												<td></td>
												<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (attr.amount).toFixed(it.decimalCount)}}</td>
											</tr>
											{{~}}


											{{? value.note}}
											<tr>
												<td width = "100%" style="font-size: 12px;text-align: left;">&nbsp;--{{=value.note}}</td>
											</tr>
											{{?}}
										</table>
										<!-- info table -->
										{{~}}
									{{?}}



										{{? it.item_wise_tax}}
										{{~ section.taxCodeWiseItems :taxType:j}}
											<table style="width: 100%; text-align: left;table-layout:fixed;">
												<tr>
													{{? taxType.code != "no_tax"}}
														<td colspan="2" style="text-align: left;">{{=taxType.code}} @ {{=taxType.rate}}% </td>
													{{?}}
												</tr>
											</table>
											{{~ taxType.items :value}}
											<table style="width: 100%; text-align: left;table-layout:fixed;">
												<tr>
													<td width="30%" style="text-align: left;  word-wrap: break-word;">
													{{=value.item_name.charAt(0).toUpperCase()+value.item_name.substr(1)}}
													{{? value.localize_item_name }}<div style="text-align:right">{{=value.localize_item_name}}</div>{{?}}
													</td>
													{{? it.show_post_tax_price && value.tax_value}}
													<td width="10%" style="text-align: right;">{{=(Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count))).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? !it.show_post_tax_price || !value.tax_value}}
													<td width="10%" style="text-align: right;">{{=parseFloat(value.unit_price).toFixed(it.decimalCount)}}</td>
													{{?}}
													<td width="10%" style="text-align: center;">{{=value.item_count}}</td>
													{{? it.show_post_tax_price && value.tax_value}}
													{{? !value.discount_amount}}
														<td width="10%" style="text-align: right;">{{=((value.item_count * (Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count)))) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? value.discount_amount}}
														<td width="10%" style="text-align: right;">{{=((value.item_count * (Number(value.unit_price) + (Number(value.tax_value) / Number(value.item_count)))) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{?}}
													{{? !it.show_post_tax_price || !value.tax_value}}
													{{? !value.discount_amount}}
														<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{? value.discount_amount}}
														<td width="10%" style="text-align: right;">{{=((value.item_count * value.unit_price) - value.other_discounts_total - value.discount_amount).toFixed(it.decimalCount)}}</td>
													{{?}}
													{{?}}
												</tr>
												{{? value.loose_item_name}}
													<tr>
														<td width="30%" style="font-size: 12px;text-align: left;">{{=value.loose_item_name.charAt(0).toUpperCase()+value.loose_item_name.substr(1)}} </td>
														<td></td>
														<td></td>	                                    
													</tr>
												{{?}}
												{{? value.attributes.length > 0}}
													{{~ value.attributes :attr:indx}}
															<tr>
																<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.count}} x {{=attr.name.charAt(0).toUpperCase()+attr.name.substr(1)}} </td>
																<td></td>
																<td></td>
																{{? (attr.unit_price * attr.count) != 0}}
																<td width="70%" style="font-size: 12px;text-align: right !important;">{{= (attr.unit_price * attr.count).toFixed(it.decimalCount)}}</td>
																{{?}}
															</tr>
													{{~}}
												{{?}}


												{{? value.discount_amount}}
													<tr>
														{{? value.discountUnits == "%"}}
														<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount @ {{=value.discount}}%</td>
														{{?}}
														{{? value.discountUnits != "%"}}
														<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;Discount </td>
														{{?}}
														<td></td>
														<td></td>
														<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (value.discount_amount).toFixed(it.decimalCount)}}</td>
													</tr>
												{{?}}


											{{~ value.other_discounts: attr}}
												<tr>
													{{? attr.discount_type == "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} @ {{=attr.discount}}%</td>
													{{?}}
													{{? attr.discount_type != "%"}}
													<td width="30%" style="font-size: 12px;text-align: left;">&nbsp;{{=attr.discount_code}} </td>
													{{?}}
													<td></td>
													<td></td>
													<td width="70%" style="font-size: 12px;text-align: right !important;">-{{= (attr.amount).toFixed(it.decimalCount)}}</td>
												</tr>
											{{~}}


											{{? value.note}}
												<tr>
												<td width = "100%" style="font-size: 12px;text-align: left;">&nbsp;--{{=value.note}}</td>
												</tr>
											{{?}}

											</table>
											<!-- info table -->
											{{~}}
											{{? j!= (section.taxCodeWiseItems.length - 1)}}
												<table style="width: 100%; text-align: left;table-layout:fixed;">
													<tr>
														<td colspan="5" style="text-align: center;">---------------</td>
													</tr>
												</table>
											{{?}}
										{{~}}
										{{?}}

									<table style="width: 100%;">
										<tbody>
										<tr>
											<td colspan="3" height="5"></td>
											<!-- margin -->
										</tr>
										<tr>
											<td style="border-bottom: 1px dashed #000;"></td>
										</tr>
										<tr>
											<td colspan="3" height="5"></td>
											<!-- margin -->
										</tr>
										</tbody>
									</table>
									<!-- dotted hr-line -->

									<!-- Section subtotal begins-->
									<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left; font-weight: 600;">{{=section.sectionName.toUpperCase()}} Subtotal</td>
															<td colspan="2" style="text-align: right; font-weight: 600;">{{=section.subTotal.toFixed(it.decimalCount)}}</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
									</table>
									<!-- Section subtotal end-->

									{{? index != (it.cartSections.length - 1)}}
										<table style="width: 100%;">
											<tbody>
												<tr>
													<td colspan="3" height="5"></td>
													<!-- margin -->
												</tr>
												<tr>
													<td style="border-bottom: 1px dashed #000;"></td>
												</tr>
												<tr>
													<td colspan="3" height="5"></td>
													<!-- margin -->
												</tr>
											</tbody>
										</table>
										<!-- dotted hr-line -->
									{{?}}
									{{~}} {{?}}

									<!-- Cart items list end -->


									<table style="width: 100%;">
									<tbody>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
										<tr>
										<td style="border-bottom: 1px solid #000;"></td>
										</tr>
										<tr>
										<td colspan="3" height="5"></td><!-- margin -->
										</tr>
									</tbody>
									</table><!-- solid hr-line -->

									<!-- Subtotal begin-->
									<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left;">
															Subtotal
															{{? it.localize_titles && it.localize_titles['sub total']}}<div style="text-align:right;">{{=it.localize_titles['sub total']}}</div> {{?}}
															</td>
															{{? it.item_wise_tax && it.show_post_tax_price }}
															<td colspan="2" style="text-align: right;">{{= (it.cart_value).toFixed(it.decimalCount)}}</td> <!--  + it.cart_tax-->
															{{?}}
															{{? !it.item_wise_tax || !it.show_post_tax_price}}
															<td colspan="2" style="text-align: right;">{{=it.cart_value.toFixed(it.decimalCount)}}</td>
															{{?}}
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
									</table>
									<!-- Subtotal end -->	                    

									<!-- Tax breakup begin-->
									{{? it.group_taxes && it.group_taxes.length > 0}}
									{{~ it.group_taxes :value:idx}}
									<table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td colspan="5">
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td colspan="3" style="text-align: left;"> 
																	{{? value.item_count}}
																		{{=value.item_count}} Item{{? value.item_count != 1 && value.item_count != -1 }}s{{?}} with
																	{{?}}
																	{{=value.breakup_name}}@{{=value.rate}} % 
																</td>
																<td colspan="2" style="text-align: right;"> {{=value.value.toFixed(it.decimalCount)}}</td>
															</tr>		                                          
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									{{~}}
									{{?}}
									<!-- Tax breakup end-->

									<!-- Total begin -->
									<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left; font-size: 18px; font-weight: 600;">
															TOTAL
															{{? it.localize_titles && it.localize_titles['total']}}<div style="text-align:right;">{{=it.localize_titles['total']}}</div> {{?}}
															</td>
															<td colspan="2" style="text-align: right; font-size: 18px; font-weight: 600">{{=it.currencySymbol}} {{=it.totalPrice.toFixed(it.decimalCount)}}</td>
														</tr>
														{{? !it.isTwoLanguageShown}}
														<tr style="text-align: left; font-size: 12px;">
															<td>*The total amount includes the following:</td>
														</tr>
														{{? }}
														
														<!-- Add updated diary balance directly without modifying printObj -->
														${diary_balance_available ? `
															<tr>
																<td colspan="3" style="text-align: left; font-size: 18px; font-weight: 400;">
																	Diary Balance:
																</td>
																<td colspan="2" style="text-align: right; font-size: 18px; font-weight: 400">
																  Rs. ${current_diary_balance}
																</td>
															</tr>
														` : ''}
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
									</table>
									<!-- Total end-->

									<!-- Split payment list begin i.e CASH/CARD breakup-->
										{{? it.splitPaymentList && it.splitPaymentList.length > 0}} {{~ it.splitPaymentList :value:ind}}
										<table style="width: 100%; font-size: 14px;">
											<tbody>
												<tr>
													<td colspan="5">
														<table style="width: 100%;">
															<tbody>
																{{? value.name }}
																	<tr>
																		<td colspan="3" style="text-align: left;">{{=value.name.toUpperCase()}} </td>
																		<td colspan="2" style="text-align: right;">{{=value.amount.toFixed(it.decimalCount)}}</td>
																	</tr>
																{{?}}
																{{? !value.name}}
																<tr>
																	<td colspan="3" style="text-align: left;">{{=value.sub_class_name.toUpperCase()}} </td>
																	<td colspan="2" style="text-align: right;">{{=value.amount.toFixed(it.decimalCount)}}</td>
																</tr>
																{{?}}
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
										{{~}}{{?}}
									<!-- Split payment list end-->

								<!-- Overall Discount begins -->
								{{? it.discount && it.discountUnit}}
								<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														{{? it.discountUnit == '%'}}
															<tr>
																<td colspan="3" style="text-align: left;">
																DISCOUNT @{{=it.discount}} % 
																{{? it.localize_titles && it.localize_titles['discount']}}<div style="text-align:right;">{{=it.localize_titles['discount']}}</div> {{?}}
																</td>
																<td colspan="2" style="text-align: right;"> {{=it.discount_amount.toFixed(it.decimalCount)}}</td>
															</tr>
														{{?}}
														{{? it.discountUnit != '%'}}
															<tr>
																<td colspan="3" style="text-align: left;">
																DISCOUNT
																{{? it.localize_titles && it.localize_titles['discount']}}<div style="text-align:right;">{{=it.localize_titles['discount']}}</div> {{?}}
																</td>
																<td colspan="2" style="text-align: right;"> {{=it.discount_amount.toFixed(it.decimalCount)}}</td>
															</tr>
														{{?}}
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
								{{?}}
								<!-- Overall Discount ends -->


								<!-- Other discounts on Sub Total begins-->
								{{~ it.on_subtotal_other_discounts :value}}
									<table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td colspan="5">
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td colspan="3" style="text-align: left;">{{=value.name}}</td>
																<td colspan="2" style="text-align: right;"> {{=value.value.toFixed(it.decimalCount)}}</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								{{~}}
								<!-- Other discounts on Sub Total ends-->

								<!-- Extra charges begin-->
								{{? it.extraCharges && it.extraCharges.length > 0}}
								{{~ it.extraCharges :value:ind}}
									{{? value.value > 0}}
									<table style="width: 100%; font-size: 14px;">
									<tbody>
											<tr>
												<td colspan="5">
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td colspan="3" style="text-align: left;">{{=value.charge_name.toUpperCase()}} </td>
																<td colspan="2" style="text-align: right;"> {{=value.value.toFixed(it.decimalCount)}}</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									{{?}}
								{{~}}
								{{?}}
								<!-- Extra charges end-->		                

								<!-- Cash Discount begins -->
								{{? it.cash_discount && it.cashDiscountUnit}}
									<table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td colspan="5">
													<table style="width: 100%;">
														<tbody>
															{{? it.cashDiscountUnit == '%'}}
															<tr>
																<td colspan="3" style="text-align: left;">
																CASH DISCOUNT @{{=it.cash_discount}} % 
																{{? it.localize_titles && it.localize_titles['cash discount']}}<div style="text-align:right;">{{=it.localize_titles['cash discount']}}</div> {{?}}
																</td>
																<td colspan="2" style="text-align: right;"> {{=it.cash_discount_amount.toFixed(it.decimalCount)}}</td>
															</tr>
															{{?}}
															{{? it.cashDiscountUnit != '%'}}
															<tr>
																<td colspan="3" style="text-align: left;">
																	CASH DISCOUNT
																	{{? it.localize_titles && it.localize_titles['cash discount']}}<div style="text-align:right;">{{=it.localize_titles['cash discount']}}</div> {{?}}
																</td>
																<td colspan="2" style="text-align: right;"> {{=it.cash_discount_amount.toFixed(it.decimalCount)}}</td>
															</tr>
															{{?}}
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								{{?}}
								<!-- Cash Discount ends -->

								<!-- Roundoff value begin-->
								{{? it.rounding_value && it.rounding_value != 0}}
								<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left;">
															Round off 
															{{? it.localize_titles && it.localize_titles['round off']}}<div style="text-align:right;">{{=it.localize_titles['round off']}}</div> {{?}}
															</td>
															<!-- {{? it.rounding_value > 0}}
																<td colspan="2" style="text-align: right;"> +  {{=it.rounding_value.toFixed(it.decimalCount)}}</td>
															{{?? it.rounding_value < 0}}
																<td colspan="2" style="text-align: right;"> -  {{=it.rounding_value.toFixed(it.decimalCount)}}</td>
															{{??}}
															{{?}} -->
															<td colspan="2" style="text-align: right;"> {{=it.rounding_value.toFixed(it.decimalCount)}}</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
								{{?}}
								<!-- Roundoff value end-->

								<!-- Credit note amount begin-->
								{{? it.totalPrice && it.totalPrice < 0}}
								<table style="width: 100%; font-size: 14px;">
									<tbody>
										<tr>
											<td colspan="5">
												<table style="width: 100%;">
													<tbody>
														<tr>
															<td colspan="3" style="text-align: left;">
															Credit note amount
															{{? it.localize_titles && it.localize_titles['credit note amount']}}<div style="text-align:right;">{{=it.localize_titles['credit note amount']}}</div> {{?}}
															</td>		                                            
															<td colspan="2" style="text-align: right;"> {{=it.totalPrice.toFixed(it.decimalCount)}}</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
								{{?}}
								<!-- Credit note amount end-->

								<table style="width: 100%; font-size: 14px;">
								<tbody>
								<tr>
									<td style='text-align: right;'>
									<table style="width: 100%;">
										<tbody>
										<tr>
											<td style="border-bottom: 1px solid #000;"></td>
										</tr>
										</tbody>
									</table><!-- solid hr-line -->
									</td>
								</tr>
								</tbody>
							</table><!-- check table -->
							
							<!-- Order note begins -->
							{{? it.note }}
								<table style="width: 100%; font-size: 14px;">
								<tbody>
									<tr>
									<td style='text-align: center;'>
										<table style="width: 100%;">
										<tbody>
											<tr>
											<td style="text-align: center; font-weight: 600;">{{=it.note}}</td>
											</tr>
										</tbody>
										</table>
									</td>
									</tr>
								</tbody>
								</table>
							{{?}}
							<!-- Order note ends -->

							<!-- Discount begins -->
							{{? it.total_discount > 0}}
								<table style="width: 100%; font-size: 14px;">
								<tbody>
									<tr>
									<td style='text-align: center;'>
										<table style="width: 100%;">
										<tbody>
											<tr>
											<td style="text-align: center; font-weight: 600;">You saved  {{=it.total_discount.toFixed(it.decimalCount)}}</td>
											</tr>
										</tbody>
										</table>
									</td>
									</tr>
								</tbody>
								</table>
							{{?}}
							<!-- Discount ends -->

								<!-- GST taxes information begins -->
								{{?it.show_gst_summary_on_print && it.taxesByTaxCode && it.taxesByTaxCode.length>0}}
									<table style="width: 100%;>
										<tbody>
											<tr>
												<td> 
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td><b>GST Receipt Summary</b></td>
															</tr>
															<tr>
																<td style="border-bottom: 1px solid #000;"></td>
															</tr>
														</tbody>
													</table><!-- solid hr-line -->
												</td>
											</tr>		              				
										</tbody>
									</table>	
									
									<table style="text-align: center; width: 100%">
										<tbody>
											<tr>
												<th> 
													HSN/SAC Code
												</th>
												<th>
													GST %
												</th>
												<th>
													SGST Amt
												</th>
												<th>
													CGST Amt
												</th>
												<th>
													IGST Amt
												</th>
											</tr>
										</tbody>
										<tbody>
											{{~ it.taxesByTaxCode :tax:indtx}}
												<tr>
													<td> 
														{{=tax.code}}
													</td>
													<td>
														{{=tax.rate}}
													</td>
													<td>
														{{? tax.tax_breakup.SGST}}
															{{=tax.tax_breakup.SGST.toFixed(it.decimalCount)}}
														{{?}}
														{{? !tax.tax_breakup.SGST}}
															0
														{{?}}
													</td>
													<td>
														{{? tax.tax_breakup.CGST}}
															{{=tax.tax_breakup.CGST.toFixed(it.decimalCount)}}
														{{?}}
														{{? !tax.tax_breakup.CGST}}
															0
														{{?}}
													</td>
													<td>
														{{? tax.tax_breakup.IGST}}
															{{=tax.tax_breakup.IGST.toFixed(it.decimalCount)}}
														{{?}}
														{{? !tax.tax_breakup.IGST}}
															0
														{{?}}
													</td>
												</tr>
											{{~}}
										</tbody>
									</table>

									<table style="width: 100%; font-size: 14px;">
										<tbody>
											<tr>
												<td style='text-align: right;'>
													<table style="width: 100%;">
														<tbody>
															<tr>
																<td style="border-bottom: 1px solid #000;"></td>
															</tr>
														</tbody>
													</table><!-- solid hr-line -->
												</td>
											</tr>
										</tbody>
									</table><!-- check table -->
								{{?}}
								<!-- GST taxes information ends -->

								<table style="width: 100%;>
								<tbody>
									<tr>
									<td colspan="3" height="20"></td><!-- margin -->
									</tr>
									{{? it.discountReason && it.discountReason != ""}}
									<tr>
										<td colspan="5" style="text-align: center; font-size: 12px;">Discount Reason: {{=it.discountReason}}</td>
									</tr>
									{{?}}
									{{? it.client_reference_code && it.terminal && it.bill_ref_id }}
										<tr>
										<td colspan="5" style="text-align: center; font-size: 12px;">			                      	
											Terminal: {{=it.terminal}} 
											{{? it.localize_titles && it.localize_titles['terminal']}}:{{=it.localize_titles['terminal']}}{{?}}
											&nbsp;&nbsp; 
											Ref ID: {{=it.bill_ref_id}}
											{{? it.localize_titles && it.localize_titles['ref id']}}:{{=it.localize_titles['ref id']}}{{?}}
										</td>
										</tr>
									{{?}}
									{{? !it.print_footer_note_enabled || !it.print_footer_note }}
										<tr>
										<td style='text-align: center; font-size: 12px;'>Thank you for your business!</td>
										</tr>
										<tr>
										<td style='text-align: center; font-size: 12px;'>With love from OneGreenDiary..</td>
										</tr>
									{{?}}
									{{? it.print_footer_note_enabled && it.print_footer_note }}
										<tr>
										<td style='text-align: center; font-size: 12px;'>{{=it.print_footer_note}}</td>
										</tr>                      
									{{?}}
									<tr>
									<td colspan="3" height="10"></td><!-- margin -->
									</tr>
								</tbody>
								</table><!-- thanks container -->

							</td>
							</tr>

							</tbody>
							</table><!-- Email Wrapper -->

						</td>
						</tr>
					</tbody>
					</table><!-- Generall Wrapper -->
				`;
		}
		//TODO: check the print object and date time.
		try {
			localStorage.removeItem('selected_staff_user');
			var tempFn = dot.template(str2);
			var printContents = tempFn(printObj);

		} catch (e) {
			console.log(e);
		}

		try {
			console.log("pop up called");
			// remove diary balance from local storage
			localStorage.removeItem('current_diary_balance');

			let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
			popupWin.document.open();

			popupWin.document.write(`
	            <html>
	                <head>
	                    <title>Print tab</title>
	                    <style>
	              .marginValues{
	                margin-top:0px !important;
	              }
	            .item{
	              float:left !important;
	            }
	             .text{
	                          text-transform: capitalize !important
	                      }
	              .kot-modal-bg {
	                  background-color: lightgray;
	              }
	            .print{
	              font-family:monospace;
	              text-align:center !important;
	            }
	            .kot-print {
	              font-size:20px;
	              font-family:monospace;
	            }
	            .align-right{
	              tax-align:right !important;
	            }
	                    </style>
	                    <script type="text/javascript">
                            function onload() {
                            	setTimeout(function(){window.print(); window.close();}, 200);  
                                /*window.print();
                                setTimeout(function(){window.close();}, 1000);*/
                            }
                        </script>
	                </head>
	                <body onload='onload();'>${printContents}
	                </body>
	            </html>`
			);
			popupWin.document.close();
		} catch (e) {
			console.log(e)
			alert("please enable popup to show print")
		}
	}

	printCheckPrintOnWifiPrinter(printerObj, printObj) {
		console.log('printCheckPrintOnWifiPrinter')
		let self = this;
		let printConfigs = JSON.parse(localStorage.getItem('print_configs'));
				let printConfigAvailable = false;
				let selectedPrintConfig;
				if(printConfigs && printConfigs instanceof Array && printerObj){
					let printC = printConfigs.filter(item=> item.printer_id === printerObj.id);
					if(printC && printC instanceof Array && printC.length > 0){
						printConfigAvailable = true;
						selectedPrintConfig = printC[0].print_config
					}
				}

				printObj['print_config'] = selectedPrintConfig;
				printObj['print_staff_name'] = printerObj?.print_staff_name
				if(printConfigAvailable){
					try {

						//find appropriate printer/s      		
						let printerData = JSON.parse(localStorage.getItem('shop_printers'));
						if (printerData) {
							var printers_list = printerData;
							var printerFound = false;
							printers_list.forEach(printerObj => {
								if (printerObj.ip_address && printerObj.ip_address != "" && (printerObj.print_type.indexOf('receipt') != -1 || printerObj.print_type.indexOf('self_service') != -1)) {
									printerFound = true;
									let builder = new epson.ePOSBuilder();

									//right to left character need to reverse
									if (printObj.localize_titles && (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1)) {
										for (var x in printObj.localize_titles) {
											if (printObj.localize_titles[x]) {
												printObj.localize_titles[x] = printObj.localize_titles[x].split("").reverse().join("");
											}
										}
									}
									this.getPrintLogo(builder, printObj).then(isLogoAdded => {
										if (!isLogoAdded || printObj['print_config'].outlet_name) {
											builder.addTextStyle(false, false, true);
											builder.addTextAlign(builder.ALIGN_CENTER);
											builder.addTextSize(2, 2);
											builder.addText('\n' + printObj.shop_name + '\n');
											builder.addTextSize(1, 1);
										}
			
										builder.addTextStyle(false, false, true);
										builder.addTextAlign(builder.ALIGN_CENTER);
										// print format is here
										if(printObj['print_config'].shop_address)
										builder = this.printAddress(builder, printObj.shop_address);
			
										if (printObj.shop_phone_number && printObj.shop_phone_number != "NA" && printObj['print_config'].outlet_phone_number) {
											if (printObj.isTwoLanguageShown && printObj.localize_titles["phone number"]) {
												builder.addText('PH : ' + printObj.shop_phone_number + " : " + printObj.localize_titles['phone number'] + ' \n');
											} else {
												builder.addText('PH : ' + printObj.shop_phone_number + '\n');
											}
										}
			
										if (printObj.shop_zip_code && printObj.shop_zip_code != "NA" && printObj['print_config'].shop_zip_code) {
											if (printObj.isTwoLanguageShown && printObj.localize_titles["pin code"]) {
												builder.addText('Pin Code : ' + printObj.shop_zip_code + " : " + printObj.localize_titles['pin code'] + ' \n');
											} else {
												builder.addText('Pin Code : ' + printObj.shop_zip_code + '\n');
											}
										}
			
										// if (printObj.tax_remark) {
										// 	printObj.tax_remark.forEach(obj => {
										// 		if (obj.code && obj.remark) {
										// 			if (printerObj.tax_number_title) {
										// 				builder.addText(printerObj.tax_number_title + ': ' + obj.code + '\n');
										// 			} else {
										// 				builder.addText(obj.remark + ': ' + obj.code + '\n');
										// 			}
										// 		}
										// 	});
										// }

										if (printObj['print_config'].outlet_gst_no) {
											if (printerObj.tax_number_title) {
												builder.addText(printerObj.tax_number_title + ': ' + printObj.gstin + '\n');
											} else {
												builder.addText('Outlet GST No: ' + printObj.gstin + '\n');
											}
										}

										if (printObj.print_extra_field && printObj.extra_field_name && printObj.extra_field_value) {
											builder.addText(printerObj.extra_field_name + ': ' + printObj.extra_field_value + '\n');
										}
			
										builder.addTextStyle(false, false, true);
										builder.addTextAlign(builder.ALIGN_CENTER);
										builder.addTextFont(builder.FONT_A);
										if (printObj.customer_name && printObj.customer_mobile_no != "0000000000") {
											builder.addText('---------------------------------------------\n');
											builder.addText('CUSTOMER INFORMATION' + '\n');
											if (printObj.customer_name) {
												if (printObj.isTwoLanguageShown && printObj.localize_titles["name"]) {
													builder.addText('Name : ' + printObj.customer_name + " : " + printObj.localize_titles['name'] + ' \n');
												} else {
													builder.addText('Name : ' + printObj.customer_name + '\n');
												}
											}
											if (printerObj?.print_staff_name) {
												let staff_name = localStorage.getItem('selected_staff_user')
												if (staff_name && staff_name != undefined) {
													builder.addText('Staff : ' + staff_name + ' \n');
												}
											}
			
											if (printObj.customer_address_1 && printObj['print_config'].customer_address) {
												builder = this.printAddress(builder, printObj.customer_address_1);
											}
			
											if (printObj.customer_mobile_no && printObj.customer_mobile_no != "NA" && printObj['print_config'].customer_number) {
												if (printObj.isTwoLanguageShown && printObj.localize_titles["phone number"]) {
													builder.addText('PH : ' + printObj.customer_mobile_no + " : " + printObj.localize_titles['phone number'] + ' \n');
												} else {
													builder.addText('PH : ' + printObj.customer_mobile_no + '\n');
												}
											}
			
											//printing GSTIN of client
											if (printObj.customer_gstin && printObj.customer_gstin != "NA" && printObj.customer_gstin != "null" && printObj['print_config'].customer_gst_no) {
												builder.addText("Customer GSTIN: " + printObj.customer_gstin + '\n');
											}
										}
			
			
										builder.addText('---------------------------------------------\n');
										builder.addTextAlign(builder.ALIGN_CENTER);
										builder.addTextStyle(false, false, true);
										if(printObj['print_config'].header_receipt_tax_invoice){
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["receipt"]) {
												if (printerObj.print_header) {
													builder.addText(printerObj.print_header + '\n\n');
												} else {
													builder.addText('Receipt / Tax Invoice\n');
													builder.addText(printObj.localize_titles["receipt"] + ' \n\n');
												}
											} else {
												if (printerObj.print_header) {
													builder.addText(printerObj.print_header + '\n\n');
												} else {
													builder.addText('Receipt / Tax Invoice\n\n');
												}
											}
										}
			
										//transaction date
										if (printObj.dateTime && printObj['print_config'].transaction_date) {
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["transaction date"]) {
												console.log("transaction date:", printObj.transaction_date);
												builder.addText('Transaction Date: ' + printObj.dateTime + " : " + printObj.localize_titles["transaction date"] + ' \n');
											} else {
												builder.addText('Transaction Date: ' + printObj.dateTime + '\n');
											}
										}
			
										//delivery date
										if (printObj.deliveryDateTime && printObj['print_config'].delivery_date) {
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["delivery date"]) {
												builder.addText('Delivery Date: ' + printObj.deliveryDateTime + " : " + printObj.localize_titles["delivery date"] + ' \n');
											} else {
												builder.addText('Delivery Date: ' + printObj.deliveryDateTime + '\n');
											}
										}
										if (printObj.bill_no && printObj.bill_no != "NA" && printObj.user_name && printObj['print_config'].bill_number && printObj['print_config'].user_name) {
											builder.addText('Bill No: ' + printObj.bill_no +'  User: ' + printObj.user_name + ' \n');
										} else if (printObj.user_name && printObj['print_config'].user_name) {
											if (printObj.isTwoLanguageShown && printObj.localize_titles["user name"]) {
												builder.addText('User: ' + printObj.user_name + " : " + printObj.localize_titles['user name'] + ' \n');
											} else {
												builder.addText('User: ' + printObj.user_name + '\n');
											}
										} else if (printObj.bill_no && printObj.bill_no != "NA" && printObj['print_config'].bill_number) {
											builder.addText('Bill No: ' + printObj.bill_no + ' \n');
					
										}
			
										if (printObj.checkPrintNumber) {
											builder.addText('Check Print Number: ' + printObj.checkPrintNumber + ' \n');
										}
			
										if (printObj.enable_tables) {
											let sectionTableStr = "";
											if (printObj.section && printObj.table_no && printObj['print_config'].section_garden_section && printObj['print_config'].table_number) {
												if (printObj.isTwoLanguageShown && printObj.localize_titles["section"] && printObj.localize_titles["table no"]) {
													sectionTableStr = 'Section: ' + this.capitalizeFirstLetter(printObj.section) + " : " + printObj.localize_titles['section'] + ' \n';
													sectionTableStr += 'Table No: ' + printObj.table_no + " : " + printObj.localize_titles['table no'] + ' \n';
												} else {
													sectionTableStr = 'Section: ' + this.capitalizeFirstLetter(printObj.section) + '  Table No: ' + printObj.table_no + '\n';
												}
											} else if (printObj.section && printObj['print_config'].section_garden_section) {
												if (printObj.isTwoLanguageShown && printObj.localize_titles["section"] && printObj.localize_titles["table no"]) {
													sectionTableStr = 'Section: ' + this.capitalizeFirstLetter(printObj.section) + " : " + printObj.localize_titles['section'] + ' \n';
												} else {
													sectionTableStr = 'Section: ' + this.capitalizeFirstLetter(printObj.section) + ' \n'
												}
											} else if (printObj.table_no && printObj['print_config'].table_number) {
												if (printObj.isTwoLanguageShown && printObj.localize_titles["section"] && printObj.localize_titles["table no"]) {
													sectionTableStr = 'Table No: ' + printObj.table_no + " : " + printObj.localize_titles['table no'] + ' \n';
												} else {
													sectionTableStr = 'Table No: ' + printObj.table_no + '\n'
												}
											}
			
											builder.addText(sectionTableStr);
										}
			
										builder.addTextStyle(false, false, false);
			
										builder.addText('---------------------------------------------\n');
										builder.addPageBegin();
										if (printObj.isTwoLanguageShown && printObj.localize_titles["particulars"] && printObj.localize_titles["rate"] && printObj.localize_titles["qty"] && printObj.localize_titles["amount"]) {
											builder.addPageArea(20, 0, 530, 60);
											builder.addText("Particulars          Rate      Qty     Amt\n");
											builder.addText(printObj.localize_titles["particulars"] + "          " + printObj.localize_titles["rate"] + "      " + printObj.localize_titles["qty"] + "      " + printObj.localize_titles["amount"] + ' \n');
										} else {
											builder.addPageArea(20, 0, 530, 30);
											builder.addText("Particulars          Rate      Qty     Amt\n");
										}
										builder.addPageEnd();
										builder.addText('---------------------------------------------\n');
			
										// Add each line item to receipt (function below)
										if (!printObj.print_sectionwise) {
											//normal print (non-sectionwise printing)
											if (printObj.item_wise_tax) {
												//group by tax code and print	            
												printObj.taxCodeWiseItems.forEach((taxType, indx) => {
													if (taxType.code != "no_tax") {
														builder.addPageBegin();
														builder.addPageArea(5, 0, 364, 30);
														builder.addText(taxType.code + " @ " + taxType.rate + "%");
														builder.addPageEnd();
													}
			
													for (var i = 0; i < taxType.items.length; i++) {
														builder = this.createItemLineV2(builder, taxType.items[i], printObj);
													}
			
													if (indx != printObj.taxCodeWiseItems.length - 1) {
														builder.addPageBegin();
														builder.addPageArea(5, 0, 364, 30);
														builder.addText("               ---------------");
														builder.addPageEnd();
													}
												})
											} else {
												//print without grouping
												for (var i = 0; i < printObj.cart_items.length; i++) {
													builder = this.createItemLineV2(builder, printObj.cart_items[i], printObj);
												}
											}
										} else {
											printObj.cartSections.forEach((sectionObj, indx) => {
												builder.addText(sectionObj.sectionName.toUpperCase() + ' ITEMS\n');
												if (printObj.item_wise_tax) {
													//group by tax code and print
													sectionObj.taxCodeWiseItems.forEach((taxType, indx) => {
														if (taxType.code != "no_tax") {
															builder.addPageBegin();
															builder.addPageArea(5, 0, 364, 30);
															builder.addText(taxType.code + " @ " + taxType.rate + "%");
															builder.addPageEnd();
														}
			
														for (var i = 0; i < taxType.items.length; i++) {
															builder = this.createItemLineV2(builder, taxType.items[i], printObj);
														}
			
														if (indx != sectionObj.taxCodeWiseItems.length - 1) {
															builder.addPageBegin();
															builder.addPageArea(5, 0, 364, 30);
															builder.addText("               ---------------");
															builder.addPageEnd();
														}
													});
												} else {
													//print without grouping
													sectionObj.items.forEach(item => {
														builder = this.createItemLineV2(builder, item, printObj);
													})
												}
			
												builder = this.createSectionSubTotalLineV2(builder, sectionObj, printObj);
												if (indx != (printObj.cartSections.length - 1)) {
													builder.addTextAlign(builder.ALIGN_CENTER);
													builder.addText('---------------------------------------------\n');
												}
											});
										}
			
										// Add total row at bottom of receipt	      
										builder.addTextAlign(builder.ALIGN_CENTER);
										builder.addText('---------------------------------------------\n');
										builder.addTextAlign(builder.ALIGN_RIGHT);
										if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["sub total"]) {
											builder.addText('Sub-total: ' + printObj.cart_value.toFixed(printObj.decimalCount) + "    \n " + printObj.localize_titles["sub total"] + '    \n');
										} else {
											builder.addText('Sub-total: ' + printObj.cart_value.toFixed(printObj.decimalCount) + '   \n');
										}
			
										//tax breakup
										if (printObj.group_taxes) {
											printObj.group_taxes.forEach(tax => {
												let itText = "";
												if (tax.item_count) {
													itText = tax.item_count + " Item" + ((tax.item_count != 1 && tax.item_count != -1) ? "s" : "") + " with ";
												}
			
												if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1) {
													builder.addText('Includes ' + itText + tax.breakup_name + "@" + tax.rate + "% of " + tax.value.toFixed(printObj.decimalCount) + '    ' + '\n');
												} else {
													builder.addText('Includes ' + itText + tax.breakup_name + "@" + tax.rate + "% of " + tax.value.toFixed(printObj.decimalCount) + '    ' + '\n');
												}
											})
										}
			
										builder.addTextStyle(false, false, true);
										if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["total"]) {
											builder.addText('TOTAL: ' + printObj.totalPrice.toFixed(printObj.decimalCount) + printObj.currencySymbol + "    \n " + printObj.localize_titles["total"] + '    \n');
										} else {
											builder.addText('TOTAL: ' + printObj.currencySymbol + " " + printObj.totalPrice.toFixed(printObj.decimalCount) + '   ' + '\n'); //seatPrices.total
										}
										builder.addTextStyle(false, false, false);
										builder.addTextFont(builder.FONT_B);
			
										if (Array.isArray(printObj.splitPaymentList) && printObj.splitPaymentList.length > 0) {
											printObj.splitPaymentList.forEach((sp: any) => {
												let tN: any = sp.name ? sp.name : sp.sub_class_name ? sp.sub_class_name : "";
												builder.addText(tN + ': ' + sp.amount.toFixed(printObj.decimalCount) + "   \n ");
											})
										}
			
										//overall discount
										if (printObj.discount && printObj.discountUnit) {
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["discount"]) {
												if (printObj.discountUnit == '%') {
													builder.addText('Includes DISCOUNT @' + printObj.discount + "% of " + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["discount"] + '    \n');
												} else {
													builder.addText('Includes DISCOUNT of ' + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["discount"] + '    \n');
												}
											} else {
												if (printObj.discountUnit == '%') {
													builder.addText('Includes DISCOUNT @' + printObj.discount + "% of " + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
												} else {
													builder.addText('Includes DISCOUNT of ' + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
												}
											}
										}
			
										//overall discount
										if (printObj.discount && printObj.discountUnit) {
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["discount"]) {
												if (printObj.discountUnit == '%') {
													builder.addText('Includes DISCOUNT @' + printObj.discount + "% of " + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["discount"] + '    \n');
												} else {
													builder.addText('Includes DISCOUNT of ' + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["discount"] + '    \n');
												}
											} else {
												if (printObj.discountUnit == '%') {
													builder.addText('Includes DISCOUNT @' + printObj.discount + "% of " + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
												} else {
													builder.addText('Includes DISCOUNT of ' + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
												}
											}
										}
			
										//other discounts on subtotal
										if (printObj.on_subtotal_other_discounts) {
											printObj.on_subtotal_other_discounts.forEach(dValue => {
												if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1) {
													builder.addText(dValue.name + " " + Math.abs(dValue.value).toFixed(printObj.decimalCount) + '    ' + '\n');
												} else {
													builder.addText(dValue.name + " " + Math.abs(dValue.value).toFixed(printObj.decimalCount) + '    ' + '\n');
												}
											});
										}
			
										//extra charges
										if (printObj.extraCharges) {
											printObj.extraCharges.forEach(ec => {
												if (ec.value > 0) {
													if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1) {
														builder.addText('Includes ' + ec.charge_name.toUpperCase() + ' of: ' + ec.value.toFixed(printObj.decimalCount) + '    ' + '\n');
													} else {
														builder.addText('Includes ' + ec.charge_name.toUpperCase() + ' of: ' + ec.value.toFixed(printObj.decimalCount) + '    ' + '\n');
													}
												}
											})
										}
			
			
										//cash discount
										if (printObj.cash_discount && printObj.cashDiscountUnit) {
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["cash discount"]) {
												if (printObj.discountUnit == '%') {
													builder.addText('Includes CASH DISCOUNT @' + printObj.cash_discount + "% of " + printObj.cash_discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["cash discount"] + '    \n');
												} else {
													builder.addText('Includes CASH DISCOUNT of ' + printObj.cash_discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["cash discount"] + '    \n');
												}
											} else {
												if (printObj.cashDiscountUnit == '%') {
													builder.addText('Includes CASH DISCOUNT @' + printObj.cash_discount + "% of " + printObj.cash_discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
												} else {
													builder.addText('Includes CASH DISCOUNT of ' + printObj.cash_discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
												}
											}
										}
			
										//roundoff
										if (printObj.rounding_value != 0) {
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["round off"]) {
												builder.addText('Roundoff Amount ' + printObj.rounding_value.toFixed(printObj.decimalCount) + '    \n ' + printObj.localize_titles["round off"] + '    \n');
											} else {
												builder.addText('Roundoff Amount ' + printObj.rounding_value.toFixed(printObj.decimalCount) + '    ' + '\n');
											}
										}
			
										//credit note amount 
										if (printObj.totalPrice < 0) {
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["credit note amount"]) {
												builder.addText('Credit Note Amount ' + printObj.totalPrice.toFixed(printObj.decimalCount) + '    \n ' + printObj.localize_titles["credit note amount"] + '    \n');
											} else {
												builder.addText('Credit Note Amount ' + printObj.totalPrice.toFixed(printObj.decimalCount) + '    ' + '\n');
											}
										}
			
										// Add sales rep and transaction date/time
										builder.addTextAlign(builder.ALIGN_CENTER);
										builder.addTextFont(builder.FONT_A);
										builder.addText('---------------------------------------------\n');
										builder.addTextFont(builder.FONT_B);
			
										if (printObj.note) {
											builder.addTextStyle(false, false, true);
											builder.addText(printObj.note + '\n');
											builder.addTextStyle(false, false, false);
										}
			
										if (printObj.total_discount > 0) {
											builder.addTextStyle(false, false, true);
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1) {
												builder.addText('You saved: ' + printObj.total_discount.toFixed(printObj.decimalCount) + ' \n');
											} else {
												builder.addText('You saved: ' + printObj.total_discount.toFixed(printObj.decimalCount) + '\n');
											}
											builder.addTextStyle(false, false, false);
			
											//discount reason
											if (printObj.discountReason) {
												builder.addText('Discount Reason: ' + printObj.discountReason + '\n');
											}
										}
			
										//client Gst summary
										if (printObj.show_gst_summary_on_print && printObj.taxesByTaxCode && printObj.taxesByTaxCode.length > 0) {
											builder.addTextFont(builder.FONT_B);
											builder.addTextStyle(false, false, true);
											builder.addText('GST Receipt Summary\n');
											builder.addPageBegin();
											builder.addPageArea(20, 0, 120, 60);
											builder.addText('HSN/SAC Code');
											builder.addPageArea(160, 0, 50, 60);
											builder.addText('GST %');
											builder.addPageArea(230, 0, 50, 30);
											builder.addText('SGST Amt');
											builder.addPageArea(300, 0, 50, 30);
											builder.addText('CGST Amt');
											builder.addPageArea(380, 0, 100, 30);
											builder.addText('IGST Amt');
											builder.addPageEnd();
											builder.addTextStyle(false, false, false);
											printObj.taxesByTaxCode.forEach(tax => {
												builder.addPageBegin();
												builder.addPageArea(20, 0, 120, 60);
												builder.addText(tax.code);
												builder.addPageArea(160, 0, 50, 60);
												builder.addText(tax.rate);
												builder.addPageArea(230, 0, 50, 30);
												if (tax.tax_breakup.SGST) {
													builder.addText(tax.tax_breakup.SGST.toFixed(printObj.decimalCount));
												} else {
													builder.addText('0');
												}
												builder.addPageArea(300, 0, 50, 30);
												if (tax.tax_breakup.CGST) {
													builder.addText(tax.tax_breakup.CGST.toFixed(printObj.decimalCount));
												} else {
													builder.addText('0');
												}
												builder.addPageArea(380, 0, 100, 30);
												if (tax.tax_breakup.IGST) {
													builder.addText(tax.tax_breakup.IGST.toFixed(printObj.decimalCount));
												} else {
													builder.addText('0');
												}
												builder.addPageEnd();
											})
											builder.addTextFont(builder.FONT_A);
											builder.addText('---------------------------------------------\n');
										}
										//client reference code terminal
										if (printObj.client_reference_code && printObj.terminal && printObj.bill_ref_id && printObj['print_config'].terminal_details) {
											if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["terminal"] && printObj.localize_titles["ref id"]) {
												builder.addText('Terminal: ' + printObj.bill_ref_id + " : " + printObj.localize_titles["terminal"] + ' \n');
												builder.addText('Ref ID: ' + printObj.terminal + " : " + printObj.localize_titles["ref id"] + " \n");
											} else {
												builder.addText('Terminal: ' + printObj.bill_ref_id + '  Ref ID: ' + printObj.terminal + '\n');
											}
										}
			
										var ipAddr = printerObj.ip_address;
										var address = 'http://' + ipAddr + '/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000';
										var epos = new epson.ePOSPrint(address);
										// Check if printer cover is open
										epos.oncoveropen = function () { alert('coveropen'); };
			
										// Send print job
										if(printObj['print_config'].footer){
											if (printerObj.print_footer_note_enabled && printerObj.print_footer_note) {
												builder.addText(printerObj.print_footer_note + '\n');
											} else {
												builder.addText('Thank you for your business' + '\n');
												builder.addText('With love from OneGreenDiary' + '\n');
											}
										}
			
										builder.addPageBegin();
										builder.addPageArea(0, 0, 30, 30);
										builder.addPageLine(0, 0, 0, 100, builder.LINE_THIN);
										builder.addPageEnd();
			
			
										// Add 2 line feeds and cut
										builder.addFeed();
										builder.addFeed();
										builder.addCut(builder.CUT_FEED);
										// console.log("builder ",builder.toString());
										epos.send(builder.toString());
										try {
											localStorage.removeItem('selected_staff_user');
										} catch (e) {
											console.log(e);
										}
									});
								}
							});
			
							if (!printerFound) {
								console.log('Printer not found');
								this.messageService.add({ severity: 'success', summary: 'Wifi Printer not found', detail: 'Wifi Printer not found' });
								return;
							}
						} else {
							console.log("Printer data could not be fetched");
							this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
							return;
						}
					} catch (err) {
						console.log(err);
					}
				}else{

		try {

			//find appropriate printer/s      		
			let printerData = JSON.parse(localStorage.getItem('shop_printers'));
			if (printerData) {
				var printers_list = printerData;
				var printerFound = false;
				printers_list.forEach(printerObj => {
					if (printerObj.ip_address && printerObj.ip_address != "" && (printerObj.print_type.indexOf('receipt') != -1 || printerObj.print_type.indexOf('self_service') != -1)) {
						printerFound = true;
						let builder = new epson.ePOSBuilder();

						//right to left character need to reverse
						if (printObj.localize_titles && (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1)) {
							for (var x in printObj.localize_titles) {
								if (printObj.localize_titles[x]) {
									printObj.localize_titles[x] = printObj.localize_titles[x].split("").reverse().join("");
								}
							}
						}
						this.getPrintLogo(builder, printObj).then(isLogoAdded => {
							if (!isLogoAdded || printObj['print_config'].outlet_name) {
								builder.addTextStyle(false, false, true);
								builder.addTextAlign(builder.ALIGN_CENTER);
								builder.addTextSize(2, 2);
								builder.addText('\n' + printObj.shop_name + '\n');
								builder.addTextSize(1, 1);
							}

							builder.addTextStyle(false, false, true);
							builder.addTextAlign(builder.ALIGN_CENTER);

							builder = this.printAddress(builder, printObj.shop_address);
							if (printObj.shop_phone_number && printObj.shop_phone_number != "NA") {
								if (printObj.isTwoLanguageShown && printObj.localize_titles["phone number"]) {
									builder.addText('PH : ' + printObj.shop_phone_number + " : " + printObj.localize_titles['phone number'] + ' \n');
								} else {
									builder.addText('PH : ' + printObj.shop_phone_number + '\n');
								}
							}

							if (printObj.shop_zip_code && printObj.shop_zip_code != "NA") {
								if (printObj.isTwoLanguageShown && printObj.localize_titles["pin code"]) {
									builder.addText('Pin Code : ' + printObj.shop_zip_code + " : " + printObj.localize_titles['pin code'] + ' \n');
								} else {
									builder.addText('Pin Code : ' + printObj.shop_zip_code + '\n');
								}
							}

							// if (printObj.tax_remark) {
							// 	printObj.tax_remark.forEach(obj => {
							// 		if (obj.code && obj.remark) {
							// 			if (printerObj.tax_number_title) {
							// 				builder.addText(printerObj.tax_number_title + ': ' + obj.code + '\n');
							// 			} else {
							// 				builder.addText(obj.remark + ': ' + obj.code + '\n');
							// 			}
							// 		}
							// 	});
							// }

							if (printObj['print_config'].outlet_gst_no) {
								if (printerObj.tax_number_title) {
									builder.addText(printerObj.tax_number_title + ': ' + printObj.gstin + '\n');
								} else {
									builder.addText('Outlet GST No: ' + printObj.gstin + '\n');
								}
							}

							if (printObj.print_extra_field && printObj.extra_field_name && printObj.extra_field_value) {
								builder.addText(printerObj.extra_field_name + ': ' + printObj.extra_field_value + '\n');
							}

							builder.addTextStyle(false, false, true);
							builder.addTextAlign(builder.ALIGN_CENTER);
							builder.addTextFont(builder.FONT_A);
							if (printObj.customer_name && printObj.customer_mobile_no != "0000000000") {
								builder.addText('---------------------------------------------\n');
								builder.addText('CUSTOMER INFORMATION' + '\n');
								if (printObj.customer_name) {
									if (printObj.isTwoLanguageShown && printObj.localize_titles["name"]) {
										builder.addText('Name : ' + printObj.customer_name + " : " + printObj.localize_titles['name'] + ' \n');
									} else {
										builder.addText('Name : ' + printObj.customer_name + '\n');
									}
								}
								if (printerObj?.print_staff_name) {
									let staff_name = localStorage.getItem('selected_staff_user')
									if (staff_name && staff_name != undefined) {
										builder.addText('Staff : ' + staff_name + ' \n');
									}
								}

								if (printObj.customer_address_1) {
									builder = this.printAddress(builder, printObj.customer_address_1);
								}

								if (printObj.customer_mobile_no && printObj.customer_mobile_no != "NA") {
									if (printObj.isTwoLanguageShown && printObj.localize_titles["phone number"]) {
										builder.addText('PH : ' + printObj.customer_mobile_no + " : " + printObj.localize_titles['phone number'] + ' \n');
									} else {
										builder.addText('PH : ' + printObj.customer_mobile_no + '\n');
									}
								}

								//printing GSTIN of client
								if (printObj.customer_gstin && printObj.customer_gstin != "NA" && printObj.customer_gstin != "null") {
									builder.addText("Customer GSTIN: " + printObj.customer_gstin + '\n');
								}
							}


							builder.addText('---------------------------------------------\n');
							builder.addTextAlign(builder.ALIGN_CENTER);
							builder.addTextStyle(false, false, true);
							if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["receipt"]) {
								if (printerObj.print_header) {
									builder.addText(printerObj.print_header + '\n\n');
								} else {
									builder.addText('Receipt / Tax Invoice\n');
									builder.addText(printObj.localize_titles["receipt"] + ' \n\n');
								}
							} else {
								if (printerObj.print_header) {
									builder.addText(printerObj.print_header + '\n\n');
								} else {
									builder.addText('Receipt / Tax Invoice\n\n');
								}
							}

							//transaction date
							if (printObj.dateTime) {
								if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["transaction date"]) {
									builder.addText('Transaction Date: ' + printObj.dateTime + " : " + printObj.localize_titles["transaction date"] + ' \n');
								} else {
									builder.addText('Transaction Date: ' + printObj.dateTime + '\n');
								}
							}

							//delivery date
							if (printObj.deliveryDateTime) {
								if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["delivery date"]) {
									builder.addText('Delivery Date: ' + printObj.deliveryDateTime + " : " + printObj.localize_titles["delivery date"] + ' \n');
								} else {
									builder.addText('Delivery Date: ' + printObj.deliveryDateTime + '\n');
								}
							}

							if (printObj.bill_no && printObj.bill_no != "NA" && printObj.user_name) {
								builder.addText('Bill No: ' + printObj.bill_no + '  User: ' + printObj.user_name + ' \n');
							} else if (printObj.user_name) {
								if (printObj.isTwoLanguageShown && printObj.localize_titles["user name"]) {
									builder.addText('User: ' + printObj.user_name + " : " + printObj.localize_titles['user name'] + ' \n');
								} else {
									builder.addText('User: ' + printObj.user_name + '\n');
								}
							} else if (printObj.bill_no && printObj.bill_no != "NA") {
								builder.addText('Bill No: ' + printObj.bill_no + ' \n');
							}

							if (printObj.checkPrintNumber) {
								builder.addText('Check Print Number: ' + printObj.checkPrintNumber + ' \n');
							}

							if (printObj.enable_tables) {
								let sectionTableStr = "";
								if (printObj.section && printObj.table_no) {
									if (printObj.isTwoLanguageShown && printObj.localize_titles["section"] && printObj.localize_titles["table no"]) {
										sectionTableStr = 'Section: ' + this.capitalizeFirstLetter(printObj.section) + " : " + printObj.localize_titles['section'] + ' \n';
										sectionTableStr += 'Table No: ' + printObj.table_no + " : " + printObj.localize_titles['table no'] + ' \n';
									} else {
										sectionTableStr = 'Section: ' + this.capitalizeFirstLetter(printObj.section) + '  Table No: ' + printObj.table_no + '\n';
									}
								} else if (printObj.section) {
									if (printObj.isTwoLanguageShown && printObj.localize_titles["section"] && printObj.localize_titles["table no"]) {
										sectionTableStr = 'Section: ' + this.capitalizeFirstLetter(printObj.section) + " : " + printObj.localize_titles['section'] + ' \n';
									} else {
										sectionTableStr = 'Section: ' + this.capitalizeFirstLetter(printObj.section) + ' \n'
									}
								} else if (printObj.table_no) {
									if (printObj.isTwoLanguageShown && printObj.localize_titles["section"] && printObj.localize_titles["table no"]) {
										sectionTableStr = 'Table No: ' + printObj.table_no + " : " + printObj.localize_titles['table no'] + ' \n';
									} else {
										sectionTableStr = 'Table No: ' + printObj.table_no + '\n'
									}
								}

								builder.addText(sectionTableStr);
							}

							builder.addTextStyle(false, false, false);

							builder.addText('---------------------------------------------\n');
							builder.addPageBegin();
							if (printObj.isTwoLanguageShown && printObj.localize_titles["particulars"] && printObj.localize_titles["rate"] && printObj.localize_titles["qty"] && printObj.localize_titles["amount"]) {
								builder.addPageArea(20, 0, 530, 60);
								builder.addText("Particulars          Rate      Qty     Amt\n");
								builder.addText(printObj.localize_titles["particulars"] + "          " + printObj.localize_titles["rate"] + "      " + printObj.localize_titles["qty"] + "      " + printObj.localize_titles["amount"] + ' \n');
							} else {
								builder.addPageArea(20, 0, 530, 30);
								builder.addText("Particulars          Rate      Qty     Amt\n");
							}
							builder.addPageEnd();
							builder.addText('---------------------------------------------\n');

							// Add each line item to receipt (function below)
							if (!printObj.print_sectionwise) {
								//normal print (non-sectionwise printing)
								if (printObj.item_wise_tax) {
									//group by tax code and print	            
									printObj.taxCodeWiseItems.forEach((taxType, indx) => {
										if (taxType.code != "no_tax") {
											builder.addPageBegin();
											builder.addPageArea(5, 0, 364, 30);
											builder.addText(taxType.code + " @ " + taxType.rate + "%");
											builder.addPageEnd();
										}

										for (var i = 0; i < taxType.items.length; i++) {
											builder = this.createItemLineV2(builder, taxType.items[i], printObj);
										}

										if (indx != printObj.taxCodeWiseItems.length - 1) {
											builder.addPageBegin();
											builder.addPageArea(5, 0, 364, 30);
											builder.addText("               ---------------");
											builder.addPageEnd();
										}
									})
								} else {
									//print without grouping
									for (var i = 0; i < printObj.cart_items.length; i++) {
										builder = this.createItemLineV2(builder, printObj.cart_items[i], printObj);
									}
								}
							} else {
								printObj.cartSections.forEach((sectionObj, indx) => {
									builder.addText(sectionObj.sectionName.toUpperCase() + ' ITEMS\n');
									if (printObj.item_wise_tax) {
										//group by tax code and print
										sectionObj.taxCodeWiseItems.forEach((taxType, indx) => {
											if (taxType.code != "no_tax") {
												builder.addPageBegin();
												builder.addPageArea(5, 0, 364, 30);
												builder.addText(taxType.code + " @ " + taxType.rate + "%");
												builder.addPageEnd();
											}

											for (var i = 0; i < taxType.items.length; i++) {
												builder = this.createItemLineV2(builder, taxType.items[i], printObj);
											}

											if (indx != sectionObj.taxCodeWiseItems.length - 1) {
												builder.addPageBegin();
												builder.addPageArea(5, 0, 364, 30);
												builder.addText("               ---------------");
												builder.addPageEnd();
											}
										});
									} else {
										//print without grouping
										sectionObj.items.forEach(item => {
											builder = this.createItemLineV2(builder, item, printObj);
										})
									}

									builder = this.createSectionSubTotalLineV2(builder, sectionObj, printObj);
									if (indx != (printObj.cartSections.length - 1)) {
										builder.addTextAlign(builder.ALIGN_CENTER);
										builder.addText('---------------------------------------------\n');
									}
								});
							}

							// Add total row at bottom of receipt	      
							builder.addTextAlign(builder.ALIGN_CENTER);
							builder.addText('---------------------------------------------\n');
							builder.addTextAlign(builder.ALIGN_RIGHT);
							if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["sub total"]) {
								builder.addText('Sub-total: ' + printObj.cart_value.toFixed(printObj.decimalCount) + "    \n " + printObj.localize_titles["sub total"] + '    \n');
							} else {
								builder.addText('Sub-total: ' + printObj.cart_value.toFixed(printObj.decimalCount) + '   \n');
							}

							//tax breakup
							if (printObj.group_taxes) {
								printObj.group_taxes.forEach(tax => {
									let itText = "";
									if (tax.item_count) {
										itText = tax.item_count + " Item" + ((tax.item_count != 1 && tax.item_count != -1) ? "s" : "") + " with ";
									}

									if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1) {
										builder.addText('Includes ' + itText + tax.breakup_name + "@" + tax.rate + "% of " + tax.value.toFixed(printObj.decimalCount) + '    ' + '\n');
									} else {
										builder.addText('Includes ' + itText + tax.breakup_name + "@" + tax.rate + "% of " + tax.value.toFixed(printObj.decimalCount) + '    ' + '\n');
									}
								})
							}

							builder.addTextStyle(false, false, true);
							if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["total"]) {
								builder.addText('TOTAL: ' + printObj.totalPrice.toFixed(printObj.decimalCount) + printObj.currencySymbol + "    \n " + printObj.localize_titles["total"] + '    \n');
							} else {
								builder.addText('TOTAL: ' + printObj.currencySymbol + " " + printObj.totalPrice.toFixed(printObj.decimalCount) + '   ' + '\n'); //seatPrices.total
							}
							builder.addTextStyle(false, false, false);
							builder.addTextFont(builder.FONT_B);

							if (Array.isArray(printObj.splitPaymentList) && printObj.splitPaymentList.length > 0) {
								printObj.splitPaymentList.forEach((sp: any) => {
									let tN: any = sp.name ? sp.name : sp.sub_class_name ? sp.sub_class_name : "";
									builder.addText(tN + ': ' + sp.amount.toFixed(printObj.decimalCount) + "   \n ");
								})
							}

							//overall discount
							if (printObj.discount && printObj.discountUnit) {
								if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["discount"]) {
									if (printObj.discountUnit == '%') {
										builder.addText('Includes DISCOUNT @' + printObj.discount + "% of " + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["discount"] + '    \n');
									} else {
										builder.addText('Includes DISCOUNT of ' + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["discount"] + '    \n');
									}
								} else {
									if (printObj.discountUnit == '%') {
										builder.addText('Includes DISCOUNT @' + printObj.discount + "% of " + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
									} else {
										builder.addText('Includes DISCOUNT of ' + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
									}
								}
							}

							//overall discount
							if (printObj.discount && printObj.discountUnit) {
								if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["discount"]) {
									if (printObj.discountUnit == '%') {
										builder.addText('Includes DISCOUNT @' + printObj.discount + "% of " + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["discount"] + '    \n');
									} else {
										builder.addText('Includes DISCOUNT of ' + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["discount"] + '    \n');
									}
								} else {
									if (printObj.discountUnit == '%') {
										builder.addText('Includes DISCOUNT @' + printObj.discount + "% of " + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
									} else {
										builder.addText('Includes DISCOUNT of ' + printObj.discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
									}
								}
							}

							//other discounts on subtotal
							if (printObj.on_subtotal_other_discounts) {
								printObj.on_subtotal_other_discounts.forEach(dValue => {
									if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1) {
										builder.addText(dValue.name + " " + Math.abs(dValue.value).toFixed(printObj.decimalCount) + '    ' + '\n');
									} else {
										builder.addText(dValue.name + " " + Math.abs(dValue.value).toFixed(printObj.decimalCount) + '    ' + '\n');
									}
								});
							}

							//extra charges
							if (printObj.extraCharges) {
								printObj.extraCharges.forEach(ec => {
									if (ec.value > 0) {
										if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1) {
											builder.addText('Includes ' + ec.charge_name.toUpperCase() + ' of: ' + ec.value.toFixed(printObj.decimalCount) + '    ' + '\n');
										} else {
											builder.addText('Includes ' + ec.charge_name.toUpperCase() + ' of: ' + ec.value.toFixed(printObj.decimalCount) + '    ' + '\n');
										}
									}
								})
							}


							//cash discount
							if (printObj.cash_discount && printObj.cashDiscountUnit) {
								if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["cash discount"]) {
									if (printObj.discountUnit == '%') {
										builder.addText('Includes CASH DISCOUNT @' + printObj.cash_discount + "% of " + printObj.cash_discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["cash discount"] + '    \n');
									} else {
										builder.addText('Includes CASH DISCOUNT of ' + printObj.cash_discount_amount.toFixed(printObj.decimalCount) + '    ' + " \n " + printObj.localize_titles["cash discount"] + '    \n');
									}
								} else {
									if (printObj.cashDiscountUnit == '%') {
										builder.addText('Includes CASH DISCOUNT @' + printObj.cash_discount + "% of " + printObj.cash_discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
									} else {
										builder.addText('Includes CASH DISCOUNT of ' + printObj.cash_discount_amount.toFixed(printObj.decimalCount) + '    ' + '\n');
									}
								}
							}

							//roundoff
							if (printObj.rounding_value != 0) {
								if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["round off"]) {
									builder.addText('Roundoff Amount ' + printObj.rounding_value.toFixed(printObj.decimalCount) + '    \n ' + printObj.localize_titles["round off"] + '    \n');
								} else {
									builder.addText('Roundoff Amount ' + printObj.rounding_value.toFixed(printObj.decimalCount) + '    ' + '\n');
								}
							}

							//credit note amount 
							if (printObj.totalPrice < 0) {
								if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["credit note amount"]) {
									builder.addText('Credit Note Amount ' + printObj.totalPrice.toFixed(printObj.decimalCount) + '    \n ' + printObj.localize_titles["credit note amount"] + '    \n');
								} else {
									builder.addText('Credit Note Amount ' + printObj.totalPrice.toFixed(printObj.decimalCount) + '    ' + '\n');
								}
							}

							// Add sales rep and transaction date/time
							builder.addTextAlign(builder.ALIGN_CENTER);
							builder.addTextFont(builder.FONT_A);
							builder.addText('---------------------------------------------\n');
							builder.addTextFont(builder.FONT_B);

							if (printObj.note) {
								builder.addTextStyle(false, false, true);
								builder.addText(printObj.note + '\n');
								builder.addTextStyle(false, false, false);
							}

							if (printObj.total_discount > 0) {
								builder.addTextStyle(false, false, true);
								if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1) {
									builder.addText('You saved: ' + printObj.total_discount.toFixed(printObj.decimalCount) + ' \n');
								} else {
									builder.addText('You saved: ' + printObj.total_discount.toFixed(printObj.decimalCount) + '\n');
								}
								builder.addTextStyle(false, false, false);

								//discount reason
								if (printObj.discountReason) {
									builder.addText('Discount Reason: ' + printObj.discountReason + '\n');
								}
							}

							//client Gst summary
							if (printObj.show_gst_summary_on_print && printObj.taxesByTaxCode && printObj.taxesByTaxCode.length > 0) {
								builder.addTextFont(builder.FONT_B);
								builder.addTextStyle(false, false, true);
								builder.addText('GST Receipt Summary\n');
								builder.addPageBegin();
								builder.addPageArea(20, 0, 120, 60);
								builder.addText('HSN/SAC Code');
								builder.addPageArea(160, 0, 50, 60);
								builder.addText('GST %');
								builder.addPageArea(230, 0, 50, 30);
								builder.addText('SGST Amt');
								builder.addPageArea(300, 0, 50, 30);
								builder.addText('CGST Amt');
								builder.addPageArea(380, 0, 100, 30);
								builder.addText('IGST Amt');
								builder.addPageEnd();
								builder.addTextStyle(false, false, false);
								printObj.taxesByTaxCode.forEach(tax => {
									builder.addPageBegin();
									builder.addPageArea(20, 0, 120, 60);
									builder.addText(tax.code);
									builder.addPageArea(160, 0, 50, 60);
									builder.addText(tax.rate);
									builder.addPageArea(230, 0, 50, 30);
									if (tax.tax_breakup.SGST) {
										builder.addText(tax.tax_breakup.SGST.toFixed(printObj.decimalCount));
									} else {
										builder.addText('0');
									}
									builder.addPageArea(300, 0, 50, 30);
									if (tax.tax_breakup.CGST) {
										builder.addText(tax.tax_breakup.CGST.toFixed(printObj.decimalCount));
									} else {
										builder.addText('0');
									}
									builder.addPageArea(380, 0, 100, 30);
									if (tax.tax_breakup.IGST) {
										builder.addText(tax.tax_breakup.IGST.toFixed(printObj.decimalCount));
									} else {
										builder.addText('0');
									}
									builder.addPageEnd();
								})
								builder.addTextFont(builder.FONT_A);
								builder.addText('---------------------------------------------\n');
							}
							//client reference code terminal
							if (printObj.client_reference_code && printObj.terminal && printObj.bill_ref_id) {
								if (["ar-qa", "ar-kw"].indexOf(printObj.enabled_language_code) != -1 && printObj.isTwoLanguageShown && printObj.localize_titles["terminal"] && printObj.localize_titles["ref id"]) {
									builder.addText('Terminal: ' + printObj.bill_ref_id + " : " + printObj.localize_titles["terminal"] + ' \n');
									builder.addText('Ref ID: ' + printObj.terminal + " : " + printObj.localize_titles["ref id"] + " \n");
								} else {
									builder.addText('Terminal: ' + printObj.bill_ref_id + '  Ref ID: ' + printObj.terminal + '\n');
								}
							}

							var ipAddr = printerObj.ip_address;
							var address = 'http://' + ipAddr + '/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000';
							var epos = new epson.ePOSPrint(address);
							// Check if printer cover is open
							epos.oncoveropen = function () { alert('coveropen'); };

							// Send print job
							if(printObj['print_config'].footer){
								if (printerObj.print_footer_note_enabled && printerObj.print_footer_note) {
									builder.addText(printerObj.print_footer_note + '\n');
								} else {
									builder.addText('Thank you for your business' + '\n');
									builder.addText('With love from OneGreenDiary' + '\n');
								}
							}

							builder.addPageBegin();
							builder.addPageArea(0, 0, 30, 30);
							builder.addPageLine(0, 0, 0, 100, builder.LINE_THIN);
							builder.addPageEnd();


							// Add 2 line feeds and cut
							builder.addFeed();
							builder.addFeed();
							builder.addCut(builder.CUT_FEED);
							// console.log("builder ",builder.toString());
							epos.send(builder.toString());
	
							try {
								localStorage.removeItem('selected_staff_user');
							} catch (e) {
								console.log(e);
							}
						});
					}
				});

				if (!printerFound) {
					console.log('Printer not found');
					this.messageService.add({ severity: 'success', summary: 'Wifi Printer not found', detail: 'Wifi Printer not found' });
					return;
				}
			} else {
				console.log("Printer data could not be fetched");
				this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
				return;
			}
		} catch (err) {
			console.log(err);
		}
	}
	}

	getPrintLogo(builder, printObj) {
		let self = this;
		return new Promise((resolve, reject) => {
			if(printObj['print_config'].shop_logo){
				if (printObj.print_logo_on_bill && printObj.imgSrc) {
					//console.log(printObj.imgSrc);
					let canvas: any = document.getElementById('canvas');
					let context = canvas.getContext('2d');
					let image = new Image();
					image.onload = function () {
						context.drawImage(image, 40, 10, 500, 230);
						builder.addImage(context, 0, 0, canvas.width, canvas.height, builder.COLOR_1);
						return resolve(true);
					}

					image.onerror = function (error) {
						console.log("on error", error);
						return resolve(true);
					}
					image.crossOrigin = 'anonymous';
					image.src = printObj.imgSrc;
				} else {
					return resolve(false);
				}
			} else {
				return resolve(false);
			}
		});
	}

	//method to print address in proper format (i.e on separate lines and without breaking any words midway)
	printAddress(builder, fullAddress: string) {
		let addressArray = fullAddress.split(' ');
		let addressLine1 = '';
		let addressLine2 = '';
		let addressLine3 = '';
		let j = -1;
		let k = -1;

		//creating address line 1
		addressArray.some((element, i) => {
			if (addressLine1.length < 48 && (addressLine1.length + element.length) <= 48) {
				addressLine1 = addressLine1 + element + ' ';
				return false;
			} else {
				j = i;
				return true;
			}
		})

		builder.addText(addressLine1.trim() + '\n');

		//creating address line 2 (if line 1 can't accomodate the entire address)
		if (j > 0) {
			for (; j < addressArray.length; j++) {
				if (addressLine2.length < 48 && (addressLine2.length + addressArray[j].length) <= 48) {
					addressLine2 = addressLine2 + addressArray[j] + ' ';
				} else {
					k = j;
					break;
				}
			}
			builder.addText(addressLine2.trim() + '\n');
		}

		//creating address line 3 (if line 1 & 2 can't accomodate the entire address)
		if (k > 0) {
			for (; k < addressArray.length; k++) {
				if (addressLine3.length < 48 && (addressLine3.length + addressArray[k].length) <= 48) {
					addressLine3 = addressLine3 + addressArray[k] + ' ';
				}
			}
			builder.addText(addressLine3.trim() + '\n');
		}

		return builder;
	}

	createItemLineV2(builder: any, item: any, printObj: any) {
		let sellPrice: any = parseFloat(item.unit_price);
		let quantity: any = parseFloat(item.item_count);
		let attributes: any = item.attributes && item.attributes.length > 0 ? item.attributes : [];
		let loose_item_name: any = item.loose_item_name ? item.loose_item_name : "";
		let combo_item_codes: any = item.combo_item_codes && item.combo_item_codes.length > 0 ? item.combo_item_codes : [];
		let included_combo_items: any = item.included_combo_items && item.included_combo_items.length > 0 ? item.included_combo_items : [];

		let productName = this.capitalizeFirstLetter(item.item_name);
		if (this.checkIsSpecialOrder(item) && item.unit) {
			productName += " " + item.unit_value + " " + item.unit;
		}


		builder.addPageBegin();
		if (printObj.isTwoLanguageShown && item.localize_item_name) {
			let dHeight = Math.ceil(productName.length / 20) * 60; //we suppose 20 character will come in one line
			builder.addPageArea(20, 0, 230, dHeight);
			builder.addText(productName + " \n");
			builder.addText(item.localize_item_name);
			builder.addPageArea(260, 0, 100, dHeight);
		} else {
			let dHeight = Math.ceil(productName.length / 20) * 30; //we suppose 20 character will come in one line
			builder.addPageArea(20, 0, 230, dHeight);
			builder.addText(productName);
			builder.addPageArea(260, 0, 100, dHeight);
		}

		var rate;
		if (printObj.item_wise_tax && printObj.show_post_tax_price && item.tax_value) {
			rate = (sellPrice).toFixed(printObj.decimalCount);
		} else {
			rate = sellPrice.toFixed(printObj.decimalCount);
		}

		builder.addText(rate);
		builder.addPageArea(400, 0, 50, 30);
		builder.addText(quantity);
		builder.addPageArea(450, 0, 100, 30);

		var totalPrice = 0;
		sellPrice = sellPrice * quantity;

		if (printObj.item_wise_tax && printObj.show_post_tax_price && item.tax_value) {
			sellPrice = sellPrice + item.tax_value;
		}

		if (item.discount_amount) {
			sellPrice -= item.discount_amount;
		}

		sellPrice -= item.other_discounts_total;
		sellPrice = sellPrice.toFixed(printObj.decimalCount);
		while (sellPrice.length < 7) {
			sellPrice = " " + sellPrice;
		}

		builder.addText(sellPrice);
		builder.addPageEnd();

		if (loose_item_name) {
			builder.addTextAlign(builder.ALIGN_LEFT);
			builder.addTextFont(builder.FONT_C);
			builder.addPageBegin();
			builder.addPageArea(68, 0, 364, 30);
			var tempLooseItemName = this.capitalizeFirstLetter(loose_item_name);
			let adHeight = Math.ceil(tempLooseItemName.length / 21) * 30;
			builder.addText(tempLooseItemName);
			builder.addPageArea(408, 0, 172, adHeight);
			builder.addTextAlign(builder.ALIGN_RIGHT);
			builder.addPageEnd();
			builder.addTextFont(builder.FONT_A);
		}

		if (attributes.length > 0) {
			builder.addTextAlign(builder.ALIGN_LEFT);
			builder.addTextFont(builder.FONT_C);
			for (var attr = 0; attr < attributes.length; attr++) {
				if (attributes[attr].count && attributes[attr].name && attributes[attr].unit_price) {
					builder.addPageBegin();
					builder.addPageArea(68, 0, 364, 30);
					var attrName = this.capitalizeFirstLetter(attributes[attr].name);
					let attrNameAndCountString = attributes[attr].count + "  x " + attrName;
					let adHeight = Math.ceil(attrNameAndCountString.length / 21) * 30;
					builder.addText(attrNameAndCountString);
					builder.addPageArea(408, 0, 172, adHeight);
					builder.addTextAlign(builder.ALIGN_RIGHT);
					sellPrice = attributes[attr].unit_price * attributes[attr].count;
					if (sellPrice > 0) {
						sellPrice = sellPrice.toFixed(printObj.decimalCount);
						do {
							sellPrice = " " + sellPrice;
						} while (sellPrice.length < 14);
						builder.addText(sellPrice);
					}
					builder.addPageEnd();
				}
			}
			builder.addTextFont(builder.FONT_A);
		}
		if (combo_item_codes.length > 0) {
			builder.addTextAlign(builder.ALIGN_LEFT);
			builder.addTextFont(builder.FONT_C);
			for (var attr = 0; attr < combo_item_codes.length; attr++) {
				if (combo_item_codes[attr].quantity_in_combo && combo_item_codes[attr].item_name) {
					builder.addPageBegin();
					builder.addPageArea(68, 0, 364, 30);
					var attrName = this.capitalizeFirstLetter(combo_item_codes[attr].item_name);
					let attrNameAndCountString = combo_item_codes[attr].quantity_in_combo + "  x " + attrName;
					builder.addText(attrNameAndCountString);
					builder.addPageEnd();
				}
			}
			builder.addTextFont(builder.FONT_A);
		}
		if (included_combo_items.length > 0) {
			builder.addTextAlign(builder.ALIGN_LEFT);
			builder.addTextFont(builder.FONT_C);
			for (var attr = 0; attr < included_combo_items.length; attr++) {
				if (included_combo_items[attr].quantity && included_combo_items[attr].item_name) {
					builder.addPageBegin();
					builder.addPageArea(68, 0, 364, 30);
					var attrName = this.capitalizeFirstLetter(included_combo_items[attr].item_name);
					let attrNameAndCountString = included_combo_items[attr].quantity + "  x " + attrName;
					builder.addText(attrNameAndCountString);
					builder.addPageEnd();
				}
			}
			builder.addTextFont(builder.FONT_A);
		}

		if (item.discount_amount) {
			builder.addTextFont(builder.FONT_C);
			builder.addPageBegin();
			builder.addPageArea(68, 0, 364, 30);
			if (item.discountUnits == "%") {
				builder.addText("Discount @ " + item.discount + "%");
			} else {
				builder.addText("Discount");
			}
			builder.addPageArea(408, 0, 172, 30);
			builder.addTextAlign(builder.ALIGN_RIGHT);
			var discount_amount = "-" + item.discount_amount.toFixed(printObj.decimalCount);
			do {
				discount_amount = " " + discount_amount;
			} while (discount_amount.length < 14);
			builder.addText(discount_amount);
			builder.addPageEnd();
			builder.addTextFont(builder.FONT_A);
		}

		if (item.other_discounts.length > 0) {
			item.other_discounts.forEach(discount => {
				builder.addTextFont(builder.FONT_C);
				builder.addPageBegin();
				builder.addPageArea(68, 0, 364, 30);
				if (discount.discount_type == "%") {
					builder.addText(discount.discount_code + " @ " + discount.discount + "%");
				} else {
					builder.addText(discount.discount_code);
				}
				builder.addPageArea(408, 0, 172, 30);
				builder.addTextAlign(builder.ALIGN_RIGHT);
				var discount_amount = "-" + discount.amount.toFixed(printObj.decimalCount);
				do {
					discount_amount = " " + discount_amount;
				} while (discount_amount.length < 14);
				builder.addText(discount_amount);
				builder.addPageEnd();
				builder.addTextFont(builder.FONT_A);
			})
		}

		if (item.special_order_charges) {
			builder.addTextFont(builder.FONT_C);
			builder.addPageBegin();
			builder.addPageArea(68, 0, 364, 30);
			builder.addText("Sp. Order Charge");
			builder.addPageArea(408, 0, 172, 30);
			builder.addTextAlign(builder.ALIGN_RIGHT);
			var special_order_charges = "+" + item.special_order_charges.toFixed(printObj.decimalCount);
			do {
				special_order_charges = " " + special_order_charges;
			} while (special_order_charges.length < 14);
			builder.addText(special_order_charges);
			builder.addPageEnd();
			builder.addTextFont(builder.FONT_A);
		}

		if (item.note) {
			builder.addTextFont(builder.FONT_C);
			builder.addPageBegin();
			let noteString = "--" + item.note;
			let ndHeight = Math.ceil(noteString.length / 21) * 30;
			builder.addPageArea(30, 0, 364, ndHeight);
			builder.addText(noteString);
			builder.addPageEnd();
			builder.addTextFont(builder.FONT_A);
		}

		return builder;
	}

	createSectionSubTotalLineV2(builder: any, sectionObj, printObj) {
		// var cartSectionsObj = {
		//   sectionName: "",
		//   itemCount: 0,
		//   subTotal: 0,
		//   items: []
		// };
		builder.addTextAlign(builder.ALIGN_CENTER);
		builder.addText('---------------------------------------------\n');
		builder.addPageBegin();
		builder.addPageArea(20, 0, 55, 30);
		builder.addTextStyle(false, false, true);
		builder.addPageArea(70, 0, 500, 30);
		builder.addTextAlign(builder.ALIGN_RIGHT);

		sectionObj.subTotal = sectionObj.subTotal.toFixed(printObj.decimalCount);
		var subtotalLine = sectionObj.sectionName.toUpperCase() + " Sub-total: " + sectionObj.subTotal;
		while (subtotalLine.length < 39) {
			subtotalLine = " " + subtotalLine;
		}

		builder.addText(subtotalLine + "\n");
		builder.addTextStyle(false, false, false);
		builder.addPageEnd();
		return builder;
	}

	capitalizeFirstLetter(string: string) {
		return string.trim().charAt(0).toUpperCase() + string.trim().slice(1).toLowerCase();
	}


	fireCustomerAddressPrint(printData) {
		//data= {customer_name:"",customer_phone:"",customer_address:"",bill_number:""}
		if (printData.customer_name) {
			printData.customer_name = this.capitalizeFirstLetter(printData.customer_name)
		}

		if (printData.customer_address) {
			printData.customer_address = this.capitalizeFirstLetter(printData.customer_address)
		}

		let printerData = JSON.parse(localStorage.getItem('shop_printers'));
		//flags to check if there is/are Wifi and/or USB printer/s with receipt or self-service flags on
		let wifiPrinterPresent = false;
		let usbPrinterPresent = false;
		let selectedUSBPrinter;
		if (printerData) {
			printerData.forEach(printerObj => {
				if (printerObj.ip_address && printerObj.ip_address != "" && printerObj.ip_address != null && (printerObj.print_type.indexOf('receipt') != -1 || printerObj.print_type.indexOf('self_service') != -1)) {
					wifiPrinterPresent = true;
				} else if ((!printerObj.ip_address || printerObj.ip_address == "" || printerObj.ip_address == null) && (printerObj.print_type.indexOf('receipt') != -1 || printerObj.print_type.indexOf('self_service') != -1)) {
					if (!selectedUSBPrinter) { selectedUSBPrinter = printerObj; }
					usbPrinterPresent = true;
				}
			})

			if (wifiPrinterPresent) {
				this.customerAddressPrintOnWifiPrinter(printData);
			} else if (usbPrinterPresent) {
				this.customerAddressUSBPrint(printData);
			}

			if (!wifiPrinterPresent && !usbPrinterPresent) {
				alert("No printer found for check print");
			}
			/*} else {
				return resolve(true);
			}*/
		} else {
			alert("Printer data could not be fetched at this time. Please logout and login again.");
		}
	}


	customerAddressUSBPrint(printData) {
		var str2 = `
	      	<table style="text-align: center; width: 100%; font-family: sans-serif;">
		      <tbody>
		        <tr>
		          <td>
		            <table cellpadding="10px" border="1px" style="text-align: left; width: 100%; max-width: 700px; border: 0px solid #ccc; margin: auto;">
		              <tbody>
		                <tr>
		                	<td style="width: 120px;">DELIVER TO - </td>
		                  	<td colspan="3">
		                  		{{? it.customer_name}}
		                  			{{=it.customer_name}}		                          
		                        {{?}}
		                  	</td>
		                </tr>
		                <tr>
		                	<td  style="width: 120px;">ADDRESS - </td>
		                  	<td colspan="3">
		                  		{{? it.customer_address}}
		                  			<b>{{=it.customer_address}}</b>
		                        {{?}}
		                  	</td>
		                </tr>
		                <tr>
		                	<td  style="width: 120px;">CONTACT NO. - </td>
		                  	<td>
		                  		{{? it.customer_phone}}
		                  			{{=it.customer_phone}}		                          
		                        {{?}}
		                  	</td>
		                	<td  style="width: 120px;">BILL NO. - </td>		                  	
		                  	<td>
		                  		{{? it.bill_number}}
		                  			{{=it.bill_number}}		                          
		                        {{?}}
		                  	</td>
		                </tr>
		              </tbody>
		            <table>
		          </td>
		        </tr>
		      </tbody>
	    	</table><!-- Generall Wrapper -->
	    `;

		try {
			var tempFn = dot.template(str2);
			var printContents = tempFn(printData);
		} catch (e) {
			console.log(e);
		}

		try {
			let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
			popupWin.document.open();

			popupWin.document.write(`
	            <html>
	                <head>
	                    <title>Print tab</title>
	                    <style>
	              .marginValues{
	                margin-top:0px !important;
	              }
	            .item{
	              float:left !important;
	            }
	             .text{
	                          text-transform: capitalize !important
	                      }
	              .kot-modal-bg {
	                  background-color: lightgray;
	              }
	            .print{
	              font-family:monospace;
	              text-align:center !important;
	            }
	            .kot-print {
	              font-size:20px;
	              font-family:monospace;
	            }
	            .align-right{
	              tax-align:right !important;
	            }
	                    </style>
	                    <script type="text/javascript">
                          function onload() {
                          	setTimeout(function(){window.print(); window.close();}, 200); 
                            /*window.print();
                            setTimeout(function(){window.close();}, 1000);*/
                          }
                        </script>
	                </head>
	                <body onload='onload();'>${printContents}
	                </body>
	            </html>`
			);
			popupWin.document.close();
		} catch (e) {
			alert("please enable popup to show print")
		}
	}

	customerAddressPrintOnWifiPrinter(printData) {
		if (!printData.customer_name) { printData.customer_name = ""; }
		if (!printData.customer_phone) { printData.customer_phone = ""; }
		if (!printData.customer_address) { printData.customer_address = ""; }
		if (!printData.bill_number) { printData.bill_number = ""; }

		let builder = new epson.ePOSBuilder();
		builder.addTextAlign(builder.ALIGN_LEFT);
		builder.addText('DELIVERY TO - ');
		builder.addTextAlign(builder.ALIGN_LEFT);
		builder.addTextStyle(false, false, true, builder.COLOR_1);
		builder.addText(printData.customer_name + ' \n');
		builder.addTextAlign(builder.ALIGN_LEFT);
		builder.addTextStyle(false, false, false, builder.COLOR_1);
		builder.addText('Address - ');
		builder.addTextAlign(builder.ALIGN_LEFT);
		builder.addTextStyle(false, false, true, builder.COLOR_1);
		builder.addText(printData.customer_address + '\n');
		builder.addTextStyle(false, false, false, builder.COLOR_1);
		builder.addTextAlign(builder.ALIGN_LEFT);
		builder.addText('CONTACT NO. - ');
		builder.addTextAlign(builder.ALIGN_LEFT);
		builder.addTextStyle(false, false, true, builder.COLOR_1);
		builder.addText(printData.customer_phone + '\n');
		builder.addTextStyle(false, false, false, builder.COLOR_1);
		builder.addTextAlign(builder.ALIGN_LEFT);
		builder.addText('BILL NO. - ');
		builder.addTextAlign(builder.ALIGN_LEFT);
		builder.addTextStyle(false, false, true, builder.COLOR_1);
		builder.addText(printData.bill_number + '\n');

		// Add 2 line feeds and cut
		builder.addFeed();
		builder.addFeed();
		builder.addCut(builder.CUT_FEED);
		let printerData = JSON.parse(localStorage.getItem("shop_printers"));
		if (printerData) {
			var printers_list = printerData;
			var printerFound = false;
			printers_list.forEach(printerObj => {
				if (
					printerObj.ip_address &&
					printerObj.ip_address != "" &&
					(printerObj.kitchen_department.trim() == "" ||
						printerObj.kitchen_department == null) &&
					(printerObj.print_type.indexOf("receipt") != -1 ||
						printerObj.print_type.indexOf("self_service") != -1)
				) {

					printerFound = true;
					var ipAddr = printerObj.ip_address;
					console.log("ipAddr:" + ipAddr);
					var address = "http://" + ipAddr + "/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000";
					console.log("ADDRESS :: " + address);
					var epos = new epson.ePOSPrint(address);

					// Check if printer cover is open
					epos.oncoveropen = function () {
						alert("coveropen");
					};
					// Send print job
					console.log("builder object " + builder.toString());
					try {
						epos.send(builder.toString());
					} catch (e) {
						console.log(e);
					}
				}
			})
		}
	}
}

export interface PrintBillUtility {
	printerName: string;
	billJson: any;
}
