/**
 * (c) OneGreenDiary Software Pvt. Ltd.
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform,
 *    solely reside with the third-party.
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Once OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without
 *    prior agreement with OneGreenDiary Software Pvt. Ltd.
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */

import { Component, OnInit } from '@angular/core';
import { StateGuardService } from '../state-guard.service';
import { SeparateBillHelper } from './Separate-bill-helper';
import { remove } from 'lodash-es';

@Component({
  selector: 'discount-helper',
  template: ``,
})

export class DiscountHelper {

	constructor(public stateGuard: StateGuardService){
    }

	public getDisountInfo(subtotal,cart_items,is_different_tax_classes_item_available,is_membership_item_available) {
		let subTotalCopy = JSON.parse(JSON.stringify(subtotal));
		let all_discount_list = JSON.parse(localStorage.getItem('discount_list')) || [];
		let discount_list = all_discount_list.filter(it => {
			var isExpired = true;
            if( !is_membership_item_available && it.is_deleted == 0 &&  it.discount_specifics.type !== 'cashback' && it.discount_specifics && it.discount_specifics.is_auto_apply) {

                //check coupon is not expired
            	if(it.discount_specifics.start_date && it.discount_specifics.end_date
                    && new Date(it.discount_specifics.start_date) <= new Date()
                    && new Date(it.discount_specifics.end_date) >= new Date()) {

                    //check coupon used in usage time period or not
                    if(it.discount_specifics.restrictions && Array.isArray(it.discount_specifics.restrictions.usage_time)) {
                        var usage_time = it.discount_specifics.restrictions.usage_time;
                        for(var i=0;i<usage_time.length;i++) {
                            if(usage_time[i] && usage_time[i].start_time && usage_time[i].end_time) {

                                var order_time = new Date().toTimeString().split(" ")[0].split(":");
                                var order_time_in_sec = (parseInt(order_time[0])*3600)+(parseInt(order_time[1])*60)+(parseInt(order_time[2]));
                                var temp_offer_start_time = usage_time[i].start_time.split(":");
                                var start_time_in_sec = (parseInt(temp_offer_start_time[0])*3600)+(parseInt(temp_offer_start_time[1])*60)+(parseInt(temp_offer_start_time[2]));
                                var temp_offer_end_time = usage_time[i].end_time.split(":");
                                var end_time_in_sec = (parseInt(temp_offer_end_time[0])*3600)+(parseInt(temp_offer_end_time[1])*60)+(parseInt(temp_offer_end_time[2]));
                                if(start_time_in_sec <= order_time_in_sec && end_time_in_sec >= order_time_in_sec) {
                                    isExpired = false;
                                    break;
                                }
                            }
                        }
                    } else {
                        isExpired = false;
                    }
                }
            }
			return !isExpired;
		});

		var is_externalDiscountApplied = false;
		var is_FoundExternalDiscount = false;

    	var tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};

    	if(tempUnit && tempUnit.temp_external_discount_list && tempUnit.temp_external_discount_list){
    		tempUnit.temp_external_discount_list.forEach(d =>{
    			d['is_external'] = true;
    			is_FoundExternalDiscount = true;
    			discount_list.push(d);
    		});
    	}

    	if(tempUnit && tempUnit.external_discount_list && tempUnit.external_discount_list){
    		tempUnit.external_discount_list.forEach(d =>{
    			d['is_external'] = true;
    			discount_list.push(d);
    		});
    	}

		/*let discount_list = all_discount_list.filter(it => {
			return (it.discount_specifics && it.discount_specifics.is_auto_apply && it.discount_specifics.type == 'flat_offer')
		});*/
		let appliedDiscount:any = {
			bogo_discount : [],
			bogo_any_discount : [],
			regular_discount : []
		}

    	let shipping_type = tempUnit.delivery_type;
    	//check for shipping type.
    	//check if enable_separate_bill_for_tax_classes is on and cart has 2 different item then remove discount which has flat value
		discount_list = discount_list.filter(dil => {

			if(is_different_tax_classes_item_available
				&& ((dil.discount_specifics.type == "flat_offer" && !dil.discount_specifics.restrictions.is_apply_per_item) || (dil.discount_specifics.type != "flat_offer"))
	    		&& ((['flat_offer','regular_discount','cashback'].indexOf(dil.discount_specifics.type) != -1
	    		&& dil.discount_specifics.amount_mode == 'fixed') ||
	    		( ['bogo','bogo_any','combo'].indexOf(dil.discount_specifics.type) != -1)
	    		)) {
	    		return false;
	    	} else {
	    		return (dil.discount_specifics.restrictions.shipping_types == 'no' || (
		                    			Array.isArray(dil.discount_specifics.restrictions.shipping_types) && dil.discount_specifics.restrictions.shipping_types.indexOf(shipping_type) != -1))
	    	}
		});


		var discount_usage_rank = {"bogo":1,"bogo_any":2,"flat_offer":3,"combo":4,"regular_discount":5,"cashback":6,'discount_on_order':7};
		discount_list = discount_list.sort((a,b) => { return discount_usage_rank[a.discount_specifics.type]-discount_usage_rank[b.discount_specifics.type] })
		var item_code_wise_cart_item_for_combo = {};
        var is_max_limit = false;
    	cart_items.forEach(cti => {
    		if(item_code_wise_cart_item_for_combo[cti.item_code]) {
    			item_code_wise_cart_item_for_combo[cti.item_code].qty += cti.item_count;
    		} else {
				item_code_wise_cart_item_for_combo[cti.item_code] = {
					qty:cti.item_count,
					item_price:cti.item_price
				};
    		}
    	});
    	var temp_dl = [];

    	// logic start for handle the same item available in more then 2 combo discount
    	//this is handling to choose same item need to give which discount first. 
    	//like it1 ,it2, it3  where it1 and it3 is in discount 1 and it1 and it2 is in discount 2. 
    	//now user add first it1 and it2 item so first discount is discount 2 applied
    	//then user add it 3 item then discount will not change to discount 1 it will remain discount 2 even it1 and it3 combo discount condition matches
    	//(basically which combo discount is added first same item count use for that same item)
    	var is_combo_found_in_other_discounts = 0;
    	if(tempUnit && Array.isArray(tempUnit.other_discounts) && discount_list.length>0) {
    		var odLen = tempUnit.other_discounts.length;
    		var dLen = discount_list.length;
    		for(var i=0;i<odLen;i++) {
    			if(tempUnit.other_discounts[i].type == "combo") {
	    			for(var j=0;j<dLen;j++) {
	    				if(tempUnit.other_discounts[i].name == discount_list[j].discount_code) {
	    					is_combo_found_in_other_discounts = 1;
	    					temp_dl.push(discount_list[j])
	    					break;
	    				}
	    			}
    			}
    		}
    	}
    	if(is_combo_found_in_other_discounts) {
	    	discount_list.forEach(dl => {
	    		var fdata = temp_dl.filter(td => {return td.discount_code == dl.discount_code});
	    		if(fdata.length==0) {
	    			temp_dl.push(dl);
	    		}
	    	});

	    	discount_list = temp_dl;
    	}
    	// logic end for combo
		discount_list.forEach((dil) => {

			var is_restrict = 0;
    		dil.applied_discount = 0;

    		var temp_required_products = dil.discount_specifics.restrictions.required_products;
	        var products = dil.discount_specifics.restrictions.products;
	        var category = dil.discount_specifics.restrictions.category;
	        var quantity = dil.discount_specifics.restrictions.quantity;

	        var required_products = (temp_required_products && temp_required_products != "no") ? temp_required_products : null;
            var PIA = (products && (Array.isArray(products.include))) ? products.include : [];
            var PEA = (products && (Array.isArray(products.exclude))) ? products.exclude : [];
            var CIA = (category && (Array.isArray(category.include))) ? category.include : [];
            var CEA = (category && (Array.isArray(category.exclude))) ? category.exclude : [];
    		if(dil.discount_specifics.type == 'bogo' && dil.discount_specifics.restrictions){
    			var clubbed_cart_items = this.clubbedCartItemsForItemCount(JSON.parse(JSON.stringify(cart_items)));
		        clubbed_cart_items.forEach(cti => {
		            if(required_products){
		                if(required_products[cti.item_code] && required_products[cti.item_code].qty) {
		                    if(required_products[cti.item_code].qty <= cti.item_count) {

		                        if(PIA.length>0 && CIA.length>0 && quantity && quantity != "no" &&
	                        			parseFloat(quantity[PIA[0]]) > 0) {

		                        	let q = Math.floor(cti.item_count/required_products[cti.item_code].qty);
		                        	let tempData = {
		                        		item_code : PIA[0],
		                        		category_code : CIA[0],
		                        		item_count : (quantity[PIA[0]] * q),
		                        		name : dil.discount_code,
		                        		type : 'bogo',
		                        		is_external : false,
		                        		id : dil.id,
	                    				is_exclusive : dil.discount_specifics.is_exclusive? dil.discount_specifics.is_exclusive : false,
		                        	}
		                        	appliedDiscount.bogo_discount.push(tempData);
		                        }
		                    }
		                }
		                is_restrict = 1;
		            }
		        });
			}else if(dil.discount_specifics.type == 'bogo_any' && dil.discount_specifics.restrictions){
		      	var BIIC = dil.discount_specifics.restrictions.buy_products.include_items;//buy_include_items_codes
		      	var BEIC = dil.discount_specifics.restrictions.buy_products.exclude_items;//buy_exclude_items_codes

		      	var BICC = dil.discount_specifics.restrictions.buy_products.include_category;//buy_include_category_codes
		      	var BECC = dil.discount_specifics.restrictions.buy_products.exclude_category;//buy_exclude_category_codes

		      	var GIIC = dil.discount_specifics.restrictions.get_products.include_items;//get_include_items_codes
		      	var GEIC = dil.discount_specifics.restrictions.get_products.exclude_items;//get_exclude_items_codes

		      	var GICC = dil.discount_specifics.restrictions.get_products.include_category;//get_include_category_codes
		      	var GECC = dil.discount_specifics.restrictions.get_products.exclude_category;//get_exclude_category_codes

		      	var matched_get_items = [];
		      	var buy_qty = dil.discount_specifics.restrictions.buy_products.qty;
		      	var temp_buy_qty = 0;
		      	var matched_item =  {};
		      	var clubbed_cart_items = this.clubbedCartItemsForItemCount(JSON.parse(JSON.stringify(cart_items)));
		        clubbed_cart_items.forEach(cti => {
		            let buy_item_code_match = false;
					let buy_category_code_match = false;

					if((BIIC.length == 0 && BEIC == 0) ||
						(BIIC.length>0 && BIIC.indexOf(cti.item_code) != -1) ||
						(BEIC.length>0 && BEIC.indexOf(cti.item_code) == -1)
						) {
						buy_item_code_match = true;
					}

					if((BICC.length == 0 && BECC == 0) ||
						(BICC.length>0 && BICC.indexOf(cti.category_code) != -1) ||
						(BECC.length>0 && BECC.indexOf(cti.category_code) == -1)
						) {
						buy_category_code_match = true;
					}

					if(buy_item_code_match && buy_category_code_match) {						
						matched_item[cti.item_code] = cti.item_count;
						temp_buy_qty += cti.item_count;
					}
		        });
		        if(temp_buy_qty >= buy_qty) {
			        cart_items.forEach(cti => {

						let get_item_code_match = false;
						let get_category_code_match = false;

						if((GIIC.length == 0 && GEIC == 0) ||
							(GIIC.length>0 && GIIC.indexOf(cti.item_code) != -1) ||
							(GEIC.length>0 && GEIC.indexOf(cti.item_code) == -1)
							) {
							get_item_code_match = true;
						}

						if((GICC.length == 0 && GECC == 0) ||
							(GICC.length>0 && GICC.indexOf(cti.category_code) != -1) ||
							(GECC.length>0 && GECC.indexOf(cti.category_code) == -1)
							) {
							get_category_code_match = true;
						}

						if(get_item_code_match && get_category_code_match) {
							//matched_item[cti.item_code] = cti.item_count;
							if(!matched_item[cti.item_code]) {
								matched_get_items.push(cti);
							}
						}
			        });
		    	}

				if(temp_buy_qty >= buy_qty && matched_get_items.length > 0){
					var temp_get_qty = 0;
					var temp_qty = 0;
					var temp_only_get = 0;
					var discount_val = 0;
					var get_qty = dil.discount_specifics.restrictions.get_products.qty * Math.floor(temp_buy_qty/buy_qty);
					var sorted_matched_item = matched_get_items.sort((a,b) => {return a.item_price-b.item_price});
					sorted_matched_item.forEach(sortedItem => {
						var ti = sortedItem;
						if(matched_item[ti.item_code]) {
							temp_qty += matched_item[ti.item_code];
						} else {
							temp_only_get += ti.item_count;
						}
					});

					var remain_qty = get_qty - temp_only_get;
					if((get_qty-temp_only_get) < 0) {
						var buy_get_count = 0;
						for(var i=0;i<temp_qty;i += buy_qty) {
							if((temp_qty - buy_get_count -((buy_get_count+temp_only_get) * buy_qty)) >= 0) {
								buy_get_count += dil.discount_specifics.restrictions.get_products.qty;
							}
						}

						if(buy_get_count > 0) {
							get_qty = dil.discount_specifics.restrictions.get_products.qty * Math.floor((Math.abs(temp_buy_qty-temp_qty)/buy_qty)) + buy_get_count;
						}
					}
					
					for(var i=0;i<sorted_matched_item.length;i++) {
						var ti = sorted_matched_item[i];
						if(temp_get_qty < get_qty) {
							if((temp_get_qty+ti.item_count) > get_qty) {
								discount_val += (get_qty-temp_get_qty) * ti.item_price;
								temp_get_qty += (get_qty-temp_get_qty);
							} else {
								discount_val += ti.item_count * ti.item_price;
								temp_get_qty += ti.item_count;
							}
						} else {
							break;
						}
					}

					var total_eligible_item = 0;
					for(var x in matched_item) { total_eligible_item+= matched_item[x]; }
					if(discount_val > 0 && total_eligible_item>=buy_qty){
						if(dil.max_discount) { discount_val = (discount_val > dil.max_discount) ? dil.max_discount : discount_val};
	                	let tempData = {
	                		name : dil.discount_code,
	                		type : 'bogo_any',
	                		value : discount_val,
	                		is_external : false,
	                		id : dil.id,
	        				is_exclusive : dil.discount_specifics.is_exclusive? dil.discount_specifics.is_exclusive : false,
	                	}
	                	appliedDiscount.bogo_any_discount.push(tempData);
                	}
				}
			}else if(dil.discount_specifics.type == 'discount_on_order' &&
						dil.discount_specifics.restrictions){
				var minimum_cart_value = ((dil.discount_specifics.restrictions.minimum_cart_value || dil.discount_specifics.restrictions.minimum_cart_value == 0) && dil.discount_specifics.restrictions.minimum_cart_value != "no")  ? dil.discount_specifics.restrictions.minimum_cart_value : 0;
				var maximum_cart_value = ((dil.discount_specifics.restrictions.maximum_cart_value || dil.discount_specifics.restrictions.maximum_cart_value == 0) && dil.discount_specifics.restrictions.maximum_cart_value != "no")  ? dil.discount_specifics.restrictions.maximum_cart_value : 10000000;
				if(subtotal !=0 && subtotal >= minimum_cart_value && subtotal<=maximum_cart_value){
					let is_found = false;
					let discount_val = dil.discount_specifics.amount;
					let name = dil.discount_code;

					if(discount_val){
						let f_data = appliedDiscount.regular_discount.filter(k => k.name == name);
						if(f_data.length == 0){
							let tempData = {
	                    		name : name,
	                    		type : 'discount_on_order',
	                    		value : (discount_val * (-1)),
	                    		is_external : dil.is_external ? dil.is_external : false,
	                    		id : dil.id,
	                    		is_max_discount_val : false,
	                    		discount_order_id:dil.discount_order_id,
	                    		is_exclusive : dil.discount_specifics.is_exclusive? dil.discount_specifics.is_exclusive : false,
	                    	}
	                    	appliedDiscount.regular_discount.push(tempData);
						}else{
							appliedDiscount.regular_discount.forEach(d =>{
								d.value = (discount_val * (-1));
							})
						}
					}
				}
            }else if(dil.discount_specifics.type == 'regular_discount' &&
						dil.discount_specifics.restrictions){
				var minimum_cart_value = ((dil.discount_specifics.restrictions.minimum_cart_value || dil.discount_specifics.restrictions.minimum_cart_value == 0) && dil.discount_specifics.restrictions.minimum_cart_value != "no")  ? dil.discount_specifics.restrictions.minimum_cart_value : 0;
				var tempSubTotal = ((dil.discount_specifics.is_exclusive) ? subTotalCopy : subtotal);
				if(tempSubTotal !=0 && tempSubTotal >= minimum_cart_value) {
					let is_found = false;
					let discount_val = 0;
					let is_max_limit = false;
					let name = dil.discount_code;

					if(dil.discount_specifics.amount_mode == 'percent'){
						discount_val = (tempSubTotal * dil.discount_specifics.amount * 0.01);
						if(discount_val > dil.discount_specifics.restrictions.usage_value_per_order){
							discount_val = dil.discount_specifics.restrictions.usage_value_per_order;
							is_max_limit = true;
						}
						//name = name + " @ "+dil.discount_specifics.amount+"%";

					}else if(dil.discount_specifics.amount_mode == 'fixed'){
						discount_val = dil.discount_specifics.amount;
					}
					if(discount_val){
						if(dil.max_discount && discount_val > dil.max_discount) {
							discount_val = dil.max_discount;
							is_max_limit = true;
						};
						let f_data = appliedDiscount.regular_discount.filter(k => k.name == name);
						if(f_data.length == 0){
							let tempData = {
	                    		name : name,
	                    		type : 'regular_discount',
	                    		value : (discount_val * (-1)),
	                    		is_external : dil.is_external ? dil.is_external : false,
	                    		id : dil.id,
	                    		is_max_discount_val : is_max_limit,
	                    		is_exclusive : dil.discount_specifics.is_exclusive? dil.discount_specifics.is_exclusive : false,
	                    	}
	                    	appliedDiscount.regular_discount.push(tempData);
						}else{
							appliedDiscount.regular_discount.forEach(d =>{
								d.value = (discount_val * (-1));
							})
						}
					}
				}
            }else if(dil.discount_specifics.type == 'flat_offer' &&
						dil.discount_specifics.restrictions){

    			var item_discounts_info = [];
            	var total_purchased_item = 0;
                var total_purchase_item_wise_count = {};
                var is_max_limit = false;

            	cart_items.forEach(cti => { total_purchased_item += cti.item_count; });
            	var minimum_cart_value = ((dil.discount_specifics.restrictions.minimum_cart_value || dil.discount_specifics.restrictions.minimum_cart_value == 0) && dil.discount_specifics.restrictions.minimum_cart_value != "no")  ? dil.discount_specifics.restrictions.minimum_cart_value : 0;
            	var minimum_item_purchase = ((dil.discount_specifics.restrictions.minimum_item_purchase || dil.discount_specifics.restrictions.minimum_item_purchase == 0) && dil.discount_specifics.restrictions.minimum_item_purchase != "no")  ? dil.discount_specifics.restrictions.minimum_item_purchase : 0;
				var maximum_item_purchase = ((dil.discount_specifics.restrictions.maximum_item_purchase || dil.discount_specifics.restrictions.maximum_item_purchase == 0) && dil.discount_specifics.restrictions.maximum_item_purchase != "no")  ? dil.discount_specifics.restrictions.maximum_item_purchase : 10000000;

				var tempSubTotal = ((dil.discount_specifics.is_exclusive) ? subTotalCopy : subtotal);				
				if(tempSubTotal != 0 && tempSubTotal >= dil.discount_specifics.restrictions.minimum_cart_value){
					let discount_val = 0;
					var temp_total_item_count = JSON.parse(JSON.stringify(total_purchased_item))
					if(PIA.length == 0 && PEA.length == 0 && CIA.length == 0 && CEA.length == 0 && !dil.discount_specifics.restrictions.is_apply_per_item) {
						if(dil.discount_specifics.amount_mode == 'percent'){
							discount_val += (dil.discount_specifics.amount * 0.01) * tempSubTotal;//(subtotal * dil.discount_specifics.amount * 0.01);
						}else if(dil.discount_specifics.amount_mode == 'fixed'){
							discount_val += (dil.discount_specifics.amount);
						}

						
					} else {
						temp_total_item_count = 0;
						cart_items.forEach(i => {

							let item_code_match = false;
							let category_code_match = false;

							if((PIA.length == 0 && PEA.length == 0) ||
									(PIA.length > 0 && PIA.indexOf(i.item_code) != -1) ||
										(PEA.length > 0 && PEA.indexOf(i.item_code) == -1))
							{
								item_code_match = true;
							}

							if(item_code_match && ((CIA.length == 0 && CEA.length == 0) ||
									(CIA.length > 0 && CIA.indexOf(i.category_code) != -1) ||
										(CEA.length > 0 && CEA.indexOf(i.category_code) == -1)))
							{
								category_code_match = true;
							}

							if(item_code_match && category_code_match){
								var temp_discount_val = 0;
								if(dil.discount_specifics.amount_mode == 'percent'){
									temp_discount_val = (dil.discount_specifics.amount * 0.01) * (i.item_count * i.item_price);//(subtotal * dil.discount_specifics.amount * 0.01);
								}else if(dil.discount_specifics.amount_mode == 'fixed'){
									temp_discount_val = (dil.discount_specifics.amount * i.item_count);
								}
								item_discounts_info.push({val:temp_discount_val,discount:dil.discount_specifics.amount,type:((dil.discount_specifics.amount_mode == "percent") ? "%":""),item_code:i.item_code,item_add_time:i.add_time});
								discount_val += temp_discount_val
								if(total_purchase_item_wise_count[i.item_code]) {
                                    total_purchase_item_wise_count[i.item_code].item_count += i.item_count;
                                } else {
                                    total_purchase_item_wise_count[i.item_code] = {item_count:i.item_count,item_price:i.item_price};
                                }
								temp_total_item_count += i.item_count;
							}
				        });
					}

					if(dil.discount_specifics.restrictions.is_apply_per_item) {
						var cond_match_item_code = [];
						discount_val = 0;
                        for(var x in total_purchase_item_wise_count) {
                            if(total_purchase_item_wise_count[x].item_count >= minimum_item_purchase &&
                                total_purchase_item_wise_count[x].item_count <= maximum_item_purchase) {
                                cond_match_item_code.push(x);
                            }
                        }
                        remove(item_discounts_info, idi => {return cond_match_item_code.indexOf(idi.item_code) == -1});
                        item_discounts_info.forEach(idi => { discount_val += idi.val; });
					} else {
						if(temp_total_item_count < minimum_item_purchase || temp_total_item_count > maximum_item_purchase) {
							discount_val = 0;
						}
					}


					let name = dil.discount_code;
					if(dil.discount_specifics.restrictions.usage_value_per_order != 0 &&discount_val > dil.discount_specifics.restrictions.usage_value_per_order){
						discount_val = dil.discount_specifics.restrictions.usage_value_per_order;
						is_max_limit = true;
					}
					if(discount_val){
						if(dil.max_discount && discount_val > dil.max_discount) {
							discount_val = dil.max_discount;
							is_max_limit = true;
						};
						let f_data = appliedDiscount.regular_discount.filter(k => k.name == name);
						if(f_data.length == 0){
							let tempData = {
	                    		name : name,
	                    		type : 'flat_offer',
	                    		value : (discount_val * (-1)),
	                    		is_external : dil.is_external ? dil.is_external : false,
	                    		id : dil.id,
	                    		item_discounts_info: item_discounts_info,
	                    		is_max_discount_val : is_max_limit,
	                    		is_exclusive : dil.discount_specifics.is_exclusive? dil.discount_specifics.is_exclusive : false,
	                    	}
	                    	if(item_discounts_info.length>0) {subtotal -= discount_val};
	                    	appliedDiscount.regular_discount.push(tempData);
						}else{
							appliedDiscount.regular_discount.forEach(d =>{
								if(d.name == name) {
									d.value = (discount_val * (-1));
								}
							})
						}
					}
				}
            } else if(dil.discount_specifics.type == 'combo' && dil.discount_specifics.restrictions)  {

				if(subtotal != 0 && required_products){
					let discount_val = 0;
                    var matched_combo_pack = [];
                    var required_products_keys_len = Object.keys(required_products).length;
                    var copy_of_total_purchase_item_wise_count = JSON.parse(JSON.stringify(item_code_wise_cart_item_for_combo));
					for(var x in required_products) {
						if(copy_of_total_purchase_item_wise_count[x] &&  required_products[x].qty <= copy_of_total_purchase_item_wise_count[x].qty) {
							while(required_products[x].qty <= copy_of_total_purchase_item_wise_count[x].qty) {
								var isMissing = true;
								for(var i=0;i<matched_combo_pack.length;i++) {
									var mcp = matched_combo_pack[i];
									if(!mcp[x]) {
										isMissing = false;
										mcp[x] = {qty:required_products[x].qty};
										copy_of_total_purchase_item_wise_count[x].qty -= required_products[x].qty;
										break;
									}
								}

								if(isMissing) {
									var temp_j = {};
									temp_j[x] = {qty:required_products[x].qty};
								    copy_of_total_purchase_item_wise_count[x].qty -= required_products[x].qty;
								    matched_combo_pack.push(temp_j);
								}
							}
						}
					}

					remove(matched_combo_pack,mcp => {
						var mcpKeysLen = Object.keys(mcp).length;
						return (mcpKeysLen != required_products_keys_len);
					})

					if(matched_combo_pack.length>0) {
						var total_matched_item_price_total = 0;
						matched_combo_pack.forEach(item => {
							for(var x in item) {
								if(item_code_wise_cart_item_for_combo[x] && item_code_wise_cart_item_for_combo[x].item_price > 0) {
									total_matched_item_price_total += item[x].qty * item_code_wise_cart_item_for_combo[x].item_price;
									item_code_wise_cart_item_for_combo[x].qty -= item[x].qty;
								}
							}
						})
						if(dil.discount_specifics.amount_mode == 'percent'){
							discount_val = (total_matched_item_price_total * dil.discount_specifics.amount * 0.01);
						}else if(dil.discount_specifics.amount_mode == 'fixed'){
							discount_val = dil.discount_specifics.amount * matched_combo_pack.length;
						}

						if(dil.discount_specifics.restrictions.usage_value_per_order != "no" && discount_val > dil.discount_specifics.restrictions.usage_value_per_order){
							discount_val = dil.discount_specifics.restrictions.usage_value_per_order;
							is_max_limit = true;
						}

						let name = dil.discount_code;
						if(discount_val){
							if(dil.max_discount && discount_val > dil.max_discount) {
								discount_val = dil.max_discount;
								is_max_limit = true;
							};
							let f_data = appliedDiscount.regular_discount.filter(k => k.name == name);
							if(f_data.length == 0){
								let tempData = {
		                    		name : name,
		                    		type : 'combo',
		                    		value : (discount_val * (-1)),
		                    		is_external : dil.is_external ? dil.is_external : false,
		                    		id : dil.id,
		                    		is_max_discount_val : is_max_limit,
		                    		is_exclusive : dil.discount_specifics.is_exclusive? dil.discount_specifics.is_exclusive : false,
		                    	}
		                    	appliedDiscount.regular_discount.push(tempData);
							}else{
								appliedDiscount.regular_discount.forEach(d =>{
									d.value = (discount_val * (-1));
								})
							}
						}
					}
				}
            } else if(dil.discount_specifics.type == 'cashback' && dil.discount_specifics.restrictions){
            	var minimum_cart_value = ((dil.discount_specifics.restrictions.minimum_cart_value || dil.discount_specifics.restrictions.minimum_cart_value == 0) && dil.discount_specifics.restrictions.minimum_cart_value != "no")  ? dil.discount_specifics.restrictions.minimum_cart_value : 0;
				var tempSubTotal = ((dil.discount_specifics.is_exclusive) ? subTotalCopy : subtotal);				            	
            	if(tempSubTotal != 0 && tempSubTotal >= minimum_cart_value){
	        		let is_max_limit = false;
					let discount_val = 0;
					let name = dil.discount_code;
					if(dil.discount_specifics.amount_mode == 'percent'){
						discount_val = (tempSubTotal * dil.discount_specifics.amount * 0.01);
						if(dil.discount_specifics.restrictions.usage_value_per_order != "no" && discount_val > dil.discount_specifics.restrictions.usage_value_per_order){
							discount_val = dil.discount_specifics.restrictions.usage_value_per_order;
							is_max_limit = true;
						}
						//name = name + " @ "+dil.discount_specifics.amount+"%";

					}else if(dil.discount_specifics.amount_mode == 'fixed'){
						discount_val = dil.discount_specifics.amount;
					}
					if(discount_val){
						if(dil.max_discount && discount_val > dil.max_discount) {
							discount_val = dil.max_discount;
							is_max_limit = true;
						};
						let f_data = appliedDiscount.regular_discount.filter(k => k.name == name);
						if(f_data.length == 0){
							let tempData = {
	                    		name : name,
	                    		type : 'cashback',
	                    		value : (discount_val * (-1)),
	                    		is_external : dil.is_external ? dil.is_external : false,
	                    		id : dil.id,
	                    		is_max_discount_val: is_max_limit,
	                    		is_exclusive : dil.discount_specifics.is_exclusive? dil.discount_specifics.is_exclusive : false,
	                    	}
	                    	appliedDiscount.regular_discount.push(tempData);
						}else{
							appliedDiscount.regular_discount.forEach(d =>{
								d.value = (discount_val * (-1));
							})
						}
					}

            	}
            }
		});
    	if(tempUnit && tempUnit.temp_external_discount_list && tempUnit.temp_external_discount_list.length>0){
    		tempUnit.temp_external_discount_list.forEach(di =>{
    			let fData = appliedDiscount.regular_discount.filter(it => (it.is_external && di.discount_code == it.name));
    			if(fData && fData.length > 0){
    				is_externalDiscountApplied = true;
					di['is_external'] = true;
    				tempUnit.external_discount_list.push(di)
    			}
    		});
    	}

    	tempUnit.temp_external_discount_list = [];
    	if(is_FoundExternalDiscount){
    		if(is_externalDiscountApplied){
				return JSON.parse(JSON.stringify(appliedDiscount));
    		}else{
    			return null;
    		}
    	}else{
			return JSON.parse(JSON.stringify(appliedDiscount));
    	}
	}

	public getDiscountInfoForSeparateTaxClasses(total_st,subtotal,cart_items,discount_info) {
		let all_discount_list = JSON.parse(localStorage.getItem('discount_list')) || [];
		let discount_info_result = [];
		let tempSubToal = JSON.parse(JSON.stringify(subtotal));

		var adlLen = all_discount_list.length;
		var discount_usage_rank = {"bogo":1,"bogo_any":2,"flat_offer":3,"combo":4,"regular_discount":5,"cashback":6,'discount_on_order':7};
		discount_info = discount_info.sort((a,b) => { return discount_usage_rank[a.type]-discount_usage_rank[b.type] })
		discount_info.forEach(dInfo => {
			var dil = null;
			for(var i=0;i<adlLen;i++) {
				if(all_discount_list[i].discount_code == dInfo.discount_code) {
					dil = all_discount_list[i];
					break;
				}
			}

			if(dil && dil.discount_specifics.restrictions) {
				let is_item_wise_discount = 0;
				var temp_required_products = dil.discount_specifics.restrictions.required_products;
		        var products = dil.discount_specifics.restrictions.products;
		        var category = dil.discount_specifics.restrictions.category;
		        var quantity = dil.discount_specifics.restrictions.quantity;

		        var required_products = (temp_required_products && temp_required_products != "no") ? temp_required_products : null;
		        var PIA = (products && (Array.isArray(products.include))) ? products.include : [];
		        var PEA = (products && (Array.isArray(products.exclude))) ? products.exclude : [];
		        var CIA = (category && (Array.isArray(category.include))) ? category.include : [];
		        var CEA = (category && (Array.isArray(category.exclude))) ? category.exclude : [];

	           	let cval = 0;
				if(['regular_discount','cashback'].indexOf(dil.discount_specifics.type) != -1){
					if(dil.discount_specifics.amount_mode == 'percent'){
						cval = (subtotal * dil.discount_specifics.amount * 0.01);
					}
	            } else if(dil.discount_specifics.type == 'flat_offer') {
					if(PIA.length == 0 && PEA.length == 0 && CIA.length == 0 && CEA.length == 0) {
						if(dil.discount_specifics.amount_mode == 'percent') {
							cval = (dil.discount_specifics.amount * 0.01) * subtotal;
						}
					} else {
						cart_items.forEach(item => {
							var tItem = (item.selected_variant_item) ? item.selected_variant_item : item;
							if(Array.isArray(item.other_discounts)) {
								item.other_discounts.forEach(od => {
									if(od.discount_code == dInfo.discount_code) {
										cval += od.amount;
									}
								})
							}
						})
						is_item_wise_discount = 1;
						subtotal -= cval;
					}
	            }

				if(cval > 0) {
		            if(dInfo.is_max_discount_val) {
						var percentageClaimed = (tempSubToal/total_st*100);
						cval = ((dInfo.claimed_value * percentageClaimed)/100)
					}
					discount_info_result.push({claimed_value:cval,discount_code:dInfo.discount_code,type:dInfo.type,is_item_wise_discount: is_item_wise_discount})
				}
			}
		});

		return discount_info_result;
	}

	public clubbedCartItemsForItemCount(cart_items) {
		var clubbed_cart_items = {}
		cart_items.forEach(item => {
			if(clubbed_cart_items[item.item_code]) {
				clubbed_cart_items[item.item_code].item_count += item.item_count;
			} else {
				clubbed_cart_items[item.item_code] = item;
			}
		});

		let result_items = [];
		for(var x in clubbed_cart_items) {
			result_items.push(clubbed_cart_items[x]);
		}
		return result_items;
	}
}
