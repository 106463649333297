import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FoodOrderingComponent } from './food-ordering/food-ordering.component';
import { PlaceSelectionComponent } from './place-selection/place-selection.component';
import { CashRegisterComponent } from './cash-register/cash-register.component';
import { OpenCloseComponent } from './cash-register/open-close/open-close.component';
import { CashComponent } from './cash-register/cash/cash.component';
import { RecentOrdersComponent } from './recent-orders/recent-orders.component';
import { OrdersComponent } from './orders/orders.component';
import { RegisterComponent } from './register/register.component';
import { ReportsComponent } from './reports/reports.component';
import { PagesComponent } from './pages.component';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { PersonalPinComponent } from './personal-pin/personal-pin.component';
import { SalesComponent } from './reports/sales/sales.component';
import { SettingsComponent } from './settings/settings.component';
import { PrinterSettingsComponent } from './settings/printer-settings/printer-settings.component';
import { OthersSettingsComponent } from './settings/other-settings/other-settings.component';
import { OnlinePartnerSettingsComponent } from './settings/online-partner-settings/online-partner-settings.component';
import { SectionwiseDetailReportComponent } from './reports/sectionwise-item-detail-report/sectionwise-item-detail-report.component';
import { ItemCancellationReportComponent } from './reports/item-cancellation-report/item-cancellation-report.component';
import { CashRegisterClosureSummaryComponent } from './cash-register/cash-register-closure-summary/cash-register-closure-summary.component';


const routes: Routes = [

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginModalComponent },
  { path: 'verify-pin', component: PersonalPinComponent },
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      { path: 'recent-orders', component: RecentOrdersComponent },
      { path: 'orders', component: OrdersComponent },
      {
        path: 'cash-register',
        component: CashRegisterComponent,
        children: [
          { path: 'open-close', component: OpenCloseComponent },
          { path: 'cash', component: CashComponent },
          { path: 'cash-register-closure-summary', component: CashRegisterClosureSummaryComponent },
          { path: '', redirectTo: '/cash-register/open-close', pathMatch: 'full' }
        ]
      },

      {
        path: 'reports',
        component: ReportsComponent,
        children: [
          { path: 'sales-report', component: SalesComponent },
          { path: 'sectionwise-item-detail-report', component: SectionwiseDetailReportComponent },
          { path: 'item-cancellation-report', component: ItemCancellationReportComponent },
          { path: '', redirectTo: '/reports/sales-report', pathMatch: 'full' }
        ]
      },

      {
        path: 'register',
        component: RegisterComponent,
        children: [
          { path: 'place-selection/:type', component: PlaceSelectionComponent },
          { path: 'place-selection', redirectTo: '/register/place-selection/here', pathMatch: 'full' },
          { path: 'food-ordering/:payment', component: FoodOrderingComponent },
          { path: 'food-ordering', component: FoodOrderingComponent },
          { path: '', redirectTo: '/register/place-selection/here', pathMatch: 'full' },
        ]
      },

      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          { path: 'printers', component: PrinterSettingsComponent },
          { path: 'others', component: OthersSettingsComponent },
          { path: 'online-partner', component: OnlinePartnerSettingsComponent },
          
          { path: '', redirectTo: '/settings/printers', pathMatch: 'full' }
        ]
      },
      { path: '**', component: RegisterComponent }
    ]
  }
];

export const routing = RouterModule.forChild(routes);