import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Pipe({
    name: 'customCurrencyPipe',
})

export class CustomCurrencyPipe implements PipeTransform {
    transform(
        value: number,
        currencyCode: string = 'INR',
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol-narrow',
        digitsInfo: string = '2.2'
    ): string | null {
    	  digitsInfo = currencyCode == "BHD" ? '2.3' : '2.2';
        return formatCurrency(
          value,
          'en',
          getCurrencySymbol(currencyCode, 'wide'),
          currencyCode,
          digitsInfo,
        );
    }
}