import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
 
@Injectable()
export class CurrentMenuItemsMessage {
    private subject = new Subject<any>();
 
    sendCurrentMenuItems(message: any) {
        this.subject.next({ CurrentMenuItems: message });
    }

    clearCurrentMenuItems() {
        this.subject.next();
    }

    getCurrentMenuItems(): Observable<any> {
        return this.subject.asObservable();
    }
}