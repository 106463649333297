import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cancel-order-confirmation-modal',
  templateUrl: './cancel-order-confirmation-modal.component.html',
  styleUrls: ['./cancel-order-confirmation-modal.component.less']
})
export class CancelOrderConfirmationModalComponent implements OnInit {
  
  constructor(public modalRef: BsModalRef) { }
  public decision: string;
  public text: string='';

  ngOnInit() {
  	this.getText();
  }

  getText(){
    var self = this;
    setTimeout(()=>{
      self.text = this.modalRef.content.text;
    },100);
  }
  
}
