import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.css']
})
export class InputSelectComponent implements OnInit {

	@Input('label') label: string = '.';
	@Input('type') type: string = 'text';
	@Input('name') name: string;
	@Input('value') value: string;
	@Input('placeholder') placeholder: string;
  @Input('filter_items') filter_items: any;
	@Input('disabled') disabled: boolean = false;

	@Output() sendValue = new EventEmitter<string>();

  showItems: boolean;
  filterBy: string = '';

  constructor() { }

  ngOnInit() {
  }

  @HostListener('window:click') onClick() {
    this.showItems = false;
  }

  showSelect() {
    setTimeout(() => {
      this.showItems = true;
    }, 100);
  }

  sendData() {
  	this.sendValue.emit(this.value);
  }

}
