import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { ThirdPartyOrderService } from '../helpers/services/third-party-orders.service';

@Component({
  selector: 'app-third-delivery-partner-info-modal',
  templateUrl: './third-party-delivery-partner-modal.component.html',
  styleUrls: ['./third-party-delivery-partner-modal.component.less']
})
export class ThirdPartyDeliveryPartnerModalComponent implements OnInit {
  isLoadingShow : boolean = false;
  decision: string;
  task_id: string;
  order_id:any;
  order_type: string;
  rider_name = "";
  estimationDistance = "";
  estimationPrice = "";
  estimationPickupTime = "";
  estimationDropoffTime = "";
  estimateButton = true;
  createTaskButton = false;
  doneButton = false;
  
  shop_code = localStorage.getItem("shop_code");
  merchant_code = localStorage.getItem("merchant_id");   
  delivery_partner_info:any;
  selected_delivery_partner_info:any = [];
  delivery_date:any;
  delivery_time:any;
  constructor(public modalRef: BsModalRef,public messageService:MessageService,private thirdPartyOrderServices:ThirdPartyOrderService,) { }
  
  ngOnInit() {    
    this.isLoadingShow = true;  
    this.delivery_partner_info = JSON.parse(localStorage.getItem("delivery_partner_info"));       
    this.isLoadingShow = false; 
    setTimeout(() => {
      this.delivery_date = this.delivery_date ? new Date(this.delivery_date) : new Date();      
      if(!this.delivery_date) {
        let cDate = new Date();
        cDate.setMinutes(cDate.getMinutes()+30);
        let hh = cDate.getHours() > 12 ? cDate.getHours() : "0"+cDate.getHours();
        let mm = cDate.getMinutes() > 12 ? cDate.getMinutes() : "0"+cDate.getMinutes();
        this.delivery_time = hh+ ":"+mm;
      } else {
        let hh = this.delivery_date.getHours() > 12 ? this.delivery_date.getHours() : "0"+this.delivery_date.getHours();
        let mm = this.delivery_date.getMinutes() > 12 ? this.delivery_date.getMinutes() : "0"+this.delivery_date.getMinutes();
        this.delivery_time = hh+ ":"+mm;
      }
    },300)
  } 
  changeType(type){  
    this.selected_delivery_partner_info = this.delivery_partner_info.filter(sl => { return sl.id == type});
   
  }
  getEstimate(){    
    this.isLoadingShow = true;  
    if(this.selected_delivery_partner_info.length == 0){
      this.messageService.add({ severity: 'error', summary: "Please Select Partner First", detail: '' }); 
      this.isLoadingShow = false;     
      return;
    }    
    var data = {
      "merchant_id":this.selected_delivery_partner_info[0].id,
      "order_id": this.order_id,
      "shop_code":this.shop_code
    }
    this.thirdPartyOrderServices.getEstimate(data).subscribe(res => {
      var apiRes = res.json();  
      if (apiRes.status === 0) {
        if(apiRes.result.unserviceable_location_error){
          this.messageService.add({ severity: 'error', summary: apiRes.message, detail: '' });           
          this.isLoadingShow = false; 
        } else {
          this.estimationDistance = "Estimated Distance: "+apiRes.result.distance;
          this.estimationPrice = "Estimated Price: "+apiRes.result.estimated_price;
          this.estimationPickupTime = "Estimated Pickup Time: "+ apiRes.result.eta.pickup;
          this.estimationDropoffTime = "Estimated Dropoff Time: "+apiRes.result.eta.dropoff;
          this.estimateButton = false;
          this.createTaskButton = true
          this.isLoadingShow = false;  
        }
      }else{
        this.messageService.add({ severity: 'error', summary: apiRes.message, detail: '' }); 
        this.isLoadingShow = false;  
      }      
    },(error) => {
      this.messageService.add({ severity: 'error', summary: "", detail: 'Internal server error' }); 
      this.isLoadingShow = false;  
    });
    
  }

  deliveryDateChanged(event) {
    if(event) {
      this.delivery_date = new Date(event);
    }
  }

  deliveryTimeChanged(event) {
    if(event) {
      this.delivery_time = event;
    }
  }

  createTask(){
    if(this.selected_delivery_partner_info.length == 0){
      this.messageService.add({ severity: 'error', summary: "Please Select Partner First", detail: '' });  
      return;
    }

    if(!this.delivery_time) {
      this.messageService.add({ severity: 'error', summary: "Please valid schedule pickup time", detail: '' });
      return
    }

    var data = {
      "merchant_id":this.selected_delivery_partner_info[0].id,
      "order_id": this.order_id,
      "shop_code":this.shop_code,
      "type":"DELIVERY_TYPE",
      "delivery_date": new Date(this.delivery_date)
    }

    let sTime = this.delivery_time.split(":");
    data["delivery_date"].setHours(sTime[0]);
    data["delivery_date"].setMinutes(sTime[1]);
    data["delivery_date"].setSeconds(0);

    let cDate = new Date();
    cDate.setMinutes(cDate.getMinutes()+30);
    if(data["delivery_date"] < cDate) {
      this.messageService.add({ severity: 'error', summary: "Schedule pickup time must be greater then 30 minutes from current time", detail: '' });
      return;
    }
    
    this.isLoadingShow = true;
    this.thirdPartyOrderServices.createTask(data).subscribe(res => {
      var apiRes = res.json();                  
      if (apiRes.status === 0) {
        if(!apiRes.result.code){
          this.task_id = apiRes.result.task_info.task_id;                      
          let orders = JSON.parse(localStorage.getItem('temp_orders_list')) || [];
          for(var i=0,fLen = orders.length;i<fLen;i++) {          
            if(orders[i].order.order_id == this.order_id){             
              orders[i].order.third_party_dunzo_task_id = apiRes.result.task_info.task_id;
              orders[i].order.delivery_date = data["delivery_date"];
              orders[i].delivery_date = data["delivery_date"];
              break;
            }
          }
          localStorage.setItem('temp_orders_list',JSON.stringify(orders));
          this.messageService.add({ severity: 'success', summary: apiRes.message, detail: '' });        
          this.isLoadingShow = false;  
          this.createTaskButton = false;      
          this.doneButton = true;
          this.delivery_date = data["delivery_date"];
        }else{
          this.messageService.add({ severity: 'error', summary: "", detail: apiRes.message });
          this.isLoadingShow = false; 
        }
      }else{
        this.messageService.add({ severity: 'error', summary: "", detail: apiRes.message });        
        this.isLoadingShow = false;
      }      
    },(error) => {
      this.messageService.add({ severity: 'error', summary: "", detail: 'Internal server error' }); 
      this.isLoadingShow = false;  
    });
  }

  done(){    
    this.decision='done';
    this.modalRef.hide()
  }
}
