import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { remove, uniq } from 'lodash-es';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-loose-item-selection-modal',
  templateUrl: './loose-item-selection-modal.component.html',
  styleUrls: ['./loose-item-selection-modal.component.less']
})
export class LooseItemSelectionModalComponent implements OnInit, AfterViewInit {
  decision: string;
  item_quantity_portion: number;
  targetUnit: any;
  looseItems = [];
  isCustomSelected: boolean = false;
  add_modifier: boolean = false;
  modifiers: any = [];
  modifier_attributes: any = [];
  exclusive_selective_modifier_group = [];  
  dish: any;
  modalResponse: any = {selectedLooseItem:{},selectedModifiers:[]};
  @ViewChild('itemQuantityInput',{static: false}) itemQuantityInput;
  constructor(public modalRef: BsModalRef,
    private messageService: MessageService) { }

  ngOnInit() {
    setTimeout(()=>{
      this.looseItems = this.dish.loose_items;
      if(!this.looseItems || this.looseItems.length == 0) {
        this.isCustomSelected = true;
        setTimeout(() => {
          this.setFocus();    
        },100)
      }
      this.initializeModifiers();
    },100)
  }

  ngAfterViewInit () {
    setTimeout(()=> {
      this.looseItems = this.dish.loose_items;
      if(!this.looseItems || this.looseItems.length == 0) {
        this.isCustomSelected = true;
        setTimeout(() => {
          document.querySelector("#item_quantity_portion")["focus"]();
        },100)
      }    
    },100)    
  }

  initializeModifiers() {
    this.modifiers = [];
    for (var k in this.dish.modifier_attributes) {
      var modifierObject = {
        name: k,
        options: []
      };

      this.dish.modifier_attributes[k].forEach(mod => {
        mod.remove = false;
        mod.selected = false;
        mod.count = 0;
        mod.price = mod.unit_price;

        if(mod.is_selection_exclusive) {
          this.exclusive_selective_modifier_group.push(mod.group_name);
        }
          modifierObject.options.push(mod);
      });

      this.modifiers.push(modifierObject);
    }
  }

  setFocus(){
     document.querySelector("#item_quantity_portion")["focus"]();
  }  

  onSelectLooseItem(looseItem:any) {
    if(looseItem.name == "Custom") {
      this.isCustomSelected=true;
      this.modalResponse["selectedLooseItem"] = {portion:1}
      this.item_quantity_portion = 1;
    } else {
      if(!looseItem.portion || isNaN(parseFloat(looseItem.portion))) {
        return alert("did not found right portion for loose item");
      }

      this.item_quantity_portion = parseFloat(looseItem.portion) || 0;              
      this.modalResponse["selectedLooseItem"] = looseItem;
    }    
  }

  changeCountOnClickOfRect(event,option) {
    if (["increaseModifier","decreaseModifier"].indexOf(event.target.id) != -1) {
      return;
    }
    this.changeCount(option,'plus')
  }

  changeCount(option, value) {
    var self = this;
    if (value == 'plus') {
      option.count++;
      option.price = option.unit_price * option.count
    } else {
      if (option.count > 0) {
        option.count--;
      }
      if (option.count == 0) {
        setTimeout(() => {
          option.selected = false;
        }, 30);
      }
    }

    option.old_count = JSON.parse(JSON.stringify(option.count));
  }

  getCSSOfVariantModifier(option) {
    if(option && option.name) {
      if(option.name.length < 20) {
        if(window.innerWidth <=1500) {
          return "col-sm-4 col-md-4";
        } else {
          return "col-sm-4 col-md-3";
        }        
      } else if(option.name.length < 30) {
        return "col-sm-6 col-md-4";
      } else if(option.name.length < 50) {
        return "col-sm-6 col-md-6";
      } else if(option.name.length < 60){
        return "col-sm-8 col-md-7";
      } else {
        return "col-sm-8 col-md-8";
      }
    } else {
      return "col-sm-4 col-md-3";
    }
  }

  done() {
    this.modalResponse["selectedLooseItem"]["portion"] = this.item_quantity_portion;
    var modifierArray = [];
    this.modifiers.forEach(element => {
      element.options.forEach(attr => {
        if (attr.count > 0) {
          modifierArray.push(attr);
        }
      });
    });
    this.modalResponse["selectedModifiers"] = modifierArray;
    this.dish.attributes = modifierArray;
    this.checkExclusiveSelectiveAttributeAdded().then(result => {
      if(result) {
        this.checkMinimumMaximumAttribteCondition().then(mmcresult => {
          if(mmcresult) {            
            this.decision = "done";
            this.modalRef.hide()
          }
        })
      }
    });
  }

  checkExclusiveSelectiveAttributeAdded() {
    return new Promise((resolve,reject)=> {
        var not_selected_group = JSON.parse(JSON.stringify(this.exclusive_selective_modifier_group));
        remove(not_selected_group,group_name => {
          var flag = false;
          var tempAttributes = (this.dish.selected_variant_item) ? this.dish.selected_variant_item.attributes : this.dish.attributes;

          if(Array.isArray(tempAttributes)) {
            var dalen = tempAttributes.length;
            for(var i=0;i<dalen;i++) {
              if(tempAttributes[i].group_name == group_name) {
                flag = true;
                break;
              }
            }
          }

          return flag;
        });

        if(not_selected_group.length>0) {
          this.messageService.add({ severity: 'error', summary: "select at least one modifier from group -> "+ not_selected_group.join(""), detail: '' });
          return resolve(false);
        } else {
          return resolve(true);
        }
    })
  }

  checkMinimumMaximumAttribteCondition() {
    return new Promise((resolve,reject)=> {
        let temp_dish_v = (this.dish.selected_variant_item) ? this.dish.selected_variant_item : this.dish;
        if(!temp_dish_v.minimum_unique_attribute_selection && !temp_dish_v.maximum_unique_attribute_selection) {
          return resolve(true);
        }

        var flag = false;
        var unique_attr = (Array.isArray(temp_dish_v.attributes)) ? uniq(temp_dish_v.attributes.map(attr => {return attr.attribute_code; })) : []
        if(temp_dish_v.minimum_unique_attribute_selection && unique_attr.length < temp_dish_v.minimum_unique_attribute_selection) {
          this.messageService.add({ severity: 'error', summary: "select  minimum "+ temp_dish_v.minimum_unique_attribute_selection +" modifier", detail: '' });
          return resolve(false);
        } else if(temp_dish_v.maximum_unique_attribute_selection && temp_dish_v.maximum_unique_attribute_selection > 0 && unique_attr.length > temp_dish_v.maximum_unique_attribute_selection) {
          this.messageService.add({ severity: 'error', summary: "select  maximum "+ temp_dish_v.maximum_unique_attribute_selection +" modifier", detail: '' });
          return resolve(false);
        } else {
          return resolve(true);
        }
    })
  }
}
