import { Component, OnInit, EventEmitter, Input, Output, DoCheck, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { RecentOrdersService } from '../../helpers/services/recent-orders.service';
import { Router } from '@angular/router';
import { StateGuardService } from '../../state-guard.service';
import { MenuComponent } from '../../menu/menu.component';
import { APIHelper } from '../../helpers/api-helper';
import { Subscription } from 'rxjs';
import { BasketComponent } from '../../basket/basket.component';
import { CancelOrderConfirmationModalComponent } from '../../cancel-order-confirmation-modal/cancel-order-confirmation-modal.component';
import { ConfirmationModal } from '../../confirmation-modal/confirmation-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { PaymentHelper } from '../../helpers/payment-helper';
import { SaveOrderService } from '../../helpers/services/save-order.service';
import { MakePaymentService } from '../../helpers/services/make-payment.service';
import { DiaryService } from '../../helpers/services/diary.service';
import { CashRegisterService } from '../../helpers/services/cash-register.service';
import { SpecialOrderService } from '../../helpers/services/special-order.service';

import { CustomerService } from '../../helpers/services/customer.service';
import { CancelOrderReasonModalComponent } from '../../cancel-order-modal/cancel-order-modal.component';
import { ThirdPartyDeliveryTimeModalComponent } from '../../third-party-delivery-time-modal/third-party-delivery-time-modal.component';
import { ThirdPartyRejectOrderReasonModalComponent } from '../../third-party-reject-order-reason-modal/third-party-reject-order-reason-modal.component';
import { ThirdPartyRiderInfoModalComponent } from '../../third-party-rider-info-modal/third-party-rider-info-modal.component';
import { ThirdPartyDeliveryPartnerModalComponent } from '../../third-party-delviery-partner-modal/third-party-delivery-partner-modal.component';
import { ThirdPartyRiderTemperatureModalComponent } from '../../third-party-rider-temperature-modal/third-party-rider-temperature-modal.component';

import { KotHelper } from '../../helpers/kot-helper';
import { PrintHelper } from '../../helpers/print-helper';
import { EnterDiaryModalComponent } from '../../enter-diary-modal/enter-diary-modal.component';
import 'assets/js/epos-print-3.0.0.js';
import 'assets/js/cxmlp-api.js';
import { BaseConfig } from '../../helpers/api-config';
import * as dot from 'dot';

declare var $: any;
declare var epson: any;
declare var citizen: any;

@Component({
  selector: 'app-recent-order',
  templateUrl: './recent-order.component.html',
  styleUrls: ['./recent-order.component.less']
})
export class RecentOrderComponent implements OnInit {

  @Input('order') order: any;
  @Input('edit_items') edit_items: boolean;
  @Input('page_type') page_type: any;
  @Output() cancelOrderComplete: EventEmitter<any> = new EventEmitter();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() fetchOrderDetail: EventEmitter<any> = new EventEmitter();

  public paymentHelper: PaymentHelper;
  view_invoice: boolean = false;
  edit: boolean;
  shopConfig: boolean;
  loading: boolean = false;
  isShowFullAddress: boolean = false;
  builder: any;
  shopConfiguration: any;
  merchantProfile: any;
  popupWin: any;
  isRestaurant: boolean = false;
  public currencySymbol: any;
  public temp_compare_order_id: any;
  public isPaid: boolean = false;
  public is_dairy_history_show: boolean = true;
  public isDiscountShow: boolean = false;
  public is_show_post_tax_price: boolean;
  public modalRef: BsModalRef;
  public cancelModalRef: BsModalRef;
  public subscriptions: Subscription[] = [];
  public third_party_order_message = { message: "", color: "", order_ready_time_message: "" }
  public is_order_message_checking_set: boolean = false;
  public loggedInUserRole: any = "";
  public local_configuration: any = JSON.parse(localStorage.getItem("local_configuration")) || { enable_dispatch_print: false, enable_customer_address_print: false };
  time: any;

  constructor(private menuComponent: MenuComponent, private basketComponent: BasketComponent,
    private stateGuard: StateGuardService, private router: Router, private datePipe: DatePipe,
    private saveOrderService: SaveOrderService, private makePaymentService: MakePaymentService,
    private diaryService: DiaryService, private cashRegisterService: CashRegisterService,
    private customerService: CustomerService, private recentOrdersService: RecentOrdersService,
    private modalService: BsModalService, private cancelModalService: BsModalService,
    private messageService: MessageService, private cdRef: ChangeDetectorRef,
    private printHelper: PrintHelper, private kotHelper: KotHelper, private specialOrderService: SpecialOrderService) { }

  ngOnInit() {
    try {
      let loggedInUser: any = JSON.parse(localStorage.getItem("loggedInUser")) || null;
      if (loggedInUser && loggedInUser.role) {
        this.loggedInUserRole = loggedInUser.role;
      }
    } catch (e) { }

    this.selectTime();
    this.shopConfiguration = JSON.parse(localStorage.getItem('shop_configuration'));
    if (this.shopConfiguration) {
      this.isRestaurant = this.shopConfiguration.enable_tables
      this.currencySymbol = this.shopConfiguration.currency;
      if (this.shopConfiguration.show_post_tax_price) {
        this.is_show_post_tax_price = true;
      }
    }

    if (this.order && this.order.order && this.order.order.third_party_order_other_information &&
      this.order.order.third_party_order_other_information.order_messages && this.order.order.third_party_order_other_information.order_messages.length > 0) {
      this.setOrderMessageTimeIntervals()
    }
  }

  setOrderMessageTimeIntervals() {
    if (!this.is_order_message_checking_set) {
      this.is_order_message_checking_set = true;
      this.setThirdPartyOrderMessage();
      setInterval(() => {
        this.setThirdPartyOrderMessage();
      }, 1000);
    }
  }

  checkDairyHistory() {
    let credit_amount_total = 0;
    let clear_amount_total = 0;
    let is_found_clear = false;

    this.order.diary_history.forEach(data => {
      if (data.comment == 'credit' || data.comment == 'credit_due' || data.comment == 'outstanding') {
        credit_amount_total += data.diary_amount;
      } else if (data.comment == 'clear') {
        is_found_clear = true;
        clear_amount_total += data.diary_amount;
      }
    })
     
    let remainAmount = (credit_amount_total + clear_amount_total)
    if (remainAmount == 0) {
      if (is_found_clear) {
        this.is_dairy_history_show = false;
      }
    } else {
      this.is_dairy_history_show = true;
    }

    return remainAmount;    
  }

  initFlags() {
    if (this.order && this.order.order && this.order.order.cancel_order_comment) {
      this.order.order.is_order_cancelled = true;
    }
  }

  ngAfterViewChecked() {
    if (this.order) {
      if (this.order.order_id && this.order.order_id != this.temp_compare_order_id) {
        this.temp_compare_order_id = JSON.parse(JSON.stringify(this.order.order_id));
        this.checkDairyHistory();
        this.initFlags();
      } else if (this.order.order && this.order.order.order_id && this.order.order.order_id != this.temp_compare_order_id) {
        this.temp_compare_order_id = JSON.parse(JSON.stringify(this.order.order.order_id));
        this.checkDairyHistory();
        this.initFlags();
      }
    }
    // this is added for solve the issue of Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    this.cdRef.detectChanges();
  }

  /*getItemPrice(dish) {

   let price = dish.unit_price * dish.item_count;
   if(this.shopConfig.show_post_tax_price) {
     let discount = 0;
     if(dish.discount && dish.discount > 0) {
       discount = (dish.discountUnits == "%") ? ((price * dish.discount) / 100) : dish.discount;
     }
     return (price - discount) + ((dish.tax_value) ? dish.tax_value : 0);
   } else {

     let discount = 0;
     if(dish.discount && dish.discount > 0) {
       discount = (dish.discountUnits == "%") ? ((price * dish.discount) / 100) : dish.discount;
     }
     return (price - discount);
   }
 }*/

  getItemPrice(price, tax, count, item) {
    if (this.is_show_post_tax_price) {
      let taxVal = (tax) ? parseFloat(tax) : 0;
      let countVal = (count) ? parseInt(count) : 1;

      countVal = Math.abs(countVal);
      taxVal = Math.abs(taxVal);

      return (parseFloat(price) + (taxVal / countVal)) * ((count < 0) ? (-1) : 1);
    }
    return price;
  }

  ngDoCheck() {
    this.selectTime();
  }

  editAddress() {
    if (this.order.order.order_status == APIHelper.ORDER_EDITED) {
      this.edit = !this.edit;
    }
  }


  callPaymentInstance() {
    this.shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
    this.paymentHelper = PaymentHelper.getInstance(this.shopConfig, this.customerService, this.saveOrderService, this.makePaymentService, this.diaryService, this.cashRegisterService, this.specialOrderService);
  }

  isCurrentPaymentType(payment_type) {
    let isMatch = false;
    if (this.order.order.split_payment) {
      for (var i = 0; i < this.order.order.split_payment.length; i++) {
        if (this.order.order.split_payment[i].payment_code == this.getTypeCode(payment_type)) {
          isMatch = true;
          break;
        }
      }
    }
    if (!isMatch && this.order.order.payment_type == this.getTypeCode(payment_type)) {
      isMatch = true;
    }
    return isMatch;
  }


  showMoreInfo() {
    this.isShowFullAddress = !this.isShowFullAddress;
  }

  clearOrderEnable(diary_history) {
    var is_clear_enable = false;
    if (Array.isArray(diary_history) && diary_history.length > 0) {
      for (var i = 0; i < diary_history.length; i++) {
        if (((diary_history[i].comment == 'credit_due' || diary_history[i].comment == 'credit' || diary_history[i].comment == 'outstanding') && diary_history[i].diary_amount > 0) && this.is_dairy_history_show) {
          is_clear_enable = true;
          break;
        }
      }
    }
    return is_clear_enable;
  }

  getSubTotalAmount() {

    var total = 0;
    if (this.is_show_post_tax_price) {
      this.order.items.forEach(dish => {
        dish.discount = (dish.discount) ? parseFloat(dish.discount) : 0;
        var other_discount: any = 0;
        if (Array.isArray(dish.other_discounts) && dish.other_discounts.length > 0) {
          if (typeof dish.item_price == 'string') {
            dish.item_price = parseFloat(dish.item_price);
          }
          dish.other_discounts.forEach(iod => {
            if (iod.discount_type == '%') {
              if (typeof dish.item_price == 'string') {
                dish.item_price = parseFloat(dish.item_price);
              }
              other_discount += ((parseInt(dish.item_count) * dish.item_price * iod.discount) / 100);
            } else {
              other_discount += parseInt(dish.item_count) * iod.discount;
            }
          })
        }
        total -= dish.discount;
        total -= other_discount;
        total += ((dish.item_price * (dish.item_count)) + ((dish.tax_value) ? (dish.tax_value) : 0));
        if (Array.isArray(dish.attributes)) {
          dish.attributes.forEach((attr) => {
            total += attr.price * ((attr.count > 0) ? attr.count : 1);
          });
        }
      });
    } else {
      this.order.items.forEach(dish => {
        total += dish.item_price * (dish.item_count);
        dish.discount = (dish.discount) ? parseFloat(dish.discount) : 0;
        var other_discount: any = 0;
        if (Array.isArray(dish.other_discounts) && dish.other_discounts.length > 0) {
          if (typeof dish.item_price == 'string') {
            dish.item_price = parseFloat(dish.item_price);
          }
          dish.other_discounts.forEach(iod => {
            if (iod.discount) {
              if (iod.discount_type == '%') {
                other_discount += ((parseInt(dish.item_count) * dish.item_price * iod.discount) / 100);
              } else {
                other_discount += parseInt(dish.item_count) * iod.discount;
              }
            }
          })
        }
        total -= dish.discount + other_discount;
        if (Array.isArray(dish.attributes)) {
          dish.attributes.forEach((attr) => {
            total += attr.price * ((attr.count > 0) ? attr.count : 1);
          });
        }
      });
    }
    this.order.order.sub_total = total;
    return total;
  }

  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  makeDuplicateOrder() {
    var self = this;
    for (var i = 0; i < this.order.items.length; ++i) {
      this.menuComponent.addItems(this.order.items[i]);
      if (i == (this.order.items.length - 1)) {
        setTimeout(() => {
          let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
          self.router.navigate(['/pages/register/food-ordering']);
        }, 2000);
      }
    }
  }

  clearAmount() {
    let diary_amount = this.checkDairyHistory();
    this.customerService.contactSearch(this.order.user.phone, this.order.order.shop_code).subscribe((res) => {
      var result = res.json();
      if (result.result.details.length) {
        this.modalRef = this.modalService.show(EnterDiaryModalComponent, { class: 'centred-modal' });
        this.modalRef.content.decision = "";
        this.modalRef.content.order_id = this.order.order.order_id;
        this.modalRef.content.customer_auth_token = result.result.details[0].auth_token;
        this.modalRef.content.type = "clear_diary";
        this.modalRef.content.diary_amount = diary_amount;
        let cSub: Subscription = this.modalService.onHide.subscribe(() => {
          cSub.unsubscribe();
          this.getOrderDetail();
        });
      }
    })
  }
  getOrderDetail() {
    let sendData = {
      'shop_code': this.order.order.shop_code,
      'order_id': this.order.order.order_id,
      'getLocalizeName': true
    }
    this.recentOrdersService.getSelectedOrderInfo(sendData).subscribe((res) => {
      let ordersData = res.json();
      if (ordersData.result) {
        let shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
        if (typeof ordersData.result.order.comment == 'string' || ordersData.result.order.nocharge_comment) {
          if (ordersData.result.order.comment || ordersData.result.order.nocharge_comment) {
            if (ordersData.result.order.nocharge_comment) {
              ordersData.result.order.comment = { noChargeNote: ordersData.result.order.nocharge_comment };
              if (ordersData.result.order.employee_id && ordersData.result.order.employee_id != -1) {
                ordersData.result.order.comment["employeeId"] = ordersData.result.order.employee_id;
              }
            } else {
              try {
                ordersData.result.order.comment = (ordersData.result.order.comment) ? JSON.parse(ordersData.result.order.comment) : "";
              } catch (e) {
                ordersData.result.order.comment = "";
              }
            }

            if (ordersData.result.order.comment.employeeId) {
              if (localStorage.getItem('shop_user_list')) {
                let userList = JSON.parse(localStorage.getItem('shop_user_list'));
                for (var i = 0; i < userList.length; ++i) {
                  if (userList[i].id.toString() == ordersData.result.order.comment.employeeId.toString()) {
                    ordersData.result.order.comment['emp_name'] = userList[i].name;
                    break;
                  }
                }
              }
            }
          } else {
            ordersData.result.order.comment = null;
          }
        } else {
          ordersData.result.order.comment = (ordersData.result.order.comment);
        }
        let total_item_count = 0;
        let data = {
          'order': ordersData.result.order,
          'user': (ordersData.result.user.length > 0) ? ordersData.result.user[0] : {},
          'items': (ordersData.result.items.length > 0) ? ordersData.result.items : [],
          'discount_info': (ordersData.result.discount_info.length > 0) ? ordersData.result.discount_info : [],
          'diary_history': (ordersData.result.diary_history.length > 0) ? ordersData.result.diary_history : [],
          'rider_data': (ordersData.result.rider_data) ? ordersData.result.rider_data : null
        }


        let groupTaxes = [];
        data.items.forEach(function (item: any) {
          total_item_count += (item.item_count) ? item.item_count : 0;
          item.tax_breakup.forEach(function (f: any) {
            /*if(!ordersData.result.order.is_order_cancelled){
              f['breakup_name'] = f['breakup_name']//+' @'+f['rate'];
            }*/
            var present = false;
            for (var calcTax of groupTaxes) {
              if (calcTax !== undefined) {
                if (f['breakup_name'] === calcTax['breakup_name'] && f['rate'] === calcTax['rate']) {
                  present = true;
                  if (!ordersData.result.order.is_order_cancelled) {
                    calcTax['value'] = calcTax['value'] + f['value'];
                  }
                }
              }
            }
            if (!present) {
              groupTaxes.push(JSON.parse(JSON.stringify(f)));
            }
          });
        });
        data.order.group_tax = groupTaxes;
        if (!Array.isArray(data.order.tax_breakup) || data.order.tax_breakup.length == 0) {
          data.order.tax_breakup = groupTaxes;
        }

        if (data.user.profile_img_url) {
          data.user.profile_img_url = BaseConfig.config.API + data.user.profile_img_url;
        }

        data.user.phone = (data.user.alt_address && data.user.alt_address.length > 0 && data.user.alt_address[data.user.alt_address.length - 1].contact_no) ? (data.user.alt_address[data.user.alt_address.length - 1].contact_no) : (data.user.phone) ? data.user.phone : null;
        data.user.address = data.user.address ? data.user.address : (data.user.alt_address && data.user.alt_address.length > 0) ? (data.user.alt_address[data.user.alt_address.length - 1].address) : null;

        ordersData.result.diary_history.forEach(data => {
          if (data.comment == 'credit' || data.comment == 'credit_due') {
            data['title'] = 'Credit Due Amount';
          } else if (data.comment == 'credit_void') {
            data['title'] = 'Credit Void';
          } else if (data.comment == 'used' || data.comment == 'used_due') {
            data['title'] = 'Pay from diary';
          } else if (data.comment == 'used_void') {
            data['title'] = 'Pay from diary void';
          } else if (data.comment == 'outstanding' || data.comment == 'outstanding_due') {
            data['title'] = "Outstanding Amount";
          } else if (data.comment == 'advance' || data.comment == 'advance_due') {
            data['title'] = "Advance Amount";
          } else if (data.comment == 'credit_note_advance') {
            data['title'] = "Credit Note Amount";
          } else {
            data['title'] = data.comment;
          }
        })

        let payment_list = [];
        if (data.order.split_payment_details) {
          for (var key in data.order.split_payment_details) {
            if (data.order.split_payment_details.hasOwnProperty(key)) {

              if (Array.isArray(data.order.split_payment_details[key].payment_sub_class_info) && data.order.split_payment_details[key].payment_sub_class_info.length > 0) {
                data.order.split_payment_details[key].payment_sub_class_info.forEach(data => {
                  let tempPaymentDetail = {
                    title: data.sub_class_name,
                    amount: data.amount,
                    payment_code: key
                  }
                  if (key == APIHelper.SAVE_TO_DIARY_TYPE) {
                    /*obj['comment'] = billInfo.save_to_diary_comment;
                    obj.title = "Save to Diary";
                    obj.payment_code = APIHelper.SAVE_TO_DIARY_TYPE;*/
                  } else {
                    payment_list.push(tempPaymentDetail);
                  }
                });
              } else {
                let obj = {
                  title: data.order.split_payment_details[key].name,
                  amount: data.order.split_payment_details[key].amount,
                  payment_code: key
                }
                if (key == APIHelper.SAVE_TO_DIARY_TYPE) {
                  /*obj['comment'] = data.order.save_to_diary_comment;
                  obj.title = "Save to Diary";
                  obj.payment_code = APIHelper.SAVE_TO_DIARY_TYPE;*/
                } else {
                  payment_list.push(obj);
                }
              }
            }
          }
        }

        let is_clear_dairy_amount = false;
        ordersData.result.diary_history.forEach(data => {
          if (data.comment == 'clear') {
            is_clear_dairy_amount = true;
            /*let list = data.payment_details;
            if(list){
              for (var key in list) {
                if (list.hasOwnProperty(key)) {
                  if(Array.isArray(list[key].payment_sub_class_info) && list[key].payment_sub_class_info.length>0){
                    list[key].payment_sub_class_info.forEach( data =>{
                      let tempPaymentDetail ={
                        title:data.sub_class_name,
                        amount:data.amount,
                        payment_code:key
                      }

                      let is_found = false;
                      payment_list.forEach(payment=>{
                        if(payment.title == tempPaymentDetail.title){
                          payment.amount += tempPaymentDetail.amount;
                          is_found = true;
                        }
                      })

                      if(!is_found){
                        payment_list.push(tempPaymentDetail);
                      }
                    });
                  }else {
                    let obj ={
                      title:list[key].name,
                      amount:list[key].amount,
                      payment_code:key
                    }

                    let is_found = false;
                    payment_list.forEach(paymentData=>{
                      if(paymentData.title == obj.title){
                        paymentData.amount += obj.amount;
                        is_found = true;
                      }
                    })
                    if(!is_found){
                      payment_list.push(obj);
                    }
                  }
                }
              }
            }*/
          }
        })
        if (is_clear_dairy_amount) {
          ordersData.result.diary_history.forEach(data => {
            if (data.comment == 'used') {
              let obj = {
                title: 'Pay from diary',
                amount: data.diary_amount,
                payment_code: 'PAY_FROM_DAIRY'
              }
              payment_list.push(obj);
            }
          })
        }

        let assignToId = data.order.shop_user_id;
        if (assignToId == -1) {
          data.order['assignTo'] = null;
        } else {
          let shop_user_list = JSON.parse(localStorage.getItem('shop_user_list'));
          for (var i = 0; i < shop_user_list.length; ++i) {
            if (shop_user_list[i].id == assignToId) {
              data.order['assignTo'] = {
                name: shop_user_list[i].name,
                id: shop_user_list[i].id
              }
            }
          }
        }

        if (data.order.is_order_cancelled) {
          data.order.order_status = APIHelper.ORDER_COMPLETED;
          data.order.shipping_type = APIHelper.IMMEDIATE;
        }

        data.order['assign'] = null;
        let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
        if (loggedInUser) {
          data.order['assign'] = {
            'id': loggedInUser.id,
            'name': loggedInUser.name
          };
        }
        data.order.split_payment = payment_list;
        this.order = data;        
        this.checkDairyHistory();
        this.cdRef.detectChanges();

        let tempOrders = JSON.parse(localStorage.getItem('temp_orders_list')) || [];
        for(var i=0,fLen=tempOrders.length;i<fLen;i++) {
          if (tempOrders[i].order.order_id == this.order.order.order_id) {
            tempOrders[i] = Object.assign(tempOrders[i],JSON.parse(JSON.stringify(this.order)));
          }
        }        
        localStorage.setItem('temp_orders_list', JSON.stringify(tempOrders));
      }
    });
  }

  // clearAmount() {
  //   let temp_client_reference_code = this.order.client_reference_code;
  //   if (this.order.user.alt_address && this.order.user.alt_address.length > 0) {
  //     this.order.order["userDetail"] = this.order.user.alt_address;
  //   } else {
  //     this.order.user.contact_no = this.order.user.phone;
  //     this.order.order["userDetail"] = [this.order.user];
  //   }

  //   localStorage.setItem('selectedOrderDetail', JSON.stringify(this.order));
  //   let tempCurrentUnit: any = {
  //     location: this.order.order.shop_section ? this.order.order.shop_section : 'NA',
  //     tables: [0],
  //     covers: 1,
  //     activePhase: {
  //       name: "green",
  //       timeStamp: new Date().valueOf(),
  //       timerText: "00:00:00"
  //     },
  //     visitors: [
  //       {
  //         dishes: [[]],
  //         kot: [],
  //         temp_client_reference_code: temp_client_reference_code
  //       }],

  //   }
  //   let other_discounts = []
  //   if (Array.isArray(this.order.discount_info)) {
  //     this.order.discount_info.forEach(discount => {
  //       other_discounts.push({
  //         is_exclusive: false,
  //         is_external: true,
  //         is_max_discount_val: false,
  //         name: discount.discount_code,
  //         type: "regular_discount",
  //         value: discount.claimed_value * (-1)
  //       })
  //     })
  //   }

  //   if (this.order.order.shipping_type) {
  //     tempCurrentUnit.delivery_type = this.order.order.shipping_type;
  //   }

  //   let extraCharges = JSON.parse(JSON.stringify(this.order.order.extra_charges));
  //   if (Array.isArray(extraCharges) && extraCharges[0]) {
  //     tempCurrentUnit['isExtraChargeChanges'] = true;
  //     tempCurrentUnit['ExtraCharges'] = extraCharges;
  //   }
  //   tempCurrentUnit.other_discounts = JSON.parse(JSON.stringify(other_discounts));
  //   this.stateGuard.entrust('selects-box.component:currentUnit', tempCurrentUnit);
  //   var self = this;
  //   for (var i = 0; i < this.order.items.length; ++i) {
  //     this.menuComponent.addItems(this.order.items[i]);
  //     if (i == (this.order.items.length - 1)) {
  //       setTimeout(() => {
  //         let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
  //         tempUnit.other_discounts = JSON.parse(JSON.stringify(other_discounts));
  //         this.stateGuard.entrust('selects-box.component:currentUnit', tempCurrentUnit);

  //         self.router.navigate(['/pages/register/food-ordering/payment']);
  //       }, 1500);
  //     }
  //   }
  // }

  cancelOrderInBackground(reason, reversePaymentType, isDuplicate) {
    this.callPaymentInstance();
    let sendData: any = {};
    let Items = JSON.parse(JSON.stringify(this.order.items));

    let isDiscountFound = false;
    let total_item_count = 0;
    Items.forEach((item) => {
      total_item_count += item.item_count;
      item.item_price = item.item_price * (-1);
      //item.item_count = item.item_count * (-1);
      item.tax_value = item.tax_value * (-1);
      delete item.order_id;
      delete item.id;
      delete item.createdAt;
      delete item.updatedAt;
      if (item.discount) {
        item.discount = parseFloat(item.discount) * (-1);
        isDiscountFound = true;
      }
      item.tax_breakup.forEach(function (f: any) {
        f.value = f.value * (-1);
      });
      item.unit_price = item.unit_price * (-1);
    });

    let tempBill = localStorage.getItem('ref_bill_num');
    let device_type = JSON.parse(localStorage.getItem('device_key'));

    let billNo = tempBill ? parseInt(tempBill) : 1;
    let terminal_name = (device_type && device_type.terminal_name) ? device_type.terminal_name : null;
    let terminal_id = (device_type && device_type.terminal_id) ? device_type.terminal_id : null;
    let tempOrderId = billNo + '/' + terminal_name + "-" + terminal_id + '/WBP' + (new Date()).getTime();

    let newBillNo = billNo + 1;
    localStorage.setItem('ref_bill_num', JSON.stringify(newBillNo));

    let client_reference_code = '';
    if (this.order.order.client_reference_code) {
      client_reference_code = this.order.order.client_reference_code;
    } else {
      client_reference_code = this.order.order.order_id;
    }

    sendData['order_id'] = tempOrderId;
    //sendData['order_id'] = '';

    sendData['shopCode'] = localStorage.getItem('shop_code');
    sendData['paymentType'] = 'PAYT0003';
    //sendData['kot_items'] = this.order.kot_items;
    sendData['kot_items'] = [];

    sendData['cart_items'] = Items;
    sendData['deliveryDate'] = new Date();
    sendData['createdAt'] = new Date();
    sendData['deliveryType'] = this.order.order.shipping_type;
    sendData['shipping_type'] = (this.order.order.shipping_type) ? JSON.parse(JSON.stringify(this.order.order.shipping_type)) : "";
    let current_customer_info = JSON.parse(localStorage.getItem('current_customer_info'));
    sendData['customer_auth_token'] = current_customer_info.auth_token;
    sendData['table_number'] = this.order.order.table_number;
    sendData['table_covers'] = this.order.order.table_covers;
    sendData['shop_section'] = this.order.order.shop_section;
    sendData['non_chargeable'] = 'false';

    sendData['prev_client_reference_code'] = client_reference_code;
    sendData['prev_order_id'] = this.order.order.order_id;

    let charges = JSON.parse(JSON.stringify(this.order.order.extra_charges));
    if (charges) {
      charges.forEach((charge) => {
        charge.value = charge.value * (-1);
      });
    }

    sendData['extraCharges'] = charges;
    sendData['partner_ids_list'] = this.order.order.partner_ids;
    sendData['shop_user_id'] = this.order.order.shop_user_id;
    sendData['cancel_order_flag'] = 'true';
    sendData['cancel_order_comment'] = reason;
    sendData['register_customer_info_flag'] = true;
    sendData["register_customer_data"] = {
      "add_address": {
        "address": {
          "address": this.order.user.address,
          "city": this.order.user.city,
          "state": this.order.user.state,
          "zip_code": this.order.user.zip_code,
          "contact_no": this.order.user.phone,
          "mobile_no": this.order.user.phone,
          "phone": this.order.user.phone
        }
      },
      customer_update_data: {
        "user": {
          "city": this.order.user.city,
          "state": this.order.user.state,
          "email": this.order.user.email,
          "name": this.order.user.name,
          "phone": this.order.user.phone,
          "zip_code": this.order.user.zip_code,
          "shop_code": this.order.order.shop_code
        }
      },
      contact_search_data: {
        "mobile_no": this.order.user.phone,
        "local_user_id": "",
        "shop_code": this.order.order.shop_code
      }
    }


    let discount_amount = 0;
    let rewards_amount = 0;
    if (this.order.order.discount) {
      discount_amount = (typeof this.order.order.discount == 'string') ? parseFloat(this.order.order.discount) : this.order.order.discount;
    }

    if (this.order.order.rewards_amount) {
      rewards_amount = (typeof this.order.order.rewards_amount == 'string') ? parseFloat(this.order.order.rewards_amount) : this.order.order.rewards_amount;
    }
    discount_amount = discount_amount * (-1);
    rewards_amount = rewards_amount * (-1);

    sendData['discount_amount'] = discount_amount;
    sendData['cash_discount_amount'] = this.order.order.cash_discount ? (this.order.order.cash_discount * (-1)) : 0;
    sendData['rewards_amount'] = rewards_amount;

    let valued_at = (this.order.order.valued_at) ? this.order.order.valued_at : 0;
    let rounding_value = (this.order.order.rounding_value) ? this.order.order.rounding_value : 0;
    let cart_tax = 0;
    let extra_charges = JSON.parse(JSON.stringify(this.order.order.extra_charges));

    let extra_charges_amount = 0;

    if (extra_charges) {
      extra_charges.forEach((charge) => {
        extra_charges_amount += charge.value;
      });
    }

    let groupTaxes = JSON.parse(JSON.stringify(this.order.order.group_tax));

    if (groupTaxes) {
      groupTaxes.forEach((tax) => {
        cart_tax += tax.value;
        tax.value = tax.value * (-1);
      });
    }

    if (this.order.order.tax_breakup) {
      this.order.order.tax_breakup.forEach(tb => {
        cart_tax += tb.value;
        tb.value = tb.value * (-1);
      })
      this.order.order.tax_value *= (-1);
    }

    let cart_value = valued_at - extra_charges_amount - (rounding_value) - cart_tax + (discount_amount * (-1)) + (sendData["cash_discount_amount"] * (-1)) + (rewards_amount * (-1));
    valued_at = valued_at * (-1);
    cart_value = cart_value * (-1);
    rounding_value = rounding_value * (-1);
    cart_tax = cart_tax * (-1);

    let save_to_diary_amount = 0;
    let pay_from_diary_amt = 0;
    let save_to_diary_comment = '';
    let clear_balance_payment_object: any = null;
    let temp_offline_diary_history = [];
    /*if(!reversePaymentType) {
      if(this.order.order.split_payment){
        this.order.order.split_payment.forEach((payment) =>{
          if(payment.payment_code == APIHelper.SAVE_TO_DIARY_TYPE){
            save_to_diary_amount = payment.amount;
            save_to_diary_comment = (payment.comment)? payment.comment : '';
          }else if(payment.payment_code == "PAY_FROM_DIARY"){
          }
        });
      }
    } else {*/
    for (var i = 0; i < this.order.diary_history.length; i++) {
      var diary = this.order.diary_history[i];
      var tempDiary = JSON.parse(JSON.stringify(diary));
      tempDiary.diary_amount = tempDiary.diary_amount * (-1);
      if (!clear_balance_payment_object && (diary.comment == 'credit' || diary.comment == 'credit_due' || diary.comment == 'outstanding')) {
        save_to_diary_amount = diary.diary_amount * (-1);
        save_to_diary_comment = 'credit_void';
        tempDiary.comment = 'credit_void';
        tempDiary.title = 'Credit Void';
        temp_offline_diary_history.push(tempDiary);
      } else if (diary.comment == "clear" && diary.is_clear) {
        save_to_diary_amount = 0;
        save_to_diary_comment = "";
        if (diary.payment_details) {
          clear_balance_payment_object = JSON.parse(JSON.stringify(diary.payment_details));
        }
      } else if (diary.comment == "used") {
        pay_from_diary_amt = diary.diary_amount * (-1);
        tempDiary.comment = 'used_void';
        tempDiary.title = 'Pay from diary void';
        temp_offline_diary_history.push(tempDiary);
      }
    }
    /*this.order.diary_history.forEach(diary =>{
      if(diary.comment == 'credit' || diary.comment == 'credit_due' || diary.comment == 'outstanding'){
          save_to_diary_amount = diary.diary_amount * (-1);
          save_to_diary_comment = "clear";
      }
    })*/
    //}
    sendData['save_to_diary_amount'] = save_to_diary_amount;
    sendData['save_to_diary_comment'] = save_to_diary_comment;
    sendData['pay_from_diary_amt'] = pay_from_diary_amt;

    let splitPayment = {};
    let splitPaymentDetailList = {};
    if (!reversePaymentType) {

      /*if(Array.isArray(this.order.order.split_payment)){
        this.order.order.split_payment.forEach(payment =>{
          splitPayment.push({title:payment.title,amount:payment.amount * (-1),payment_code:payment.payment_code});
        })
      }*/


      splitPaymentDetailList = JSON.parse(JSON.stringify(this.order.order.split_payment_details));

      if (splitPaymentDetailList) {
        for (var key in splitPaymentDetailList) {
          if (splitPaymentDetailList[key] && splitPaymentDetailList[key].amount) {
            splitPayment[key] = splitPaymentDetailList[key].amount * (-1);
            splitPaymentDetailList[key].amount = splitPaymentDetailList[key].amount * (-1);
            if (splitPaymentDetailList[key].payment_sub_class_info && splitPaymentDetailList[key].payment_sub_class_info.length > 0) {
              splitPaymentDetailList[key].payment_sub_class_info.forEach(data => {
                data.amount = data.amount * (-1);
              })
            }
          }
        }
      }
    } else {
      /*if(Array.isArray(this.order.order.split_payment)){
        this.order.order.split_payment.forEach(payment =>{
          totalPaymentAmount += payment.amount;
        })
      }*/

      var totalPaymentAmount = 0;
      var tempSplitPaymentDetailList = JSON.parse(JSON.stringify(this.order.order.split_payment_details));

      if (tempSplitPaymentDetailList) {
        for (var key in tempSplitPaymentDetailList) {
          if (tempSplitPaymentDetailList[key] && tempSplitPaymentDetailList[key].amount && key != APIHelper.SAVE_TO_DIARY_TYPE) {
            totalPaymentAmount += tempSplitPaymentDetailList[key].amount;
          }
        }
      }

      /*if (clear_balance_payment_object) {
        for (var x in clear_balance_payment_object) {
          if (clear_balance_payment_object[x] && clear_balance_payment_object[x].amount) {
            if (clear_balance_payment_object[x].payment_sub_class_info && clear_balance_payment_object[x].payment_sub_class_info.length > 0) {
              clear_balance_payment_object[x].payment_sub_class_info.forEach(data => {
                totalPaymentAmount += data.amount;
              });
            } else {
              totalPaymentAmount += clear_balance_payment_object[x].amount;
            }
          }
        }
      }*/

      splitPayment[reversePaymentType.code] = totalPaymentAmount * (-1);
      sendData["paymentType"] = reversePaymentType.code;
      if (reversePaymentType.is_sub_class) {
        splitPaymentDetailList[reversePaymentType.code] = { name: reversePaymentType.type, amount: totalPaymentAmount * (-1), payment_sub_class_info: [] }
        splitPaymentDetailList[reversePaymentType.code]["payment_sub_class_info"].push({ "sub_class_name": reversePaymentType.name, amount: totalPaymentAmount * (-1) })
      } else {
        splitPaymentDetailList[reversePaymentType.code] = { name: reversePaymentType.name, amount: totalPaymentAmount * (-1), payment_sub_class_info: [] }
      }
    }
    
    sendData['record_item_wise_discount'] = isDiscountFound;
    sendData['shop_section'] = JSON.parse(JSON.stringify(this.order.order.shop_section));
    sendData['cart_value'] = cart_value;
    sendData['valued_at'] = valued_at;
    sendData['splitPayment'] = splitPayment;
    sendData['splitPaymentDetail'] = splitPaymentDetailList;
    sendData['cart_tax'] = cart_tax;
    sendData['rounding_value'] = rounding_value;
    sendData['tax_breakup'] = JSON.parse(JSON.stringify(this.order.order.tax_breakup));
    sendData['tax_value'] = JSON.parse(JSON.stringify(this.order.order.tax_value));
    sendData['tax_rate'] = JSON.parse(JSON.stringify(this.order.order.tax_rate));
    sendData['tax_type'] = JSON.parse(JSON.stringify(this.order.order.tax_type));
    sendData['employer_code'] = JSON.parse(JSON.stringify(this.order.order.employer_code));
    sendData['tax_number'] = JSON.parse(JSON.stringify(this.order.order.tax_number));
    sendData['isOfflineOrder'] = true;
    sendData['discount_info'] = [];
    sendData["user"] = JSON.parse(JSON.stringify(this.order.user));
    sendData["items"] = JSON.parse(JSON.stringify(sendData['cart_items']));
    sendData['diary_history'] = temp_offline_diary_history;
    sendData['total_item_count'] = total_item_count;

    let torder = {
      'order_id': sendData.order_id,
      'order_status': 'offline',
      'shipping_type': sendData.deliveryType,
      'payment_type': sendData.paymentType,
      'shop_section': sendData.shop_section,
      'delivery_date': sendData.deliveryDate,
      'valued_at': sendData.valued_at,
      'createdAt': sendData.createdAt,
      'discount': sendData.discount_amount ? sendData.discount_amount : 0,
      'cash_discount': sendData.cash_discount_amount ? sendData.cash_discount_amount : 0,
      'other_discount': 0,
      'discount_reason': "",
      'discount_phone_no': "",
      'rounding_value': rounding_value,
      'total_item_count': total_item_count,
      'extra_charges': sendData.extraCharges,
      'group_tax': JSON.parse(JSON.stringify(groupTaxes)),
      'tax_breakup': sendData.tax_breakup,
      'split_payment': [],
      'split_payment_details': sendData.splitPaymentDetail,
      'partner_ids': sendData.partner_ids_list,
      'employer_code': sendData.employer_code,
      'tax_number': sendData.tax_number,
      'table_number': sendData.table_number,
      'table_covers': sendData.table_covers,
      'comment': JSON.parse(JSON.stringify(this.order.order.comment)),
      'cancel_order_comment': sendData.cancel_order_comment
    };

    for (var x in sendData.splitPaymentDetail) {
      if (x != APIHelper.SAVE_TO_DIARY_TYPE) {
        var tempPayment = [];
        tempPayment.push({
          title: sendData.splitPaymentDetail[x].name,
          amount: sendData.splitPaymentDetail[x].amount,
          payment_code: x
        })

        var subClassInfo = sendData.splitPaymentDetail[x].payment_sub_class_info;
        if (subClassInfo && subClassInfo.length > 0) {
          tempPayment = [];
          subClassInfo.forEach(sub_class_payment => {
            tempPayment.push({
              title: sub_class_payment.sub_class_name,
              amount: sub_class_payment.amount,
              payment_code: x
            })
          })
        }

        torder.split_payment = torder.split_payment.concat(tempPayment);
      }
    }

    let assignToId = sendData.shop_user_id;
    if (assignToId == -1) {
      torder['assignTo'] = null;
    } else {
      let shop_user_list = JSON.parse(localStorage.getItem('shop_user_list'));
      for (var i = 0; i < shop_user_list.length; ++i) {
        if (shop_user_list[i].id == assignToId) {
          torder['assignTo'] = {
            name: shop_user_list[i].name,
            id: shop_user_list[i].id
          }
        }
      }
    }

    torder['assign'] = null;
    let loggedInUserData = JSON.parse(localStorage.getItem('loggedInUser'));
    if (loggedInUserData) {
      torder['assign'] = {
        'id': loggedInUserData.id,
        'name': loggedInUserData.name
      };
    }

    if (torder.comment && torder.comment.employeeId) {
      if (localStorage.getItem('shop_user_list')) {
        let userList = JSON.parse(localStorage.getItem('shop_user_list'));
        for (var i = 0; i < userList.length; ++i) {
          if (userList[i].id.toString() == torder.comment.employeeId.toString()) {
            torder.comment['emp_name'] = userList[i].name;
            break;
          }
        }
      }
    }
    sendData["order"] = torder;
    /* this.paymentHelper.bill(sendData).then((res) => {
       if(res){*/

    let cash_total = 0;
    for (var key in sendData.splitPayment) {
      if (sendData.splitPayment[key]) {
        if (key == APIHelper.CASH_IN_HAND_TYPE || key == APIHelper.CASH_ON_DELIVERY_TYPE) {
          cash_total += sendData.splitPayment[key] * (-1);
        }
      }
    }

    let availableAmount = 0;
    if (localStorage.getItem('cash_register')) {
      let cash_register = JSON.parse(localStorage.getItem('cash_register'));
      if (cash_register && cash_register[0]) {
        availableAmount = cash_register[0].cash_sale + cash_register[0].start_cash_in.cash_in_amount + cash_register[0].paid_in - cash_register[0].paid_out;
        //availableAmount =  cash_register[0].cash_sale;
      }

      if (availableAmount < cash_total) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not enough cash available. Kindly, Do cash in.' });
        return;
      } else if (cash_total > 0) {
        if (cash_register && cash_register[0]) {
          cash_register[0].cash_return = cash_total + cash_register[0].cash_return;
        }
        localStorage.setItem('cash_register', JSON.stringify(cash_register));
      }
    }

    let bill_queue = JSON.parse(localStorage.getItem('bill_queue')) || [];
    bill_queue.push(JSON.parse(JSON.stringify(sendData)));
    localStorage.setItem('bill_queue', JSON.stringify(bill_queue));

    var self = this;
    let orders = JSON.parse(localStorage.getItem('temp_orders_list'));
    if (orders) {
    } else {
      orders = [];
    }
    orders.forEach(temp_order => {
      if (temp_order.client_reference_code == client_reference_code) {
        temp_order.order.is_order_cancelled = true;
      }
    });
    this.order.order.is_order_cancelled = true;
    localStorage.setItem('temp_orders_list', JSON.stringify(orders));
    if (isDuplicate) {
      let device_type = JSON.parse(localStorage.getItem('device_key'));
      let terminal_name = (device_type && device_type.terminal_name) ? device_type.terminal_name : null;
      let terminal_id = (device_type && device_type.terminal_id) ? device_type.terminal_id : null;
      let temp_client_reference_code = terminal_name + "-" + terminal_id + '/WBP' + (new Date()).getTime();
      let tempCurrentUnit: any = {
        location: this.order.order.shop_section ? this.order.order.shop_section : 'NA',
        tables: [0],
        covers: 1,
        activePhase: {
          name: "green",
          timeStamp: new Date().valueOf(),
          timerText: "00:00:00"
        },
        visitors: [
          {
            dishes: [[]],
            kot: [],
            temp_client_reference_code: terminal_name + "-" + terminal_id + '/WBP' + (new Date()).getTime()
          }]
      }

      if (this.order.order.shipping_type) {
        tempCurrentUnit.delivery_type = this.order.order.shipping_type;
      }

      let extraCharges = JSON.parse(JSON.stringify(this.order.order.extra_charges));
      if (Array.isArray(extraCharges) && extraCharges[0]) {
        tempCurrentUnit['isExtraChargeChanges'] = true;
        tempCurrentUnit['ExtraCharges'] = extraCharges;
      }
      this.stateGuard.entrust('selects-box.component:currentUnit', tempCurrentUnit);
      this.makeDuplicateOrder();
    }
    /*setTimeout(()=>{
      //self.cancelOrderComplete.emit(true);
      //self.messageService.add({ severity: 'success', summary:'Success', detail: 'Order successfully cancelled.'});
    },10);
  }else{
    this.messageService.add({ severity: 'error', summary:'This order is already cancelled', detail: ''});
  }
});*/
  }

  findItemDiscount(item) {
    let discount: any = 0;
    if (item.discount_type == '%' && item.discount) {
      if (typeof item.discount == 'string') {
        item.discount = parseFloat(item.discount);
      }
      if (typeof item.item_price == 'string') {
        item.item_price = parseFloat(item.item_price);
      }
      discount += ((parseInt(item.item_count) * item.item_price * item.discount) / 100);
    } else {
      discount += item.discount;
    }

    if (Array.isArray(item.other_discounts) && item.other_discounts.length > 0) {
      if (typeof item.item_price == 'string') {
        item.item_price = parseFloat(item.item_price);
      }
      item.other_discounts.forEach(iod => {
        if (iod.discount) {
          if (typeof iod.discount == 'string') {
            iod.discount = parseFloat(iod.discount);
          }
          if (iod.discount_type == '%') {
            discount += (parseInt(item.item_count) * ((item.item_price * iod.discount) / 100));
          } else {
            discount += parseInt(item.item_count) * parseFloat(iod.discount);
          }
        }
      })
    }

    discount = parseFloat(discount).toFixed(3)
    if (this.order.is_order_cancelled) {
      return discount;
    } else {
      return discount * (-1);
    }
  }

  getDiscountClaimedValue(amount) {

    if (typeof amount == 'string') {
      amount = parseFloat(amount);
    }

    if (this.order.is_order_cancelled) {
      return (amount);
    } else {
      return (amount * (-1));
    }

  }


  getTotalDiscountAmount(amount) {

    if (typeof amount == 'string') {
      amount = parseFloat(amount);
    }

    if (this.order.is_order_cancelled) {
      return (amount);
    } else {
      return (amount * (-1));
    }
  }


  getDiscountAmount(amount) {
    return (amount * (-1));
  }

  showOrderVoidModal() {
    if (!this.order.order.client_reference_code || this.order.order.client_reference_code == "NA") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "can not cancel the order" });
      return;
    }

    this.modalRef = this.cancelModalService.show(ConfirmationModal, { class: 'centred-modal' });
    this.modalRef.content.decision = "";
    this.modalRef.content.text = "Are you sure to void this order. this will reset payment types of this order and mark order status as void?";

    this.subscriptions.push(this.cancelModalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'yes') {
        setTimeout(() => {
          this.voidOrder();
        }, 100);
      }
      this.unsubscribe();
    }));
  }

  showCancelOrderModal() {
    if (!this.order.order.client_reference_code || this.order.order.client_reference_code == "NA") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "can not cancel the order" });
      return;
    }
    this.modalRef = this.modalService.show(CancelOrderConfirmationModalComponent, { class: 'centred-modal' });
    this.modalRef.content.decision = "";
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'cancel') {

        this.modalRef = this.cancelModalService.show(ConfirmationModal, { class: 'centred-modal' });
        this.modalRef.content.decision = "";
        this.modalRef.content.text = "Are you sure to cancel this order?";

        this.subscriptions.push(this.cancelModalService.onHide.subscribe(() => {
          if (this.modalRef.content.decision === 'yes') {
            setTimeout(() => {
              this.getCancelOrderReason(false);
            }, 100);
          } else {
          }
          this.unsubscribe();
        }));
      } else if (this.modalRef.content.decision === 'duplicate') {
        var self = this;
        setTimeout(() => {
          self.getCancelOrderReason(true);
        }, 100);
        this.unsubscribe();
      }
    }));
  }

  checkForEnoughBalance() {
    return new Promise((resolve, reject) => {
      let cash_total = 0;
      if (Array.isArray(this.order.order.split_payment)) {
        this.order.order.split_payment.forEach(payment => {
          if (payment.payment_code == APIHelper.CASH_IN_HAND_TYPE || payment.payment_code == APIHelper.CASH_ON_DELIVERY_TYPE) {
            cash_total += payment.amount;
          }
        })
      }

      let availableAmount = 0;
      if (localStorage.getItem('cash_register')) {
        let cash_register = JSON.parse(localStorage.getItem('cash_register'));
        if (cash_register && cash_register[0]) {
          availableAmount = cash_register[0].cash_sale + cash_register[0].start_cash_in.cash_in_amount + cash_register[0].paid_in - cash_register[0].paid_out;
          //availableAmount =  cash_register[0].cash_sale;
        }

        if (availableAmount < cash_total) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not enough cash available. Kindly, Do cash in.' });
          return reject("")
        }
      }
      return resolve(true)
    })
  }

  getCancelOrderReason(isRedirect) {
    this.checkForEnoughBalance().then(result => {
      var self = this;
      let valued_at = (this.order.order.valued_at) ? this.order.order.valued_at : 0;
      let save_to_diary_amount = 0;
      let pay_from_diary_amt = 0;

      let clear_balance_payment_object: any = null;
      for (var i = 0; i < this.order.diary_history.length; i++) {
        var diary = this.order.diary_history[i];
        if (!clear_balance_payment_object && (diary.comment == 'credit' || diary.comment == 'credit_due' || diary.comment == 'outstanding')) {
          save_to_diary_amount = Math.abs(diary.diary_amount);
        } else if (diary.comment == "clear" && diary.is_clear) {
          save_to_diary_amount = 0;
          if (diary.payment_details) {
            clear_balance_payment_object = JSON.parse(JSON.stringify(diary.payment_details));
          }
        } else if (diary.comment == "used") {
          pay_from_diary_amt = Math.abs(diary.diary_amount);
        }
      }
      this.modalRef = this.modalService.show(CancelOrderReasonModalComponent, { class: 'centred-modal' });
      this.modalRef.content.decision = "";
      this.modalRef.content.open_from = "recent-order";
      this.modalRef.content.is_only_save_to_diary_payment = ((pay_from_diary_amt + save_to_diary_amount) == valued_at) ? true : false;

      this.modalRef.content.reasonList = [
        {
          'text': 'Damaged',
          'id': APIHelper.KOT_DAMAGED
        },
        {
          'text': 'Customer Cancelled',
          'id': APIHelper.KOT_CUSTOMER_CANCELLED
        },
        {
          'text': 'Delivery Delayed',
          'id': APIHelper.KOT_DELIVERY_DELAYED
        },
        {
          'text': 'Other',
          'id': APIHelper.KOT_OTHER
        }
      ]

      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'send') {
          let reasonList = this.modalRef.content.reasonList;
          let id = this.modalRef.content.selectedReason;
          let reversePaymentType = this.modalRef.content.selectedReversePaymentType
          let selectedReason = null;
          reasonList.forEach(reason => {
            if (reason.id.toString() == id) {
              selectedReason = reason.text;
            }
          });

          if (isRedirect) {
            this.cancelOrderInBackground(selectedReason, reversePaymentType, true);
          } else {
            this.cancelOrderInBackground(selectedReason, reversePaymentType, false);
          }
          this.unsubscribe();
        }
      }));
    })
      .catch(err => {
        console.log(err)
      })
  }
  fetchOrder(order_id) {
    if (!order_id) { return; }
    this.fetchOrderDetail.emit(order_id);
  }
  getTypeCode(name) {
    return APIHelper[name];
  }

  selectDeliveryType(dev_type) {
    if (this.order.order.order_status == APIHelper.ORDER_EDITED) {
      this.order.order.shipping_type = dev_type;
    }
  }

  selectPaymentType(pay_type) {
    if (this.order.order.order_status == APIHelper.ORDER_EDITED) {
      this.order.order.payment_type = pay_type;
    }
  }

  showDeliveryTimeModal(id, type) {
    if (this.order.order.third_party_order_id) {
      this.modalRef = this.modalService.show(ThirdPartyDeliveryTimeModalComponent, { class: 'centred-modal' });
      this.modalRef.content.decision = "";
      this.modalRef.content.delivery_time = 30;
      this.modalRef.content.prep_time = 30;
      let cSub: Subscription = this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'done') {
          this.acceptOrder(id, this.modalRef.content.delivery_time, this.modalRef.content.prep_time, type);
        }
        cSub.unsubscribe();
      });
    } else {
      this.acceptOrder(id, 0, 0, type);
    }
  }

  showRiderTemperatureModal(id) {
    if (this.order.order.third_party_order_id) {
      this.modalRef = this.modalService.show(ThirdPartyRiderTemperatureModalComponent, { class: 'centred-modal' });
      this.modalRef.content.decision = "";
      this.modalRef.content.temperature = 0;
      this.modalRef.content.rider_phone_number = "";
      let cSub: Subscription = this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'done') {
          this.riderTemperatureDetail(id, this.modalRef.content.temperature, this.modalRef.content.rider_phone_number);
        }
        cSub.unsubscribe();
      });
    }
  }

  showRejectOrderReasonModal(id) {
    if (this.order.order.third_party_order_id) {
      this.modalRef = this.modalService.show(ThirdPartyRejectOrderReasonModalComponent, { class: 'centred-modal' });
      this.modalRef.content.decision = this.order.order.partner_short_code;
      this.modalRef.content.third_party_partner = "";
      if (this.order.order.partner_ids && this.order.order.partner_ids.length > 0) {
        this.modalRef.content.third_party_partner = this.order.order.partner_ids[0].partner_short_code;
        console.log('Recent Order:', this.order.order.partner_ids[0].partner_short_code);
      }
      if (this.order.order.third_party_order_other_information && this.order.order.third_party_order_other_information.time_to_penalty_map && this.order.order.third_party_order_other_information.time_to_penalty_map.length > 0) {
        this.modalRef.content.time_to_penalty_map = this.order.order.third_party_order_other_information.time_to_penalty_map;
      }
      this.modalRef.content.order_created_at = new Date(this.order.order.createdAt);
      let cSub: Subscription = this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'done') {
          this.rejectOrder(id, this.modalRef.content.reject_reason);
        }
        cSub.unsubscribe();
      });
    } else {
      this.rejectOrder(id, "");
    }
  }

  setThirdPartyOrderMessage() {
    let isFound = false;
    if (this.order.order.third_party_order_other_information && this.order.order.third_party_order_other_information.order_messages && this.order.order.third_party_order_other_information.order_messages.length > 0) {
      let order_messages = this.order.order.third_party_order_other_information.order_messages;
      order_messages = order_messages.sort((a, b) => { return (a.message_order - b.message_order); });
      let currentTimeStamp = new Date().getTime();
      for (var i = 0, olen = order_messages.length; i < olen; i++) {
        let messageTimeStamp: any = new Date(order_messages[i].message_exp_time);
        if (messageTimeStamp) { messageTimeStamp = messageTimeStamp.getTime(); }
        if (messageTimeStamp > currentTimeStamp) {
          this.third_party_order_message.message = order_messages[i].message;
          this.third_party_order_message.color = order_messages[i].color;
          isFound = true;
          break;
        }
      }
    }
    if (!isFound) {
      this.third_party_order_message.message = "";
      this.third_party_order_message.color = "";
    }

    if (this.order.order.third_party_order_other_information && this.order.order.third_party_order_other_information.order_prep_time) {
      let orderPrepTime = new Date(this.order.order.third_party_order_other_information.order_prep_time);
      let tempTimeStr = this.getTimeDifferenceStr(orderPrepTime);
      this.third_party_order_message.order_ready_time_message = (tempTimeStr) ? 'Time Left (' + tempTimeStr + ')' : "Time over.";
    } else {
      this.third_party_order_message.order_ready_time_message = "";
    }
  }

  showRiderInfoModal(id, type) {
    this.modalRef = this.modalService.show(ThirdPartyRiderInfoModalComponent, { class: 'centred-modal' });
    this.modalRef.content.decision = "";
    this.modalRef.content.order_type = this.order.order.order_type ? this.order.order.order_type : "";
    let cSub: Subscription = this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'done') {
        if (type == "rider_picked_up") {
          this.riderPickedUpOrder(id, this.modalRef.content.rider_name, this.modalRef.content.rider_phone_number);
        } else {
          this.riderAssignedToOrder(id, this.modalRef.content.rider_name, this.modalRef.content.rider_phone_number);
        }
      }
      cSub.unsubscribe();
    });
  }

  acceptOrder(id, delivery_time, prep_time, type) {
    this.edit_items = false;
    let partnerNames = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.name ? op.name : ''; }) : []).join(",");
    let partnerShortCode = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.partner_short_code ? op.partner_short_code : ''; }) : []).join(",");
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'delivery_time': delivery_time,
      'prep_time': prep_time,
      'partner_short_code': partnerShortCode,
      'partner_name': partnerNames,
      'order_status': this.getTypeCode('ORDER_APPROVED'),
    }
    this.loading = true;
    this.recentOrdersService.acceptSelectedOrderInfo(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status !== 0 && ordersData.code != "-164") {
        alert(ordersData.message);
      } else {
        this.messageService.add({ severity: 'success', summary: 'Accept Order', detail: 'Order accepted successfully.' });

        let orderUStatus = APIHelper.ORDER_APPROVED;
        if (!this.order.order.reference_order_id && this.order.order.order_type == "ecom_subscription") {
          orderUStatus = APIHelper.ORDER_COMPLETED;
        }
        this.chnageOrderStatus(id, orderUStatus);
        this.order.order.order_status = orderUStatus;
        let t = new Date();
        if (!prep_time || prep_time <= 0) { prep_time = 30 }
        t.setMinutes(t.getMinutes() + prep_time)
        if (this.order.order.third_party_order_other_information) {
          this.order.order.third_party_order_other_information["order_prep_time"] = t;
        } else {
          this.order.order.third_party_order_other_information = { "order_prep_time": t };
        }
        this.setOrderMessageTimeIntervals();
        if (type == "accept") {
          this.closePopupModal();
        } else {
          this.printRecentOrderKOT()
          setTimeout(() => {
            this.closePopupModal();
          }, 1000);
        }
      }
    }, error => {
      this.loading = false;
    });
  }

  closePopupModal() {
    this.closeModal.emit(this.order.order);
  }

  getAdvanceAmount() {
    let advanceAmount = 0;
    this.order.order.split_payment.forEach(payment => {
      advanceAmount = advanceAmount + ((typeof payment.amount == 'string') ? parseFloat(payment.amount) : payment.amount);
    });
    return advanceAmount;
  }

  getTotalAmount() {
    let totalAmount = this.order.order.valued_at;
    this.order.order.extra_charges.forEach(charge => {
      totalAmount += charge.value;
    });
    return totalAmount;
  }

  rejectOrder(order_id, rejection_message) {
    this.edit_items = false;
    let partnerNames = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.name ? op.name : ''; }) : []).join(",");
    let partnerShortCode = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.partner_short_code ? op.partner_short_code : ''; }) : []).join(",");
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'rejection_message': rejection_message,
      'order_status': this.getTypeCode('ORDER_REJECTED'),
      'partner_short_code': partnerShortCode,
      'partner_name': partnerNames,
    }
    this.loading = true;
    this.recentOrdersService.rejectSelectedOrder(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status !== 0 && ordersData.code != "-165") {
        alert(ordersData.message);
      } else {
        this.messageService.add({ severity: 'success', summary: 'Reject Order', detail: 'Order rejected successfully.' });
        this.order.order.order_status = APIHelper.ORDER_REJECTED;
        this.chnageOrderStatus(order_id, APIHelper.ORDER_REJECTED);

        let ordersList = JSON.parse(localStorage.getItem('not_completed_orders')) || [];
        let tempList = JSON.parse(JSON.stringify(ordersList));
        for (var i = 0; i < tempList.length; ++i) {
          if (tempList[i].order_id == order_id) {
            ordersList.splice(i, 1);
            localStorage.setItem('not_completed_orders', JSON.stringify(ordersList));
            break;
          }
        }

        this.closePopupModal();
      }
    }, error => {
      this.loading = false;
    });
  }

  riderPickedUpOrder(order_id, rider_name, rider_phone_number) {
    this.edit_items = false;
    let partnerNames = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.name ? op.name : ''; }) : []).join(",");
    let partnerShortCode = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.partner_short_code ? op.partner_short_code : ''; }) : []).join(",");
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'rider_name': rider_name,
      'rider_phone_number': rider_phone_number,
      'order_status': this.getTypeCode('ORDER_PICKEDUP'),
      'partner_short_code': partnerShortCode,
      'partner_name': partnerNames,
    }
    this.recentOrdersService.riderPickedUpOrder(sendData).subscribe((res) => {
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        this.chnageOrderStatus(order_id, APIHelper.ORDER_PICKEDUP);
        this.order.order.order_status = APIHelper.ORDER_PICKEDUP;
      }
    });
  }

  riderAssignedToOrder(order_id, rider_name, rider_phone_number) {
    this.edit_items = false;
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'rider_name': rider_name,
      'rider_phone_number': rider_phone_number
    }
    this.recentOrdersService.riderAssignedToOrder(sendData).subscribe((res) => {
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        this.chnageOrderStatus(order_id, APIHelper.ORDER_ASSIGNED_TO_RIDER);
        this.order.order.order_status = APIHelper.ORDER_ASSIGNED_TO_RIDER;
      }
    });
  }

  riderTemperatureDetail(order_id, rbt, rider_phone_number) {
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'rbt': rbt,
      'rider_phone': rider_phone_number
    }

    this.recentOrdersService.riderTemperatureDetail(sendData).subscribe((res) => {
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        this.messageService.add({ severity: 'success', summary: 'Rider Temperature', detail: 'Rider temperature successfully submitted.' });
        /*this.chnageOrderStatus(order_id,APIHelper.ORDER_ASSIGNED_TO_RIDER);
        this.order.order.order_status = APIHelper.ORDER_ASSIGNED_TO_RIDER;*/
      }
    });
  }


  chnageOrderStatus(id, status) {
    let orders = JSON.parse(localStorage.getItem('temp_orders_list')) || [];
    orders.forEach(temp_order => {
      if (temp_order.order.order_id == id) {
        temp_order.order.order_status = status;
        if (status == APIHelper.ORDER_VOID) {
          temp_order.order.split_payment = [{ title: "VOID", amount: temp_order.order.valued_at, payment_code: APIHelper.VOID_PAYMENT_TYPE }];
          temp_order.order.payment_type = APIHelper.VOID_PAYMENT_TYPE;
          if (this.order && this.order.order) {
            this.order.order.split_payment = temp_order.order.split_payment;
            this.order.order.payment_type = APIHelper.VOID_PAYMENT_TYPE;
          }
        }
      }
    });
    localStorage.setItem('temp_orders_list', JSON.stringify(orders));
  }

  chnageOrderSelfServiceStatus(id, status) {
    let orders = JSON.parse(localStorage.getItem('temp_orders_list')) || [];
    orders.forEach(temp_order => {
      if (temp_order.order.order_id == id) {
        temp_order.order.self_service_order_status = status;
      }
    });
    localStorage.setItem('temp_orders_list', JSON.stringify(orders));
  }

  cancelOrder(order_id) {
    this.edit_items = false;
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id
    }
    this.recentOrdersService.cancelSelectedOrder(sendData).subscribe((res) => {
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        this.order.order.order_status = APIHelper.ORDER_REJECTED;
        this.chnageOrderStatus(order_id, APIHelper.ORDER_REJECTED);
      }
    });
  }

  paidPendingAmount(id) {
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id
    }
    this.recentOrdersService.paidAmount(sendData).subscribe((res) => {
      let ordersData = res.json();

      if (ordersData.status == 0) {
        this.isPaid = true;
        this.order.order.order_status = APIHelper.ORDER_DELIVERED;
        this.chnageOrderStatus(id, APIHelper.ORDER_DELIVERED)
        this.messageService.add({ severity: 'success', summary: 'Payment', detail: 'Payment marked as received' });
      }
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      }
    });
  }

  deliverOrder() {
    let partnerNames = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.name ? op.name : ''; }) : []).join(",");
    let partnerShortCode = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.partner_short_code ? op.partner_short_code : ''; }) : []).join(",");
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'order_status': this.getTypeCode('ORDER_DELIVERED'),
      'partner_short_code': partnerShortCode,
      'partner_name': partnerNames,
    }
    
    this.loading = true;
    this.recentOrdersService.deliverSelectedOrderInfo(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Order marked as delivered' })
        this.chnageOrderStatus(this.order.order.order_id, APIHelper.ORDER_DELIVERED);
        this.order.order.order_status = APIHelper.ORDER_DELIVERED;
      }
    }, error => {
      this.loading = false;
    });
  }

  dispatchOrder() {
    let partnerNames = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.name ? op.name : ''; }) : []).join(",");
    let partnerShortCode = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.partner_short_code ? op.partner_short_code : ''; }) : []).join(",");
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'order_status': this.getTypeCode('ORDER_DISPATCHED'),
      'partner_short_code': partnerShortCode,
      'partner_name': partnerNames,
    }

    this.loading = true;
    this.recentOrdersService.dispatchSelectedOrderInfo(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Order marked as dispatched' })
        this.chnageOrderStatus(this.order.order.order_id, APIHelper.ORDER_DISPATCHED);
        this.order.order.order_status = APIHelper.ORDER_DISPATCHED;
      }
    }, error => {
      this.loading = false;
    });
  }

  selfServiceDeliverOrder() {
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id
    }
    this.loading = true;
    this.recentOrdersService.selfServiceDeliverSelectedOrderInfo(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Order marked as self service delivered' })
        this.chnageOrderSelfServiceStatus(this.order.order.order_id, APIHelper.SELF_SERVICE_ORDER_DELIVERED);
        this.order.order.self_service_order_status = APIHelper.SELF_SERVICE_ORDER_DELIVERED;
      }
    }, error => {
      this.loading = false;
    });
  }

  thirdPartyOrderReady(order_id) {
    let partnerNames = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.name ? op.name : ''; }) : []).join(",");
    let partnerShortCode = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.partner_short_code ? op.partner_short_code : ''; }) : []).join(",");
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'order_status': this.getTypeCode('ORDER_READY'),
      'partner_short_code': partnerShortCode,
      'partner_name': partnerNames,
    }

    this.loading = true;
    this.recentOrdersService.thirdPartyOrderReady(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Order marked as ready to pickedup' })
        this.chnageOrderStatus(this.order.order.order_id, APIHelper.ORDER_READY);
        this.order.order.order_status = APIHelper.ORDER_READY;
      }
    }, error => {
      this.loading = false;
    });
  }


  sendOrderReadyAlert(is_phone_call) {
    let device_type = JSON.parse(localStorage.getItem('device_key'));
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'phone': this.order.user.phone,
      'client_reference_code': (this.order.order.order_status != 'offline' && this.order.order.client_reference_code != "NA") ? (this.order.order.client_reference_code) : this.order.order.order_status == "offline" ? this.order.order.order_id : null,
      'is_phone_call': (is_phone_call) ? true : false,
      'order_id': this.order.order.order_status != 'offline' ? this.order.order.order_id : null,
      'terminal_name': (device_type && device_type.terminal_name) ? device_type.terminal_name : null,
    }

    this.loading = true;
    this.recentOrdersService.sendOrderReadyAlert(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        let s_msg = 'Order ready SMS sent to customer'
        if (is_phone_call) {
          s_msg = 'Phone call for order ready alert to customer is initiated.'
        }
        this.messageService.add({ severity: 'success', summary: 'Success', detail: s_msg })
        var tempRes = {
          'phone': this.order.user.phone,
          'comment': "message sent successfully.",
          'is_success': true,
          'createdAt': new Date(),
          'updatedAt': new Date()
        }

        if (is_phone_call) {
          if (Array.isArray(this.order.order.order_ready_phone_call_detail)) {
            this.order.order.order_ready_phone_call_detail.unshift(tempRes);
          } else {
            this.order.order.order_ready_phone_call_detail = [tempRes];
          }
        } else {
          if (Array.isArray(this.order.order.order_ready_sms_detail)) {
            this.order.order.order_ready_sms_detail.unshift(tempRes);
          } else {
            this.order.order.order_ready_sms_detail = [tempRes];
          }
        }

        //add sent sms detail in non completed order if found
        let notCompletedOrdersList = JSON.parse(localStorage.getItem('not_completed_orders')) || [];
        let tempNonCompletedOrderList = JSON.parse(JSON.stringify(notCompletedOrdersList));
        for (var i = 0; i < tempNonCompletedOrderList.length; ++i) {
          if (tempNonCompletedOrderList[i].order_id == this.order.order.order_id) {
            if (is_phone_call) {
              if (!Array.isArray(notCompletedOrdersList[i].order.order_ready_phone_call_detail)) {
                notCompletedOrdersList[i].order.order_ready_phone_call_detail = [];
              }

              notCompletedOrdersList[i].order.order_ready_phone_call_detail.unshift(tempRes);
            } else {
              if (!Array.isArray(notCompletedOrdersList[i].order.order_ready_sms_detail)) {
                notCompletedOrdersList[i].order.order_ready_sms_detail = [];
              }

              notCompletedOrdersList[i].order.order_ready_sms_detail.unshift(tempRes);
            }
            localStorage.setItem('not_completed_orders', JSON.stringify(notCompletedOrdersList));
            break;
          }
        }

        //add sent sms detail in completed order if found
        let completedOrdersList = JSON.parse(localStorage.getItem('temp_orders_list')) || [];
        let tempCompletedOrderList = JSON.parse(JSON.stringify(completedOrdersList));
        for (var i = 0; i < tempCompletedOrderList.length; ++i) {
          if (tempCompletedOrderList[i].order_id == this.order.order.order_id) {
            if (is_phone_call) {
              if (!Array.isArray(completedOrdersList[i].order.order_ready_phone_call_detail)) {
                completedOrdersList[i].order.order_ready_phone_call_detail = [];
              }

              completedOrdersList[i].order.order_ready_phone_call_detail.push(tempRes);
            } else {
              if (!Array.isArray(completedOrdersList[i].order.order_ready_sms_detail)) {
                completedOrdersList[i].order.order_ready_sms_detail = [];
              }

              completedOrdersList[i].order.order_ready_sms_detail.push(tempRes);
            }


            localStorage.setItem('temp_orders_list', JSON.stringify(completedOrdersList));
            break;
          }
        }
      }
    }, error => {
      this.loading = false;
    });
  }

  voidOrder() {
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id
    }
    this.loading = true;
    this.recentOrdersService.voidSelectedOrder(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status == 0) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Order changed to void order' })
        this.order.order.order_status = APIHelper.ORDER_VOID;
        this.chnageOrderStatus(this.order.order.order_id, APIHelper.ORDER_VOID);
      } else {
        alert(ordersData.message);
      }
    }, error => {
      this.loading = false;
    });
  }
  assignDeliveryPartner(id) {
    this.modalRef = this.modalService.show(ThirdPartyDeliveryPartnerModalComponent, { class: 'centred-modal' });
    this.modalRef.content.decision = "";
    this.modalRef.content.order_id = id;
    this.modalRef.content.delivery_date = this.order.order.delivery_date;
    this.modalRef.content.task_id = "";
    let cSub: Subscription = this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'done') {

        this.order.order.third_party_dunzo_task_id = this.modalRef.content.task_id;
        this.order.delivery_date = this.modalRef.content.delivery_date;
        this.order.order.delivery_date = this.modalRef.content.delivery_date;


      }
      cSub.unsubscribe();
    });
  }
  completeOrder() {
    let partnerNames = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.name ? op.name : ''; }) : []).join(",");
    let partnerShortCode = (this.order.order.partner_ids ? this.order.order.partner_ids.map((op:any) => { return op.partner_short_code ? op.partner_short_code : ''; }) : []).join(",");
    let sendData = {
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'partner_short_code': partnerShortCode,
      'partner_name': partnerNames,
      'order_status': this.getTypeCode('ORDER_COMPLETED'),
    }
    this.loading = true;
    this.recentOrdersService.completeSelectedOrderInfo(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status == 0 || ordersData.code == "-188") {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Order marked as delivered and completed' })
        this.order.order.order_status = APIHelper.ORDER_COMPLETED;
        let id = ordersData.result.completedOrder.order_id;
        this.chnageOrderStatus(id, APIHelper.ORDER_COMPLETED);
        let ordersList = JSON.parse(localStorage.getItem('not_completed_orders')) || [];
        let tempList = JSON.parse(JSON.stringify(ordersList));
        for (var i = 0; i < tempList.length; ++i) {
          if (tempList[i].order_id == id) {
            ordersList.splice(i, 1);
            localStorage.setItem('not_completed_orders', JSON.stringify(ordersList));
            break;
          }
        }
      }
      if (ordersData.status !== 0 && ordersData.code != "-188") {
        alert(ordersData.message);
      }
    }, error => {
      this.loading = false;
    });
  }

  changeOrder(id) {
    this.edit_items = false;
    let sendData = {
      'user_id': this.order.order.user_id,
      'shop_code': localStorage.getItem('shop_code'),
      'order_id': this.order.order.order_id,
      'cart_items': this.order.items,
      'payment_type': this.order.order.payment_type,
      'shipping_type': this.order.order.shipping_type,
      'delivery_date': this.order.order.delivery_date
    }
    this.loading = true;
    this.recentOrdersService.changeSelectedOrderInfo(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if (ordersData.status !== 0) {
        alert(ordersData.message);
      } else {
        this.order.order.order_status = APIHelper.ORDER_PENDING;
        this.chnageOrderStatus(id, APIHelper.ORDER_PENDING);
      }
    }, error => {
      this.loading = false;
    });
  }

  editItems() {
    this.edit_items = true;
    this.order.order.order_status = APIHelper.ORDER_EDITED;
  }

  deleteItem(order, index) {
    order.items.splice(index, 1);
  }

  disabledState() {
    if (this.order.order.order_status == APIHelper.ORDER_EDITED) {
      return true;
    } else {
      return false;
    }
  }

  getTimeDifferenceStr(date: any) {
    if (!date) {
      return "";
    }

    let date1 = new Date().getTime();
    let date2 = new Date(date).getTime();
    var delta: any = (date2 - date1) / 1000;
    if (delta <= 0) { return ""; }


    /*var hours:any = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;    */
    var minutes: any = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    var seconds: any = Math.floor(delta) % 60;
    /*if(hours<=9) { hours = "0"+hours}*/
    if (minutes <= 9) { minutes = "0" + minutes }
    if (seconds <= 9) { seconds = "0" + seconds }
    return /*hours+":"+*/minutes + ":" + seconds;
  }

  getBeforeHours(order_date: any) {
    if (!order_date) {
      return "";
    }
    let date1 = new Date().getTime();
    let date2 = new Date(order_date).getTime();
    let diff = (date1 - date2);
    let min: any = (diff / (60000));
    let hour: any = Math.floor((min) / 60);
    let sendVal = hour + ' hours';
    if (min < 60) {
      sendVal = Math.floor(min) + ' minutes';
    }
    return sendVal;
  }


  handleTime(time) {
    if (time.toString().length == 5) {
      this.order.order.date = new Date(
        this.datePipe.transform(this.order.order.delivery_date, 'MMM') + ' '
        + this.order.order.delivery_date.getDate() + ', '
        + this.order.order.delivery_date.getFullYear() + ' ' + time + ''
      );
    }
  }

  selectTime() {
    if (this.order && this.order.order.delivery_date) {
      let date = new Date(this.order.order.delivery_date);
      let count = date.toString().search(':');
      this.time = date.toString().slice(count - 2, count + 3);
    }
  }

  // using logic to check if there are kitchen departments or no
  // IF no. of depts >= 1 then print KOT department-wise (KOT for items without a dept goes to default printer)
  // ELSE if no. of depts = 0 then print all items in one KOT
  printRecentOrderKOT() {
    try {
      let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
      var billInfo = JSON.parse(JSON.stringify(this.order))

      let printerData = JSON.parse(localStorage.getItem('shop_printers'));
      if (printerData) {
        var printers_list = printerData;
        var allInOnePrinter;
        var kotPrintersCount = 0;
        printers_list.forEach(printer => {
          if (printer.print_type.indexOf('kitchen') != -1 || printer.print_type.indexOf('self_service') != -1) {
            kotPrintersCount++;
            allInOnePrinter = printer;
          }
        })

        if (kotPrintersCount > 0) {
          // this.selectedIndex.forEach(indx => {
          //     if(indx == 'all'){
          //     indx = 0;
          //     }

          // var kotLen = tempUnit.visitors[indx].kot.length;
          // if(kotLen > 0){
          var kotObj = { kot_number: (localStorage.getItem('kot_number') ? localStorage.getItem('kot_number') : 1) }
          kotObj['is_recent_order_kot'] = true;
          kotObj['shipping_type_name'] = this.getShippingTypeName(billInfo.order.shipping_type)
          kotObj['split_payment_details'] = this.getSplitPaymentDetailsArray(billInfo.order.split_payment_details)
          kotObj['partner_ids'] = billInfo.order.partner_ids ? billInfo.order.partner_ids : [];
          kotObj['currencySymbol'] = this.basketComponent.getCurrencySymbol();
          if (shop_config.shop_info.kitchen_departments.length > 0) { //kotPrintersCount > 1
            //print KOT department wise
            var departmentWiseItems = this.kotHelper.groupItemsDepartmentWise(billInfo.items);
            for (var dept in departmentWiseItems) {
              var printer = this.kotHelper.findRequiredPrinter(printers_list, dept);
              if (printer == "Printer not found") {
                this.messageService.add({ severity: 'success', summary: 'Printer not found', detail: 'Neither Wifi nor USB Printer not found for department ' + dept });
              } else {
                if (printer.ip_address && printer.ip_address != "" && printer.ip_address != null) {
                  //printer is a Wifi printer
                  this.kotHelper.printKOT(kotObj, departmentWiseItems[dept], printer);
                } else {
                  //printer is a USB printer
                  this.kotHelper.printKOTonUSBPrinter(kotObj, departmentWiseItems[dept], false);
                }
                var currKotNo = (localStorage.getItem('kot_number') ? Number(localStorage.getItem('kot_number')) : 1)
                localStorage.setItem('kot_number', (currKotNo + 1).toString());
              }
            }
          } else if (shop_config.shop_info.kitchen_departments.length == 0) { //kotPrintersCount == 1

            if (allInOnePrinter.ip_address && allInOnePrinter.ip_address != "" && allInOnePrinter.ip_address != null) {
              //printer is a Wifi printer
              this.kotHelper.printKOT(kotObj, billInfo.items, allInOnePrinter);
            } else {
              //printer is a USB printer
              this.kotHelper.printKOTonUSBPrinter(kotObj, billInfo.items, false);
            }
            var currKotNo = (localStorage.getItem('kot_number') ? Number(localStorage.getItem('kot_number')) : 1)
            localStorage.setItem('kot_number', (currKotNo + 1).toString());
          }
          // }
          // })
        } else {
          //neither Wifi not usb printer found
          this.messageService.add({ severity: 'success', summary: 'Wifi nor USB Printer found', detail: 'Neither Wifi nor USB printer found for KOT print' });
        }
      } else {
        this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
      }
      // });
    } catch (e) {
      console.log(e)
    }
  }

  getShippingTypeName(shipping_type) {
    var shippingTypes = {
      'ST0001': "PICK UP",
      'ST0002': "HOME DELIVERY",
      'ST0003': "IMMEDIATE"
    }
    return shippingTypes[shipping_type];
  }

  getSplitPaymentDetailsArray(split_payment_details) {
    var splitPaymentArray = []
    for (var pay_type in split_payment_details) {
      if (Number(split_payment_details[pay_type].amount) > 0) {
        splitPaymentArray.push(split_payment_details[pay_type])
      }
    }
    return splitPaymentArray;
  }

  printCustomerDetail() {
    let billInfo = JSON.parse(JSON.stringify(this.order));
    let bill_no = "";
    if (billInfo.order.order_id) {
      billInfo['order_id_info'] = billInfo.order.order_id.split("/");
      if (billInfo['order_id_info'][1]) {
        bill_no = billInfo['order_id_info'][1]
      }
    }

    let printData = {
      customer_name: billInfo.user ? billInfo.user.name : null,
      customer_address: billInfo.user ? billInfo.user.address : null,
      customer_phone: billInfo.user ? billInfo.user.phone : null,
      bill_number: bill_no
    }

    this.printHelper.fireCustomerAddressPrint(printData);
  }

  printRecentOrder() {
    this.commonPrint();
  }

  //method to print address in proper format (i.e on separate lines and without breaking any words midway)
  printAddress(fullAddress: string) {
    var addressArray = fullAddress.split(' ');
    var addressLine1 = '';
    var addressLine2 = '';
    var addressLine3 = '';
    var j = -1;
    var k = -1;

    //creating address line 1
    addressArray.some((element, i) => {
      if (addressLine1.length < 48 && (addressLine1.length + element.length) <= 48) {
        addressLine1 = addressLine1 + element + ' ';
        return false;
      } else {
        j = i;
        return true;
      }
    })
    this.builder.addText(addressLine1.trim() + '\n');

    //creating address line 2 (if line 1 can't accomodate the entire address)
    if (j > 0) {
      for (; j < addressArray.length; j++) {
        if (addressLine2.length < 48 && (addressLine2.length + addressArray[j].length) <= 48) {
          addressLine2 = addressLine2 + addressArray[j] + ' ';
        } else {
          k = j;
          break;
        }
      }
      this.builder.addText(addressLine2.trim() + '\n');
    }

    //creating address line 3 (if line 1 & 2 can't accomodate the entire address)
    if (k > 0) {
      for (; k < addressArray.length; k++) {
        if (addressLine3.length < 48 && (addressLine3.length + addressArray[k].length) <= 48) {
          addressLine3 = addressLine3 + addressArray[k] + ' ';
        }
      }
      this.builder.addText(addressLine3.trim() + '\n');
    }
  }

  groupItemsByTaxCode(items) {
    var taxCodeWiseItems = [];
    var findCode = "";
    items.forEach(itm => {
      var item;
      if (itm.selected_variant_item) {
        item = JSON.parse(JSON.stringify(itm.selected_variant_item));
      } else {
        item = JSON.parse(JSON.stringify(itm));
      }
      if (item.tax_type && item.tax_breakup && item.tax_breakup.length > 0) {
        //item has tax
        findCode = item.tax_type;
      } else {
        //item doesn't have tax
        findCode = "no_tax";
      }

      if (taxCodeWiseItems.filter(taxGroup => taxGroup.code == findCode).length > 0) {
        //if tax code already present
        var taxGrIndex = taxCodeWiseItems.indexOf(taxCodeWiseItems.filter(taxGroup => taxGroup.code == findCode)[0]);
        taxCodeWiseItems[taxGrIndex].items.push(JSON.parse(JSON.stringify(item)));
      } else {
        //if tax code not already present
        var taxGroup = { 'code': findCode, 'rate': 0.0, 'items': [] };
        if (item.tax_breakup.length > 0) {
          item.tax_breakup.forEach((tax) => {
            taxGroup.rate += tax.rate;
          })
        }
        taxGroup.items.push(JSON.parse(JSON.stringify(item)));
        taxCodeWiseItems.push(taxGroup);
      }
    })
    return taxCodeWiseItems;
  }

  detectCartSections(cartItems) {
    //console.log("cartItems in detectCartSections"+JSON.stringify(cartItems));

    var sectionAlreadyPresentFlag = false;
    var cartSections = [];
    var cartSectionsObj = {
      sectionName: "",
      itemCount: 0,
      subTotal: 0,
      items: []
    };

    if (cartItems) {
      for (var i = 0; i < cartItems.length; i++) {

        var item;
        if (cartItems[i].selected_variant_item) {
          item = JSON.parse(JSON.stringify(cartItems[i].selected_variant_item));
        } else {
          item = JSON.parse(JSON.stringify(cartItems[i]));
        }

        cartSectionsObj = {
          sectionName: "",
          itemCount: 0,
          subTotal: 0,
          items: []
        };

        sectionAlreadyPresentFlag = false;
        for (var j = 0; j < cartSections.length; j++) {
          if (this.basketComponent.findSection(item.item_tags) == cartSections[j].sectionName) {
            sectionAlreadyPresentFlag = true;
            cartSections[j].itemCount = cartSections[j].itemCount + item.item_count;

            cartSections[j].subTotal = cartSections[j].subTotal + this.getCartItemValue(item);

            // cartSections[j].items.push(cartItems[i]);
            cartSections[j].items.push(item);
            // console.log("Old this.cartSections["+j+"] :"+JSON.stringify(this.cartSections[j]));
            //console.log("cart item value!!! "+this.getCartItemValue(cartItems[i]));
          }
        }
        if (!sectionAlreadyPresentFlag) {
          cartSectionsObj.sectionName = this.basketComponent.findSection(item.item_tags);
          cartSectionsObj.itemCount = item.item_count;
          cartSectionsObj.subTotal = this.getCartItemValue(item);

          // cartSectionsObj.items.push(cartItems[i]);
          cartSectionsObj.items.push(item);
          cartSections.push(cartSectionsObj);
          // console.log("New this.cartSections"+ JSON.stringify(cartSectionsObj));
        }
      }
    }
    return cartSections;
  }

  getCartItemValue(item) {
    var sellPrice = 0;
    //adding item price
    sellPrice = item.item_price * item.item_count;
    if (this.shopConfiguration.show_post_tax_price && this.shopConfiguration.item_wise_tax && item.tax_value) {
      sellPrice = sellPrice + item.tax_value;
    }

    //adding modifier price to item price
    if (item.attributes.length > 0) {
      for (var attr = 0; attr < item.attributes.length; attr++) {
        // sellPrice = sellPrice + (item.attributes[attr].unit_price * item.attributes[attr].count);
        sellPrice = sellPrice + item.attributes[attr].price;
      }
    }

    // if(item.discount && (item.discount_type || item.discountUnits)){
    //   let discType = item.discount_type ? item.discount_type : item.discountUnits;
    //   if(discType != '%'){
    //       sellPrice -= item.discount;
    //   }else{
    //       sellPrice -= (item.item_price * item.item_count) * (item.discount/100);
    //   }
    // }

    if (item.discount_amount) {
      sellPrice -= item.discount_amount
    }

    sellPrice -= item.other_discounts_total;

    return sellPrice;
  }

  commonPrint() {
    let billInfo = JSON.parse(JSON.stringify(this.order));
    var itemWiseDiscount = 0.0;
    var otherDiscounts = 0.0;
    var itemWiseOtherDiscounts = []
    billInfo.items.forEach(item => {
      if (item.item_price) {
        item.item_price = parseFloat(item.item_price);
        item.unit_price = item.item_price;
      }

      if (item.discount && (item.discount_type || item.discountUnits)) {
        let discType = item.discount_type ? item.discount_type : item.discountUnits;
        if (discType != '%') {
          item['discount_amount'] = item.discount;
        } else {
          item['discount_amount'] = (item.item_price * item.item_count) * (item.discount / 100);
        }
        itemWiseDiscount += item['discount_amount'];
      }

      if (!item['other_discounts']) {
        item['other_discounts'] = []
      }

      var totalOtherDiscForItem = 0.0
      item['other_discounts'].forEach(disc => {
        totalOtherDiscForItem += disc.amount;
        otherDiscounts += disc.amount;

        if (itemWiseOtherDiscounts.filter(discCode => discCode == disc.discount_code).length == 0) {
          itemWiseOtherDiscounts.push(disc.discount_code)
        }
      })

      item['other_discounts_total'] = totalOtherDiscForItem;
    });
    let printData = {
      assigny: null,
      order_id: billInfo.order && billInfo.order.order_id ? billInfo.order.order_id : "",
      user_name: "",//added below    
      section: billInfo.order.shop_section ? billInfo.order.shop_section : null,
      seat_no: "",
      table_no: billInfo.order.table_number ? billInfo.order.table_number.join(',') : null,
      delivery_date: billInfo.order.delivery_date ? billInfo.order.delivery_date : null,
      createdAt: billInfo.order.createdAt ? billInfo.order.createdAt : new Date(),
      client_reference_code: billInfo.client_reference_code ? billInfo.client_reference_code : (billInfo.order.client_reference_code) ? (billInfo.order.client_reference_code) : "NA",
      cart_items: billInfo.items,
      cart_tax: 0,//added below      
      cart_value: 0,//added below,
      totalPrice: billInfo.valued_at ? billInfo.valued_at : (billInfo.order.valued_at) ? billInfo.order.valued_at : 0,
      discount: billInfo.order.discount,
      discountUnit: "",
      discount_amount: billInfo.order.discount,//added below    
      cash_discount: billInfo.order.cash_discount,//added below    
      cashDiscountUnit: "FIXED",//added below      
      cash_discount_amount: billInfo.order.cash_discount,//added below    
      on_subtotal_other_discounts: [],//added below    
      group_taxes: billInfo.order.tax_breakup && billInfo.order.tax_breakup.length > 0 ? billInfo.order.tax_breakup : (!billInfo.order.tax_breakup || billInfo.order.tax_breakup.length == 0) && (billInfo.order.group_tax && billInfo.order.group_tax.length > 0) ? billInfo.order.group_tax : [],
      extraCharges: billInfo.order.extra_charges ? billInfo.order.extra_charges : [],
      rounding_value: billInfo.order.rounding_value ? billInfo.order.rounding_value : 0,
      total_discount: 0,//added below     
      customer_gstin: billInfo.user && billInfo.user.additional_info && billInfo.user.additional_info.gstin ? billInfo.user.additional_info.gstin : null,
      customer_name: billInfo.user ? billInfo.user.name : null,
      customer_address_1: billInfo.user ? billInfo.user.address : null,
      customer_mobile_no: billInfo.user ? billInfo.user.phone : null,
      discountReason: "",//added below      
      overall_discount: 0,
      note: billInfo.order.note ? billInfo.order.note : "",
      splitPaymentList: []//added below
    }

    //add split payment
    let splitPaymentList = JSON.parse(JSON.stringify(billInfo.order.split_payment_details));
    printData['splitPaymentList'] = [];
    if (splitPaymentList) {
      for (var key in splitPaymentList) {
        if (splitPaymentList[key] && splitPaymentList[key].amount) {
          if (splitPaymentList[key].payment_sub_class_info && splitPaymentList[key].payment_sub_class_info.length > 0) {
            splitPaymentList[key].payment_sub_class_info.forEach(data => {
              printData['splitPaymentList'].push(JSON.parse(JSON.stringify(data)))
            })
          } else {
            printData['splitPaymentList'].push(JSON.parse(JSON.stringify(splitPaymentList[key])))
          }

        }
      }
    }

    //set user name
    if (billInfo.order && billInfo.order.assign && billInfo.order.assign.name) {
      printData.user_name = billInfo.order.assign.name;
    } else if (billInfo.order && billInfo.order.assign && billInfo.order.assignTo.name) {
      printData.user_name = billInfo.order.assignTo.name;
    }

    printData["cart_value"] = this.getSubTotalAmount();

    let overAllDiscount = 0.0;
    if (billInfo.order.discount) {
      printData['overall_discount'] = billInfo.order.discount - itemWiseDiscount
    }

    let onSubTotalOtherDiscounts = 0;
    if (billInfo.discount_info && billInfo.discount_info.length > 0) {
      billInfo.discount_info.forEach(disc => {
        if (itemWiseOtherDiscounts.filter(discCode => discCode == disc.discount_code).length == 0) {
          var discCopy = JSON.parse(JSON.stringify(disc));
          if (disc.discount_code.toLowerCase().indexOf('discount') < 0) {
            discCopy.discount_code = discCopy.discount_code + " (DISCOUNT)"
          }
          discCopy.value = discCopy.claimed_value;
          discCopy.name = discCopy.discount_code;
          printData['on_subtotal_other_discounts'].push(discCopy)
          onSubTotalOtherDiscounts += disc.claimed_value
        }
      })
    }

    if (billInfo.diary_history && billInfo.diary_history.length > 0) {
      billInfo.diary_history.forEach(diaryEntry => {
        if (this.is_dairy_history_show && diaryEntry.comment == "used") {
          printData['splitPaymentList'].push({ name: diaryEntry.title, amount: diaryEntry.diary_amount })
        }
        if (diaryEntry.title && diaryEntry.title.indexOf("cashback_") == 0) {
          printData['on_subtotal_other_discounts'].push({ name: "Cashback to be received:", value: Math.abs(diaryEntry.diary_amount) })
          onSubTotalOtherDiscounts += Math.abs(diaryEntry.diary_amount);
        }
      })
    }

    // total discount (for "you saved")
    printData.total_discount = parseFloat(billInfo.order.discount) + otherDiscounts + onSubTotalOtherDiscounts + ((billInfo.order.cash_discount) ? parseFloat(billInfo.order.cash_discount) : 0);
    this.printHelper.firePrint(printData, 'recent');
  }

  openOrderInvoice() {
    if ((this.order && this.order.order_id || this.order.order.order_id)) {
      this.view_invoice = true;
    }
  }

}
