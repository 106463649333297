export const paper_sizes: string[] = [
	'58mm',
	'72mm',
	'80mm'
];

export const printing_types: string[] = [
	'Receipt',
	'Kitchen',
	'Self Service'
];

/*export const printing_types: any[] = [
	{name:'Receipt',value:'receipt'},
	{name:'Kitchen',value:'kitchen'},
	{name:'Self Service',value:'self_service'}
];*/