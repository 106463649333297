import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CaptainUrlConfigService {
  private captainServerUrl: string = '';

  constructor() {
    this.loadCaptainServerUrl();
  }

  private loadCaptainServerUrl() {
    const url = localStorage.getItem('captain_server_url');
    if (url) {
      this.captainServerUrl = url;
    }
  }

  getCaptainServerUrl(): string {
    return this.captainServerUrl || 'Please set the captain server url in the Other settings'; // Fallback if not set
  }
}
