import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { BasketComponent } from '../basket/basket.component';
import { PaymentComponent } from '../payment-component/payment.component';
import { MenuComponent } from '../menu/menu.component';
import { OptionsBoxComponent } from '../options-box/options-box.component';
import { ScreenKeysComponent } from '../screen-keys/screen-keys.component';
import { StateGuardService } from '../state-guard.service';
import { CommonService } from '../../pages/helpers/services/common.service';
import { APIHelper } from '../helpers/api-helper';
import { RewardPointMessage } from '../../pages/reward-points.message.service';
import { remove } from 'lodash-es';

@Component({
  selector: 'app-food-ordering',
  templateUrl: './food-ordering.component.html',
  styleUrls: ['./food-ordering.component.less'],
  host: { '(window:keydown)': 'hotkeys($event)' },
})
export class FoodOrderingComponent implements OnInit, AfterViewInit  {
  isPaymentOpen: boolean;
  quickCashCalled = false;
  quickCardCalled = false;
  constructor(private commonService:CommonService, private screenKeysComponent:ScreenKeysComponent,
    private route: ActivatedRoute,private stateGuard:StateGuardService,
    private rewardPointMessage:RewardPointMessage,private cdRef:ChangeDetectorRef,
    private optionsBoxComponent:OptionsBoxComponent,private router:Router,private cmenuComponent:MenuComponent,
    private nonRestaurantBasketComponent: BasketComponent) {
    route.paramMap.subscribe(
      params => {
        this.isPaymentOpen = this.route.snapshot.params.payment === "payment";
      }
    );
  }
  @ViewChild(BasketComponent,{static: false}) basketComponent: BasketComponent;
  @ViewChild(MenuComponent,{static: false}) menuComponent: MenuComponent;

  @ViewChild(PaymentComponent,{static: false}) paymentComponent: PaymentComponent;
  @Output() optionsState: EventEmitter<boolean> = new EventEmitter();

  ngAfterViewInit() {
    if(this.basketComponent) {
      this.basketComponent.checkForDiscount();    
    } 
  }

  ngOnInit() {
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    if(!shop_config){
      this.router.navigate(['verify-pin']);
      return;
    }
    this.optionsState.emit(true);
  }

  ngAfterViewChecked() {
    // this is added for solve the issue of Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked 
    this.cdRef.detectChanges();
  }


  hotkeys(event: any) {
    if (event.keyCode === 49 && event.shiftKey && !this.quickCashCalled) {
      this.quickCashCalled = true;
      this.optionsBoxComponent.onPaymentClick('cash',null);
    }else if (event.keyCode === 50 && event.shiftKey && !this.quickCardCalled) {      
      this.quickCardCalled = true;
      this.optionsBoxComponent.onPaymentClick('card',null);      
    }else if (event.keyCode === 51 && event.shiftKey) {
      this.optionsBoxComponent.onPaymentClick('payment',null);                  
    }else if (event.keyCode === 56 && event.shiftKey) {
      localStorage.setItem('checkForHold','true');
      this.screenKeysComponent.print();
    }else if (event.keyCode === 57 && event.shiftKey) {
      localStorage.setItem('checkForHold','true');
      this.screenKeysComponent.printBill();
    }else if (event.keyCode === 53 && event.shiftKey) {
      this.optionsBoxComponent.onClickOT(true,null);
    }
  }

  doPayment(){
    this.optionsBoxComponent.onPaymentClick('other',true);
  }

  reactOnAddingDish (parcel) { 
    if(this.basketComponent) {      
      this.basketComponent.addToBasket(parcel,false);
    } else {
      this.nonRestaurantBasketComponent.addToBasket(parcel,false);
    }
  }

  addItemFromBasket(parcel) {
    let is_free_dish = parcel.is_free_dish  ? true : false
    if(parcel && this.menuComponent) {
      parcel = this.menuComponent.collateVariantItems([parcel])
      parcel[0].tempData = {
        'temp_unit_price': (parcel[0].unit_price)? parseFloat(parcel[0].unit_price) :parseFloat(parcel[0].item_price),
        'temp_item_count': parcel[0].item_count,
        'unit_price':  (parcel[0].unit_price)? parseFloat(parcel[0].unit_price) :parseFloat(parcel[0].item_price)
      };
      if(is_free_dish) {
        if(parcel[0].selected_variant_item) {
          parcel[0].selected_variant_item.is_free_dish = true;
          remove(parcel[0].selected_variant_item.attributes, attr => { return attr.is_variant_attribute});
        } else {
          parcel[0].is_free_dish = true;
        }
        this.reactOnAddingDish(parcel[0])
      } else {
        this.menuComponent.addDish(parcel[0]);
      }  
    }
  }

  parkTheOrder(event) {
    this.optionsBoxComponent.putOnHold(true);
  }

  reactOnDeletePayment(payment) {
    if(Array.isArray(payment) && payment[0] && payment[0].code == APIHelper.REWARD_PAYMENT_TYPE){
      this.rewardPointMessage.sendRewardPoints(payment[0].rewards_points);
      this.paymentComponent.deleteRewardsAmount();
    }else{
      this.paymentComponent.movesDetected(true);
    }
  }
}
