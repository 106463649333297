
import {fromEvent as observableFromEvent, of as observableOf, merge as observableMerge,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
/**
 * (c) OneGreenDiary Software Pvt. Ltd.
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform,
 *    solely reside with the third-party.
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Once OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without
 *    prior agreement with OneGreenDiary Software Pvt. Ltd.
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */
//import { Config } from './index';
import { SaveOrderService } from './services/save-order.service';
import { MakePaymentService } from './services/make-payment.service';
import { DiaryService } from '../helpers/services/diary.service';
import { APIHelper } from './api-helper';
import { BaseConfig } from '../helpers/api-config';
import { CustomerService } from '../helpers/services/customer.service';
import { CashRegisterService } from '../helpers/services/cash-register.service';
import { SpecialOrderService } from '../helpers/services/special-order.service';
import { sumBy } from 'lodash-es';

export class PaymentHelper {

    // follow a singleton pattern - with a twist
    private static _instance: PaymentHelper = null;
    private shopConfiguration: any = [];
    private saveOrderService: SaveOrderService;
    private makePaymentService: MakePaymentService;
    private customerService: CustomerService;
    private diaryService: DiaryService;
    private cashRegisterService: CashRegisterService;
    private specialOrderService: SpecialOrderService;
    private billQueueBeingEdited: boolean = false;
    private beingBilled: boolean = false;
    private supplierOrderBeingBilled: boolean = false;
    private supplierBillQueueBeingEdited: boolean = false;
    online$: Observable<boolean>;

    // singleton helper
    public static getInstance(shopConfiguration: any, customerServices:CustomerService,saveOrder: SaveOrderService, 
      makePayment: MakePaymentService,diaryPayment: DiaryService,cashRegisterApiService:CashRegisterService,
      specialOrderApiService:SpecialOrderService): PaymentHelper {
        if (PaymentHelper._instance === null) {
            PaymentHelper._instance = new PaymentHelper();
            PaymentHelper._instance.online$ = observableMerge(
                observableOf(navigator.onLine),
                observableFromEvent(window, 'online').pipe(map(() => true)),
                observableFromEvent(window, 'offline').pipe(map(() => false))
            );
            setInterval(PaymentHelper._instance.oneBillInBackground, 12000);
            setInterval(PaymentHelper._instance.oneSupplierOrderInBackground, 12000);
        }

        if (shopConfiguration !== null) PaymentHelper._instance.shopConfiguration = shopConfiguration;
        if (saveOrder !== null) PaymentHelper._instance.saveOrderService = saveOrder;
        if (customerServices !== null) PaymentHelper._instance.customerService = customerServices;
        if (makePayment !== null) PaymentHelper._instance.makePaymentService = makePayment;
        if (diaryPayment !== null) PaymentHelper._instance.diaryService = diaryPayment;
        if (cashRegisterApiService !== null) PaymentHelper._instance.cashRegisterService = cashRegisterApiService;
        if (specialOrderApiService !== null) PaymentHelper._instance.specialOrderService = specialOrderApiService;
        return PaymentHelper._instance;
    }


    // do one bill in background
    oneBillInBackground() {
        if (!navigator.onLine) {
            PaymentHelper._instance.beingBilled = false; // TODO: is this correct?
            return;
        }

        if (PaymentHelper._instance.billQueueBeingEdited) return;
        if (PaymentHelper._instance.beingBilled) return;

        // lock the billing, till this is done
        PaymentHelper._instance.beingBilled = true;
        var billQueue: any = [];
        try {
            billQueue = JSON.parse(localStorage.getItem('bill_queue'));
        } catch (e) {
            billQueue = [];
        }
        if (billQueue === null) billQueue = [];
        // console.log(billQueue);
        if (billQueue.length === 0) {
          PaymentHelper._instance.beingBilled = false;
          return;
        }
        
        var toBill = billQueue[0];
        if(toBill) {console.log("start a bill - "+toBill.order_id+" - "+new Date());}
        PaymentHelper._instance.bill(toBill).then((res) => {
                // TODO: evaluate res
                // if the billing happend, remove item
                if(res){
                  if(toBill) {console.log("end a bill case 1 - "+toBill.order_id+" - "+new Date());}
                  PaymentHelper._instance.beingBilled = true;
                  billQueue = JSON.parse(localStorage.getItem('bill_queue'));
                  localStorage.setItem('bill_queue', JSON.stringify(billQueue.splice(1)));

                  PaymentHelper._instance.billQueueBeingEdited = false;

                  // unlock for next bill in the queue
                  PaymentHelper._instance.beingBilled = false;

                }else{
                  if(toBill) {console.log("end a bill case 2 - "+toBill.order_id+" - "+new Date());}
                  PaymentHelper._instance.shuffleOrder();
                  PaymentHelper._instance.beingBilled = false;                  
                }
            });
    }

    oneSupplierOrderInBackground() {
        if (!navigator.onLine) {
            PaymentHelper._instance.supplierOrderBeingBilled = false; // TODO: is this correct?
            return;
        }

        if (PaymentHelper._instance.supplierBillQueueBeingEdited) return;
        if (PaymentHelper._instance.supplierOrderBeingBilled) return;

        // lock the billing, till this is done
        PaymentHelper._instance.supplierOrderBeingBilled = true;
        var supplierOrderBillQueue: any = [];
        try {
            supplierOrderBillQueue = JSON.parse(localStorage.getItem('supplier_order_bill_queue'));
        } catch (e) {
            supplierOrderBillQueue = [];
        }
        if (supplierOrderBillQueue === null) supplierOrderBillQueue = [];
        // console.log(supplierOrderBillQueue);
        if (supplierOrderBillQueue.length === 0) {
          PaymentHelper._instance.supplierOrderBeingBilled = false;
          return;
        }
        
        var sToBill = supplierOrderBillQueue[0];
        if(sToBill) {console.log("start a supplier order bill - "+sToBill.po_number+ " - "+ sToBill.order_id +" - "+new Date());}
        PaymentHelper._instance.processSavedSupplierOrder(sToBill).then((res) => {
                // TODO: evaluate res
                // if the billing happend, remove item
                if(res){
                  if(sToBill) {console.log("end a supplier order bill case 1 - "+sToBill.po_number+ " - "+ sToBill.order_id +" - "+new Date());}
                  PaymentHelper._instance.supplierOrderBeingBilled = true;
                  supplierOrderBillQueue = JSON.parse(localStorage.getItem('supplier_order_bill_queue'));
                  localStorage.setItem('supplier_order_bill_queue', JSON.stringify(supplierOrderBillQueue.splice(1)));

                  PaymentHelper._instance.supplierBillQueueBeingEdited = false;

                  // unlock for next bill in the queue
                  PaymentHelper._instance.supplierOrderBeingBilled = false;

                }else{
                  if(sToBill) {console.log("end a supplier order bill case 2 - "+sToBill.po_number+ " - "+ sToBill.order_id +" - "+new Date());}
                  PaymentHelper._instance.shuffleSupplierOrder();
                  PaymentHelper._instance.supplierOrderBeingBilled = false;                  
                }
            });
    }


    registerCustomer(data){

      return new Promise<string>((resolve,reject) =>{
        let sendData = data.contact_search_data;
        let updateData = data.customer_update_data;

        let shop_code = updateData.user.shop_code;
        let name = updateData.user.name;
        let mobile_no = updateData.user.phone;

        let address = data.add_address.address;

        if(mobile_no == "" || mobile_no == "0000000000") {
          return resolve("00000000000000000000000000000000");
        }

        this.customerService.contact_search(sendData).subscribe((res) => {
            let userData = res.json();
            if(userData.result && userData.result.details && userData.result.details.length>0){
              let auth_token = userData.result.details[0].auth_token;
              this.customerService.update_customer_data(updateData,auth_token).subscribe((res) => {
                var result = res.json();
                if (result.status === 0) {
                  this.customerService.addAddress(auth_token,shop_code,address).then((response:any) => {
                    resolve(auth_token);
                  });
                }else{
                  if(result.message){
                    resolve(auth_token);
                  }
                }
              })
            }else{
              this.customerService.registerCustomer(mobile_no,"",name,shop_code).subscribe((res) => {
                var result = res.json();
                if (result.status === 0) {

                  let auth_token = result.result.auth_token;

                  this.customerService.update_customer_data(updateData,auth_token).subscribe((res) => {
                    var result = res.json();
                    if (result.status === 0) {
                      this.customerService.addAddress(auth_token,shop_code,address).then((response:any) => {
                        resolve(auth_token);
                      });
                    }else{
                      if(result.message){
                        resolve(auth_token);
                      }
                    }
                  })
                }
              });
            }
          });
      });
    }

    // do billing
    bill(orderData): Promise<boolean> {

        /*var orderType = localStorage.getItem('orderType');
        if (orderType === 'takeAway' || orderType === 'delivery') {
            var newDateObj = new Date();
            newDateObj.setTime(orderData.deliveryDate.getTime() + (30 * 60 * 1000));
            orderData.deliveryDate = newDateObj;
        }        */

        return new Promise<boolean>((resolve, reject) => {
          orderData.cart_items = this.prepareOrderCartItems(orderData.cart_items);
          orderData.is_special_order = (orderData.cancel_order_flag == "true") ? false :  this.checkIsSpecialOrder(orderData.cart_items);          
          
          if(orderData.register_customer_info_flag){
            this.registerCustomer(orderData.register_customer_data).then((token) =>{

              if(token){
                orderData.customer_auth_token = token;
              }              
              PaymentHelper._instance.saveOrderService.save(orderData.cart_items,
                      orderData.kot_items,
                      orderData.shopCode,
                      new Date(orderData.deliveryDate),  // TODO: change for scheduled delivery
                      orderData.deliveryType, // TODO: change for home delivery / pickup
                      orderData.paymentType,
                      orderData.customer_auth_token,
                      orderData.table_number,
                      orderData.table_covers,
                      orderData.shop_section,
                      orderData.non_chargeable,
                      orderData.no_charge_comment,
                      orderData.extraCharges,
                      new Date(orderData.createdAt),
                      orderData.partner_ids_list,
                      orderData.employer_code,
                      orderData.tax_number,
                      orderData.shop_user_id,
                      orderData.cancel_order_flag,
                      orderData.order_id,
                      orderData.record_item_wise_discount,
                      orderData.cancel_order_comment,
                      orderData.prev_client_reference_code,
                      orderData.prev_order_id,
                      orderData.discount_codes,
                      /*(orderData.separate_bill_for_tax_classes && orderData.is_separate_bill_order_flow) ? orderData.discount_info : [],*/
                      orderData.discount_info,
                      orderData.tax_rate,
                      orderData.tax_value,
                      orderData.tax_type,
                      orderData.tax_breakup,
                      orderData.note,
                      orderData.bill_tax_class,
                      orderData.separate_bill_for_tax_classes,
                      orderData.is_special_order ? 1 : 0,
                      orderData.check_prints,
                      orderData.cart_value,
                      orderData.valued_at
                  ).subscribe((res) => {
                      var saveOrder = res.json();
                      if (saveOrder.status === 0) {
                        orderData.save_order = saveOrder.result;
                        PaymentHelper._instance.saveToDiary(saveOrder.result.orderdetail.order_detail.order_id,orderData).then((res) => {
                          if(res) {
                              /*PaymentHelper._instance.returnPayFromDiaryInCacenlOrder(saveOrder.result.orderdetail.order_detail.order_id,orderData).then((res) => {
                                if(res) {*/
                                  PaymentHelper._instance.makePayment(orderData).then((res) => {
                                    this.cashRegisterPaidOut(orderData);
                                    if(orderData.is_special_order) { this.saveSpecialOrder(orderData) }
                                    return resolve(res);
                                  });
                                /*} else {
                                  resolve(false);
                                }
                              });*/
                           } else {
                             resolve(false);
                           }
                        });
                      } else if(saveOrder.status == -1 && saveOrder.error && saveOrder.error.code == "-188" && 
                                saveOrder.order_detail && saveOrder.order_detail.order_id && saveOrder.order_detail.valued_at == orderData.valued_at) {
                        //order already completed                        
                        this.setOrderDetails(saveOrder.order_detail.order_id,orderData.kot_items);
                        return resolve(true);                        
                      } else if(saveOrder.status == -1 && saveOrder.error && saveOrder.error.code == "-207" && 
                                saveOrder.order_detail && saveOrder.order_detail.order_id) {
                        //order already cancelled
                        this.setOrderDetails(saveOrder.order_detail.order_id,[]);
                        return resolve(true);                        
                      } else {                        
                        console.log(saveOrder);
                        return resolve(false);
                      }
                  }, err =>{
                    console.log("save order error ",err);
                    return resolve(false);
                  });

            })

          }else{
                  PaymentHelper._instance.saveOrderService.save(orderData.cart_items,
                      orderData.kot_items,
                      orderData.shopCode,
                      new Date(orderData.deliveryDate),  // TODO: change for scheduled delivery
                      orderData.deliveryType, // TODO: change for home delivery / pickup
                      orderData.paymentType,
                      orderData.customer_auth_token,
                      orderData.table_number,
                      orderData.table_covers,
                      orderData.shop_section,
                      orderData.non_chargeable,
                      orderData.no_charge_comment,
                      /*orderData.cancel_order_reason,*/
                      orderData.extraCharges,
                      new Date(orderData.createdAt),
                      orderData.partner_ids_list,
                      orderData.employer_code,
                      orderData.tax_number,
                      orderData.shop_user_id,
                      orderData.cancel_order_flag,
                      orderData.order_id,
                      orderData.record_item_wise_discount,
                      orderData.cancel_order_comment,
                      orderData.prev_client_reference_code,
                      orderData.prev_order_id,
                      orderData.discount_codes,
                      /*(orderData.separate_bill_for_tax_classes && orderData.is_separate_bill_order_flow) ? orderData.discount_info : [],*/
                      orderData.discount_info,
                      orderData.tax_rate,
                      orderData.tax_value,
                      orderData.tax_type,
                      orderData.tax_breakup,
                      orderData.note,
                      orderData.bill_tax_class,
                      orderData.separate_bill_for_tax_classes,
                      orderData.is_special_order ? 1 : 0,
                      orderData.check_prints,
                      orderData.cart_value,
                      orderData.valued_at
                  ).subscribe((res) => {
                      var saveOrder = res.json();

                      if (saveOrder.status === 0) {
                        orderData.save_order = saveOrder.result;
                        PaymentHelper._instance.saveToDiary(saveOrder.result.orderdetail.order_detail.order_id,orderData).then((res) => {
                          if(res) {
                              /*PaymentHelper._instance.returnPayFromDiaryInCacenlOrder(saveOrder.result.orderdetail.order_detail.order_id,orderData).then((res) => {
                                if(res) {*/
                                  PaymentHelper._instance.makePayment(orderData).then((res) => {
                                    this.cashRegisterPaidOut(orderData);                                    
                                    if(orderData.is_special_order) { this.saveSpecialOrder(orderData) }                                    
                                    return resolve(res);
                                  });
                                /*} else {
                                  resolve(false);
                                }
                              });*/
                           } else {
                             resolve(false);
                           }
                        });
                      } else if(saveOrder.status == -1 && saveOrder.error && saveOrder.error.code == "-188" && 
                                saveOrder.order_detail && saveOrder.order_detail.order_id) {
                        //order already completed                        
                        this.setOrderDetails(saveOrder.order_detail.order_id,orderData.kot_items);
                        return resolve(true);                        
                      } else if(saveOrder.status == -1 && saveOrder.error && saveOrder.error.code == "-207" && 
                                saveOrder.order_detail && saveOrder.order_detail.order_id) {
                        //order already cancelled
                        this.setOrderDetails(saveOrder.order_detail.order_id,[]);
                        return resolve(true);
                      } else {
                        return resolve(false);
                      }
                  }, err =>{
                    console.log("Insid save order ",err);
                    return resolve(false);

                  });
            }

        });
    }

    returnPayFromDiaryInCacenlOrder(order_id:any,orderData:any) {
      return new Promise<boolean>((resolve, reject) => {
        if(orderData.return_pay_from_diary_balance) {
           PaymentHelper._instance.diaryService.saveToDairy(order_id,
              orderData.customer_auth_token,"used_void",
              "",
              orderData.return_pay_from_diary_balance,
              true,
              orderData.shopCode,
              null, //diary reference code              
              orderData.shop_user_id
            ).subscribe((pres) => {
              var tempRes = pres.json();
              if (tempRes.status === 0) { resolve(true); } else {resolve(false) }
            }, err =>{
              return resolve(false);
            });
        } else {
          return resolve(true);
        }
      })
    }

    saveToDiary(order_id:any,orderData:any) {
      return new Promise<boolean>((resolve, reject) => {
        if(orderData.save_to_diary_amount != 0) {
          PaymentHelper._instance.diaryService.saveToDairy(order_id,
              orderData.customer_auth_token,orderData.save_to_diary_comment,
              "",                              // payment_details
              orderData.save_to_diary_amount,
              false,                           //  is_clear
              orderData.shopCode,
              null, //diary reference code,
              orderData.shop_user_id              
            ).subscribe((sres) => {
              var saveToDiaryRes = sres.json();
              if (saveToDiaryRes.status === 0) {
                //check for payfrom diary.
                if(orderData.pay_from_diary_amt && orderData.pay_from_diary_amt != 0) {
                  var comment = (orderData.cancel_order_flag == "true") ? "used_void" : "used";
                  PaymentHelper._instance.diaryService.saveToDairy(order_id,
                      orderData.customer_auth_token,comment,
                      "",                              // payment_details
                      orderData.pay_from_diary_amt,
                      false,                           //  is_clear
                      orderData.shopCode,
                      null, //diary reference code ,
                      orderData.shop_user_id
                    ).subscribe((pres) => {
                      var payFromDiaryRes = pres.json();
                      if (payFromDiaryRes.status === 0) { resolve(true); } else {resolve(false) }
                    },err =>{
                      return resolve(false);
                    });
                } else {
                  return resolve(true)
                }
              } else {
               return resolve(false)
              }
            }, err =>{
              return resolve(false);
            });
        } else if(orderData.pay_from_diary_amt && orderData.pay_from_diary_amt != 0) {

          var comment = (orderData.cancel_order_flag == "true") ? "used_void" : "used";
          PaymentHelper._instance.diaryService.saveToDairy(order_id,
              orderData.customer_auth_token,comment,
              "",                              // payment_details
              orderData.pay_from_diary_amt,
              false,                           //  is_clear
              orderData.shopCode,
              null, //diary reference code
              orderData.shop_user_id
            ).subscribe((pres) => {
              var payFromDiaryRes = pres.json();
              if (payFromDiaryRes.status === 0) { resolve(true); } else {resolve(false) }
            }, err =>{
              return resolve(false);
            });
        } else {
          return resolve(true);
        }
      })
    }

    cashRegisterPaidOut(orderData){
      return new Promise<boolean>((resolve, reject) => {        
        let cash_total = 0;
        if(orderData.cancel_order_flag == "true" && orderData.prev_client_reference_code && orderData.splitPayment) {
          for (var key in orderData.splitPayment) {
            if(orderData.splitPayment[key]){
              if(key == APIHelper.CASH_IN_HAND_TYPE || key == APIHelper.CASH_ON_DELIVERY_TYPE){
                cash_total += Math.abs(orderData.splitPayment[key]);
              }
            }
          }
        }
        
        if(cash_total==0) {
          return resolve(true);
        }

        let device_info = JSON.parse(localStorage.getItem('device_key')) || {};
        let order_id = (orderData.prev_order_id) ? orderData.prev_order_id : orderData.prev_client_reference_code;
        let shop_user_id = orderData.shop_user_id;
        let sData = {
          "denomination": "",
          "is_closing": false,
          "is_opening": false,
          "is_cancelled_return":true,
          "remark": "CANCELLED:"+order_id,
          "shift": "",
          "total_cash": Math.abs(cash_total),
          "category": "",
          "user_id": shop_user_id,
          "terminal_id":(device_info && device_info.terminal_id) ? device_info.terminal_id : null,      
          "shop_code": localStorage.getItem('shop_code')
        }

        this.cashRegisterService.cashOut(sData).subscribe((res) => {
          let resData = res.json();
          return resolve(true);          
        }, err =>{
          console.log(err)
          return resolve(true);           
        });
      });
    }

    shuffleOrder(){
        PaymentHelper._instance.billQueueBeingEdited = true;
        let billQueue = JSON.parse(localStorage.getItem('bill_queue'));
        if(billQueue && billQueue.length>0){
          billQueue.push(billQueue.splice(0, 1)[0])
          localStorage.setItem('bill_queue', JSON.stringify(billQueue));
        }
        PaymentHelper._instance.billQueueBeingEdited = false;
    }

    shuffleSupplierOrder(){
        PaymentHelper._instance.supplierBillQueueBeingEdited = true;
        let sOrderbillQueue = JSON.parse(localStorage.getItem('supplier_order_bill_queue'));
        if(sOrderbillQueue && sOrderbillQueue.length>0){
          sOrderbillQueue.push(sOrderbillQueue.splice(0, 1)[0])
          localStorage.setItem('supplier_order_bill_queue', JSON.stringify(sOrderbillQueue));
        }
        PaymentHelper._instance.supplierBillQueueBeingEdited = false;
    }

    makePayment(orderData:any) {
        return new Promise<boolean>((resolve, reject) => {
            this.makePaymentService.payment(orderData.save_order.orderdetail.order_detail.order_id,
                orderData.shopCode,
                orderData.cart_value,
                orderData.valued_at,
                orderData.paymentType,
                orderData.splitPayment, // split payment
                orderData.splitPaymentDetail, // split payment
                orderData.cart_tax, // tax and other items to be taken care of
                0, // TODO: service charge
                0, // TODO: delivery charge
                orderData.discount_amount, // TODO: discount amount
                orderData.rewards_amount, // TODO: rewards amount
                0, // TODO: card amount
                (Math.abs(orderData.pay_from_diary_amt)) ? orderData.pay_from_diary_amt:0, // TODO: diary amount
                (orderData.cancel_order_flag != "true" && orderData.save_to_diary_amount<0) ? orderData.save_to_diary_amount:0, // TODO: advance amount
                ((orderData.cancel_order_flag == "true" && orderData.save_to_diary_amount<0) || (orderData.cancel_order_flag != "true" && orderData.save_to_diary_amount>0)) ?  orderData.save_to_diary_amount:0, // TODO: outstanding amount
                orderData.rounding_value,
                orderData.shop_user_id,
                orderData.rewards_pt,
                orderData.give_rewards,
                orderData.discount_reason,
                orderData.discount_phone_no,
                orderData.total_other_discount_amount,
                orderData.cash_discount_amount
            ).subscribe((pres) => {
                var paymentRes = pres.json();

                if (paymentRes.status === 0) {
                    this.setOrderDetails(orderData.save_order.orderdetail.order_detail.order_id,orderData.kot_items);
                    if(orderData.is_cancel){
                      let sendData = {
                        'shop_code':localStorage.getItem('shop_code'),
                        'order_id':orderData.save_order.orderdetail.order_detail.order_id
                      }
                      this.makePaymentService.cancelSelectedOrder(sendData).subscribe((res) => {
                        let ordersData = res.json();
                        if(ordersData.status!==0){
                          alert(ordersData.message);
                          return resolve(true);

                        }else{
                          return resolve(true);
                        }
                      });
                    }else{
                      return resolve(true);
                    }
                } else {
                    return resolve(false);
                }
            },err =>{
              console.log("make payment error ",err);
              return resolve(false);
            });
        });
    }

    saveSpecialOrder(orderData) {
      return new Promise<boolean>((resolve, reject) => {
        let specialOrderItems = orderData.cart_items.filter(it=>{
          return it.special_order_information && it.special_order_information.supplier_id;
        })

        if(specialOrderItems.length == 0) {
          return resolve(true);
        }

        var d = new Date();
        var m = '';
        var day = d.getDate(); var month = d.getMonth() + 1; var year = d.getFullYear();
        var hr = d.getHours(); var min = d.getMinutes(); var sec = d.getSeconds();
        if (month < 10) {
          m = '' + 0 + month;
        }

        let special_order_bill_index:any = localStorage.getItem('special_order_bill_index');
        if(!special_order_bill_index)  { special_order_bill_index = 1 }      
        let newBillIndex:number = parseInt(special_order_bill_index)+1;
        localStorage.setItem("special_order_bill_index",JSON.stringify(newBillIndex));
        
        let po_number = "PO" + day + m + year + hr + min + sec + '#'+ orderData.shopCode+special_order_bill_index;        
        let valued_at = (specialOrderItems[0].item_price * specialOrderItems[0].item_count) + (specialOrderItems[0].tax_value ? specialOrderItems[0].tax_value : 0);
        if(Array.isArray(specialOrderItems[0].attributes)) {
          specialOrderItems[0].attributes.forEach((attr: any) => {
            if(attr.count && attr.unit_price) {
              valued_at += (attr.count * attr.unit_price);
            }
          });
        }
        
        if(!specialOrderItems[0].special_order_information.special_order_charges) {
          specialOrderItems[0].special_order_information.special_order_charges = 0;
        }
        let tempSpecialOrerItem = {
          item_code:specialOrderItems[0].item_code,
          category_code:specialOrderItems[0].category_code,
          item_name:specialOrderItems[0].item_name,
          unit:specialOrderItems[0].unit,
          quantity:specialOrderItems[0].item_count,
          is_ingredient:false,
          unit_cost:specialOrderItems[0].unit_price,
          unit_price:specialOrderItems[0].unit_price,
          discount:0,
          discount_value:0,
          discount_unit:"",
          /*expiry_date: null,
          mfg_date: null,*/
          tracking_number: "",
          tracking_mode:"NA",
          tax_breakup:specialOrderItems[0].tax_settings,
          item_status: "ordered",
          attributes: specialOrderItems[0].attributes,
          special_order_charges: specialOrderItems[0].special_order_information.special_order_charges
        }
        let orderObj = {
          invoice_date: orderData.createdAt,
          date:orderData.delivery_date ? orderData.delivery_date: orderData.createdAt,
          po_number: po_number,
          items: [tempSpecialOrerItem],
          special_order_img_url: specialOrderItems[0].special_order_information.special_order_image,
          order_id: orderData.save_order.orderdetail.order_detail.order_id,
          special_order: true,
          supplier_id: specialOrderItems[0].special_order_information.supplier_id,
          valued_at: valued_at,
          note:orderData.note || specialOrderItems[0].special_order_information.item_note || "",
          instructions:specialOrderItems[0].special_order_information.item_instruction ? specialOrderItems[0].special_order_information.item_instruction : "",
          shop_code: orderData.shopCode,
          special_order_additional_info: {
            client_reference_id: orderData.save_order.orderdetail.order_detail.client_reference_code,
            customer: (orderData.user && orderData.user.name) ? orderData.user.name : "Guest",
            delivery_date:orderData.delivery_date,
            note:orderData.note || specialOrderItems[0].special_order_information.item_note || "",
            instructions:specialOrderItems[0].special_order_information.item_instruction ? specialOrderItems[0].special_order_information.item_instruction : "",
            shape_code: specialOrderItems[0].special_order_information.shape_code,
            phone: (orderData.user && orderData.user.phone) ? orderData.user.phone : "",
          }
        }
        PaymentHelper._instance.specialOrderService.createSpecialOrder(orderObj).subscribe((res:any) => {
          let resData = res.json();
          if(resData.status != 0) {                     
            this.addSpecialOrderInQueue(JSON.parse(JSON.stringify(orderObj)))
            return resolve(false);
          } else {
            let tempParam = {
              shop_code: orderObj.shop_code,
              po_processing_status: "new",
              po_number: orderObj.po_number,
              item_data: orderObj.items,
              valued_at: valued_at,
            }
            PaymentHelper._instance.specialOrderService.changePOOrderStatus(tempParam).subscribe((res:any) => {
              let soRESData = res.json();
              if(soRESData.status != 0) {
                this.addSpecialOrderInQueue(JSON.parse(JSON.stringify(orderObj)));
                return resolve(false);
              } else {
                return resolve(true);
              }              
            },error => {
              this.addSpecialOrderInQueue(JSON.parse(JSON.stringify(orderObj)));
              return resolve(false);
            });
          }          
        },error => {
          this.addSpecialOrderInQueue(JSON.parse(JSON.stringify(orderObj)))                   
          return resolve(false)
        });
      });
    }

    addSpecialOrderInQueue(orderObj) {
      var sOrderbillQueue: any = [];
      try {
          sOrderbillQueue = JSON.parse(localStorage.getItem('supplier_order_bill_queue'));
      } catch (e) {
          sOrderbillQueue = [];
      }

      if (sOrderbillQueue === null) sOrderbillQueue = [];
      let isOrderFound = false;
      if(sOrderbillQueue && sOrderbillQueue.length>0) {
        for(var i=0,llen=sOrderbillQueue.length;i<llen;i++){
          if(sOrderbillQueue[i].order_id == orderObj.order_id && sOrderbillQueue[i].po_number == orderObj.po_number) {
            isOrderFound = true;
            break;
          }
        }
      }

      if(!isOrderFound) {
        sOrderbillQueue.push(JSON.parse(JSON.stringify(orderObj)));
        localStorage.setItem('supplier_order_bill_queue',JSON.stringify(sOrderbillQueue));
      }
    }

    processSavedSupplierOrder(orderObj) {
      return new Promise<boolean>((resolve, reject) => {
        PaymentHelper._instance.specialOrderService.createSpecialOrder(orderObj).subscribe((res:any) => {
          let resData = res.json();
          if(resData.status != 0) {
            return resolve(false);
          } else {
            let tempParam = {
              shop_code: orderObj.shop_code,
              po_processing_status: "new",
              po_number: orderObj.po_number,
              item_data: orderObj.items,
              valued_at: orderObj.valued_at?orderObj.valued_at:0,
            }
            PaymentHelper._instance.specialOrderService.changePOOrderStatus(tempParam).subscribe((res:any) => {
              let soRESData = res.json();
              if(soRESData.status != 0) {
                return resolve(false);
              } else {
                return resolve(true);
              }              
            },error => {
              return resolve(false);
            });
          }
        },error => {          
          return resolve(false);
        }); 
      });
    }

    prepareOrderCartItems(items) {
      items.forEach(it => {
        it.unit = (it.unit) ? ((it.unit_value ? it.unit_value : 1) + " " + it.unit) : null;
      });

      return items;
    }

    checkIsSpecialOrder(items) {
      let isSpeciaOrder = false;
      for(var i=0,llen=items.length;i<llen;i++) {
        if(items[i].special_order_information && items[i].special_order_information.supplier_id) {
          isSpeciaOrder = true;
          break;
        }
      } 

      return isSpeciaOrder;
    }

    getSubTotalAmount(items){

      var total = 0;
      items.forEach(dish => {
        total += dish.item_price * dish.item_count;
        if(dish.attributes){
          dish.attributes.forEach((attr) => {
            total += attr.price ;
          });
        }
      });
      return total;
    }


    setOrderDetails(order_id,kot_items){
      return new Promise((resolve,reject) => {
        let sendData = {
          'shop_code':localStorage.getItem('shop_code'),
          'order_id':order_id,
          'getLocalizeName': true
        }
        this.makePaymentService.getSelectedOrderInfo(sendData).subscribe((res) => {
          let ordersData = res.json();
          if(ordersData.result){
            let shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
            if(ordersData.result.order.comment || ordersData.result.order.nocharge_comment){
              if(ordersData.result.order.nocharge_comment) {
                ordersData.result.order.comment = {noChargeNote:ordersData.result.order.nocharge_comment};
                if(ordersData.result.order.employee_id && ordersData.result.order.employee_id != -1) {
                  ordersData.result.order.comment["employeeId"] = ordersData.result.order.employee_id;
                }
              } else if(typeof ordersData.result.order.comment != 'string'){
                ordersData.result.order.comment = (ordersData.result.order.comment);
              }else{
                ordersData.result.order.comment = (ordersData.result.order.comment) ? JSON.parse(ordersData.result.order.comment) : "";
              }
              if(ordersData.result.order.comment.employeeId){
                if(localStorage.getItem('shop_user_list')){
                  let userList = JSON.parse(localStorage.getItem('shop_user_list'));
                  for (var i = 0; i < userList.length; ++i) {
                    if(userList[i].id.toString() == ordersData.result.order.comment.employeeId.toString()){
                      ordersData.result.order.comment['emp_name'] = userList[i].name;
                      break;
                    }
                  }
                }
              }
            }else{
              ordersData.result.order.comment = null;
            }
            let data:any = {
              'order':ordersData.result.order,
              'user':(ordersData.result.user.length>0)? ordersData.result.user[0] : {},
              'items':(ordersData.result.items.length>0)? ordersData.result.items : [],
              'kot_items':(kot_items && kot_items.length > 0) ? kot_items : [],
              'discount_info':(ordersData.result.discount_info.length>0)? ordersData.result.discount_info : [],
              'diary_history':(ordersData.result.diary_history.length>0)? ordersData.result.diary_history : []
            }

            if(data.user.profile_img_url){
              data.user.profile_img_url = BaseConfig.config.API+data.user.profile_img_url;
            }

            data.user.phone = (data.user.alt_address && data.user.alt_address.length>0)? (data.user.alt_address[data.user.alt_address.length - 1].contact_no) : data.user.phone ? data.user.phone : null;
            data.user.address = (data.user.alt_address && data.user.alt_address.length>0)? (data.user.alt_address[data.user.alt_address.length - 1].address) : null;

            ordersData.result.diary_history.forEach(dh=>{
              if(dh.comment == 'credit' || dh.comment == 'credit_due'){
                  dh['title'] = 'Credit Due Amount';
                }else if(dh.comment == 'credit_void'){
                  dh['title'] = 'Credit Void';
                }else if(dh.comment == 'used'  || dh.comment == 'used_due'){
                  dh['title'] = 'Pay from diary';
                }else if(dh.comment == 'used_void'){
                  dh['title'] = 'Pay from diary void';
                }else if(dh.comment == 'outstanding' || dh.comment == 'outstanding_due'){
                  dh['title'] ="Outstanding Amount";
                }else if(dh.comment == 'advance' || dh.comment == 'advance_due'){
                  dh['title'] ="Advance Amount";
                }else if(dh.comment == 'credit_note_advance'){
                  dh['title'] ="Credit Note Amount";
                }else{
                  dh['title'] = dh.comment;
                }
            })

            let payment_list = [];
            if(data.order.split_payment_details){
              for (var key in data.order.split_payment_details) {
                if (data.order.split_payment_details.hasOwnProperty(key)) {

                  if(Array.isArray(data.order.split_payment_details[key].payment_sub_class_info) && data.order.split_payment_details[key].payment_sub_class_info.length>0){
                    data.order.split_payment_details[key].payment_sub_class_info.forEach( data =>{
                      let tempPaymentDetail ={
                        title:data.sub_class_name,
                        amount:data.amount,
                        payment_code:key
                      }

                      if(key == APIHelper.SAVE_TO_DIARY_TYPE){
                        /*obj['comment'] = billInfo.save_to_diary_comment;
                        obj.title = "Save to Diary";
                        obj.payment_code = APIHelper.SAVE_TO_DIARY_TYPE;*/
                      }else{
                        payment_list.push(tempPaymentDetail);
                      }
                    });
                  }else {
                    let obj ={
                      title:data.order.split_payment_details[key].name,
                      amount:data.order.split_payment_details[key].amount,
                      payment_code:key
                    }
                    if(key == APIHelper.SAVE_TO_DIARY_TYPE){
                     /* obj['comment'] = data.order.save_to_diary_comment;
                      obj.title = "Save to Diary";
                      obj.payment_code = APIHelper.SAVE_TO_DIARY_TYPE;*/
                    }else{
                      payment_list.push(obj);
                    }
                  }
                }
              }
            }

            let is_clear_dairy_amount = false;
            ordersData.result.diary_history.forEach(dh=>{
              if(dh.comment == 'clear'){
                let is_clear_dairy_amount = true;
                // let list = dh.payment_details;
                // if(list){
                //   for (var key in list) {
                //     if (list.hasOwnProperty(key)) {
                //       if(Array.isArray(list[key].payment_sub_class_info) && list[key].payment_sub_class_info.length>0){
                //         list[key].payment_sub_class_info.forEach( psci =>{
                //           let tempPaymentDetail ={
                //             title:psci.sub_class_name,
                //             amount:psci.amount,
                //             payment_code:key
                //           }

                //           let is_found = false;
                //           payment_list.forEach(payment=>{
                //             if(payment.title == tempPaymentDetail.title){
                //               payment.amount += tempPaymentDetail.amount;
                //               is_found = true;
                //             }
                //           })

                //           if(!is_found){
                //             payment_list.push(tempPaymentDetail);
                //           }
                //         });
                //       }else {
                //         let obj ={
                //           title:list[key].name,
                //           amount:list[key].amount,
                //           payment_code:key
                //         }

                //         let is_found = false;
                //         payment_list.forEach(paymentData=>{
                //           if(paymentData.title == obj.title){
                //             paymentData.amount += obj.amount;
                //             is_found = true;
                //           }
                //         })
                //         if(!is_found){
                //           payment_list.push(obj);
                //         }
                //       }
                //     }
                //   }
                // }
              }
            })

            if(is_clear_dairy_amount){
              ordersData.result.diary_history.forEach(dh=>{
                if(dh.comment == 'used'){
                  let obj ={
                    title:'Pay from diary',
                    amount:dh.diary_amount,
                    payment_code:'PAY_FROM_DAIRY'
                  }
                  payment_list.push(obj);
                }
              })
            }


            data.order.split_payment = payment_list;


            let groupTaxes = [];
            data.items.forEach(function(item : any) {
              item.tax_breakup.forEach(function(f : any) {
                /*if(!ordersData.result.order.is_order_cancelled){
                  f['breakup_name'] = f['breakup_name']+' @'+f['rate'];
                }*/
                var present = false;
                for(var calcTax of groupTaxes) {
                  if(calcTax !== undefined) {
                    if(f['breakup_name'] === calcTax['breakup_name'] && f['rate'] === calcTax['rate']){
                      present = true;
                      if(!ordersData.result.order.is_order_cancelled){
                        calcTax['value'] = calcTax['value'] + f['value'];
                      }
                    }
                  }
                }
                if(!present) {
                  groupTaxes.push(JSON.parse(JSON.stringify(f)));
                }
              });
            });
            
            data.order.group_tax = groupTaxes;
            if(!Array.isArray(data.order.tax_breakup) || data.order.tax_breakup.legnth == 0) {
              data.order.tax_breakup = groupTaxes;
            }          

            data['order_id'] = order_id;
            data['user_name'] = ordersData.result.user[0].name;
            data['valued_at'] = ordersData.result.order.valued_at;
            data['order_status'] = ordersData.result.order.order_status;
            data['total_item_count'] = ordersData.result.order.total_item_count;
            data['shipping_type'] = ordersData.result.order.shipping_type;
            data['delivery_date'] = ordersData.result.order.delivery_date;
            data['is_full_detail_available'] = true;
            data['createdAt'] = ordersData.result.order.createdAt;
            data['is_order_sync'] = true;
            data['assign'] = null;
            data['client_reference_code'] = ordersData.result.order.client_reference_code;

            if(ordersData.result.order.is_order_cancelled){
              data['order_status'] = APIHelper.ORDER_COMPLETED;
              data['shipping_type'] = APIHelper.IMMEDIATE;
              ordersData.result.order.order_status = APIHelper.ORDER_COMPLETED;
              ordersData.result.order.shipping_type = APIHelper.IMMEDIATE;
            }

            let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

            if(loggedInUser){
              data.order['assign'] = {
                    'id':loggedInUser.id,
                    'name':loggedInUser.name
                  };
            }

            let assignToId = data.order.shop_user_id;
            if(assignToId == -1){
              data.order['assignTo'] = null;
            }else{
              let shop_user_list = JSON.parse(localStorage.getItem('shop_user_list'));
              for (var i = 0; i < shop_user_list.length; ++i) {
                if(shop_user_list[i].id == assignToId){
                  data.order['assignTo'] = {
                      name:shop_user_list[i].name,
                      id:shop_user_list[i].id
                    }
                }
              }
            }

            let orders = JSON.parse(localStorage.getItem('temp_orders_list'));
            if(!orders){orders = [];}

            let isOrderUpdated = false;
            for(let i=0,fLen=orders.length;i<fLen;i++) {
              if(orders[i]["order_id"] == data.order_id) {
                orders[i] = data;
                isOrderUpdated = true;
                break;
              }
            }

            if(!isOrderUpdated) {
              orders.unshift(data)
            };

            let ordersList = [];

            try {
                ordersList = JSON.parse(localStorage.getItem('not_completed_orders'));
            } catch (e) {
                ordersList = [];
            }
            if (ordersList === null) ordersList = [];
            
            if(ordersData.result.order.order_status != APIHelper.ORDER_COMPLETED && (ordersData.result.order.shipping_type == APIHelper.HOME_DELIVERY || ordersData.result.order.shipping_type == APIHelper.PICK_UP)) {
              ordersList.unshift(data);
            }

            localStorage.setItem('not_completed_orders',JSON.stringify(ordersList));
            localStorage.setItem('temp_orders_list',JSON.stringify(orders));

          }
          return resolve({status:0})
        }, (error) => {
          console.log(error);
          return resolve({status:-1})
        });
      })
    }
}

