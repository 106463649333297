import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-bill-number-modal',
  templateUrl: './bill-number-modal.component.html',
  styleUrls: ['./bill-number-modal.component.less']
})
export class BillNumberModalComponent implements OnInit {
  decision: string = '';
  selectedNumber:any=undefined;
  constructor(public modalRef: BsModalRef) { }
  ngOnInit() {
  }

  done(){
    if(!this.selectedNumber){
      alert("Enter any number");
      return;
    }
    this.modalRef.hide();
  }

}

