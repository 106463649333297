import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective {

  constructor(private self: ViewContainerRef) { 
    setTimeout(() => {
      self.element.nativeElement.focus();
    }, 0);
  }

}
