import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable, of } from 'rxjs';
import { catchError, retryWhen, delay, take, tap } from 'rxjs/operators';
import { CaptainUrlConfigService } from './captain-url_config.service';
import { MessageService } from 'primeng/api';
import { KotHelper } from '../kot-helper';

declare var epson: any;

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket$: WebSocketSubject<any>;
  private builder: any;
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 500;
  private reconnectDelay = 3000;
  private printerUtility = false;

  constructor(
    private captainUrlConfigService: CaptainUrlConfigService,
    private messagingService: MessageService,
    private kotHelper: KotHelper
  ) {
    if (localStorage.getItem('PRINT_UTILITY')) {
      this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
    }

    const serverUrl = this.captainUrlConfigService.getCaptainServerUrl();
    this.initializeWebSocket(serverUrl);

    // Clean up old printed KOTs daily
    this.cleanupPrintedKots();
  }

  private initializeWebSocket(serverUrl: string) {
    this.socket$ = webSocket(serverUrl);
    console.log('WebSocket connection established:', serverUrl);

    this.socket$.pipe(
      retryWhen((errors) =>
        errors.pipe(
          tap(() => {
            this.reconnectAttempts++;
            console.log(`Reconnection attempt ${this.reconnectAttempts}`);
          }),
          delay(this.reconnectDelay),
          take(this.maxReconnectAttempts),
          tap({
            complete: () => {
              console.log('Max reconnection attempts reached. No more retries.');
            }
          })
        )
      ),
      catchError((err) => {
        console.error('WebSocket connection error:', err);
        return of(err);
      })
    ).subscribe({
      next: (message) => {
        console.log('Received WebSocket message:', message);
        // Process any message received through WebSocket
        this.handleWebSocketMessage(message);
      },
      error: (err) => {
        console.error('WebSocket connection error after retries:', err);
      },
      complete: () => {
        console.log('WebSocket connection closed');
      }
    });
  }

  private handleWebSocketMessage(message: any) {
    // Handle any type of message and trigger appropriate action
    if (message) {
        console.log('Received WebSocket message type:', message.type);
        
        try {
            if (message.type === 'new_kot') {
                console.log('Processing new KOT notification');
                this.processDirectKOT(message.data);
            } 
            else if (message.type === 'reprint_kot') {
                console.log('Processing KOT reprint request');
                // Check which format we received
                if (message.data.kot) {
                    // Original format with kot and table objects
                    this.processReprintKOT(message.data);
                } else if (message.data.kotNumber || message.data.cartItems) {
                    // Direct KOT data format
                    this.processDirectReprintKOT(message.data);
                } else {
                    throw new Error('Unrecognized KOT reprint data format');
                }
            }
            else if (message.data && message.data.kot) {
                console.log('Processing direct KOT data');
                this.processDirectKOT(message.data.kot);
            }
            else if (message.data && message.data.table_list) {
                console.log('Processing KOT data from table list');
                this.prepareToPrintKOTFromWebSocket(message);
            }
        } catch (error) {
            console.error('Error handling WebSocket message:', error);
        }
    }
  }

  private processDirectKOT(kotData: any) {
    try {
        if (!kotData) {
            throw new Error('Invalid KOT data received');
        }

        console.log('Processing KOT data:', kotData);
        const printerData = JSON.parse(localStorage.getItem('shop_printers'));
        if (!printerData) {
            throw new Error('No printer configuration found');
        }

        // Process and print the KOT
        this.processKotPrinting(kotData, kotData.table, printerData);

    } catch (error) {
        console.error('Error processing KOT:', error);
        this.messagingService.add({
            severity: 'error',
            summary: 'KOT Processing Error',
            detail: error.message || 'Failed to process KOT'
        });
    }
  }

  private prepareToPrintKOTFromWebSocket(response: any) {
    try {
      console.log('Starting KOT print preparation');
      const printerData = JSON.parse(localStorage.getItem('shop_printers'));

      if (!printerData) {
        throw new Error('Printer data not found');
      }

      const printers_list = printerData;

      console.log('Printers list:', printers_list);

      Object.keys(response.data.table_list).forEach(section => {
        console.log('Processing section:', section);
        response.data.table_list[section].forEach(table => {
          console.log('Processing table:', table);
          if (table.visitors) {
            table.visitors.forEach(visitor => {
              console.log('Processing visitor:', visitor);
              if (visitor.kot) {
                console.log('Processing visitor:', visitor);
                visitor.kot.forEach(kot => {
                  console.log('Processing KOT:', kot.kot_number);
                  this.processKotPrinting(kot, table, printers_list);
                });
              }
            });
          }
        });
      });
    } catch (error) {
      console.error('Error in KOT preparation:', error);
      this.messagingService.add({
        severity: 'error',
        summary: 'Print Preparation Error',
        detail: error.message || 'Failed to prepare KOT printing'
      });
    }
  }

  private processKotPrinting(kot: any, table: any, printers_list: any[]) {
    try {
        if (!kot && !kot.items) {
            throw new Error('Invalid KOT data');
        }

        // Log the KOT data for debugging
        console.log('Processing KOT:', kot.kot_number);
        console.log('KOT Items:', kot.items);

        // Add check for already printed KOTs
        const printedKots = JSON.parse(localStorage.getItem('printed_kots') || '[]');
        if (printedKots.includes(kot.kot_number)) {
            console.log(`KOT #${kot.kot_number} already printed, skipping...`);
            return;
        }

        const departmentWiseItems = this.groupItemsDepartmentWise(kot.items);
        console.log('Department Wise Items:', departmentWiseItems);
        
        // Check for unknown items before proceeding with printing
        const hasUnknownItems = Object.values(departmentWiseItems).some(items => 
            items.some(item => !item.item_name || item.item_name === 'Unknown Item')
        );

        if (hasUnknownItems) {
            console.error('Unknown items found in KOT, skipping print');
            this.messagingService.add({
                severity: 'error',
                summary: 'Print Error',
                detail: `KOT #${kot.kot_number} contains unknown items and cannot be printed`
            });
            return;
        }
        
        Object.entries(departmentWiseItems).forEach(([dept, items]) => {
            let printer = this.findRequiredPrinter(printers_list, dept);
            
            if (!printer || printer === "Printer not found") {
                console.warn(`No printer found for department ${dept}, using default printer`);
                printer = printers_list.find(p => p.print_type?.includes('kitchen')) || printers_list[0];
            }

            if (!printer) {
                throw new Error(`No printer available for department ${dept}`);
            }

            // Format KOT data for printing
            const kotPrintData = {
                printerName: printer.name || "Default Printer",
                paperSize: 80,
                kotJson: {
                    shopName: localStorage.getItem('shop_name') || '',
                    rePrint: false,
                    kotNumber: kot.kot_number || 0,
                    date: new Date().toJSON().slice(0, 10),
                    time: new Date().toLocaleTimeString(),
                    tableNo: table?.tables?.[0]?.toString() || '',
                    seatNo: table?.covers?.toString() || '',
                    location: table?.section_name || '',
                    partner: '',
                    cartItems: items.map(item => ({
                        item_name: this.capitalizeFirstLetter(item.item_name || ''),
                        quantity: item.item_count || 0,
                        item_count: item.item_count || 0,
                        unit_price: item.unit_price || 0,
                        description: item.description || '',
                        discount: item.discount || 0,
                        kitchen_department: item.kitchen_department || dept || '',
                        note: item.note || '',
                        attributes: (item.attributes || []).map(attr => ({
                            name: attr.name || attr.attribute_name || '',
                            count: attr.count || 0
                        })),
                        included_combo_items: (item.included_combo_items || []).map(combo => ({
                            item_code: combo.item_code || '',
                            item_name: combo.item_name || '',
                            quantity: combo.quantity || 0,
                            unit_price: combo.unit_price || 0
                        }))
                    }))
                }
            };

            console.log('Sending print request for department:', dept);
            console.log('Print data:', kotPrintData);

            // Send print request
            fetch('http://127.0.0.1:5000/print/kot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(kotPrintData)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`); 
                }
                return response.json();
            })
            .then(data => {
                console.log('Print successful for department:', dept);
                // Add KOT to printed list only after successful print
                if (!printedKots.includes(kot.kot_number)) {
                    printedKots.push(kot.kot_number);
                    localStorage.setItem('printed_kots', JSON.stringify(printedKots));
                }
                this.messagingService.add({
                    severity: 'success',
                    summary: 'KOT Printed',
                    detail: `KOT #${kot.kot_number} printed successfully for ${dept}`
                });
            })
            .catch(error => {
                console.error('Error printing KOT:', error);
                this.messagingService.add({
                    severity: 'error',
                    summary: 'Print Error',
                    detail: `Failed to print KOT for ${dept}: ${error.message}`
                });
            });
        });

    } catch (error) {
        console.error('Error in processKotPrinting:', error);
        throw new Error(`Failed to print KOT: ${error.message}`);
    }
  }

  // private printKOT(fullkot: any, dept_items: any[], dept_printer: any, table: any) {
  //   try {
  //     this.builder = new epson.ePOSBuilder();
  //     const shopName = localStorage.getItem('shop_name');
      
  //     // Header
  //     this.builder.addTextAlign(this.builder.ALIGN_CENTER);
  //     this.builder.addText('\n' + shopName + '\n');
  //     this.builder.addText('KOT No: ' + fullkot.kot_number + '\n');
      
  //     const date = new Date().toJSON().slice(0, 10);
  //     const time = new Date().toLocaleTimeString();
  //     this.builder.addText('Kot Date: ' + date + ' ' + time + '\n');

  //     if (table.section_name) {
  //       this.builder.addText('Section: ' + this.capitalizeFirstLetter(table.section_name) + '\n');
  //     }
      
  //     if (table.tables?.length > 0) {
  //       this.builder.addText('Table No: ' + table.tables[0] + '\n');
  //     }

  //     this.builder.addText('---------------------------------------------\n');

  //     // Items
  //     const cartItems = this.kotHelper.groupSameItems(dept_items);
  //     cartItems.forEach(item => {
  //       this.createKotItemLine(
  //         this.capitalizeFirstLetter(item.item_name),
  //         item,
  //         item.attributes || [],
  //         item.combo_item_codes || [],
  //         item.included_combo_items || []
  //       );
  //     });

  //     // Footer
  //     this.builder.addTextAlign(this.builder.ALIGN_CENTER);
  //     this.builder.addText('---------------------------------------------\n');
  //     this.builder.addFeed();
  //     this.builder.addCut(this.builder.CUT_FEED);

  //     // Send to printer
  //     const ipAddr = dept_printer.ip_address || '192.168.1.201';
  //     const address = `http://${ipAddr}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000`;
  //     const epos = new epson.ePOSPrint(address);

  //     epos.send(this.builder.toString());
  //     console.log('KOT print job sent successfully');
  //   } catch (error) {
  //     console.error('Error in KOT printing:', error);
  //     this.messagingService.add({
  //       severity: 'error',
  //       summary: 'Print Error',
  //       detail: error.message || 'Failed to print KOT'
  //     });
  //   }
  // }

  // Original WebSocket methods
  sendMessage(message: any) {
    this.socket$.next(message);
  }

  getMessages(): Observable<any> {
    return this.socket$;
  }

  closeConnection() {
    this.socket$.complete();
  }

  // Helper methods to be implemented
  groupItemsDepartmentWise(items: any[]): Record<string, any[]> {
    let departmentWiseItems: Record<string, any[]> = {};
    
    if (!Array.isArray(items)) {
        console.error('Invalid items array:', items);
        return {};
    }

    items.forEach(item => {
        if (!item) {
            console.warn('Null or undefined item found in items array');
            return;
        }

        console.log('Processing item for department grouping:', item);

        const dept = item.kitchen_department || 'default';
        if (!departmentWiseItems[dept]) {
            departmentWiseItems[dept] = [];
        }
        
        // Only add items that have a name
        if (item.item_name) {
            const validItem = {
                ...item,
                item_count: item.item_count || 0,
                kitchen_department: dept
            };
            departmentWiseItems[dept].push(validItem);
        } else {
            console.warn('Item without name found:', item);
        }
    });

    // Remove departments that have no valid items
    Object.keys(departmentWiseItems).forEach(dept => {
        if (departmentWiseItems[dept].length === 0) {
            delete departmentWiseItems[dept];
        }
    });

    console.log('Grouped items by department:', departmentWiseItems);
    return departmentWiseItems;
  }

  findRequiredPrinter(printers_list: any[], dept: string) {
    if (!printers_list || !Array.isArray(printers_list)) {
        console.warn('No printers list available');
        return "Printer not found";
    }

    if (!dept || dept === 'default') {
        // Return first available kitchen printer or first printer in list
        return printers_list.find(p => p.print_type?.includes('kitchen')) || printers_list[0];
    }

    // Look for department specific printer
    for (let printer of printers_list) {
        const kitchen_dept = printer.kitchen_department?.toString().trim() || '';
        const dept_trim = dept.toString().trim();
        
        if ((kitchen_dept === dept_trim || 
            printer.print_type?.includes('self_service')) && 
            (printer.print_type?.includes('kitchen') || 
            printer.print_type?.includes('self_service'))) {
            return printer;
        }
    }

    return "Printer not found";
  }

  createKotItemLine(productName: any, item: any, attributes: any, combo_item_codes: any, included_combo_items: any) {
    this.builder.addPageBegin();
    let itemNameAndCountString = item.item_count + " x " + productName;
    let dHeight = Math.ceil(itemNameAndCountString.length / 35) * 30; //we suppose 35 character will come in one line
    this.builder.addPageArea(60, 0, 436, dHeight);
    this.builder.addText(itemNameAndCountString);
    this.builder.addPageArea(436, 0, 100, dHeight);
    this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
    this.builder.addPageEnd();

    if (attributes.length > 0) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addTextFont(this.builder.FONT_C);
      for (var attr = 0; attr < attributes.length; attr++) {
        this.builder.addPageBegin();

        var attrName = this.capitalizeFirstLetter(attributes[attr].name);
        let attrNameAndCountString = attributes[attr].count + " x " + attrName;
        let adHeight = Math.ceil(attrNameAndCountString.length / 35) * 30;
        this.builder.addPageArea(106, 0, 456, adHeight);
        this.builder.addText(attrNameAndCountString);
        this.builder.addPageEnd();
      }
      this.builder.addTextFont(this.builder.FONT_A);
    }
    if (combo_item_codes.length > 0) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addTextFont(this.builder.FONT_C);
      for (var attr = 0; attr < combo_item_codes.length; attr++) {
        this.builder.addPageBegin();

        var item_name = this.capitalizeFirstLetter(combo_item_codes[attr].item_name);
        let attrNameAndCountString = combo_item_codes[attr].quantity_in_combo + " x " + item_name;
        let adHeight = Math.ceil(attrNameAndCountString.length / 35) * 30;
        this.builder.addPageArea(106, 0, 456, adHeight);
        this.builder.addText(attrNameAndCountString);
        this.builder.addPageEnd();
      }
      this.builder.addTextFont(this.builder.FONT_A);
    }
    if (included_combo_items.length > 0) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addTextFont(this.builder.FONT_C);
      for (var attr = 0; attr < included_combo_items.length; attr++) {
        this.builder.addPageBegin();

        var item_name = this.capitalizeFirstLetter(included_combo_items[attr].item_name);
        let attrNameAndCountString = included_combo_items[attr].quantity + " x " + item_name;
        let adHeight = Math.ceil(attrNameAndCountString.length / 35) * 30;
        this.builder.addPageArea(106, 0, 456, adHeight);
        this.builder.addText(attrNameAndCountString);
        this.builder.addPageEnd();
      }
      this.builder.addTextFont(this.builder.FONT_A);
    }


    if (item.note) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addPageBegin();
      let noteString = "Note: " + item.note;
      let ndHeight = Math.ceil(noteString.length / 35) * 30;
      this.builder.addPageArea(106, 0, 456, ndHeight);
      this.builder.addText(noteString);
      this.builder.addPageEnd();
      this.builder.addTextFont(this.builder.FONT_A);
    }
  }

  capitalizeFirstLetter(string: string) {
    return string.trim().charAt(0).toUpperCase() + string.trim().slice(1).toLowerCase();
  }

  private cleanupPrintedKots() {
    // Clean up printed KOTs older than 24 hours
    setInterval(() => {
        localStorage.setItem('printed_kots', '[]');
    }, 24 * 60 * 60 * 1000); // 24 hours
  }

  // New method to handle direct KOT reprint data
  private processDirectReprintKOT(data: any) {
    try {
        if (!data || !data.cartItems) {
            throw new Error('Invalid KOT reprint data received');
        }

        console.log('Processing direct KOT reprint request:', data);
        const printerData = JSON.parse(localStorage.getItem('shop_printers'));
        if (!printerData) {
            throw new Error('No printer configuration found');
        }

        // Create a table object from the data
        const table = {
            tables: [data.tableNo],
            covers: data.seatNo,
            section_name: data.location
        };

        // Group items by department
        const departmentWiseItems = this.groupItemsByDepartment(data.cartItems);
        
        // Process each department's items
        Object.entries(departmentWiseItems).forEach(([dept, items]) => {
            let printer = this.findRequiredPrinter(printerData, dept);
            
            if (!printer || printer === "Printer not found") {
                console.warn(`No printer found for department ${dept}, using default printer`);
                printer = printerData.find(p => p.print_type?.includes('kitchen')) || printerData[0];
            }

            if (!printer) {
                throw new Error(`No printer available for department ${dept}`);
            }

            // Format KOT data for printing
            const kotPrintData = {
                printerName: printer.name || "Default Printer",
                paperSize: 80,
                kotJson: {
                    shopName: localStorage.getItem('shop_name') || '',
                    rePrint: true, // Always true for reprints
                    kotNumber: data.kotNumber || 0,
                    date: data.date || new Date().toJSON().slice(0, 10),
                    time: data.time || new Date().toLocaleTimeString(),
                    tableNo: data.tableNo || '',
                    seatNo: data.seatNo || '',
                    location: data.location || '',
                    partner: data.partner || '',
                    cartItems: items
                }
            };

            console.log('Sending direct reprint request for department:', dept);
            console.log('Print data:', kotPrintData);

            // Send print request
            fetch('http://127.0.0.1:5000/print/kot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(kotPrintData)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`); 
                }
                return response.json();
            })
            .then(result => {
                console.log('Reprint successful for department:', dept);
                this.messagingService.add({
                    severity: 'success',
                    summary: 'KOT Reprinted',
                    detail: `KOT #${data.kotNumber} reprinted successfully for ${dept}`
                });
            })
            .catch(error => {
                console.error('Error reprinting KOT:', error);
                this.messagingService.add({
                    severity: 'error',
                    summary: 'Reprint Error',
                    detail: `Failed to reprint KOT for ${dept}: ${error.message}`
                });
            });
        });

    } catch (error) {
        console.error('Error in processDirectReprintKOT:', error);
        this.messagingService.add({
            severity: 'error',
            summary: 'KOT Reprint Error',
            detail: error.message || 'Failed to reprint KOT'
        });
    }
  }

  // Helper method to group items by department for direct reprint
  private groupItemsByDepartment(items: any[]): Record<string, any[]> {
    let departmentWiseItems: Record<string, any[]> = {};
    
    if (!Array.isArray(items)) {
        console.error('Invalid items array:', items);
        return {};
    }

    items.forEach(item => {
        if (!item) {
            console.warn('Null or undefined item found in items array');
            return;
        }

        const dept = item.kitchen_department || 'default';
        if (!departmentWiseItems[dept]) {
            departmentWiseItems[dept] = [];
        }
        
        departmentWiseItems[dept].push(item);
    });

    return departmentWiseItems;
  }

  // Add this method to handle the original KOT reprint format
  private processReprintKOT(data: any) {
    try {
      if (!data || !data.kot || !data.table) {
        throw new Error('Invalid KOT reprint data received');
      }

      console.log('Processing KOT reprint request:', data);
      const printerData = JSON.parse(localStorage.getItem('shop_printers'));
      if (!printerData) {
        throw new Error('No printer configuration found');
      }

      // Mark as reprint
      const kotData = data.kot;
      kotData.is_reprint = true;

      // Process and print the KOT
      this.processKotPrinting(kotData, data.table, printerData);

      this.messagingService.add({
        severity: 'info',
        summary: 'KOT Reprint',
        detail: `Reprinting KOT #${kotData.kot_number}`
      });

    } catch (error) {
      console.error('Error processing KOT reprint:', error);
      this.messagingService.add({
        severity: 'error',
        summary: 'KOT Reprint Error',
        detail: error.message || 'Failed to reprint KOT'
      });
    }
  }

}