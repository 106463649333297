import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { APIHelper } from '../helpers/api-helper';
import { remove } from 'lodash-es';
import { MessageService } from 'primeng/api';
import { DiaryService } from '../helpers/services/diary.service';
import { PaymentHelper } from '../helpers/payment-helper';
@Component({
  selector: 'app-enter-diary-modal',
  templateUrl: './enter-diary-modal.component.html',
  styleUrls: ['./enter-diary-modal.component.less']
})
export class EnterDiaryModalComponent implements OnInit {
  decision: string;
  updated_diary_amount: any = "";
  type: any = "";
  customer_auth_token: any;
  order_id: any;
  paymentTypes: any = [];
  selectedPaymentType: any;
  diary_amount: number = 0;
  shop_code: any = localStorage.getItem('shop_code');
  isLoadingShow: boolean = false;
  private paymentHelper: PaymentHelper;  
  constructor(public modalRef: BsModalRef, public diaryService: DiaryService, public messageService: MessageService) {
    this.paymentHelper = PaymentHelper.getInstance(null, null, null, null, null, null, null);
  }

  ngOnInit() {

    let tempPaymentSubClassTypes = [];
    var tempPaymentTypes = JSON.parse(localStorage.getItem('payment_types')) || null;
    var shop_settings = JSON.parse(localStorage.getItem("shop_configuration"));
    let paymentTypes = shop_settings.payment_types;
    let payment_sub_class = shop_settings.payment_class;

    if (tempPaymentTypes && tempPaymentTypes[0]) {
      let finalList: any = [
        { name: 'CASH', sub_class: [], code: APIHelper.CASH_IN_HAND_TYPE },
        { name: 'CARD', sub_class: [], code: APIHelper.CARD_PAYMENT_TYPE }
      ];

      tempPaymentTypes.forEach((type) => {
        if ([APIHelper.SAVE_TO_DIARY_TYPE, APIHelper.CASH_IN_HAND_TYPE, APIHelper.CASH_ON_DELIVERY_TYPE].indexOf(type.payment_type_code) == -1 &&
          paymentTypes.indexOf(type.payment_type_code) != -1) {

          let detail = { name: type.payment_type, code: type.payment_type_code, sub_class: [] };
          finalList.push(detail);
        }
      });
      this.paymentTypes = finalList;
    }

    if (payment_sub_class) {
      this.paymentTypes.forEach(payment_type => {
        for (var x in payment_sub_class) {
          if (payment_type.code == x) {
            payment_type.sub_class = payment_sub_class[x];
          }
        }
      });
    }

    var removeMainPaymentType = []
    this.paymentTypes.forEach(payment_type => {
      if (payment_type.sub_class && payment_type.sub_class.length > 0) {

        let count = 0;
        payment_type.sub_class.forEach(sub_class => {
          if (sub_class.is_active) {
            removeMainPaymentType.push(payment_type.code);
            tempPaymentSubClassTypes.push({
              name: sub_class.name.toUpperCase(),
              type: payment_type.name,
              is_sub_class: true,
              code: payment_type.code
            });
          }
        });
      }
    });

    this.paymentTypes = this.paymentTypes.concat(tempPaymentSubClassTypes);

    remove(this.paymentTypes, pt => { return (removeMainPaymentType.indexOf(pt.code) != -1 && !pt.is_sub_class); });
  }

  onDone() {
    if (!this.type || !this.selectedPaymentType || !this.diary_amount) {
      return;
    } else {
      //add diary comment
      let diary_comment = "advance";
      if (this.type == 'clear_diary') { diary_comment = "clear" }

      let tempDiaryAmount = JSON.parse(JSON.stringify(this.diary_amount));
      tempDiaryAmount *= (-1);
      //get diary reference code
      let device_type = JSON.parse(localStorage.getItem('device_key'));
      let tempBill: any = localStorage.getItem('diary_ref_bill_num');
      let billNo = parseInt(tempBill);
      billNo = billNo ? billNo : 1;
      let terminal_name = (device_type && device_type.terminal_name) ? device_type.terminal_name : null;
      let terminal_id = (device_type && device_type.terminal_id) ? device_type.terminal_id : null;
      let diary_reference_code = billNo + '/' + terminal_name + "-" + terminal_id + '/WBP' + (new Date()).getTime();
      let newBillNo = billNo + 1;
      localStorage.setItem('diary_ref_bill_num', JSON.stringify(newBillNo));

      let loggedeInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};
      let shop_user_id = loggedeInUser.id;
      //calculate payment detail        
      let payment_details = {};
      if (this.selectedPaymentType.is_sub_class) {
        payment_details[this.selectedPaymentType.code] = { name: this.selectedPaymentType.type, amount: (this.order_id ? Math.abs(tempDiaryAmount) : tempDiaryAmount), payment_sub_class_info: [] }
      } else {
        payment_details[this.selectedPaymentType.code] = { name: this.selectedPaymentType.name, amount: (this.order_id ? Math.abs(tempDiaryAmount) : tempDiaryAmount), payment_sub_class_info: [] }
      }

      if (this.selectedPaymentType.is_sub_class) {
        payment_details[this.selectedPaymentType.code]["payment_sub_class_info"].push({ "sub_class_name": this.selectedPaymentType.name, amount: this.order_id ? Math.abs(tempDiaryAmount) : tempDiaryAmount })
      }
      this.isLoadingShow = true;
      this.diaryService.saveToDairy((this.order_id ? this.order_id : ""),
        this.customer_auth_token, diary_comment,
        payment_details,
        tempDiaryAmount,
        ((diary_comment == 'clear') ? true : false),
        this.shop_code,
        diary_reference_code,
        shop_user_id
      ).subscribe(async (pres) => {
        var tempRes = pres.json();
        if (tempRes.status === 0) {
          if(Array.isArray(tempRes.cleared_order_ids) && tempRes.cleared_order_ids.length>0) {
            try {
              let offlineOrders = JSON.parse(localStorage.getItem('temp_orders_list'))
              let clearedOrders = offlineOrders.filter((o:any) => tempRes.cleared_order_ids.includes(o.order_id))
              for(let x in clearedOrders) {
                let order = clearedOrders[x];
                await this.paymentHelper.setOrderDetails(order.order_id,(Array.isArray(order.kot_items) || [])) ;
              }              
            } catch(e) {console.log(e)}
          }

          if (this.selectedPaymentType.code == "PAYT0003") {
            let tempAmount = Math.abs(tempDiaryAmount);
            this.updateCashRegister(tempAmount, diary_comment)
          }
          this.updated_diary_amount = tempRes.result;
          this.messageService.add({ severity: 'success', summary: "Diary entry successfully done.", detail: '' });
          this.decision = "done";
          this.modalRef.hide();
        } else {
          this.messageService.add({ severity: 'error', summary: (tempRes.message) ? tempRes.message : "error while made diary entry", detail: '' });
        }
        this.isLoadingShow = false;
        this.resetDiaryBillCounter();
      }, err => {
        this.isLoadingShow = false;
        this.messageService.add({ severity: 'error', summary: "error while made diary entry", detail: '' });
        this.resetDiaryBillCounter();
      });
    }
  }

  updateCashRegister(cash_total, diary_comment) {
    let availableAmount = 0;
    if (localStorage.getItem('cash_register')) {
      let cash_register = JSON.parse(localStorage.getItem('cash_register'));
      if (cash_register && cash_register[0]) {
        if (diary_comment == "advance") {
          cash_register[0].cash_advance = cash_total + cash_register[0].cash_advance;
        } else {
          cash_register[0].cash_sale = cash_total + cash_register[0].cash_sale;
        }
      }
      localStorage.setItem('cash_register', JSON.stringify(cash_register));
    }
  }

  resetDiaryBillCounter() {
    let tempBill: any = localStorage.getItem('diary_ref_bill_num');
    tempBill = parseInt(tempBill);
    tempBill = tempBill ? tempBill : 1;
    let newBillNo = tempBill - 1;
    localStorage.setItem('diary_ref_bill_num', JSON.stringify(newBillNo));
  }
}
