import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InventoryModalComponent } from '../inventory-modal/inventory-modal.component';
import { Subscription } from 'rxjs';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { Dish } from '../dish';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'extra-charge-details',
  templateUrl: './extra-charge-details.component.html',
  styleUrls: ['./extra-charge-details.component.less']
})
export class ExtraChargeDetailsComponent implements OnInit {

  @Input() extraCharge: any;
	@Output() editExtraCharge: EventEmitter<any> = new EventEmitter();
	@Output() hideExtraCharge: EventEmitter<any> = new EventEmitter();
  public modalRef: BsModalRef;
  public isReadOnly: boolean = true;
  public subscriptions: Subscription[] = [];
  public rate:string='';
  public discount:string='';
  public note:string='';
  public price:string='';
  phase: number = 1;
  currencySymbol:any;
  isPercentsDiscount: boolean;
  
  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    //this.extraCharge = JSON.parse(JSON.stringify(this.extraCharge));
    this.rate = this.extraCharge.rate;
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shop_config.currency;
  }
  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  showInventoryModal (dish: Dish) {
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'done') {
      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(InventoryModalComponent, {class: 'common-modal'});
    this.modalRef.content.decision = "";
    this.modalRef.content.dish = dish;
  }
  editExtraChargeRate () {
    this.isReadOnly = false;
    this.phase = 2;
    this.editExtraCharge.emit(this.extraCharge);
  }
  keyPress(event: any) {
   const pattern = /[0-9]/;

   let inputChar = String.fromCharCode(event.charCode);
   if (event.keyCode != 8 && !pattern.test(inputChar)) {
     event.preventDefault();
   }
  }
  hideExtraChargeRate (extraCharge, form) {
    this.extraCharge.rate = this.rate;
    this.extraCharge.value = this.rate;
    this.editExtraCharge.emit(Object.assign(this.extraCharge));
    this.isReadOnly = true;
    this.phase = 1;
    this.hideExtraCharge.emit(this.extraCharge);
  }

}
