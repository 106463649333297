import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-kot-detail-modal',
  templateUrl: './kot-detail-modal.component.html',
  styleUrls: ['./kot-detail-modal.component.less']
})
export class KotDetailModalComponent implements OnInit {
  
  constructor(public modalRef: BsModalRef) { }
  public decision: string;
  public allSeatKot: any[]=[];
  public kot_description = "";
  bodyTag = document.getElementsByTagName("body")[0];

  ngOnInit() {  	
    this.bodyTag.setAttribute("class", "overflow-hidden");  	
  }

  onSkip() {
  	if(Array.isArray(this.allSeatKot)) {
  		this.allSeatKot.forEach(kotObj => {
        kotObj.kot[kotObj.kot.length-1].kot_description = "";
  			kotObj.kot[kotObj.kot.length-1].items.forEach(item => {
  				item.description = "";
  			});
  		})
  	}
  	this.onClose();
  }

  onSubmit() {
    if(Array.isArray(this.allSeatKot)) {
      this.allSeatKot.forEach(kotObj => {
        kotObj.kot[kotObj.kot.length-1].kot_description = (this.kot_description) ? this.kot_description :" ";        
      })
    }
    this.onClose(); 
  }

  onClose() {
    this.bodyTag.setAttribute("class", "");
    this.modalRef.hide();
  }
 }
