import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-quantity',
  templateUrl: './input-quantity.component.html',
  styleUrls: ['./input-quantity.component.css']
})
export class InputQuantityComponent implements OnInit {

  @Input('value') value: string;
  @Input('label') label: string = 'Quantity';
  @Input('can_be_sold_loose') can_be_sold_loose: boolean = false;
  @Output() sendValue = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }

  changeValue(val: any) {
    if(this.value == null || this.value == '') {
      this.value = '1';
    }
    var setData = parseFloat(this.value) + parseFloat(val);
    if(!setData){
      setData = 1;
    }
    this.value = setData.toString();
    this.sendData();
  }

  keyPress(event: any) {
    var self = this;
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 45 && (!this.can_be_sold_loose || (this.can_be_sold_loose && event.keyCode != 46)) && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    setTimeout(()=>{
      if(self.value == '0'){
        self.value = '1';
      }
      this.sendData();
    },30)
  }

  writeValue(val: any) {
    //this.value = Number(val);
    this.value = val;
    this.sendData();
  }

  sendData() {
    this.sendValue.emit(this.value);
  }

}
