import { Injectable } from '@angular/core';
import { Http } from '@angular/http';



import { APIHelper } from './api-helper';

@Injectable()
export class ShopLoginService {
    constructor(private http: Http) { }

    login(loginData: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_REGISTER, loginData);
    }

    verify(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_VERIFY, data);
    }

    resendOTP() {
        var data = {
            mobile_no: localStorage.getItem('mobile')
        };
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_RESEND, data);
    }

    sendTokenToServer(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_SEND_TOKEN_TO_SERVER, data);
    }

    logout(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_LOGOUT, data);
    }

    shopSetting(shop_code:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_MERCHANT_SHOP_PROFILE,"shop_code=" + shop_code);
    }

    updateDeviceKey(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_MERCHANT_UPDATE_DEVICE_KEY, data);
    }

    getUser(data: any) {
        const key = Object.keys(data)[0];
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_USER, key + "=" + data[key]);
    }
    
}