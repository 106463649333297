import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-inventory-modal',
  templateUrl: './inventory-modal.component.html',
  styleUrls: ['./inventory-modal.component.less']
})
export class InventoryModalComponent implements OnInit {
  decision: string;
  item_stock_list: any[];
  dish: any = {};
  currencySymbol: any;
  
  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
  }

}
