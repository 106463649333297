import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-otmodal',
  templateUrl: './otmodal.component.html',
  styleUrls: ['./otmodal.component.less']
})
export class OTModalComponent implements OnInit {
  decision: string = '';
  unit: any = {visitors: []};
  selectedIndex:any;
  selectedIndexList:any=[];
  constructor(public modalRef: BsModalRef) { }
  
  ngOnInit() {
    this.selectedIndexList = [];
  }

  isInSelectedList(seat){
    let is_available = this.selectedIndexList.indexOf(seat);
    if(is_available == -1){
      return false;
    }else{
      return true;
    }
  }

  selectSeat(index){
    let is_available = this.selectedIndexList.indexOf(index);
    if(is_available == -1){
      this.selectedIndexList.push(index);
    }else{
      this.selectedIndexList.splice(is_available,1);
    }
  }

}

