import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-table-transfer-modal',
  templateUrl: './table-transfer-modal.component.html',
  styleUrls: ['./table-transfer-modal.component.less']
})
export class TableTransferModalComponent implements OnInit {
  decision: string = '';
  fromSection:any=undefined;
  toSection:any=undefined;
  fromTable:any=undefined;
  toTable:any=undefined;
  places:any;
  activeHoldTableList:any=[];
  freeTableList:any=[];
  selecetedFromTable:any;
  sectionList:any;
  selecetedToTable:any;
  constructor(public modalRef: BsModalRef) { }
  ngOnInit() {
  }

  submitModal(){
    if(!this.fromSection || !this.toSection || !this.fromTable || !this.toTable || this.fromSection == 'undefined' || this.fromTable == 'undefined' || this.toSection == 'undefined' || this.toTable == 'undefined'){
      alert("First Select All value");
      return;
    }
    this.decision = 'transfer';
    this.selecetedFromTable = this.activeHoldTableList[this.fromTable];
    this.selecetedToTable = this.freeTableList[this.toTable];
    this.modalRef.hide();
  }

  onChangeToSection(e:any){

    this.toTable = undefined;
    let freeTableList = [];
    let index = 0;

    this.places.forEach(place => {
        place.units.forEach(unit => {
          if (unit.location == e && !unit["activePhase"]) {
            unit['toTransferTableIndex'] = index;
            freeTableList.push(unit);
            index++;
          }
        });
    });
    this.freeTableList =freeTableList;
  }

  onChangeFromSection(e:any){

    this.fromTable = undefined;
    let activeHoldTableList = [];
    let index = 0;

    this.places.forEach(place => {
      place.units.forEach(unit => {
        
        if (unit.location == e && unit["activePhase"]) {
          unit['fromTransferTableIndex'] = index;
          activeHoldTableList.push(unit);
          index++;
        }
      });
    });
    this.activeHoldTableList =activeHoldTableList;
  }

}

