import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SpecialOrderService } from '../helpers/services/special-order.service';
import { BaseConfig } from '../helpers/api-config';
import { MessageService } from 'primeng/api';
import { findLast } from 'lodash-es';

@Component({
  selector: 'app-special-order-modal',
  templateUrl: './special-order-modal.component.html',
  styleUrls: ['./special-order-modal.component.less']
})
export class SpecialOrderModalComponent implements OnInit {
  decision: string;
  loading: boolean = false;
  currencySymbol: any;
  dish: any = {};
  is_special_order: boolean = false;
  special_order_charges: any = 0;
  special_order_price: any = 0;
  shape_code: any = ""
  item_note: any = ""
  item_instruction: any = "";
  supplierList: any = [];
  selectedSupplier: any;

  special_order_image: any = "";
  special_order_image_full_path: any = "";
  special_order_details : any = null;

  isLoadingShow: boolean = false;
  constructor(public modalRef: BsModalRef,public messageService:MessageService,public specialOrderService:SpecialOrderService) { }

  ngOnInit() {
    setTimeout(() => {
      this.initData();     
    },200);
  }

  initData() {
    if(this.special_order_details) {
      this.is_special_order = true;
      this.special_order_charges =  this.special_order_details.special_order_charges,
      this.special_order_price = this.special_order_details.special_order_price,
      this.shape_code =  this.special_order_details.shape_code,      
      this.item_note =  this.special_order_details.item_note,      
      this.item_instruction =  this.special_order_details.item_instruction,      
      this.special_order_image  = this.special_order_details.special_order_image ? this.special_order_details.special_order_image : "";      
      if(this.special_order_image) {
        this.special_order_image_full_path = BaseConfig.config.API+this.special_order_image+"?t="+(new Date().getTime());      
      }
      if(Array.isArray(this.supplierList)) {
        let fSupplier = findLast(this.supplierList, sl => { return sl.id == this.special_order_details.supplier_id; });
        if(fSupplier) {
          this.selectedSupplier = fSupplier;
        }
      }
    } else {
      this.special_order_price = this.dish.unit_price;
    }
  }

  onSelectSupplier(event) {
    if(event && event.id) {
      this.selectedSupplier = event;
    }
  }  

  onFileUpload(event) {   
    if(event && event.extention && event.base64Data.indexOf("base64") != -1) {                  
      var imageArray = event.base64Data.split('base64,');      
      imageArray[0] = imageArray[0].replace("data:","");
      imageArray[0] = imageArray[0].replace(";","");
      let shop_code = localStorage.getItem("shop_code");
      let poImageName = "PO"+(new Date().getTime())+"_"+shop_code+".png";
      if(this.special_order_image) {
        let tIma = this.special_order_image.split("po/");        
        if(tIma && tIma[1]) {           
          poImageName = tIma[1]; 
        }
      }

      let imageData = {
        itemImage: {
          name: "po/"+poImageName,
          type: imageArray[0],
          dataURL:event.base64Data
        },
        shop_code: shop_code
      }
      this.isLoadingShow = true;
      this.specialOrderService.uploadSpecialOrderImage(imageData).subscribe((res:any) => {
        let resJSON = res.json();
        if(resJSON.status == 0) {
          this.special_order_image = resJSON.result.name;
          this.special_order_image_full_path = BaseConfig.config.API+resJSON.result.name+"?t="+(new Date().getTime());
        } else {
          this.messageService.add({ severity: 'error', summary: "Error while uploading special order image.", detail: ((resJSON.message ? resJSON.message : "") + '.Please try again.') });        
        }
        this.isLoadingShow = false;
      },error => {
        this.messageService.add({ severity: 'error', summary: "Error while uploading special order image.", detail: 'Please try again.' });      
        this.isLoadingShow = false;        
      });      
    }
  }

  onUnitValueChange(event) {        
    this.special_order_price = (!this.dish.default_unit_value || this.dish.default_unit_value<=0) ? (this.dish.unit_value * this.dish.default_unit_price): ((this.dish.unit_value/this.dish.default_unit_value)*this.dish.default_unit_price) + (this.special_order_charges ? parseFloat(this.special_order_charges) : 0);
    this.special_order_price = parseFloat(parseFloat(this.special_order_price).toFixed(3));
  }

  onChangeSpecialOrderCharges() {
    this.special_order_price = (!this.dish.default_unit_value || this.dish.default_unit_value<=0) ? (this.dish.unit_value * this.dish.default_unit_price): ((this.dish.unit_value/this.dish.default_unit_value)*this.dish.default_unit_price) + (this.special_order_charges ? parseFloat(this.special_order_charges) : 0);
    this.special_order_price = parseFloat(parseFloat(this.special_order_price).toFixed(3));
  }

  done() {
    if(this.is_special_order) {
      if (!this.selectedSupplier || !this.selectedSupplier.id) {
        this.messageService.add({ severity: 'errors', summary: "Please select supplier", detail: '' });      
        return;
      } else if (this.special_order_price<=0) {
        this.messageService.add({ severity: 'errors', summary: "Special order price can't be 0", detail: '' });      
        return;
      } else if (this.dish.unit_value<=0) {
        this.messageService.add({ severity: 'errors', summary: "Unit value can't be 0 or less", detail: '' });      
        return;
      }

      this.special_order_details = {
        special_order_charges: this.special_order_charges ?  this.special_order_charges : 0,
        special_order_price: this.special_order_price,
        item_instruction: this.item_instruction,
        unit: this.dish.unit,
        unit_value: this.dish.unit_value,
        shape_code: this.shape_code,
        item_note: this.item_note,
        supplier_id: this.selectedSupplier.id,        
        special_order_image: this.special_order_image ? this.special_order_image : "",
      }
    } else {
      this.special_order_details = null;
    }

    this.decision = "done";
    this.modalRef.hide();
  }

  resetSpecialImage() {
    /*this.previous_special_order_image = JSON.parse(JSON.stringify(this.special_order_image));*/
    this.special_order_image = "";
    this.special_order_image_full_path = "";
  }
}
