import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { APIHelper } from '../pages/helpers/api-helper';
import { ShopLoginService } from '../pages/helpers/shopLogin.service';
import { AppStrings } from '../pages/helpers/app-strings';
import { MessageService } from 'primeng/api';
import { CommonService } from '../pages/helpers/services/common.service';

import { FirebaseMessagingService } from '../pages/helpers/services/firebase-messaging.service';

@Component({
    selector: 'login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.css'],
    providers: [ShopLoginService, AppStrings]
})
export class LoginFormComponent {
    loginStep: number = 1;
    isVisibleTerminalBlock:boolean = false;
    terminalList:any[];
    selectedTerminal:string='undefined';
    //@Input() modalRef;

    constructor(private http: Http, public modalRef: BsModalRef,
        private _loginService: ShopLoginService,
        private messageService:MessageService,
        private _Titleservice: AppStrings,
        private router: Router,
        private msg:FirebaseMessagingService,
        private commonService:CommonService) {
    }

    onLoggedin() {
        console.log('isLoggedin', 'true');
    }

    requestOPT(loginForm) {
        var requestData = loginForm.value.firstStep;
        if (requestData.user !== '' && requestData.shop_code !== '' && requestData.shop_code !== '') {
            requestData.shop_code = requestData.shop_code.toUpperCase();
            var requestData = loginForm.value.firstStep;
            localStorage.setItem('user', requestData.user);
            localStorage.setItem('shop_code', requestData.shop_code);
            var outerScope = this;
            var loginData = {
                user: requestData.user,
                shop_code: requestData.shop_code,
                passphrase: requestData.passphrase
            }
            outerScope._loginService.login(loginData).subscribe((res) => {
                var apiRes = res.json();
                if (apiRes.status === 0) {
                    localStorage.setItem("session_id", apiRes.result.session_id);
                    APIHelper.getInstance(null).setAuthToken(apiRes.result.auth_token);
                    this.loginStep = 2;
                } else {
                    alert(apiRes.message);
                }
            });
        }
    }

    verifyOTP(form: any) {
        var verifyData = {
            verification_code: form.value.verification_code,
            /*license_code: form.value.license_code,
            shop_code: form.value.shop_code*/
        };
        var outerScope = this;
        outerScope._loginService.verify(verifyData).subscribe((res) => {
            var apiRes = res.json();
            if (apiRes.status === 0) {
                /*localStorage.setItem('shop_code', verifyData.shop_code);*/
                APIHelper.getInstance(null).setLicenceVerified();

                this._loginService.shopSetting(localStorage.getItem("shop_code")).subscribe((res) => {
                    var resData = res.json();
                    this.loginStep = 3;
                    if (resData.status === 0) {
                        this.terminalList = resData.result.shop_info.shop_terminals.filter(terminal => !terminal.is_captain_terminal && terminal.is_active);
                    } else {
                        alert(resData.message);
                    }
                });
                //this.router.navigate(['verify-pin']);
            } else {
                alert(apiRes.message);
            }
        });
    }

    sendTokenToServer(token){
        let sendData = {
            device_uid : token,
            device_type  : 'web',
            app_type  : 'pos',
            shop_code : localStorage.getItem('shop_code'),
        }
        this._loginService.sendTokenToServer(sendData).subscribe((res) => {
            var apiRes = res.json();
            if (apiRes.status === 0) {
                localStorage.setItem("push_record_id",apiRes.result.id);
            } else {
                alert(apiRes.message);
            }
        });
    }

    submitTerminal(){
        if(this.selectedTerminal == 'undefined'){
            alert('Select terminal');
            return;
        }
        let shop_code = localStorage.getItem('shop_code');
        let sData = {
            shop_code:shop_code,
            terminal_id:this.selectedTerminal
        }

        this._loginService.updateDeviceKey(sData).subscribe((res) => {
            var resData = res.json();

                console.log("device keyyyyy");

            if (resData.status === 0) {

                let terminal_name = null;
                this.terminalList.forEach(terminal=>{
                    if(terminal.terminal_id == this.selectedTerminal){
                        terminal_name = terminal.terminal_name
                    }
                });

                this.msg.getPermission().then( data =>{
                    console.log(data);
                    if(data){
                        console.log("send to server",data);
                        this.sendTokenToServer(data);
                    }else{
                        console.log("Something went wrong");
                    }
                });

                let setData = {
                    'terminal_id':this.selectedTerminal,
                    'device_key':resData.result.device_key,
                    'terminal_name':terminal_name
                }
                localStorage.setItem('device_key',JSON.stringify(setData));
                this.getShopPrinters();
                this.router.navigate(['verify-pin']);
            } else {

               /* let terminal_name = null;
                this.terminalList.forEach(terminal=>{
                    if(terminal.terminal_id == 2){
                        terminal_name = terminal.terminal_name
                    }
                });

                let setData = {
                    'terminal_id':2,
                    'device_key':resData.result.device_key,
                    'terminal_name':terminal_name
                }
                localStorage.setItem('device_key',JSON.stringify(setData));
                this.router.navigate(['verify-pin']);*/
                alert(resData.result.message);
            }
        });
    }

    resendOTP() {
        var outerScope = this;
        outerScope._loginService.resendOTP().subscribe((res) => {
            alert(this._Titleservice.signupValues().newCodeSent);
        });
    }

    getShopPrinters(){

        let shopCode = localStorage.getItem('shop_code');
        let sendData = {
           'shop_code':shopCode
         }

        this.commonService.getPrinters(sendData).subscribe((res) => {
            let printerData = res.json();
            if(printerData.status === 0){
                var printers_list = printerData.result;
              localStorage.setItem('shop_printers',JSON.stringify(printers_list));
            }else{
                console.log("Printer data could not be fetched");
                alert(this._Titleservice.signupValues().printerError);
                // this.messagingService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
            }
        });
    }

}


