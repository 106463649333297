import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-order-note-modal',
  templateUrl: './order-note-modal.component.html',
  styleUrls: ['./order-note-modal.component.less']
})
export class OrderNoteModalComponent implements OnInit {
  
  constructor(public modalRef: BsModalRef) { }
  public decision: string;
  public note: string='';

  ngOnInit() {  	
  }
 }
