import { Component, OnInit} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';


import { LoginFormComponent } from '../login-form/login-form.component';
@Component({
  selector: 'login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit {
  bsModalRef: BsModalRef;
  public config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true
  };
  constructor(private modalService: BsModalService,private router:Router) {
    this.openModalWithComponent();
  }

  ngOnInit() {
    if(localStorage.getItem('device_key')){
      this.router.navigate(['verify-pin']);
    }
  }
  
 
  public openModalWithComponent() {
    // var configuration = Object.assign({}, this.config, {class: 'centred-modal'});
    // this.bsModalRef = this.modalService.show(LoginFormComponent, configuration);
    // this.bsModalRef.content.title = 'Modal with component';
  }
}
