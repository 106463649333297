import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../helpers/base-modal.component';
import { CustomerService } from '../helpers/services/customer.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-employer-modal',
  templateUrl: './employer-modal.component.html',
  styleUrls: ['./employer-modal.component.less']
})
export class EmployerModal extends BaseModalComponent implements OnInit {
  
  constructor(public modalRef: BsModalRef,public customerService:CustomerService,
    public messageService: MessageService) {
  	super(modalRef);
  }

  public isLoadingShow: boolean = false;
  public decision: string;
  public employer: any;
  shop_configuration:any= JSON.parse(localStorage.getItem("shop_configuration")) || null;
  gstStateList: any = [];  
  selectedState: any = null;
  ngOnInit() {
    this.getGSTStateList();
    setTimeout(()=> {
      this.setState();
    },300);
  }

  done(){  
    this.close(false);
  }

  setState() {
    if(this.employer.state) {
      for(var i=0;i<this.gstStateList.length;i++) {
        if(this.employer.state.toLowerCase().indexOf(this.gstStateList[i].state.toLowerCase()) != -1 ) {
          this.selectedState = this.gstStateList[i];
          break;
        }
      }
    }
  }

  getGSTStateList() {
    this.gstStateList = JSON.parse(localStorage.getItem("gst_state_codes")) || [];         
  }

  onSelectState(event) {    
    if(event && event.state_code) {
      this.selectedState = event;  
      this.employer.state = event.name;      
    }
  }

  onSubmitForm() {
    if(!this.employer.employer_name) {
      return;
    }
    var sendData = {
      "address": this.employer.address,
      "city": this.employer.city,
      "employer_name": this.employer.employer_name,
      "tax_number": this.employer.tax_number,
      "pin_code":this.employer.pin_code,
      "state": this.employer.state,
      "shop_code": localStorage.getItem("shop_code") || ""
    }

    this.isLoadingShow = true;
    if(!this.employer.employer_code) {
      this.customerService.add_employer(sendData).subscribe((res) => {
        let employerData = res.json();
        if(employerData.status == 0) {
          this.employer = employerData.result;
          this.decision='add';
          this.done();
        } else {
          this.messageService.add({ severity: 'error', summary: "internal server error", detail: '' });        
        }
        this.isLoadingShow = false;
      },(error) => {
        this.messageService.add({ severity: 'error', summary: "internal server error", detail: '' });        
        this.isLoadingShow = false;
      });
    } else{
      sendData["employer_code"] = this.employer.employer_code;      
      this.customerService.update_employer(sendData).subscribe((res) => {
        let employerData = res.json();
        if(employerData.status == 0) {
          this.employer = employerData.result;
          this.decision='update';
          this.done();
        } else {
          this.messageService.add({ severity: 'error', summary: "internal server error", detail: '' });        
        }
        this.isLoadingShow = false;
      },(error) => {
        this.messageService.add({ severity: 'error', summary: "internal server error", detail: '' });        
        this.isLoadingShow = false;
      });
    }
  }
  
}
