import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
 
@Injectable()
export class PlacesMessage {
    private subject = new Subject<any>();
 
    sendPlacesInfo(message: string) {
        this.subject.next({ places: message });
    }
    getPlacesInfo(): Observable<any> {
        return this.subject.asObservable();
    }
}