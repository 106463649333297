import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-third-party-delivery-time-modal',
  templateUrl: './third-party-delivery-time-modal.component.html',
  styleUrls: ['./third-party-delivery-time-modal.component.less']
})
export class ThirdPartyDeliveryTimeModalComponent implements OnInit {
  decision: string;
  delivery_time = 30;
  prep_time = 30;
  
  constructor(public modalRef: BsModalRef,public messageService:MessageService) { }
  
  ngOnInit() {
  } 
  
  done(){    
    if(this.prep_time > 30) {
      this.messageService.add({ severity: 'error', summary: "preparation time should be less then 30 minutes", detail: '' });
      return;
    }
    this.decision='done';
    this.modalRef.hide()
  }

}
