import { Directive, HostListener, HostBinding, Input } from '@angular/core';
import { DragService } from './drag-service.service';

@Directive({
  selector: '[draggableDish]'
})
export class DraggableDishDirective {

  constructor(private dragService: DragService) { }
  private options: DraggableOptions = {};
  @HostBinding('draggable')
  get draggable() {
    return true;
  }
  @Input()
  set draggableDish(options: DraggableOptions) {
    if (options) {
      this.options = options;
    }
  }
  private shift = [0, 0];
  @HostListener('touchstart', ['$event'])
  onTouchStart(event) {
    var target = this.getParent(event.target, "dish"),
      touch = event.targetTouches[0];
    this.shift = [touch.pageY - target.getBoundingClientRect().top,
    touch.pageX - target.getBoundingClientRect().left];
  }
  @HostListener('touchmove', ['$event'])
  onTouchMove(event) {
    const { zone = 'zone', data = {} } = this.options;
    var target = this.getParent(event.target, "dish"),
      touch = event.targetTouches[0],
      landElement = event.target.parentElement;

    target.style.width = target.getBoundingClientRect().width + "px";
    target.style.height = target.getBoundingClientRect().height + "px";
    target.classList.add('in-drag');
    target.style.left = (touch.pageX - this.shift[1]) + 'px';
    target.style.top = (touch.pageY - this.shift[0]) + 'px';
    this.dragService.startDrag(zone);
    event.preventDefault();
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event) {
    const { zone = 'zone', data = {} } = this.options;
    var target = this.getParent(event.target, "dish"),
        landElement = target.parentElement,
        parentTop = landElement.getBoundingClientRect().top,
        visitors = document.getElementsByClassName("meal-course");
    if (!target.classList.contains("in-drag")) {
      return;
    }

    landElement = this.getLandingPlace(visitors, target);
    this.resetStyles(target);
    this.dragService.startDrag(zone);
    this.fireCustomEvent('drop', landElement, data);
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(event) {
    const { zone = 'zone', data = {} } = this.options;

    this.dragService.startDrag(zone);
    event.dataTransfer.setData('DISH', JSON.stringify(data));
  }
  @HostListener('touchcancel', ['$event'])
  onDragCancel(event) {
    var target = this.getParent(event.target, "dish");

    this.resetStyles(target);
  }
  resetStyles(target) {
    target.style.top = null;
    target.style.left = null;
    target.style.width = null;
    target.style.height = null;
    target.classList.remove('in-drag');
  }
  fireCustomEvent(eventName, element, data) {
    'use strict';
    var event;
    data = data || {};
    if (document.createEvent) {
      event = document.createEvent("HTMLEvents");
      event.initEvent(eventName, true, true);
    } else {
      event = document["createEventObject"]();
      event.eventType = eventName;
    }

    event.eventName = eventName;
    event = Object.assign(event, data);
    event.dataTransfer = {
      getData: function () {
        var theData = data;
        return JSON.stringify(theData);
      }
    };

    if (document.createEvent) {
      element.dispatchEvent(event);
    } else {
      element.fireEvent("on" + event.eventType, event);
    }
  }
  getParent(child, targetClass) {
    var target = child;
    while (target && !target.classList.contains(targetClass)) {
      target = target.parentElement;
    }
    return target;
  }
  getLandingPlace(places, target) {
    var i = places.length - 1, place,
        targetCenter = target.getBoundingClientRect().top + target.getBoundingClientRect().height / 2;

    for (let ind = 0, len = places.length; ind < len; ind++) {
      if (places[ind].getBoundingClientRect().top < targetCenter && places[ind].getBoundingClientRect().bottom > targetCenter) {
        place = places[ind];
      }
    }

    return place;
  }
}
export interface DraggableOptions {
  zone?: string;
  data?: any;
}