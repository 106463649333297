import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-third-party-reject-order-reason-modal',
  templateUrl: './third-party-reject-order-reason-modal.component.html',
  styleUrls: ['./third-party-reject-order-reason-modal.component.less']
})
export class ThirdPartyRejectOrderReasonModalComponent implements OnInit {
  decision: string;
  third_party_partner: string;
  reject_reason = "ITEM_OUT_OF_STOCK";
  time_to_penalty_map: any = [];
  order_created_at:any;
  other_reason = "";
  showRejectionPenalty: boolean = false;  
  rejectionTimeDiff: any = 0;
  rejectionPenalty: any = 0;
  constructor(public modalRef: BsModalRef,public messageService:MessageService) { }
  
  ngOnInit() {
  } 
  
  done(){       
    console.log('reject_reason:', this.third_party_partner, this.decision);
    if(!this.reject_reason) {
      this.messageService.add({ severity: 'error', summary: "please select the reason.", detail: '' });
      return;
    }

    if(this.reject_reason == "OTHER" && !this.other_reason ) {
      this.messageService.add({ severity: 'error', summary: "please add reason.", detail: '' });
      return;
    }

    if(this.reject_reason == "OTHER") {
      this.reject_reason = this.other_reason;
    }

    if(!this.showRejectionPenalty && this.rejectionPenalty>0) {
      this.showRejectionPenalty = true;
    } else {
      this.decision='done';
      this.modalRef.hide()
    }
  }

  getPenaltyMessage() {
    let message = "";
    if(this.order_created_at && this.time_to_penalty_map && this.time_to_penalty_map.length>0) {
      let tdate = new Date(this.order_created_at);
      if(tdate) {
        let tdiff = (new Date().getTime()-tdate.getTime())/1000;
        for(var i=0,tlen=this.time_to_penalty_map.length;i<tlen;i++) {          
          if(this.time_to_penalty_map[i].start_duration <= tdiff && this.time_to_penalty_map[i].end_duration >= tdiff) {
            message = "₹"+this.time_to_penalty_map[i].penalty_amount + " will be charged as rejection penalty.";
            this.rejectionPenalty = this.time_to_penalty_map[i].penalty_amount;
            this.rejectionTimeDiff = Math.round(tdiff/60);            
            break;
          }
        }        
      }
    }
    return message;
  }

  /*message = ""*/
}
