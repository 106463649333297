import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Md5 } from 'ts-md5/dist/md5';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-verify-pin-modal',
  templateUrl: './verify-pin-modal.component.html',
  styleUrls: ['./verify-pin-modal.component.less']
})
export class VerifyPinModalComponent implements OnInit, AfterViewInit {
  decision: string;
  pinNumber: number = undefined;
  targetUnit: any;
  modal = {covers: null};
  constructor(public modalRef: BsModalRef,private messageService:MessageService) { }
  
  ngOnInit() {
    this.setFocus();
  }
  ngAfterViewInit () {
    document.querySelector("#pinNumber")["focus"]();
  }

  setFocus(){
    setTimeout(()=>{
      document.querySelector("#pinNumber")["focus"]();
    },100);
  }

  verify(){
    
    if(!this.pinNumber){
      this.messageService.add({ severity: 'error', summary: 'Enter your pin number', detail: '' });
      return;
    }
    var userList = JSON.parse(localStorage.getItem('shop_user_list'));
    var validUser = [];
    if(userList.length>0){
      validUser = userList.filter((i: any) => i.pin === Md5.hashStr(this.pinNumber.toString()));
      if(validUser.length > 0){
          this.decision='done';
          this.modalRef.hide();
      }else{
        this.messageService.add({ severity: 'error', summary: 'Entered wrong pin', detail: '' });
      }
    }
  }
}
