/**
 * (c) OneGreenDiary Software Pvt. Ltd.
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform,
 *    solely reside with the third-party.  
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Once OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without
 *    prior agreement with OneGreenDiary Software Pvt. Ltd.
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';



import { APIHelper } from '../api-helper';

@Injectable()
export class ReportService {
    constructor(private http: Http) { }

    getConsumerReport(shop_code: any, from_date: any, to_date: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CONSUMER_REPORT, { 'shop_code': shop_code, 'from_date': new Date(from_date), 'to_date': new Date(to_date) });
    }

    getReport(shop_code: any, from_date: any, to_date: any,report_type: any, sub_report_type: any, tags: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_REPORT, { 'shop_code': shop_code, 'from_date': new Date(from_date), 'to_date': new Date(to_date) ,'report_type': report_type, 'sub_report_type': sub_report_type, 'tags': tags});
    }

    getSalesSummaryReport(shop_code: any, from_date: any, to_date: any) {
        return APIHelper.getInstance(this.http).reportApiCall(APIHelper.API_SALES_SUMMARY_REPORT, { 'shop_code_list': [shop_code], 'from_date': new Date(from_date), 'to_date': new Date(to_date) ,'is_excel_data_include':false,'is_array_format_response':true,'sub_report_type':'TOTAL IN DATE RANGE'});
    }
    
}
