export class MultiLanguageTitles {
    public static titles = {
        "shop name": {
            "ar-qa": "إسم المحل",
        },
        "kot no": {
            "ar-qa": "رقم كوت",
        },
        "section": {
            "ar-qa": "القسم",
        },
        "address": {
            "ar-qa": "العنوان",
        },
        "phone number": {
            "ar-qa": "رقم الهاتف",
        },
        "pin code": {
            "ar-qa": "الرقم السري",
        },
        "bill no": { 
            "ar-qa": "رقم الفاتورة",
        },
        "user name": { 
            "ar-qa": "إسم المستخدم",
        },
        "table no": {
            "ar-qa": "رقم المنضدة",
        },
        "seat no": {
            "ar-qa": "رقم المقعد",
        },       
        "particulars": {
            "ar-qa": "التفاصيل",
        },
        "rate": {
            "ar-qa": "السعر",
        },
        "qty": {
            "ar-qa": "الكمية",
        },
        "amount": {
            "ar-qa":"المبلغ",
        },
        "sub total": {
            "ar-qa":"إجمالي فرعي",
        },
        "total": {
            "ar-qa":"إجمالي: رق" ,
        },
        "transaction date": {
          "ar-qa":"تاريخ المعاملة",  
        },
        "terminal": {
          "ar-qa":"نقطة البيع",    
        },
        "ref id": {
          "ar-qa":"رقم هوية المرجعية",    
        },
        "thank you": {
          "ar-qa":"شكراً لكم",      
        },       
        "receipt": {
            "ar-qa":"إيصال ",
        },
        "delivery date": {
            "ar-qa":"تاريخ الإستلام",  
        },            
        "cash": {
            "ar-qa":"نقداً",  
        },
        "discount": {
            "ar-qa":"الخصم",    
        },
        "cash discount": {
            "ar-qa":"الخصم النقدي" ,
        },
        "round off": {
            "ar-qa": "نهاية الجولة"
        },
        "customer information": {
            "ar-qa": "معلومات العميل" 
        },
        "name": {
            "ar-qa": "اسم"
        },
        "credit note amount": {
            "ar-qa": "مبلغ إشعار الائتمان"
        }
    };
}
