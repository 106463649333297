import { Injectable } from '@angular/core';
import { Http } from '@angular/http';



import { APIHelper } from '../api-helper';

@Injectable()
export class RecentOrdersService {
    constructor(private http: Http) { }

    getAllOrdersInfo(data:any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_ALL_ORDER_INFO,data);
    }

    getSelectedOrderInfo(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_SELECTED_ORDER_INFO,data);
    }

    cancelSelectedOrder(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_CANCEL_SELECTED_ORDER,data);
    }

    rejectSelectedOrder(data:any){
        if (data.partner_short_code === 'urban_piper' || data.partner_short_code.indexOf('urban_piper') !== -1) {
            return APIHelper.getInstance(this.http).urbanPiperApiCall(APIHelper.UP_ORDER_STATUS_UPDATE, data);
        }
        
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_REJECT_SELECTED_ORDER,data);
        
    }

    changeSelectedOrderInfo(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_CHANGE_SELECTED_ORDER,data);
    }

    completeSelectedOrderInfo(data:any){
        if (data.partner_short_code === 'urban_piper' || data.partner_short_code.indexOf('urban_piper') !== -1) {
            return APIHelper.getInstance(this.http).urbanPiperApiCall(APIHelper.UP_ORDER_STATUS_UPDATE, data);
        }
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_COMPLETE_SELECTED_ORDER,data);
    }

    deliverSelectedOrderInfo(data:any){
        console.log('Send Data:', data);
        if (data.partner_short_code === 'urban_piper' || data.partner_short_code.indexOf('urban_piper') !== -1) {
            return APIHelper.getInstance(this.http).urbanPiperApiCall(APIHelper.UP_ORDER_STATUS_UPDATE, data);
        }
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_DELIVER_SELECTED_ORDER,data);
    }

    dispatchSelectedOrderInfo(data:any){
        if (data.partner_short_code === 'urban_piper' || data.partner_short_code.indexOf('urban_piper') !== -1) {
            return APIHelper.getInstance(this.http).urbanPiperApiCall(APIHelper.UP_ORDER_STATUS_UPDATE, data);
        }
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_DISPTACH_SELECTED_ORDER,data);
    }

    selfServiceDeliverSelectedOrderInfo(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_SELF_SERVICE_DELIVER_SELECTED_ORDER,data);
    }

    sendOrderReadyAlert(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_ORDER_READY_ALERT,data);
    }

    acceptSelectedOrderInfo(data:any){
        if (data.partner_short_code === 'urban_piper' || data.partner_short_code.indexOf('urban_piper') !== -1) {
            return APIHelper.getInstance(this.http).urbanPiperApiCall(APIHelper.UP_ORDER_STATUS_UPDATE, data);
        }
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_ACCEPT_SELECTED_ORDER,data);
    }

    voidSelectedOrder(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_VOID_ORDER,data);
    }

    thirdPartyOrderReady(data:any){
        if (data.partner_short_code === 'urban_piper' || data.partner_short_code.indexOf('urban_piper') !== -1) {
            return APIHelper.getInstance(this.http).urbanPiperApiCall(APIHelper.UP_ORDER_STATUS_UPDATE, data);
        }
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_THIRD_PARTY_ORDER_READY,data);
    }

    riderPickedUpOrder(data:any){
        if (data.partner_short_code === 'urban_piper' || data.partner_short_code.indexOf('urban_piper') !== -1) {
            return APIHelper.getInstance(this.http).urbanPiperApiCall(APIHelper.UP_ORDER_STATUS_UPDATE, data);
        }
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_RIDER_PICKEDUP_ORDER,data);
    }

    riderAssignedToOrder(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_RIDER_ASSIGNED_TO_ORDER,data);
    }
    
    riderTemperatureDetail(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_RIDER_TEMPERATURE_DETAIL,data);
    }

    clearPendingAmount(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_PENDING_AMOUNT,data);
    }

    contact_search(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_CONTACT_SEARCH,data);
    }

    paidAmount(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_PAID_AMOUNT,data);
    }

    changeOrderVehicleDetail(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CHANGE_ORDER_VEHICLE_DETAIL,data);
    }
}