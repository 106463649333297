import { Component,OnInit,Output,EventEmitter } from '@angular/core';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { LoginFormComponent } from '../../login-form/login-form.component';
import { SelectsBoxComponent } from '../selects-box/selects-box.component';
import { MenuComponent } from '../menu/menu.component';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
import { ShopLoginService } from '../../pages/helpers/shopLogin.service';
import { CaptainAppService } from '../../pages/helpers/services/captain-app.service';
import { BaseConfig } from '../helpers/api-config';
import { Subscription } from 'rxjs';
import { CommonService } from '../../pages/helpers/services/common.service';
import { StateGuardService } from '../state-guard.service';
import { ActivatedRoute, Router,NavigationEnd  } from '@angular/router';
import { UpdateInventoryMessage } from '../update-inventory-message.service';
import { MessageService } from 'primeng/api';
import { CaptainAppHelper } from '../helpers/captain-app-helper';
import { remove,groupBy,forOwn } from 'lodash-es';

declare var $:any;

@Component({
  selector: 'app-demo-modal-service-static',
  templateUrl: './demo-modal-service-static.component.html',
  styleUrls: ['./demo-modal-service-static.component.css'],
})
export class DemoModalServiceStaticComponent implements OnInit {
  modalRef: BsModalRef;
  public subscriptions: Subscription[] = [];
  subscription:Subscription;
  isUpdateAvailable:boolean = false;
  isLoadingShow:boolean=false;
  @Output("onSignOut") onSignOut: EventEmitter<any> = new EventEmitter()

  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  public isItFoodOrderingPage:boolean = false;

  public config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true
  };
  constructor(private messageService:MessageService,private menuComponent:MenuComponent,
              private updateInventoryService:UpdateInventoryMessage,private commonService:CommonService,
              private stateGuard:StateGuardService,private selectsBoxComponent:SelectsBoxComponent,
              private captainAppService:CaptainAppService,private shopLoginService:ShopLoginService,
              private modalService: BsModalService,private router:Router,private captainAppHelper:CaptainAppHelper) {

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        let index = event.url.indexOf('food-ordering');
        if(index == -1){
          this.isItFoodOrderingPage =false;
        }else{
          this.isItFoodOrderingPage =true;
        }
      }
    });
  }

  ngOnInit() {
    this.subscription = this.updateInventoryService.getUpdateAvailableInfo().subscribe(message => {
      this.isUpdateAvailable = JSON.parse(JSON.stringify(message.UpdateInfo));
    });
  }

  public openModalWithComponent() {
    this.onSignOut.emit(true);
  }

  updateOnHoldItems(updatedItems){
    let allPlaces = this.stateGuard.obtain('selects-box.component:places') || [];

    if(allPlaces && allPlaces[0]){
      allPlaces.forEach((place) =>{
        if(place.units && place.units[0]) {
          place.units.forEach((units) =>{
            if(units && units.visitors && units.visitors[0]){
              units.visitors.forEach((visitor) =>{
                if(visitor && visitor.dishes && visitor.dishes[0]){
                  visitor.dishes[0].forEach((dish) =>{
                    updatedItems.forEach(updatedItem => {
                      if(dish.selected_variant_item && dish.selected_variant_item.item_code == updatedItem.item_code){
                        dish.selected_variant_item = this.updateItemAfterVersionChange(dish.selected_variant_item,updatedItem)
                      } else if(dish.item_code == updatedItem.item_code){
                        dish = this.updateItemAfterVersionChange(dish,updatedItem)
                      }
                    });
                  });

                  //remove delete item from dish
                  remove(visitor.dishes[0], (dish) => {
                    if(dish.selected_variant_item){
                      return dish.selected_variant_item.update_comment == "delete";
                    } else {
                     return dish.update_comment == "delete";
                    }
                  });
                }
              });
            }
          })
        }
      })
    }

    let localOffUnits = [];
    try {
      localOffUnits = JSON.parse(localStorage.getItem("offUnits"));
    } catch(e) {}
    
    if(localOffUnits && localOffUnits.length>0) {
      localOffUnits.forEach((units) =>{
          if(units && units.visitors && units.visitors[0]){
            units.visitors.forEach((visitor) =>{
              if(visitor && visitor.dishes && visitor.dishes[0]){
                visitor.dishes[0].forEach((dish) =>{
                  updatedItems.forEach(updatedItem => {
                    if(dish.selected_variant_item && dish.selected_variant_item.item_code == updatedItem.item_code){
                      dish.selected_variant_item = this.updateItemAfterVersionChange(dish.selected_variant_item,updatedItem);
                    } else if(dish.item_code == updatedItem.item_code){
                      dish = this.updateItemAfterVersionChange(dish,updatedItem)
                    }
                  });
                });

                //remove delete item from dish
                remove(visitor.dishes[0], (dish) => {
                  if(dish.selected_variant_item){
                    return dish.selected_variant_item.update_comment == "delete";
                  } else {
                   return dish.update_comment == "delete";
                  }
                });
              }
            });
          }
        });

        this.stateGuard.amend('basket.component:offUnits', function (offUnits) {
          offUnits = localOffUnits;
          localStorage.setItem('offUnits',JSON.stringify(offUnits));
        });
    }
  }


  updateItems(category_code,updatedItems){

    let item_list = JSON.parse(localStorage.getItem(category_code));
    if(item_list && item_list[0]){
      let temp_item_list = [];
      item_list.forEach((item_info) => {
        updatedItems.forEach(updatedItem => {
          if(item_info.selected_variant_item && item_info.selected_variant_item.item_code == updatedItem.item_code){
            item_info.selected_variant_item = this.updateItemAfterVersionChange(item_info.selected_variant_item,updatedItem)
          } else if(item_info.item_code == updatedItem.item_code){
            item_info = this.updateItemAfterVersionChange(item_info,updatedItem)
          }
        })
        if(item_info.update_comment != "delete") {
          temp_item_list.push(item_info);
        }
      });

      updatedItems.forEach(updatedItem => {
        if(updatedItem.update_comment == "add") {
          temp_item_list.push(updatedItem);
        }
      });
      localStorage.setItem(category_code,JSON.stringify(temp_item_list));
    }
  }

  updateItemAfterVersionChange(currentItem,updatedItem) {
    if(updatedItem.unit_price) {
      updatedItem['unit_price']=parseFloat(updatedItem.unit_price);    

      updatedItem.tempData = {
        'temp_unit_price':parseFloat(updatedItem.unit_price),
        'temp_item_count':currentItem.item_count,
        'unit_price':parseFloat(updatedItem.unit_price)
      };
    }

    delete updatedItem.attributes;//it is changing current category and cart attribute;
    currentItem = Object.assign(currentItem,updatedItem);
    return currentItem;
  }

  onClickUpdateAvailable(){
    var billQueue: any = [];
    try {
        billQueue = JSON.parse(localStorage.getItem('bill_queue')) || [];
    } catch (e) {
        billQueue = [];
    }

    if(billQueue.length != 0) {
      this.messageService.add({ severity: 'error', summary: 'orders are pending', detail: billQueue.length +' orders are still in queue. Try again once all order completed.' });
      return;
    }

    this.subscriptions.push(this.modalService.onHide.subscribe(() => {

      if (this.modalRef.content.decision === 'yes') {

        let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
        let is_item_added = false;

        if(tempUnit && tempUnit.visitors) {
          for(var i=0;i<tempUnit.visitors.length;i++) {
            if(tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
              is_item_added = true;
              break;
            }
          }
        }

        if(is_item_added){
          /*tempUnit.visitors.forEach((seat, index) => {
            seat.isOnHold = true;
          });
          if(tempUnit.isOnHold) {
            //this.router.navigate(['/pages/register/place-selection/here']);
            //return;
          }else{
            tempUnit.isOnHold = true;
            tempUnit.isFromOnHold = true;
            this.stateGuard.amend('basket.component:offUnits', function (offUnits) {
              offUnits.push(tempUnit);
              localStorage.setItem('offUnits',JSON.stringify(offUnits));
            });
          }*/
          //this.basketComponent.clearCart(false);
          //this.router.navigate(['/pages/register/place-selection/here']);
        }

        this.isLoadingShow = true;

        let version = localStorage.getItem('current_version_no');
        let sendData = {
          'shop_code':localStorage.getItem('shop_code'),
          'version_number': (typeof version=='string')? parseInt(version) : version
        }
        this.commonService.getInventoryUpdate(sendData).subscribe((res) => {
            var apiRes = res.json();

            if (apiRes.status === 0) {
              this.isLoadingShow = false;
              if(apiRes.result.item_history && apiRes.result.item_history.length > 0){
                var allUpdatedItems = apiRes.result.item_history.map(updatedItem => updatedItem.item);
                let itemGroupByCategoryCode = groupBy( allUpdatedItems, function (item) { return item.master_item_category_code; });
                let allUpdatedItem = [];
                forOwn(itemGroupByCategoryCode,(itemsDetail,category_code) => {
                    this.updateItems(category_code,itemsDetail);
                    allUpdatedItem = allUpdatedItem.concat(itemsDetail);
                });

                this.updateOnHoldItems(allUpdatedItem);
                let totalUpdatedItem = groupBy( apiRes.result.item_history, function (item) { return item.item_code; });
                let count = Object.keys(totalUpdatedItem).length;

                this.getUpdateItemsInfoForSync(allUpdatedItems);
                let len = apiRes.result.item_history.length;
                let latestVersion = apiRes.result.item_history[len-1].inventory_version;
                localStorage.setItem('current_version_no',latestVersion);
                this.updateInventoryService.sendUpdateAvailableInfo(false,true);
                this.messageService.add({ severity: 'success', summary: 'Update Item', detail: count+' item successfully updated.' });
                //this.menuComponent.fetchCurrentCategory();
                //this.menuComponent.loadItemsForSearch();

                this.isLoadingShow = false;
              }
            } else {
                this.isLoadingShow = false;
                this.messageService.add({ severity: 'error', summary: 'Something Went Wrong', detail: '' });
            }
        },err =>{
          this.isLoadingShow = false;
          this.messageService.add({ severity: 'error', summary: 'Something Went Wrong', detail: '' });
        });
      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(ConfirmationModal, {class: 'common-modal'});
    this.modalRef.content.decision = "";
    this.modalRef.content.text = "Items in the orders parked or saved will be overwritten with updated information, Do you still want to update?";

  }

  goToPlaceSelectionPage(){
    this.router.navigate(['/pages/register/place-selection/here']);
  }

  getUpdateItemsInfoForSync(items){
    //this.isLoadingShow = true;
    let temp_item_list = [];
    var self = this;

    let updatedItemCount = items.length;
    if(Array.isArray(items)){

      for (var i = 0; i < items.length; ++i) {

        let category_code = items[i].master_item_category_code;
        let item_list = JSON.parse(localStorage.getItem(category_code));
        if(item_list && item_list[0]){

          let item_list_count = item_list.length;
          for (var j = 0; j < item_list.length; ++j) {
            if(item_list[j].selected_variant_item && item_list[j].selected_variant_item.item_code == items[i].item_code){
              item_list[j].selected_variant_item = this.updateItemAfterVersionChange(item_list[j].selected_variant_item,items[i])
              temp_item_list.push(item_list[j]);
            } else if(item_list[j].item_code == items[i].item_code){
              item_list[j] = this.updateItemAfterVersionChange(item_list[j],items[i]);
              temp_item_list.push(item_list[j]);
            }
            if(i == (updatedItemCount-1) && j == (item_list_count - 1)){
              items.forEach(updatedItem => {
                if(updatedItem.update_comment == "add") {
                  temp_item_list.push(updatedItem);
                }
              });
              setTimeout(()=>{
                 self.captainAppHelper.syncItems(temp_item_list);
              },500)
            }
          }
        }
      }
    }
  }

  logout(){
    var self = this;
    let loggedinUser = JSON.parse(localStorage.getItem('loggedInUser'));
    let id = JSON.parse(localStorage.getItem('device_key'));
    let terminal_id = (id && id.terminal_id)? id.terminal_id : 0;
    let sendData = {
      'user_name':(loggedinUser && loggedinUser.user_name)? loggedinUser.user_name : null,
      'shop_code':localStorage.getItem('shop_code'),
      'terminal_id': (typeof terminal_id == 'string')? parseInt(terminal_id) : terminal_id,
      'push_record_id':localStorage.getItem('push_record_id')
    }

    this.shopLoginService.logout(sendData).subscribe((res) => {
        var apiRes = res.json();
        if (apiRes.status === 0) {
          this.selectsBoxComponent.createTableSections().then( (flag) =>{
            localStorage.clear();
            setTimeout(() => {
              self.stateGuard.clear();
              window.location.reload();
            },1000);
            //localStorage.setItem('resetPlaces','true');
            this.router.navigate(['login']);
          });
        } else {
          console.log(apiRes.message);
          this.router.navigate(['login']);
        }
    });
  }

  fullScreenMode(){

    let docElm:any = document.documentElement;
    let doc:any = document;
    let isInFullScreen:any = (doc.fullscreenElement && doc.fullscreenElement !== null) ||
        (doc.webkitFullscreenElement && doc.webkitFullscreenElement !== null) ||
        (doc.mozFullScreenElement && doc.mozFullScreenElement !== null) ||
        (doc.msFullscreenElement && doc.msFullscreenElement !== null);

    if (!isInFullScreen) {
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        }
    } else {
        if (doc.exitFullscreen) {
            doc.exitFullscreen();
        } else if (doc.webkitExitFullscreen) {
            doc.webkitExitFullscreen();
        } else if (doc.mozCancelFullScreen) {
            doc.mozCancelFullScreen();
        } else if (doc.msExitFullscreen) {
            doc.msExitFullscreen();
        }
    }
  }
}
