import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { APIHelper } from '../helpers/api-helper';
import { MessageService } from 'primeng/api';
import { DiaryService } from '../helpers/services/diary.service';

@Component({
  selector: 'app-diary-detail-modal-report',
  templateUrl: './diary-detail-modal.component.html',
  styleUrls: ['./diary-detail-modal.component.less']
})
export class DiaryDetailModalComponent implements OnInit {  
  isApplyDisabled: boolean = false;
  filterData:any = [];      
  diaryData: any = [];
  from_date: any = new Date();
  to_date: any = new Date();
  customer_auth_token: any;
  shop_code: any = localStorage.getItem('shop_code');  
  total_diary_amount: any = 0;
  total_paid_amount: any = 0;
  total_used_amount: any = 0;
  filters_list: any = [ 
    {'in_prod_name':'Order ID'},
    {'in_prod_name':'Total'},
    {'in_prod_name':'Paid'},
    {'in_prod_name':'Diary used'},
    {'in_prod_name':'Diary (Balance)'},
  ];
  currencySymbol:any;    
  constructor(public modalRef: BsModalRef,public diaryService:DiaryService,public messageService:MessageService) { }

  ngOnInit() {   
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shop_config.currency;

    this.from_date = new Date();
    this.from_date.setHours(0);
    this.from_date.setMinutes(0);
    this.from_date.setSeconds(0);

    this.to_date = new Date();
    this.to_date.setHours(23);
    this.to_date.setMinutes(59);
    this.to_date.setSeconds(59);

    setTimeout(() => {    
      this.getDiaryDetail();
    },200);
  }

  closeModal() {   
    this.modalRef.hide();
  }

  onChangeAttrFilter(event) {
  	var tempReportD = this.diaryData.filter(rd => {
  	    var is_match = true;
  	    for(var i=0;i<event.length;i++) {
  		    var it = event[i];      
  		    if(it.attr == 'Order ID' && it.value && rd.order_id.toLowerCase().indexOf(it.value.toLowerCase()) == -1 || 
  	           it.attr == 'Total' && it.value && rd.total_amount > it.value || 
  	           it.attr == 'Paid' && it.value && rd.paid_amount > it.value || 
  	           it.attr == 'Diary used' && it.value && rd.diary_amount > it.value || 
  	           it.attr == 'Diary (Balance)' && it.value && rd.diary_balance > it.value 
  	           ) {
  	          is_match = false;
  	          break;
  	    	}		    
  		}

  		return is_match;
  	})
    
    this.filterData = JSON.parse(JSON.stringify(tempReportD));  

    this.total_diary_amount = 0;
    this.total_paid_amount = 0;
    this.total_used_amount = 0;
    this.filterData.forEach(r => {
      this.total_diary_amount += (r.total_amount) ? parseFloat(r.total_amount) : 0;
      this.total_paid_amount += (r.paid_amount) ? parseFloat(r.paid_amount) : 0;
      this.total_used_amount += (r.diary_amount) ? parseFloat(r.diary_amount) : 0;
    })    
  }

  getDiaryDetail() {    

      if(!this.from_date || !this.to_date || this.from_date > this.to_date) {
        this.messageService.add({ severity: 'error', summary: "error while get diary entry", detail: '' });          
        return;
      }

      this.from_date.setHours(0);
      this.from_date.setMinutes(0);
      this.from_date.setSeconds(0);

      this.to_date.setHours(23);
      this.to_date.setMinutes(59);
      this.to_date.setSeconds(59);

      this.isApplyDisabled = true;
      this.diaryData = [];
      this.filterData = [];
      this.total_diary_amount = 0;
      this.total_paid_amount = 0;
      this.total_used_amount = 0;
      this.diaryService.diaryDetail(
          this.customer_auth_token,
          this.from_date,
          this.to_date,
          this.shop_code
        ).subscribe((pres) => {
          var tempRes = pres.json();
          if (tempRes.status === 0) {
            tempRes.result.forEach(r=> {
              if(!r.order_id) {
                if(r.comment == "clear") {
                  r.order_id = "Diary Cleared";
                } else if(r.comment == "advance") {
                  r.order_id = "Advance Received";
                }
              }
              this.total_diary_amount += (r.total_amount) ? parseFloat(r.total_amount) : 0;
              this.total_paid_amount += (r.paid_amount) ? parseFloat(r.paid_amount) : 0;
              this.total_used_amount += (r.diary_amount) ? parseFloat(r.diary_amount) : 0;
            })

            tempRes.result = tempRes.result.sort((a,b) => {
              let aD:any = new Date(a.createdAt);
              let bD:any = new Date(b.createdAt);
              return (aD-bD);
            });
            this.diaryData = tempRes.result;
            this.filterData = JSON.parse(JSON.stringify(this.diaryData));            
          } 
          this.isApplyDisabled = false;            
        }, err =>{
          this.isApplyDisabled = false;            
          this.messageService.add({ severity: 'error', summary: "error while get diary entry", detail: '' });          
        });
  }
}
