import { Component, OnInit, ViewChild,EventEmitter,Input,Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Md5 } from 'ts-md5/dist/md5';
import { MessageService } from 'primeng/api';
import { Dish } from '../dish';
import { CustomerService } from '../helpers/services/customer.service';


@Component({
  selector: 'app-membership-option-modal',
  templateUrl: './membership-option-modal.component.html',
  styleUrls: ['./membership-option-modal.component.less'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        transform: 'translateY(-100%)'
      })),
      state('active', style({
        transform: 'translateY(0%)'
      })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ]),
    trigger('fadeOverlay', [
      state('inactive', style({
        opacity: '0'
      })),
      state('active', style({
        opacity: '1'
      })),
      transition('inactive => active', animate('150ms ease-in')),
      transition('active => inactive', animate('150ms ease-out'))
    ])
  ]
})
export class MembershipOptionModalComponent implements OnInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() doneEvent: EventEmitter<any> = new EventEmitter();
  @Output() addingDish: EventEmitter<Dish> = new EventEmitter();
  @Input('dish') dish: any;
  isLoadingShow:boolean = false;
  anim_state: string = 'inactive';  
  currencySymbol: any
  selected_fee_option =  'initial';
  is_secondary_user_info_added= false;
  shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));  
  constructor(public modalRef: BsModalRef,private messageService:MessageService,private customerService:CustomerService) { }
  
  ngOnInit() {
    this.currencySymbol = this.shopConfig.currency;

    var self = this;
    var tout = setTimeout(() => {
      clearTimeout(tout);
      self.anim_state = 'active';     
    }, 150);    
  }

  registerSecondaryUsers() {
    this.isLoadingShow = true;
    var totalUser = this.dish.secondary_user_phone_number.length;
    this.dish.secondary_user_phone_number.forEach(sui => {
      if(sui&& sui.phone && sui.phone.length == 10) {
        this.customerService.registerCustomer(sui.phone,"",sui.name,this.shopConfig.shop_code).subscribe((res) => {
            var result = res.json();
            if (result.status === 0) {sui.user_id = result.result.user_id}
            totalUser--;
            if(totalUser == 0) {
              this.isLoadingShow = false;
              this.doneEvent.emit();
            }
        });    
      } else {
        totalUser--;
        if(totalUser == 0) {
          this.isLoadingShow = false;
          this.doneEvent.emit();
        }
      }
    });
  }

  onClickSecondaryUserBtn() {
    this.is_secondary_user_info_added = true;
  }

  selectItemPrice(type) {
    this.selected_fee_option = type;
    if(type=='renew') {
      this.dish.unit_price = this.dish.renew_item_price;
      this.dish.tempData.temp_unit_price = this.dish.renew_item_price;
      this.dish.tempData.unit_price = this.dish.renew_item_price;
      this.dish.is_renew_plan_selected = true;
    } else {
      this.dish.unit_price = this.dish.initial_price;
      this.dish.tempData.temp_unit_price = this.dish.initial_price;
      this.dish.tempData.unit_price = this.dish.initial_price;
      this.dish.is_renew_plan_selected = false;
    }
  }
 
  done() {
    if(this.is_secondary_user_info_added) {
      this.registerSecondaryUsers()
    } else {
      this.doneEvent.emit()  
    }    
  }

  close() {
    setTimeout(() => {
      this.closeEvent.emit();
    }, 200);
    this.anim_state = 'inactive';
  }
}
