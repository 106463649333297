import { Component, OnInit, Output,EventEmitter,ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls:['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  public isRestaurant:boolean = false;
  
  constructor(private router:Router) { 
  }

  ngOnInit(){
    
    if(localStorage.getItem('isRestaurant') == 'true'){
      this.isRestaurant = true;
    }
  }

  print(){
  	//console.log('print');
  }

}