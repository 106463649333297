import { Directive, HostListener,AfterViewInit,ElementRef,DoCheck } from '@angular/core';
import { StateGuardService } from './state-guard.service';

@Directive({
  selector: '[screen-keys]'
})
export class ScreenKeysDirective implements AfterViewInit,DoCheck{

  constructor(private stateGuard: StateGuardService,private el:ElementRef) { }

  //@HostListener('focus', ['$event'])

  @HostListener('focus') onFocus() {
    this.stateGuard.entrust("screen-keys.directive:currentInput", this.el.nativeElement);
  }
  
  ngAfterViewInit(){
  	this.ngDoCheck();
  }

  ngDoCheck(){
	this.el.nativeElement.focus();
  }
  
  /*onFocus (event) {
    this.stateGuard.entrust("screen-keys.directive:currentInput", event.srcElement);
  }*/
}
