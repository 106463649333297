import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CashRegisterService } from '../helpers/services/cash-register.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-open-cash-register-modal',
  templateUrl: './open-cash-register-modal.component.html',
  styleUrls: ['./open-cash-register-modal.component.less']
})
export class OpenCashRegisterModalComponent implements OnInit {
  decision: string;
  amount: number;
  notes: any;
  loading: boolean = false;
  constructor(public cashRegisterService:CashRegisterService,public modalRef:BsModalRef,
              public messageService: MessageService,public router:Router) { }
  
  ngOnInit() {
   
  }

  keyPress(event: any) {
   const pattern = /[0-9]/;
   let inputChar = String.fromCharCode(event.charCode);
   if (event.keyCode != 8 && !pattern.test(inputChar)) {
     event.preventDefault();
   }
  }

  submitModal(){
    if(!this.amount){
      this.amount = 0;
    }


    let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};
    let shopInfo = JSON.parse(localStorage.getItem('shop_configuration')) || {};
    let device_info = JSON.parse(localStorage.getItem('device_key')) || {};
    let sData = {
      "denomination": "",
      "is_closing": false,
      "is_opening": true,
      "remark": this.notes,
      "shift": "",
      "total_cash": this.amount,
      "user_id": loggedInUser.id,
      "terminal_id":(device_info && device_info.terminal_id) ? device_info.terminal_id : null,            
      "shop_code": localStorage.getItem('shop_code')
    }
    this.loading = true;
    this.cashRegisterService.cashIn(sData).subscribe((res) => {
      this.loading = false;
      let resData = res.json();
      if(resData.status === 0) {
        localStorage.setItem('isCashRegister','true');
        let cash_register_info = {
          "start_cash_in":{
              "createdAt": new Date(),
              'shop_name': shopInfo.shop_info.name,
              'terminal_name': device_info.terminal_name,
              "cash_in_amount": this.amount ? this.amount :0
            },
          'type':'Cash',
          'cash_sale':0,
          'cash_advance':0,
          'cash_return':0,
          'paid_in':0,
          'paid_out':0
        };

        let cash_register = [];
        cash_register.push(cash_register_info);
        localStorage.setItem('cash_register',JSON.stringify(cash_register));
        let setData = {
          "email": loggedInUser.email,
          "id": loggedInUser.id,
          "notes": this.notes,
          "reason": 'Floating amount',
          "time": new Date(),
          "transaction": this.amount,
          "user": loggedInUser.name
        }
        let paid_in_out_list= [];
        paid_in_out_list.push(setData);
        localStorage.setItem('paid_in_out_list',JSON.stringify(paid_in_out_list));
        let open_register_update_date = localStorage.getItem('open_register_update_date');
        if(open_register_update_date){
          let lastUpdateDate = new Date(open_register_update_date);
          let date = new Date()
          var dd   = date.getDate()+'';
          var mm   = (date.getMonth()+1)+'';
          var yyyy = date.getFullYear();
          if(dd.length == 1)  { dd='0'+dd } 
          if(mm.length == 1)  { mm='0'+mm }
          let str = yyyy+'/'+mm+'/'+dd;
          let curr_date = new Date(str);

          if(lastUpdateDate < curr_date){
            localStorage.setItem('all_cash_register_sessions','[]');
            localStorage.setItem('not_completed_orders','[]');
          }
        }
        var currDate = new Date();
        localStorage.setItem('open_register_update_date',currDate.toISOString());
        this.decision='submit';
        this.modalRef.hide()
      } else {
        this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: resData.message ? resData.message : "" });
      }
    }, err => {
        this.loading = false;
        this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: '' });
        //this.modalRef.hide()
    });

    /*this.decision='submit';
    this.modalRef.hide()*/
  }

}
