import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
 
@Injectable()
export class UpdateInventoryMessage {
    private subject = new Subject<any>();
 
    sendUpdateAvailableInfo(message: boolean,is_item_reload:boolean) {
        this.subject.next({'UpdateInfo':message,is_item_reload:is_item_reload});
    }

    clearUpdateAvailableInfo() {
        this.subject.next();
    }

    getUpdateAvailableInfo(): Observable<any> {
        return this.subject.asObservable();
    }

}