import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ThirdPartyOrderService } from '../../helpers/services/third-party-orders.service';
import { ThirdPartyDeliveryOrTakeawayStatusModalComponent } from '../../third-party-delivery-or-takeaway-status-modal/third-party-delivery-or-takeaway-status-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-online-partner-settings',
	templateUrl: './online-partner-settings.component.html',
	styleUrls: ['./online-partner-settings.component.less']
})
export class OnlinePartnerSettingsComponent implements OnInit {

	isLoadingShow:boolean = false;
	shop_code: any = localStorage.getItem("shop_code") || "";
	public modalRef: BsModalRef;
  	public subscriptions: Subscription[] = [];

	constructor(private modalService: BsModalService,
				private thirdPartyOrderService: ThirdPartyOrderService,
				private messageService:MessageService) { }

	ngOnInit() {
	}

  	onClickEditDTStatus(type) {
  		this.isLoadingShow = true;
  		this.getStatus(type).then((status:any) => {
  			this.isLoadingShow = false;
	  		this.modalRef = this.modalService.show(ThirdPartyDeliveryOrTakeawayStatusModalComponent, {class: 'centred-modal'});
	        this.modalRef.content.decision = "";
	        this.modalRef.content.status= status;
	        this.modalRef.content.title = type == "D" ? "Delivery Status" : "Takeaway Status";
	        let cSub: Subscription = this.modalService.onHide.subscribe(() => {
	        	cSub.unsubscribe();
	            if (this.modalRef.content.decision === 'done') {
	            	this.updateStatus(type,this.modalRef.content.status,this.modalRef.content.reason);	            	
	           	}          
	        });
        })
  	}

  	getStatus(type) {
  		return new Promise((resolve,reject) => {
	  		if(type == "D") {
	  			this.thirdPartyOrderService.getZomatoDeliveryStatus({shop_code:this.shop_code}).subscribe((res:any) => {
            		let result = res.json();      	  				
	  				if(result.status == 0 && result.result && result.result.outlet_delivery_status) {
	  					return resolve(true);		
	  				} else {
	  					return resolve(false);
	  				}	  				
	  			},(error) => {
	  				console.log(error);
	  				this.messageService.add({ severity: 'error', summary: 'Get error while fetching delivery status', detail: '' });
	  				return resolve(false);
	  			});
	  		} else if(type == "T") {
	  			this.thirdPartyOrderService.getZomatoTakeawayStatus({shop_code:this.shop_code}).subscribe((res:any) => {
	  				let result = res.json();      	  				
	  				if(result.status == 0 && result.result && result.result.outlet_takeaway_status) {
	  					return resolve(true);		
	  				} else {
	  					return resolve(false);
	  				}
	  			},(error) => {
	  				console.log(error);
	  				this.messageService.add({ severity: 'error', summary: 'Get error while fetching takeaway status', detail: '' });
	  				return resolve(false);
	  			});
	  		} else {
	  			return  resolve(false);
	  		}
  		})
  	}

  	updateStatus(type,status,reason) {
  		return new Promise((resolve,reject) => {
  			this.isLoadingShow = true;
	  		if(type == "D") {
	  			let params = {
	  				shop_code:this.shop_code,
	  				delivery_status: status ? 1 : 0,
	  				delivery_status_update_reason: reason
	  			}
	  			this.thirdPartyOrderService.updateZomatoDeliveryStatus(params).subscribe((res:any) => {
	  				this.isLoadingShow = false;
	  				this.messageService.add({ severity: 'success', summary: 'Updated delivery status', detail: '' });
	  				return  resolve(true);
	  			},(error) => {
	  				console.log(error);
	  				this.messageService.add({ severity: 'error', summary: 'Get error while update delivery status', detail: '' });
	  				this.isLoadingShow = false;
	  				return resolve(false);
	  			});
	  		} else if(type == "T") {
	  			let params = {
	  				shop_code:this.shop_code,
	  				takeaway_status: status ? 1 : 0,
	  				status_update_reason: reason
	  			}
	  			this.thirdPartyOrderService.updateZomatoTakeawayStatus(params).subscribe((res:any) => {
	  				this.isLoadingShow = false;
	  				this.messageService.add({ severity: 'success', summary: 'Updated takeaway status', detail: '' });
	  				return  resolve(true);
	  			},(error) => {
	  				console.log(error);
	  				this.messageService.add({ severity: 'error', summary: 'Get error while update takeaway status', detail: '' });
	  				this.isLoadingShow = false;
	  				return resolve(false);
	  			});
	  		} else {
	  			this.isLoadingShow = false;
	  			return  resolve(false);
	  		}
  		});
  	}
}

