
import {map} from 'rxjs/operators';

import { BaseConfig } from './api-config';
import { Http, Headers, RequestOptions, Response } from '@angular/http';



import { Observable } from 'rxjs';

export class APIHelper {

    // API header parameters
    public static DEFAULT_TOKEN = '00000000000000000000000000000000';
    public static API_KEY = '12345';
    public static RAZORPAY_KEY = 'rzp_live_ABhEtApdyrH51T';
    public static GKEY = 'AIzaSyAcdV9eoaXfrB4bd_HJ3nHINUvspxL5sgQ';
    public static UP_API_KEY = '06fc5fa75eb57a40e4075bffec4ffab7';

    // merchant registration
    public static API_REGISTER = { 'url': 'merchant/v2_register', 'method': 'POST' };
    public static API_RESEND = { 'url': 'merchant/resend', 'method': 'POST' };
    public static API_VERIFY = { 'url': 'merchant/v2_verify', 'method': 'POST' };
    public static API_LOGOUT = { 'url': 'v2/shop/logout_shop_user', 'method': 'POST' };
    public static API_MERCHANT_PROFILE = { 'url': 'merchant/profile', 'method': 'GET' };
    public static API_MERCHANT_SHOP_PROFILE = { 'url': 'shop/settings', 'method': 'GET' };
    public static API_MERCHANT_UPDATE_PROFILE = { 'url': 'merchant/profile', 'method': 'PUT' };
    public static API_MERCHANT_UPDATE_SHOP_PROFILE = { 'url': 'shop/settings', 'method': 'PUT' };
    public static API_MERCHANT_UPDATE_DEVICE_KEY = { 'url': 'merchant/update_device_key', 'method': 'PUT' };
    public static API_MERCHANT_PAYMENT_TYPES = { 'url': 'merchant/payment_types', 'method': 'GET' };
    public static API_GET_UNBUNDLE_ITEMS = { 'url': 'merchant/getAllUnbundleItems', 'method': 'POST' };

    //for sync with captain app
    public static SYNC_SHOP_SETTING = { 'url': 'merchant/captain/setting/update', 'method': 'POST' };
    public static SYNC_ITEMS_CATEGORIES = { 'url': 'merchant/captain/item_category/update', 'method': 'POST' };
    public static SYNC_ITEMS_INFO = { 'url': 'merchant/captain/items/update', 'method': 'POST' };
    public static SYNC_TABLES_INFO = { 'url': 'merchant/captain/tables/sync', 'method': 'POST' };
    public static GET_SYNC_TABLES_INFO = { 'url': 'merchant/captain/tables/get_all_shop_tables', 'method': 'GET' };
    public static GET_PARTICULAR_SYNC_TABLE_INFO = { 'url': 'merchant/captain/tables/get_shop_table', 'method': 'POST' };
    public static SYNC_PARTICULAR_TABLE_INFO = { 'url': 'merchant/captain/tables/update', 'method': 'POST' };
    public static SAVE_CAPTAIN_APP_ORDER = { 'url': 'merchant/captain/orders/save_order', 'method': 'POST' };

    // consumer list, consumer registration
    public static API_CONSUMER_LIST = { 'url': 'merchant/consumer_list', 'method': 'GET' };
    public static API_CONSUMER_INFO = { 'url': 'consumer/info', 'method': 'POST' };
    public static API_BASIC_CONSUMER_INFO = { 'url': 'consumer/basic_info', 'method': 'POST' };
    public static API_CONSUMER_INFO_BY_PHONE = { 'url': 'consumer/infoByPhone', 'method': 'POST' };
    public static API_MERCHANT_TYPES = { 'url': 'merchant/types', 'method': 'GET' };
    public static API_REGISTER_CUSTOMER = { 'url': 'merchant/registerCustomer', 'method': 'POST' };
    public static API_UPDATE_CUSTOMER = { 'url': 'user/profile', 'method': 'PUT' };
    public static API_ADD_ADDRESS = { 'url': 'consumer/add_address', 'method': 'POST' };


    // discount
    public static API_GET_DISCOUNT_LIST = { 'url': 'discount/list', 'method': 'POST' };
    public static VALIDATE_DISCOUNT = { 'url': 'discount/apply', 'method': 'POST' };
    public static GET_VERIFIED_DISCOUNT = { 'url': 'discount/get_verified_manual_discount', 'method': 'POST' };


    //renew licence
    public static API_FOR_RENEW_LICENCE = { 'url': 'merchant/renewLicence', 'method': 'POST' };

    //cloud messanging
    public static API_FOR_SEND_TOKEN_TO_SERVER = { 'url': 'register/device', 'method': 'POST' };

    //membership
    public static API_GET_MEMBERSHIP = { 'url': 'merchant/membership', 'method': 'POST' };
    public static API_ENROLL_USER_FOR_MEMBERSHIP = { 'url': 'merchant/membership/user/enroll', 'method': 'POST' };
    public static API_RENEW_USER_MEMBERSHIP = { 'url': 'merchant/membership/user/renew', 'method': 'POST' };
    public static API_GET_USER_MEMBERSHIP_DETAIL = { 'url': 'merchant/membership/user/membership_detail', 'method': 'POST' };
    public static API_MAKE_MEMBERSHIP_PAYMENT = { 'url': 'merchant/membership/user/payment', 'method': 'POST' };
    public static API_GET_MEMBERSHIP_PAYMENT_DETAIL = { 'url': 'merchant/membership/user/payment_details', 'method': 'GET' };



    // shop users
    public static API_SHOP_USER_LIST = { 'url': 'shop/get_shop_users', 'method': 'GET' };
    public static API_SHOP_USER_LOGIN = { 'url': 'shop/login_shop_user', 'method': 'POST' };
    public static API_SHOP_USER_LOGOUT = { 'url': 'shop/logout_shop_user', 'method': 'POST' };

    // search item, contact, categories, attributes
    public static API_ITEM_SEARCH = { 'url': 'merchant/item_search', 'method': 'POST' };
    public static API_ITEM_LIST = { 'url': 'merchant/itemList', 'method': 'POST' };
    public static API_CONTACT_SEARCH = { 'url': 'merchant/contact_search', 'method': 'POST' };
    public static API_MERCHANT_CATEGORIES = { 'url': 'merchant/shop_categories', 'method': 'POST' };
    public static API_ITEM_ATTRIBUTES = { 'url': 'product/attributeList', 'method': 'POST' };

    // add, edit, update category, item
    public static API_GET_CATEGORY = { 'url': 'category/get', 'method': 'POST' };
    public static API_ADD_CATEGORY = { 'url': 'category/add', 'method': 'POST' };
    public static API_UPDATE_CATEGORY = { 'url': 'category/update', 'method': 'PUT' };
    public static API_GET_ITEM = { 'url': 'product/get', 'method': 'POST' };
    public static API_ADD_ITEM = { 'url': 'product/add', 'method': 'POST' };
    public static API_UPDATE_ITEM = { 'url': 'product/update', 'method': 'PUT' };
    public static API_UNBUNDLE_UPDATE_ITEM = { 'url': 'merchant/unBundleItem', 'method': 'POST' };


    // make payment, save hold orders
    public static API_MAKE_PAYMENT = { 'url': 'merchant/make_payment', 'method': 'POST' };
    public static API_SAVE_ORDER = { 'url': 'merchant/save_order', 'method': 'POST' };
    public static API_HOLD_ORDER = { 'url': 'merchant/hold_orders', 'method': 'POST' };
    public static API_ORDER_LIST = { 'url': 'merchant/orders_list', 'method': 'GET' };
    public static API_ORDER_ITEM_LIST = { 'url': 'merchant/list_order_items', 'method': 'POST' };
    public static API_HOLD_ORDER_LIST = { 'url': 'merchant/hold_orders', 'method': 'GET' };
    public static API_SAVE_TO_DIARY = { 'url': 'merchant/save_to_diary', 'method': 'POST' };
    public static API_GET_DIARY_DETAIL = { 'url': 'merchant/diary', 'method': 'POST' };
    public static API_CLEAR_DIARY = { 'url': 'merchant/clear_diary', 'method': 'POST' };
    public static API_ACCEPT_ORDER = { 'url': 'merchant/accept_order', 'method': 'POST' };
    public static API_REJECT_ORDER = { 'url': 'merchant/reject_order', 'method': 'POST' };
    public static API_COMPLETE_ORDER = { 'url': 'merchant/complete_order', 'method': 'POST' };
    public static API_CANCEL_ORDER = { 'url': 'merchant/cancel_order', 'method': 'POST' };
    public static API_GET_THIRD_PARTY_NEW_ORDER_COUNT = { 'url': 'merchant/thirdparty/get_third_party_new_order_count', 'method': 'GET' };

    //po orders
    
    public static API_GET_SUPPLIER_LIST = { 'url': 'merchant/supplier_list', 'method': 'GET' };

    // cash register
    public static API_CASH_OUT = { 'url': 'merchant/cash_register/out', 'method': 'POST' };
    public static API_CASH_IN = { 'url': 'merchant/cash_register/in', 'method': 'POST' };
    public static API_CASH_REGISTER_GENERATE_REPORT = { 'url': 'merchant/cash_register/generate_report', 'method': 'POST' };
    public static API_CASH_REGISTER_SAVE_REPORT = { 'url': 'merchant/cash_register/save_report_summary', 'method': 'POST' };
    public static API_CASH_REGISTER_REPORT_LIST = { 'url': 'merchant/cash_register/report_list', 'method': 'POST' };
    public static API_CASH_REGISTER_LIST = { 'url': 'merchant/cash_register/list', 'method': 'POST' };

    //printer setting
    public static API_ADD_PRINTER = { 'url': 'merchant/create_printer', 'method': 'POST' };
    public static API_GET_PRINTER = { 'url': 'merchant/all_printer_list', 'method': 'POST' };
    public static API_GET_PRINTER_SECTIONLIST = { 'url': 'merchant/printer_section', 'method': 'POST' };
    public static API_UPDATE_PRINTER = { 'url': 'merchant/update_printer', 'method': 'POST' };
    public static API_DELETE_PRINTER = { 'url': 'merchant/delete_printer', 'method': 'POST' };

    //supplier order
    public static API_SUPPLIER_CREATE_PO = { 'url': 'merchant/supplier_create_po', 'method': 'POST' };   
    public static API_SUPPLIER_PO_STATUS = { 'url': 'merchant/po/status', 'method': 'POST' };   

    // some basic reports
    public static API_CONSUMER_REPORT = { 'url': 'merchant/consumer_reports', 'method': 'POST' };
    public static API_REPORT = { 'url': 'merchant/report_helper', 'method': 'POST' };
    public static API_ITEM_REPORT = { 'url': 'merchant/item_report', 'method': 'POST' };
    public static API_ORDER_REPORT = { 'url': 'merchant/order_reports', 'method': 'POST' };

    //report server url
    public static API_SALES_SUMMARY_REPORT = { 'url': 'merchant/reports/summary_report/sales_summary_report', 'method': 'POST' };

    //online partner settings
    public static API_GET_ZOMATO_TAKEAWAY_STATUS = { 'url': 'merchant/thirdparty/get_takeaway_status/zomato', 'method': 'POST' };
    public static API_GET_ZOMATO_DELIVERY_STATUS = { 'url': 'merchant/thirdparty/get_delivery_status/zomato', 'method': 'POST' };
    public static API_UPDATE_ZOMATO_TAKEAWAY_STATUS = { 'url': 'merchant/thirdparty/update_takeaway_status/zomato', 'method': 'POST' };
    public static API_UPDATE_ZOMATO_DELIVERY_STATUS = { 'url': 'merchant/thirdparty/update_delivery_status/zomato', 'method': 'POST' };

    //recent orders info
    public static API_ALL_ORDER_INFO = { 'url': 'merchant/all_orders_list', 'method': 'POST' };
    public static API_SELECTED_ORDER_INFO = { 'url': 'merchant/get_order_detail', 'method': 'POST' };
    public static API_FOR_CANCEL_SELECTED_ORDER = { 'url': 'merchant/cancel_order', 'method': 'POST' };
    public static API_FOR_CHANGE_SELECTED_ORDER = { 'url': 'merchant/change_order', 'method': 'POST' };
    public static API_FOR_COMPLETE_SELECTED_ORDER = { 'url': 'merchant/complete_order', 'method': 'POST' };
    public static API_FOR_DELIVER_SELECTED_ORDER = { 'url': 'merchant/deliver_order', 'method': 'POST' };
    public static API_FOR_DISPTACH_SELECTED_ORDER = { 'url': 'merchant/order_dispatched', 'method': 'POST' };
    public static API_FOR_SELF_SERVICE_DELIVER_SELECTED_ORDER = { 'url': 'merchant/self_service_order_delivered', 'method': 'POST' };
    public static API_FOR_ORDER_READY_ALERT = { 'url': 'merchant/order_ready', 'method': 'POST' };
    public static API_FOR_THIRD_PARTY_ORDER_READY = { 'url': 'merchant/third_party_order_ready', 'method': 'POST' };
    public static API_FOR_ACCEPT_SELECTED_ORDER = { 'url': 'merchant/accept_order', 'method': 'POST' };
    public static API_FOR_VOID_ORDER = { 'url': 'merchant/void_order', 'method': 'POST' };
    public static API_FOR_REJECT_SELECTED_ORDER = { 'url': 'merchant/reject_order', 'method': 'POST' };
    public static API_FOR_PENDING_AMOUNT = { 'url': 'merchant/save_to_diary', 'method': 'POST' };
    public static API_FOR_PAID_AMOUNT = { 'url': 'merchant/deliver_order', 'method': 'POST' };
    public static API_FOR_RIDER_PICKEDUP_ORDER = { 'url': 'merchant/order_pickedup', 'method': 'POST' };
    public static API_FOR_RIDER_ASSIGNED_TO_ORDER = { 'url': 'merchant/order_rider_assigned', 'method': 'POST' };
    public static API_FOR_RIDER_TEMPERATURE_DETAIL = { 'url': 'merchant/rider_temperature_detail', 'method': 'POST' };    
    public static API_FOR_CONTACT_SEARCH = { 'url': 'merchant/contact_search', 'method': 'POST' };
    public static API_FOR_PARTNER_LIST = { 'url': 'shop/partner', 'method': 'GET' };
    public static API_CHANGE_ORDER_VEHICLE_DETAIL = { 'url': 'merchant/change_order_vehicle_detail', 'method': 'POST' };

    // shop taxes
    public static API_GET_TAX_CONFIG = { 'url': 'merchant/get_standard_tax_rates', 'method': 'POST' };

    // some trends
    public static API_CUSTOMER_PAST_TREND = { 'url': 'merchant/intelligence/previous_purchase_trends', 'method': 'POST' };

    // price list and update
    public static API_GET_PRICE_LIST = { 'url': 'merchant/get_price_list', 'method': 'POST' };

    // item stock list
    public static API_GET_ITEM_STOCK_LIST = { 'url': 'merchant/getItemStock', 'method': 'POST' };
    public static API_SAVE_STOCK_REPORT = { 'url': 'merchant/save_stock_report', 'method': 'POST' };

    // inventory version update
    public static API_INVENTORY_VERSION = { 'url': 'product/inventory_version', 'method': 'POST' };
    public static API_CURRENT_VERSION = { 'url': 'backoffice/merchant/product/inventory_version', 'method': 'POST' };
    public static API_GET_INVENTORY_UPDATE = { 'url': 'backoffice/merchant/product/get_inventory_update', 'method': 'POST' };

    // employeer api
    public static API_GET_ALL_EMPLOYERS = { 'url': 'merchant/get_all_employers', 'method': 'POST' };
    public static API_UPDATE_EMPLOYER = { 'url': 'merchant/update_employer', 'method': 'POST' };
    public static API_ADD_EMPLOYER = { 'url': 'merchant/add_employer', 'method': 'POST' };


    //Delivery Partner API
    public static API_GET_ACTIVE_DELIVERY_PARTNER_LIST = { 'url': 'shop/get_active_delivery_partner', 'method': 'GET' };
    public static API_GET_DUNZO_ESTIMATE = { 'url': 'merchant/delivery_partners/dunzo/get_estimate', 'method': 'POST' };
    public static API_CREATE_DUNZO_TASK = { 'url': 'merchant/delivery_partners/dunzo/create_task', 'method': 'POST' }

    //GST state codes
    public static API_GET_GST_STATE_CODES = { 'url': 'merchant/get_gst_state_code', 'method': 'GET' };


    // images
    public static API_ITEM_IMAGE_UPDATE = { 'url': 'merchant/update_item_image', 'method': 'POST' };

    // other API related settings
    public static API_TOKEN_VALIDITY = 30;  // number of days for which the token is valid

    // Licence API
    public static API_LICENCE_VALIDITY = { 'url': 'merchant/licence', 'method': 'GET' };

    //recommendation
    // public static API_GET_SHOP_MODEL = { 'url': 'merchant/model', 'method': 'GET' };
    // public static API_GET_SHOP_ITEMS_AND_MIN_MAX_QTY = { 'url': 'merchant/shop_data', 'method': 'GET' };

    // urban piper API
    public static UP_ORDER_STATUS_UPDATE = { 'url': 'api/v2/merchant/thirdparty/callback/order/client/status/update', 'method': 'POST' };

    // verify mobile number
    public static API_FOR_VERFIY_MOBILE = { 'url': 'merchant/verify_mobile', 'method': 'POST' };

    // other
    public static API_GET_USER = { 'url': 'backoffice/merchant', 'method': 'GET' };

    // some global constants
    // payment and delivery types
    // delivery (shipping) type
    public static HOME_DELIVERY = 'ST0002';
    public static PICK_UP = 'ST0001';
    public static IMMEDIATE = 'ST0003';

    // payment type
    public static SAVE_TO_DIARY_TYPE = 'PAYT0001';
    public static CASH_ON_DELIVERY_TYPE = 'PAYT0002';
    public static CASH_IN_HAND_TYPE = 'PAYT0003';
    public static CARD_PAYMENT_TYPE = 'PAYT0004';
    public static COUPON_PAYMENT_TYPE = 'PAYT0005';
    public static REWARD_PAYMENT_TYPE = 'PAYT0006';
    public static ONLINE_PAYMENT_TYPE = 'PAYT0008';
    public static VOID_PAYMENT_TYPE = 'PAYT0000';


    // order status
    public static ORDER_ON_HOLD = 'ORDS0001';
    public static ORDER_PENDING = 'ORDS0005';
    public static ORDER_NEW = 'ORDS0007';
    public static ORDER_COMPLETED = 'ORDS0008';
    public static ORDER_APPROVED = 'ORDS0002';
    public static ORDER_DELIVERED = 'ORDS0004';
    public static ORDER_REJECTED = 'ORDS0003';
    public static ORDER_CANCELED = 'ORDS0003';
    public static ORDER_EDITED = 'ORDS0009';
    public static ORDER_DRAFT = 'ORDS0010';
    public static ORDER_PICKEDUP = 'ORDS0011';
    public static ORDER_ASSIGNED_TO_RIDER = 'ORDS0012';
    public static ORDER_READY = 'ORDS0013';
    public static ORDER_DISPATCHED = 'ORDS0014';
    public static ORDER_VOID = 'ORDS0015';


    //self service order status
    public static SELF_SERVICE_ORDER_DELIVERED = "SSORDS0004";
    public static SELF_SERVICE_ORDER_PENDING = "SSORDS0005";

    //kot damaged reason
    public static KOT_DAMAGED = 'KOT0001';
    public static KOT_CUSTOMER_CANCELLED = 'KOT0002';
    public static KOT_DELIVERY_DELAYED = 'KOT0003';
    public static KOT_OTHER = 'KOT0004';


    // ai constants
    public static AI_CATEGORY_CODE = 'MIT0000';
    public static AI_CATEGORY_NAME = 'Favorites';

    // other constants
    public static MOBILE_NUMBER_W = 10;
    public static GUEST_MOBILE = '0000000000';

    // error codes
    public static E_NO_ORDERS_FOUND = -181;

    // follow a singleton pattern - with a twist
    private static _instance: APIHelper = null;
    private auth_token: string;
    private session_id: string;
    private user_name: string;
    private http: Http;
    private device_key: string;

    // singleton helper
    public static getInstance(http: Http): APIHelper {
        if (APIHelper._instance === null) {
            APIHelper._instance = new APIHelper();
            APIHelper._instance.auth_token = localStorage.getItem('auth_token');
            let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};
            if(loggedInUser.session_id){
                APIHelper._instance.session_id = loggedInUser.session_id;
            }
            if(loggedInUser.name){
                APIHelper._instance.user_name = loggedInUser.user_name;
            }
        }
        APIHelper._instance.http = http;
        return APIHelper._instance;
    }

    setAuthTokenForUpdateUser(authKey: string) {
        this.auth_token = authKey;
    }

    // set auth token
    setAuthToken(authKey: string) {
        this.auth_token = authKey;
        localStorage.setItem('auth_token', this.auth_token);
    }

    // set user name for session login
    setUserName(userName: string) {
        this.user_name = userName;
        localStorage.setItem('user_name', this.user_name);
    }

    // session id for session login
    setSessionID(sessionID: string) {
        this.session_id = sessionID;
        localStorage.setItem('session_id', this.session_id);
    }

    // mark that the licence version process is performed
    setLicenceVerified() {
        localStorage.setItem('license_verified', 'true');
        localStorage.setItem('verification_date', (new Date()).toISOString());
    }

    // resent the licence verification process
    resetLicenceVerified() {
        localStorage.setItem('license_verified', 'false');
        localStorage.setItem('verification_date', '-invalid-');
    }

    // is the licence verification done ?
    isLicenceVerified(): boolean {
        //console.log(localStorage.getItem('shop_code'));
        var licVerified = localStorage.getItem('license_verified');
        var licVerifyDate = new Date(localStorage.getItem('verification_date'));

        if (licVerified !== 'true') return false;

        // var timeDiff = Math.abs(licVerifyDate.getTime() - (new Date()).getTime());
        // timeDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        // if (timeDiff > APIHelper.API_TOKEN_VALIDITY) {
        //     this.resetLicenceVerified(); // mark the licence to be unverified
        //     return false;
        // }

        if (localStorage.getItem('shop_code') === null || localStorage.getItem('shop_code') === '') return false;
        return true;
    }

    //  generic API call
    apiCall(apiKey: any, params: any): Observable<Response> {

        var headersVar = new Headers();

        /*var auth_token = (localStorage.getItem('auth_token')) ? localStorage.getItem('auth_token') : this.auth_token;
        APIHelper._instance.auth_token = auth_token;*/

        var session_id = this.session_id;
        var user_name = this.user_name;
        let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};

        if(loggedInUser.session_id) {
            session_id = loggedInUser.session_id;
            APIHelper._instance.session_id = session_id;
        }

        if(loggedInUser.name){
            user_name = loggedInUser.user_name;
            APIHelper._instance.user_name = loggedInUser.user_name;
        }

        headersVar.append('api_key', APIHelper.API_KEY);
        headersVar.append('auth_token', this.auth_token);
        headersVar.append('session_id', session_id);
        headersVar.append('user_name', user_name);
        headersVar.append('content-type', 'application/json');        
        if(localStorage.getItem('device_key') && JSON.parse(localStorage.getItem('device_key')).device_key){
            this.device_key = JSON.parse(localStorage.getItem('device_key')).device_key;
        }
        headersVar.append('device_key', this.device_key);

        let options = new RequestOptions({ headers: headersVar });
        if (apiKey['method'] === 'POST') {
            return this.http.post(BaseConfig.config.API + apiKey['url'], JSON.stringify(params), options).pipe(map((resData) => resData));
        } else if (apiKey['method'] === 'PUT') {
            APIHelper._instance.auth_token = localStorage.getItem('auth_token');
            return this.http.put(BaseConfig.config.API + apiKey['url'], JSON.stringify(params), options).pipe(map((resData) => resData));
        } else {
            return this.http.get(BaseConfig.config.API + apiKey['url'] + '?' + params, options).pipe(map((resData) => resData));
        }
    }

    //  report server api call
    reportApiCall(apiKey: any, params: any): Observable<Response> {

        var headersVar = new Headers();

        /*var auth_token = (localStorage.getItem('auth_token')) ? localStorage.getItem('auth_token') : this.auth_token;
        APIHelper._instance.auth_token = auth_token;*/

        var session_id = this.session_id;
        var user_name = localStorage.getItem('user');
        /*let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};

        if(loggedInUser.session_id) {
            session_id = loggedInUser.session_id;
            APIHelper._instance.session_id = session_id;
        }

        if(loggedInUser.name){
            user_name = loggedInUser.user_name;
            APIHelper._instance.user_name = loggedInUser.user_name;
        }*/

        headersVar.append('api_key', APIHelper.API_KEY);
        headersVar.append('auth_token', this.auth_token);
        headersVar.append('session_id', session_id);
        headersVar.append('user_name', user_name);
        headersVar.append('content-type', 'application/json');
        if(localStorage.getItem('device_key') && JSON.parse(localStorage.getItem('device_key')).device_key){
            this.device_key = JSON.parse(localStorage.getItem('device_key')).device_key;
        }
        headersVar.append('device_key', this.device_key);

        let options = new RequestOptions({ headers: headersVar });
        if (apiKey['method'] === 'POST') {
            return this.http.post(BaseConfig.config.REPORT_API + apiKey['url'], JSON.stringify(params), options).pipe(map((resData) => resData));
        } else if (apiKey['method'] === 'PUT') {
            APIHelper._instance.auth_token = localStorage.getItem('auth_token');
            return this.http.put(BaseConfig.config.REPORT_API + apiKey['url'], JSON.stringify(params), options).pipe(map((resData) => resData));
        } else {
            return this.http.get(BaseConfig.config.REPORT_API + apiKey['url'] + '?' + params, options).pipe(map((resData) => resData));
        }
    }


    apiCallForSyncWithCaptainApp(apiKey: any, params: any): Observable<Response> {
        var headersVar = new Headers();
        headersVar.append('API-KEY', '12345');
        headersVar.append('content-type', 'application/json');
        let options = new RequestOptions({ headers: headersVar });
        let captain_server_url = localStorage.getItem('captain_server_url');
        if (apiKey['method'] === 'POST') {
            return this.http.post(captain_server_url + apiKey['url'], JSON.stringify(params), options).pipe(map((resData) => resData));
        } else if (apiKey['method'] === 'PUT') {
            APIHelper._instance.auth_token = localStorage.getItem('auth_token');
            return this.http.put(captain_server_url + apiKey['url'], JSON.stringify(params), options).pipe(map((resData) => resData));
        } else {
            return this.http.get(captain_server_url + apiKey['url'], options).pipe(map((resData) => resData));
        }
    }

    //  urban Piper API call
    urbanPiperApiCall(apiKey: any, params: any): Observable<Response> {

        var headersVar = new Headers();

        var session_id = this.session_id;
        var user_name = this.user_name;
        let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};

        if(loggedInUser.session_id) {
            session_id = loggedInUser.session_id;
            APIHelper._instance.session_id = session_id;
        }

        if(loggedInUser.name){
            user_name = loggedInUser.user_name;
            APIHelper._instance.user_name = loggedInUser.user_name;
        }

        headersVar.append('api_key', APIHelper.API_KEY);
        headersVar.append('auth_token', this.auth_token);
        headersVar.append('session_id', session_id);
        headersVar.append('user_name', user_name);
        headersVar.append('x_api_token', APIHelper.UP_API_KEY);
        headersVar.append('content-type', 'application/json');
        
        if(localStorage.getItem('device_key') && JSON.parse(localStorage.getItem('device_key')).device_key){
            this.device_key = JSON.parse(localStorage.getItem('device_key')).device_key;
        }
        headersVar.append('device_key', this.device_key);

        let options = new RequestOptions({ headers: headersVar });
        if (apiKey['method'] === 'POST') {
            return this.http.post(BaseConfig.config.up_url + apiKey['url'], JSON.stringify(params), options).pipe(map((resData) => resData));
        } else if (apiKey['method'] === 'PUT') {
            APIHelper._instance.auth_token = localStorage.getItem('auth_token');
            return this.http.put(BaseConfig.config.up_url + apiKey['url'], JSON.stringify(params), options).pipe(map((resData) => resData));
        } else {
            return this.http.get(BaseConfig.config.up_url + apiKey['url'] + '?' + params, options).pipe(map((resData) => resData));
        }
    }

    // the constructor
    private constructor() {
        this.auth_token = APIHelper.DEFAULT_TOKEN;
        this.session_id = APIHelper.DEFAULT_TOKEN;
        this.user_name = '-invalid-';
        if(localStorage.getItem('device_key') && JSON.parse(localStorage.getItem('device_key')).device_key){
            this.device_key = JSON.parse(localStorage.getItem('device_key')).device_key;
        }else{
            this.device_key = '-invalid-'
        }
    }
}
