import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumberPipe'
})
export class CustomNumberPipe implements PipeTransform {
  transform(value: any, args: any): string {  	
  	if(!value) { return "0";}  	
  	if(args == "BHD") {
		  return parseFloat(value.toString()).toFixed(3);	
  	} else {
  		return parseFloat(value.toString()).toFixed(2);	
  	}    
  }
}