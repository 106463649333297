import { Injectable } from '@angular/core';
import { BaseConfig } from './api-config';

@Injectable()
export class AppStrings {
    // function which will return top nav bar titles
    pageTitle() {
        var data = {
            brandName: localStorage.getItem('shop_name'),
            online: 'Online',
            offline: 'Offline',
            table: 'Table',
            customer: 'Customer',
            add_cust: 'Add Customer',
            updated: 'Updated',
            updateAvailable: 'Update Available',
            profileUnavailable: 'Profile not available at the moment',
            rewardPts: 'Reward pts',
            lastVisit: 'Last Visit',
            custType: 'Cust Type'
        };
        return data;
    }

    //function which will return all titles in login page 
    loginValues() {
        var data = {
            head: 'Hello !',
            subHead: 'Link your mobile with OGD Merchant app',
            otpSms: 'You would shortly receive an OTP on this mobile number (and/or email address).',
            optional: 'Optional (e-mail)',
            submit: 'ENTER'
        };
        return data;
    }

    //function which will provide all titles on signup page 
    signupValues() {
        var data = {
            head: 'We are almost there !',
            otp: 'OTP',
            subTitle: 'Please enter verification code (OTP) received by you',
            resend: 'Resend OTP',
            licence: BaseConfig.config.CLIENT + ' Licence Code',
            enter_licence: 'Please Enter ' + BaseConfig.config.CLIENT + ' Licence code to your merchant app',
            shop: BaseConfig.config.CLIENT + ' Shop Code',
            enter_shop: 'Please enter ' + BaseConfig.config.CLIENT + ' Shop Code to your merchant app',
            submit: 'ENTER',
            newCodeSent: 'New verification code sent',
            printerError:'Printer data could not be fetched at this time' 
        };
        return data;
    }

    //function to return all titles to itemviewmodules
    tableViewValues() {
        var data = {
            tables: 'Tables',
            pickup: 'TakeAway',
            delivery: 'Delivery',
            guest: 'Guest',
            orders:'Orders',
            invalidProfile: 'Invalid profile, please re-authenticate the app',
            enterDetails: 'Enter customer information',
            coversDesc: 'Number of covers',
            covers: 'Covers',
            mobile: 'Mobile Number',
            mobileDesc: 'Enter Customer mobile number',
            addCustomer: 'Add Customer',
            cancel: 'Cancel'
        };
        return data;
    }

    //function to return all titles for customer view page
    customerViewValues() {
        var data = {
            errorMerchantProfile: 'Error reading merchant profile',
            errorShopConfiguration: 'Error reading shop configuration',
            skip: 'SKIP'
        };
        return data;
    }

    //function to return all titles for items view screen
    itemViewTitles() {
        var data = {
            pay: 'Pay',
            noSell: 'No Sell',
            sub_total: 'Sub Total',
            cash: 'Cash',
            card: 'Card',
            coupon: 'Coupon',
            add: 'Add',
            variant: 'Choose One Variant',
            modifier: 'Choose Multiple Modifier',
            fetchCategoryError: 'Unable to fetch shop categories',
            fetchItemError: 'Unable to fetch items for this category',
            billingError: 'Unable to bill, try again',
            invalidPhone: 'Invalid mobile, try again',
            guest: 'Guest',
            customerInfoFailed: 'Failed to retrieve customer info, try again',
            na: 'NA',
            variantSelectError: 'Please select a valid variant',
            taxLabel: 'GST',
            charge: 'Service Charge',
            invalidProfile: 'Invalid profile, please re-authenticate the app',
            searchItem: 'Search By Item Name',
            searchShortKey: 'Search By Item Short Key',
            searchBarcode: 'Search By Item Barcode',
            printerOptions: 'Printer Options',
            cancel: 'Cancel',
            print: 'Print',
            kot: 'KOT',
            copy: 'Copy',
            reset: 'Reset',
            total: 'Total Amount',
            resetWarn: 'Reset will clear your list,do you really want to continue with Reset ?',
            printGreetLine1: 'Thank You :-)',
            printGreetLine2: '--- With Love, ' + BaseConfig.config.CLIENT + ' ---',
            addNote: 'Enter print note :',
            addQuantity:'Enter Quantity',
            itemPrice:'Enter Item Price',
            done: 'Done'
        };
        return data;
    }

    //function to return all titles for payment screen
    paymentViewTitles() {
        var data = {
            reward: 'Reward',
            discount: 'Discount',
            card: 'Card',
            cash: 'Cash',
            coupon: 'Coupon',
            total: 'Total',
            pay: 'Pay',
            sub_total: 'Sub Total',
            all: 'All',
            rewards: 'Rewards',
            cashBack: 'Cash Back',
            reward_point: 'Reward Point',
            amount: 'Amount',
            redeem_point: 'Redeem Point',
            reward_msg: 'Minimum redeem points should be greater than 80',
            taxLabel: 'GST',
            charge: 'Service Charge',
            voidLabel: 'Void',
            bill: 'Bill',
            noItemsInCart: 'No items in cart',
            positiveBalance: 'Can not bill with positive balance',
            invalidProfile: 'Invalid profile, please re-authenticate the app',
            printGreetLine1: 'Thank You :-)',
            printGreetLine2: '--- With Love, ' + BaseConfig.config.CLIENT + ' ---',
            addNote: 'Enter print note :',
            printWarn: 'Printer has not yet set, do you still want to continue',
            done: 'Done',
            ok: 'Ok',
            cancel: 'cancel'
        };
        return data;
    }

    //function to retuen all titles on printer setting page 
    printerSettingTitles() {
        var data = {
            name: 'Printer Name',
            section: 'Section',
            mobile: 'Mobile Number',
            ip: 'IP Address',
            add: 'Add Printer'
        };
        return data;
    }

    //function to return all titles on add printer page 
    addPrinterTitles() {
        var data = {
            add: 'Add Printer',
            warn: 'Image use only for bill print',
            browse: 'Browse',
            name: 'Name',
            section: 'Section',
            add_new: 'Add New',
            printer_model: 'Printer Model',
            find: 'FInd',
            ip: 'IP Address',
            print_copy: 'Print Copy',
            paper_size: 'Print Paper Size',
            size1: '2 Inches',
            size2: '3 Inches',
            assign: 'Assign this as cash counter printer',
            service: 'Selfiles Service',
            kot: ' KOTs',
            bill: 'Bills',
            kot_bill: 'KOTs & Bills',
            other: 'Other Printer',
            Select: 'Select Printer',
            cancel: 'Cancel',
            save: 'Save',
            check: 'Check Print',
            successMsg: 'Printer added succssfully'
        };
        return data;
    }

    // function to return all titles for add guest page 
    addGuestTitles() {
        var data = {
            mobile: 'Mobile No',
            name: 'Name',
            email: 'E-mail',
            country: 'Country',
            state: 'State',
            city: 'City',
            pincode: 'PIN Code',
            address: 'Address',
            distance: 'Distance',
            cancel: 'Cancel',
            add: 'Save & close',
            view: 'View Guest',
            save: 'Save',
            defaultCountry: 'Australia',
            errorAddingCustomer: 'Error adding customer, try again',
            addressView: 'Address View',
            searchAddress: 'Search Address'
        };
        return data;
    }

    //function to return all titles for view guest page 
    viewGuestTitles() {
        var data = {
            address: 'Address 1',
            shop: 'Favorite Shop',
            today: 'Today',
            week: 'Week',
            month: 'Month'
        };
        return data;
    }

    //function to return all titles for recent orders page 
    recentOrdersTitles() {
        var data = {
            receipt_no: 'Receipt No',
            order_type: 'Order Type',
            subtotal: 'Subtotal',
            tax: 'GST',
            card: 'Card',
            cancel: 'Cancel Order',
            print: 'Print',
            invalidProfile: 'Invalid profile, please re-authenticate the app'
        };
        return data;
    }

    shopUserLogin() {
        var data = {
            invalidProfile: 'Invalid profile, please re-authenticate the app',
            selectUser: 'Select a valid user',
            invalidUser: 'Invalid user or PIN',
            selectUserLabel: 'Select User',
            remeberMe: 'Remember Me',
            signIn: 'Sign In',
            pin: 'Password'
        };
        return data;
    }

    report() {
        var data = {
            customer: 'Total Customer',
            item_sold: 'Items Sold',
            total_earning: 'Total Earning',
            total_profit: 'Total Profit',
            grand_cash_total: 'Grand Cash Total',
            grand_card_total: 'Grand Card Total',
            grand_other_total: 'Other Total',
            grand_total: 'Grand Total',
            diary_total: 'Grand Diary Total',
            total_orders: 'Total Orders',
            print: 'Print',
            printGreetLine1: 'Thank You :-)',
            printGreetLine2: '--- With Love, ' + BaseConfig.config.CLIENT + ' ---',

        }
        return data;
    }
}
