import { Component, OnInit} from '@angular/core';
import { WindowRef } from './windowRef';
import { APIHelper } from './api-helper';



@Component({
  selector: 'app-razorpay',
  template: '',  
})
export class RazorpayComponent {
	rzp1: any;
  	title = 'app';

  	constructor(private winRef: WindowRef) {
  	}
  	public initPay(amount,data): any {
  		var self = this;
  		return new Promise<any>((resolve,reject) =>{
			/*let user_data = JSON.parse(localStorage.getItem('user_data')) || {};*/
	  		let options = {
			    'key': APIHelper.RAZORPAY_KEY,
			    'amount': '0',
			    'name': 'Rise',		    
			    'image': 'assets/images/logo.png',
			    'handler': function(response) {					    
			    	resolve(response.razorpay_payment_id)
			    },
			    /*'prefill': {
			        'name': user_data.fullname? user_data.fullname : 'Guest',
			        'email': user_data.email? user_data.email : '',
			        'phone': user_data.phoneNumber? user_data.phoneNumber : '',
			    },*/
			    'notes': {
			        /*'address': user_data.address? user_data.address : '',*/
			        renewal_type: data. renewal_type,
			        m_c:data.m_c
			    },
			    'theme': {
			        'color': '#F37254'
			    },
			    "modal": {
		          "ondismiss": function(){
		            resolve(false)
		          }
		        }
			};

			let price = parseInt(amount) * 100;
	  		options.amount = price + '';
	    	self.rzp1 = new self.winRef.nativeWindow.Razorpay(options);
	    	self.rzp1.open();
    	})
  	}
  
}
