import { Component, OnInit, EventEmitter, HostListener, Output, Input } from '@angular/core';

@Component({
  selector: 'app-input-upload',
  templateUrl: './input-upload.component.html',
  styleUrls: ['./input-upload.component.css']
})
export class InputUploadComponent implements OnInit {

	@Input('upload') upload: boolean;
	@Input('input_id') input_id: string;
	@Input('allowed_extentions') allowed_extentions: any = [];

	@Output() filesDropped = new EventEmitter<FileList>();
	@Output() filesHovered = new EventEmitter();
	@Output() filesUpload = new EventEmitter();

  constructor() { }

  ngOnInit() {
	this.input_id = this.input_id ? this.input_id : 'ogp_images';
  }

    getBase64(file) {
	  return new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => resolve(reader.result);
	    reader.onerror = error => reject(error);
	  });
	}	
	
  	@HostListener('drop', ['$event'])
		onDrop($event) {
			$event.preventDefault();
			if($event.target.files && $event.target.files[0])	{
				let tFile = $event.target.files[0];
				if(this.allowed_extentions && this.allowed_extentions.length > 0 && this.allowed_extentions.indexOf(tFile.type) == -1) {
			        alert("allowed files .jpeg|jpg|png files");
				} else {
					this.filesDropped.emit(tFile);
					this.getBase64($event.target.files[0]).then((result:any) => {
						if(result) {
							var fileSA = result.split('base64,');					
							fileSA[0] = fileSA[0].replace("data:","");
							fileSA[0] = fileSA[0].replace(";","");
							if(!this.allowed_extentions || this.allowed_extentions.length == 0 || this.allowed_extentions.indexOf(fileSA[0]) != -1) {
					        	this.upload = true;				
					        	let fileExtention = (fileSA[0] == "image/png") ? 'png' : 'jpeg';
								this.filesUpload.emit({base64Data:result,extention:fileExtention});
					      	} else {
					      		this.upload = false;				
					        	alert("allowed files .jpeg|jpg|png files");
					      	}			
				      	} else {
				      		this.upload = false;				
				      	}
					})
					.catch(error => {
						this.upload = false;				
						console.log(error);
					})
				}
			}
		}

	@HostListener('dragover', ['$event'])
		onDragOver($event) {
			$event.preventDefault();
			this.filesHovered.emit(true);
			this.upload = false;
			this.filesUpload.emit(this.upload);		
		}

	@HostListener('dragleave', ['$event'])
		onDragLeave($event) {
			this.filesHovered.emit(false);		
		}

	@HostListener('click', ['$event'])
		onClick($event) {
			this.upload = false;
			this.filesUpload.emit(this.upload);			
		}

	@HostListener('change', ['$event'])
		onChange($event) {
			$event.preventDefault();
			if($event.srcElement.files && $event.srcElement.files[0])	{
				let tFile = $event.srcElement.files[0];
				if(this.allowed_extentions && this.allowed_extentions.length > 0 && this.allowed_extentions.indexOf(tFile.type) == -1) {
			        alert("allowed files .jpeg|jpg|png files");
				} else {
					this.filesDropped.emit(tFile);
					this.getBase64($event.target.files[0]).then((result:any) => {
						if(result) {
							var fileSA = result.split('base64,');					
							fileSA[0] = fileSA[0].replace("data:","");
							fileSA[0] = fileSA[0].replace(";","");
							if(!this.allowed_extentions || this.allowed_extentions.length == 0 || this.allowed_extentions.indexOf(fileSA[0]) != -1) {
					        	this.upload = true;				
					        	let fileExtention = (fileSA[0] == "image/png") ? 'png' : 'jpeg';
								this.filesUpload.emit({base64Data:result,extention:fileExtention});
					      	} else {
					      		this.upload = false;				
					        	alert("allowed files .jpeg|jpg|png files");
					      	}			
				      	} else {
				      		this.upload = false;				
				      	}
					})
					.catch(error => {
						this.upload = false;				
						console.log(error);
					})
				}
			}
			
		}
}
