import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[stopPropagation]'
})
export class StopPropagationDirective {

  @Input() stopPropagation

  constructor() { }
  @HostListener('click', ['$event'])
  onClick(event) {
    event.stopPropagation();
  }

}
