import { Component, OnInit } from '@angular/core';
import { paper_sizes } from './printer-settings-info';
import { printing_types } from './printer-settings-info';
import { CommonService } from '../../helpers/services/common.service';
import { MessageService } from 'primeng/api';
import { ConfirmationModal } from '../../confirmation-modal/confirmation-modal.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-printer-settings',
	templateUrl: './printer-settings.component.html',
	styleUrls: ['./printer-settings.component.less']
})
export class PrinterSettingsComponent implements OnInit {
	printerUtility = false;
	body = document.getElementsByTagName("body")[0];
	isRestaurant: boolean = false;
	title = 'Add';

	public modalRef: BsModalRef;
	public subscriptions: Subscription[] = [];

	sections_list: string[] = [];
	department_list: any[];
	paper_sizes_list: string[] = paper_sizes;
	print_type_list: string[] = printing_types;
	add_printer: boolean;
	printers_list: any[] = [];

	selectedPrinterId: any;
	show_format: boolean;
	isShowTypeList: boolean = false;
	isUpdatingPrinterData: boolean = false;
	isLoading: boolean = false;
	format: any;
	editPrt: boolean;

	showTypeList() {
		this.isShowTypeList = !this.isShowTypeList;
	}

	public new_printer: any = {
		name: '',
		kitchen_department: '',
		ip_address: '',
		paper_size: '',
		print_type: '',
		quantity: '0',
		receipt: true,
		kitchen_tichets: true,
		receipt_and_kitchen: true,
		print_staff_name:true,
		format: {
			img: '',
			date: new Date(),
			time: '00:00:00',
			header: '',
			footer: '',
			note: '',
			products: [
				{ name: 'HOT GOURMET FLAVO', quantity: '1', amt: '145' },
				{ name: 'LAUNGE CAPPUCCINO', quantity: '2', amt: '120' }
			],
			name: true,
			type: true,
			tax: true,
			quantity: true,
			uom: true,
			price: true,
			poweredBy: true
		}
	};

	public print_config: any = {};

	constructor(private commonService: CommonService, private messageService: MessageService,
		private modalService: BsModalService, private router: Router) {
			this.initializePrintConfig();
		 }

	ngOnInit() {
		if (localStorage.getItem('PRINT_UTILITY')) {
			this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
		}
		this.body.setAttribute("class", "scrolling-view");
		let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
		if (!shop_config) {
			this.router.navigate(['verify-pin']);
			return;
		}
		this.getInitialData();
		if (localStorage.getItem('isRestaurant') == 'true') {
			this.isRestaurant = true;
		}
	}


	getInitialData() {
		let shopCode = localStorage.getItem('shop_code');
		let sendData = {
			'shop_code': shopCode
		}
		this.getShopPrinters();
		/*this.commonService.getPrinters(sendData).subscribe((res) => {
				let printerData = res.json();
		this.isLoading = false;
				if(printerData.status === 0){
					this.printers_list = printerData.result;
				}
		},err =>{
		this.isLoading = false;
			this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.' });
	});*/
		if (navigator.onLine) {
			this.getPrinterDepartmentList();
		}
	}

	keyPress(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	getPrinterDepartmentList() {
		let shopCode = localStorage.getItem('shop_code');
		let sData = {
			'shop_code': shopCode,
			'type': 'kitchen_department'
		}
		this.commonService.getPrintersSections(sData).subscribe((res) => {
			let printerSectiondData = res.json();
			if (printerSectiondData.status === 0) {
				this.department_list = printerSectiondData.result;
			}
		});
	}

	addPrinter() {
		this.title = 'Add';
		this.new_printer = {
			name: '',
			kitchen_department: '',
			ip_address: '',
			paper_size: '',
			print_type: '',
			quantity: '0',
			receipt: true,
			kitchen_tichets: true,
			receipt_and_kitchen: true,
			print_staff_name:true,
			format: {
				img: '',
				date: new Date(),
				time: '00:00:00',
				header: '',
				footer: '',
				note: '',
				products: [
					{ name: 'HOT GOURMET FLAVO', quantity: '1', amt: '145' },
					{ name: 'LAUNGE CAPPUCCINO', quantity: '2', amt: '120' }
				],
				name: true,
				type: true,
				tax: true,
				quantity: true,
				uom: true,
				price: true,
				poweredBy: true
			}
		};
		this.add_printer = true;
		this.show_format = false;
		this.initializePrintConfig();
	}

	editPrinter(printer: any) {
		this.title = "Edit";
		this.selectedPrinterId = printer.id;
		this.editPrt = true;
		this.new_printer = JSON.parse(JSON.stringify(printer));
		this.add_printer = true;
		this.show_format = false;
		this.setPrintConfigs();
	}

	private unsubscribe() {
		this.subscriptions.forEach((subscription: Subscription) => {
			subscription.unsubscribe();
		});
		this.subscriptions = [];
	}

	deletePrinter(printer: any) {
		if (navigator.onLine) {
			this.modalRef = this.modalService.show(ConfirmationModal, { class: 'centred-modal' });
			this.modalRef.content.decision = "";
			this.modalRef.content.text = "Do you really want to delete this printer?";

			this.subscriptions.push(this.modalService.onHide.subscribe(() => {
				if (this.modalRef.content.decision === 'yes') {
					this.isUpdatingPrinterData = true;
					let shopCode = localStorage.getItem('shop_code');
					let sData = {
						'shop_code': shopCode,
						'printer_id': printer.id
					}
					this.commonService.deletePrinter(sData).subscribe((res) => {
						let printerData = res.json();
						if (printerData.status === 0) {
							this.getShopPrinters();
							for (var i = 0; i < this.printers_list.length; ++i) {
								if (this.printers_list[i].id == printer.id) {
									this.printers_list.splice(i, 1);
									this.isUpdatingPrinterData = false;
									this.messageService.add({ severity: 'success', summary: 'Printer deleted successfully.', detail: '' });
									break;
								}
							}
						} else {
							this.isUpdatingPrinterData = false;
							this.messageService.add({ severity: 'error', summary: 'Error', detail: printerData.message });
						}
					}, err => {
						this.isUpdatingPrinterData = false;
						this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });

					});
				}
				this.unsubscribe();
			}));
		} else {
			this.messageService.add({ severity: 'error', summary: 'Error', detail: "In offline mode can't change printer settting " });
		}
	}

	addPrinterDetails() {
		console.log('print config', this.print_config)
		this.savePrintConfigs(this.editPrt);
		if (this.editPrt) {
			this.editPrt = false;
			this.add_printer = false;
			this.show_format = false;
			let shopCode = localStorage.getItem('shop_code');
			let sData = {
				'shop_code': shopCode,
				'printer_id': this.selectedPrinterId,
				'name': this.new_printer.name,
				'kitchen_department': this.new_printer.kitchen_department,
				'ip_address': this.new_printer.ip_address,
				'paper_size': this.new_printer.paper_size,
				'print_footer_note': this.new_printer.print_footer_note,
				'print_header': this.new_printer.print_header,
				'tax_number_title': this.new_printer.tax_number_title,
				'print_footer_note_enabled': this.new_printer.print_footer_note_enabled ? true : false,
				'print_quantity': (typeof this.new_printer.print_quantity == 'string') ? parseInt(this.new_printer.print_quantity) : this.new_printer.print_quantity,
				'print_staff_name': this.new_printer.print_staff_name,
			}
			this.updatePrinter(sData);
		} else {
			this.savePrinter();
		}
	}

	onChangePrintType(printerData: any, type: any) {
		if (navigator.onLine) {
			this.selectedPrinterId = printerData.id;
			let print_type_list = printerData.print_type;

			if (print_type_list[0] && print_type_list.length == 1) {
				let print_type = print_type_list[0];
				if (print_type == 'self_service' && type != 'self_service') {
					return;
				}
			}


			let index = print_type_list.indexOf(type);
			if (index == -1) {
				print_type_list.push(type);
			} else {
				print_type_list.splice(index, 1);
			}

			if (type == 'self_service' && index == -1) {
				print_type_list = ['self_service'];
			} else if (type == 'self_service' && index != -1) {
				print_type_list = [];
			}


			let shopCode = localStorage.getItem('shop_code');
			let sData = {
				'shop_code': shopCode,
				'printer_id': this.selectedPrinterId,
				'print_type': print_type_list
			}
			this.updatePrinter(sData);
		} else {
			this.messageService.add({ severity: 'error', summary: 'Error', detail: "In offline mode can't change printer settting " });
		}
	}

	updatePrinter(data: any) {
		if (navigator.onLine) {
			this.isUpdatingPrinterData = true;
			this.commonService.updatePrinter(data).subscribe((res) => {
				let printerData = res.json();
				if (printerData.status === 0) {
					this.getShopPrinters();
					for (var i = 0; i < this.printers_list.length; ++i) {
						if (this.printers_list[i].id == this.selectedPrinterId) {
							this.printers_list[i] = Object.assign(this.printers_list[i], printerData.result.printer_info[0]);
							this.add_printer = false;
							this.show_format = false;
							this.isUpdatingPrinterData = false;
							break;
						}
					}
				} else {
					this.isUpdatingPrinterData = false;
					this.messageService.add({ severity: 'error', summary: 'Error', detail: printerData.message });
				}
			}, err => {
				this.isUpdatingPrinterData = false;
				this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
			});
		} else {
			this.messageService.add({ severity: 'error', summary: 'Error', detail: "In offline mode can't change printer settting " });
		}
	}

	savePrinter() {
		if (navigator.onLine) {

			if (this.new_printer.name.trim() == '') {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Printer name can not empty' });
				return;
			}

			/*if(this.new_printer.kitchen_department.trim() == ''){
						this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Department can not empty' });
						return;
			}*/


			/*if(this.new_printer.ip_address.trim() == ''){
						this.messageService.add({ severity: 'error', summary: 'Error', detail: 'IP address can not empty' });
						return;
			}*/

			let isAvailable = false
			this.printers_list.forEach(printer => {
				if (printer.ip_address && printer.ip_address.trim() && this.new_printer.ip_address && this.new_printer.ip_address.trim()
					&& printer.ip_address.trim() == this.new_printer.ip_address.trim()) {
					isAvailable = true;
				}
			});

			if (isAvailable) {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: 'You can not add same ip address printer' });
				return;
			}

			if (this.new_printer.paper_size.trim() == '') {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Paper size field can not empty' });
				return;
			}

			if (!this.new_printer.print_quantity || (parseInt(this.new_printer.print_quantity) <= 0)) {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter valid quantity value' });
				return;
			}

			this.isLoading = true;
			let shopCode = localStorage.getItem('shop_code');
			let sData = {
				'shop_code': shopCode,
				'name': this.new_printer.name,
				'kitchen_department': this.new_printer.kitchen_department,
				'ip_address': this.new_printer.ip_address,
				'print_type': [],
				'paper_size': this.new_printer.paper_size,
				'print_footer_note': this.new_printer.print_footer_note,
				'print_header': this.new_printer.print_header,
				'tax_number_title': this.new_printer.tax_number_title,
				'print_footer_note_enabled': this.new_printer.print_footer_note_enabled ? true : false,
				'print_quantity': (typeof this.new_printer.print_quantity == 'string') ? parseInt(this.new_printer.print_quantity) : this.new_printer.print_quantity,
				'print_staff_name': this.new_printer.print_staff_name,
			}
			this.commonService.addPrinter(sData).subscribe((res) => {
				let printerData = res.json();
				if (printerData.status === 0) {
					this.getShopPrinters();
					this.printers_list.push(printerData.result.printer_info);
					this.add_printer = false;
					this.show_format = false;
					this.isLoading = false;
				} else {
					this.isLoading = false;
					this.messageService.add({ severity: 'error', summary: 'Error', detail: printerData.message });
				}
			}, err => {
				this.isLoading = false;
				this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.' });
			});
		} else {
			this.messageService.add({ severity: 'error', summary: 'Error', detail: "In offline mode can't change printer settting " });
		}

	}

	receiptFormat(format: object) {
		this.format = format;
		this.show_format = true;
	}


	getShopPrinters() {

		if (navigator.onLine) {
			this.isLoading = true;

			let shopCode = localStorage.getItem('shop_code');
			let sendData = {
				'shop_code': shopCode
			}

			this.commonService.getPrinters(sendData).subscribe((res) => {
				let printerData = res.json();
				this.isLoading = false;
				if (printerData.status === 0) {
					this.printers_list = printerData.result;
					console.log('Shop printers', this.printers_list)
					this.printers_list = this.printers_list.map(item=>{
						if(item.id === this.selectedPrinterId){
							item['print_staff_name'] = this.new_printer.print_staff_name;	
						}
						return item;
					})

					localStorage.setItem('shop_printers', JSON.stringify(this.printers_list));
				} else {
					console.log("Printer data could not be fetched");
					alert('Printer data could not be fetched at this time');
					// this.messagingService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
				}
			});
		} else {
			this.printers_list = JSON.parse(localStorage.getItem('shop_printers')) || [];
		}
	}

	setPrinterUtilityStatus() {
		this.printerUtility = !this.printerUtility;
		localStorage.setItem('PRINT_UTILITY', this.printerUtility.toString());
	}

	isPrintStaffChecked(event){
		console.log('print event', event);
		this.new_printer.print_staff_name = event;
	}

	isPrintConfigChecked(event, key){
		this.print_config[key] = event;
	}

	savePrintConfigs(isEdit){
		let print_configs = JSON.parse(localStorage.getItem('print_configs'))
		if(!(print_configs && print_configs?.length > 0)){
			print_configs = [];
		}
		let data = {
			printer_id : this.selectedPrinterId,
			print_config: this.print_config
		}
		if(isEdit){
			print_configs = print_configs.filter(item=>item.printer_id !== data.printer_id);
		}
		print_configs.push(data);
		localStorage.setItem('print_configs', JSON.stringify(print_configs));
		this.initializePrintConfig();
	}

	setPrintConfigs(){
		let print_configs = JSON.parse(localStorage.getItem('print_configs'));
		if(print_configs && print_configs?.length > 0){
			let currentPrint = print_configs.filter(item=>item.printer_id === this.selectedPrinterId);
			if(currentPrint.length > 0){
				this.print_config = currentPrint[0].print_config
			}else{
				this.initializePrintConfig();
			}
		}else{
			this.initializePrintConfig();
		}
	}

	initializePrintConfig(){
		this.print_config = {
			shop_logo: true,
			outlet_name: true,
			outlet_phone_number: true,
			header_receipt_tax_invoice: true,
			transaction_date: true,
			delivery_date: true,
			bill_number: true,
			user_name: true,
			payment_type: true,
			footer:true,
			customer_gst_no:true,
			terminal_details:true,
			outlet_gst_no:true,
			customer_address:true,
	
			department_name:true,
			no_of_items:true,
			shipping_type:true,
			section_garden_section:true,
			table_number:true,

			shop_address:true,
			shop_zip_code:true,

			customer_number:true,
			customer_name:true
		};
	}
}

