import { Injectable, Inject } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { Http, Headers } from '@angular/http';
import { APIHelper } from '../api-helper';

@Injectable()
export class CaptainAppService {

    constructor(private http:Http) {}

    syncShopSetting(data: any) {
        return APIHelper.getInstance(this.http).apiCallForSyncWithCaptainApp(APIHelper.SYNC_SHOP_SETTING, data);
    }
    
    syncItemsCategories(data: any) {
        return APIHelper.getInstance(this.http).apiCallForSyncWithCaptainApp(APIHelper.SYNC_ITEMS_CATEGORIES, data);
    }

    syncItemsInfo(data: any) {
        return APIHelper.getInstance(this.http).apiCallForSyncWithCaptainApp(APIHelper.SYNC_ITEMS_INFO, data);
    }

    syncTables(data: any) {
        return APIHelper.getInstance(this.http).apiCallForSyncWithCaptainApp(APIHelper.SYNC_TABLES_INFO, data);
    }

    getSyncTables(data) {
        return APIHelper.getInstance(this.http).apiCallForSyncWithCaptainApp(APIHelper.GET_SYNC_TABLES_INFO,data);
    }

    getParticularSyncTable(data: any) {
        return APIHelper.getInstance(this.http).apiCallForSyncWithCaptainApp(APIHelper.GET_PARTICULAR_SYNC_TABLE_INFO, data);
    }

    syncParticularTable(data: any) {
        return APIHelper.getInstance(this.http).apiCallForSyncWithCaptainApp(APIHelper.SYNC_PARTICULAR_TABLE_INFO, data);
    }

    saveOrderWithCaptainApp(data:any) {
        return APIHelper.getInstance(this.http).apiCallForSyncWithCaptainApp(APIHelper.SAVE_CAPTAIN_APP_ORDER, data);        
    }
    

}
