import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Dish } from '../dish';
import { remove,keys,uniq,groupBy,difference } from 'lodash-es';
import { GeneralHelper } from '../helpers/genral-helper';
import { ShopCategoriesItemsService } from '../helpers/services/shop-categories-items.service';
import { MessageService } from 'primeng/api';
import { StateGuardService } from '../state-guard.service';

@Component({
  selector: 'app-multi-group-combo-modal',
  templateUrl: './multi-group-combo-modal.component.html',
  styleUrls: ['./multi-group-combo-modal.component.less'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        transform: 'translateY(-100%)'
      })),
      state('active', style({
        transform: 'translateY(0%)'
      })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ]),
    trigger('fadeOverlay', [
      state('inactive', style({
        opacity: '0'
      })),
      state('active', style({
        opacity: '1'
      })),
      transition('inactive => active', animate('150ms ease-in')),
      transition('active => inactive', animate('150ms ease-out'))
    ])
  ]
})
export class MultiGroupComboModalComponent implements OnInit {

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() doneEvent: EventEmitter<any> = new EventEmitter();
  @Output() addingDish: EventEmitter<Dish> = new EventEmitter();
  @Input('dish') dish: any;

  total_price:any;
  tempSelectedItemPrice:any;
  selectedModifierList:any;
  currencySymbol:any;
  isLoadingShow:boolean= false;
  quantity: string;

  add_modifier: boolean;
  is_selected_variant: boolean = false;

  anim_state: string = 'inactive';
  selected_main_item:any;
  sub_variants_item: any = {};
  sub_variants: any = [];
  variants: any = [];
  modifiers: any = [];
  isBarcodeVariant:boolean = false;
  exclusive_selective_modifier_group = [];
  can_be_sold_loose: boolean = false;
  constructor(private shopCategoryItemService: ShopCategoriesItemsService,
              private messageService:MessageService,
              private stateGuard: StateGuardService) { }

  ngOnInit() {
    var self = this;
    setTimeout(() => {
      self.anim_state = 'active';
    }, 150);

    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shop_config.currency;
    this.tempSelectedItemPrice = JSON.parse(JSON.stringify(this.dish.unit_price));

    let mainGroupItem = [];
    this.dish.multi_group_combo.main_group.forEach((mit:any) => {
      if(mit.hasOwnProperty('unit_price')) {
        var mItem = JSON.parse(JSON.stringify(mit));
        mItem.is_main_item = true;
        mainGroupItem.push(mItem)

        if(mItem.variant_attributes && keys(mItem.variant_attributes).length>0) {
          mItem.item_code = mItem.display_name+"_"+new Date().getTime();
          for (var k in mItem.variant_attributes) {            
            var variantObject = {
              name: k,
              selected: '',
              options: mItem.variant_attributes[k]
            };

            if(mItem.selected_variant_attributes && mItem.isEditFromcart) {
              for (var j in mItem.selected_variant_attributes) {
                if(j == k) {
                  if(Array.isArray(mItem.variant_attributes[k])) {
                    mItem.variant_attributes[k].forEach( attr =>{
                      if(attr.attribute_code == mItem.selected_variant_attributes[j].attribute_code){
                        this.is_selected_variant = true;                        
                        variantObject.selected = mItem.selected_variant_attributes[j].name;
                      }
                    })
                  }
                }
              };
            }
            if(this.sub_variants_item[mItem.item_name]) {
              this.sub_variants_item[mItem.item_name].push(variantObject)
            } else {
              this.sub_variants_item[mItem.item_name] = [variantObject];
            }            
          }
        }
      }
    });

    this.variants.push({
      name:"Main Group",
      selected_item_codes:[],      
      options:mainGroupItem,
      is_main_group: true
    });

    if(Array.isArray(this.dish.multi_group_combo.sub_groups) && this.dish.multi_group_combo.sub_groups.length>0) {
      this.dish.multi_group_combo.sub_groups.forEach((sg:any) => {
        let sGItems = [];
        if(Array.isArray(sg.items) && sg.items.length>0) {
          sg.items.forEach((sit:any) => {
            if(sit.hasOwnProperty('unit_price')) {
              var sItem = JSON.parse(JSON.stringify(sit));
              sItem.is_main_item = false;              
              sGItems.push(sItem);
            }
          })
          if(sGItems.length>0) {
            this.variants.push({
              name:sg.group_name,
              selected_item_codes:[],      
              min:sg.min,
              max:sg.max,
              is_main_group: false,
              options:sGItems
            });
          }
        }
      });
    }
  }

  close() {
    setTimeout(() => {
      this.closeEvent.emit();
    }, 200);
    this.anim_state = 'inactive';
  }

  done() {
    if(!this.selected_main_item) {
      this.messageService.add({ severity: 'error', summary: "Item Not Selected", detail: 'select any one item from main group' });
      return;
    }

    let selected_combo_items = [];
    var selected_attributes = [];
    this.modifiers.forEach(element => {
      element.options.forEach(attr => {
        if (attr.count > 0) {
          selected_attributes.push(attr);
        }
      });
    });

    let errorMessage = "";    
    for(var i=0,fLen = this.variants.length;i<fLen;i++) {
      let vg:any = this.variants[i];
      if(vg.is_main_group) {
        let tempSelectedItem = this.selected_main_item;
        if(this.selected_main_item && this.selected_main_item.selected_variant_item) {
          tempSelectedItem = this.selected_main_item.selected_variant_item;          
        }

        selected_combo_items.push({
          item_name: tempSelectedItem.item_name,
          item_code: tempSelectedItem.item_code,
          quantity: tempSelectedItem.quantity,
          unit_price: tempSelectedItem.unit_price
        });  

        this.dish.minimum_unique_attribute_selection = tempSelectedItem.minimum_unique_attribute_selection;
        this.dish.maximum_unique_attribute_selection = tempSelectedItem.maximum_unique_attribute_selection;
      } else {
        let selectedSGItem = 0
        vg.options.forEach((it:any) => {
          if(vg.selected_item_codes.indexOf(it.item_code) != -1) {
            selectedSGItem++;
            selected_combo_items.push({
              item_name:it.item_name,
              item_code:it.item_code,
              quantity:it.quantity,
              unit_price:it.unit_price
            });
          }
        });     
        if(vg.max || vg.min) {
          if(selectedSGItem<vg.min) {
            errorMessage = "Please  select minimum "+vg.min+" items from "+vg.name +" group";
          } else if(selectedSGItem>vg.max) {
            errorMessage = "Maximum "+vg.max+" items can select from  "+vg.name + " group";
          }          
        }
      }
      if(errorMessage) { break;  }          
    }

    if(errorMessage) {
      this.messageService.add({ severity: 'error', summary: "Item Selection", detail: errorMessage });
      return;
    }

    this.dish.included_combo_items = selected_combo_items;
    this.dish.attributes = selected_attributes;
    this.dish.item_count = this.quantity ? parseFloat(this.quantity) : 1;    
    this.checkExclusiveSelectiveAttributeAdded(this.dish).then(result => {
      if(result) {
        this.checkMinimumMaximumAttribteCondition(this.dish).then(mmcresult => {
          if(mmcresult) {
            setTimeout(() => {
              this.doneEvent.emit(this.dish);
            }, 200);
            this.anim_state = 'inactive';
          }
        })
      }
    });
  }

  checkExclusiveSelectiveAttributeAdded(item) {
    return new Promise((resolve,reject)=> {
        var not_selected_group = JSON.parse(JSON.stringify(this.exclusive_selective_modifier_group));
        remove(not_selected_group,group_name => {
          var flag = false;
          var tempAttributes = item.attributes;

          if(Array.isArray(tempAttributes)) {
            var dalen = tempAttributes.length;
            for(var i=0;i<dalen;i++) {
              if(tempAttributes[i].group_name == group_name) {
                flag = true;
                break;
              }
            }
          }

          return flag;
        });

        if(not_selected_group.length>0) {
          this.messageService.add({ severity: 'error', summary: "select at least one modifier from group > "+ not_selected_group.join(""), detail: '' });
          return resolve(false);
        } else {
          return resolve(true);
        }
    })
  }

  checkMinimumMaximumAttribteCondition(item) {
    return new Promise((resolve,reject)=> {
        if(!item.minimum_unique_attribute_selection && !item.maximum_unique_attribute_selection) {
          return resolve(true);
        }

        var flag = false;
        var unique_attr = (Array.isArray(item.attributes)) ? uniq(item.attributes.map(attr => {return attr.attribute_code; })) : []
        if(item.minimum_unique_attribute_selection && unique_attr.length < item.minimum_unique_attribute_selection) {
          this.messageService.add({ severity: 'error', summary: "select  minimum "+ item.minimum_unique_attribute_selection +" modifier", detail: '' });
          return resolve(false);
        } else if(item.maximum_unique_attribute_selection && item.maximum_unique_attribute_selection > 0 && unique_attr.length > item.maximum_unique_attribute_selection) {
          this.messageService.add({ severity: 'error', summary: "select  maximum "+ item.maximum_unique_attribute_selection +" modifier", detail: '' });
          return resolve(false);
        } else {
          return resolve(true);
        }
    })
  }

  checkMinimumMaximumGroupItemCondition() {
    return new Promise((resolve,reject)=> {
        let temp_dish_v = (this.dish.selected_variant_item) ? this.dish.selected_variant_item : this.dish;
        if(!temp_dish_v.minimum_unique_attribute_selection && !temp_dish_v.maximum_unique_attribute_selection) {
          return resolve(true);
        }

        var flag = false;
        var unique_attr = (Array.isArray(temp_dish_v.attributes)) ? uniq(temp_dish_v.attributes.map(attr => {return attr.attribute_code; })) : []
        if(temp_dish_v.minimum_unique_attribute_selection && unique_attr.length < temp_dish_v.minimum_unique_attribute_selection) {
          this.messageService.add({ severity: 'error', summary: "select  minimum "+ temp_dish_v.minimum_unique_attribute_selection +" modifier", detail: '' });
          return resolve(false);
        } else if(temp_dish_v.maximum_unique_attribute_selection && temp_dish_v.maximum_unique_attribute_selection > 0 && unique_attr.length > temp_dish_v.maximum_unique_attribute_selection) {
          this.messageService.add({ severity: 'error', summary: "select  maximum "+ temp_dish_v.maximum_unique_attribute_selection +" modifier", detail: '' });
          return resolve(false);
        } else {
          return resolve(true);
        }
    })
  }

  changeCountOnClickOfRect(event,option) {
    if (["increaseModifier","decreaseModifier"].indexOf(event.target.id) != -1) {
      return;
    }
    this.changeCount(option,'plus')
  }

  changeCount(option, value) {
    var self = this;
    if (value == 'plus') {
      option.count++;
      option.price = option.unit_price * option.count
    } else {
      if (option.count > 0) {
        option.count--;
      }
      if (option.count == 0) {
        setTimeout(() => {
          option.selected = false;
        }, 30);
      }
    }

    option.old_count = JSON.parse(JSON.stringify(option.count));

    setTimeout(() => {
      self.selectModifier(this.modifiers)
    }, 50);
  }

  // Function to add dish into cart
  addDish(item: any) {

    var modifierArray = [];
    this.modifiers.forEach(element => {
      element.options.forEach(attr => {
        if (attr.count > 0 || attr.remove) {
          modifierArray.push(attr);
        }
      });
    });
    //item.item_count = (this.quantity) ? this.quantity : 1
    if(item.selected_variant_item) {
      item['selected_variant_item'].item_count = parseFloat(this.quantity);
      if(modifierArray.length > 0){
        item['selected_variant_item'].attributes = modifierArray.sort((a,b) => {return (a.remove - b.remove)});
      }else{
        item['selected_variant_item'].attributes = [];
      }
    }
    //this.addingDish.emit(item);
    // }
  }

  changeQuantity(e:any){
    this.quantity = e;
    this.total_price = this.tempSelectedItemPrice * parseFloat(e);
    this.selectModifier(this.modifiers)
  }

  selectModifier(modifiers:any){

    if(Array.isArray(modifiers)){
      let total =0;
      modifiers.forEach(data =>{
        if(data.options && data.options.length>0){
          data.options.forEach(modifier =>{
            if(modifier.selected){
              let p = (typeof modifier.unit_price == 'string')? parseFloat(modifier.unit_price) :modifier.unit_price;
              let c = (typeof modifier.count == 'string')? parseFloat(modifier.count) :modifier.count;
              total = total + (p*c);
            }
          });
        }
      });

      let q = (typeof this.quantity == 'string')? parseFloat(this.quantity) : this.quantity;
      q = (typeof q == 'undefined' || typeof q === undefined)? 1 : q;
      
      this.total_price = q * this.tempSelectedItemPrice + total;      
    }
  }

  
  onSelectItem(dishDetail,variant:any, item: any) {
    if(variant.is_main_group) {
      variant.selected_item_codes = [item.item_code];
      this.selected_main_item = item;
      if(item.selected_variant_item && item.item_list && item.item_list.length>0) {
        this.sub_variants = this.sub_variants_item[item.item_name];
      } else {
        this.sub_variants = [];
      }
      this.getVariantSpecificModifiers(item);

      this.is_selected_variant = true;      
    }
    if(!variant.is_main_group) {      
      if(variant.min == 1 && variant.max == 1) {
        variant.selected_item_codes = [item.item_code];
      } else if(variant.selected_item_codes.indexOf(item.item_code) != -1) {
        remove(variant.selected_item_codes, (icode:any) => { return icode == item.item_code;});
      } else {
        if(variant.max && variant.selected_item_codes.length>=variant.max) {
          this.messageService.add({ severity: 'error', summary: 'Maximum Item Selected', detail: variant.name+ ' group can select maximum '+variant.max+" item." });
          return;
        }
        variant.selected_item_codes.push(item.item_code);
      }
    }

    let tempPrice:any = parseFloat(dishDetail.unit_price);
    this.tempSelectedItemPrice = tempPrice;
    let q = (typeof this.quantity == 'string')? parseFloat(this.quantity) : this.quantity;
    q = (typeof q == 'undefined' || typeof q === undefined)? 1 : q;
    this.total_price = q * tempPrice;
  }
  
  // select a variant attribute
  selectVariantAttribute(attributeGroup: any, attr: any) {
    this.is_selected_variant = true;
    if (typeof (this.selected_main_item['selected_variant_attributes']) === 'undefined') {
      var data = {
        [attributeGroup]: attr
      };
      this.selected_main_item['selected_variant_attributes'] = data;
    }
    var selectedAttributes = this.selected_main_item['selected_variant_attributes'];
    selectedAttributes[attributeGroup] = attr;

    this.selected_main_item['selected_variant_item'] = this.selectVariantItem(this.selected_main_item);
    if(this.selected_main_item.selected_variant_item) {
      this.selected_main_item['selected_variant_item']['attributes'] = [];
    }
    if(this.selected_main_item['selected_variant_item']) {
      this.getVariantSpecificModifiers(this.selected_main_item);
      this.can_be_sold_loose = (this.selected_main_item['selected_variant_item'].can_be_sold_loose) ? true : false;
      let unit_price:any = (this.selected_main_item['selected_variant_item']['unit_price'])? this.selected_main_item['selected_variant_item']['unit_price'] :0
      let tempPrice = (typeof unit_price == 'string')? parseFloat(unit_price) : unit_price;
      this.tempSelectedItemPrice = tempPrice;
      let q = (typeof this.quantity == 'string')? parseFloat(this.quantity) : this.quantity;
      q = (typeof q == 'undefined' || typeof q === undefined)? 1 : q;
      this.total_price = q * tempPrice;
      this.selectModifier(this.modifiers);
    }
  }

  getVariantSpecificModifiers(item) {
    this.modifiers = [];
    if(item) {
      let item_attributes = item.attributes ? item.attributes : [];
      
      if(item.selected_variant_item) {
        let selected_variant_item_with_attrs = item.item_list.filter(itm => itm.item_code == item['selected_variant_item'].item_code)    
        item_attributes = selected_variant_item_with_attrs[0].attributes;
      }

      if(!item.modifier_attributes) {
        item.modifier_attributes = groupBy(item_attributes.filter((atr: any) => !atr.is_variant_attribute), (grp: any) => grp.group_name);
      }

      for (var k in item.modifier_attributes) {
        var modifierObject = {
          name: k,
          options: []
        };

        item.modifier_attributes[k].forEach(mod => {
          if(item_attributes.filter(attr => mod.attribute_code == attr.attribute_code).length > 0) {
            if(item.isEditFromcart) {
              var isModSelected = false;
              item_attributes.forEach(modifier => {
                if(modifier.attribute_code == mod.attribute_code) {
                  mod.remove = modifier.remove;
                  mod.selected = modifier.selected;
                  mod.count = modifier.count;
                  mod.price = modifier.price;
                  isModSelected = true;
                }
              });

              if(!isModSelected) {
                mod.remove = false;
                mod.selected = false;
                mod.count = 0;
                mod.price = mod.unit_price;
              }
            } else {
              mod.remove = false;
              mod.selected = false;
              mod.count = 0;
              mod.price = mod.unit_price;
            }

            if(mod.is_selection_exclusive && this.exclusive_selective_modifier_group.indexOf(mod.group_name) == -1) {              
              this.exclusive_selective_modifier_group.push(mod.group_name);              
            }
            modifierObject.options.push(mod)
          }
        });

        if(modifierObject.options.length > 0){
          this.modifiers.push(modifierObject);
        }
      }
    }
  }

  // select a variant item from the selected variant item list
  selectVariantItem(item) {
    try {
      var selectedAttributes = GeneralHelper.values(item['selected_variant_attributes']).map((itm: any) => itm.attribute_code);
      var selectedItem = item['item_list'].filter((itm: any) => {
        return difference(selectedAttributes, itm.attributes.filter((x: any) =>
          x.is_variant_attribute).map((x: any) => x.attribute_code)).length === 0;
      });

      if(selectedItem.length > 1){
        selectedItem = selectedItem.sort((a,b) => { return a.can_be_unbundled-b.can_be_unbundled });
      }

      if(selectedItem.length == 0) {
        selectedItem = item['item_list'];
      }

      if(this.stateGuard.obtain('autocomplete.component:isBarcodeVariant').isBarcodeVariant){
        let barcode = this.stateGuard.obtain('autocomplete.component:isBarcodeVariant').bar_code;
        for(let i = 0 ; i < selectedItem.length ; i++){
          if(selectedItem[i].bar_code == barcode){
            return JSON.parse(JSON.stringify(selectedItem[i]));
          }
        }
      }else{
        return JSON.parse(JSON.stringify(selectedItem[0]));
      }
    } catch (e) {
      console.log('to catch exception');
      return null;
    }
  }

  getCSSOfVariantModifier(option) {
    if(option && option.name) {
      if(option.name.length < 20) {
        if(window.innerWidth <=1500) {
          return "col-sm-4 col-md-4";
        } else {
          return "col-sm-4 col-md-3";
        }        
      } else if(option.name.length < 30) {
        return "col-sm-6 col-md-4";
      } else if(option.name.length < 50) {
        return "col-sm-6 col-md-6";
      } else if(option.name.length < 60){
        return "col-sm-8 col-md-7";
      } else {
        return "col-sm-8 col-md-8";
      }
    } else {
      return "col-sm-4 col-md-3";
    }
  }
}