import { Component, OnInit, Input,OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
//import { GMapsService } from '../gmaps.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
	coordinates = [];
								 
	@Input('lat') lat: number;
	@Input('lng') lng: number;

	shop_lng:any;
	shop_lat:any;

	public dir:any=undefined;
 	constructor() {}
 	ngOnChanges(changes: SimpleChanges) { 		
	    var self = this;
	    setTimeout(()=>{
	    	self.setData(changes);
	    },300);
  	}

  	setData(changes:any){
  		let latData = changes.lat;
	    let longData = changes.lng;
	    if(!latData.firstChange && !longData.firstChange) {
		    this.dir = {
		        origin: { lat: this.shop_lat, lng: this.shop_lng },
		        destination: { lat: latData.currentValue, lng: longData.currentValue }
		    }
		    this.coordinates = [{latitude: this.shop_lat, longitude: this.shop_lng}, 
									 {latitude: latData.currentValue, longitude: longData.currentValue}];
		} else {
			this.coordinates = [{latitude: this.shop_lat, longitude: this.shop_lng}]; 
		}
  	}

  	ngOnInit() {
  		let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
	    this.shop_lng = (shop_config.shop_info && shop_config.shop_info.longitude)?  shop_config.shop_info.longitude : undefined;
	    this.shop_lat = (shop_config.shop_info && shop_config.shop_info.latitude)?  shop_config.shop_info.latitude : undefined;
	    this.shop_lat = (typeof this.shop_lat == 'string')? parseFloat(this.shop_lat) : this.shop_lat;
	    this.shop_lng = (typeof this.shop_lng == 'string')? parseFloat(this.shop_lng) : this.shop_lng;
	}

}
