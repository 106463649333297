import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.css']
})
export class InputCheckboxComponent implements OnInit {

	@Input('label') label: string;
  @Input('value') value: boolean;
	@Input('group') group: boolean;
  @Input('disabled') disabled: boolean;

	@Output() sendValue = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  sendData() {
  	this.sendValue.emit(this.value);
  }

}
