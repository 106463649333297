import { Component, OnInit } from "@angular/core";
import { payment_types } from "./register-info";
import { CashRegisterService } from "../../helpers/services/cash-register.service";
import { ConfirmationModal } from "../../confirmation-modal/confirmation-modal.component";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DemoModalServiceStaticComponent } from "../../demo-modal-service-static/demo-modal-service-static.component";

@Component({
  selector: "app-open-close",
  templateUrl: "./open-close.component.html",
  styleUrls: ["./open-close.component.less"],
  providers: [DemoModalServiceStaticComponent],
})
export class OpenCloseComponent implements OnInit {
  loggedInUser: any;
  body = document.getElementsByTagName("body")[0];

  title: string = "Last Register Closure Summary";
  opening_float_amount: number;

  type: string;
  open_view: boolean[] = [];
  actual_cash: number;
  currencySymbol: any;
  notes: any;
  public modalRef: BsModalRef;
  public subscriptions: Subscription[] = [];
  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  open_register: boolean;
  difference: any;
  total_expected: any;
  isClosureSummaryShow: boolean;
  isLastClosureSummaryFound: boolean;
  current_info: any = {};
  //current_info:any = payment_types;

  constructor(
    private cashRegisterService: CashRegisterService,
    private messageService: MessageService,
    private router: Router,
    private DemoModalServiceStaticComponent: DemoModalServiceStaticComponent,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    this.body.setAttribute("class", "scrolling-view");
    let shop_config = JSON.parse(localStorage.getItem("shop_configuration"));
    if (!shop_config) {
      this.router.navigate(["verify-pin"]);
      return;
    }
    if (
      localStorage.getItem("isCashRegister") &&
      localStorage.getItem("isCashRegister") == "true"
    ) {
      this.open_register = true;
    }
    this.type = this.route.snapshot.params.type || null;
    this.currencySymbol = shop_config.currency;
    this.checkForClosureSummaryInfo();
  }

  checkForClosureSummaryInfo() {
    this.isClosureSummaryShow = false;

    let all_cash_register_sessions =
      JSON.parse(localStorage.getItem("all_cash_register_sessions")) || [];
    if (all_cash_register_sessions.length > 0) {
      this.isLastClosureSummaryFound = true;
    } else {
      this.isLastClosureSummaryFound = false;
    }

    if (this.open_register) {
      let cash_register = JSON.parse(localStorage.getItem("cash_register"));
      if (cash_register && cash_register[0]) {
        this.current_info = cash_register[0];
        let len = all_cash_register_sessions.length + 1;
        this.current_info.start_cash_in["closure"] = len;
        let paid_in_out_list =
          JSON.parse(localStorage.getItem("paid_in_out_list")) || [];
        this.current_info["paid_in_out_list"] = paid_in_out_list;

        this.isClosureSummaryShow = true;
      }
    } else if (this.isLastClosureSummaryFound) {
      let len = all_cash_register_sessions.length;
      this.current_info = all_cash_register_sessions[len - 1];
      this.current_info.start_cash_in["closure"] = len;
      //let paid_in_out_list = JSON.parse(localStorage.getItem('paid_in_out_list')) || [];
      //this.current_info['paid_in_out_list'] = paid_in_out_list;
      this.isClosureSummaryShow = true;
    }
  }

  getTotalCashExpected(cash_sale_amount, paid_list, cash_return, cash_advance) {
    let total = cash_sale_amount + cash_advance - cash_return;
    if (paid_list && paid_list[0]) {
      paid_list.forEach((info) => {
        total += info.transaction;
      });
    }
    this.total_expected = total;
    return total;
  }

  getCashDifference(cash_sale_amount, paid_list, cash_return, cash_advance) {
    let totalExpected = this.getTotalCashExpected(
      cash_sale_amount,
      paid_list,
      cash_return,
      cash_advance
    );
    let actual_amount = this.actual_cash ? this.actual_cash : 0;
    this.difference = actual_amount - totalExpected;
    return actual_amount - totalExpected;
  }

  closeRegisterModal() {
    if (!this.actual_cash && this.actual_cash != 0) {
      this.messageService.add({
        severity: "error",
        summary: "First enter valid amount",
        detail: "",
      });
      return;
    }
    this.modalRef = this.modalService.show(ConfirmationModal, {
      class: "centred-modal",
    });
    this.modalRef.content.decision = "";
    this.modalRef.content.text = "Are You Sure Want To Close Cash Register?";
    this.subscriptions.push(
      this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === "yes") {
          this.closeRegister();
        }
        this.unsubscribe();
      })
    );
  }

  chnageDateFormat(date) {
    let hour = date.getHours();
    let minute = date.getMinutes();
    let sec = date.getSeconds();
    if (hour.toString().length == 1) {
      hour = "0" + hour;
    }
    if (minute.toString().length == 1) {
      minute = "0" + minute;
    }
    if (sec.toString().length == 1) {
      sec = "0" + sec;
    }

    let newTime = hour + ":" + minute + ":" + sec;

    var mm: any = new Date(date.toDateString()).getMonth();
    mm = mm + 1;
    if (mm.toString().length === 1) {
      mm = "0" + mm;
    }
    var dd: any = new Date(date.toDateString()).getDate();
    var yy: any = new Date(date.toDateString()).getFullYear();
    if (dd.toString().length === 1) {
      dd = "0" + dd;
    }

    let newDate = yy + "-" + mm + "-" + dd;
    return newDate + " " + newTime;
  }

  closeRegister() {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    let device_info = JSON.parse(localStorage.getItem("device_key")) || null;
    /*let sData = {
      "denomination": "",
      "is_closing": true,
      "is_opening": false,
      "remark": "",
      "shift": "",
      "total_cash": this.actual_cash? this.actual_cash : 0.0,
      "user_id": loggedInUser.id,
      "terminal_id":(device_info && device_info.terminal_id) ? device_info.terminal_id : null,
      "shop_code": localStorage.getItem('shop_code')
    }
    this.cashRegisterService.cashOut(sData).subscribe((res) => {
      let resData = res.json();
      if(resData.status === 0){*/

    let temp_actual_cash: any = this.actual_cash;
    let cash_register = JSON.parse(localStorage.getItem("cash_register")) || [];
    let all_cash_register_sessions =
      JSON.parse(localStorage.getItem("all_cash_register_sessions")) || [];

    if (cash_register && cash_register[0]) {
      cash_register[0].start_cash_in["closedAt"] = new Date();

      let paid_in_out_list =
        JSON.parse(localStorage.getItem("paid_in_out_list")) || [];
      cash_register[0]["paid_in_out_list"] = paid_in_out_list;
      cash_register[0]["actual_cash"] = this.actual_cash ? this.actual_cash : 0;
      cash_register[0]["difference"] = this.difference ? this.difference : 0;
      cash_register[0]["total_expected"] = this.total_expected
        ? this.total_expected
        : 0;
      all_cash_register_sessions.push(cash_register[0]);
    }
    let startDate = new Date(cash_register[0].start_cash_in.createdAt);
    let endDate = new Date(cash_register[0].start_cash_in.closedAt);

    localStorage.setItem("cash_register", "[]");
    localStorage.setItem("paid_in_out_list", "[]");
    localStorage.setItem("isCashRegister", "false");
    localStorage.setItem(
      "all_cash_register_sessions",
      JSON.stringify(all_cash_register_sessions)
    );

    this.actual_cash = undefined;
    this.open_register = false;
    this.opening_float_amount = undefined;
    this.notes = "";
    var self = this;
    setTimeout(() => {
      self.checkForClosureSummaryInfo();
    }, 100);
    this.generateReport(startDate, endDate, temp_actual_cash);
    if (this.type == "closeShop") {
      this.saveStockReport();
      var local_config =
        JSON.parse(localStorage.getItem("local_configuration")) || {};
      if (!local_config.disable_reset_bill_number) {
        let date = new Date();
        var dd = date.getDate() + "";
        var mm = date.getMonth() + 1 + "";
        var yyyy = date.getFullYear();
        if (dd.length == 1) {
          dd = "0" + dd;
        }
        if (mm.length == 1) {
          mm = "0" + mm;
        }
        let str = yyyy + "/" + mm + "/" + dd;
        localStorage.setItem("ref_bill_num", "-1");
        localStorage.setItem("ref_bill_update_date", date.toISOString());
        localStorage.setItem("kot_number", "1");
        localStorage.setItem("check_print_number", "1");
      }

      localStorage.setItem("temp_orders_list", "[]");
      this.router.navigate(["verify-pin"]);
    } else if (this.type == "logout") {
      this.DemoModalServiceStaticComponent.logout();
    }
    /*}
    }, err =>{
        this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: '' });
    });*/
  }

  generateReport(sDate, eDate, actual_cash) {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser")) || {};
    let device_info = JSON.parse(localStorage.getItem("device_key")) || null;
    let sData = {
      end_date: eDate,
      start_date: sDate,
      user_id: loggedInUser.id,
      terminal_id:
        device_info && device_info.terminal_id ? device_info.terminal_id : null,
      cash_sales: this.current_info.cash_sale || 0,
      shop_code: localStorage.getItem("shop_code"),
    };
    this.cashRegisterService.generateReport(sData).subscribe(
      (res) => {
        let resData = res.json();
        if (resData.status === 0) {
          var report_id =
            resData.result && resData.result.summary
              ? resData.result.summary.id
              : null;
          this.saveReport(sDate, eDate, report_id, actual_cash);
        }
      },
      (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Something went wrong",
          detail: "",
        });
      }
    );
  }

  saveReport(sDate, eDate, report_id, actual_amount) {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser")) || {};
    let device_info = JSON.parse(localStorage.getItem("device_key")) || null;

    let sData = {
      end_date: eDate,
      start_date: sDate,
      report_id: report_id,
      user_id: loggedInUser.id,
      actual_amount: actual_amount,
      terminal_id:
        device_info && device_info.terminal_id ? device_info.terminal_id : null,
      shop_code: localStorage.getItem("shop_code"),
    };
    this.cashRegisterService.saveReport(sData).subscribe(
      (res) => {
        let resData = res.json();
        if (resData.status === 0) {
          //console.log(" saveReport",resData);
        }
      },
      (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Something went wrong",
          detail: "",
        });
      }
    );
  }

  saveStockReport() {
    let sData = {
      shop_code: localStorage.getItem("shop_code"),
    };
    this.cashRegisterService.saveStockReport(sData).subscribe(
      (res) => {
        let resData = res.json();
        if (resData.status === 0) {
        }
      },
      (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Something went wrong while save stock report.",
          detail: "",
        });
      }
    );
  }

  openRegister() {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser")) || {};
    let shopInfo = JSON.parse(localStorage.getItem("shop_configuration")) || {};
    let device_info = JSON.parse(localStorage.getItem("device_key")) || {};
    let sData = {
      denomination: "",
      is_closing: false,
      is_opening: true,
      remark: this.notes,
      shift: "",
      total_cash: this.opening_float_amount ? this.opening_float_amount : 0,
      user_id: loggedInUser.id,
      terminal_id:
        device_info && device_info.terminal_id ? device_info.terminal_id : null,
      shop_code: localStorage.getItem("shop_code"),
    };
    this.cashRegisterService.cashIn(sData).subscribe(
      (res) => {
        let resData = res.json();
        if (resData.status === 0) {
          localStorage.setItem("isCashRegister", "true");
          let cash_register_info = {
            start_cash_in: {
              createdAt: new Date(),
              shop_name: shopInfo.shop_info.name,
              terminal_name: device_info.terminal_name,
              cash_in_amount: this.opening_float_amount
                ? this.opening_float_amount
                : 0,
            },
            type: "Cash",
            cash_sale: 0,
            cash_advance: 0,
            cash_return: 0,
            paid_in: 0,
            paid_out: 0,
          };

          let cash_register = [];
          cash_register.push(cash_register_info);
          localStorage.setItem("cash_register", JSON.stringify(cash_register));
          this.open_register = true;
          var self = this;
          setTimeout(() => {
            self.checkForClosureSummaryInfo();
          }, 100);

          let setData = {
            email: loggedInUser.email,
            id: loggedInUser.id,
            notes: this.notes,
            reason: "Floating amount",
            time: new Date(),
            transaction: this.opening_float_amount
              ? this.opening_float_amount
              : 0,
            user: loggedInUser.name,
          };
          let paid_in_out_list = [];
          paid_in_out_list.push(setData);
          localStorage.setItem(
            "paid_in_out_list",
            JSON.stringify(paid_in_out_list)
          );

          let open_register_update_date = localStorage.getItem(
            "open_register_update_date"
          );
          if (open_register_update_date) {
            let lastUpdateDate = new Date(open_register_update_date);
            let date = new Date();
            var dd = date.getDate() + "";
            var mm = date.getMonth() + 1 + "";
            var yyyy = date.getFullYear();
            if (dd.length == 1) {
              dd = "0" + dd;
            }
            if (mm.length == 1) {
              mm = "0" + mm;
            }
            let str = yyyy + "/" + mm + "/" + dd;
            let curr_date = new Date(str);

            if (lastUpdateDate < curr_date) {
              localStorage.setItem("all_cash_register_sessions", "[]");
            }
          }
          var currDate = new Date();
          localStorage.setItem(
            "open_register_update_date",
            currDate.toISOString()
          );
        }
      },
      (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Something went wrong",
          detail: "",
        });
      }
    );
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
