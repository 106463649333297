import { Component, OnInit, Output, EventEmitter,ViewChild,ElementRef } from '@angular/core';
import { BasketComponent } from '../basket/basket.component';
import { Dish } from '../dish';
import { UpdateInventoryMessage } from '../../pages/update-inventory-message.service';
import { CommonService } from '../../pages/helpers/services/common.service';
import { Messenger } from '../messenger.service';
import { Subscription } from 'rxjs';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { OffersModalComponent } from '../offers-modal/offers-modal.component';
import { LicenseRenewModalComponent } from '../license-renew-modal/license-renew-modal.component';
import { StateGuardService } from '../state-guard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { APIHelper } from '../helpers/api-helper';
import { BaseConfig } from '../helpers/api-config';
import { ShopCategoriesService } from '../helpers/services/shop-categories.service';
import { ShopCategoriesItemsService } from '../helpers/services/shop-categories-items.service';
import { GeneralHelper } from '../helpers/genral-helper';
import { CurrentMenuItemsMessage } from '../menu-current-items-message.service';
import { MessageService } from 'primeng/api';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
/*import { RecommenderService } from '../../pages/helpers/services/recommender.service';*/
import { LooseItemSelectionModalComponent } from '../loose-item-selection-modal/loose-item-selection-modal.component';
import { forEach,keys,remove,groupBy,uniqBy,forOwn,difference } from 'lodash-es';

declare var $:any

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})

export class MenuComponent implements OnInit {
  @Output() addingDish: EventEmitter<Dish> = new EventEmitter();
  @ViewChild('autoCompleteEl',{static: false}) autoCompleteEl: any;

  currentUnit;
  displayAutocomplete: boolean = false;
  isResetAutocomplete: boolean = false;
  isDataAvailable: boolean = false;
  isOnlyModifiers: boolean = false;
  isQuantityInputFieldShow: boolean = false;
  isMenuItemImageViewDisabled = false;
  public searchItemQuantity: any;
  public selectedItem: any;
  public isSearchItemQuantityEnable: boolean = false;
  public subscriptions: Subscription[] = [];
  public autoCompleteSelectedData:any;
  public currencySymbol:any;
  public modalRef: BsModalRef;
  public width:string= "85";
  private subscription: Subscription;
  private currentCategory: string = '';
  public loading = false;
  private currentItems: any = [];
  private currentTempItems: any = [];
  private isInSubCat: boolean = false;
  private copyOfCurrentItems: any = [];
  recommenderVisible: boolean = false;
  recommenderExpanded: boolean = true;
  recommendedItemList = []
  recommendedItemCodes = []
  // recommendedItemCodes = ['IT61013','IT61045','IT61057','IT61077','IT61110']
  // recommendedItemCodes = ['IT137220','IT137221','IT137219','IT137218','IT132392']
 // recommendedItemCodes = ['IT134990','IT134991','IT134992','IT134993','IT135139']
  moveNamesList = false;  
  itemSearchFiled: any = [
    {'field':'item_name','match':'contain','type':'string'},
    {'field':'bar_code','match':'exact','type':'number'},
    {'field':'short_key','match':'exact','type':'number'}
  ]
  
  constructor(private messenger: Messenger,
    private modalService: BsModalService,
    private stateGuard: StateGuardService,
    private currentMenuItemsMessage:CurrentMenuItemsMessage,
    private route: ActivatedRoute,
    private updateInventoryMessage:UpdateInventoryMessage,
    private router: Router,
    private basketComponent:BasketComponent,
    private commonService: CommonService,
    private shopCategoryService: ShopCategoriesService,
    private shopCategoryItemService: ShopCategoriesItemsService,
    private messageService: MessageService,
    /*private recommenderService: RecommenderService*/) {

  }

  ngAfterViewInit() {    
    if(this.autoCompleteEl?.autoCompleteObject?.inputEL?.nativeElement) {
      this.autoCompleteEl.autoCompleteObject.inputEL.nativeElement.focus();
    }
  }

  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  fetchCurrentCategory(){
    return new Promise((resolve,reject) => {
      let currentCategoryInfo: any = JSON.parse(localStorage.getItem("current_category_info"));
      this.fetchItems(currentCategoryInfo.category_code, currentCategoryInfo.category_name,false,null).then(fiR => { 
        return resolve(fiR)
      });
    })
  }

  showOffersModal(dish) {
    if (!dish.offer) { return; }
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'done') {

      }
      this.unsubscribe();
    }));

    this.modalRef = this.modalService.show(OffersModalComponent, { class: 'offers-modal common-modal' });
    this.modalRef.content.decision = "";
  }
  reactOnSearchChange(event, searchField) {
    event.preventDefault();
    this.displayAutocomplete = !!searchField.value;
    this.messenger.sendMessage(searchField.value);
  }

  countGrabbed(dish) {
    var counter = 0;
    if (this.currentUnit.visitors !== undefined) {
      this.currentUnit.visitors.forEach(visitor => {
        visitor.dishes.forEach(course => {
          course.forEach(visitorsDish => {
            if(visitorsDish.item_code == dish.item_code)  {
              if(visitorsDish.selected_variant_item) {
                dish.item_list.forEach(variant_item => {
                  if(variant_item.item_code == visitorsDish.selected_variant_item.item_code) {
                    counter += visitorsDish.selected_variant_item.item_count;
                  }
                })
              } else {
                counter += visitorsDish.item_count;
              }
            }
          });
        });
      });
    }
    return counter;
  }
  clearMessage(): void {
    this.messenger.clearMessage();
  }

  showKeyboard: boolean = false;
  currentDishesGroup;
  dishesModel:any = [];
  targetVisitor;
  searchParam = { text: "" }
  menuChapters = [];
  selectedDish: any;
  copyOfSelectedDish:Dish;
  has_variants: boolean;
  has_batch_serial_number_itm: boolean;
  is_membership_item: boolean;
  is_multi_group_combo_item: boolean = false;

  addDish(dish: any) {
    this.moveNamesList = true;
    var scope = this

    setTimeout(function(){
      scope.moveNamesList = false;
    },500)

    this.isResetAutocomplete = false;
    this.isOnlyModifiers = false;
    var isBatchSerialNumberItemsAvailable = this.checkBatchSerialNumberItemsAvailable(dish);    
    if(dish.is_membership && (dish.is_group_allowed || dish.fee_type == "SUBSCRIPTION")) {
      this.selectedDish = JSON.parse(JSON.stringify(dish));
      var temp_a = [];
      this.selectedDish.maximum_user_in_group = (this.selectedDish.maximum_user_in_group) ? this.selectedDish.maximum_user_in_group : 0;
      for(var i=0;i<this.selectedDish.maximum_user_in_group;i++) {temp_a.push({"phone":"","name":""})}
      this.selectedDish.secondary_user_phone_number = temp_a;
      this.is_membership_item = true;
    } else if(dish.is_multi_group_combo) {
      if(!dish.multi_group_combo || !Array.isArray(dish.multi_group_combo.main_group) || dish.multi_group_combo.main_group.length == 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "data missing for combo multi group item" });
        return;
      } else {
        this.selectedDish = JSON.parse(JSON.stringify(dish));
        this.is_multi_group_combo_item = true;
      }
    } else if (!dish.item_list || dish.item_list.length == 0) {
      if(dish.can_be_sold_loose) {
        this.getLooseItemQuantity(dish).then((result:any) => { // result = {selectedLooseItem:{name:'',portion:0.1},selectedModifiers:[]}
          let newInputQuantity:any = dish.item_count || 1;          
          if(result) {
            if(result["selectedLooseItem"] && result["selectedLooseItem"]["portion"]>0) {
              newInputQuantity = result["selectedLooseItem"]["portion"];              
              dish.loose_item_name = result["selectedLooseItem"].name || "";
            } else {
              dish.item_count = parseFloat(newInputQuantity);
              dish.tempData.temp_item_count = parseFloat(newInputQuantity);  
            }

            if(result["selectedModifiers"]) {
              dish.attributes = result["selectedModifiers"];
              dish.isOnlyModifier = true;
            }
          }
          dish.item_count = parseFloat(newInputQuantity);
          dish.tempData.temp_item_count = parseFloat(newInputQuantity);
          this.addingDish.emit(dish);
          this.reactOnKeyboardAction("");
        })
      } else if(dish.modifier_attributes){
        this.isOnlyModifiers = true;
        this.selectedDish = JSON.parse(JSON.stringify(dish));
        this.has_variants = true;
      } else if (isBatchSerialNumberItemsAvailable){
        this.selectedDish = JSON.parse(JSON.stringify(dish));
        if(this.selectedDish.barcode_data && this.selectedDish.barcode_data.batch &&
          Array.isArray(this.selectedDish.batch_serial_stock_info) &&
          this.selectedDish.batch_serial_stock_info.length>0) {
          let findB = this.selectedDish.batch_serial_stock_info.find((s:any) => s.tracking_number == this.selectedDish.barcode_data.batch);
          if(findB) {
            this.selectedDish.tracking_number = findB.tracking_number;
            this.selectedDish.tracking_mode = findB.tracking_mode;

            return this.addDishWithBatchSerialNumberItem(this.selectedDish);
          }
        }
        this.has_batch_serial_number_itm = true;
      } else {
        //this.addingDish.emit(JSON.parse(JSON.stringify(dish)));
        this.getLooseItemQuantity(dish).then((result:any) => {
          let newInputQuantity:any = dish.item_count || 1;          
          if(result) {
            if(result["selectedLooseItem"] && result["selectedLooseItem"]["portion"]>0) {
              newInputQuantity = result["selectedLooseItem"]["portion"];              
              dish.loose_item_name = result["selectedLooseItem"].name || "";              
            } else {
              dish.item_count = parseFloat(newInputQuantity);
              dish.tempData.temp_item_count = parseFloat(newInputQuantity);  
            }

            if(result["selectedModifiers"]) {
              dish.attributes = result["selectedModifiers"];
              dish.isOnlyModifier = true;
            }
          }
          dish.item_count = parseFloat(newInputQuantity);
          dish.tempData.temp_item_count = parseFloat(newInputQuantity);

          this.addingDish.emit(dish);
          this.reactOnKeyboardAction("");    
        })
      }
    } else if(dish.item_list && (dish.item_list.length > 0) && JSON.stringify(dish.variant_attributes) =='{}'){
      alert("variant_attributes can't be empty");
    } else if(dish.item_list && (dish.item_list.length > 0)){
        delete dish.selected_variant_item; 
        this.selectedDish = JSON.parse(JSON.stringify(dish));
        this.has_variants = true;
    } else if (isBatchSerialNumberItemsAvailable){
      this.selectedDish = JSON.parse(JSON.stringify(dish));
      if(this.selectedDish.barcode_data && this.selectedDish.barcode_data.batch &&
        Array.isArray(this.selectedDish.batch_serial_stock_info) &&
        this.selectedDish.batch_serial_stock_info.length>0) {
        let findB = this.selectedDish.batch_serial_stock_info.find((s:any) => s.tracking_number == this.selectedDish.barcode_data.batch);
        if(findB) {
          this.selectedDish.tracking_number = findB.tracking_number;
          this.selectedDish.tracking_mode = findB.tracking_mode;

          return this.addDishWithBatchSerialNumberItem(this.selectedDish);
        }
      }
      this.has_batch_serial_number_itm = true;
    }
    // if(localStorage.getItem('isRestaurant') && !eval(localStorage.getItem('isRestaurant'))){
      if(this.recommenderVisible){
        var scope = this
        /*this.recommenderService.predict().then((predictedItemCodes) => {
          scope.recommendedItemCodes = JSON.parse(JSON.stringify(predictedItemCodes));
          if(predictedItemCodes.length != 0){
            scope.addItemToRecommendationList(scope.recommendedItemCodes)
          }
        })*/
      }
    // }

  }

  checkBatchSerialNumberItemsAvailable(item) {
    return (item.batch_serial_stock_info && (Array.isArray(item.batch_serial_stock_info) && item.batch_serial_stock_info.length>0));
  }

  ngOnInit() {
    if (!APIHelper.getInstance(null).isLicenceVerified()) {
      this.router.navigate(['']);
      return;
    }

    let isSearchItemQuantityEnable = localStorage.getItem("isSearchItemQuantityEnable") == "true" ? true : false;
    this.changeIsSearchItemQuantityEnable(!isSearchItemQuantityEnable);

    try {
      let mssl = localStorage.getItem("menu_smart_search_list");
      mssl = JSON.parse(mssl);
      if(mssl && mssl.length>0) {
        this.itemSearchFiled = mssl;
      }
    } catch(e) {
      console.log(e);
    }

    let local_configuration = JSON.parse(localStorage.getItem("local_configuration")) || null;
    if(local_configuration && local_configuration.disable_menu_item_with_image_view == true) {
      this.isMenuItemImageViewDisabled = true;
    } else {
      this.isMenuItemImageViewDisabled = false;
    }

    var self = this;
    if(localStorage.getItem('is_page_refresh') == 'true'){
      setTimeout(()=>{
        self.getInitialData();
      },1000);
      setTimeout(()=>{
        localStorage.setItem('is_page_refresh','false');
      },1500);
    }else{
      self.getInitialData();
    }

    this.updateInventoryMessage.getUpdateAvailableInfo().subscribe(message => {
      if(message.is_item_reload) {                
        this.setMultiGroupComboItemsInfo().then(re => {
          this.loadItemsForSearch(true).then(result =>{          
            this.fetchCurrentCategory().then(fccR => {});            
          });
        });        
      }
    });

    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    
    if(shop_config && shop_config.shop_info && shop_config.shop_info.enable_recommendations){
      this.recommenderVisible = true;
    }else{
      this.recommenderVisible = false;
    }
    this.checkLocalStorage();
    // this.addItemToRecommendationList(this.recommendedItemCodes);
  }
  onClickBackBtn() {
    // this.currentItems = this.currentTempItems;
    this.currentItems[0] = {...this.currentTempItems[0]};
    this.isInSubCat = false;
  }
  onClickSubCategory(subCat) {
    this.currentTempItems[0] = {...this.currentItems[0]};
    this.isInSubCat = true;
    this.currentItems[0].dishes = subCat.items;
    this.currentItems[0].subCats = [];
  }

  getInitialData(){
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shop_config.currency+ ((["QAR","QR"].indexOf(shop_config.currency) != -1) ? " " : "");
    this.isDataAvailable = true;
    if(this.currentUnit && this.currentUnit.activePhase && this.currentUnit.activePhase.timeStamp) {
      if(!this.currentUnit.activePhase.timeStamp) {  this.currentUnit.activePhase.timeStamp = new Date().valueOf(); }
    }

    this.subscription = this.currentMenuItemsMessage.getCurrentMenuItems().subscribe(message => {
      this.currentItems = JSON.parse(JSON.stringify(message.CurrentMenuItems));
      let _currentItems = {};
      let _subCats = [];
      let _dishes = [];
      _currentItems["name"] = this.currentItems[0].name;
      this.currentItems[0].dishes.forEach(item => {
        if(item.modifier_attributes) {  //if it's an variant item
          if(item.item_list[0] && !!item.item_list[0]?.sub_category_code) { // if this modifier&variant item is in subcategory
            let index = _subCats.findIndex(cat => cat.sub_category_code === item.item_list[0].sub_category_code);
            if(index === -1) {
              _subCats.push({
                sub_category_code: item.item_list[0].sub_category_code,
                sub_category_name: item.item_list[0].sub_category_name,
                sub_category_img_url: item.item_list[0].sub_category_img_url,
                items: [item]
              })
            } else {
              _subCats[index].items.push(item);
            }
          } else {
            _dishes.push(item);
          }
        } else if(item && !!item?.sub_category_code) { //if item is in subcategory
          let index = _subCats.findIndex(cat => cat.sub_category_code === item.sub_category_code);
          if(index === -1) {
            _subCats.push({
              sub_category_code: item.sub_category_code,
              sub_category_name: item.sub_category_name,
              sub_category_img_url: item.sub_category_img_url,
              items: [item]
            })
          } else {
            _subCats[index].items.push(item);
          }
        } else {  //if item is in category
          _dishes.push(item);
        }
      })
      _currentItems["subCats"] = _subCats;
      _currentItems["dishes"] = _dishes;
      this.currentItems[0] = _currentItems;
    });

    if(navigator.onLine) {
      this.checkForLicenseRenewal();
      this.getVersionInfo();
    }
    this.loadCategories();

  }

  getVersionInfo(){
    let sendData = {
      'shop_code':localStorage.getItem('shop_code')
    }
    this.commonService.getCurrentVersionInfo(sendData).subscribe((res) => {
        var apiRes = res.json();
        if (apiRes.status === 0) {
          let version = apiRes.inventory_info.version_number;
          if(localStorage.getItem('current_version_no')){
            let current_version = (typeof localStorage.getItem('current_version_no') == 'string')?  parseInt(localStorage.getItem('current_version_no')) : localStorage.getItem('current_version_no');
            if(current_version < version){
              this.updateInventoryMessage.sendUpdateAvailableInfo(true,false);
            }else{
              this.updateInventoryMessage.sendUpdateAvailableInfo(false,false);
            }
          }else{
            localStorage.setItem('current_version_no',version);
          }
        } else {
            this.updateInventoryMessage.sendUpdateAvailableInfo(false,false);
        }
    });
  }


  checkForLicenseRenewal(){
    let license_renew_update_date = localStorage.getItem('license_renew_update_date');
    if(license_renew_update_date){
      let lastUpdateDate = new Date(license_renew_update_date);
      let date = new Date()
      var dd   = date.getDate()+'';
      var mm   = (date.getMonth()+1)+'';
      var yyyy = date.getFullYear();
      if(dd.length == 1)  { dd='0'+dd }
      if(mm.length == 1)  { mm='0'+mm }
      let str = yyyy+'/'+mm+'/'+dd;
      let curr_date = new Date(str);

      if(lastUpdateDate < curr_date){
        this.validateLicense();
        this.getRecommendationModel();
      }
    }
  }

  validateLicense(){
      let shop_code= localStorage.getItem('shop_code');
      this.commonService.validateLicense(shop_code).subscribe( (res) => {
        let result = res.json();

        var currDate = new Date();
        localStorage.setItem('license_renew_update_date',currDate.toISOString());
        if (result.status === 0 && result.result.merchant_licence[0]) {
          let remainingDays = result.result.merchant_licence[0].remainingDays + 1;          
          let month_wise_subscription_value = result.result.merchant_licence[0].month_wise_subscription_value;
          let annual_subscription_value = result.result.merchant_licence[0].annual_subscription_value;
          let monthly_subscription_value = result.result.merchant_licence[0].monthly_subscription_value;
         
          if(remainingDays <= 7){
            this.showModalForLicenseRenew({days:remainingDays,month_wise_subscription_value:month_wise_subscription_value,annual_subscription_value:annual_subscription_value,monthly_subscription_value:monthly_subscription_value});
          }
        }
      });
  }

  checkLocalStorage(){
    if(GeneralHelper.isStorageFull()){
      this.modalRef = this.modalService.show(ConfirmationModal, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.type = "only-yes";
      this.modalRef.content.text = "You need to clear Your Completed order Storage to Place a new Order. Are you ready?";
      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'yes') {
          localStorage.setItem("temp_orders_list","[]");
        }
        this.unsubscribe();
      }));
    }
  }
  getRecommendationModel(){
    /*this.recommenderService.getmodel();*/
  }

  showModalForLicenseRenew(data){
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'skip') {
      }else if(this.modalRef.content.decision === 'yes'){
        if(this.modalRef.content.remainingDays <= 0){
          this.router.navigate(['']);
        }        
      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(LicenseRenewModalComponent, {class: 'common-modal',keyboard: false,
        backdrop: true,ignoreBackdropClick: true});
    this.modalRef.content.decision = "";
    this.modalRef.content.remainingDays = data.days;
    this.modalRef.content.month_wise_subscription_value = data.month_wise_subscription_value;
    this.modalRef.content.annual_subscription_value = data.annual_subscription_value;
    this.modalRef.content.monthly_subscription_value = data.monthly_subscription_value;

  }

  changeIsSearchItemQuantityEnable(flag){

    if(!flag){
      this.width='70';
      this.isQuantityInputFieldShow = true;
      this.isSearchItemQuantityEnable = true;
      localStorage.setItem("isSearchItemQuantityEnable","true");
      //$('#keyboardDiv').css({'margin-left':'10%'})
    }else{
      this.width='85';
      this.isQuantityInputFieldShow = false;
      this.isSearchItemQuantityEnable = false;
      $('#keyboardDiv').css({'margin-left':'0%'})
      localStorage.setItem("isSearchItemQuantityEnable","false");
    }

  }

  addDishWithMultiGroupCombo(event) {
    this.selectedDish = event;    
    if(this.selectedDish.barcode_data && !isNaN(this.selectedDish.barcode_data.count)) {
      this.selectedDish.item_count = this.selectedDish.barcode_data.count;
    }

    if(this.selectedDish.tempData){
      this.selectedDish.tempData.temp_item_count =  this.selectedDish.item_count ? parseFloat(this.selectedDish.item_count) : 1
      this.selectedDish.tempData.temp_unit_price = (typeof this.selectedDish.unit_price == 'string') ? parseFloat(this.selectedDish.unit_price) : this.selectedDish.unit_price;
      this.selectedDish.tempData.unit_price = (typeof this.selectedDish.unit_price == 'string') ? parseFloat(this.selectedDish.unit_price) : this.selectedDish.unit_price;
    }
    
    this.addingDish.emit(this.selectedDish);
    this.is_multi_group_combo_item = false;
    this.isResetAutocomplete=true;
    this.reactOnKeyboardAction("");

  }

  addDishWithBatchSerialNumberItem(event) {
    this.selectedDish = event;
    if(this.selectedDish.selected_variant_item) {
      if(this.selectedDish.tempData && this.selectedDish.selected_variant_item){
        this.selectedDish.tempData.temp_item_count = (typeof this.selectedDish.selected_variant_item.item_count == 'string') ? parseFloat(this.selectedDish.selected_variant_item.item_count) : this.selectedDish.selected_variant_item.item_count;
        this.selectedDish.tempData.temp_unit_price = (typeof this.selectedDish.selected_variant_item.unit_price == 'string') ? parseFloat(this.selectedDish.selected_variant_item.unit_price) : this.selectedDish.selected_variant_item.unit_price;
        this.selectedDish.tempData.unit_price = (typeof this.selectedDish.selected_variant_item.unit_price == 'string') ? parseFloat(this.selectedDish.selected_variant_item.unit_price) : this.selectedDish.selected_variant_item.unit_price;
      }
    } else {
      if(this.selectedDish.tempData){
        this.selectedDish.tempData.temp_item_count = parseFloat(this.selectedDish.item_count) || 1;//(typeof this.selectedDish.item_count == 'string') ? parseFloat(this.selectedDish.item_count) : this.selectedDish.item_count;
        this.selectedDish.tempData.temp_unit_price = (typeof this.selectedDish.unit_price == 'string') ? parseFloat(this.selectedDish.unit_price) : this.selectedDish.unit_price;
        this.selectedDish.tempData.unit_price = (typeof this.selectedDish.unit_price == 'string') ? parseFloat(this.selectedDish.unit_price) : this.selectedDish.unit_price;
      }
    }

    this.addingDish.emit(this.selectedDish);
    this.has_batch_serial_number_itm = false;
    this.isResetAutocomplete=true;
    this.reactOnKeyboardAction("");

  }

  addDishWithVariants(event) {
    var tempItem = (this.selectedDish.selected_variant_item) ? this.selectedDish.selected_variant_item : this.selectedDish;
    var isBatchSerialNumberItemsAvailable = this.checkBatchSerialNumberItemsAvailable(tempItem);
    if (isBatchSerialNumberItemsAvailable){
        if(this.isOnlyModifiers){
          this.selectedDish['isOnlyModifier'] = true;
        }
        this.has_variants = false;
        if(this.selectedDish.barcode_data && this.selectedDish.barcode_data.batch &&
          Array.isArray(tempItem.batch_serial_stock_info) &&
          tempItem.batch_serial_stock_info.length>0) {
          let findB = tempItem.batch_serial_stock_info.find((s:any) => s.tracking_number == this.selectedDish.barcode_data.batch);
          if(findB) {
            tempItem.tracking_number = findB.tracking_number;
            tempItem.tracking_mode = findB.tracking_mode;
            tempItem.item_count = (this.selectedDish.barcode_data && this.selectedDish.barcode_data.count) || tempItem.item_count;
            if(this.selectedDish.selected_variant_item) {
              this.selectedDish.selected_variant_item = tempItem;
            } else {
              this.selectedDish = tempItem;
            }

            return this.addDishWithBatchSerialNumberItem(this.selectedDish);
          }
        }

        this.has_batch_serial_number_itm = true;
    } else {
      //this.addingDish.emit(JSON.parse(JSON.stringify(this.selectedDish)));
      if(this.selectedDish.tempData && this.selectedDish.selected_variant_item){
        this.selectedDish.tempData.temp_item_count = (typeof this.selectedDish.selected_variant_item.item_count == 'string') ? parseFloat(this.selectedDish.selected_variant_item.item_count) : this.selectedDish.selected_variant_item.item_count;
        this.selectedDish.tempData.temp_unit_price = (typeof this.selectedDish.selected_variant_item.unit_price == 'string') ? parseFloat(this.selectedDish.selected_variant_item.unit_price) : this.selectedDish.selected_variant_item.unit_price;
        this.selectedDish.tempData.unit_price = (typeof this.selectedDish.selected_variant_item.unit_price == 'string') ? parseFloat(this.selectedDish.selected_variant_item.unit_price) : this.selectedDish.selected_variant_item.unit_price;
        this.updateItemData(this.selectedDish);
      }

      if(this.isOnlyModifiers){
        this.selectedDish['isOnlyModifier'] = true;
        this.addingDish.emit(this.selectedDish);
      }else{
        this.addingDish.emit(this.selectedDish);
      }
      this.has_variants = false;
      this.isResetAutocomplete=true;
    }
    this.reactOnKeyboardAction("");    
  }


  addDishWithMembership() {
    this.selectedDish.secondary_user_ids = this.selectedDish.secondary_user_phone_number.filter(o => {return ((o.user_id) ? true : false)}).map(o=> o.user_id);
    this.addingDish.emit(this.selectedDish);
    this.is_membership_item = false;
    this.isResetAutocomplete = true;
    this.reactOnKeyboardAction("");    
  }

  runSearch(searchParams) {
    /*var params = searchParams.split(" "),
    results = [],
    searchBuffer = this.dishesModel[0];
    searchBuffer.dishes = [];

    this.currentDishesGroup = "search";
    this.dishesModel.forEach(dishesGroup => {
      dishesGroup.dishes.forEach(dish => {
        params.forEach(param => {
          if (dish.item_name.toLowerCase().indexOf(param.toLowerCase()) >= 0) {
            results.push(dish);
          }
        });
      });
    });
    searchBuffer.dishes = results;*/
  }
  reactOnKeyboardAction(parcel) {
    let searchText = "";
    let searchField = this.autoCompleteEl.autoCompleteObject.inputEL.nativeElement;
    var caretPos = this.getCaretPosition(searchField),
      currentVal = searchField.value;
    if (typeof parcel === "string") {
      searchField.value = currentVal.substring(0, caretPos) + parcel + currentVal.substring(caretPos, currentVal.length);
      searchField.setSelectionRange(caretPos + 1, caretPos + 1);
      searchField.focus();
    } else {
      if (parcel === 1) {
        this.runSearch(currentVal)
      } else if (parcel === -1) {
        searchField.value = currentVal.substring(0, caretPos - 1) + currentVal.substring(caretPos, currentVal.length);
        searchField.setSelectionRange(caretPos - 1, caretPos - 1);
        searchField.focus();
      } else {
        this.showKeyboard = false;
      }
    }
    //this.autoCompleteEl.onKeyBoardChange();    
  }
  private getCaretPosition(oField) {
    var iCaretPos = 0;

    if (document["selection"]) {
      oField.focus();
      var oSel = document["selection"].createRange();
      oSel.moveStart('character', -oField.value.length);
      iCaretPos = oSel.text.length;
    } else if (oField.selectionStart || oField.selectionStart == '0') {
      iCaretPos = oField.selectionStart;
    }

    return iCaretPos;
  }
  switchDishesGroup(chapter) {
    this.currentDishesGroup = chapter;
  }
  reactOnAautocomplete(parcel){
    this.displayAutocomplete = false;
    let findItem = this.dishesModel.filter(dish => {return dish.category_code == parcel.category_code;});
    if(findItem && findItem.length > 0) {
      var findDish = findItem[0].dishes.filter(item => { return item.item_unique_code == parcel.item_unique_code});
      if(findDish && findDish.length > 0) {
        if(parcel.barcode_data) {
          findDish[0].barcode_data = parcel.barcode_data
        }
        findDish[0].item_count = parcel.barcode_data && !isNaN(parcel.barcode_data.count) ? Number(parseFloat(parcel.barcode_data.count).toFixed(3)) :  1;
        if(this.isSearchItemQuantityEnable && findDish[0].item_code) {
          setTimeout(function () {
            $("#searchItemQuantity").focus();
          },200)
          this.autoCompleteSelectedData = findDish[0];
        } else {
          $("#auto_item_search_id").focus();
          this.autoCompleteEl.autoCompleteObject.inputEL.nativeElement.focus();
          this.addDish(findDish[0]);
        }
        this.isResetAutocomplete = false;
      }
    }
  }

  addDishWithQuantity() {
    if(this.isSearchItemQuantityEnable && this.autoCompleteSelectedData) {
      this.autoCompleteSelectedData.item_count = (this.searchItemQuantity>0) ? JSON.parse(JSON.stringify(parseInt(this.searchItemQuantity))) : 1;
      this.addDish(this.autoCompleteSelectedData);
      this.isResetAutocomplete = true;
      this.searchItemQuantity = '';      
      $('#searchItemQuantity').val('');
      $("#auto_item_search_id").focus();
      this.autoCompleteEl.autoCompleteObject.inputEL.nativeElement.focus();
    }
  }

  getLooseItemQuantity(dish) {
    return new Promise((resolve,reject) => {
      let default_item_count:number = (dish.item_count) ? dish.item_count : 1;

      if((!dish.modifier_attributes || dish.modifier_attributes.length == 0) && 
        dish.barcode_data && dish.barcode_data.count) {
         return resolve({selectedLooseItem:{portion: dish.barcode_data.count},selectedModifiers:[]});
      }

      if(!dish.can_be_sold_loose) {
        return resolve(null);
      }

      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        this.unsubscribe();        
        if (this.modalRef.content.decision === 'done') {
          if(this.modalRef.content.modalResponse) {
            return resolve(JSON.parse(JSON.stringify(this.modalRef.content.modalResponse)));
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      }));
      
      this.modalRef = this.modalService.show(LooseItemSelectionModalComponent, { class: 'loose-item-selection-modal' });
      this.modalRef.content.decision = "";
      this.modalRef.content.item_quantity_portion = default_item_count;
      this.modalRef.content.dish = dish;
    })
  }

  keyPress(event: any) {
   const pattern = /[0-9]/;
   let inputChar = String.fromCharCode(event.charCode);
   if (event.keyCode != 8 && !pattern.test(inputChar)) {
     event.preventDefault();
   }
  }

  //load categories from server if not available locally
  loadCategories() {
    return new Promise((resolve,reject) => {
      var categories: any = localStorage.getItem('categories');
      if (categories === null || categories === '') {
        this.loading = true;
        this.shopCategoryService.categories(localStorage.getItem('shop_code')).subscribe((res) => {
          categories = res.json();
          if (categories.status === 0) {
            var catCode = [];
            var catIndex = [];
            for (var cIndx = 0; cIndx < categories.result.categories.length; cIndx++) {
              categories.result.categories[cIndx].category_sequence = categories.result.categories[cIndx].category_sequence>=0 ? categories.result.categories[cIndx].category_sequence : 1000;
              if (catCode.indexOf(categories.result.categories[cIndx].category_code) === -1 && !categories.result.categories[cIndx].is_ingredient_category && categories.result.categories[cIndx].show_category) {
                catIndex.push(cIndx);
                catCode.push(categories.result.categories[cIndx].category_code);
              }
            }

            var tempArray: any = [];
            catIndex.forEach(i => {
              tempArray.push(categories.result.categories[i]);
            });

            categories.result.categories = tempArray;
            this.menuChapters = categories.result.categories.sort((a: any, b: any) => {
              if (a.category_sequence > b.category_sequence) return 1;
              if (a.category_sequence < b.category_sequence) return -1;
              return 0;
            });

            var membershipCategory = {
              "category_code": "MEMBERSHIP",
              "category_name": "Membership",
              "category_sequence": 10000,
              "category_image_url": "",
              "category_image_md5": "",
              "approval_request": null,
              "rejection_comment": null,
              "show_category": true,
              "show_category_online": true,
              "updatedAt": "2018-01-22T11:41:35.000Z",
              "createdAt": "2018-01-22T11:41:35.000Z",
              "is_ingredient_category": true,
              "significance": 0
            }
            var membership_list:any = JSON.parse(localStorage.getItem("membership_list")) || [];

            if(Array.isArray(membership_list) && membership_list.length>0) {
              this.menuChapters.push(membershipCategory);
            }
            localStorage.setItem('categories', JSON.stringify(this.menuChapters));


            var nCategories = this.menuChapters.length;
            var outerObject = this;

            // try one time chache of all items
            forEach(this.menuChapters, function (category, categoryIdx, arr) {
              if(category.category_code == "MEMBERSHIP") {
                  nCategories--;
                  if (nCategories === 0) {
                    let currentCategoryInfo:any = outerObject.getCurrentCategory(outerObject.menuChapters)                  
                      outerObject.setMultiGroupComboItemsInfo().then(re => {
                        outerObject.loadItemsForSearch(true).then(re => {                      
                          outerObject.fetchItems(currentCategoryInfo.category_code, currentCategoryInfo.category_name,false,null).then(fiR => {
                          });
                        });
                      });  
                    
                    //outerObject.addSavedItemFromPlaceSlectionPage();
                    localStorage.setItem('inventory_update_date', (new Date()).toString());
                    outerObject.loading = false;
                    return resolve(true);
                  }
              } else {
                outerObject.shopCategoryItemService.items(localStorage.getItem('shop_code'), category.category_code).subscribe((res: any) => {
                  var items = res.json();
                  nCategories--;
                  if (items.status === 0) {
                    var sortedItems = items.result.items.sort((a: any, b: any) => {
                      if (a.item_name > b.item_name) return 1;
                      if (a.item_name < b.item_name) return -1;
                      return 0;
                    });
                    sortedItems = sortedItems.filter((itm: any) => itm.show_item !== false);

                    sortedItems.forEach( itemInfo => {
                      itemInfo['category_code'] = category.category_code
                    })
                    localStorage.setItem(category.category_code, JSON.stringify(sortedItems));
                  } else if(items.status == 1 && items.result && Array.isArray(items.result.items) && items.result.items.length == 0) {
                    localStorage.setItem(category.category_code, JSON.stringify([]));                  
                  }
                  if (nCategories === 0) {
                    let currentCategoryInfo:any = outerObject.getCurrentCategory(outerObject.menuChapters)                  
                      outerObject.setMultiGroupComboItemsInfo().then(re => {
                        outerObject.loadItemsForSearch(true).then(re => {
                          outerObject.fetchItems(currentCategoryInfo.category_code, currentCategoryInfo.category_name,false,null).then(fiR => {});
                        })                      
                      });  
                    
                    //outerObject.addSavedItemFromPlaceSlectionPage();
                    localStorage.setItem('inventory_update_date', (new Date()).toString());
                    // outerObject.addItemToRecommendationList(outerObject.recommendedItemCodes);
                    outerObject.loading = false;
                    return resolve(true);
                  }
                });
              }
            });
          } else {
            this.loading = false;
            return resolve(true);
            // alert(this._titleService.itemViewTitles().fetchCategoryError);
          }
        });
      } else {
        categories = JSON.parse(categories);
        this.menuChapters = categories;
        let currentCategoryInfo:any = this.getCurrentCategory(categories)
        this.setMultiGroupComboItemsInfo().then(re => {
          this.loadItemsForSearch(false).then(re => {        
            this.fetchItems(currentCategoryInfo.category_code, currentCategoryInfo.category_name,false,null).then(fiR => {});
            return resolve(true);
          });
        });        
      }
    });
  }

  getCurrentCategory(allCategory:any) {
    let currentCategoryInfo: any = localStorage.getItem("current_category_info");
    let findIndex = -1;
    if(currentCategoryInfo) {
      currentCategoryInfo = JSON.parse(currentCategoryInfo);
      findIndex = allCategory.findIndex(categoryDetail => {
         return categoryDetail.category_code == currentCategoryInfo.category_code;
      });
    }
    if(findIndex == -1 || !currentCategoryInfo) {
      currentCategoryInfo = {
        category_code: allCategory[0].category_code,
        category_name: allCategory[0].category_name
      }
    }
    return currentCategoryInfo;
  }

  loadItemsForSearch(isGetFreshData) {
    return new Promise((resolve,reject) => {
      var categories: any = localStorage.getItem('categories');
      if(!categories) { return resolve(true); }

      this.dishesModel = [
        {
          name: "search",
          category_code:"search",
          dishes: []
        }
      ];

      categories = JSON.parse(categories);
      if(!categories) { return resolve(true); }

      let categorywiseShopItems = this.stateGuard.obtain("item-search-data");
      if(!isGetFreshData && categorywiseShopItems) {     
        let tItems = [];
          forOwn(categorywiseShopItems, (it)=> {
            tItems.push(it);
          });
          
          categories.forEach(categoryDetail => {
            var dish = {
              name: categoryDetail.category_name,
              category_code:categoryDetail.category_code,
              dishes:  tItems.filter(tit => { return tit.category_code ==  categoryDetail.category_code })
            };

            this.dishesModel.push(dish);
          });
      } else {
        categories.forEach(categoryDetail => {
          if(categoryDetail.category_code != "MEMBERSHIP") {
            var dish = {
              name: categoryDetail.category_name,
              category_code:categoryDetail.category_code,
              dishes: []
            };
            var items: any = localStorage.getItem(categoryDetail.category_code);
            if(items) {
              items = JSON.parse(items);
              var unique_count = 0;
              if(Array.isArray(items)) {
                items.forEach(item => {
                  item["category_code"] = categoryDetail.category_code;
                  item["item_unique_code"] = unique_count;
                  unique_count++;
                });
              }
              dish.dishes = this.setPrice(items);            
              dish.dishes = this.collateVariantItems(dish.dishes);

              dish = this.setTempCounts(dish);
              this.dishesModel.push(dish);
            }
          }
        });
        let tempShopItems:any = {};
        this.dishesModel.forEach((dish) => {
          dish.dishes.forEach(item => {
            if(item.item_code){
              tempShopItems[item.item_code] = JSON.parse(JSON.stringify(item)) 
            }else if(item.selected_variant_item && item.selected_variant_item.item_code){
              tempShopItems[item.selected_variant_item.item_code] = JSON.parse(JSON.stringify(item)) 
            }
          });
        });
        this.stateGuard.entrust("item-search-data",JSON.parse(JSON.stringify(tempShopItems)));
      }
      return resolve(true);
    });
  }

  setMultiGroupComboItemsInfo() {
    return new Promise((resolve,reject) => {
      //return resolve(""); // for now we are not using multi group combo. 6/25/2020
      let categories: any = JSON.parse(localStorage.getItem('categories')) || [];
      if(categories && categories.length>0) {
        let shopAllItems = {};
        let categoriesWiseItems = {};
        categories.forEach(categoryDetail => {
          if(categoryDetail.category_code != "MEMBERSHIP") {          
            let items: any = localStorage.getItem(categoryDetail.category_code);
            if(items) {
              items = JSON.parse(items);
              if(Array.isArray(items) && items.length>0) {
                items.forEach((it:any) => {
                  shopAllItems[it.item_code] = it;
                });              
              }
              categoriesWiseItems[categoryDetail.category_code] = items;
            }          
          }
        })
        for(var category_code in categoriesWiseItems) {
          let items: any = categoriesWiseItems[category_code];
          if(Array.isArray(items) && items.length>0) {
              let isItemUpdated = false;
              items.forEach((item:any) => {
                if(item.is_multi_group_combo && item.multi_group_combo && Array.isArray(item.multi_group_combo.main_group) && item.multi_group_combo.main_group.length>0) {
                  item.multi_group_combo.main_group.forEach((mit:any) => {                    
                    if(shopAllItems[mit.item_code]) {
                      mit.item_name = shopAllItems[mit.item_code].item_name;
                      mit.unit_price = shopAllItems[mit.item_code].unit_price;
                      mit.unit = shopAllItems[mit.item_code].unit;
                      mit.unit_value = shopAllItems[mit.item_code].unit_value;
                      mit.variant_tag = shopAllItems[mit.item_code].variant_tag;
                      mit.minimum_unique_attribute_selection = shopAllItems[mit.item_code].minimum_unique_attribute_selection;
                      mit.maximum_unique_attribute_selection = shopAllItems[mit.item_code].maximum_unique_attribute_selection;
                      mit.attributes = [];
                      /*mit.modifiers = [];
                      mit.variant_attributes = [];
                      mit.is_variant_item = false;                    */
                      if(Array.isArray(shopAllItems[mit.item_code].attributes) && shopAllItems[mit.item_code].attributes.length>0) {
                        shopAllItems[mit.item_code].attributes.forEach((attr:any) => {
                          if(attr) {
                            let tempAttr = {
                              name: attr.name,
                              group_name: attr.group_name,
                              attribute_code:attr.attribute_code,
                              id:attr.id,
                              unit_value: attr.unit_value,
                              unit_price: attr.unit_price,                              
                              is_variant_attribute: attr.is_variant_attribute,
                              is_selection_exclusive: attr.is_selection_exclusive
                            }
                            mit.attributes.push(tempAttr);
                            /*if(attr.is_variant_attribute) {
                              mit.variant_attributes.push(tempAttr);
                              mit.is_variant_item = true
                            } else {
                              mit.modifiers.push(tempAttr);
                            }*/
                          }
                        });
                      }                    
                      isItemUpdated = true;
                    }
                  });                
                  if(Array.isArray(item.multi_group_combo.sub_groups) && item.multi_group_combo.sub_groups.length>0) {
                    item.multi_group_combo.sub_groups.forEach((sg:any) => {
                      if(Array.isArray(sg.items) && sg.items.length>0) {
                        sg.items.forEach((sit:any) => {
                          if(shopAllItems[sit.item_code]) {
                            sit.item_name = shopAllItems[sit.item_code].item_name;
                            sit.unit_price = shopAllItems[sit.item_code].unit_price;
                            sit.unit = shopAllItems[sit.item_code].unit;
                            sit.unit_value = shopAllItems[sit.item_code].unit_value;                           
                          }
                        });
                      }
                    });
                  }
                }
              });
              if(isItemUpdated) {
                localStorage.setItem(category_code,JSON.stringify(items));
              }

          }
        }
        return resolve("");        
      } else {
        return resolve("");
      }
    });
  }

  setTempCounts(dish:any) {
    dish.dishes.forEach(dishData => {
      dishData.item_img_url = BaseConfig.config.API + dishData.item_img_url;

      if(dishData.selected_variant_item){
        dishData.tempData = {
          'temp_unit_price':dishData.selected_variant_item.unit_price,
          'temp_item_count':dishData.selected_variant_item.item_count,
          'unit_price':(typeof dishData.selected_variant_item.unit_price == 'string')? parseFloat(dishData.selected_variant_item.unit_price) : dishData.selected_variant_item.unit_price
        };
      }else{
        dishData.tempData = {
          'temp_unit_price':dishData.unit_price,
          'temp_item_count':dishData.item_count,
          'unit_price':dishData.unit_price
        };
      }
    });

    return dish;
  }

  /*addSavedItemFromPlaceSlectionPage() {
    if(localStorage.getItem('first_item') && localStorage.getItem('first_item')!='NA'){
      let parcel = JSON.parse(localStorage.getItem('first_item'));
      localStorage.setItem('first_item','NA');
      if(parcel.selected_variant_item){
        this.addingDish.emit(parcel);
      } else {
        this.currentItems.forEach(categories => {
          categories.dishes.forEach(dish => {
            if(dish.item_code == parcel.item_code) {
              this.addingDish.emit(dish);
            }
          });
        });
      }
    }
  }*/

  addItems(dish){
    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    let categories = JSON.parse(localStorage.getItem('categories'));

    for (var i = 0; i < categories.length; ++i) {
      let categoryCode = categories[i].category_code;
      let categoryName = categories[i].category_name;
      this.fetchItems(categoryCode,categoryName,true,dish);
    }
  }

  updateItemData(dish) {
    if(this.currentItems) {
      for(var i=0;i<this.currentItems.length;i++) {
        if(this.currentItems[i] && Array.isArray(this.currentItems[i].dishes)) {
          for(var j=0;j<this.currentItems[i].dishes.length;j++) {
            if(this.currentItems[i].dishes[j].display_name == dish.display_name) {
              this.currentItems[i].dishes[j]= JSON.parse(JSON.stringify(dish));
            }
          }
        }
      }
    }
  }

  //fetch items of a category from server if not available locally
  fetchItems(categoryCode: string, categoryName: string,flag:boolean,data) {
    this.isInSubCat = false;
    return new Promise((resolve,reject) => {
      this.currentDishesGroup = categoryName;
      this.currentCategory = categoryCode;
      localStorage.setItem("current_category_info",JSON.stringify({category_code:categoryCode,category_name:categoryName}));

      var dish = {
        name: categoryName,
        dishes: []
      };
      //this.currentItems = [];
      if(categoryCode == "MEMBERSHIP") {
        var membership_list:any = JSON.parse(localStorage.getItem("membership_list")) || [];
        var tempItems:any = [];
        if(Array.isArray(membership_list)) {
          membership_list.forEach(memInfo => {
              var tempMembershipItemCommonFields = {
                "item_name": memInfo.membership_name,
                "item_code": memInfo.id,
                "sub_category_code": null,
                "tags": [],
                "sub_category_img_url": null,
                "sub_category_img_md5": "",
                "item_img_url": "items/IT121339.png",
                "expire_after": null,
                "unit": "Serving",
                "unit_price": memInfo.applicable_fee,
                "initial_price": memInfo.applicable_fee,
                "unit_value": 1,
                "description": "",
                "alt_names": null,
                "item_img_md5": "",
                "quantity": null,
                "show_item": true,
                "show_item_online": true,
                "short_key": 1000,
                "approval_request": "APPROVE0000",
                "rejection_comment": null,
                "significance": 0,
                "bar_code": null,
                "unit_cost": 0,
                "attributes": [],
                "has_attribute": null,
                "default_attribute_code": null,
                "variant_tag": "",
                "section_tag": null,
                "low_stock_alert": null,
                "section_wise_price": {},
                "online_partner_price": [],
                "tax_settings": memInfo.applicable_tax,
                "supplier_id": -1,
                "track_stock": true,
                "unbundle_item_code": null,
                "unbundle_item_quantity": 0,
                "can_be_sold_loose": false,
                "updatedAt": "2018-07-03T12:09:20.000Z",
                "is_price_editable": false,
                "can_be_unbundled": false,
                "receipt_section": [],
                "kitchen_department": null,
                "category_code": "MEMBERSHIP",
                "is_membership":true,
                "is_group_allowed":memInfo.is_group_allowed,
                "maximum_user_in_group":memInfo.maximum_user_in_group,
                "fee_type":memInfo.fee_type,
                "renew_item_price":memInfo.applicable_recurring_fee
              }
              tempItems.push(tempMembershipItemCommonFields);
          })
        }

        if(tempItems.length > 0) {
          var sortedItems = tempItems.sort((a: any, b: any) => {
            if (a.item_name > b.item_name) return 1;
            if (a.item_name < b.item_name) return -1;
            return 0;
          });

          localStorage.setItem(categoryCode, JSON.stringify(sortedItems));

          /* For shop_items key */
          if(localStorage.getItem('shop_items')){
            var shopItems = JSON.parse(localStorage.getItem('shop_items'))
          }else{
            var shopItems:any = {}
          }

          dish.dishes = this.setPrice(sortedItems);                 
          dish.dishes = this.collateVariantItems(dish.dishes);
          this.copyOfCurrentItems = JSON.parse(JSON.stringify(dish.dishes));
        }
        resolve(true);
      } else {
        var items: any = localStorage.getItem(categoryCode);
        if (items === 'null' || items === null || items === '') {
          this.loading = true;
          this.shopCategoryItemService.items(localStorage.getItem('shop_code'), categoryCode).subscribe((res) => {
            items = res.json();
            if (items.status === 0 && Array.isArray(items.result.items)) {
              var sortedItems = items.result.items.sort((a: any, b: any) => {
                if (a.item_name > b.item_name) return 1;
                if (a.item_name < b.item_name) return -1;
                return 0;
              });

              sortedItems.forEach( itemInfo => {
                itemInfo['category_code'] = categoryCode
              })
              localStorage.setItem(categoryCode, JSON.stringify(sortedItems));

              if(!flag){
                sortedItems.forEach((item:any) => {                  
                  if(item.is_multi_group_combo && item.multi_group_combo && Array.isArray(item.multi_group_combo.main_group) && item.multi_group_combo.main_group.length>0) {
                    item.multi_group_combo.main_group = this.collateVariantItems(item.multi_group_combo.main_group);
                  }
                });
                dish.dishes = this.setPrice(sortedItems);                
                dish.dishes = this.collateVariantItems(dish.dishes);
              }else{
                dish.dishes = sortedItems;
              }
              this.copyOfCurrentItems = JSON.parse(JSON.stringify(dish.dishes));
              this.loading = false;
            } else if(items.status == 1 && items.result && Array.isArray(items.result.items) && items.result.items.length == 0) {
              localStorage.setItem(categoryCode, JSON.stringify([]));
              this.loading = false;
            }else {
              this.loading = false;
              // alert(this._titleService.itemViewTitles().fetchItemError);
            }
            resolve(true);
          },error => {
            resolve(true);
          });
        } else {
          items = JSON.parse(items);
          items.forEach((item:any) => {

            if(item.is_multi_group_combo && item.multi_group_combo && Array.isArray(item.multi_group_combo.main_group) && item.multi_group_combo.main_group.length>0) {
              item.multi_group_combo.main_group = this.collateVariantItems(item.multi_group_combo.main_group);
            }
          });
          if(!flag) {
            dish.dishes = this.setPrice(items);
            dish.dishes = this.collateVariantItems(dish.dishes);
          } else {
            dish.dishes = items;
          }
          this.copyOfCurrentItems = JSON.parse(JSON.stringify(dish.dishes));
          resolve(true);
        }
      }

      dish = this.setTempCounts(dish);      

      let tempData = [];
      tempData.push(dish);
      this.currentMenuItemsMessage.sendCurrentMenuItems(tempData);
      if(flag && dish.dishes && dish.dishes.length>0 && data && data.item_code){
        for (var i = 0; i < dish.dishes.length; ++i) {
          let item = dish.dishes[i];
          if(item && item.item_code && data && item.item_code == data.item_code){
            item.item_count = data.item_count;
            let tempData = {
              "temp_unit_price": (typeof data.item_price == 'string') ? parseFloat(data.item_price) : data.item_price,
              "unit_price": (typeof data.item_price == 'string') ? parseFloat(data.item_price) : data.item_price,
              "temp_item_count":(typeof data.item_count == 'string') ? parseFloat(data.item_count) : data.item_count
            }
            item.unit_price = tempData.unit_price;
            item.per_unit_price = tempData.unit_price;        
            if(data.attributes && data.attributes.length>0){

              data.attributes.forEach(detail=>{
                if(!detail.count){
                  detail['count']= 1;
                }
              });          
              let price = data.item_price;
              data['unit_price'] = 0;
              data.unit_price = tempData.unit_price//price;
              let sendItem = {
                "tempData" : tempData,
                "selected_variant_item":data             
              }
              this.basketComponent.addToBasket(sendItem,true);
              break;
            }else{
              let tempItem = JSON.parse(JSON.stringify(item));
              if(Array.isArray(tempItem.attributes)) {
                remove(tempItem.attributes, ti => { return ti.is_variant_attribute; })
              }
              tempItem.tempData = tempData;
              this.basketComponent.addToBasket(tempItem,true);
              break;
            }
          }
        }
      }
    })
  }

  setPrice(items:any):any {
    if(!items) {
      return items;
    }
    var currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    if(localStorage.getItem('isRestaurant') == 'true' && currentUnit && currentUnit.location) {
      var selected_partner_list = currentUnit.selected_partner_list || [];
      var selected_partner_ids = [];
      if(Array.isArray(selected_partner_list)) {
        selected_partner_ids = selected_partner_list.map(spl => { return spl.id});
      }
      items.forEach(it => {
        if(Array.isArray(it.section_wise_price)) {
          it.section_wise_price.forEach(swp => {
            if(currentUnit.location == swp.section) {
              it.unit_cost = (swp.unit_cost || swp.unit_cost == 0 ) ? parseFloat(swp.unit_cost) : it.unit_cost;
              it.unit_price = (swp.unit_price || swp.unit_price == 0) ? parseFloat(swp.unit_price) : it.unit_price;
            }
          });
        }

        if(Array.isArray(selected_partner_ids) && selected_partner_ids.length>0 &&
          Array.isArray(it.online_partner_price) && it.online_partner_price.length>0) {
          it.online_partner_price.forEach(sop => {
            if(selected_partner_ids.indexOf(sop.id) != -1) {
              it.unit_cost = (sop.unit_cost || sop.unit_cost == 0) ? parseFloat(sop.unit_cost) : it.unit_cost;
              it.unit_price = (sop.unit_price || sop.unit_price == 0) ? parseFloat(sop.unit_price) : it.unit_price;
            }
          });
        }
      });
    }
    return items;
  }

  // collate items with same variant variant_tag
  collateVariantItems(items: any): any {
    if(!items || items.length == 0) { return []}
    var collatedItems: any = [];
    var variantItems: any = groupBy(items.filter((itm: any) => itm.variant_tag !== null && itm.variant_tag !== ''),
      (itm: any) => itm.variant_tag);
    var modifierItems: any = groupBy(items.filter((itm: any) => ((itm.variant_tag === null || itm.variant_tag === '') && itm.attributes.length > 0)), (itm: any) => itm.item_name);
    var nonVariantModifierItems: any = items.filter((nvmitm: any) => ((nvmitm.variant_tag === null || nvmitm.variant_tag === '') && nvmitm.attributes.length === 0));
    collatedItems = nonVariantModifierItems;
    collatedItems.forEach(i => {
      i.variants = [];
      i.unit_price = parseFloat(i.unit_price);
      i.item_count = 1;
      i.item_list = [];
    });
    for (var nvi in modifierItems) {
      var modifierItem: any = modifierItems[nvi][0];
      var uniqueAttr: any = uniqBy([].concat.apply([], modifierItems[nvi].map((itm: any) => itm.attributes)),
        (atr: any) => atr ? atr.attribute_code : null);

      remove(uniqueAttr,(attr:any) => { return !attr });

      modifierItem['modifier_attributes'] = groupBy(uniqueAttr.filter((atr: any) => !atr.is_variant_attribute), (grp: any) => grp.group_name);
      if (modifierItem[vi] === undefined) modifierItem['item_list'] = [];
      else modifierItem['item_list'] = modifierItems[vi];
      //console.log("item_list :: " + modifierItem);
      modifierItem['unit_price'] = Math.min.apply(null, modifierItems[nvi].map((itm: any) => itm.unit_price));
      modifierItem['display_name'] = nvi;
      modifierItem['category_code'] = modifierItems[nvi][0].category_code;
      modifierItem['item_unique_code'] = modifierItems[nvi][0].item_unique_code;
      modifierItem['quantity'] = Math.max.apply(null, modifierItems[nvi].map((itm: any) => itm.quantity));
      modifierItem['unit'] = modifierItems[nvi][0].unit;
      modifierItem['unit_value'] = modifierItems[nvi][0].unit_value;
      modifierItem['attribute_group'] = groupBy(uniqueAttr, (grp: any) => grp.group_name);
      modifierItem['attributes'] = modifierItems[nvi][0].attributes;
      modifierItem['item_name'] = nvi;
      modifierItem['variant_tag'] = null;
      //modifierItem['item'] = modifierItems[nvi][0];
      //modifierItem['item_img_url'] = modifierItems[nvi][0].item_img_url;
      modifierItem['selected_modifier_attributes'] = {};

      GeneralHelper.keys(modifierItem['modifier_attributes']).map(
        function (grp: any) {
          // make the first one in the list selected
          return modifierItem['selected_modifier_attributes'][grp] = modifierItem['modifier_attributes'][grp][0];
        }
      );
      collatedItems.push(modifierItem);
    }
    for (var vi in variantItems) {
      var variantItem: any = {};
      var uniqueAttr: any = uniqBy([].concat.apply([], variantItems[vi].map((itm: any) => itm.attributes)),
        (atr: any) =>  atr ? atr.attribute_code : null);

       remove(uniqueAttr,(attr:any) => { return !attr });
      variantItem['item_name'] = vi;
      variantItem['category_code'] = variantItems[vi][0].category_code;
      variantItem['item_unique_code'] = variantItems[vi][0].item_unique_code;
      variantItem['item_list'] = variantItems[vi];
      variantItem['unit_price'] = Math.min.apply(null, variantItems[vi].map((itm: any) => itm.unit_price));
      variantItem['quantity'] = Math.max.apply(null, variantItems[vi].map((itm: any) => itm.quantity));
      variantItem['unit'] = '';
      variantItem['unit_value'] = 0;
      variantItem['attributes'] = groupBy(uniqueAttr, (grp: any) => grp.group_name);
      variantItem['variant_attributes'] = groupBy(uniqueAttr.filter((atr: any) => atr.is_variant_attribute), (grp: any) => grp.group_name);
      variantItem['modifier_attributes'] = groupBy(uniqueAttr.filter((atr: any) => !atr.is_variant_attribute), (grp: any) => grp.group_name);
      variantItem['item_img_url'] = variantItems[vi][0].item_img_url;
      variantItem['display_name'] = vi;
      variantItem['selected_variant_attributes'] = {};
      GeneralHelper.keys(variantItem['variant_attributes']).map(
        function (grp: any) {
          // make the first one in the list selected
          return variantItem['selected_variant_attributes'][grp] = variantItem['variant_attributes'][grp][0];
        }
      );

      variantItem['selected_variant_item'] = this.selectVariantItem(variantItem);
      collatedItems.push(variantItem);

    }
    // console.log("variant item : "+JSON.stringify(collatedItems));
    return collatedItems;
  }

  // select a variant item from the selected variant item list
  selectVariantItem(item: any) {
    try {
      var selectedAttributes = GeneralHelper.values(item['selected_variant_attributes']).map((itm: any) => itm.attribute_code);

      var selectedItem = item['item_list'].filter((itm: any) => {
        return difference(selectedAttributes, itm.attributes.filter((x: any) =>
          x.is_variant_attribute).map((x: any) => x.attribute_code)).length === 0;
      });
      return selectedItem[0];
    } catch (e) {
      //console.log('to catch exception');
      return null;
    }
  }

  // helper for general helper ;-)
  generalHelper() {
    return GeneralHelper;
  }

  toggleVisibility(dish){
    // let shop_code = localStorage.getItem('shop_code');
    // let sendData = {
    //   'unit_value':dish.unit_value,
    //   'item_code':dish.item_code,
    //   'item_name':dish.item_name,
    //   'shop_code':shop_code,
    //   'unit':dish.unit,
    //   'per_unit_price':dish.unit_price,
    //   'can_be_sold_loose':dish.can_be_sold_loose,
    //   'show_item_online': !dish.show_item_online
    // }

    this.onClickUpdateAvailable(dish);
    // this.shopCategoryItemService.updateItems(sendData).subscribe((res) => {
    //   let resData = res.json();
    //   if(resData.status == 0){
        // dish.show_item_online = !dish.show_item_online;
        // this.onClickUpdateAvailable();
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: dish.item_name+" updated successfully" });
    //   }else{
    //     this.messageService.add({ severity: 'error', summary: 'Error', detail: dish.item_name+' could not be updated at this time' });
    //   }
    // });
  }

  onClickUpdateAvailable(dish){

    this.subscriptions.push(this.modalService.onHide.subscribe(() => {

      if (this.modalRef.content.decision === 'yes') {

        let shop_code = localStorage.getItem('shop_code');
        let sendData = {
          'unit_value':dish.unit_value,
          'item_code':dish.item_code,
          'item_name':dish.item_name,
          'shop_code':shop_code,
          'unit':dish.unit,
          'per_unit_price':dish.unit_price,
          'can_be_sold_loose':dish.can_be_sold_loose,
          'show_item_online': !dish.show_item_online
        }

        this.shopCategoryItemService.updateItems(sendData).subscribe((res) => {
          let resData = res.json();
          if(resData.status == 0){
          //data

          let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
          let is_item_added = false;

          if(tempUnit && tempUnit.visitors) {
            for(var i=0;i<tempUnit.visitors.length;i++) {
              if(tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
                is_item_added = true;
                break;
              }
            }
          }

          if(is_item_added){
            tempUnit.visitors.forEach((seat, index) => {
              seat.isOnHold = true;
            });
            if(tempUnit.isOnHold) {
              //this.router.navigate(['/pages/register/place-selection/here']);
              //return;
            }else{
              tempUnit.isOnHold = true;
              tempUnit.isFromOnHold = true;
              this.stateGuard.amend('basket.component:offUnits', function (offUnits) {
                offUnits.push(tempUnit);
                localStorage.setItem('offUnits',JSON.stringify(offUnits));
              });
            }
            //this.basketComponent.clearCart(false);
            //this.router.navigate(['/pages/register/place-selection/here']);
          }


          let version = localStorage.getItem('current_version_no');
          let sendData = {
            'shop_code':localStorage.getItem('shop_code'),
            'version_number': (typeof version=='string')? parseInt(version) : version
          }
          this.commonService.getInventoryUpdate(sendData).subscribe((res) => {
              var apiRes = res.json();

              if (apiRes.status === 0) {
                if(apiRes.result.item_history && apiRes.result.item_history.length > 0){
                  var allUpdatedItems = apiRes.result.item_history.map(updatedItem => updatedItem.item);
                  let itemGroupByCategoryCode = groupBy( allUpdatedItems, function (item) { return item.master_item_category_code; });
                  let allUpdatedItem = [];
                  forOwn(itemGroupByCategoryCode,(itemsDetail,category_code) => {
                      this.updateItems(category_code,itemsDetail);
                      allUpdatedItem = allUpdatedItem.concat(itemsDetail);
                  });

                  this.updateOnHoldItems(allUpdatedItem);

                  let totalUpdatedItem = groupBy( apiRes.result.item_history, function (item) { return item.item_code; });

                  let count = Object.keys(totalUpdatedItem).length;

                  let len = apiRes.result.item_history.length;
                  let latestVersion = apiRes.result.item_history[len-1].inventory_version;
                  localStorage.setItem('current_version_no',latestVersion);
                  this.updateInventoryMessage.sendUpdateAvailableInfo(false,true);
                  this.messageService.add({ severity: 'success', summary: 'Update Item', detail: dish.item_name +' updated successfully.' });                  
                  this.setMultiGroupComboItemsInfo().then(re => {
                    this.loadItemsForSearch(true).then(result => {                    
                      this.fetchCurrentCategory().then(fccR => {});
                    })
                  });                    
                }
              } else {
                  this.messageService.add({ severity: 'success', summary: 'Update Item', detail: dish.item_name + " updated successfully on server, please click on 'Update Available' to see changes." });
              }
          });

          }else{
            this.messageService.add({ severity: 'error', summary: 'Error', detail: dish.item_name+' could not be updated at this time' });
          }
        })

      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(ConfirmationModal, {class: 'common-modal'});
    this.modalRef.content.decision = "";
    this.modalRef.content.text = "Items in the orders parked or saved will be overwritten with updated information, Do you still want to update?";

    /**/
  }

  updateItems(category_code,updatedItems){

    let item_list = JSON.parse(localStorage.getItem(category_code));
    if(item_list && item_list[0]){
      let temp_item_list = [];
      item_list.forEach((item_info) => {
        updatedItems.forEach(updatedItem => {
          if(item_info.selected_variant_item && item_info.selected_variant_item.item_code == updatedItem.item_code){
            item_info.selected_variant_item = this.updateItemAfterVersionChange(item_info.selected_variant_item,updatedItem)
          } else if(item_info.item_code == updatedItem.item_code){
            item_info = this.updateItemAfterVersionChange(item_info,updatedItem)
          }
        })
        if(item_info.update_comment != "delete") {
          temp_item_list.push(item_info);
        }
      });

      updatedItems.forEach(updatedItem => {
        if(updatedItem.update_comment == "add") {
          temp_item_list.push(updatedItem);
        }
      });

      localStorage.setItem(category_code,JSON.stringify(temp_item_list));

            


    }
  }

  updateItemAfterVersionChange(currentItem,updatedItem) {
    updatedItem['unit_price']=parseFloat(updatedItem.unit_price);

    updatedItem.tempData = {
      'temp_unit_price':parseFloat(updatedItem.unit_price),
      'temp_item_count':currentItem.item_count,
      'unit_price':parseFloat(updatedItem.unit_price)
    };
    currentItem = Object.assign(currentItem,updatedItem);

    return currentItem;
  }

  updateOnHoldItems(updatedItems){

    let allPlaces = this.stateGuard.obtain('selects-box.component:places') || [];

    if(allPlaces && allPlaces[0]){
      allPlaces.forEach((place) =>{
        if(place.units && place.units[0]) {
          place.units.forEach((units) =>{
            if(units && units.visitors && units.visitors[0]){
              units.visitors.forEach((visitor) =>{
                if(visitor && visitor.dishes && visitor.dishes[0]){
                  visitor.dishes[0].forEach((dish) =>{
                    updatedItems.forEach(updatedItem => {
                      if(dish.selected_variant_item && dish.selected_variant_item.item_code == updatedItem.item_code){
                        dish.selected_variant_item = this.updateItemAfterVersionChange(dish.selected_variant_item,updatedItem)
                      } else if(dish.item_code == updatedItem.item_code){
                        dish = this.updateItemAfterVersionChange(dish,updatedItem)
                      }
                    });
                  });

                  //remove delete item from dish
                  remove(visitor.dishes[0], (dish) => {
                    if(dish.selected_variant_item){
                      return dish.selected_variant_item.update_comment == "delete";
                    } else {
                     return dish.update_comment == "delete";
                    }
                  });
                }
              });
            }
          })
        }
      })
    }

    let localOffUnits = JSON.parse(localStorage.getItem("offUnits")) || [];
    if(localOffUnits && localOffUnits.length>0) {
      localOffUnits.forEach((units) =>{
          if(units && units.visitors && units.visitors[0]){
            units.visitors.forEach((visitor) =>{
              if(visitor && visitor.dishes && visitor.dishes[0]){
                visitor.dishes[0].forEach((dish) =>{
                  updatedItems.forEach(updatedItem => {
                    if(dish.selected_variant_item && dish.selected_variant_item.item_code == updatedItem.item_code){
                      dish.selected_variant_item = this.updateItemAfterVersionChange(dish.selected_variant_item,updatedItem);
                    } else if(dish.item_code == updatedItem.item_code){
                      dish = this.updateItemAfterVersionChange(dish,updatedItem)
                    }
                  });
                });

                //remove delete item from dish
                remove(visitor.dishes[0], (dish) => {
                  if(dish.selected_variant_item){
                    return dish.selected_variant_item.update_comment == "delete";
                  } else {
                   return dish.update_comment == "delete";
                  }
                });
              }
            });
          }
        });

        this.stateGuard.amend('basket.component:offUnits', function (offUnits) {
          offUnits = localOffUnits;
          localStorage.setItem('offUnits',JSON.stringify(offUnits));
        });
    }
  }

  // recommendedItemList = []
  // recommendedItemCodes = ['IT137220','IT137221','IT137219','IT137218','IT137224']

  addItemToRecommendationList(recommendedItemCodes) {
    if(localStorage.getItem('shop_items')) {
      this.recommendedItemList = []
      var shopItems = JSON.parse(localStorage.getItem('shop_items'))

      recommendedItemCodes.forEach(r_item => {
        shopItems[r_item].item_name = this.toTitleCase(shopItems[r_item].item_name)
        this.recommendedItemList.push(JSON.parse(JSON.stringify(shopItems[r_item])))
      })

      this.recommendedItemList = this.setPrice(this.recommendedItemList);      
      // var dish = {'dishes': JSON.parse(JSON.stringify(this.recommendedItemList))}
      // dish = this.setTempCounts(dish)
      // this.recommendedItemList = dish.dishes;



      // recommendedItemCodes.forEach(r_item => {
      //   var catCode = shopItems[r_item];
      //   console.log('catCode: ' + catCode)
      //   var catItems = JSON.parse(localStorage.getItem(catCode))
      //   console.log(" items: " + catItems.length)
      //   var itemObj = catItems.filter(itm => itm.item_code == r_item )[0]
      //   itemObj.item_name = this.toTitleCase(itemObj.item_name)
      //   this.recommendedItemList.push(JSON.parse(JSON.stringify(itemObj)))
      // })

      // this.recommendedItemList = this.setPrice(this.recommendedItemList)

      // var dish = {'dishes': JSON.parse(JSON.stringify(this.recommendedItemList))}
      // dish = this.setTempCounts(dish)
      // this.recommendedItemList = dish.dishes;

    }
  }

  toTitleCase(name){
    var finalName = name.trim().split(" ").map((word) => { return word.charAt(0).toUpperCase() + word.slice(1) }).join(" ")
    return finalName
  }

}
