import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-add-cash',
  templateUrl: './add-cash.component.html',
  styleUrls: ['./add-cash.component.less'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        transform: 'translateY(-100%)'
      })),
      state('active', style({
        transform: 'translateY(0%)'
      })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ]),
    trigger('fadeOverlay', [
      state('inactive', style({
        opacity: '0'
      })),
      state('active', style({
        opacity: '1'
      })),
      transition('inactive => active', animate('150ms ease-in')),
      transition('active => inactive', animate('150ms ease-out'))
    ])
  ]
})
export class AddCashComponent implements OnInit {

	cash_in: boolean = true;

	@Input('state') state: string = 'Add';

	@Output() closeEvent: EventEmitter<any> = new EventEmitter();
	@Output() addEvent: EventEmitter<any> = new EventEmitter();

	title: string = 'Add';
  anim_state: string = 'inactive';
  shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));      
  cash: any = { time: new Date(),  reason: 'Cash in', transaction: undefined,category:null, notes: '' };/*user: 'Filib Reuven', email: 'rajatbakshi51@gmail.com',*/
  cash_register_categories:any = [];
  constructor(private messageService:MessageService) { }

  ngOnInit() {

    var self = this;
    this.cash_register_categories = (this.shopConfig) ? this.shopConfig.cash_register_categories : [];
    setTimeout(() => {
      if(self.state == 'Add') {
        self.title = 'Add';
      } else {
        self.title = 'Remove';
      }
      this.changeReason();
      let loggedeInUser = JSON.parse(localStorage.getItem('loggedInUser')) || {};
      this.cash['user'] = loggedeInUser.name;
      this.cash['email'] = loggedeInUser.email;
      this.cash['id'] = loggedeInUser.id;
      self.anim_state = 'active';
    }, 150);

  }

  changeReason(){
    if(this.cash_in && this.state == 'Add'){
      this.cash['reason'] = 'Cash in';
    }else if(this.cash_in && this.state == 'Remove'){
      this.cash['reason'] = 'Cash out';
    }else if(!this.cash_in && this.state == 'Add'){
      this.cash['reason'] = 'Petty Cash in';
    }else if(!this.cash_in && this.state == 'Remove'){
      this.cash['reason'] = 'Petty Cash out';
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  cancel() {
    var self = this;
    setTimeout(() => {
  	  self.closeEvent.emit();
    }, 200);
    this.anim_state = 'inactive';
  }

  onSelectCategory(event) {
    if(event) {
      this.cash.category = event;
    }
  }

  add() {
    var self = this;
    if(this.cash.transaction) {
      setTimeout(() => {
        if(self.state != 'Add' && self.cash.transaction > 0) {
          self.cash.transaction = -self.cash.transaction;
        }
        self.addEvent.emit(self.cash);
      }, 200);
      self.anim_state = 'inactive';
    }else{
      this.messageService.add({ severity: 'error', summary: 'First enter valid amount', detail: '' });
    }
  }

}
