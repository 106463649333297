import { Component, OnInit, Input, Output, EventEmitter,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasketComponent } from '../basket/basket.component';
import { APIHelper } from '../helpers/api-helper';
import { StateGuardService } from '../state-guard.service';


@Component({
  selector: 'app-place-selection',
  templateUrl: './place-selection.component.html',
  styleUrls: ['./place-selection.component.less'],
})

export class PlaceSelectionComponent implements OnInit {
  placeType: string = 'here';
  isRestaurant: string = 'true';
  isAppSelectBox: string = 'false';
  isFoodOrderBox: string = 'false';
  isFoodOrderBoxSelectItem: string = 'false';

  lat:any;
  lng:any;

  @Input() deliveryType: number;
  constructor(private route: ActivatedRoute, private router: Router,
                    private stateGuard:StateGuardService, private basketComponent: BasketComponent) { }
  @Output() optionsState: EventEmitter<boolean> = new EventEmitter();      

  ngOnInit() {

    this.optionsState.emit(true);

    let shop_config = JSON.parse(localStorage.getItem('shop_configuration')) || {};
    
    if(shop_config.shop_info){
      this.lng = (shop_config.shop_info && shop_config.shop_info.longitude)?  shop_config.shop_info.longitude : undefined;
      this.lat = (shop_config.shop_info && shop_config.shop_info.latitude)?  shop_config.shop_info.latitude : undefined;
      this.lat = (typeof this.lat == 'string')? parseFloat(this.lat) : this.lat;
      this.lng = (typeof this.lng == 'string')? parseFloat(this.lng) : this.lng;
    }
    

    this.placeType = this.route.snapshot.params.type || "here";    
    this.setDeliveryType();
    let delivery_type_code = APIHelper.IMMEDIATE;
    if(this.placeType == "home") {
      delivery_type_code = APIHelper.HOME_DELIVERY;
    } else if(this.placeType == "pickup") {
      delivery_type_code = APIHelper.PICK_UP;
    }
    localStorage.setItem("delivery_type",delivery_type_code); 
    //this.basketComponent.clearCart(); 
    this.clearCart();

  }

  setGeometryData(data){
    this.lat = data.lat;
    this.lng = data.lng;
  }

  clearCart(){
     let targetUnit:any = {};

      targetUnit.token = APIHelper.DEFAULT_TOKEN;
      targetUnit.selected_partner_list = [];
      targetUnit.selected_employer = null;
      targetUnit.lock_index = 0;    
      targetUnit.register_customer_info_flag =false;
      targetUnit.register_customer_data = null;
      targetUnit.userInfo = null;
      targetUnit.covers = 1;
      targetUnit.delivery_date = new Date();
      targetUnit.tables = [];
      targetUnit.activePhase = {
        lastCPNumber:  null,
        name: "green",
        timeStamp: new Date().valueOf(),
        timerText: "00:00:00"
      }
      targetUnit.visitors = [{ dishes: [[], []],kot:[],deleted_items:[],temp_client_reference_code:this.getTempClientReferenceCode()}];    
      this.stateGuard.entrust("selects-box.component:currentUnit",targetUnit);
      this.stateGuard.entrust("payment.component:orderDetail",{});
  }

  setDeliveryType() {    
    this.isRestaurant = localStorage.getItem('isRestaurant');
    if(this.placeType == 'here' || this.placeType == "pickup"){
      if(this.isRestaurant == 'false' || this.placeType == "pickup") {
        this.isFoodOrderBox = 'true';
        this.isAppSelectBox = 'false';
      } else {
        this.isFoodOrderBox = 'false';
        this.isAppSelectBox = 'true';         
      }
    } else {
      this.isAppSelectBox = 'false';
    }
  }
  getCurrentPlaceType () {
    this.placeType = this.route.snapshot.params.type;
    return this.route.snapshot.params.type;
  }

  getTempClientReferenceCode() {
    let device_type = JSON.parse(localStorage.getItem('device_key'));              
    let terminal_name = (device_type && device_type.terminal_name)? device_type.terminal_name: null;
    let teminal_id  = (device_type && device_type.terminal_id)? device_type.terminal_id: null;            
    let temp_client_reference_code = terminal_name+"-"+teminal_id+'/WBP' + (new Date()).getTime();      
    return temp_client_reference_code;
  }

  reactOnAddingDish (parcel) {     
    var self = this;
    let delivery_type_code = APIHelper.IMMEDIATE;
    if(this.placeType == "home") {
      delivery_type_code = APIHelper.HOME_DELIVERY;
    } else if(this.placeType == "pickup") {
      delivery_type_code = APIHelper.PICK_UP;
    }
    localStorage.setItem("delivery_type",delivery_type_code);
    let tcurrentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    if(tcurrentUnit) {
      tcurrentUnit.delivery_type = delivery_type_code;
      if(tcurrentUnit["visitors"] && tcurrentUnit["visitors"][0]) {
        tcurrentUnit["visitors"][0]["temp_client_reference_code"] = this.getTempClientReferenceCode();      
      }
    }
    this.router.navigate(["/pages/register/food-ordering"])
    self.basketComponent.addToBasket(parcel,true);    
  }

  proceedToFoodOrdering () {
    this.router.navigate(["pages/register/food-ordering"]);
  }

  updateState(parcel) {
    this.placeType = parcel;
    this.setDeliveryType();
  }
}

