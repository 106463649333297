import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-covers-modal',
  templateUrl: './covers-modal.component.html',
  styleUrls: ['./covers-modal.component.less']
})
export class CoversModalComponent implements OnInit, AfterViewInit {
  decision: string;
  coversAmount: number;
  targetUnit: any;
  modal = {covers: null};
  @ViewChild('coversInput',{static: false}) coversInput;
  constructor(public modalRef: BsModalRef) { }
  
  ngOnInit() {

    this.setFocus();

  }
  ngAfterViewInit () {
    document.querySelector("#covers-amount")["focus"]();
  }

  setFocus(){
    setTimeout(()=>{
      document.querySelector("#covers-amount")["focus"]();
    },100);
  }

  submitCoverModal(){
    this.decision='done';
    this.modalRef.hide()
  }

}
