import { Component, OnInit, HostListener } from '@angular/core';
import { APIHelper } from '../helpers/api-helper';
import { Router } from '@angular/router';
import { AppStrings } from '../helpers/app-strings';
import { ShopUserService } from '../helpers/services/shop-user.service';
import { StateGuardService } from '../state-guard.service';
import { MerchantProfileService } from '../helpers/services/merchant-profile.service';
import { ShopConfigurationService } from '../helpers/services/shop-configuration.service';
import { Md5 } from 'ts-md5/dist/md5';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
import { BillNumberModalComponent } from '../bill-number-modal/bill-number-modal.component';
import { DemoModalServiceStaticComponent } from '../demo-modal-service-static/demo-modal-service-static.component';
import { SelectsBoxComponent } from '../selects-box/selects-box.component';
import { MenuComponent } from '../menu/menu.component';
import { BasketComponent } from '../basket/basket.component';
import { ShopLoginService } from '../../pages/helpers/shopLogin.service';
import { CommonService } from '../../pages/helpers/services/common.service';
import { BaseConfig } from '../helpers/api-config';
import { CustomerService } from '../helpers/services/customer.service';
import { MembershipService } from '../helpers/services/membership.service';
import { LicenseRenewModalComponent } from '../license-renew-modal/license-renew-modal.component';
import { ThirdPartyOrderService } from '../helpers/services/third-party-orders.service';
/*import { RecommenderService } from '../../pages/helpers/services/recommender.service';*/


@Component({
	selector: 'app-personal-pin',
	templateUrl: './personal-pin.component.html',
	styleUrls: ['./personal-pin.component.sass'],
})
export class PersonalPinComponent implements OnInit {

	correct_pin: number[] = [1, 5, 9, 3];
	input_pin: number[] = [];
	correct: boolean = true;
	loading: boolean = true;
	printLogo: any;
	public modalRef: BsModalRef;
	public modalRefBill: BsModalRef;
	public subscriptions: Subscription[] = [];
	private unsubscribe() {
		this.subscriptions.forEach((subscription: Subscription) => {
			subscription.unsubscribe();
		});
		this.subscriptions = [];
	}
	valid: boolean;
	pin: string;
	defaultVisitor = {
		auth_token: APIHelper.DEFAULT_TOKEN,
		consumer_name: "Guest",
		lastVisit: "",
		lastCheck: 0,
		lastCheckCurrency: "INR",
		email: "",
		mobile_no: "0000000000",
		points: 0,
		place: "Here",
		distance: ' - kms'
	}
	private showKeyBoard: boolean = false;
	private merchantProfile: any = {};
	private shopConfiguration: any = {};
	private isManagerLogin: boolean = false;
	private shopUserList: any = [];
	private selectedUser: string = '_select_user';
	constructor(private router: Router,
		private modalService: BsModalService,
		private modalRefBillService: BsModalService,
		private strings: AppStrings,
		private demoModalServiceStaticComponent: DemoModalServiceStaticComponent,
		private shopUser: ShopUserService,
		private merchantProfileService: MerchantProfileService,
		private stateGuard: StateGuardService,
		private shopConfigurationService: ShopConfigurationService,
		private customerService: CustomerService,
		private membershipService: MembershipService,
		private commonService: CommonService,
		private thirdPartyOrderServices: ThirdPartyOrderService
		/*private recommenderService: RecommenderService*/) { }

	ngOnInit() {
		if (!APIHelper.getInstance(null).isLicenceVerified()) {
			this.demoModalServiceStaticComponent.logout();
			return;
		}
		this.pin = '';
		this.loading = true;

		this.validateLicense();

		if (!navigator.onLine && localStorage.getItem('merchant_profile') && localStorage.getItem('shop_configuration')) {
			this.getUserList();
		} else {
			this.merchantProfileService.profile().subscribe((merchant_profile) => {
				var merchantProfile = merchant_profile.json();
				var shop_code = localStorage.getItem('shop_code');
				if (!shop_code) {
					this.router.navigate(['']);
				} else {
					if (merchantProfile.status === 0) {
						// localStorage.setItem('shop_name', merchantProfile.result.merchant.name);
						this.shopConfigurationService.configuration(shop_code).subscribe((shop_configuration) => {
							var shopConfiguration = shop_configuration.json();
							if (shopConfiguration.status !== 0) {
								alert(this.strings.customerViewValues().errorShopConfiguration);
							} else {
								localStorage.setItem('shop_name', shopConfiguration.result.shop_info.name);
								localStorage.setItem('currency_symbol', shopConfiguration.result.currency_symbol);
								localStorage.setItem('merchant_profile', JSON.stringify(merchantProfile.result));

								if (JSON.stringify(shopConfiguration.result.table_configuration) !== '{}') {
									localStorage.setItem('shop_configuration', JSON.stringify(shopConfiguration.result));
								} else {
									shopConfiguration.result.table_configuration = [];
									localStorage.setItem('shop_configuration', JSON.stringify(shopConfiguration.result));
								}

								let sendData: any = {
									'shop_code_list': [],
									'get_usage_count': false,
									'exclude_deleted_discount': true,
								}
								sendData.shop_code_list.push(shop_code);
								this.getAllDiscountList(sendData);
								if (shopConfiguration && shopConfiguration.result.currency == "INR") { this.getGSTStateCode(); }
							}
							this.getAllUnbundleItems();
							this.getUserList();
							this.getPaymentTypeList();
							this.getShopImage();
							this.getPartnerList();
							this.getMembershipPlan();
							this.getPartnerInfo();
							this.getAllEmployerList({ shop_code: shop_code });
						});
					} else {
						alert(this.strings.customerViewValues().errorMerchantProfile);
					}
				}
			});
		}
	}

	getPartnerInfo() {
		this.thirdPartyOrderServices.getAllDeliveryPartner("shop_code=" + localStorage.getItem("shop_code") + "&only_shop=1&only_active=1").subscribe(res => {
			var apiRes = res.json();
			if (apiRes.status === 0) {
				localStorage.setItem("delivery_partner_info", JSON.stringify(apiRes.result.partner_info))
			}
		});
	}
	validateLicense() {
		if (navigator.onLine) {
			let shop_code = localStorage.getItem('shop_code');
			this.commonService.validateLicense(shop_code).subscribe((res) => {
				let result = res.json();
				var currDate = new Date();
				this.getRecommendationModel(localStorage.getItem('license_renew_update_date'))
				localStorage.setItem('license_renew_update_date', currDate.toISOString());
				if (result.status === 0 && result.result.merchant_licence[0]) {
					let remainingDays = result.result.merchant_licence[0].remainingDays + 1;
					let month_wise_subscription_value = result.result.merchant_licence[0].month_wise_subscription_value;
					let annual_subscription_value = result.result.merchant_licence[0].annual_subscription_value;
					let monthly_subscription_value = result.result.merchant_licence[0].monthly_subscription_value;


					if (remainingDays <= 7) {
						this.showModalForLicenseRenew({ days: remainingDays, month_wise_subscription_value: month_wise_subscription_value, annual_subscription_value: annual_subscription_value, monthly_subscription_value: monthly_subscription_value });
					}
				}

			});
		}
	}

	getRecommendationModel(license_renew_update_date) {
		// let license_renew_update_date = localStorage.getItem('license_renew_update_date');
		if (license_renew_update_date) {
			let lastUpdateDate = new Date(license_renew_update_date);
			let date = new Date()
			var dd = date.getDate() + '';
			var mm = (date.getMonth() + 1) + '';
			var yyyy = date.getFullYear();
			if (dd.length == 1) { dd = '0' + dd }
			if (mm.length == 1) { mm = '0' + mm }
			let str = yyyy + '/' + mm + '/' + dd;
			let curr_date = new Date(str);

			if (lastUpdateDate < curr_date) {
				/*this.recommenderService.getmodel();*/
			}
		} else {
			/*this.recommenderService.getmodel();*/
		}
	}

	showModalForLicenseRenew(data) {
		this.subscriptions.push(this.modalService.onHide.subscribe(() => {
			if (this.modalRef.content.decision === 'skip') {
			} else if (this.modalRef.content.decision === 'yes') {
				if (this.modalRef.content.remainingDays <= 0) {
					this.router.navigate(['']);
				}
			}
			this.unsubscribe();
		}));
		this.modalRef = this.modalService.show(LicenseRenewModalComponent, {
			class: 'common-modal', keyboard: false,
			backdrop: true, ignoreBackdropClick: true
		});
		this.modalRef.content.decision = "";
		this.modalRef.content.remainingDays = data.days;
		this.modalRef.content.subscription_amount = data.subscription_amount;
		this.modalRef.content.month_wise_subscription_value = data.month_wise_subscription_value;
		this.modalRef.content.annual_subscription_value = data.annual_subscription_value;
		this.modalRef.content.monthly_subscription_value = data.monthly_subscription_value;

	}

	getMembershipPlan() {
		var shopcode = localStorage.getItem('shop_code');
		this.membershipService.getMembershipPlans({ applicable_shops: [shopcode], only_active: true }).subscribe((res) => {
			var result = res.json();
			if (result.status === 0) {
				if (Array.isArray(result.result.membership_list)) {
					localStorage.setItem('membership_list', JSON.stringify(result.result.membership_list));
				}
			}
		});
	}

	getAllDiscountList(param) {
		this.shopConfigurationService.getAllDiscountList(param).subscribe((res) => {
			var result = res.json();
			if (result.status == 0) {
				localStorage.setItem('discount_list', JSON.stringify(result.result.discount_list));
				localStorage.setItem('isDiscountAvailable', JSON.stringify(result.result.discount_list.length > 0 ? true : false));

			}
		});
	}

	getAllEmployerList(param) {
		this.customerService.get_all_employers(param).subscribe((res) => {
			var result = res.json();
			if (result.status == 0) {
				result.result.forEach(emp => {
					emp.name = emp.employer_name;
				})
				localStorage.setItem('employer_list', JSON.stringify(result.result));
			}
		});
	}

	getGSTStateCode() {
		this.customerService.get_gst_state_code().subscribe((res) => {
			var result = res.json();
			if (result.status == 0) {
				result.result.forEach(st => {
					st.name = st.state;
				})
				localStorage.setItem('gst_state_codes', JSON.stringify(result.result));
			}
		});
	}

	getPartnerList() {

		let shop_code = localStorage.getItem('shop_code');
		this.customerService.getPartners(shop_code).subscribe((res) => {
			var result = res.json();
			if (result.status === 0) {
				let data = result.result.partner_info;
				let tempList = [];
				let is_online_partner_available = 0;
				if (data && data.length > 0) {
					data.forEach(partner => {
						let tempObject = {
							id: partner.id,
							name: partner.name,
							online_partner_id: partner.online_partner_id,
							is_active: partner.is_active
						};
						if ((partner.online_partner_id || partner.online_partner_id == 0) && partner.is_active) {
							is_online_partner_available = 1
						}
						tempList.push(tempObject);
					});
				}
				localStorage.setItem("partner_list", JSON.stringify(tempList));
				localStorage.setItem("is_online_partner_available", JSON.stringify(is_online_partner_available));
			}
		});
	}

	getAllUnbundleItems() {
		this.shopConfigurationService.getAllUnbundleItems({ shop_code: localStorage.getItem('shop_code') }).subscribe((res) => {
			var response = res.json();
			if (response.status === 0) {
				let tempList = response.result;
				if (Array.isArray(tempList) && tempList.length > 0) {
					localStorage.setItem('all_unbundle_items_list', JSON.stringify(tempList));
				} else {
					localStorage.setItem('all_unbundle_items_list', '[]');
				}
			}
		});
	}

	getPaymentTypeList() {

		this.shopConfigurationService.getPaymentTypes(localStorage.getItem('shop_code')).subscribe((res) => {
			var paymentTypes = res.json();
			if (paymentTypes.status !== 0) {
				alert(paymentTypes.message);
			} else {
				let list = paymentTypes.result;
				localStorage.setItem('payment_types', JSON.stringify(list));
			}
		});
	}

	getUserList() {
		try {
			this.merchantProfile = JSON.parse(localStorage.getItem('merchant_profile'));
			this.shopConfiguration = JSON.parse(localStorage.getItem('shop_configuration'));

			if (!navigator.onLine && localStorage.getItem('shop_user_list')) {
				this.shopUserList = JSON.parse(localStorage.getItem('shop_user_list'));
				this.loading = false;
			} else {
				this.shopUser.list(this.shopConfiguration.shop_code).subscribe((res) => {
					var result = res.json();

					if (result.status === 0) {
						this.shopUserList = result.result.filter((usr: any) => usr.is_active);
					} else {
						this.shopUserList = [];
					}
					this.loading = false;
					localStorage.setItem('shop_user_list', JSON.stringify(this.shopUserList));
				});
			}
		} catch (e) {
			//alert(this.strings.shopUserLogin().invalidProfile);
			//this.router.navigate(['']);
		}
	}

	inputPin(value) {
		if (!this.loading) {
			this.input_pin.push(Number(value));
			if (this.input_pin.length == 4) {
				this.equalPin(this.input_pin);
				this.input_pin = [];
			}
		}
	}

	getRefBillNum() {

		this.modalRefBill = this.modalRefBillService.show(BillNumberModalComponent, { class: 'centred-modal' });
		this.modalRefBill.content.decision = "";
		this.subscriptions.push(this.modalRefBillService.onHide.subscribe(() => {
			if (this.modalRefBill.content.decision === 'done') {
				let seletedNumber = this.modalRefBill.content.selectedNumber;
				localStorage.setItem('ref_bill_num', seletedNumber);
				let date = new Date()
				var dd = date.getDate() + '';
				var mm = (date.getMonth() + 1) + '';
				var yyyy = date.getFullYear();
				if (dd.length == 1) { dd = '0' + dd }
				if (mm.length == 1) { mm = '0' + mm }
				let str = yyyy + '/' + mm + '/' + dd;
				localStorage.setItem('ref_bill_update_date', date.toISOString());
				this.router.navigate(['/pages/register/place-selection/here']);
			}
			this.unsubscribe();
		}));
	}

	equalPin(inputPinValue) {
		var scope = this;
		var userList = JSON.parse(localStorage.getItem('shop_user_list'));
		var validUser = [];
		if (userList.length > 0) {
			validUser = userList.filter((i: any) => i.pin === Md5.hashStr(inputPinValue.join("")));
		}
		if (validUser.length > 0) {
			if (navigator.onLine) {
				this.shopUser.login(this.shopConfiguration.shop_code, validUser[0].user_name, inputPinValue.join("")).subscribe((res) => {
					var response = res.json();
					if (response.status === 0) {
						validUser[0].session_id = response.result.session_id;
						localStorage.setItem('loggedInUser', JSON.stringify(validUser[0]));
					}
				});
			}

			scope.valid = true;
			localStorage.setItem('shop_configuration', JSON.stringify(this.shopConfiguration));
			localStorage.setItem("current_customer_info", JSON.stringify(this.defaultVisitor));
			this.stateGuard.entrust("config-box.component:customer_info", JSON.parse(JSON.stringify(this.defaultVisitor)));

			localStorage.setItem('loggedInUser', JSON.stringify(validUser[0]));
			if (this.shopConfiguration.enable_tables) { // navigation after successful pin verify and shop with table settings
				this.shopConfiguration.enable_tables = true;
				localStorage.setItem('isRestaurant', 'true')
			} else {
				this.shopConfiguration.enable_tables = false;
				localStorage.setItem('isRestaurant', 'false')
			}

			let temp_ref_bill_update_date: any = localStorage.getItem("ref_bill_update_date") || new Date();
			let ref_bill_update_date = new Date(temp_ref_bill_update_date);

			if (ref_bill_update_date) {

				let date = new Date()
				var dd = date.getDate() + '';
				var mm = (date.getMonth() + 1) + '';
				var yyyy = date.getFullYear();
				if (dd.length == 1) { dd = '0' + dd }
				if (mm.length == 1) { mm = '0' + mm }
				let close_time = this.shopConfiguration.shop_close_time;
				let start_time = this.shopConfiguration.shop_open_time;


				let setCloseDate = yyyy + '/' + mm + '/' + dd + ' ' + close_time;
				let shop_close_time = new Date(setCloseDate);
				let setStartDate = yyyy + '/' + mm + '/' + dd + ' ' + start_time;
				let shop_start_time = new Date(setStartDate);
				let temp_ref_bill_update_date: any = localStorage.getItem("ref_bill_update_date") || new Date();
				let curr_time = new Date();
				let ref_bill = (typeof localStorage.getItem('ref_bill_num') == 'string') ? parseInt(localStorage.getItem('ref_bill_num')) : localStorage.getItem('ref_bill_num');

				if ((curr_time > shop_close_time && (curr_time < ref_bill_update_date || (curr_time > ref_bill_update_date && ref_bill_update_date < shop_start_time))) ||
					(curr_time > ref_bill_update_date && ref_bill_update_date < shop_start_time)) {
					let ref_bill = (typeof localStorage.getItem('ref_bill_num') == 'string') ? parseInt(localStorage.getItem('ref_bill_num')) : localStorage.getItem('ref_bill_num');

					if (ref_bill && ref_bill === -1) {
						var self = this;
						setTimeout(() => {
							self.getRefBillNum();
						}, 300)
					} else if (ref_bill && ref_bill !== -1) {
						var local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
						if (!local_config.disable_reset_bill_number) {
							this.modalRef = this.modalService.show(ConfirmationModal, { class: 'centred-modal' });
							this.modalRef.content.decision = "";
							this.modalRef.content.text = "You have not closed your shop for the last day, do you want to close now?";

							this.subscriptions.push(this.modalService.onHide.subscribe(() => {
								if (this.modalRef.content.decision === 'yes') {
									let date = new Date()
									var dd = date.getDate() + '';
									var mm = (date.getMonth() + 1) + '';
									var yyyy = date.getFullYear();
									if (dd.length == 1) { dd = '0' + dd }
									if (mm.length == 1) { mm = '0' + mm }
									let str = yyyy + '/' + mm + '/' + dd;
									localStorage.setItem('ref_bill_num', '-1');
									localStorage.setItem('ref_bill_update_date', date.toISOString());
									localStorage.setItem('kot_number', '1');
									localStorage.setItem('check_print_number', '1');
									setTimeout(() => {
										this.getRefBillNum();
									}, 300)


									localStorage.setItem('temp_orders_list', '[]');
									this.unsubscribe();
									//this.router.navigate(['verify-pin']);
								} else {
									this.unsubscribe();
									this.router.navigate(['/pages/register/place-selection/here']);
								}
							}));
						} else {
							localStorage.setItem('temp_orders_list', '[]');
							this.router.navigate(['/pages/register/place-selection/here']);
						}
					} else {
						this.router.navigate(['/pages/register/place-selection/here']);
					}
				} else {
					this.router.navigate(['/pages/register/place-selection/here']);
				}
			} else {
				this.router.navigate(['/pages/register/place-selection/here']);
			}
		} else {
			scope.correct = false;
			setTimeout(() => { scope.correct = true; }, 300);
		}
	}

	deletePin() {
		this.input_pin.splice(this.input_pin.length - 1, 1);
	}

	getShopImage() {
		var outerscope = this;
		var canvas: any = document.getElementById('canvas');
		// var context = canvas.getContext('2d');
		var image = new Image();
		image.onload = function () {
			var shop_image = { src: "", height: 0, width: 0 };
			// context.drawImage(image, 200, 0, 300, 80);
			var scaledDimensions = outerscope.scaleImage(image.width, image.height);
			if (scaledDimensions.height != 0 && scaledDimensions.width != 0) {
				shop_image.height = scaledDimensions.height;
				shop_image.width = scaledDimensions.width;
			} else {
				shop_image.height = image.height;
				shop_image.width = image.width;
			}
			canvas.height = shop_image.height + 2;

			var context = canvas.getContext('2d');
			context.fillStyle = "white";
			context.fillRect(0, 0, canvas.width, canvas.height);
			context.drawImage(image, (450 - shop_image.width) / 2, 0, shop_image.width, shop_image.height);
			shop_image.src = canvas.toDataURL();
			localStorage.setItem('shop_image', JSON.stringify(shop_image));
		}
		image.crossOrigin = 'anonymous';
		image.src = BaseConfig.config.API + 'receiptlogo/' + this.shopConfiguration.shop_code + '.png?timestamp='+new Date().getTime();
		//  image.src = BaseConfig.config.API + 'receiptlogo/' + 'daulat' + '.png';
	}

	scaleImage(width, height) {
		var scaledDimensions = { height: 0.0, width: 0.0 };
		//scaling image down if too big
		if (width > 260 || height > 230) {
			var ratio = width / height;
			if (width > height) {
				if (width > 260) {
					scaledDimensions.height = 260 / ratio;
					scaledDimensions.width = 260;
				}
			} else if (width < height) {
				if (height > 230) {
					scaledDimensions.height = 230;
					scaledDimensions.width = 230 * ratio;
				}
			} else {
				scaledDimensions.height = 230;
				scaledDimensions.width = 230;
			}
		}
		return scaledDimensions;
	}

	@HostListener('window:keyup', ['$event']) onPress(event: KeyboardEvent) {
		if (Number(event.key) || event.key.toString() == '0') {
			this.inputPin(Number(event.key));
		}
		if (event.key.toString() == 'Delete' || event.key.toString() == 'Backspace') {
			this.deletePin();
		}
	}

}
