import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-save-diary-modal',
  templateUrl: './save-diary-modal.component.html',
  styleUrls: ['./save-diary-modal.component.less']
})
export class SaveDiaryModalComponent implements OnInit {
  decision: string;
  amount: number;
  isOutStandingOrder: boolean;  
  currencySymbol:any;  
  constructor(public modalRef: BsModalRef) { }
  
  ngOnInit() {  	
  	let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shop_config.currency;
  }

}
