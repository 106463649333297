/**
 * (c) OneGreenDiary Software Pvt. Ltd.
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform,
 *    solely reside with the third-party.  
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Once OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without
 *    prior agreement with OneGreenDiary Software Pvt. Ltd.
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';



import { APIHelper } from '../api-helper';

@Injectable()
export class CustomerService {
    constructor(private http: Http) { }

    info(auth_token: string) {
        let shop_code = localStorage.getItem('shop_code');
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CONSUMER_INFO, { 'auth_token': auth_token, 'shop_code': shop_code });
    }

    basicInfo(auth_token: string) {
        let shop_code = localStorage.getItem('shop_code');
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_BASIC_CONSUMER_INFO, { 'auth_token': auth_token, 'shop_code': shop_code });
    }

    register(mobile_no: string, email: string, name: string, shop_code: string) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_REGISTER_CUSTOMER, { 'mobile_no': mobile_no, 'email': email, 'consumer_name': name, 'shop_code': shop_code });
    }

    registerCustomer(mobile_no: string, local_user_id: string, name: string, shop_code: string) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_REGISTER_CUSTOMER, { 'mobile_no': '' + mobile_no, local_user_id: local_user_id, 'consumer_name': name, 'shop_code': shop_code });
    }

    contactSearch(mobile_no: string, shop_code: string) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CONTACT_SEARCH, { 'mobile_no': '' + mobile_no, 'shop_code': shop_code });
    }
    addAddress(auth_token: string, shop_code: string, address: any) {
        return new Promise((resolve, reject) => {
            if (address.address && address.address.trim()) {
                APIHelper.getInstance(this.http).apiCall(APIHelper.API_ADD_ADDRESS, { 'auth_token': auth_token, 'shop_code': shop_code, 'address': address }).subscribe((response) => {
                    var rdata = response.json();
                    resolve(rdata);
                }, error => {
                    resolve({ status: -1, message: "internal server error" });
                });
            } else {
                resolve({ status: -1, message: "address can't be empty" });
            }
        });
    }

    update(auth_token: string, customer_details: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_UPDATE_CUSTOMER, { 'auth_token': auth_token, 'customer_details': customer_details });
    }

    getPartners(shopCode: string) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_PARTNER_LIST, 'shop_code=' + shopCode + "&only_active=1");
    }

    contact_search(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_CONTACT_SEARCH, data);
    }

    update_customer_data(data: any, auth_token) {
        APIHelper.getInstance(null).setAuthTokenForUpdateUser(auth_token);
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_UPDATE_CUSTOMER, data);
    }

    get_all_employers(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_ALL_EMPLOYERS, data);
    }

    add_employer(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_ADD_EMPLOYER, data);
    }

    update_employer(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_UPDATE_EMPLOYER, data);
    }

    get_gst_state_code() {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_GST_STATE_CODES, "");
    }

    getDetailByPinCode(pincode) {
        return this.http.get("https://api.postalpincode.in/pincode/" + pincode, {});
    }
}
