import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-kot-item-cancel-reason-modal',
  templateUrl: './kot-item-cancel-reason-modal.component.html',
  styleUrls: ['./kot-item-cancel-reason-modal.component.less']
})
export class CancelKotItemReasonModal implements OnInit {
  decision: string = '';
  selectedReason:any=undefined;
  reasonList:any;
  constructor(public modalRef: BsModalRef) { }
  ngOnInit() {
  }

  send(){
    if(!this.selectedReason){
      alert("Select any reason");
      return;
    }
    this.modalRef.hide();
  }

}

