import { Component, OnInit, Output,EventEmitter,ViewChild  } from '@angular/core';
import { StateGuardService } from '../state-guard.service';
import { Router } from '@angular/router';
import { InventoryModalComponent } from '../inventory-modal/inventory-modal.component';
import { KotHelper } from '../helpers/kot-helper';
import { SeparateBillHelper } from '../helpers/Separate-bill-helper';
import { Subscription } from 'rxjs';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { Dish } from '../dish';
import { NgForm } from '@angular/forms';
import { CustomerMessage } from '../customer-message.service';
import { APIHelper } from '../helpers/api-helper';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { SelectSeatModalComponent } from '../select-seat-modal/select-seat-modal.component';
import { VerifyPinModalComponent } from '../verify-pin-modal/verify-pin-modal.component';
import { CancelOrderReasonModalComponent } from '../cancel-order-modal/cancel-order-modal.component';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
import { CommonService } from '../helpers/services/common.service';
import { OpenCashRegisterModalComponent } from '../open-cash-register-modal/open-cash-register-modal.component';
import { BaseConfig } from '../helpers/api-config';
import { CaptainAppHelper } from '../helpers/captain-app-helper';
import { DiscountHelper } from '../helpers/discount-helper';
import { PrintHelper } from '../helpers/print-helper';
import { CancelKotItemReasonModal } from '../kot-item-cancel-reason-modal/kot-item-cancel-reason-modal.component';
import { Messenger } from '../messenger.service';
import { ShopConfigurationService } from '../helpers/services/shop-configuration.service';
import { MultiLanguageTitles } from '../helpers/multi-language-titles';
import { remove,sumBy } from 'lodash-es';
import { GeneralHelper } from '../helpers/genral-helper';

import 'assets/js/epos-print-3.0.0.js';
//dot reference https://olado.github.io/doT/index.html
import * as dot from 'dot';

declare var epson: any;

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.less']
})
export class BasketComponent implements OnInit {
  @Output() removingPayment: EventEmitter<any> = new EventEmitter();
  @Output() doPayment: EventEmitter<any> = new EventEmitter();
  @Output() addingBasketItem: EventEmitter<any> = new EventEmitter();
  @Output() parkTheOrder: EventEmitter<any> = new EventEmitter();

  locations;
  currentUnit;
  stagedVisitor;
  currentLocation;
  extraCharges;
  groupedTaxes;
  isManualDiscountAvailable: boolean = false;
  isLoadingShow: boolean = false;
  isRestaurant:boolean = false;
  public selectedVisitorIndex:any;
  currentVisitorIndex:any=null;
  orderDetail;
  selectedSeatNo:any=undefined;
  isFromMoveAllItems:boolean=false;
  isRemoveDiscountShow:boolean=false;
  isKotView:boolean=false;
  isFnCallFromCancelOrder:boolean=false;
  currentEditedExtraChargeIndex:number;
  isDataAvailable:boolean=false;
  dishDetailsDiscount = 0;
  isResetCartEnable:boolean = false;
  special_order_flag:number = 0;
  shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
  localConfiguration = null;
  public modalRef: BsModalRef;
  public subscriptions: Subscription[] = [];
  private state;
  public currencySymbol:any;
  public reasonList = [
        {
          'text':'Damaged',
          'id': APIHelper.KOT_DAMAGED
        },
        {
          'text':'Customer Cancelled',
          'id': APIHelper.KOT_CUSTOMER_CANCELLED
        },
        {
          'text':'Delivery Delayed',
          'id': APIHelper.KOT_DELIVERY_DELAYED
        },
        {
          'text':'Other',
          'id': APIHelper.KOT_OTHER
        }
  ];
  private moreOptionsForDefaultListModel = [
    /*{ text: "Edit", callback: "runEdit", active: true },*/
    /*{ text: "Move Items", callback: "", active: false },*/
    { text: "Combine Seat", callback: "combineSeat", active: false },
    { text: "Single Seat Checkout", callback: "addToSingleCheckout", active: false },
    { text: "Move All Item To Seat", callback: "moveAllItemsToSeat", active: false },
    { text: "Cancel Order", callback: "cancelOrder", active: false },
    { text: "Note", callback: "", active: false },
    { text: "Kot view", callback: "showKotView", active: false }
  ]
  private moreOptionsListModel = [
    /*{ text: "Edit", callback: "runEdit", active: true },*/
    /*{ text: "Move Items", callback: "", active: false },*/
    /*{ text: "Combine Seat", callback: "", active: false },*/
    { text: "Single Seat Checkout", callback: "addToSingleCheckout", active: false },
    { text: "Move All Item To Seat", callback: "moveAllItemsToSeat", active: false },
    { text: "Cancel Order", callback: "cancelOrder", active: false },
    { text: "Note", callback: "", active: false }
  ]


  builder;
  shopConfiguration;
  merchantProfile;
  popupWin;

  printerUtility = false;

  @ViewChild('dishDetailsForm',{static: false}) dishDetailsForm;
  @ViewChild('extraChargeDetailsForm',{static: false}) extraChargeDetailsForm;
  constructor(private stateGuard: StateGuardService,private messageService: MessageService,
             private customerMessageService:CustomerMessage,private router: Router ,private commonService:CommonService,
             private modalService: BsModalService,private kotHelper:KotHelper,
             private captainAppHelper:CaptainAppHelper,private discountHelper:DiscountHelper,private messagePopup: Messenger,
             private printHelper:PrintHelper,private shopConfigurationService: ShopConfigurationService,
             ) {
    this.shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));    
    this.messagePopup.getBasketComponentMessage().subscribe(message => {
      if(message && message.isResetCartEnable) {
        this.isResetCartEnable = (message.isResetCartEnable == "true") ? true : false;
      }
    });
    if (localStorage.getItem('PRINT_UTILITY')) {
      this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
    }
    this.commonService.notifyPrinterObservable$.subscribe(result => {
      this.printerUtility = result;
    });
  }

  resetCart(){
    if(this.currentVisitorIndex == 0 || !this.currentVisitorIndex ){
      if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]) {
        this.currentUnit.visitors.forEach(visitor => {
          if(visitor.dishes && visitor.dishes[0]) {
            visitor.dishes[0]=[];
            visitor.kot=[];
          }
          this.currentUnit.groupedTaxes = [];
          delete this.currentUnit.other_discounts;
          delete this.currentUnit.temp_other_discounts;
          delete this.currentUnit.external_discount_list;
          delete this.currentUnit.cart_items;
          delete this.currentUnit.subtotal;

        });
        this.stateGuard.entrust("selects-box.component:currentUnit",this.currentUnit);
      }
    }else{
      if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[this.currentVisitorIndex]) {
        this.currentUnit.visitors[this.currentVisitorIndex].dishes[0]=[];
        this.currentUnit.visitors[this.currentVisitorIndex].kot=[];

        if(this.currentUnit.visitors[this.currentVisitorIndex].dishes[0].length == 0){
          this.currentUnit.groupedTaxes = [];
        }
        this.stateGuard.entrust("selects-box.component:currentUnit",this.currentUnit);
      }
    }
  }

  currency: string;

  clearCustomerInfo() {

    let sData:any = {
      auth_token : APIHelper.DEFAULT_TOKEN,
      name: "",
      consumer_name:"",
      email: "",
      mobile_no: "",
      zip_code: "",
      address_1: "",
      city: "",
      lastVisit: "",
      lastCheck: 0,
      lastCheckCurrency: "INR",
      points: 0,
      diary_amt:0,
      place: "here",
      distance: ' - kms',
      places : [
        {id: 1, name: 'here'},
        {id: 2, name: 'home'},
        {id: 3, name: 'pickup'}
      ]
    };
    localStorage.setItem("current_customer_info",JSON.stringify(sData));
    localStorage.setItem("delivery_type",APIHelper.IMMEDIATE);
    this.customerMessageService.sendCustomerInfo(sData,false);
  }

  getInitialData(){

    var self = this;
    if(!this.shopConfig){
      this.router.navigate(['verify-pin']);
      return;
    }
    try {
      this.localConfiguration = JSON.parse(localStorage.getItem("local_configuration"))
    } catch(e) {}

    this.currencySymbol = this.shopConfig.currency;
    this.locations = this.stateGuard.obtain("selects-box.component:places") || [];
    this.orderDetail = this.stateGuard.obtain("payment.component:orderDetail") || {};
    this.currency = this.stateGuard.obtain("app").currency;
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
    if(!(this.currentUnit.isParked || this.currentUnit.isFromOnHold || this.currentUnit.isOnHold)){
      this.moreOptionsForDefaultListModel.push({text: "Reset cart", callback: "resetCart", active: false});
      this.moreOptionsListModel.push({text: "Reset cart", callback: "resetCart", active: false});
    }
    if(this.currentUnit.isParked || this.currentUnit.isFromOnHold || this.currentUnit.isOnHold) {
      this.isResetCartEnable = false;
    } else {
      this.isResetCartEnable = true;
    }

    if(this.currentUnit.visitors && this.currentUnit.visitors[0]) {
      this.stagedVisitor = this.currentUnit.visitors[0];
      this.stagedVisitor.on = true;
    }
    this.locations.forEach(location => {
      if(location.name == this.currentUnit.location){
        location.units.forEach(unit => {
          if(unit.tables && unit.tables.length>0 && this.currentUnit.tables && this.currentUnit.tables.length>0  && unit.tables.join(",") === this.currentUnit.tables.join(",")) {
            this.currentLocation = location;
          }
        });
      }
    });

    this.isDataAvailable = true;

    this.state = {
      moreOptionsMap: (this.currentUnit.visitors && this.currentUnit.visitors[0])? new Array(this.currentUnit.visitors.length).fill(false) : []
    }
    this.extraCharges = [];
    setTimeout(()=>{
      self.getExtraChargesList();
    },500);

    this.groupedTaxes = [];
    if(this.currentUnit) {
      if((this.currentUnit.register_customer_info_flag && this.currentUnit.register_customer_data && this.currentUnit.register_customer_data.contact_search_data && 
        (this.currentUnit.register_customer_data.contact_search_data.local_user_id || this.currentUnit.register_customer_data.contact_search_data.mobile_no)) || 
        (this.currentUnit.userInfo && this.currentUnit.userInfo.mobile_no)){
        this.special_order_flag = 1;
        if(this.currentUnit.visitors && this.currentUnit.visitors.length>0) {
          this.currentUnit.visitors.forEach(visitor => {            
            if(this.special_order_flag == 1 && visitor && visitor.dishes.length>0 && visitor.dishes[0].length>0){
              visitor.dishes[0].forEach(dish =>{
                if(this.special_order_flag == 1 && dish.special_order_information && dish.special_order_information.supplier_id) {
                  this.special_order_flag = 3;
                }
              });
            }
          });
        }        
      } else {
        this.special_order_flag = 0;
      }

      //check for discount in case of captain app order.
      //table_status assume as only available in captain app order.
      if(this.currentUnit.table_status) {
        this.checkForDiscount();
      }      
    }

    let cSub: Subscription = this.customerMessageService.getCustomerInfo().subscribe(message => {
     
      if(message && message.CustomerInfo) {
        if(!message.is_partial_change || (message.is_partial_change && message.CustomerInfo.hasOwnProperty('mobile_no'))) {
          this.special_order_flag = (message.CustomerInfo.mobile_no) ? 1 : 0;
        }       
      }      
    });
  }

  getExtraChargesList(){
    var currentShippingType = localStorage.getItem("delivery_type") || "";
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];

    if(this.currentUnit.visitors && this.currentUnit.visitors[0]) {
      for (var i = 0; i < this.currentUnit.visitors.length; ++i) {
        if(this.currentUnit.visitors[i].kot && this.currentUnit.visitors[i].kot.length>0){
          remove(this.moreOptionsForDefaultListModel,it=> it.callback == 'moveAllItemsToSeat');
          remove(this.moreOptionsForDefaultListModel,it=> it.callback == 'combineSeat');
          remove(this.moreOptionsForDefaultListModel,it=> it.callback == 'resetCart');
          remove(this.moreOptionsListModel,it=> it.callback == 'resetCart');
          remove(this.moreOptionsListModel,it=> it.callback == 'moveAllItemsToSeat');
          break;
        }
      }
    }
    if(this.shopConfig.enable_extra_charges &&  this.currentUnit && !this.currentUnit.isExtraChargeChanges){
      this.extraCharges = [];
      for(var extra_charge of this.shopConfig.extra_charges) {

        if(!extra_charge.shipping_types || (Array.isArray(extra_charge.shipping_types) && extra_charge.shipping_types.indexOf(currentShippingType) != -1)) {
          var charge = {
              'charge_name' : extra_charge.charge_name,
              'is_value_in_percentage': extra_charge.is_value_in_percentage,
              'edited' : false,
              'rate' : parseFloat(extra_charge.value),
              'value' : (extra_charge.is_value_in_percentage ? 0 : extra_charge.value),
              'expanded' : false
          };
          this.extraCharges.push(charge);
        }
      }
      //console.log("extra charge ",this.extraCharges)
    }else if (this.shopConfig.enable_extra_charges  && this.currentUnit && this.currentUnit.isExtraChargeChanges){
      let charges = JSON.parse(JSON.stringify(this.currentUnit.ExtraCharges));
      if(Array.isArray(charges) && charges[0]){
        charges.forEach((charge)=>{
          for (var i = 0; i < this.shopConfig.extra_charges.length; ++i) {
            if(charge.charge_name == this.shopConfig.extra_charges[i].charge_name){
              this.extraCharges.push(charge);
              break;
            }
          }
        });
      }
    } else {
      this.extraCharges = [];
    }

  }

  ngOnInit() {
    var self = this;
    this.isRestaurant = (localStorage.getItem('isRestaurant') == 'true') ? true : false;
    this.isManualDiscountAvailable = (localStorage.getItem('isDiscountAvailable') == 'true') ? true : false;
    this.shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
    if(localStorage.getItem('is_page_refresh') == 'true'){
      setTimeout(()=>{
        self.getInitialData();
      },1200);
      setTimeout(()=>{
        localStorage.setItem('is_page_refresh','false');
      },1700);
    }else{
      self.getInitialData();
    }
   
  }

  showKotView(){
    this.isKotView = true;
  }

  cancelOrder(tempCurrentVisitorIndex){
    return new Promise<boolean>((resolve,reject)=>{
      let currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];

      if(tempCurrentVisitorIndex >= 0) {
        this.currentVisitorIndex = tempCurrentVisitorIndex;
      }
      //find is there any item deleted after print ot.
      let tempKotItems = [];
      if(currentUnit.visitors && currentUnit.visitors[0]){

        currentUnit.visitors.forEach((visitor,index) =>{
          if(!this.currentVisitorIndex || this.currentVisitorIndex == index) {
            if(visitor && visitor.kot && visitor.kot.length>0){
                var tempKot:any = JSON.parse(JSON.stringify(visitor.kot));
                tempKot.forEach( (kot) =>{
                  kot.items.forEach((item)=>{
                    let is_item_found = false;
                    tempKotItems.forEach((cart_item) =>{
                      if(cart_item.item_code == item.item_code){
                        is_item_found = true;
                        cart_item.item_count += item.item_count;
                      }
                    })
                    if(!is_item_found){
                      tempKotItems.push(item);
                    }
                  })
                });
            }
          }
        })
      }

      if(tempKotItems.length == 0) {
        resolve(false);
        this.messageService.add({ severity: 'error', summary: 'No Item for cancel', detail: 'There is no item for cancel which OT already done.' });
        return;
      }

      this.modalRef = this.modalService.show(CancelOrderReasonModalComponent, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.open_from = "basket";
      this.modalRef.content.reasonList = this.reasonList;

      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'send') {

          let reasonList = this.modalRef.content.reasonList;
          let id = this.modalRef.content.selectedReason;
          let selectedReason = null;

            this.unsubscribe();

            this.modalRef = this.modalService.show(ConfirmationModal, {class: 'centred-modal'});
            this.modalRef.content.decision = "";
            this.modalRef.content.text = "Do you really want to cancel the order?";

            this.subscriptions.push(this.modalService.onHide.subscribe(() => {
                if (this.modalRef.content.decision === 'yes') {

                  reasonList.forEach( reason =>{
                    if(reason.id.toString() == id){
                      selectedReason = reason.text;
                    }
                  });

                  //check if any other seat has any item?
                  let isMoreSeatHasItem = false;
                  if(this.currentVisitorIndex != 0) {
                    if(currentUnit.visitors && currentUnit.visitors[0]){
                      currentUnit.visitors.forEach((visitor,index) =>{
                        if(index != this.currentVisitorIndex && visitor && visitor.dishes.length>0 && visitor.dishes[0].length>0){
                          isMoreSeatHasItem = true;
                        }
                      })
                    }
                  }

                  if(selectedReason){
                    currentUnit.cancel_order_flag = 'true';
                    currentUnit.cancel_order_reason = selectedReason;
                    currentUnit.is_cancel_order_from_cart = true;
                  }
                  if(this.currentVisitorIndex == 0 || !isMoreSeatHasItem){
                    if(currentUnit.visitors && currentUnit.visitors[0]){
                      currentUnit.visitors.forEach(visitor =>{
                        if(visitor && visitor.dishes.length>0 && visitor.dishes[0].length>0){

                          if(!visitor.deleted_items){
                            visitor.deleted_items = [];
                          }
                          visitor.dishes[0].forEach((tempItem)=>{
                            let item = JSON.parse(JSON.stringify(tempItem));
                            if(item.selected_variant_item){
                              item.selected_variant_item.item_count = item.selected_variant_item.item_count * (-1);
                              item.selected_variant_item['delete_reason'] = id;
                              item.selected_variant_item['is_fully_deleted'] = true;
                              item.selected_variant_item['is_billed'] = false;
                              delete item.selected_variant_item.kot_item_count;
                              delete item.selected_variant_item.isKotfound;
                              visitor.deleted_items.push(item.selected_variant_item);
                            }else{
                              item.item_count =  item.item_count * (-1);
                              item['delete_reason'] =  id;
                              item['is_fully_deleted'] = true;
                              item['is_billed'] = false;
                              delete item.kot_item_count;
                              delete item.isKotfound;
                              visitor.deleted_items.push(item);
                            }
                          })
                          visitor.dishes[0]= [];
                        }
                      })
                    }
                    this.kotHelper.onClickOT(false,null).then((res)=>{
                      this.doPayment.emit();
                      resolve(true)
                      //this.router.navigate(['/pages/register/place-selection/here']);
                    })
                  }else{
                    this.isFnCallFromCancelOrder=true;
                    this.addToSingleCheckout(null,id);
                    resolve(true)
                  }
                }
                this.unsubscribe();
            }));
          } else {
            resolve(false);
          }
      }));
    });
  }

  combineSeat(){

    let tempUnitData = this.stateGuard.obtain("selects-box.component:currentUnit");
    let item_list = [];

    if(tempUnitData.visitors.length>1 && tempUnitData.visitors[0].dishes.length>0){
      tempUnitData.visitors.forEach(visitor =>{
        if(visitor && visitor.dishes.length>0 && visitor.dishes[0].length>0){
          visitor.dishes[0].forEach(dish =>{
            if (dish.selected_variant_item) {
              item_list.push(JSON.parse(JSON.stringify(dish)));
            } else {
              let isAvailable = false;
              /*item_list.forEach(function (i: any) {
                if (i.item_code === dish.item_code) {
                  i.item_count += dish.item_count;
                  i.tempData.temp_item_count = i.item_count;
                  isAvailable = true;
                }
              });*/
              if (!isAvailable) item_list.push(JSON.parse(JSON.stringify(dish)));
            }
          })
          visitor.dishes[0]= [];
        }
      })

      if(this.isFromMoveAllItems){
        if(this.selectedSeatNo){
          if(tempUnitData.visitors.length>1 && tempUnitData.visitors[this.selectedSeatNo]){
            tempUnitData.visitors[this.selectedSeatNo].dishes[0] = item_list;
          }
          this.selectedSeatNo = undefined;
        }
        this.isFromMoveAllItems = false;
      }else{
        if(tempUnitData.visitors.length>1 && tempUnitData.visitors[0].dishes.length>0){
          tempUnitData.visitors[1].dishes[0] = item_list;
        }
      }
    }
  }


  moveAllItemsToSeat(){

    this.modalRef = this.modalService.show(SelectSeatModalComponent, {class: 'centred-modal'});
    this.modalRef.content.unit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];

    this.modalRef.content.decision = "";
    this.modalRef.content.currentVisitorIndex = this.currentVisitorIndex;

    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'send') {

        //this.modalRef.content.artefacts.unit.activePhase.name = "green";
        //this.modalRef.content.artefacts.unit.chosen = true;

        let seat = this.modalRef.content.selectedIndex;
        this.toggleVisitor(this.modalRef.content.artefacts.unit.visitors[seat]);

        if(seat){
          this.isFromMoveAllItems = true;
          this.selectedSeatNo = seat;
          this.combineSeat();
        }
      }
      this.unsubscribe();
    }));
  }

  singleCheckPaymentDone(){
    let tempUnitData = JSON.parse(JSON.stringify(this.stateGuard.obtain("selects-box.component:currentUnit")));
    let cart = JSON.parse(localStorage.getItem('single_checkout_cart_items'));
    let tempIndex = tempUnitData.index;
    let tempPlaces = this.stateGuard.obtain('selects-box.component:places') || [];
    if(tempPlaces && tempPlaces.length > 0){
      tempPlaces.forEach(place => {

        if(place.chosen){
          place.units.forEach(unit => {
            if (unit.index) {
              let index = unit.index;
              if(index){
                if(index > 0){
                  for (var i = 0; i < unit.visitors.length; ++i) {
                    if(i == index){
                      unit.visitors[i].dishes[0] = [];
                      unit.visitors[i].kot = [];
                    }
                  }
                }
                delete unit.index;
                delete unit.cancel_order_flag;
                delete unit.cancel_order_reason;
                delete unit.is_cancel_order_from_cart;
              }
            }
          });
        }
      });
    }
    if(tempIndex){
      if(tempIndex > 0){
          for (var i = 0; i < cart.visitors.length; ++i) {
            if(i == tempIndex){
              cart.visitors[i].dishes[0] = [];
              cart.visitors[i].kot = [];
            }
        }
      }
      delete cart.index;
      delete cart.cancel_order_flag;
      delete cart.cancel_order_reason;
      delete cart.is_cancel_order_from_cart;

      this.stateGuard.entrust("selects-box.component:currentUnit", cart);
      let tempCurentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
      this.captainAppHelper.syncTable(tempCurentUnit,false);
      this.stateGuard.entrust("payment.component:orderDetail",{});

    }else{
      this.clearCart(true);
    }
  }

  removePayment(removePaymentIndex) {;
    if(this.orderDetail && this.orderDetail.payment) {
      let removedPayment = this.orderDetail.payment.splice(removePaymentIndex,1);
      this.removingPayment.emit(removedPayment);
    }
  }

  onClickBackBtn(){
    this.isKotView = false;
  }

  addToSingleCheckout(visitor,selectedReason) {

    var index = 0;

    if(this.isFnCallFromCancelOrder){
        index = this.currentVisitorIndex;
    } else {

        if(this.currentUnit.visitors && this.currentUnit.visitors.length>0 && this.currentUnit.visitors[0].dishes[0].length>0){
          this.messageService.add({ severity: 'success', summary: 'Default cart', detail: 'Please assign default cart items to respective seats' });
          return;
        }
        index = this.currentUnit.visitors.indexOf(visitor);
        if(this.currentUnit.visitors && this.currentUnit.visitors.length>0 && this.currentUnit.visitors[index].dishes[0].length == 0){
          this.messageService.add({ severity: 'error', summary: 'No items available', detail: '' });
          return;
        }
    }
    this.currentUnit['index']=index;
    this.currentUnit['isOnHold'] = false;
    this.currentUnit['isFromOnHold'] = true;

    let tempData = JSON.parse(JSON.stringify(this.currentUnit));
    localStorage.setItem('single_checkout_cart_items',JSON.stringify(tempData));
    for (var i = 0; i < tempData.visitors.length; ++i) {
      if(i == index){
      }else{
        tempData.visitors[i].dishes[0] = [];
        tempData.visitors[i].kot = [];
      }
    }
    if(this.isFnCallFromCancelOrder){
      localStorage.setItem('isSingleCheckoutForCancellOrder','true');
      let currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
      if(currentUnit.visitors && currentUnit.visitors[index]){

        if(!currentUnit.visitors[index].deleted_items){
          currentUnit.visitors[index].deleted_items = [];
        }
        if(currentUnit.visitors[index] && currentUnit.visitors[index].dishes.length>0 && currentUnit.visitors[index].dishes[0].length>0){
          currentUnit.visitors[index].dishes[0].forEach(tempItem =>{
            let item = JSON.parse(JSON.stringify(tempItem));
            if(item.selected_variant_item){
              item.selected_variant_item.item_count = item.selected_variant_item.item_count * (-1);
              item.selected_variant_item['delete_reason'] = selectedReason;
              item.selected_variant_item['is_fully_deleted'] = true;
              item.selected_variant_item['is_billed'] = false;
              delete item.selected_variant_item.kot_item_count;
              delete item.selected_variant_item.isKotfound;
              currentUnit.visitors[index].deleted_items.push(item.selected_variant_item);
            }else{
              item.item_count =  item.item_count * (-1);
              item['delete_reason'] =  selectedReason;
              item['is_fully_deleted'] = true;
              item['is_billed'] = false;
              delete item.kot_item_count;
              delete item.isKotfound;
              currentUnit.visitors[index].deleted_items.push(item);
            }
          });
          currentUnit.visitors[index].dishes[0]= [];
        }
      }

      let sendIndex = [];
      sendIndex.push(index);
      this.kotHelper.onClickOT(false,sendIndex).then((res)=>{
        this.doPayment.emit();
        //this.singleCheckPaymentDone();
        this.router.navigate(['/pages/register/place-selection/here']);
      })
    }else{
      this.stateGuard.entrust("selects-box.component:currentUnit",tempData);
      this.router.navigate(['/pages/register/food-ordering/payment',{'type':'singleCheckout'}]);
    }
  }
  filtrate(array, feature) {
    return array.filter(item => item.course === feature);
  }
  toggleMoreOptions(event, visitorIndex) {
    this.currentVisitorIndex = visitorIndex;
    var target = event.target, callback, currentItemState = this.state.moreOptionsMap[visitorIndex];
    if (target !== event.currentTarget) {
      while (!target.classList.contains("option")) {
        target = target.parentElement;
      }
      callback = target.getAttribute("id");
      if (callback) {
        this[callback](this.currentUnit.visitors[visitorIndex]);
      }
    }
    this.state.moreOptionsMap.forEach((item, optionIndex) => { this.state.moreOptionsMap[optionIndex] = false });
    this.state.moreOptionsMap[visitorIndex] = !currentItemState;
  }
  runEdit(visitor) {

  }
  getVisitorsOrderPrice(visitor) {
    var price = 0;
    visitor.dishes.forEach(course => {
      course.forEach(dish => { price += dish.price * dish.amount });
    });
    return price;
  }

  getItemsAmount() {
    var amount = 0;
    if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]){
      this.currentUnit.visitors.forEach(visitor => {
        visitor.dishes.forEach(course => {
          course.forEach(dish => {
            if(dish.selected_variant_item) {
              amount += dish.selected_variant_item.item_count;
            } else {
              amount += dish.item_count;
            }
          });
        });
      });
    }
    return !isNaN(amount) ? Number(parseFloat(amount.toString()).toFixed(3)) : 0;
  }

  getItemPrice(dish) {

    let price = dish.unit_price * dish.item_count;
    if(this.shopConfig.show_post_tax_price) {
      let discount = 0;
      if(dish.discount && dish.discount > 0) {
        discount = (dish.discountUnits == "%") ? ((price * dish.discount) / 100) : dish.discount;
      }
      return (price - discount) + ((dish.tax_value) ? dish.tax_value : 0);
    } else {
      let discount = 0;
      if(dish.discount && dish.discount > 0) {
        discount = (dish.discountUnits == "%") ? ((price * dish.discount) / 100) : dish.discount;
      }
      return (price - discount);
    }
  }

  getSubTotalAmount(){

    var total = 0;
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
    if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]){
      this.currentUnit.visitors.forEach(visitor => {
        visitor.dishes.forEach(course => {
          course.forEach(dish => {
            if(dish.selected_variant_item) {
              total += dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count
              if(dish.selected_variant_item.attributes) {
                dish.selected_variant_item.attributes.forEach(attr => {
                  total += attr.unit_price * attr.count;
                });
              }
              if(dish.selected_variant_item.discount && dish.selected_variant_item.discount > 0) {
                total -= (dish.selected_variant_item.discountUnits == "%") ? ((dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count * dish.selected_variant_item.discount) / 100) : dish.selected_variant_item.discount;
              }

              if(Array.isArray(dish.selected_variant_item.other_discounts) && dish.selected_variant_item.other_discounts.length > 0) {
                  dish.selected_variant_item.other_discounts.forEach(odt => {
                      if(odt && odt.discount>0) {
                        total -= dish.selected_variant_item.unit_price.item_count * ((odt.discount_type == "%") ? ((dish.selected_variant_item.unit_price * odt.discount) / 100) : odt.discount);
                      }
                  })
              }

              if(this.shopConfig.show_post_tax_price && dish.selected_variant_item.tax_value) {
                  total += dish.selected_variant_item.tax_value;
              }
            } else {
              total += dish.unit_price * dish.item_count
              if(dish.isOnlyModifier || dish.is_multi_group_combo){
                if(dish.attributes) {
                  dish.attributes.forEach(attr => {
                    total += attr.unit_price * attr.count;
                  });
                }
              }
              if(dish.discount && dish.discount > 0) {
                total -= (dish.discountUnits == "%") ? ((dish.unit_price * dish.item_count * dish.discount) / 100) : dish.discount;
              }
              if(Array.isArray(dish.other_discounts) && dish.other_discounts.length > 0) {
                  dish.other_discounts.forEach(odt => {
                      if(odt && odt.discount>0) {
                        total -= dish.item_count * ((odt.discount_type == "%") ? ((dish.unit_price * odt.discount) / 100) : odt.discount);
                      }
                  })
              }
              if(this.shopConfig.show_post_tax_price && dish.tax_value) {
                  total += dish.tax_value;
              }
            }
          })
        })
      });
    }

    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
    if(!this.currentUnit.other_discounts){
      this.currentUnit.other_discounts = [];
    }

    return (total);
  }

  getSubTotalAndDiscountAmount(){
    if(!this.shopConfig) { 
      this.shopConfig = JSON.parse(localStorage.getItem('shop_configuration')); 
    }
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
    var total = 0;
    var totalDiscount = 0;
    var totalTax = 0;
    var totalOtherItemWiseDiscount = 0;
    if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]){

      this.currentUnit.visitors.forEach(visitor => {
        visitor.dishes.forEach(course => {
          course.forEach(dish => {
            if(dish.selected_variant_item) {
              total += dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count
              if(dish.selected_variant_item.attributes) {
                dish.selected_variant_item.attributes.forEach(attr => {
                  total += attr.unit_price * attr.count //* dish.selected_variant_item.item_count;
                });
              }
              if(dish.selected_variant_item.discount && dish.selected_variant_item.discount > 0) {
                totalDiscount += (dish.selected_variant_item.discountUnits == "%") ? ((dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count * dish.selected_variant_item.discount) / 100) : dish.selected_variant_item.discount;
                total -= (dish.selected_variant_item.discountUnits == "%") ? ((dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count * dish.selected_variant_item.discount) / 100) : dish.selected_variant_item.discount;
              }

              if(Array.isArray(dish.selected_variant_item.other_discounts) && dish.selected_variant_item.other_discounts.length > 0) {
                  dish.selected_variant_item.other_discounts.forEach(odt => {
                      if(odt && odt.discount>0) {
                        var tempD = dish.selected_variant_item.item_count * ((odt.discount_type == "%") ? ((dish.selected_variant_item.unit_price * odt.discount) / 100) : odt.discount);
                        total -= tempD;
                        totalOtherItemWiseDiscount += tempD;
                      }
                  })
              }

              if(this.shopConfig.show_post_tax_price && dish.selected_variant_item.tax_value) {
                total += dish.selected_variant_item.tax_value;
              }
              totalTax+= (dish.selected_variant_item.tax_value) ? dish.selected_variant_item.tax_value: 0;
            } else {
              total += dish.unit_price * dish.item_count
              if(dish.isOnlyModifier || dish.is_multi_group_combo ){
                if(dish.attributes) {
                  dish.attributes.forEach(attr => {
                    total += attr.unit_price * attr.count;
                  });
                }
              }
              if(dish.discount && dish.discount > 0) {
                totalDiscount += (dish.discountUnits == "%") ? ((dish.unit_price * dish.item_count * dish.discount) / 100) : dish.discount;
                total -= (dish.discountUnits == "%") ? ((dish.unit_price * dish.item_count * dish.discount) / 100) : dish.discount;
              }
              if(Array.isArray(dish.other_discounts) && dish.other_discounts.length > 0) {
                  dish.other_discounts.forEach(odt => {
                      if(odt && odt.discount>0) {
                        var tempD = dish.item_count *  ((odt.discount_type == "%") ? ((dish.unit_price * odt.discount) / 100) : odt.discount);
                        total -= tempD;
                        totalOtherItemWiseDiscount += tempD;
                      }
                  })
              }

              if(this.shopConfig.show_post_tax_price && dish.tax_value) {
                  total += (dish.tax_value) ? dish.tax_value: 0;
              }
              totalTax+= (dish.tax_value) ? dish.tax_value : 0;
            }
          })
        })
      });
    }

    return {'total':total,'totalDiscount':totalDiscount,totalTax:totalTax,totalOtherItemWiseDiscount:totalOtherItemWiseDiscount};
  }

  getVisitorSubTotalAndDiscountAmount(visitor){

    var total = 0;
    var totalDiscount = 0;
    // if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]){
    //   this.currentUnit.visitors.forEach(visitor => {
        visitor.dishes.forEach(course => {
          course.forEach(dish => {
            if(dish.selected_variant_item) {
              total += dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count
              if(dish.selected_variant_item.attributes) {
                dish.selected_variant_item.attributes.forEach(attr => {
                  total += attr.unit_price * attr.count; //* dish.selected_variant_item.item_count;
                });
              }
              if(dish.selected_variant_item.discount && dish.selected_variant_item.discount > 0) {
                totalDiscount += (dish.selected_variant_item.discountUnits == "%") ? ((dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count * dish.selected_variant_item.discount) / 100) : dish.selected_variant_item.discount;
                total -= (dish.selected_variant_item.discountUnits == "%") ? ((dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count * dish.selected_variant_item.discount) / 100) : dish.selected_variant_item.discount;
              }
              if(this.shopConfig.show_post_tax_price && dish.selected_variant_item.tax_value) {
                total += dish.selected_variant_item.tax_value;
              }
            } else {
              total += dish.unit_price * dish.item_count
              if(dish.isOnlyModifier || dish.is_multi_group_combo){
                if(dish.attributes) {
                  dish.attributes.forEach(attr => {
                    total += attr.unit_price * attr.count;
                  });
                }
              }
              if(dish.discount && dish.discount > 0) {
                totalDiscount += (dish.discountUnits == "%") ? ((dish.unit_price * dish.item_count * dish.discount) / 100) : dish.discount;
                total -= (dish.discountUnits == "%") ? ((dish.unit_price * dish.item_count * dish.discount) / 100) : dish.discount;
              }
              if(this.shopConfig.show_post_tax_price && dish.tax_value) {
                  total += dish.tax_value;
              }
            }
          })
        })
    //   });
    // }
    return {'total':total,'totalDiscount':totalDiscount};
  }

  getGeneralPrice() {
    let tIA =this.getItemsAmount();
    if(!tIA && tIA != 0) {
      return 0;
    }
    var price = 0;
    var totalTaxAmount: number = 0;
    var itemExtraTotalTax: number = 0;
    var itemWiseDiscount: number = 0;
    var totalItemWiseOtherDiscount: number = 0;
    let isIGSTEnabled: boolean = false;

    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
    if(!this.currentUnit || !this.currentUnit.visitors) {
      return 0;
    }

    if(this.currentUnit.delivery_type == APIHelper.HOME_DELIVERY && this.shopConfig && 
        this.shopConfig.shop_info && this.shopConfig.shop_info.state) {
      if(
          this.currentUnit.userInfo && this.currentUnit.userInfo.state &&
          this.currentUnit.userInfo.state.toUpperCase() != this.shopConfig.shop_info.state.toUpperCase()
        ) {
        isIGSTEnabled = true;
      }
    }

    if(!this.currentUnit.other_discounts){
      this.currentUnit.other_discounts = [];
    }
    var other_discounts = (this.currentUnit.other_discounts) ? JSON.parse(JSON.stringify(this.currentUnit.other_discounts)) : [];
    var taxes = [];
    this.currentUnit.visitors.forEach(visitor => {
      visitor.dishes.forEach(course => {
        //console.log('course=>' + JSON.stringify(course));
        course.forEach(dish => {
          let currrentItemPrice = 0;
          if(dish.selected_variant_item) {
            price += dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count;
            currrentItemPrice += dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count;
            if(dish.selected_variant_item.attributes) {
              dish.selected_variant_item.attributes.forEach(attr => {
                price += attr.unit_price * attr.count;
                currrentItemPrice += attr.unit_price * attr.count;
              });
            }
            if(dish.selected_variant_item.discount && dish.selected_variant_item.discount > 0) {
              if(dish.selected_variant_item.discountUnits == "%"){
                itemWiseDiscount += ((dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count * dish.selected_variant_item.discount) / 100);
                currrentItemPrice -= ((dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count * dish.selected_variant_item.discount) / 100);
              }else{
                itemWiseDiscount += dish.selected_variant_item.discount;
                currrentItemPrice -= dish.selected_variant_item.discount;
              }
            }

            dish.selected_variant_item.other_discounts = [];
            other_discounts.forEach(ot => {
              if(ot.type == "flat_offer") {
                if(Array.isArray(ot.item_discounts_info)) {
                  ot.item_discounts_info.forEach(it => {
                    if(it.item_code == dish.selected_variant_item.item_code && it.item_add_time == dish.selected_variant_item.add_time) {
                      var tempDiscount = {
                        discount_code:((ot.name) ? ot.name : ''),
                        amount:((it.val) ? it.val : 0),
                        discount:((it.discount) ? it.discount : 0),
                        discount_type:((it.type) ? it.type : this.currencySymbol)
                      }
                      dish.selected_variant_item.other_discounts.push(tempDiscount);
                      totalItemWiseOtherDiscount += (it.val) ? (it.val) : 0;
                      currrentItemPrice -=  (it.val) ? (it.val) : 0;
                    }
                  })
                }
              } else if(ot.type == "bogo") {
                if(ot.item_code == dish.selected_variant_item.item_code && ot.updated_item_count>0) {
                  let isDiscountExist = dish.selected_variant_item.other_discounts.some(od => od.discount_code == ot.name);
                  if(!isDiscountExist) {
                    let disVal = Math.abs(ot.value/ot.item_count);
                    let itCount = dish.selected_variant_item.item_count>ot.updated_item_count ? ot.updated_item_count : dish.selected_variant_item.item_count;
                    var tempDiscount = {
                      discount_code:((ot.name) ? ot.name : ''),
                      amount:((disVal) ? (disVal * itCount) : 0),
                      discount:((disVal) ? (disVal * itCount) : 0),
                      discount_type: this.currencySymbol
                    }

                    dish.selected_variant_item.other_discounts.push(tempDiscount);
                    totalItemWiseOtherDiscount += tempDiscount.amount;
                    currrentItemPrice -= tempDiscount.amount;
                    ot.updated_item_count -= itCount;
                  }
                }
              }
            })
          } else {
            price += dish.unit_price * dish.item_count;
            if(dish.isOnlyModifier|| dish.is_multi_group_combo){
              if(dish.attributes) {
                dish.attributes.forEach(attr => {
                  price += attr.unit_price * attr.count;
                  currrentItemPrice +=  attr.unit_price * attr.count //* dish.item_count;
                });
              }
            }
            currrentItemPrice += dish.unit_price * dish.item_count;
            if(dish.discount && dish.discount > 0) {
              if(dish.discountUnits == "%"){

                itemWiseDiscount += ((dish.unit_price * dish.item_count * dish.discount) / 100);
                currrentItemPrice -= ((dish.unit_price * dish.item_count * dish.discount) / 100);

              }else{

                itemWiseDiscount += dish.discount;
                currrentItemPrice -= dish.discount;

              }
            }
            
            dish.other_discounts = [];
            other_discounts.forEach(ot => {
              if(ot.type == "flat_offer") {
                if(Array.isArray(ot.item_discounts_info)) {
                  ot.item_discounts_info.forEach(it => {
                    if(it.item_code == dish.item_code && it.item_add_time == dish.add_time) {
                      var tempDiscount = {
                        discount_code:((ot.name) ? ot.name : ''),
                        amount:((it.val) ? it.val : 0),
                        discount:((it.discount) ? it.discount : 0),
                        discount_type:((it.type) ? it.type : this.currencySymbol)
                      }
                      dish.other_discounts.push(tempDiscount);
                      totalItemWiseOtherDiscount += (it.val) ? (it.val) : 0;
                      currrentItemPrice -=  (it.val) ? (it.val) : 0;
                    }
                  })
                }
              } else if(ot.type == "bogo") {
                if(ot.item_code == dish.item_code && ot.updated_item_count>0) {
                  let isDiscountExist = dish.other_discounts.some(od => od.discount_code == ot.name);
                  if(!isDiscountExist) {
                    let disVal = Math.abs(ot.value/ot.item_count);
                    let itCount = dish.item_count>ot.updated_item_count ? ot.updated_item_count : dish.item_count;
                    var tempDiscount = {
                      discount_code:((ot.name) ? ot.name : ''),
                      amount:((disVal) ? (disVal * itCount) : 0),
                      discount:((disVal) ? (disVal * itCount) : 0),
                      discount_type: this.currencySymbol
                    }

                    dish.other_discounts.push(tempDiscount);
                    totalItemWiseOtherDiscount += tempDiscount.amount;
                    currrentItemPrice -= tempDiscount.amount;
                    ot.updated_item_count -= itCount;
                  }
                }
              }
            })
          }

          //calculate tax for each item
          if(this.shopConfig.item_wise_tax) {
            //console.log('applying item wise tax');
            var itemTotalTax: number = 0;
            //let tempDish = JSON.parse(JSON.stringify(dish));
            let tempDish = dish;
            let is_variant = false;
            if(tempDish.selected_variant_item) {
              tempDish = tempDish.selected_variant_item;
              is_variant = true;
            }
            tempDish.tax_breakup = [];
            for (var tax of tempDish.tax_settings) {
                var breakup_object = 0;
                if (typeof (tax.breakup) === 'undefined' || tax.breakup === null || tax.breakup === '') {
                  breakup_object = 0;
                } else {
                  breakup_object = Object.keys(tax.breakup).length;
                }
                var taxObject : any;
                var itemPrice = currrentItemPrice;
                tempDish.tax_type = tax.code;
                tempDish.tax_rate = tax.rate || 0;

                if (breakup_object > 0) {
                  if(isIGSTEnabled && (tax.main_tax_name == "GST" || tax.tax_class == "GST")) {
                    taxObject = {};

                    var taxAmount = 0;
                    taxAmount = (itemPrice) * (tax.rate * 1) / 100;
                    taxObject['tax'] = "IGST";
                    taxObject['value'] = taxAmount;
                    taxObject['rate'] = parseFloat(tax.rate);
                    itemTotalTax += taxAmount;
                    taxes.push(taxObject);

                    var tempObj: any = {};
                    tempObj['breakup_name'] = taxObject['tax'];
                    tempObj['rate'] = parseFloat(taxObject['rate']);
                    tempObj['value'] = taxObject['value'];
                    tempDish.tax_breakup.push(tempObj);
                  } else {
                    for (var key in tax.breakup) {
                      taxObject = {};
                      var taxAmount = 0;
                      taxAmount = (itemPrice) * (tax.breakup[key] * 1) / 100;/* * tempDish.item_count*/

                      taxObject['tax'] = key;
                      taxObject['value'] = taxAmount;
                      taxObject['rate'] = tax.breakup[key];
                      //console.log(dish['item_name'] + " calculated tax=>" + JSON.stringify(taxObject));
                      taxes.push(taxObject);

                      itemTotalTax += taxAmount;

                      var tempObj: any = {};
                      tempObj['breakup_name'] = taxObject['tax'];
                      tempObj['rate'] = parseFloat(taxObject['rate']);
                      tempObj['value'] = taxObject['value'];

                      tempDish.tax_breakup.push(tempObj);
                    }
                  }
                } else {
                  taxObject = {};

                  var taxAmount = 0;
                  taxAmount = (itemPrice) * (tax.rate * 1) / 100;/* * tempDish.item_count*/
                  taxObject['tax'] = tax.code;
                  taxObject['value'] = taxAmount;
                  taxObject['rate'] = tax.rate;
                  itemTotalTax += taxAmount;
                  taxes.push(taxObject);

                  var tempObj: any = {};
                  tempObj['breakup_name'] = taxObject['tax'];
                  tempObj['rate'] = parseFloat(taxObject['rate']);
                  tempObj['value'] = taxObject['value'];
                  tempDish.tax_breakup.push(tempObj);
                }

                if (typeof (tax.others) === 'undefined' || tax.others === null || tax.others === '') {
                  tempDish.item_price = parseFloat(tempDish.unit_price);
                  tempDish.tax_value = itemTotalTax;
                  // console.log("TAX VALUE 1: "+ tempDish.tax_value)
                } else {
                  //TODO: change this function implementation
                  tempDish = this.getOthersTax(tempDish,itemPrice, tax, itemTotalTax, taxes);
                  itemTotalTax = tempDish.tax_value;
                }
                if(is_variant) {
                  dish.selected_variant_item = tempDish;
                } else {
                  dish = tempDish;
                }
            }

            totalTaxAmount += itemTotalTax;
            this.currentUnit.groupedTaxes = [];

            let tempTaxes = JSON.parse(JSON.stringify(taxes));
            //for(var calcTax of taxes) {
                tempTaxes.forEach(function(f : any) {
                    var present = false;
                    for(var calcTax of this.currentUnit.groupedTaxes) {
                        //console.log("check=>" + JSON.stringify(calcTax));
                        if(calcTax !== undefined) {
                            if(f['tax'] == calcTax['tax'] && f['rate'] == calcTax['rate']){
                                //console.log("match found for " + calcTax['tax']);
                                present = true;
                                calcTax['value'] = calcTax['value'] + f['value'];
                            }
                        }
                    }
                    if(!present) {
                      this.currentUnit.groupedTaxes.push(f);
                    }
                }.bind(this));
              //}
          }else if(this.shopConfig.is_service_tax){
            //apply service tax on subtotal
            this.currentUnit.groupedTaxes = [];
            //console.log('applying service tax');
            totalTaxAmount = (price * this.shopConfig.service_tax) / 100;
            var tax: any = {};
            tax['tax'] = "Service Tax";
            tax['rate'] = parseFloat(this.shopConfig.service_tax);
            tax['value'] = totalTaxAmount;
            this.currentUnit.groupedTaxes.push(tax);
            //console.log(JSON.stringify(this.currentUnit.groupedTaxes));
          } else {
            //no taxes applied
            //console.log('applying no tax');
          }
        });
      });
    });



    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
    this.orderDetail = this.stateGuard.obtain("payment.component:orderDetail") || {};


    //assign current unit discount to order detail(to handle discount after save order.)
    this.orderDetail.discount = this.currentUnit.discount;
    this.orderDetail.discountUnit = this.currentUnit.discountUnit;
    this.orderDetail.discount_reason = this.currentUnit.discount_reason;
    this.orderDetail.discount_phone_no = this.currentUnit.discount_phone_no;

    this.orderDetail.cash_discount = this.currentUnit.cash_discount;
    this.orderDetail.cashDiscountUnit = this.currentUnit.cashDiscountUnit;

    var totalExtraCharges = this.getExtraCharges(price - itemWiseDiscount - totalItemWiseOtherDiscount);
    var totalDiscount = this.getDiscountVal();    
    var totalRewardAmount = this.getRewardAmount();
    var totalOtherDiscount = sumBy(other_discounts, o => ((o.value && o.type != "cashback") ? Math.abs(o.value) : 0));    
    //calculate new subtotal single shop tax....
    if(!this.shopConfig.item_wise_tax && !this.shopConfig.is_service_tax && this.shopConfig.extra_tax_components && this.shopConfig.extra_tax_components[0]){
      itemExtraTotalTax = 0;
      this.currentUnit.groupedTaxes = [];
      
      this.shopConfig.extra_tax_components.forEach((extra_tax) =>{
        if(isIGSTEnabled && extra_tax[this.shopConfig.shop_info.country] && extra_tax[this.shopConfig.shop_info.country].rate >= 0 && 
          (extra_tax[this.shopConfig.shop_info.country].main_tax_name == "GST" || extra_tax[this.shopConfig.shop_info.country].tax_class == "GST" || extra_tax[this.shopConfig.shop_info.country].type == "GST")) {
            let taxAmount = (((price+totalExtraCharges) - totalDiscount - totalOtherDiscount) * parseFloat(extra_tax[this.shopConfig.shop_info.country].rate)) / 100;
            let tax: any = {};
            tax['tax'] = "IGST";
            tax['rate'] = parseFloat(extra_tax[this.shopConfig.shop_info.country].rate);
            tax['value'] = taxAmount;

            this.currentUnit.groupedTaxes.push(tax);
        } else if(extra_tax[this.shopConfig.shop_info.country] && extra_tax[this.shopConfig.shop_info.country].breakup && 
          Object.keys(extra_tax[this.shopConfig.shop_info.country].breakup).length > 0) {          

            for (var key in extra_tax[this.shopConfig.shop_info.country].breakup) {
              if (extra_tax[this.shopConfig.shop_info.country].breakup.hasOwnProperty(key)) {
                  let val = extra_tax[this.shopConfig.shop_info.country].breakup[key];
                  let taxAmount = (((price+totalExtraCharges) - totalDiscount - totalOtherDiscount) * parseFloat(val)) / 100;
                  let tax: any = {};
                  tax['tax'] = key;
                  tax['rate'] = parseFloat(val);
                  tax['value'] = taxAmount;

                  this.currentUnit.groupedTaxes.push(tax);
              }
            }          
        } else if(extra_tax[this.shopConfig.shop_info.country] && extra_tax[this.shopConfig.shop_info.country].main_tax_name &&  extra_tax[this.shopConfig.shop_info.country].rate>=0){
          let val = extra_tax[this.shopConfig.shop_info.country].rate;
          let taxAmount = (((price+totalExtraCharges) - totalDiscount - totalOtherDiscount) * parseFloat(val)) / 100;
          let tax: any = {};
          tax['tax'] = extra_tax[this.shopConfig.shop_info.country].main_tax_name;
          tax['rate'] = parseFloat(val);
          tax['value'] = taxAmount;

          this.currentUnit.groupedTaxes.push(tax);
        }
      });
      if(this.currentUnit.groupedTaxes && this.currentUnit.groupedTaxes[0]){
        this.currentUnit.groupedTaxes.forEach(tax =>{
            itemExtraTotalTax += tax.value;
        });
      }
    }

    if(itemExtraTotalTax !== 0){
      totalTaxAmount+=itemExtraTotalTax;
    }
    let totalCashDiscountAmount = 0;
    if(this.orderDetail) {
      let amount = price + totalExtraCharges + totalTaxAmount - totalRewardAmount - totalOtherDiscount - totalDiscount;
      if(amount && this.orderDetail.cashDiscountUnit && this.orderDetail.cash_discount) {
        if(this.orderDetail.cashDiscountUnit == '%'){
          totalCashDiscountAmount = (this.orderDetail.cash_discount * (amount) ) / 100;
        }else{
          totalCashDiscountAmount = this.orderDetail.cash_discount;
        }
      }
      amount -= totalCashDiscountAmount;
      let rounding_off_amount = GeneralHelper.toRound(JSON.parse(JSON.stringify(amount)))-amount;     

      this.orderDetail.orderTotal = amount;
      this.orderDetail.extraCharges = this.extraCharges;
      this.orderDetail.cart_value = price;
      this.orderDetail.cart_tax = totalTaxAmount;
      this.orderDetail.group_tax =this.currentUnit.groupedTaxes;
      this.orderDetail.discount_amount = totalDiscount;
      this.orderDetail.cash_discount_amount = totalCashDiscountAmount;
      this.orderDetail.total_other_discount_amount = totalOtherDiscount;

      this.orderDetail.rounding_off_amount = rounding_off_amount;
      this.stateGuard.entrust("payment.component:orderDetail",this.orderDetail);

    }

    return price + totalExtraCharges + totalTaxAmount - totalRewardAmount - totalOtherDiscount - totalDiscount - totalCashDiscountAmount;
  }

  getRoundingOffAmount(){
    let amount = this.getGeneralPrice();
    let tempAmount = amount;
    amount =  GeneralHelper.toRound(amount);

    return (amount && tempAmount) ? (amount-tempAmount) :0 ;
  }


  getRewardAmount() {
    var total = 0;
    if(this.orderDetail && this.orderDetail.payment) {
      this.orderDetail.payment.forEach(payment_type => {
        if(payment_type.code == APIHelper.REWARD_PAYMENT_TYPE) {
          total -= (payment_type.total) ? payment_type.total : 0;
        }
      })
    }
    return total;
  }

  removeDiscount(){
    delete this.orderDetail.discountUnit;
    delete this.orderDetail.discount;
    delete this.orderDetail.discount_amount;
    delete this.orderDetail.total_other_discount_amount;

    delete this.currentUnit.discountUnit;
    delete this.currentUnit.discount;

    this.checkForDiscount();
    this.currentUnit.visitors.forEach(visitor => {
      visitor.dishes.forEach(course => {
        course.forEach(dish => {
          if(dish.selected_variant_item) {
            delete dish.selected_variant_item.discountUnits;
            delete dish.selected_variant_item.discount;
            this.dishDetailsDiscount = 0;
          } else {
            delete dish.discountUnits;
            delete dish.discount;
            this.dishDetailsDiscount = 0;
          }
        })
      })
    });
  }

  removeCashDiscount() {
    delete this.orderDetail.cashDiscountUnit;
    delete this.orderDetail.cash_discount;
    delete this.orderDetail.cash_discount_amount;   

    delete this.currentUnit.cashDiscountUnit;
    delete this.currentUnit.cash_discount; 
  }

  removeExternalDiscount(dis){

    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
    if(tempUnit && tempUnit.external_discount_list && tempUnit.external_discount_list.length>0){

        let removeData = remove(tempUnit.external_discount_list, it => it.discount_code == dis.name);
        remove(dis.other_discounts, it => it.discount_code == dis.name);
        this.checkForDiscount();
        /*if(removeData.length > 0){
          this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
          let find = remove(this.currentUnit.other_discounts,item => item.is_external && item.id == removeData[0].id);

        }*/

    }else{
      tempUnit.external_discount_list = [];
    }

  }

  calculateTaxesAndPrices(data,oparam){
    this.shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));    
    var groupedTaxes = [];
    var price = 0;
    var temp_cart_items = [];
    var totalTaxAmount: number = 0;
    data.forEach(dish => {
      temp_cart_items.push(dish);
      let currrentItemPrice = 0;
      let taxes = [];
      let tItem = (dish.selected_variant_item) ? (dish.selected_variant_item) : dish;
      price += tItem.unit_price * tItem.item_count;
      if(tItem.isOnlyModifier|| dish.is_multi_group_combo){
        if(tItem.attributes) {
          tItem.attributes.forEach(attr => {
            price += attr.unit_price * attr.count;
            currrentItemPrice +=  attr.unit_price * attr.count //* tItem.item_count;
          });
        }
      }
      currrentItemPrice += tItem.unit_price * tItem.item_count;
      if(tItem.discount && tItem.discount > 0) {
        if(tItem.discountUnits == "%"){
          price -= ((tItem.unit_price * tItem.item_count * tItem.discount) / 100);
          currrentItemPrice -= ((tItem.unit_price * tItem.item_count * tItem.discount) / 100);
        }else{
          price -= tItem.discount;
          currrentItemPrice -= tItem.discount;
        }
      }

      if(Array.isArray(tItem['other_discounts'])){
          tItem['other_discounts'].forEach(disc => {
            currrentItemPrice -= disc.amount;
            price -= disc.amount;
          });
      }


      //calculate tax for each item
      if(this.shopConfig.item_wise_tax) {
        var itemTotalTax: number = 0;
        let tempDish = dish;
        let is_variant = false;
        if(tempDish.selected_variant_item) {
          tempDish = tempDish.selected_variant_item;
          is_variant = true;
        }
        tempDish.tax_breakup = [];

        if(tempDish.tax_settings) {
          for (var tax of tempDish.tax_settings) {
              var breakup_object = 0;
              if (typeof (tax.breakup) === 'undefined' || tax.breakup === null || tax.breakup === '') {
                breakup_object = 0;
              } else {
                breakup_object = Object.keys(tax.breakup).length;
              }
              var taxObject : any;
              var itemPrice = currrentItemPrice;
              tempDish.tax_type = tax.code;
              tempDish.tax_rate = tax.rate || 0;
              
              if(oparam.isIGSTEnabled && (tax.main_tax_name == "GST" || tax.tax_class == "GST")) {
                taxObject = {};

                var taxAmount = 0;
                taxAmount = (itemPrice) * (tax.rate * 1) / 100;/* * tempDish.item_count*/
                taxObject['tax'] = "IGST";
                taxObject['value'] = taxAmount;
                taxObject['rate'] = parseFloat(tax.rate);
                itemTotalTax += taxAmount;
                taxes.push(taxObject);
                
                var tempObj: any = {};
                tempObj['breakup_name'] = taxObject['tax'];
                tempObj['rate'] = parseFloat(taxObject['rate']);
                tempObj['value'] = taxObject['value'];
                tempDish.tax_breakup.push(tempObj);
              } else if (breakup_object > 0) {
                for (var key in tax.breakup) {
                  taxObject = {};
                  var taxAmount = 0;
                  taxAmount = (itemPrice) * (tax.breakup[key] * 1) / 100;/* * tempDish.item_count*/

                  taxObject['tax'] = key;
                  taxObject['value'] = taxAmount;
                  taxObject['rate'] = tax.breakup[key];
                  taxes.push(taxObject);

                  itemTotalTax += taxAmount;

                  var tempObj: any = {};
                  tempObj['breakup_name'] = taxObject['tax'];
                  tempObj['rate'] = parseFloat(taxObject['rate']);
                  tempObj['value'] = taxObject['value'];

                  tempDish.tax_breakup.push(tempObj);
                }
              } else {
                taxObject = {};

                var taxAmount = 0;
                taxAmount = (itemPrice) * (tax.rate * 1) / 100;/* * tempDish.item_count*/
                taxObject['tax'] = tax.code;
                taxObject['value'] = taxAmount;
                taxObject['rate'] = tax.rate;
                itemTotalTax += taxAmount;
                taxes.push(taxObject);

                var tempObj: any = {};
                tempObj['breakup_name'] = taxObject['tax'];
                tempObj['rate'] = parseFloat(taxObject['rate']);
                tempObj['value'] = taxObject['value'];
                tempDish.tax_breakup.push(tempObj);
              }

              if (typeof (tax.others) === 'undefined' || tax.others === null || tax.others === '') {
                tempDish.item_price = parseFloat(tempDish.unit_price);
                tempDish.tax_value = itemTotalTax;
              } else {
                //TODO: change this function implementation
                tempDish = this.getOthersTax(tempDish, itemPrice, tax, itemTotalTax, taxes);
              }
              if(is_variant) {
                dish.selected_variant_item = tempDish;
              } else {
                dish = tempDish;
              }
          }
        }

        totalTaxAmount += itemTotalTax;
        let tempTaxes = JSON.parse(JSON.stringify(taxes));
        //for(var calcTax of taxes) {
            tempTaxes.forEach(function(f : any) {
                var present = false;
                for(var calcTax of groupedTaxes) {
                    if(calcTax !== undefined) {
                        if(f['tax'] == calcTax['tax'] && f['rate'] == calcTax['rate']){
                            //console.log("match found for " + calcTax['tax']);
                            present = true;
                            calcTax['value'] = calcTax['value'] + f['value'];
                        }
                    }
                }
                if(!present) {
                  groupedTaxes.push(f);
                }
            }.bind(this));
          //}
      } else if(this.shopConfig.is_service_tax) {
        //apply service tax on subtotal
        //console.log('applying service tax');
        totalTaxAmount = (price * this.shopConfig.service_tax) / 100;
        var tax: any = {};
        tax['tax'] = "Service Tax";
        tax['rate'] = parseFloat(this.shopConfig.service_tax);
        tax['value'] = totalTaxAmount;
        groupedTaxes.push(tax);
        //console.log(JSON.stringify(this.currentUnit.groupedTaxes));
      } else if(this.shopConfig.extra_tax_components && this.shopConfig.extra_tax_components[0]){
        this.shopConfig.extra_tax_components.forEach((extra_tax) =>{
          if(oparam.isIGSTEnabled && extra_tax[this.shopConfig.shop_info.country] && extra_tax[this.shopConfig.shop_info.country].rate >= 0 && (extra_tax[this.shopConfig.shop_info.country].main_tax_name == "GST" || extra_tax[this.shopConfig.shop_info.country].tax_class == "GST" || extra_tax[this.shopConfig.shop_info.country].type == "GST")) {
              let taxAmount = (price * parseFloat(extra_tax[this.shopConfig.shop_info.country].rate)) / 100;
              totalTaxAmount += (price * parseFloat(extra_tax[this.shopConfig.shop_info.country].rate)) / 100;

              let tax: any = {};                  
              tax['tax'] = "IGST";
              tax['rate'] = parseFloat(extra_tax[this.shopConfig.shop_info.country].rate);
              tax['value'] = taxAmount;
              groupedTaxes.push(tax);
          } else if(extra_tax[this.shopConfig.shop_info.country] && extra_tax[this.shopConfig.shop_info.country].breakup && 
            Object.keys(extra_tax[this.shopConfig.shop_info.country].breakup).length > 0) {
            
              for (var key in extra_tax[this.shopConfig.shop_info.country].breakup) {
                if (extra_tax[this.shopConfig.shop_info.country].breakup.hasOwnProperty(key)) {
                  let val = extra_tax[this.shopConfig.shop_info.country].breakup[key];

                  let taxAmount = 0;
                  taxAmount = (price * parseFloat(val)) / 100;

                  totalTaxAmount += (price * parseFloat(val)) / 100;

                  let tax: any = {};
                  
                  tax['tax'] = key;
                  tax['rate'] = parseFloat(val);
                  tax['value'] = taxAmount;
                  groupedTaxes.push(tax);
                }
              }            
          } else if(extra_tax[this.shopConfig.shop_info.country] && extra_tax[this.shopConfig.shop_info.country].main_tax_name && extra_tax[this.shopConfig.shop_info.country].rate >= 0){
            let taxAmount = (price * parseFloat(extra_tax[this.shopConfig.shop_info.country].rate)) / 100;
            totalTaxAmount += (price * parseFloat(extra_tax[this.shopConfig.shop_info.country].rate)) / 100;

            let tax: any = {};                  
            tax['tax'] = extra_tax[this.shopConfig.shop_info.country].main_tax_name;
            tax['rate'] = parseFloat(extra_tax[this.shopConfig.shop_info.country].rate);
            tax['value'] = taxAmount;
            groupedTaxes.push(tax);
          }
        });

      }else {
        //no taxes applied
        //console.log('applying no tax');
      }
    });

    if(this.shopConfig.enable_extra_charges){
        this.extraCharges = [];
        for(var extra_charge of this.shopConfig.extra_charges) {
          if(!this.shopConfig.enable_separate_bill_for_tax_classes ||
            (this.shopConfig.enable_separate_bill_for_tax_classes && SeparateBillHelper.cheackIsDifferentTaxClassesItemAvailable(temp_cart_items) && extra_charge.is_value_in_percentage)) {

            var charge = {
              'charge_name' : extra_charge.charge_name,
              'is_value_in_percentage': extra_charge.is_value_in_percentage,
              'edited' : false,
              'rate' : extra_charge.value,
              'value' : (extra_charge.is_value_in_percentage ? 0 : extra_charge.value),
              'expanded' : false
            };
            this.extraCharges.push(charge);
          }
        }
      //console.log("extra charge ",this.extraCharges)
    } else {
      this.extraCharges = [];
    }
      var totalExtraCharges = this.getExtraCharges(price);
      let sendData = {
        'orderTotal' : price + totalExtraCharges + totalTaxAmount ,
        'extraCharges' : this.extraCharges,
        'cart_value' : price,
        'cart_tax' : totalTaxAmount,
        'group_tax' : groupedTaxes,
      }
      return sendData;
  }

  /*getDiscountAmount(cart_value){
    var totalDiscount:number = 0;
    if(this.orderDetail.discountUnit) {
      if(this.orderDetail.discountUnit == '%'){
        totalDiscount = (this.orderDetail.discount * cart_value ) / 100;
      }else{
        totalDiscount = this.orderDetail.discount;
      }
    }
    return totalDiscount;
  }*/

  getDiscountVal(){

    var res:any = this.getSubTotalAndDiscountAmount();
    var cart_value = (res.total) ? res.total : 0;
    var item_discount = (res.totalDiscount) ? res.totalDiscount : 0;
    var totalDiscount = 0;

    if(this.shopConfig.show_post_tax_price) {
      cart_value-= res.totalTax;
    }
    if(this.orderDetail.discountUnit) {
      if(this.orderDetail.discountUnit == '%'){
        totalDiscount = (this.orderDetail.discount * (cart_value) ) / 100;
      }else{
        totalDiscount = this.orderDetail.discount;
      }
    }

    return totalDiscount+item_discount;
  }

  getDiscountAmountToShowInCart(){

    var res:any = this.getSubTotalAndDiscountAmount();
    var totalDiscount = res.totalDiscount;
    if(this.shopConfig.show_post_tax_price) {
      res.total -= res.totalTax;
    }
    if(this.orderDetail.discountUnit) {
      if(this.orderDetail.discountUnit == '%'){
        totalDiscount += (this.orderDetail.discount * res.total ) / 100;
      }else{
        totalDiscount += this.orderDetail.discount;
      }
    }

    if(totalDiscount>0){
      this.isRemoveDiscountShow = true;
    }else{
      this.isRemoveDiscountShow = false;
    }
    return (totalDiscount) ? (parseFloat(totalDiscount) * (-1)) : 0;
  }

  getTotalAmount(){
    let amount = this.getGeneralPrice();
    let tempAmount = amount;
    amount =  GeneralHelper.toRound(amount);
    return tempAmount + (amount-tempAmount);
  }

  clearExtraCharges(extraCharge){
    let index = this.extraCharges.indexOf(extraCharge);
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
    if(index != -1){
      this.extraCharges[index].edited = true;
      this.extraCharges[index].value = 0;
      this.currentUnit.isExtraChargeChanges = true;
      this.currentUnit.ExtraCharges = JSON.parse(JSON.stringify(this.extraCharges));
    }
  }

  // service charge on cart value
  getExtraCharges(cart_value: number) {
    var totalCharges : number = 0;
    if(cart_value>0) {
      this.extraCharges.forEach(extraCharge => {
        if(!extraCharge.edited) {
            if(extraCharge.is_value_in_percentage) {
                //calculate charges in percentage
                extraCharge.value = (extraCharge.rate * cart_value) / 100;;
                totalCharges += extraCharge.value;
            } else {
                //add the value directly
                extraCharge.value = extraCharge.rate;
                totalCharges += extraCharge.value;
            }
        } else {
            totalCharges += extraCharge.value;
        }
      });
    }
    return totalCharges;
  }

  getOthersTax(item: any, preTaxItemPrice, tax: any, taxAmt: number, taxes: any[]) {
    // console.log("get other tax");
    var otherTaxes: number = 0;
    var isAvailable: boolean = false;
    var taxValues = [];
    var totalTax: number = taxAmt;  
    for (var other of tax.others) {
      // console.log("in for " + other.rate + other.compounded + typeof (other.compounded));
      var taxObject: any = {};
      if (other.rate >= 0) {
        taxObject['tax'] = other.name;        
        if (other.compounded === 'false' || other.compounded === false) {
          otherTaxes = preTaxItemPrice * (other.rate * 1) / 100;
          item.tax_rate  = parseFloat(item.tax_settings[0].rate) + parseFloat(other.rate);
          taxObject["compounded"] = false;
        } else {
          otherTaxes = taxAmt * (other.rate * 1) / 100;
          taxObject["compounded"] = true;
        }
        totalTax = totalTax + otherTaxes;
        //item.tax_settings[0].tax_rate = parseInt(item.tax_settings[0].rate) + parseInt(other.rate);        
        taxObject['value'] = otherTaxes;
        taxObject['rate'] = other.rate;
        taxes.push(taxObject);
        var tempObj: any = {};
        tempObj['breakup_name'] = taxObject['tax'];
        tempObj['rate'] = parseFloat(taxObject['rate']);
        tempObj['value'] = taxObject['value'];
        tempObj['compounded'] = taxObject['compounded'];
        item.tax_breakup.push(tempObj);
      }
    }    
    item.item_price = parseFloat(item.unit_price);    
    item.tax_value = parseFloat(totalTax.toString());
    return item;
  }

  checkPrint() {
    console.log("check print");
    let cpNo = Number(localStorage.getItem("check_print_number"));
        cpNo = (!cpNo) ? 1 : (cpNo);
    let tempUnit = JSON.parse(JSON.stringify(this.stateGuard.obtain("selects-box.component:currentUnit") || []));
    if(tempUnit.visitors && tempUnit.visitors.length>1 && tempUnit.visitors[0].dishes[0].length>0){

      let is_item_added = false;
      for(var i=0;i<tempUnit.visitors.length;i++) {
        if(i !=0 && tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
          is_item_added = true;
          break;
        }
      }
      if(is_item_added){
        this.messageService.add({ severity: 'success', summary: 'Default cart', detail: 'Please assign default cart items to respective seats' });
        return;
      }else if(JSON.stringify(tempUnit.visitors[0]) == JSON.stringify(this.stagedVisitor)){
        //TODO: USB fire print
        this.commonPrint({cpNo: cpNo});
      }else{
        console.log("Please assign cart items to this seat");
        this.messageService.add({ severity: 'success', summary: 'No Items', detail: 'Please assign cart items to this seat' });
        return;
      }
    } else {
      if(this.stagedVisitor.dishes){
        if(this.stagedVisitor.dishes[0].length > 0){
          this.commonPrint({cpNo: cpNo});
        }else{
          console.log("Please assign cart items to this seat");
          this.messageService.add({ severity: 'success', summary: 'No Items', detail: 'Please assign cart items to this seat' });
          return;
        }
      }
    }

    if(localStorage.getItem('isRestaurant') == 'true') {
      let cpData = {
        cp_date: Date.now(),
        cp_number:  cpNo,
        total: this.orderDetail.orderTotal
      }
      if(!Array.isArray(this.currentUnit.check_prints)) {        
        this.currentUnit.check_prints = [];
      }
      this.currentUnit.check_prints.push(cpData);    
      if(this.currentUnit.activePhase) {
        this.currentUnit.activePhase.lastCPNumber = cpNo;
        this.currentUnit.activePhase.name = 'yellow';
      }
      localStorage.setItem("check_print_number",JSON.stringify(cpNo+1));
    }
    // setTimeout(() => { this.router.navigate(['/register/place-selection']) }, 0);
  }


  clearCart(isCheckForUemergeTable) {
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]) {
        this.currentUnit.visitors[0].dishes[0]=[];
        this.currentUnit.visitors[0].kot=[];
        if(this.currentUnit.activePhase && this.currentUnit.lastCPNumber){
          delete this.currentUnit.lastCPNumber;
        }
        if(this.currentUnit.activePhase && this.currentUnit.ticker){
          clearInterval(this.currentUnit.activePhase.ticker);
        }
        if(this.currentUnit.activePhase && this.currentUnit.activePhase.timeOut){
          clearTimeout(this.currentUnit.activePhase.timeOut);
        }

        this.currentUnit.chosen = false;
        if(this.currentUnit.groupedTaxes){
          delete this.currentUnit.groupedTaxes;
        }

        if(this.currentUnit.selected_partner_list){
          delete this.currentUnit.selected_partner_list;
        }

        if(this.currentUnit.selected_employer){
          delete this.currentUnit.selected_employer;
        }

        if(this.currentUnit.token){
          delete this.currentUnit.token;
        }

        delete this.currentUnit.check_prints;
        delete this.currentUnit.other_discounts;
        delete this.currentUnit.temp_other_discounts;
        delete this.currentUnit.external_discount_list;
        delete this.currentUnit.cart_items;
        delete this.currentUnit.subtotal;
        
        delete this.currentUnit.cash_discount;
        delete this.currentUnit.cashDiscountUnit;
        delete this.currentUnit.discount;
        delete this.currentUnit.discountUnit;

        if(this.currentUnit.user_id){
          delete this.currentUnit.user_id;
        }

        if(this.currentUnit.delivery_type){
          delete this.currentUnit.delivery_type;
        }

        if(this.currentUnit.is_checkout_call){
          delete this.currentUnit.is_checkout_call;
        }

        if(this.currentUnit.register_customer_info_flag){
          delete this.currentUnit.register_customer_info_flag;
        }

        if(this.currentUnit.register_customer_data){
          delete this.currentUnit.register_customer_data;
        }

        if(this.currentUnit.is_cancel_order_from_cart){
          delete this.currentUnit.is_cancel_order_from_cart;
        }

        if(this.currentUnit.noChargeFlag){
          delete this.currentUnit.noChargeFlag;
        }
        if(this.currentUnit.noChargeNote){
          delete this.currentUnit.noChargeNote;
        }
        if(this.currentUnit.cancel_order_flag){
          delete this.currentUnit.cancel_order_flag;
        }
        if(this.currentUnit.cancel_order_comment){
          delete this.currentUnit.cancel_order_comment;
        }
        if(this.currentUnit.cancel_order_reason){
          delete this.currentUnit.cancel_order_reason;
        }

        if(this.currentUnit.isExtraChargeChanges){
          delete this.currentUnit.isExtraChargeChanges;
        }

        if(this.currentUnit.userInfo){
          delete this.currentUnit.userInfo;
        }

        let lock_index = this.currentUnit.lock_index;
        if(this.currentUnit.lock_index || this.currentUnit.lock_index == 0){
          delete this.currentUnit.lock_index;
        }

        if(this.currentUnit.ExtraCharges){
          delete this.currentUnit.ExtraCharges;
        }

        if(this.currentUnit.isFromOnHold){
          delete this.currentUnit.isFromOnHold;
        }

        if(this.currentUnit.index || this.currentUnit.index == 0){
          delete this.currentUnit.index;
        }

        this.currentUnit.assigny = '';
        this.clearCustomerInfo();
        if(localStorage.getItem('isRestaurant') == 'true' && !this.currentUnit.isOnHold && !this.currentUnit.isParked) {
          this.clearHoldTable(isCheckForUemergeTable);
          this.syncTable(lock_index,this.currentUnit,isCheckForUemergeTable);
        }

        if(this.currentUnit.isOnHold){
            delete this.currentUnit.isOnHold;
        }

        if(this.currentUnit.isParked){
            delete this.currentUnit.isParked;
        }
        this.stateGuard.entrust("selects-box.component:currentUnit",this.currentUnit);
        this.stateGuard.entrust("payment.component:orderDetail",{});
    }
  }

  syncTable(lock_index,currentUnit,isCheckForUemergeTable){
    let sendData = {
      section_name               : currentUnit.location,
      tables                     : currentUnit.tables,
      covers                     : 0,
      assigny                    : currentUnit.assigny,
      table_status               : 'idle',
      lock_index                 : lock_index ? lock_index : 0,
      temp_client_reference_code : null,
      started_at                 : null,
      kot_request_number         : 0,
      kot_request_description    : "",
      is_clear                   : true,
      is_checkout                : isCheckForUemergeTable,
      kot_ready_number           : 0,
      is_checkout_call           : false,
      visitors                   : []
    }
    this.captainAppHelper.syncParticularTable(sendData,false,false);
  }

  clearHoldTable(isCheckForUemergeTable){
    let tempUnitData = this.stateGuard.obtain("selects-box.component:currentUnit");
    let tableNo = "0";
    if(tempUnitData && tempUnitData.tables && tempUnitData.tables.length>0){
      tableNo = tempUnitData.tables.join(",");
    }
    tempUnitData.isOnHold = false;
    if(tableNo && tableNo != "0"){
      let tempPlace = this.stateGuard.obtain('selects-box.component:places');
      if(tempPlace && tempPlace.length>0){
        tempPlace.forEach(place =>{
          place.units.forEach(unit =>{
            if(unit && unit.tables && unit.tables.length>0){
              if(tableNo == unit.tables.join(",") && tempUnitData.location == unit.location){

                let length = unit.tables.length;
                /*for (var i = 0; i < unit.visitors.length; ++i) {
                    unit.visitors[i].dishes[0]=[];
                    unit.visitors[i].kot=[];

                }
                */
                /*if(unit && unit.visitors[0]){
                  unit.visitors.forEach(visitor =>{
                    visitor.dishes[0]=[];
                    visitor.kot=[];
                  });
                }*/
                if(unit && unit.activePhase && unit.activePhase.ticker){
                  clearInterval(unit.activePhase.ticker);
                }
                if(unit && unit.activePhase && unit.activePhase.timeOut){
                  clearTimeout(unit.activePhase.timeOut);
                }
                unit.chosen = false;
                delete unit.visitors;
                delete unit.activePhase;
                delete unit.covers;

               
                if(unit.groupedTaxes){
                  delete unit.groupedTaxes;
                }
                if(unit.isOnHold){
                  delete unit.isOnHold;
                }
                if(unit.isFromOnHold){
                  delete unit.isFromOnHold;
                }

                if(this.currentUnit.isParked){
                    delete this.currentUnit.isParked;
                }

                if(unit.index){
                  delete unit.index;
                }

                //below are the captain app fields.
                  delete unit.table_status;
                  delete unit.temp_client_reference_code;
                  delete unit.started_at;
                  delete unit.kot_request_number;
                  delete unit.kot_ready_number;
                  delete unit.kot_request_description;
                  delete unit.is_checkout_call;
                //end captain app fields.

                if(isCheckForUemergeTable && length>1){
                  let result = [];
                  place.units.forEach(placeUnit => {
                    if (placeUnit.tables.length > 1 && tableNo == placeUnit.tables.join(",") && !placeUnit.visitors) {
                      placeUnit.tables.forEach(num => {
                        result.push({ tables: [num], assigny: placeUnit.assigny, chosen: false, location: placeUnit.location });
                      });
                    } else {
                      result.push(placeUnit);
                    }
                  });
                  
                  result = result.sort((a: any, b: any) => {
                    return parseInt(a.tables[0]) > parseInt(b.tables[0]) ? 1 : -1;
                  });
                  place.units = result;
                }
              }
            }
          })
        });
      }
      localStorage.setItem('saved_places',JSON.stringify(tempPlace));
    }
  }


  openCashRegister(){

    return new Promise<boolean>((resolve,reject)=>{

      this.modalRef = this.modalService.show(OpenCashRegisterModalComponent, {class: 'centred-modal'});
      this.modalRef.content.amount = undefined;
      this.modalRef.content.notes = '';
      this.modalRef.content.decision = "";
      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'submit') {
          this.unsubscribe();
          resolve(true);
        }else{
          this.unsubscribe();
          resolve(false);
        }
      }));
    })
  }

  addToBasket(dish,isTakeReRef) {
    var flagForCheckDiscount = (dish.is_free_dish) ? false : true;
    delete dish.is_free_dish;
    this.addComboItem(dish).then(newDish => {
      dish = newDish;
      if(!localStorage.getItem('isCashRegister') || localStorage.getItem('isCashRegister')== 'false'){
        this.openCashRegister().then((res)=>{
          if(res){
            this.addItemInCart(dish,flagForCheckDiscount,isTakeReRef);
          }
        });
      }else{
        this.addItemInCart(dish,flagForCheckDiscount,isTakeReRef);
      }
    })
  }

  addComboItem(dish) {
    return new Promise((resolve,reject) => {
      if(dish.is_combo) {
        var combo_sub_item = dish.combo_item_codes;
        if(combo_sub_item && Array.isArray(combo_sub_item)) {
          let totalComboSubItemCount = 0;

          let categories = JSON.parse(localStorage.getItem('categories'));
          for (var i = 0; i < categories.length; ++i) {
            var currentItems: any = JSON.parse(localStorage.getItem(categories[i].category_code));
            if(Array.isArray(currentItems)){
              for (var tempMainItem of currentItems) {
                combo_sub_item.forEach(temp_combo_item => {
                  if (tempMainItem.item_code === temp_combo_item.item_code) {
                    temp_combo_item.item_name = tempMainItem.item_name;
                    temp_combo_item.unit_price = (tempMainItem.unit_price) ? parseFloat(tempMainItem.unit_price) : 0;
                    totalComboSubItemCount++;
                  }
                });
              }
            }
            if(totalComboSubItemCount == combo_sub_item.length) { break; }
          }
          dish.combo_item_codes = combo_sub_item;
        }
        return resolve(dish);
      } else {
        return resolve(dish);
      }
    })
  }

  addItemInCart(dish,flagForCheckDiscount,isTakeReRef){
    this.isKotView = false;

    if(!this.stagedVisitor || isTakeReRef) {
      this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
      if(this.currentUnit.visitors && this.currentUnit.visitors[0]) {
        this.stagedVisitor = this.currentUnit.visitors[0];
        this.stagedVisitor.on = true;
      }
    }

    if(this.currentUnit && !this.currentUnit.delivery_type)  { this.currentUnit.delivery_type = APIHelper.IMMEDIATE; }
    if(dish.selected_variant_item && !dish.selected_variant_item.unit_price && dish.selected_variant_item.unit_price != 0){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This item cannot be added to cart. Exception!' });
      return;
    }else if(!dish.selected_variant_item && !dish.unit_price && dish.unit_price != 0){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This item cannot be added to cart. Exception!' });
      return;
    }

    if (this.stagedVisitor) {
      var isAvailable = false;
      if (dish.selected_variant_item) {
          /*if(dish.selected_variant_item.attributes.length == 0){
            this.stagedVisitor.dishes[0].forEach(function (i: any) {
              if(i.selected_variant_item) {
                if (i.selected_variant_item.item_code == dish.selected_variant_item.item_code) {
                  i.selected_variant_item.item_count += (dish.selected_variant_item.item_count) ? dish.selected_variant_item.item_count : 1;
                  isAvailable = true;
                }
              }
            });
          }*/
          if(!isAvailable) {
            dish.selected_variant_item.unit_price = (typeof dish.selected_variant_item.unit_price == "string") ? parseFloat(dish.selected_variant_item.unit_price) : dish.selected_variant_item.unit_price;
            dish.selected_variant_item.item_count =(dish.selected_variant_item.item_count) ? dish.selected_variant_item.item_count : 1;
            dish.selected_variant_item.add_time = (new Date()).getTime();
            dish.selected_variant_item.item_from = 'pos';
            this.stagedVisitor.dishes[0].push(JSON.parse(JSON.stringify(dish)));
            this.updateBatchSerialItem(dish.selected_variant_item,dish.selected_variant_item.item_count,'add');
          }
      } else {
        if(flagForCheckDiscount){

          /*this.stagedVisitor.dishes[0].forEach(function (i: any) {
            if (i.item_code === dish.item_code) {
              if(dish.isOnlyModifier){
                i.item_count += dish.item_count;
                if(i.attributes && Array.isArray(i.attributes) && dish.attributes && Array.isArray(dish.attributes) ) {
                  dish.attributes.forEach(dattr=> {
                    let isAttrFound = false;
                    i.attributes.forEach(iattr=> {
                      if(iattr.attribute_code == dattr.attribute_code) {
                        iattr.count += dattr.count;
                        isAttrFound = true;
                      }
                    });
                    if(!isAttrFound) {
                      i.attributes.push(dattr)
                    }
                  });
                } else {
                  i.attributes = dish.attributes;
                }
              }else{
                i.item_count += dish.item_count;
              }
              i.tempData.temp_item_count = i.item_count;
              isAvailable = true;
            }
          });*/
          if (!isAvailable){
            dish.item_from = 'pos';
            dish.add_time = (new Date()).getTime();
            this.stagedVisitor.dishes[0].push(JSON.parse(JSON.stringify(dish)));
            this.updateBatchSerialItem(dish,dish.item_count,'add');
          }
          if(this.stagedVisitor.deleted_items) {
            this.stagedVisitor.deleted_items.forEach(item => {
              if(item.item_code == dish.item_code) {
                item.item_count += dish.item_count;
              }
            })
            remove(this.stagedVisitor.deleted_items,item => item.item_count == 0);
          }

        }else{

          let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
          if(tempUnit && tempUnit.visitors[0]){

            let tempVisitor = tempUnit.visitors[0];
            /*tempVisitor.dishes[0].forEach(function (i: any) {
              if (i.item_code === dish.item_code) {
                i.item_count += dish.item_count;
                i.tempData.temp_item_count = i.item_count;
                isAvailable = true;
              }
            });*/
            if (!isAvailable){
              dish.item_from = 'pos';
              dish.add_time = (new Date()).getTime();
              tempVisitor.dishes[0].push(JSON.parse(JSON.stringify(dish)));
              this.updateBatchSerialItem(dish,dish.item_count,'add');
            }
            if(tempVisitor.deleted_items) {
              tempVisitor.deleted_items.forEach(item => {
                if(item.item_code == dish.item_code) {
                  item.item_count += dish.item_count;
                }
              })
              remove(tempVisitor.deleted_items,item => item.item_count == 0);
            }
          }
        }
      }
      if(flagForCheckDiscount){
        var self = this;
        setTimeout(()=>{
          self.checkForDiscount();
        },100);
      }
    }
  }

  updateBatchSerialItem(item,changed_count,reason) {
    /*if(item.tracking_number && item.tracking_mode) {
      var tCI = localStorage.getItem(item.category_code);
      if(tCI) {
        var currentItems: any = JSON.parse(tCI);
        if(Array.isArray(currentItems)){

          var ciLen = currentItems.length;
          var isRChanged = false;
          for(var i=0;i<ciLen;i++) {
            if(currentItems[i].item_code == item.item_code) {
              if(Array.isArray(currentItems[i].batch_serial_stock_info) && currentItems[i].batch_serial_stock_info.length>0) {
                var bssiLen = currentItems[i].batch_serial_stock_info.length;
                for(var j=0;j<bssiLen;j++){
                  if(currentItems[i].batch_serial_stock_info[j].tracking_number == item.tracking_number &&
                    currentItems[i].batch_serial_stock_info[j].tracking_mode == item.tracking_mode) {
                    if(reason == "add") {
                      currentItems[i].batch_serial_stock_info[j].quantity -= changed_count;
                    } else {
                      currentItems[i].batch_serial_stock_info[j].quantity += changed_count;
                    }
                    isRChanged = true;
                    break;
                  }
                }
                break;
              }
            }
          }

          if(isRChanged) {
            localStorage.setItem(item.category_code, JSON.stringify(currentItems));
          }
        }
      }
    }*/
  }

  checkForDiscount(){
    let cart_items = [];
    let subtotal = 0;
    let is_membership_item_available = false;
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    if(!Array.isArray(this.currentUnit.other_discounts)) {
      this.currentUnit.other_discounts = []; 
    }
    if(Array.isArray(this.currentUnit.visitors)) {
      this.currentUnit.visitors.forEach(function (visitor: any) {
        if(Array.isArray(visitor.dishes[0])) {
          visitor.dishes[0].forEach(function (i: any) {
            var tempItem = (i.selected_variant_item) ? i.selected_variant_item : i;
            let tempData = {
              item_count : tempItem.item_count,
              item_code : tempItem.item_code,
              item_price : (tempItem.unit_price)? parseFloat(tempItem.unit_price) : tempItem.item_price,
              category_code : tempItem.category_code,
              add_time : tempItem.add_time,
              is_membership: tempItem.is_membership,
              tax_settings : (Array.isArray(tempItem.tax_settings)) ? tempItem.tax_settings : [],
            }
            if(tempData.is_membership) {
              is_membership_item_available = true;
            }
            cart_items.push(tempData);
          });
        }
      });
    }

    var subtotal_obj:any = this.getSubTotalAndDiscountAmount()
    //+subtotal_obj.totalDiscount
    subtotal = subtotal_obj.total+subtotal_obj.totalOtherItemWiseDiscount;//item wise discount will remove from discount helper -> getDisountInfo function
    if(this.shopConfig.show_post_tax_price) {
      subtotal -= subtotal_obj.totalTax;
    }
    //this.getSubTotalAmount();

    this.currentUnit['cart_items'] = cart_items;
    this.currentUnit['subtotal'] = subtotal;
    let shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
    let is_different_tax_classes_item_available = (shopConfig && shopConfig.enable_separate_bill_for_tax_classes) ? SeparateBillHelper.cheackIsDifferentTaxClassesItemAvailable(cart_items) : false;
    if(is_different_tax_classes_item_available &&  this.orderDetail && this.orderDetail.discountUnit != '%') {
      this.orderDetail.discountUnit = "";
      this.orderDetail.discount = 0;
      this.orderDetail.discount_reason = "";
      this.orderDetail.discount_phone_no = "";
    }

    if(is_different_tax_classes_item_available &&  this.orderDetail && this.orderDetail.cashDiscountUnit != '%') {
      this.orderDetail.cashDiscountUnit = "";
      this.orderDetail.cash_discount = 0;      
    }

    let appliedDiscount:any = JSON.parse(JSON.stringify(this.discountHelper.getDisountInfo(subtotal,cart_items,is_different_tax_classes_item_available,is_membership_item_available)));
    appliedDiscount = JSON.parse(JSON.stringify(appliedDiscount));
    if(!appliedDiscount){
      this.messageService.add({ severity: 'error', summary: 'Discount not matched', detail: '' });
      return;
    }
    remove(this.currentUnit.other_discounts,item => (item.type != 'bogo' && item.type != 'combo'));
    let tempAllDiscountList = JSON.parse(JSON.stringify(appliedDiscount.regular_discount));
    let tempDisc:any = [];

    if(appliedDiscount.bogo_discount.length > 0) {
      appliedDiscount.bogo_discount.forEach(k => {
        let allItems: any = JSON.parse(localStorage.getItem(k.category_code)) || [];
        let value = 0;
        let required_items: any = allItems.filter(j => j.item_code == k.item_code);

        if(required_items && required_items.length>0){
          let it = required_items[0];
          value = (parseFloat(it.unit_price) * -1 * k.item_count);
        }
        k['value'] = value;
        tempAllDiscountList.push(k);
      });
    }
    var exclusiveDiscounts = tempAllDiscountList.filter(ed => ed.is_exclusive);
    if(exclusiveDiscounts.length != 0) {
      //var tempSortedDiscount = maxBy(exclusiveDiscounts, function(o) { return (o.value * (-1)); })
      var tempSortedDiscount = exclusiveDiscounts.sort((a,b) => { 
        return  (b.value*(-1)) - (a.value*(-1));
      })
      var finalDiscount = tempSortedDiscount[0];
      if(finalDiscount.type == 'bogo'){
        tempDisc = {
          bogo_discount : [finalDiscount],
          regular_discount : [],
          bogo_any_discount : []
        }
      } else if(finalDiscount.type == 'bogo_any'){
        tempDisc = {
          bogo_discount : [],
          regular_discount : [],
          bogo_any_discount : [finalDiscount]
        }
      }else{
        tempDisc = {
          bogo_discount : [],
          regular_discount : [finalDiscount],
          bogo_any_discount : []
        }
      }
      appliedDiscount = JSON.parse(JSON.stringify(tempDisc));
      this.currentUnit['external_discount_list'] = (this.currentUnit['external_discount_list']) ? this.currentUnit['external_discount_list'] : [];
      remove(this.currentUnit['external_discount_list'], edl => {return (edl.id != finalDiscount.id)});
    }

    if(appliedDiscount && appliedDiscount.bogo_discount.length > 0){

      appliedDiscount.bogo_discount.forEach( item =>{
        let f_list = this.currentUnit.other_discounts.filter(i =>
          (i.type == 'bogo' && i.item_code == item.item_code));
        let isIncrease = false;
        var isDecrease = false
        let new_count = 1;
        if(f_list.length == 0){
          isIncrease = true;
          new_count = item.item_count;
        } else if(f_list[0].item_count < item.item_count){
          isIncrease = true;
          new_count = (item.item_count - f_list[0].item_count);
        } else if(f_list[0].item_count > item.item_count){
          isDecrease = true;
        }

        let all_items: any = JSON.parse(localStorage.getItem(item.category_code)) || [];

        let req_items: any = all_items.filter(j => j.item_code == item.item_code);
        if(req_items && req_items.length>0){
            let addItem = req_items[0];
            addItem.item_count = new_count;

            if(isIncrease){
              //add item
              //this.addItemInCart(addItem,false);
              addItem.is_free_dish = true;
              this.addingBasketItem.emit(addItem);
            }

            if(f_list.length == 0){
              let temp = {
                type : item.type,
                item_code : addItem.item_code,
                value : (parseFloat(addItem.unit_price) * -1 * addItem.item_count),
                item_count : addItem.item_count,
                updated_item_count : addItem.item_count,
                name   :  item.name,
                is_external : item.is_external,
              }
              this.currentUnit.other_discounts.push(temp);

            }else if(isIncrease || isDecrease){
              for(var i=0;i<this.currentUnit.other_discounts.length;i++) {
                if(this.currentUnit.other_discounts[i].item_code == item.item_code){
                  this.currentUnit.other_discounts[i].item_count = item.item_count;
                  this.currentUnit.other_discounts[i].updated_item_count = item.item_count;
                  this.currentUnit.other_discounts[i].value = (parseFloat(addItem.unit_price) * -1 * item.item_count);
                }
              }
            }
        }
      });

      let list = [];
      appliedDiscount.bogo_discount.forEach( i =>{
        list.push(i.item_code);
      });

      this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
      let tempData = remove(this.currentUnit.other_discounts,item => (item.type == 'bogo' && list.indexOf(item.item_code) == -1));
      if(tempData.length>0){
        this.checkForDiscount();
      }
    }else{
      this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
      let tempData = remove(this.currentUnit.other_discounts,item => (item.type == 'bogo'));
      if(tempData.length>0){
        this.checkForDiscount();
      }
    }

    if(appliedDiscount && appliedDiscount.regular_discount && appliedDiscount.regular_discount.length > 0){
      var rdLen = appliedDiscount.regular_discount.length;
      var odLen = this.currentUnit.other_discounts.length;
      this.currentUnit.other_discounts.forEach(dis => { if(dis.type == "combo") {dis.is_new=0;}})
      for(var j =0;j<rdLen;j++) {
        if(appliedDiscount.regular_discount[j].type == "combo") {
          var is_match_found = 0;
          for(var i=0;i<odLen;i++) {
            if(this.currentUnit.other_discounts[i].name == appliedDiscount.regular_discount[j].name) {
              this.currentUnit.other_discounts[i] = appliedDiscount.regular_discount[j];
              this.currentUnit.other_discounts[i].is_new = 1;
              is_match_found = 1;
              break;
            }
          }
          if(!is_match_found) {
            appliedDiscount.regular_discount[j].is_new = 1;
            this.currentUnit.other_discounts.push(appliedDiscount.regular_discount[j]);
          }
        } else {
          this.currentUnit.other_discounts.push(appliedDiscount.regular_discount[j]);
        }
      }

      remove(this.currentUnit.other_discounts,item => (!item.is_new && item.type == 'combo'));
    } else {
       remove(this.currentUnit.other_discounts,item => (item.type == 'combo'));
    }

    if(appliedDiscount && appliedDiscount.bogo_any_discount && appliedDiscount.bogo_any_discount.length > 0){
      appliedDiscount.bogo_any_discount.forEach( d =>{
        this.currentUnit.other_discounts.push(d);
      });
    }
  }


  onDrop(parcel, course) {

    var address = parcel.address;
    let is_kot_found = false;
    if (address) {
      is_kot_found = this.isKotAvailableForItem(parcel.dish,address[0]);
    }
    if(is_kot_found){
      this.modalRef = this.modalService.show(CancelKotItemReasonModal, {class: 'common-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.reasonList = this.reasonList;
      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        let reason = '';
        if (this.modalRef.content.decision === 'send') {
          reason = this.modalRef.content.selectedReason;
          if(!this.currentUnit.visitors[address[0]].deleted_items){
            this.currentUnit.visitors[address[0]].deleted_items = [];
          }

          let item = JSON.parse(JSON.stringify(parcel.dish));
          if(item.selected_variant_item){
            item.selected_variant_item.item_count = item.selected_variant_item.item_count * (-1);
            item.selected_variant_item['delete_reason'] = reason;
            item.selected_variant_item['is_billed'] = false;
            item.selected_variant_item['is_fully_deleted'] = true;
            delete item.selected_variant_item.kot_item_count;
            delete item.selected_variant_item.isKotfound;
            this.currentUnit.visitors[address[0]].deleted_items.push(item.selected_variant_item);
          }else{
            item.item_count =  item.item_count * (-1);
            item['delete_reason'] =  reason;
            item['is_fully_deleted'] = true;
            item['is_billed'] = false;
            delete item.kot_item_count;
            delete item.isKotfound;
            this.currentUnit.visitors[address[0]].deleted_items.push(item);
          }
          this.dragItem(parcel, course);
        }else{
          this.messageService.add({ severity: 'success', summary: 'Item is not moved', detail: '' });
        }
        this.unsubscribe();
      }));
    }else{
      this.dragItem(parcel, course);
    }
  }

  dragItem(parcel, course){

    var address = parcel.address, source;
    if(address){
      source = this.currentUnit.visitors[address[0]].dishes[address[1]];
      source.splice(address[2], 1);
    }

    if (parcel.dish.selected_variant_item) {
      course.push(JSON.parse(JSON.stringify(parcel.dish)));
    } else {
      let isAvailable = false;
      /*course.forEach(function (i: any) {
        if (i.item_code === parcel.dish.item_code) {
          i.item_count += parcel.dish.item_count;
          i.tempData.temp_item_count = i.item_count;
          isAvailable = true;
        }
      });*/
      if (!isAvailable) course.push(JSON.parse(JSON.stringify(parcel.dish)));
    }
  }

  addToVisitorsMeal(visitor, dish) {
  }

  toggleVisitor(visitor) {
    var newState = !visitor.on;
    visitor.on = newState;
    if(!newState) {
      this.stagedVisitor = this.currentUnit.visitors[0];
    } else {
      this.stagedVisitor = visitor;
    }
  }

  editDishDetails(parcel, dish) {

    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    let is_kot_found = this.isKotAvailableForItem(dish,this.selectedVisitorIndex);
    if(!is_kot_found || dish.selected_variant_item){
      this.afterEditDishDetails(parcel, dish,false,null);
    }else{
      var newDetails = parcel;
      let dish_old_count = dish.kot_item_count;
      let dish_new_count = parseFloat(newDetails.quantity) || 0;
      if(dish_new_count < dish_old_count){
        this.modalRef = this.modalService.show(CancelKotItemReasonModal, {class: 'common-modal'});
        this.modalRef.content.decision = "";
        this.modalRef.content.reasonList = this.reasonList;
        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
          let data = '';
          if (this.modalRef.content.decision === 'send') {
            data = this.modalRef.content.selectedReason;
            this.afterEditDishDetails(parcel, dish,true,data);
          }else{
            dish.tempData.temp_item_count = parseFloat(dish.item_count);
            this.messageService.add({ severity: 'success', summary: 'Item is not cancelled', detail: '' });
          }
          this.unsubscribe();
        }));
      }else{
        this.afterEditDishDetails(parcel, dish,false,null);
      }
    }
  }

  updateDishForSpecialOrder(parcel, dish) {    
    let specialOrderInfo = parcel.specialOrderInfo
    if(dish.selected_variant_item){
      dish.selected_variant_item = this.prepareItemAfterAddOrRemoveSpecialOrder(specialOrderInfo,dish.selected_variant_item);      
      dish.tempData.temp_unit_price = dish.selected_variant_item.unit_price;
      dish.tempData.unit_price = dish.selected_variant_item.unit_price;
    } else {
      dish = this.prepareItemAfterAddOrRemoveSpecialOrder(specialOrderInfo,dish);
      dish.tempData.temp_unit_price = dish.unit_price;
      dish.tempData.unit_price = dish.unit_price;
    }    
    if(specialOrderInfo) {
      this.special_order_flag = 3;
    } else {
      this.special_order_flag = 1;
    }
    this.checkForDiscount();
  }

  prepareItemAfterAddOrRemoveSpecialOrder(specialOrderInfo,item) {
    if(!item.default_unit_price && item.default_unit_price != 0 ) {
      item.default_unit_price = parseFloat(item.unit_price);
    }
    if(!item.default_unit_value && item.default_unit_value != 0 ) {
      item.default_unit_value = item.unit_value ? item.unit_value : 1;
    }

    item.special_order_information = specialOrderInfo;
    if(specialOrderInfo) {
      item.unit_value = specialOrderInfo.unit_value;      
      item.unit_price = specialOrderInfo.special_order_price;      
      item.special_order_charges = specialOrderInfo.special_order_charges ?  specialOrderInfo.special_order_charges : 0;
      if(specialOrderInfo.item_note){
        item.note = specialOrderInfo.item_note;      
      } 
      if(specialOrderInfo.item_instruction){
        item.instructions = specialOrderInfo.item_instruction;      
      }  
    } else {
      item.unit_value = item.default_unit_value;
      item.unit_price = item.default_unit_price;
      item.special_order_charges = 0;
    }
    return item;
  }

  afterEditDishDetails(parcel, dish,isCallKot,reason){
    var newDetails = parcel;
    let diff = 0;
    if(dish.selected_variant_item){
      diff = parseFloat(dish.selected_variant_item.item_count) - parseFloat(newDetails.quantity);
      dish.selected_variant_item.item_count = parseFloat(newDetails.quantity) || parseFloat(dish.selected_variant_item.item_count);
      dish.tempData.temp_item_count = parseFloat(newDetails.quantity) || parseFloat(dish.selected_variant_item.item_count);
      dish.selected_variant_item.unit_price = parseFloat(newDetails.price) || parseFloat(dish.selected_variant_item.unit_price);
      dish.selected_variant_item.discount = (newDetails.discount || newDetails.discount == 0) ? parseFloat(newDetails.discount) : parseFloat(dish.selected_variant_item.discount);
      dish.selected_variant_item.discountUnits = newDetails.discountUnits || dish.selected_variant_item.discountUnits;
      dish.selected_variant_item.note = newDetails.description;
      dish.selected_variant_item.description = newDetails.description;
    }else{
      diff = parseFloat(dish.item_count) - parseFloat(newDetails.quantity);
      dish.item_count = parseFloat(newDetails.quantity) || parseFloat(dish.item_count);
      dish.tempData.temp_item_count = parseFloat(newDetails.quantity) || parseFloat(dish.item_count);
      dish.unit_price = parseFloat(newDetails.price) || parseFloat(dish.unit_price);
      dish.discount = (newDetails.discount || newDetails.discount == 0) ? parseFloat(newDetails.discount) : parseFloat(dish.discount);
      dish.discountUnits = newDetails.discountUnits || parcel.discountUnits;
      dish.note = newDetails.description;
      dish.description = newDetails.description;
    }

    if(isCallKot){
      if(!this.currentUnit.visitors[this.selectedVisitorIndex].deleted_items){
        this.currentUnit.visitors[this.selectedVisitorIndex].deleted_items = [];
      }
      let item = JSON.parse(JSON.stringify(dish));
      if(item.selected_variant_item){
        item.selected_variant_item.item_count = diff * (-1);
        item.selected_variant_item['delete_reason'] = reason;
        item.selected_variant_item['is_billed'] = false;
        delete item.selected_variant_item.kot_item_count;
        delete item.selected_variant_item.isKotfound;
        this.currentUnit.visitors[this.selectedVisitorIndex].deleted_items.push(item.selected_variant_item);
      }else{
        item.item_count =  diff * (-1);
        item['delete_reason'] =  reason;
        item['is_billed'] = false;
        delete item.kot_item_count;
        delete item.isKotfound;
        this.currentUnit.visitors[this.selectedVisitorIndex].deleted_items.push(item);
      }
    }
    this.removeAddedDiscountItem();
  }

  removeCaptainItem(visitorIndex, courseIndex, dishIndex){
    this.modalRef = this.modalService.show(VerifyPinModalComponent, {class: 'spartan-modal covers'});
    this.modalRef.content.decision = "";
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        let data = '';
        if (this.modalRef.content.decision === 'done'){
          this.removeTheDish(visitorIndex, courseIndex, dishIndex);
        }
        this.unsubscribe();
    }));
  }

  removeTheDish(visitorIndex, courseIndex, dishIndex) {
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    let is_kot_found = this.isKotAvailableForItem(this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex],visitorIndex);
    if(!is_kot_found){
      this.removeItemFromCart(visitorIndex, courseIndex, dishIndex,null,false);
    }else{
      var self = this;
      setTimeout(()=>{
        self.getReason(visitorIndex, courseIndex, dishIndex);
      },150);
    }
  }

  getReason(visitorIndex, courseIndex, dishIndex){
    this.modalRef = this.modalService.show(CancelKotItemReasonModal, {class: 'common-modal'});
    this.modalRef.content.decision = "";
    this.modalRef.content.reasonList = this.reasonList;
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        let data = '';
        if (this.modalRef.content.decision === 'send') {
          data = this.modalRef.content.selectedReason;
          this.removeItemFromCart(visitorIndex, courseIndex, dishIndex,data,true);
        }else{
          this.messageService.add({ severity: 'success', summary: 'Item is not cancelled', detail: '' });
        }
        this.unsubscribe();
    }));
  }

  removeItemFromCart(visitorIndex, courseIndex, dishIndex,reason,isKotCall){
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    let count = 0;
    if(!this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].selected_variant_item){
      this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].expanded = false;
      count = this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].item_count;
      this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].item_count = 1;
      this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].unit_price = this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].tempData.unit_price;
      this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].tempData.temp_unit_price = this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].tempData.unit_price;
      this.updateBatchSerialItem(JSON.parse(JSON.stringify(this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex])),count,'remove');

    }else{
      count = this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].selected_variant_item.item_count;
      this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].selected_variant_item.item_count =1;
      this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].selected_variant_item.unit_price = this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].tempData.unit_price;
      this.updateBatchSerialItem(JSON.parse(JSON.stringify(this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex].selected_variant_item)),count,'remove');

    }

    if(!isKotCall){
      this.currentUnit.visitors[visitorIndex].dishes[courseIndex].splice(dishIndex, 1);
    }

    if(this.currentUnit.visitors[visitorIndex].dishes[0].length == 0){
      this.currentUnit.groupedTaxes = [];
      this.removeDiscount();
    }
    if(isKotCall){

      if(!this.currentUnit.visitors[visitorIndex].deleted_items){
        this.currentUnit.visitors[visitorIndex].deleted_items = [];
      }
      let item = JSON.parse(JSON.stringify(this.currentUnit.visitors[visitorIndex].dishes[courseIndex][dishIndex]));
      if(item.selected_variant_item){
        item.selected_variant_item.item_count = count * (-1);
        item.selected_variant_item['delete_reason'] = reason;
        item.selected_variant_item['is_fully_deleted'] = true;
        item.selected_variant_item['is_billed'] = false;
        delete item.selected_variant_item.kot_item_count;
        delete item.selected_variant_item.isKotfound;
        this.currentUnit.visitors[visitorIndex].deleted_items.push(item.selected_variant_item);
      }else{
        item.item_count =  count * (-1);
        item['delete_reason'] =  reason;
        item['is_fully_deleted'] = true;
        item['is_billed'] = false;
        delete item.kot_item_count;
        delete item.isKotfound;
        this.currentUnit.visitors[visitorIndex].deleted_items.push(item);
      }
      let r = this.currentUnit.visitors[visitorIndex].dishes[courseIndex].splice(dishIndex, 1);
    }
    this.removeAddedDiscountItem();
  }


  removeAddedDiscountItem(){
    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
    if(tempUnit && tempUnit.visitors && tempUnit.visitors[0]){
      this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
      if(!this.currentUnit.other_discounts){
        this.currentUnit.other_discounts = [];
      }

      if(this.currentUnit.other_discounts.length > 0){
        let dish_item_codes = [];
        tempUnit.visitors.forEach(visitor => {
          visitor.dishes[0].forEach((dish) =>{
              let tempDish = (dish.selected_variant_item) ? JSON.parse(JSON.stringify(dish.selected_variant_item)) : JSON.parse(JSON.stringify(dish));
              dish_item_codes.push(tempDish.item_code);
              //below code not worked because of added seperate items;
              /*let fData = this.currentUnit.other_discounts.filter(it => (it.type == 'bogo' && it.item_code == tempDish.item_code));
              if(fData && fData.length > 0){
                if(tempDish.item_count >= fData[0].item_count) {
                  //do nothing
                } else {                  
                  for(var i=0;i<this.currentUnit.other_discounts.length;i++) {
                      if(this.currentUnit.other_discounts[i].item_code ==  fData[0].item_code) {
                        this.currentUnit.other_discounts[i].item_count = tempDish.item_count;
                        this.currentUnit.other_discounts[i].value = (parseFloat(tempDish.unit_price) * -1 * tempDish.item_count);
                      }
                  }
                }
              }*/
          });  
        })
        
        this.currentUnit.other_discounts.forEach(it => {
          if(it.type == "bogo" && dish_item_codes.indexOf(it.item_code) == -1) {
            it.value = 0;
          }
        })
      }

      var self = this;
      var tout = setTimeout(function(){
        clearTimeout(tout);
        self.checkForDiscount();
      },100)
    }
  }

  toggleDishDetails(event, dish,vIndex) {
    event.stopPropagation();
    if(dish.selected_variant_item){
      this.dishDetailsDiscount = (dish.selected_variant_item.discount) ? (dish.selected_variant_item.discount) : 0;
    }else{
       this.dishDetailsDiscount = (dish.discount) ? (dish.discount) : 0;
    }
    if(dish.item_from == 'captain'){
      this.modalRef = this.modalService.show(VerifyPinModalComponent, {class: 'spartan-modal covers'});
      this.modalRef.content.decision = "";
      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
          let data = '';
          if (this.modalRef.content.decision === 'done'){
            dish['isKotfound'] = this.isKotAvailableForItem(dish,vIndex);
            dish.expanded = !dish.expanded;
            this.selectedVisitorIndex = vIndex;
          }
          this.unsubscribe();
      }));
    }else{
      dish['isKotfound'] = this.isKotAvailableForItem(dish,vIndex);
      dish.expanded = !dish.expanded;
      this.selectedVisitorIndex = vIndex;
    }
  }

  isVisibleRemoveExtraIcon(charge){
    return !(charge.expanded);
  }

  isKotAvailableForItem(dish,vIndex){

  let item_code = '';
  let add_time = '';
    if(dish.selected_variant_item){
      item_code = dish.selected_variant_item.item_code;
      add_time = dish.selected_variant_item.add_time;
    }else{
      item_code = dish.item_code;
      add_time = dish.add_time;
    }
    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
    if(tempUnit && tempUnit.visitors && tempUnit.visitors[vIndex] && tempUnit.visitors[vIndex].kot){

      let total_count = 0;
      let kots = tempUnit.visitors[vIndex].kot;
      if(Array.isArray(kots)){
          kots.forEach( kot =>{
          if(kot.items && kot.items[0]){
            kot.items.forEach(item =>{
              if(item_code == item.item_code && add_time == item.add_time){
                total_count += item.item_count;
              }
            });
          }
        });
      }
      if(total_count == 0){
        return false;
      }else{
        dish['kot_item_count'] = total_count;
        return true;
      }
    }
  }

  hideExtraCharge(extraCharge:any){
    this.currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || [];
    let index = this.extraCharges.indexOf(extraCharge);
    if(index || index == 0){
      this.currentUnit.ExtraCharges = Object.assign(this.extraCharges[index],extraCharge)
      this.currentUnit.isExtraChargeChanges = true;
      this.currentUnit.ExtraCharges = JSON.parse(JSON.stringify(this.extraCharges));
    }
  }

  toggleExtraChargeDetails(event, extraCharge) {
    //event.stopPropagation();
    let index = this.extraCharges.indexOf(extraCharge);
    this.currentEditedExtraChargeIndex = index;
    extraCharge.expanded = !extraCharge.expanded;
  }
  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  editExtraChargeDetails(parcel, extraCharge) {
  }

  editDishItemCount(parcel, dish){
    var newDetails = parcel;
    var oldItemCount:any = 0;
    var changedCount:any = 0;
    if(dish.selected_variant_item){
      oldItemCount = dish.selected_variant_item.item_count;
      dish.selected_variant_item.item_count = (newDetails.quantity || newDetails.quantity == 0 ) ? parseFloat(newDetails.quantity) : parseFloat(dish.selected_variant_item.item_count);
      changedCount = dish.selected_variant_item.item_count - oldItemCount;
    }else{
      oldItemCount = dish.item_count;
      dish.item_count = (newDetails.quantity || newDetails.quantity == 0 ) ? parseFloat(newDetails.quantity) : parseFloat(dish.item_count);
      changedCount = dish.item_count - oldItemCount;
    }
    this.removeAddedDiscountItem();
    if(changedCount != 0) {
      this.updateBatchSerialItem(dish,Math.abs(changedCount),((changedCount>0) ?"add" : "remove"));
    }
  }

  changeItemCount(main_dish,dish,type) {        
    if(type == "minus") {
      /*if(dish.item_count-1 <= 0) {
        return;
      }*/
      dish.item_count--;
    } else {
      dish.item_count++;
    }

    main_dish.tempData.temp_item_count = dish.item_count;
    main_dish.loose_item_name = null;
    if(dish.attributes && dish.attributes.length>0) {
      dish.attributes.forEach(attr => {
        if(attr.count) {
          if(type == "minus") {
            attr.count -= (attr.old_count) ? parseFloat(attr.old_count) : 1;
          } else {
            attr.count += (attr.old_count) ? parseFloat(attr.old_count) : 1;;
          }
        }
      })
    }

    this.removeAddedDiscountItem();
    if(dish.item_count != 0) {
      this.updateBatchSerialItem(dish,1,type == "plus" ?"add" : "remove");
    }
  }

  commonPrint(obj) {
    let printData = {  
      assigny: this.currentUnit && this.currentUnit.assigny ? this.currentUnit.assigny : null,
      section: this.currentUnit && this.currentUnit.location ? this.currentUnit.location : null,
      seat_no: this.currentUnit && this.currentUnit.index ? this.currentUnit.index : 0,
      table_no: this.currentUnit && this.currentUnit.tables && this.currentUnit.tables[0] ? this.currentUnit.tables.join(', ') : null,
      delivery_date:this.currentUnit && this.currentUnit.delivery_date ? this.currentUnit.delivery_date : null,
      createdAt:new Date(),
      client_reference_code:'NA/'+this.stagedVisitor.temp_client_reference_code,
      cart_items: this.stagedVisitor.dishes[0],
      cart_tax:0,//added below      
      cart_value: 0,//added below,
      totalPrice: this.getTotalAmount(),
      discount:0,//added below    
      discountUnit:"FIXED",//added below    
      discount_amount:0,//added below    
      cash_discount:0,//added below    
      cashDiscountUnit:"FIXED",//added below      
      cash_discount_amount:0,//added below    
      on_subtotal_other_discounts:[],//added below    
      group_taxes:this.currentUnit.groupedTaxes ? this.currentUnit.groupedTaxes : [],
      extraCharges: this.extraCharges ? this.extraCharges : [],
      rounding_value:this.getRoundingOffAmount(),
      total_discount:0,//added below     
      note: "",
      customer_gstin: this.currentUnit && this.currentUnit.userInfo ? this.currentUnit.userInfo.GSTIN : null,
      customer_name:  this.currentUnit && this.currentUnit.userInfo ? this.currentUnit.userInfo.consumer_name : null,
      customer_address_1:  this.currentUnit && this.currentUnit.userInfo ? this.currentUnit.userInfo.address_1 : null,
      customer_mobile_no:  this.currentUnit && this.currentUnit.userInfo ? this.currentUnit.userInfo.mobile_no : null,
      discountReason:"",//added below      
      checkPrintNumber: obj ? obj.cpNo : null,//check print number
      splitPaymentList:[]
    }
    
    if(printData.group_taxes) {
      printData.group_taxes.forEach(tax => {
        tax.breakup_name = tax.tax;
      })
    }

    var subtotal_obj:any = this.getSubTotalAndDiscountAmount()
    printData["cart_value"] = subtotal_obj.total;
    let totalOtherItemWiseDiscount = subtotal_obj.totalDiscount;

    let overAllDiscount = 0.0;
    let orderDetail = this.stateGuard.obtain("payment.component:orderDetail");
    if(orderDetail) {
      if(orderDetail.cart_tax) {
        printData["cart_tax"] = orderDetail.cart_tax;
      }

      if(orderDetail.discountReason) {
        printData["discountReason"] = orderDetail.discountReason;
      }

      if(orderDetail.discountUnit && orderDetail.discount) {        
          printData.discount = orderDetail.discount;
          printData.discountUnit = orderDetail.discountUnit;
          printData.discount_amount = orderDetail.discount_amount;
          overAllDiscount += orderDetail.discount_amount;
      }

      if(orderDetail.cashDiscountUnit && orderDetail.cash_discount) {                  
          printData.cash_discount = orderDetail.cash_discount,
          printData.cashDiscountUnit = orderDetail.cashDiscountUnit,
          printData.cash_discount_amount = orderDetail.cash_discount_amount,
          overAllDiscount += orderDetail.cash_discount_amount;
      }
    }

    let otherDiscounts = 0.0;
    if(this.currentUnit.other_discounts && this.currentUnit.other_discounts.length > 0) {
      this.currentUnit.other_discounts.forEach(discount => {
        if(!discount.item_discounts_info || (discount.item_discounts_info && discount.item_discounts_info.length == 0)){
          var discCopy = JSON.parse(JSON.stringify(discount));
          if(discount.type.toLowerCase() == "cashback"){
            discCopy.name = "Cashback to be received:"
          }else{
            if(discCopy.name.toLowerCase().indexOf('discount') < 0){
              discCopy.name = discCopy.name + " (DISCOUNT)"
            }
          }
          discCopy.value = Math.abs(discCopy.value);
          printData['on_subtotal_other_discounts'].push(discCopy)
        }
        otherDiscounts += Math.abs(discount.value);
      })
    }

    printData.total_discount = totalOtherItemWiseDiscount + overAllDiscount + otherDiscounts;
    this.printHelper.firePrint(printData, 'bill');
    this.postCheckPrint();
  }


  addToSingleCheckoutForCheckPrint(visitor) {
    var index = 0;

    if(this.isRestaurant && this.currentUnit.visitors && this.currentUnit.visitors.length>1 && this.currentUnit.visitors[0].dishes[0].length>0){
      let is_item_added = false;
      for(var i=0;i<this.currentUnit.visitors.length;i++) {
        if(i !=0 && this.currentUnit.visitors[i].dishes && this.currentUnit.visitors[i].dishes[0].length > 0) {
          is_item_added = true;
          break;
        }
      }
      if(is_item_added) {
        this.messageService.add({ severity: 'success', summary: 'Default cart', detail: 'Please assign default cart items to respective seats' });
        return 'error';
      }
    }

    index = this.currentUnit.visitors.indexOf(visitor);
    if(!this.currentUnit.visitors[index]){
      this.messageService.add({ severity: 'error', summary: 'No seat selected', detail: 'Please select a seat for check print' });
      return 'error';
    }
    
    if(this.currentUnit.visitors && this.currentUnit.visitors.length>0 && this.currentUnit.visitors[index].dishes[0].length == 0){
      this.messageService.add({ severity: 'error', summary: 'No items available', detail: '' });
      return 'error';
    }

    let tempData = JSON.parse(JSON.stringify(this.currentUnit));
    localStorage.setItem('single_checkout_cart_items',JSON.stringify(tempData));
    for (var i = 0; i < tempData.visitors.length; ++i) {
      if(i == index){
      }else{
        tempData.visitors[i].dishes[0] = [];
        tempData.visitors[i].kot = [];
      }
    }
    this.stateGuard.entrust("selects-box.component:currentUnit",tempData);
    return 'success'
  }

  postCheckPrint(){
    
    if(this.currentUnit && ((['ST0001','ST0002'].includes(this.currentUnit.delivery_type)) ||
       (localStorage.getItem('isRestaurant') == 'false') || this.currentUnit.isParked || this.currentUnit.isOnHold)) {
      this.parkTheOrder.emit(true);
    } else {
      this.clearCustomerInfo();      
      this.router.navigate(['/pages/register/place-selection/here']);
    }
  }

  checkIsSpecialOrder(item) {
      return item.special_order_information && item.special_order_information.supplier_id;
  }

  capitalizeFirstLetter(string: string) {
    return string.trim().charAt(0).toUpperCase() + string.trim().slice(1).toLowerCase();
  }

  getCurrencySymbol() {
      this.shopConfiguration = JSON.parse(localStorage.getItem('shop_configuration'));
      if(this.shopConfiguration.currency){
        var currency = this.shopConfiguration.currency;
        if(currency.toUpperCase() == 'INR'){
          return 'Rs.';
        }else{
          var currency_symbol = this.shopConfiguration.currency_symbol;
          var symbol = currency_symbol.toLowerCase();
          if (symbol.charAt(0) === 'u') {
              symbol = symbol.split('u')[1];
              symbol = String.fromCharCode(parseInt(symbol, 16));
          }
          // console.log(symbol);
          return symbol;
        }
      } else {
        return "";
      }
  }

  prepareToPrintKOT(kot){
    let tempUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    let printerData = JSON.parse(localStorage.getItem('shop_printers'));
    if(printerData){
      var printers_list = printerData;
      var allInOnePrinter;
      var kotPrintersCount = 0;
      printers_list.forEach(printer => {
        if(printer.print_type.indexOf('kitchen') != -1 || printer.print_type.indexOf('self_service') != -1){
          kotPrintersCount++;
          allInOnePrinter = printer;
        }
      })


      if(kotPrintersCount > 0){
        if(shop_config.shop_info.kitchen_departments.length > 0){
        //print KOT department wise
        var departmentWiseItems = this.groupItemsDepartmentWise(kot.items);
        for(var dept in departmentWiseItems){
          var printer = this.findRequiredPrinter(printers_list,dept);
          if(printer == "Printer not found"){
                      //TODO: Handle case where wifi printer not found for a kot dept but there is a usb printer available for dat dept
            this.messageService.add({ severity: 'success', summary: 'Printer not found', detail: 'Neither Wifi nor USB Printer not found for department '+dept });
          }else{
            if (localStorage.getItem('PRINT_UTILITY')) {
              this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
            }
            if (this.printerUtility) {
              let tempUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
              this.kotHelper.printKotOnUtility(kot, departmentWiseItems[dept], printer, tempUnit.covers, true);
            } else {
            if(printer.ip_address && printer.ip_address != "" && printer.ip_address != null){
              //printer is a Wifi printer
              this.printKOT(kot,departmentWiseItems[dept],printer);
            }else{
              //printer is a USB printer
              this.kotHelper.printKOTonUSBPrinter(kot,departmentWiseItems[dept],true);
            }
          }
        }
        }
      }else if(shop_config.shop_info.kitchen_departments.length == 0){
          if(allInOnePrinter.ip_address && allInOnePrinter.ip_address != "" && allInOnePrinter.ip_address != null){
            //printer is a Wifi printer
            this.printKOT(kot,kot.items,allInOnePrinter);
          }else{
            //printer is a USB printer
            this.kotHelper.printKOTonUSBPrinter(kot,kot.items,true);
          }
      }
    }else{
        //neither Wifi not usb printer found
        this.messageService.add({ severity: 'success', summary: 'Wifi nor USB Printer found', detail: 'Neither Wifi nor USB printer found for KOT print'});
      }
    }else{
      this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
    }
  }


  groupItemsDepartmentWise(items){
    var departmentWiseItems:any = {};
    items.forEach(item => {
      if(item.kitchen_department != "" && item.kitchen_department != null){
        if(item.kitchen_department in departmentWiseItems){
          departmentWiseItems[item.kitchen_department].push(item);
        }else {
          departmentWiseItems[item.kitchen_department] = [];
          departmentWiseItems[item.kitchen_department].push(item);
        }
      }else{
        if('default' in departmentWiseItems){
          departmentWiseItems['default'].push(item);
        }else {
          departmentWiseItems['default'] = [];
          departmentWiseItems['default'].push(item);
        }
      }
    })
    return departmentWiseItems;
  }

  findRequiredPrinter(printers_list,dept){
    var printer_found = false;
    var search_default_printer = false;
    if(dept == 'default'){
        search_default_printer = true;
    }

    //search for department printer
    if(!search_default_printer){
      for(var i = 0 ; i < printers_list.length ; i++){
        if(printers_list[i].kitchen_department.trim() == dept.trim() && (printers_list[i].print_type.indexOf('kitchen') != -1 || printers_list[i].print_type.indexOf('self_service') != -1)){
          printer_found = true;
          return printers_list[i];
        }
      }
      search_default_printer = true;
    }

    //search for default printer if dept is 'default' or a printer for a dept could not be found
    if(search_default_printer){
      for(var i = 0 ; i < printers_list.length ; i++){
        // a printer is a default printer if it has no kitchen_department
        if((printers_list[i].kitchen_department.trim() == "" || printers_list[i].kitchen_department == null) && (printers_list[i].print_type.indexOf('kitchen') != -1 || printers_list[i].print_type.indexOf('self_service') != -1)){
          printer_found = true;
          return printers_list[i];
        }
      }
    }

    if(!printer_found){
      return "Printer not found"
    }
  }


  printKOT(fullkot,dept_items,dept_printer){
    // console.log("items:"+JSON.stringify(dept_items));
    // console.log("printer:"+JSON.stringify(dept_printer));
    let tempUnit = this.stateGuard.obtain('selects-box.component:currentUnit');

      this.builder = new epson.ePOSBuilder();
    // this.canvas = document.getElementById('printQuick');
      var shopName = localStorage.getItem('shop_name');
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText('\n'+ shopName + '\n');
      // this.builder.addText('KOT Reprint\n');
      this.builder.addText('KOT Reprint No: '+fullkot.kot_number+'\n');
      var today = new Date();
      var date = new Date().toJSON().slice(0, 10);
      var time = (today.getHours().toString().length == 1 ? '0' + today.getHours() : today.getHours()) + ':' + (today.getMinutes().toString().length == 1 ? '0' + today.getMinutes() : today.getMinutes()) + ':' + (today.getSeconds().toString().length == 1 ? '0' + today.getSeconds() : today.getSeconds());
      this.builder.addText('Kot Date: ' + date + ' ' + time + '\n');
      // this.builder.addText('Kot Date : ' + this.kotDate + '\n');

      if(tempUnit && tempUnit.location){
        this.builder.addText('Section: ' + this.capitalizeFirstLetter(tempUnit.location) + '\n');
      }
      if(tempUnit && tempUnit.tables && tempUnit.tables.length > 0){
        this.builder.addText('Table No: ' + tempUnit.tables[0] + '\n');
      }
      if(fullkot.kot_id.split("_")[2] != 0){
        this.builder.addText('Seat No: ' + fullkot.kot_id.split("_")[2] + '\n');
      }

      this.builder.addText('---------------------------------------------\n');

      // Add each line item to receipt (function below)
      var cartItems = dept_items;
      for (var i = 0; i < cartItems.length; i++) {
        var attributes: any = [];
        if (cartItems[i].attributes.length > 0) {
          attributes = cartItems[i].attributes;
        }
        var name = this.capitalizeFirstLetter(cartItems[i].item_name);
        this.createKotItemLine(name, cartItems[i], attributes);
      }
      this.builder.addTextAlign(this.builder.ALIGN_CENTER);
      this.builder.addText('---------------------------------------------\n');

      // Add 2 line feeds and cut
      this.builder.addFeed();
      this.builder.addFeed();
      this.builder.addCut(this.builder.CUT_FEED);
      var ipAddr = '192.168.1.199';
      if (dept_printer) {
        ipAddr = dept_printer.ip_address;
      }else {
        ipAddr = '192.168.1.201';
      }
      var address = 'http://' + ipAddr + '/cgi-bin/epos/service.cgi?devid=local_printer&timeout=30000';
      var epos = new epson.ePOSPrint(address);
      // Check if printer cover is open
      epos.oncoveropen = function () { alert('coveropen'); };
      // Send print job
      epos.send(this.builder.toString());
  }

  // create Items for kot
  createKotItemLine(productName: any, item: any, attributes: any) {
    let itemNameAndCountString = item.item_count + " x " + productName;
    let dHeight = Math.ceil(itemNameAndCountString.length/35)*30; //we suppose 35 character will come in one line
    this.builder.addPageBegin();
    this.builder.addPageArea(60, 0, 436, dHeight);
    this.builder.addText(itemNameAndCountString);
    this.builder.addPageArea(436, 0, 100, dHeight);
    this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
    this.builder.addPageEnd();

    if (attributes.length > 0) {
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addTextFont(this.builder.FONT_C);
      // this.builder.addText(notes + "\n");
      for (var attr = 0; attr < attributes.length; attr++) {
        this.builder.addPageBegin();
        
        var attrName = this.capitalizeFirstLetter(attributes[attr].name);
        let attrNameAndCountString = attributes[attr].count + " x " + attrName;
        let adHeight = Math.ceil(attrNameAndCountString.length/35)*30;
        this.builder.addPageArea(106, 0, 456, adHeight);
        this.builder.addText(attrNameAndCountString);
        // this.builder.addPageArea(415, 0, 100, 30);
        // this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
        this.builder.addPageEnd();
      }
      this.builder.addTextFont(this.builder.FONT_A);
    }

    if(item.note){
      this.builder.addTextAlign(this.builder.ALIGN_LEFT);
      this.builder.addPageBegin();
      let noteString = "Note: " + item.note;
      let ndHeight = Math.ceil(noteString.length/35)*30;
      this.builder.addPageArea(106, 0, 456, ndHeight);
      this.builder.addText(noteString);
      // this.builder.addPageArea(408, 0, 172, 30);
      // this.builder.addTextAlign(this.builder.ALIGN_RIGHT);
      this.builder.addPageEnd();
      this.builder.addTextFont(this.builder.FONT_A);
    }
  }

  detectCartSections(cartItems) {
    //console.log("cartItems in detectCartSections"+JSON.stringify(cartItems));

    var sectionAlreadyPresentFlag = false;
    var cartSections = [];
    var cartSectionsObj = {
      sectionName: "",
      itemCount: 0,
      subTotal: 0,
      items: []
    };

    if(cartItems){
      for(var i = 0; i < cartItems.length ; i++){

        var item;
        if(cartItems[i].selected_variant_item){
          item = JSON.parse(JSON.stringify(cartItems[i].selected_variant_item));
        }else{
          item = JSON.parse(JSON.stringify(cartItems[i]));
        }

        cartSectionsObj = {
                    sectionName:"",
                    itemCount: 0,
                    subTotal: 0,
                    items:[]
                };

        sectionAlreadyPresentFlag = false;
        for(var j = 0; j < cartSections.length ; j++){
          if(this.findSection(item.tags) == cartSections[j].sectionName){
            sectionAlreadyPresentFlag = true;
            cartSections[j].itemCount = cartSections[j].itemCount + item.item_count;

            cartSections[j].subTotal = cartSections[j].subTotal + this.getCartItemValue(item);

            // cartSections[j].items.push(cartItems[i]);
            cartSections[j].items.push(item);
            // console.log("Old this.cartSections["+j+"] :"+JSON.stringify(this.cartSections[j]));
            //console.log("cart item value!!! "+this.getCartItemValue(cartItems[i]));
          }
        }
        if(!sectionAlreadyPresentFlag){
          cartSectionsObj.sectionName = this.findSection(item.tags);
          cartSectionsObj.itemCount = item.item_count;
          cartSectionsObj.subTotal = this.getCartItemValue(item);

          // cartSectionsObj.items.push(cartItems[i]);
          cartSectionsObj.items.push(item);
          cartSections.push(cartSectionsObj);
          // console.log("New this.cartSections"+ JSON.stringify(cartSectionsObj));
        }
      }
  }
    return cartSections;
  }

  findSection(tags: any) {
    /*var receipt_sections:any = {
                'bar':["wine","beer","whisky","alcohol","drink"],
                'food':["burger","fries"]
                 };*/

    var shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    var receipt_sections: any = shop_config["shop_info"]["receipt_sections"];
    var section;

    //localStorage.receipt_sections
    for(var i = 0; i < tags.length ;i++){
      for( section in receipt_sections){
        for(var j = 0 ; j < receipt_sections[section].length ;j++){
          // console.log("receipt_sections[section][j]"+receipt_sections[section][j] + " tags["+i+"] :"+tags[i]);
          if(receipt_sections[section][j].toLowerCase() === tags[i].toLowerCase()){
            // console.log(receipt_sections[section][j]+"==="+tags[i] + "   "+(receipt_sections[section][j] === tags[i]));
            // console.log("returning section:"+ section);
            // console.log("items of section "+section+ " "+this.getCartItemsOfGivenSection(section));
            return section;
          }
        }
      }
    }
    return "Section Not Found";
  }

  getCartItemValue(item){
    var sellPrice = 0;
    //adding item price
    sellPrice = item.unit_price * item.item_count;
    if(this.shopConfiguration.show_post_tax_price && this.shopConfiguration.item_wise_tax && item.tax_value){
      sellPrice = sellPrice + item.tax_value;
    }

    //adding modifier price to item price
    if (item.attributes.length > 0){
      for (var attr = 0; attr < item.attributes.length; attr++) {
        if(item.attributes[attr].unit_price && item.attributes[attr].count){
          sellPrice = sellPrice + (item.attributes[attr].unit_price * item.attributes[attr].count);
        }
      }
    }
    //deducting item wise discount
    if(item.discount && (item.discount_type || item.discountUnits)){
      let discType = item.discount_type ? item.discount_type : item.discountUnits;
      if(discType != '%'){
          sellPrice -= item.discount;
      }else{
          sellPrice -= (item.unit_price * item.item_count) * (item.discount/100);
      }
    }

    sellPrice -= item.other_discounts_total;

    return sellPrice;
  }

  groupItemsByTaxCode(items){
      var taxCodeWiseItems = [];
      var findCode = "";
      items.forEach(itm => {
        var item;
        if(itm.selected_variant_item){
          item = JSON.parse(JSON.stringify(itm.selected_variant_item));
        }else{
          item = JSON.parse(JSON.stringify(itm));
        }
        if(item.tax_settings && item.tax_settings.length > 0){
          //item has tax
          if(item.tax_settings[0].code && item.tax_settings[0].rate){
            findCode = item.tax_settings[0].code;
          }
        }else{
          //item doesn't have tax
          findCode = "no_tax";
        }

        if(taxCodeWiseItems.filter(taxGroup => taxGroup.code == findCode).length > 0){
          //if tax code already present
          var taxGrIndex = taxCodeWiseItems.indexOf(taxCodeWiseItems.filter(taxGroup => taxGroup.code == findCode)[0]);
          taxCodeWiseItems[taxGrIndex].items.push(JSON.parse(JSON.stringify(item)));
          }else{
            //if tax code not already present
            var taxGroup = {'code': findCode , 'rate':0.0 , 'items':[]};
            if(item.tax_settings && item.tax_settings.length > 0){
              taxGroup.rate = item.tax_settings[0].rate;
            }
            taxGroup.items.push(JSON.parse(JSON.stringify(item)));
            taxCodeWiseItems.push(taxGroup);
          }
      })
      return taxCodeWiseItems;
    }

    onClickQuickDiscount() {
      let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};

      this.isLoadingShow = true;
      let is_vat_gst_item_available = false;
      this.shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
      //check for vat gst items.
      if(this.shopConfig && this.shopConfig.enable_separate_bill_for_tax_classes) {
          let temp_cart_items = [];        
          if(tempUnit.visitors && tempUnit.visitors.length>0){
            tempUnit.visitors.forEach(visitor => {
                if(Array.isArray(visitor.dishes)) {
                  visitor.dishes.forEach(dish => {
                    if(Array.isArray(dish)) {
                      dish.forEach(item => {
                        temp_cart_items.push(item);
                      });
                    }
                  });
                }
            })
          }
          if(SeparateBillHelper.cheackIsDifferentTaxClassesItemAvailable(temp_cart_items)) {
            is_vat_gst_item_available = true;
          }
      }
      let param = {
        shop_code : localStorage.getItem('shop_code'),
        user_auth_token : tempUnit.token,
        cart_items : tempUnit.cart_items,
        sub_total : tempUnit.subtotal,
        shipping_type : tempUnit.delivery_type,
        is_vat_gst_item_available: is_vat_gst_item_available
        /*discount_order_id :this.discount_order_id*/
      }

      this.shopConfigurationService.getVerifiedDiscount(param).subscribe((res) => {
        var result = res.json();
        if (result.status == 0 && result.result.data && result.result.data.length > 0) {
          if(!tempUnit.external_discount_list) {
            tempUnit.external_discount_list = [];
          }
          let isAnyDiscountMatched:boolean = false;
          let appliedDiscountNameList = [];
          result.result.data.forEach((applied_discount:any) => {
            var fData = tempUnit.external_discount_list.filter( i => i.id == applied_discount.discount_detail.id);
            appliedDiscountNameList.push(applied_discount.discount_detail.discount_code);
            if(fData.length == 0) {
              applied_discount.discount_detail["max_discount"] = applied_discount.max_discount;
              applied_discount.discount_detail["add_time"] = new Date().getTime();
              /*applied_discount.discount_detail["discount_order_id"] = (applied_discount.discount_detail.id="discount_on_order" && this.discount_order_id) ? JSON.parse(JSON.stringify(this.discount_order_id)) : "";*/
              tempUnit.temp_external_discount_list.push(applied_discount.discount_detail);
              isAnyDiscountMatched = true;
            }
          });
          
          if(appliedDiscountNameList.length>0) {
            this.messageService.add({ severity: 'success', summary: 'New discount applied', detail: appliedDiscountNameList.join(",")+' applied successfully' });
          } 
          if(isAnyDiscountMatched) {                      
            this.checkForDiscount();
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'No discount matched', detail: '' });        
        }
        this.isLoadingShow = false;
      },err =>{
          this.isLoadingShow = false;
          this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: '' });
      });
  }

}

         