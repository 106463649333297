import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Dish } from '../dish';
import { remove } from 'lodash-es';
import { MessageService } from 'primeng/api';
import { StateGuardService } from '../state-guard.service';

@Component({
  selector: 'app-batch-serial-item-selection-modal',
  templateUrl: './batch-serial-item-selection-modal.component.html',
  styleUrls: ['./batch-serial-item-selection-modal.component.less'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        transform: 'translateY(-100%)'
      })),
      state('active', style({
        transform: 'translateY(0%)'
      })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ]),
    trigger('fadeOverlay', [
      state('inactive', style({
        opacity: '0'
      })),
      state('active', style({
        opacity: '1'
      })),
      transition('inactive => active', animate('150ms ease-in')),
      transition('active => inactive', animate('150ms ease-out'))
    ])
  ]
})
export class BatchSerialItemSelectionModalModalComponent implements OnInit {

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() doneEvent: EventEmitter<any> = new EventEmitter();
  @Output() addingDish: EventEmitter<Dish> = new EventEmitter();
  @Input('dish') dish: any;

  currencySymbol:any;
  isLoadingShow:boolean= false;  
  anim_state: string = 'inactive';

  public temp_dish:any = {};
  constructor(private messageService:MessageService, 
              private stateGuard: StateGuardService) { }

  ngOnInit() {
    var self = this;
    setTimeout(() => {
      self.anim_state = 'active';
    }, 150);

    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shop_config.currency;    
    this.initData();
  }

  initData() {
    this.temp_dish = (this.dish.selected_variant_item) ? JSON.parse(JSON.stringify(this.dish.selected_variant_item)) : JSON.parse(JSON.stringify(this.dish));
    remove(this.temp_dish.batch_serial_stock_info, bssi => { return (!bssi.tracking_mode && !bssi.tracking_number);})
    this.temp_dish.batch_serial_stock_info.push({
      unit_cost:this.temp_dish.unit_code,
      unit_price:this.temp_dish.unit_price
    })
  }

  close() {
    setTimeout(() => {
      this.closeEvent.emit();
    }, 200);
    this.anim_state = 'inactive';
  }

  addItem(item) {   
    if(item.tracking_number && item.tracking_mode) {
      this.temp_dish.tracking_number = item.tracking_number;
      this.temp_dish.tracking_mode = item.tracking_mode;
      this.temp_dish.tracking_batch_serial_item_quantity = item.quantity;
      if(item.unit_price) { this.temp_dish.unit_price = parseFloat(item.unit_price); }
      if(item.unit_code) { this.temp_dish.unit_cost = parseFloat(item.unit_cost); }    
    }

    remove(this.temp_dish.batch_serial_stock_info, bssi => { return (!bssi.tracking_mode && !bssi.tracking_number);})    
    if(this.dish.selected_variant_item) {
      this.dish.selected_variant_item = JSON.parse(JSON.stringify(this.temp_dish));
    } else {
      this.dish = JSON.parse(JSON.stringify(this.temp_dish));
    }
    this.done();
  }

  done() {
    setTimeout(() => {
      this.doneEvent.emit(this.dish);
    }, 200);    
    this.anim_state = 'inactive';  
  }
}