import { Component, OnInit } from '@angular/core';
import { cash_info } from './cash-info';
import { CashRegisterService } from '../../helpers/services/cash-register.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cash',
  templateUrl: './cash.component.html',
  styleUrls: ['./cash.component.less']
})
export class CashComponent implements OnInit {

	cash_list:any[] = [];

  action: boolean;
	isCashRegisterOpen: boolean;
  state: string = '';
	currencySymbol: string = '';
  public modalRef: BsModalRef;
  public subscriptions: Subscription[] = [];
  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  constructor( private messageService:MessageService,
              private modalService: BsModalService,
              private cashRegisterService:CashRegisterService ) { }

  ngOnInit() {
    let shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shopConfig.currency;
    if(localStorage.getItem('paid_in_out_list')){
      this.cash_list = JSON.parse(localStorage.getItem('paid_in_out_list'));
    }else{
      this.cash_list = [];
    }
    if(localStorage.getItem('isCashRegister') && localStorage.getItem('isCashRegister')=='true'){
      this.isCashRegisterOpen = true;
    }
  }

  addCash(cash) {
    if(this.state == 'Add'){
      this.paidIn(cash);
    }else if(this.state == 'Remove'){
      this.paidOut(cash);
    }
  }

  paidIn(cash){
    let device_info = JSON.parse(localStorage.getItem('device_key')) || {};

    let sData = {
      "denomination": "",
      "is_closing": false,
      "is_opening": false,
      "remark": cash.notes,
      "shift": "",
      "total_cash": cash.transaction,
      "category": cash.category,
      "user_id": cash.id,
      "terminal_id":(device_info && device_info.terminal_id) ? device_info.terminal_id : null,
      "shop_code": localStorage.getItem('shop_code')
    }
    this.cashRegisterService.cashIn(sData).subscribe((res) => {
      let resData = res.json();
      if(resData.status === 0){
        let cash_register = JSON.parse(localStorage.getItem('cash_register')) || [];
        if(cash_register && cash_register[0]){
          cash_register[0].paid_in =  cash.transaction + cash_register[0].paid_in;
        }
        localStorage.setItem('cash_register',JSON.stringify(cash_register));
        this.cash_list.push(cash);
        localStorage.setItem('paid_in_out_list',JSON.stringify(this.cash_list));
      }
    }, err =>{
        this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: '' });
    });

  }

  paidOut(cash){
    let device_info = JSON.parse(localStorage.getItem('device_key')) || {};

    let sData = {
      "denomination": "",
      "is_closing": false,
      "is_opening": false,
      "remark": cash.notes,
      "shift": "",
      "total_cash": Math.abs(cash.transaction),
      "category": cash.category,
      "user_id": cash.id,
      "terminal_id":(device_info && device_info.terminal_id) ? device_info.terminal_id : null,      
      "shop_code": localStorage.getItem('shop_code')
    }
    this.cashRegisterService.cashOut(sData).subscribe((res) => {
      let resData = res.json();
      if(resData.status === 0){
        let cash_register = JSON.parse(localStorage.getItem('cash_register')) || [];
        if(cash_register && cash_register[0]){
          cash_register[0].paid_out =  Math.abs(cash.transaction) + cash_register[0].paid_out;
        }
        localStorage.setItem('cash_register',JSON.stringify(cash_register));
        this.cash_list.push(cash);
        localStorage.setItem('paid_in_out_list',JSON.stringify(this.cash_list));
      }
    }, err =>{
        this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: '' });
    });

  }

}
