import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportService } from '../../helpers/services/report.service';
import { APIHelper } from '../../helpers/api-helper';
import { ReportHelper } from '../../helpers/report-helper';
import { forOwn, groupBy, values, sumBy, uniq } from 'lodash-es';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
// import { constants } from 'fs';


@Component({
    selector: 'app-sectionwise-item-detail-report',
    templateUrl: './sectionwise-item-detail-report.component.html',
    styleUrls: ['./sectionwise-item-detail-report.component.css'],
    providers: [ReportService]
})
export class SectionwiseDetailReportComponent implements OnInit {


    private tagWiseDetailsReportDate: any = {};
    private reportDetailData: any;
    private currencySymbol: any;
    public isDataLoad: boolean = false;
    public reportHelper: ReportHelper = new ReportHelper(this.http);
    public shopConfiguration: any;
    public merchantProfile: any;
    public reportDataArray: any;
    printerUtility: boolean;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private report: ReportService,
        private http:HttpClient,
        private messageService: MessageService) {
    }

    ngOnInit() {
        this.shopConfiguration = JSON.parse(localStorage.getItem('shop_configuration'));
        if (!this.shopConfiguration) {
            this.router.navigate(['verify-pin']);
            return;
        }
        this.merchantProfile = JSON.parse(localStorage.getItem('merchant_profile'));
        var date = new Date();
        var mm: any = new Date(date.toDateString()).getMonth();
        mm = mm + 1;
        if (mm.toString().length === 1) {
            mm = '0' + mm;
        }
        var dd: any = new Date(date.toDateString()).getDate();
        var yy: any = new Date(date.toDateString()).getFullYear();
        if (dd.toString().length === 1) {
            dd = '0' + dd;
        }
        var shop_code = localStorage.getItem('shop_code');
        var from_date = yy + '-' + mm + '-' + dd + ' 00:00:00';
        var to_date = yy + '-' + mm + '-' + dd + ' 23:59:59';

        let report_type = 'item_tag_wise_detail';
        let tags: any[];
        let sub_report_type = "";

        let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
        this.currencySymbol = shop_config.currency;

        if (!navigator.onLine) {
            this.getOfflineReport().then(items_summary => {
                this.reportDetailData = items_summary;
                this.isDataLoad = true;
            })
        } else {
            this.report.getReport(shop_code, from_date, to_date, report_type, sub_report_type, tags).subscribe((report) => {
                var res = report.json();
                if (res.status == 0) {
                    this.reportDetailData = res.result.items_summary;
                    this.isDataLoad = true;
                    if (res.result.date) {
                        if (res.result.date.from_date) {
                            var tempDate = new Date(res.result.date.from_date);
                            res.result.date.from_date = tempDate.toLocaleDateString() + " " + tempDate.toLocaleTimeString();
                        }

                        if (res.result.date.to_date) {
                            var tempDate = new Date(res.result.date.to_date);
                            res.result.date.to_date = tempDate.toLocaleDateString() + " " + tempDate.toLocaleTimeString();
                        }
                    }
                    this.tagWiseDetailsReportDate = res.result.date;
                }
            });
        }
    }

    getTotal(itemDetails: any) {
        var total = 0;
        /*itemDetails.tag_wise_item_list.forEach(item: any =>{
            total+=item.total_tag_item_price
        });*/
        for (var idx = 0; idx < itemDetails.tag_wise_item_list.length; idx++) {
            total += itemDetails.tag_wise_item_list[idx].total_tag_item_price;
        }
        return total;
    }

    getGroupType(item: any) {
        return (item.shop_section == "NA") ? item.delivery_type : item.shop_section;
    }

    getReportDetailData() {
        return this.reportDetailData;
    }

    getSum(itemDetail: any) {
        //return (itemDetail.item_price + (itemDetail.item_tax_value/itemDetail.item_count) - itemDetail.item_discount)
        return (itemDetail.item_total_price)
    }

    getOfflineReport(): Promise<any> {
        let self = this;
        return new Promise<any>(function (resolve, reject) {
            let shopConfig: any = JSON.parse(localStorage.getItem("shop_configuration")) || {};
            let placedOrder: any = JSON.parse(localStorage.getItem("temp_orders_list")) || [];
            let offlineOrder: any = JSON.parse(localStorage.getItem("bill_queue")) || [];
            let paymentTypes = [
                { payment_type: "SAVE_TO_DIARY_TYPE", payment_type_code: APIHelper.SAVE_TO_DIARY_TYPE },
                { payment_type: "CASH_ON_DELIVERY_TYPE", payment_type_code: APIHelper.CASH_ON_DELIVERY_TYPE },
                { payment_type: "CASH_IN_HAND_TYPE", payment_type_code: APIHelper.CASH_IN_HAND_TYPE },
                { payment_type: "CARD_PAYMENT_TYPE", payment_type_code: APIHelper.CARD_PAYMENT_TYPE },
                { payment_type: "COUPON_PAYMENT_TYPE", payment_type_code: APIHelper.COUPON_PAYMENT_TYPE },
            ];
            let shopOrders = [];
            let groupedOrderInfo = [];

            if (!Array.isArray(placedOrder)) { placedOrder = []; }
            if (!Array.isArray(offlineOrder)) { offlineOrder = []; }

            placedOrder = placedOrder.concat(offlineOrder);
            placedOrder.forEach(order => {
                order.order.diary_history = order.diary_history;
                order.order.items = order.items;
                shopOrders.push(order.order);
            });

            if (shopOrders.length == 0) {
                resolve([]);
            } else {
                var sectionWiseGroupedOrders = groupBy(shopOrders, function (orderRes) { return orderRes.shop_section; });
                var sectionWiseGroupedOrdersLock = Object.keys(sectionWiseGroupedOrders).length;

                forOwn(sectionWiseGroupedOrders, (shopSectionWiseOrdersDetail, shop_section) => {
                    var groupedOrderDetails = {};
                    groupedOrderDetails[shop_section] = shopSectionWiseOrdersDetail;
                    if (!shop_section || shop_section == "null") {
                        shop_section = "NA";
                        groupedOrderDetails = {};
                        var shippingTypeWiseGroupedOrders = groupBy(shopSectionWiseOrdersDetail, function (orderRes) { return orderRes.shipping_type; });
                        forOwn(shippingTypeWiseGroupedOrders, (shippingTypeWiseOrdersDetail, shipping_type) => {
                            if (shipping_type == APIHelper.HOME_DELIVERY) {
                                shipping_type = "Hand Pickup";
                            } else if (shipping_type == APIHelper.PICK_UP) {
                                shipping_type = "Home Delivery";
                            } else if (shipping_type == APIHelper.IMMEDIATE) {
                                shipping_type = "Immediate";
                            }
                            groupedOrderDetails[shipping_type] = shippingTypeWiseOrdersDetail;
                        });
                    }

                    var groupedOrdersDetailLock = Object.keys(groupedOrderDetails).length;

                    forOwn(groupedOrderDetails, (ordersDetail, grouped_type) => {
                        var orderPaymentsDetail = ordersDetail.map(function (uod) { return uod; });
                        self.reportHelper.orderSummaryPaymentTypes(orderPaymentsDetail, paymentTypes).then(function (ordSum) {
                            var merchantOrderIds = ordersDetail.map(o => o.order_id);
                            var total_table = 0;
                            var total_table_covers = sumBy(ordersDetail, function (orderRes) { return (orderRes.table_covers) ? orderRes.table_covers : 0; });
                            total_table_covers = (total_table_covers == 0) ? 1 : total_table_covers;
                            var table_configuration = (shopConfig) ? shopConfig.table_configuration : {};
                            if (table_configuration.length > 0) {
                                table_configuration.forEach(table_configuration_info => {
                                    if (table_configuration_info.section == shop_section) {
                                        total_table = (table_configuration_info.tables) ? table_configuration_info.tables : 0;
                                    }
                                });
                            }

                            self.reportHelper.getUserExpensesByOrderIdS(ordersDetail).then(function (totalExpenses) {
                                var itemDetails = [];
                                ordersDetail.forEach(o => { itemDetails = itemDetails.concat(o.items); });
                                var groupedOrderItems = values(groupBy(itemDetails, function (itemDetail) { return itemDetail.item_code; }));
                                var gropItemsLock = groupedOrderItems.length;
                                var itemsList = [];
                                var total_price = 0;
                                groupedOrderItems.forEach(function (items, itemsIdx, itemsArr) {
                                    var itemLock = items.length;
                                    var total_item_price = 0;
                                    var total_item_count = 0
                                    items.forEach(function (item, itemIdx, itemArr) {
                                        itemLock -= 1;
                                        item.item_tags = (item.item_tags && Array.isArray(item.item_tags)) ? item.item_tags : [];
                                        item.item_tags = item.item_tags.map(tag => tag.toLowerCase());
                                        item.attributes = item.attributes;
                                        item.item_price = (item.item_price) ? item.item_price : 0;
                                        item.discount = (item.discount) ? item.discount : 0;
                                        item.tax_value = (item.tax_value) ? item.tax_value : 0;
                                        item.attributes.forEach(attr => {
                                            item.item_price += (parseFloat(attr.unit_price) * parseFloat(attr.count));
                                        });
                                        total_item_price += ((parseFloat(item.item_price) * parseFloat(item.item_count)) + parseFloat(item.tax_value) - parseFloat(item.discount));
                                        total_item_count += parseFloat(item.item_count);
                                        if (itemLock === 0) {
                                            total_price += total_item_price;
                                            itemsList.push({
                                                'item_total_price': total_item_price,
                                                'item_name': item.item_name,
                                                'item_count': total_item_count,
                                                'item_price': parseFloat(item.item_price),
                                                'item_discount': parseFloat(item.discount),
                                                'item_tax_value': parseFloat(item.tax_value),
                                                'item_tags': item.item_tags
                                            });
                                            gropItemsLock -= 1;
                                            if (gropItemsLock === 0) {
                                                var tagList = [];
                                                tagList.push("NA");
                                                itemsList.forEach(itemData => {
                                                    tagList = tagList.concat(itemData.item_tags);
                                                });

                                                tagList = uniq(tagList);

                                                var tagWiseItemList = [];
                                                tagList.forEach(tag => {
                                                    var total_tag_item_price = sumBy(itemsList, function (itemData) { return (itemData.item_tags.indexOf(tag.toLowerCase()) != -1 || (itemData.item_tags.length == 0 && tag == "NA")) ? itemData.item_total_price : 0; });
                                                    var itemListByTag = itemsList.filter(itemData => {
                                                        return (itemData.item_tags.indexOf(tag.toLowerCase()) != -1 || (itemData.item_tags.length == 0 && tag == "NA"))
                                                    });
                                                    if (tag != "NA" || (tag == "NA" && total_tag_item_price != 0)) {
                                                        tagWiseItemList.push({
                                                            total_tag_item_price: (total_tag_item_price) ? total_tag_item_price : 0,
                                                            item_details: itemListByTag,
                                                            tag: tag
                                                        })
                                                    }
                                                });

                                                groupedOrderInfo.push({
                                                    shop_section: shop_section,
                                                    delivery_type: (shop_section == "NA") ? grouped_type : "Immediate",
                                                    total: ordSum.total,
                                                    cash_total: ordSum.payment_summary[1][APIHelper.CASH_ON_DELIVERY_TYPE][1] + ordSum.payment_summary[2][APIHelper.CASH_IN_HAND_TYPE][1],
                                                    card_total: ordSum.payment_summary[3][APIHelper.CARD_PAYMENT_TYPE][1],
                                                    other_total: ordSum.payment_summary[4][APIHelper.COUPON_PAYMENT_TYPE][1],
                                                    diary_total: (totalExpenses.diary_amount) ? totalExpenses.diary_amount : 0,
                                                    reward_point_total: (totalExpenses.reward_points) ? totalExpenses.reward_points : 0,
                                                    credit_total: (totalExpenses.advanced) ? totalExpenses.advanced : 0,
                                                    outstanding_total: (totalExpenses.outstanding) ? totalExpenses.outstanding : 0,
                                                    discount_total: (totalExpenses.discount_amount) ? totalExpenses.discount_amount : 0,
                                                    tag_wise_item_list: tagWiseItemList,
                                                    table_covers: total_table_covers,
                                                    total_table: total_table
                                                });

                                                groupedOrdersDetailLock--;
                                                if (groupedOrdersDetailLock == 0) {
                                                    sectionWiseGroupedOrdersLock--;
                                                    if (sectionWiseGroupedOrdersLock == 0) {
                                                        resolve(groupedOrderInfo);
                                                    }
                                                }
                                            }
                                        }
                                    });
                                });
                            })
                        })
                    });
                })
            }
        });

    }

    prepareToPrintSectionwiseDetailReport() {
        try {
            var printerObj = this.reportHelper.checkPrinters();
            if (localStorage.getItem('PRINT_UTILITY')) {
                this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
            }
            if (this.printerUtility) {
                this.reportDataArray = [];
                var reportDataValues = JSON.parse(JSON.stringify(this.reportDetailData));
                reportDataValues.forEach(item => {
                    item.tag_wise_item_list.forEach(itm => {
                        itm.item_details.forEach(itemDetail => {
                            itemDetail.item_price = this.getSum(itemDetail);
                        })
                    })
                    this.reportDataArray.push({ "items": item, 'group_type': this.getGroupType(item), 'total': this.getTotal(item) });
                })
                if (this.reportDataArray.length > 0) {
                    this.reportHelper.printSectionWiseReportOnUtility(this.reportDataArray);
                } else {
                    this.messageService.add({ severity: 'success', summary: 'Report data not available at this time', detail: 'Report data not available at this time' });
                }

            }
            else if (printerObj) {
                if (printerObj.wifi_present) {
                    this.reportDataArray = [];
                    var reportDataValues = JSON.parse(JSON.stringify(this.reportDetailData));
                    reportDataValues.forEach(item => {
                        item.tag_wise_item_list.forEach(itm => {
                            itm.item_details.forEach(itemDetail => {
                                itemDetail.item_price = this.getSum(itemDetail);
                            })
                        })
                        this.reportDataArray.push({ "items": item, 'group_type': this.getGroupType(item), 'total': this.getTotal(item) });
                    })
                    if (this.reportDataArray.length > 0) {
                        this.reportHelper.printSectionwiseDetailReportonWifi(this.reportDataArray);
                    } else {
                        this.messageService.add({ severity: 'success', summary: 'Report data not available at this time', detail: 'Report data not available at this time' });
                    }
                } else if (printerObj.USB_present) {
                    let printContents = document.getElementById('sectionwiseDetailReportPrintDiv').innerHTML;
                    this.reportHelper.printUSBReport(printContents);
                } else {
                    console.log("Neither USB not Wifi printer available for printing the report");
                    alert('Neither USB not Wifi printer available for printing the report');
                }
            } else {
                console.log("Printer data could not be fetched");
                alert('Printer data could not be fetched at this time');
                // this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
            }

        } catch (e) {
            console.log(e);
            this.messageService.add({ severity: 'success', summary: 'Report data not available at this time', detail: 'Report data not available at this time' });
        }
    }

    getCurrencySymbol() {
        if (this.shopConfiguration.currency) {
            var currency = this.shopConfiguration.currency;
            if (currency.toUpperCase() == 'INR') {
                return 'Rs.';
            } else {
                var currency_symbol = this.shopConfiguration.currency_symbol;
                var symbol = currency_symbol.toLowerCase();
                if (symbol.charAt(0) === 'u') {
                    symbol = symbol.split('u')[1];
                    symbol = String.fromCharCode(parseInt(symbol, 16));
                }
                return symbol;
            }
        } else {
            return "";
        }
    }
}
