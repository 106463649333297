import { BrowserModule } from '@angular/platform-browser';
import { NgModule, TemplateRef } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from './pages.routing';
import { APIHelper } from './helpers/api-helper';
import { AutoCompleteModule } from 'primeng/autocomplete';

//Report
import { PagesComponent } from './pages.component';
import { SalesComponent } from './reports/sales/sales.component';
import { SectionwiseDetailReportComponent } from './reports/sectionwise-item-detail-report/sectionwise-item-detail-report.component';
import { ItemCancellationReportComponent } from './reports/item-cancellation-report/item-cancellation-report.component';

// Additional Components
import { InputSelectObjectComponent } from '../additional-components/input-select-object/input-select-object.component';
import { InputCheckboxSelectComponent } from '../additional-components/input-checkbox-select/input-checkbox-select.component';
import { InputCheckboxComponent } from '../additional-components/input-checkbox/input-checkbox.component';
import { AttributesFilterComponent } from '../additional-components/attributes-filter/attributes-filter.component';
import { InputUploadComponent } from '../additional-components/input-upload/input-upload.component';

//settings
import { SettingsComponent } from './settings/settings.component';
import { PrinterSettingsComponent } from './settings/printer-settings/printer-settings.component';
import { OthersSettingsComponent } from './settings/other-settings/other-settings.component';
import { OnlinePartnerSettingsComponent } from './settings/online-partner-settings/online-partner-settings.component';


import { DemoModalServiceStaticComponent } from './demo-modal-service-static/demo-modal-service-static.component';
import { ConfigsBoxComponent } from './configs-box/configs-box.component';
import { SelectsBoxComponent } from './selects-box/selects-box.component';
import { ReportsComponent } from './reports/reports.component';
import { OptionsBoxComponent } from './options-box/options-box.component';
import { MapComponent } from './map/map.component';
//import { LoginFormComponent } from '../login-form/login-form.component';

import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { PaymentComponent } from './payment-component/payment.component';
import { FoodOrderingComponent } from './food-ordering/food-ordering.component';
import { PlaceSelectionComponent } from './place-selection/place-selection.component';
import { BasketComponent } from './basket/basket.component';
import { MenuComponent } from './menu/menu.component';
import { AssignStaffModalComponent } from './assign-staff-modal/assign-staff-modal.component';
import { BillNumberModalComponent } from './bill-number-modal/bill-number-modal.component';
import { OrderNoteModalComponent } from './order-note-modal/order-note-modal.component';
import { CloseShopModal } from './close-shop-modal/close-shop-modal.component';
import { EmployerModal } from './employer-modal/employer-modal.component';
import { ThirdPartyDeliveryTimeModalComponent } from './third-party-delivery-time-modal/third-party-delivery-time-modal.component';
import { ThirdPartyRejectOrderReasonModalComponent } from './third-party-reject-order-reason-modal/third-party-reject-order-reason-modal.component';
import { ThirdPartyRiderInfoModalComponent } from './third-party-rider-info-modal/third-party-rider-info-modal.component';
import { ThirdPartyDeliveryPartnerModalComponent } from './third-party-delviery-partner-modal/third-party-delivery-partner-modal.component';
import { ThirdPartyRiderTemperatureModalComponent } from './third-party-rider-temperature-modal/third-party-rider-temperature-modal.component';



import { TableTransferModalComponent } from './table-transfer-modal/table-transfer-modal.component';
import { CoversModalComponent } from './covers-modal/covers-modal.component';
import { LooseItemSelectionModalComponent } from './loose-item-selection-modal/loose-item-selection-modal.component';
import { LicenseRenewModalComponent } from './license-renew-modal/license-renew-modal.component';
import { SaveDiaryModalComponent } from './save-diary-modal/save-diary-modal.component';
import { EnterDiaryModalComponent } from './enter-diary-modal/enter-diary-modal.component';
import { DiaryDetailModalComponent } from './diary-detail-modal/diary-detail-modal.component';
import { OpenCashRegisterModalComponent } from './open-cash-register-modal/open-cash-register-modal.component';
import { StateGuardService } from './state-guard.service';
import { DraggableDishDirective } from './draggable-dish.directive';
import { DragService } from './drag-service.service';
import { DropDishDirective } from './drop-dish.directive';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { KeyboardComponent } from './keyboard/keyboard.component';
import { StopPropagationDirective } from './stop-propagation.directive';
import { InventoryModalComponent } from './inventory-modal/inventory-modal.component';
import { SpecialOrderModalComponent } from './special-order-modal/special-order-modal.component';
import { GiftCardModalComponent } from './gift-card-modal/gift-card-modal.component';
import { VerifyPinModalComponent } from './verify-pin-modal/verify-pin-modal.component';
import { MembershipOptionModalComponent } from './membership-option-modal/membership-option-modal.component';
import { OTModalComponent } from './otmodal/otmodal.component';
import { SelectSeatModalComponent } from './select-seat-modal/select-seat-modal.component';

import { DishDetailsComponent } from './dish-details/dish-details.component';
import { ExtraChargeDetailsComponent } from './extra-charge-details/extra-charge-details.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { Messenger } from './messenger.service';
import { CustomerMessage } from './customer-message.service';
import { RewardPointMessage } from './reward-points.message.service';
import { PlacesMessage } from './places-message.service';

import { CurrentMenuItemsMessage } from './menu-current-items-message.service';
import { UpdateInventoryMessage } from './update-inventory-message.service';

import { ParkModalComponent } from './park-modal/park-modal.component';
import { HoldOrderModal } from './hold-order-modal/hold-order-modal.component';
import { CancelKotItemReasonModal } from './kot-item-cancel-reason-modal/kot-item-cancel-reason-modal.component';
import { KotDetailModalComponent } from './kot-detail-modal/kot-detail-modal.component';

import { ExceptionModal } from './exception-modal/exception.component';
import { ConfirmationModal } from './confirmation-modal/confirmation-modal.component';
import { OffersModalComponent } from './offers-modal/offers-modal.component';
import { ScreenKeysDirective } from './screen-keys.directive';
import { ScreenKeysComponent } from './screen-keys/screen-keys.component';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { DiscountsModalComponent } from './discounts-modal/discounts-modal.component';
import { CancelOrderReasonModalComponent } from './cancel-order-modal/cancel-order-modal.component';
import { CancelOrderConfirmationModalComponent } from './cancel-order-confirmation-modal/cancel-order-confirmation-modal.component';
import { FocusDirective } from './focus.directive';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToggleComponent } from './settings/toggle/toggle.component';
import { InputComponent } from './settings/input/input.component';
import { InputSelectComponent } from './settings/input-select/input-select.component';
import { InputQuantityComponent } from './settings/input-quantity/input-quantity.component';
import { CashRegisterComponent } from './cash-register/cash-register.component';
import { CashRegisterClosureSummaryComponent } from './cash-register/cash-register-closure-summary/cash-register-closure-summary.component';
import { RegisterComponent } from './register/register.component';
import { OpenCloseComponent } from './cash-register/open-close/open-close.component';
import { CashComponent } from './cash-register/cash/cash.component';
import { AddCashComponent } from './cash-register/cash/add-cash/add-cash.component';
import { VariantsModalComponent } from './variants-modal/variants-modal.component';
import { MultiGroupComboModalComponent } from './multi-group-combo-modal/multi-group-combo-modal.component';
import { BatchSerialItemSelectionModalModalComponent } from './batch-serial-item-selection-modal/batch-serial-item-selection-modal.component';
import { RecentOrdersComponent } from './recent-orders/recent-orders.component';
import { RecentOrderComponent } from './recent-orders/recent-order/recent-order.component';
import { OrderInvoiceComponent } from './recent-orders/order-invoice/order-invoice.component';
import { OrdersComponent } from './orders/orders.component';
import { HttpModule } from '@angular/http';
import { NameSearchPipe } from './pipes/name-search.pipe';
import { BacktipsDatePipe } from './pipes/backtips-date-pipe';
import { NumberToWordsPipe } from './pipes/number-to-words.pipe';
import { SelectObjectFilterPipe } from './pipes/object-filter.pipe';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { CustomNumberPipe } from './pipes/custom-number.pipe';
import { DatePipe } from '@angular/common';
import { CalendarComponent } from './calendar/calendar.component'
import { PersonalPinComponent } from './personal-pin/personal-pin.component';
import { AppStrings } from './helpers/app-strings';
import { environment } from '../../environments/environment';
import { KotHelper } from './helpers/kot-helper';
import { CaptainAppHelper } from './helpers/captain-app-helper';
import { DiscountHelper } from './helpers/discount-helper';
import { PrintHelper } from './helpers/print-helper';
import { ShopUserService } from './helpers/services/shop-user.service';
import { RazorpayComponent } from './helpers/razorpay.component';
import { FirebaseMessagingService } from './helpers/services/firebase-messaging.service';
import { CaptainAppService } from './helpers/services/captain-app.service';
import { MerchantProfileService } from './helpers/services/merchant-profile.service';
import { ShopConfigurationService } from './helpers/services/shop-configuration.service';
import { RecentOrdersService } from './helpers/services/recent-orders.service';
import { ThirdPartyOrderService } from './helpers/services/third-party-orders.service';
import { SaveOrderService } from './helpers/services/save-order.service';
import { MakePaymentService } from './helpers/services/make-payment.service';
import { DiaryService } from './helpers/services/diary.service';
import { ShopCategoriesItemsService } from './helpers/services/shop-categories-items.service';
import { ShopCategoriesService } from './helpers/services/shop-categories.service';
import { CommonService } from './helpers/services/common.service';
import { CashRegisterService } from './helpers/services/cash-register.service';
import { CustomerService } from './helpers/services/customer.service';
import { SpecialOrderService } from './helpers/services/special-order.service';
import { NgxLoadingModule  } from 'ngx-loading';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AgmDirectionModule } from 'agm-direction';
import { ShopLoginService } from './helpers/shopLogin.service';
import { MembershipService } from './helpers/services/membership.service';
import { SalesPrintChecksModalComponent } from './reports/sales/sales-print-checks-modal/sales-print-checks-modal.component';
import { ThirdPartyDeliveryOrTakeawayStatusModalComponent } from './third-party-delivery-or-takeaway-status-modal/third-party-delivery-or-takeaway-status-modal.component';

/*import { RecommenderService } from './helpers/services/recommender.service';*/


@NgModule({
  declarations: [
    PagesComponent,
    SalesComponent,
    AutocompleteComponent,
    FoodOrderingComponent,
    BasketComponent,
    PrinterSettingsComponent,
    OthersSettingsComponent,
    OnlinePartnerSettingsComponent,
    SectionwiseDetailReportComponent,
    ItemCancellationReportComponent,
    DemoModalServiceStaticComponent,
    SelectsBoxComponent,
    ReportsComponent,
    OptionsBoxComponent,
    // LoginFormComponent,
    MapComponent,
    PaymentComponent,
    PlaceSelectionComponent,
    AssignStaffModalComponent,
    BillNumberModalComponent,
    OrderNoteModalComponent,
    CloseShopModal,
    EmployerModal,
    ThirdPartyDeliveryTimeModalComponent,
    ThirdPartyRejectOrderReasonModalComponent,
    ThirdPartyRiderInfoModalComponent,
    ThirdPartyDeliveryPartnerModalComponent,
    ThirdPartyRiderTemperatureModalComponent,
    TableTransferModalComponent,
    LicenseRenewModalComponent,
    SaveDiaryModalComponent,
    EnterDiaryModalComponent,
    DiaryDetailModalComponent,
    OpenCashRegisterModalComponent,
    DraggableDishDirective,
    DropDishDirective,
    KeyboardComponent,
    StopPropagationDirective,
    InventoryModalComponent,
    SpecialOrderModalComponent,
    GiftCardModalComponent,
    VerifyPinModalComponent,
    MembershipOptionModalComponent,
    OTModalComponent,
    SelectSeatModalComponent,
    DishDetailsComponent,
    ExtraChargeDetailsComponent,
    ParkModalComponent,
    HoldOrderModal,
    CancelKotItemReasonModal,
    KotDetailModalComponent,
    ExceptionModal,
    ConfirmationModal,
    OffersModalComponent,
    ScreenKeysDirective,
    ScreenKeysComponent,
    DiscountsModalComponent,
    CancelOrderReasonModalComponent,
    CancelOrderConfirmationModalComponent,
    FocusDirective,
    SidebarComponent,
    SettingsComponent,
    ToggleComponent,
    InputComponent,
    InputSelectObjectComponent,
    InputCheckboxSelectComponent,
    InputCheckboxComponent,
    AttributesFilterComponent,
    InputUploadComponent,
    InputSelectComponent,
    InputQuantityComponent,
    CashRegisterComponent,
    CashRegisterClosureSummaryComponent,
    RegisterComponent,
    OpenCloseComponent,
    CashComponent,
    AddCashComponent,
    KotHelper,
    CaptainAppHelper,
    DiscountHelper,
    PrintHelper,
    VariantsModalComponent,
    MultiGroupComboModalComponent,
    BatchSerialItemSelectionModalModalComponent,
    RecentOrdersComponent,
    RecentOrderComponent,
    OrdersComponent,
    OrderInvoiceComponent,
    NameSearchPipe,
    BacktipsDatePipe,
    NumberToWordsPipe,
    CapitalizeFirstPipe,
    CustomCurrencyPipe,
    CustomNumberPipe,
    SelectObjectFilterPipe,
    CalendarComponent,
    PersonalPinComponent,
    RazorpayComponent,
    SalesPrintChecksModalComponent,
    CoversModalComponent,
    LooseItemSelectionModalComponent,
    CustomerInfoComponent,
    DiscountsModalComponent,    
    ThirdPartyDeliveryOrTakeawayStatusModalComponent,
    ConfigsBoxComponent,
    MenuComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    AngularMultiSelectModule,
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: APIHelper.GKEY
    }),
    AgmDirectionModule,
    routing,
    TabsModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    MessagesModule,
    MessageModule,
    ToastModule
  ],
  entryComponents: [
    AssignStaffModalComponent,
    BillNumberModalComponent,
    OrderNoteModalComponent,
    CloseShopModal,
    EmployerModal,
    ThirdPartyDeliveryTimeModalComponent,
    ThirdPartyRejectOrderReasonModalComponent,
    ThirdPartyRiderInfoModalComponent,
    ThirdPartyDeliveryPartnerModalComponent,
    ThirdPartyRiderTemperatureModalComponent,
    TableTransferModalComponent,
    CoversModalComponent,
    LooseItemSelectionModalComponent,
    LicenseRenewModalComponent,
    SaveDiaryModalComponent,
    EnterDiaryModalComponent,
    DiaryDetailModalComponent,
    OpenCashRegisterModalComponent,
    InventoryModalComponent,
    SpecialOrderModalComponent,
    GiftCardModalComponent,
    VerifyPinModalComponent,
    MembershipOptionModalComponent,
    OTModalComponent,
    SelectSeatModalComponent,
    DishDetailsComponent,
    ExtraChargeDetailsComponent,
    AutocompleteComponent,
    ParkModalComponent,
    HoldOrderModal,
    CancelKotItemReasonModal,
    KotDetailModalComponent,
    ExceptionModal,
    ConfirmationModal,
    OffersModalComponent,
    CustomerInfoComponent,
    CancelOrderReasonModalComponent,
    CancelOrderConfirmationModalComponent,
    DiscountsModalComponent,
    SalesPrintChecksModalComponent,
    ThirdPartyDeliveryOrTakeawayStatusModalComponent
  ],
  providers: [
    StateGuardService,
    DragService,
    Messenger,
    CustomerMessage,
    RewardPointMessage,
    PlacesMessage,
    CurrentMenuItemsMessage,
    UpdateInventoryMessage,
    NameSearchPipe,
    BacktipsDatePipe,
    DatePipe,
    NumberToWordsPipe,
    CapitalizeFirstPipe,
    CustomCurrencyPipe,
    CustomNumberPipe,
    SelectObjectFilterPipe,
    AppStrings,
    ShopUserService,
    FirebaseMessagingService,
    CaptainAppService,
    MerchantProfileService,
    ShopConfigurationService,
    RecentOrdersService,
    ThirdPartyOrderService,
    SaveOrderService,
    MakePaymentService,
    SelectsBoxComponent,
    DiaryService,
    ShopLoginService,
    ShopCategoriesItemsService,
    ShopCategoriesService,
    CustomerService,
    SpecialOrderService,
    KotHelper,
    CaptainAppHelper,
    DiscountHelper,
    PrintHelper,
    CommonService,
    MessageService,
    CashRegisterService,
    MembershipService,
    DemoModalServiceStaticComponent,
    MenuComponent,
    BasketComponent,
    ConfigsBoxComponent,
    PaymentComponent,
    ScreenKeysComponent,
    OptionsBoxComponent,
    PagesComponent,
    /*RecommenderService*/],
    bootstrap: [PagesComponent]
})
export class PagesModule { }
