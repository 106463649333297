import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-third-party-rider-temperature-modal',
  templateUrl: './third-party-rider-temperature-modal.component.html',
  styleUrls: ['./third-party-rider-temperature-modal.component.less']
})
export class ThirdPartyRiderTemperatureModalComponent implements OnInit {
  decision: string;
  temperature = 0;
  rider_phone_number = "";
  constructor(public modalRef: BsModalRef,public messageService:MessageService) { }
  
  ngOnInit() {
  } 
  
  done(){    
    if(this.temperature <= 0) {
      this.messageService.add({ severity: 'error', summary: "please add valid rider temperature", detail: '' });
      return;
    }

    if(!this.rider_phone_number) {
      this.messageService.add({ severity: 'error', summary: "please add valid rider phone number", detail: '' });
      return; 
    }
    
    this.decision='done';
    this.modalRef.hide()
  }

}
