import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StateGuardService } from '../state-guard.service';
import { Md5 } from 'ts-md5/dist/md5';
import { MessageService } from 'primeng/api';
import { ShopConfigurationService } from '../helpers/services/shop-configuration.service';
import { CommonService } from '../helpers/services/common.service';
import { SeparateBillHelper } from '../helpers/Separate-bill-helper';
import { CustomerService } from '../helpers/services/customer.service';
import { APIHelper } from '../helpers/api-helper';
import { ConfigsBoxComponent } from '../configs-box/configs-box.component';


@Component({
  selector: 'app-discounts-modal',
  templateUrl: './discounts-modal.component.html',
  styleUrls: ['./discounts-modal.component.less']
})
export class DiscountsModalComponent implements OnInit {
  decision: string;
  constructor(public modalRef: BsModalRef, public stateGuard: StateGuardService,
          public messageService:MessageService,public shopConfigurationService: ShopConfigurationService,public commonService: CommonService, private customerService:CustomerService, private configsBoxComponent:ConfigsBoxComponent) { }
  isPercentsDiscount: boolean = true;
  isManager: boolean = false;
  isLoadingShow: boolean = false;
  currency: string = "";
  enable_separate_bill_for_tax_classes: boolean = false;
  is_item_wise_tax_on: boolean = false;
  selected_discount:any;
  discount_order_id:any;
  discount_reason: string = "";
  discount_type : string = '';
  discountList:any = [];
  discount:any;
  pinNum:any;

  otp_verification:boolean = false;
  show_otp:boolean = false;
  otp_verified:boolean = false;
  mobile_no:string = "";
  user_otp:string = "";
  resendInterval: any;
  timer:string = "";
  otp:number;

  // mobile vaiables
  latitude:any;
  longitude:any;
  isChangeGeometryData:boolean= false;
  selectedState: any = null;

  defaultVisitor = {
    auth_token : APIHelper.DEFAULT_TOKEN,
    consumer_name: "",          
    lastVisit: "",
    lastCheck: 0,
    lastCheckCurrency: "INR",
    email: "",
    mobile_no: "",
    points: 0,
    diary_amt:0,
    place: "here",
    distance: '',
    places : [
      {id: 1, name: 'here'},
      {id: 2, name: 'home'},
      {id: 3, name: 'pickup'}
    ]
  }
  visitorArtefacts:any= JSON.parse(JSON.stringify(this.defaultVisitor));
  customerInfo = {
    consumer_name: "",
    email: "",
    mobile_no: null,
    local_user_id: null,
    zip_code: null,
    companyName: "",
    anniversary: null,
    child_birdthday: null,
    address_1: "",
    address_2: "",
    last_purchase:0,
    last_visited:new Date(),
    rewards_points:0,
    birdthday: null,
    spouse_birdthday: null,
    selectedPartners:[],
    selectedEmployer:null,
    selectedStaffUser:null,
    selectedState:null,
    delivery_date:new Date(),
    delivery_time:null,
    city: "",
    state: "",
    GSTIN:"",
    consumer_groups:null,
    credit_limit:null,
    lat:this.latitude,
    lng:this.longitude,
    isChangeGeometryData:this.isChangeGeometryData
  }

  ngOnInit() {
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.customerInfo = JSON.parse(localStorage.getItem('current_customer_info'));

    this.otp_verification = shop_config.enable_otp_verification;
    this.currency = shop_config.currency;
    this.enable_separate_bill_for_tax_classes = shop_config.enable_separate_bill_for_tax_classes;
    this.is_item_wise_tax_on = shop_config.item_wise_tax;
    let loggedInUserData = JSON.parse(localStorage.getItem('loggedInUser')) || {};

    if(this.customerInfo.mobile_no){
      this.mobile_no = this.customerInfo.mobile_no;
      // console.log("this.mobile_no",this.mobile_no)
      // this.otp_verified = true;
    }
    
    if(loggedInUserData.role == 'manager' || loggedInUserData.role == 'owner'){
      this.isManager = true;
    }else{
      this.isManager = false;
    }

    this.setFocus();
  }

  keyPress(event: any) {
   const pattern = /[0-9]/;

   let inputChar = String.fromCharCode(event.charCode);
   if (event.keyCode != 8 && event.keyCode != 46  && !pattern.test(inputChar)) {
     event.preventDefault();
   }
  }

  setFocus(){
    setTimeout(()=>{
      /*document.querySelector("#discounttext")["focus"]();*/
    },100);
  }

  onChangeDiscountTypeSelection(e:any){
    this.discount_type = e;
    let all_discount_list = JSON.parse(localStorage.getItem('discount_list')) || [];
    this.discountList = all_discount_list.filter(it => {
      return (it.discount_specifics && !it.discount_specifics.is_auto_apply && it.is_deleted == 0 && it.discount_specifics.type == this.discount_type)
    });
  }

  onChangeDiscountSelection(e:any){
    if(e == 'undefined' || e === undefined){
      this.selected_discount = null;
    }
    this.discountList.forEach(it => {
      if(it.id == e){
        this.selected_discount = it;
      }
    });
  }

  validateDiscount(){

    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
    this.isLoadingShow = true;
    let param = {
      discount_code : this.selected_discount.discount_code,
      shop_code : localStorage.getItem('shop_code'),
      user_auth_token : tempUnit.token,
      cart_items : tempUnit.cart_items,
      sub_total : tempUnit.subtotal,
      shipping_type : tempUnit.delivery_type,
      discount_order_id :this.discount_order_id
    }


    // 1: product condition not matched,
    // 2: maximum minimum cart value not found,
    // 3: user not avail for this offer,
    // 4: user use coupon maximum time,
    // 5: user use coupon maximum value,
    // 6: reason not found,
    // 7: discount not found,
    // 8: expired,
    // 9: usage time not match
    // 10: required item not found
    // 11: shipping type not valid for discount
    // 12: either minimum item not purchased or purchased item count is more then maximum item purchase
    // 13: condition not match with buy and get items
    // 14: membership not purchased by user or membership not available
    // 15: last order value not matched with discount minimum value
    // 16: discount order id already used in other order to get discount

    this.shopConfigurationService.validateDiscount(param).subscribe((res) => {
      var result = res.json();
      this.isLoadingShow = false;
      if (result.status == 0 && result.result.data && result.result.data.status_code == 0) {
        this.applyDiscountCode(result.result.data.max_discount);
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 1){
        this.messageService.add({ severity: 'error', summary: "product condition not matched", detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 2){
        this.messageService.add({ severity: 'error', summary: "maximum/minimum cart value not matched", detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 3){
        this.messageService.add({ severity: 'error', summary: "user not avail for this offer", detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 4){
        this.messageService.add({ severity: 'error', summary: 'user use this coupon maximum time', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 5){
        this.messageService.add({ severity: 'error', summary: 'user use coupon maximum value', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 6){
        this.messageService.add({ severity: 'error', summary: 'Discount is not applied due to some reason', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 8){
        this.messageService.add({ severity: 'error', summary: 'discount expired', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 9){
        this.messageService.add({ severity: 'error', summary: 'usage time not match', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 10){
        this.messageService.add({ severity: 'error', summary: 'required item not found', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 11){
        this.messageService.add({ severity: 'error', summary: 'shipping type not valid for discount', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 12){
        this.messageService.add({ severity: 'error', summary: 'either minimum item not purchased or purchased item count is more then maximum item purchase', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 13){
        this.messageService.add({ severity: 'error', summary: 'condition not match with buy and get items', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 14){
        this.messageService.add({ severity: 'error', summary: 'either membership not purchased or expired', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 15){
        this.messageService.add({ severity: 'error', summary: 'last order value not matched with discount minimum value', detail: '' });
      }else if(result.status == 0 && result.result.data && result.result.data.status_code == 16){
        this.messageService.add({ severity: 'error', summary: 'discount order id already used in other order to get discount', detail: '' });
      }else if(result.result.data && typeof result.result.data == "string"){
        this.messageService.add({ severity: 'error', summary: result.result.data, detail: '' });
      }else{
        this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: '' });
      }
    },err =>{
        this.isLoadingShow = false;
        this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: '' });
    });
  }

  applyDiscountCode(max_discount){

    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
    if(!tempUnit.external_discount_list){
      tempUnit.external_discount_list = [];
    }

    if(this.selected_discount){
      var fData = tempUnit.external_discount_list.filter( i => i.id == this.selected_discount.id);
      if(fData.length>0){
        this.messageService.add({ severity: 'error', summary: 'Already applied this discount', detail: '' });
      }else{
        this.selected_discount["max_discount"] = max_discount;
        this.selected_discount["add_time"] = new Date().getTime();
        this.selected_discount["discount_order_id"] = (this.selected_discount.id="discount_on_order" && this.discount_order_id) ? JSON.parse(JSON.stringify(this.discount_order_id)) : "";
        tempUnit.temp_external_discount_list.push(this.selected_discount);
        this.handleCustomerInfo();
        this.decision = 'applied_discount';
        this.modalRef.hide();
      }
    }
  }

  done(){

    if(['manual','cash_discount'].indexOf(this.discount_type) != -1){

      if(!this.discount){
        this.messageService.add({ severity: 'error', summary: 'Enter discount value', detail: '' });
        return;
      }
      //check for vat gst items.
      if(!this.isPercentsDiscount && this.enable_separate_bill_for_tax_classes) {
          let temp_cart_items = [];
          let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit") || {};
          if(tempUnit.visitors && tempUnit.visitors.length>0){
            tempUnit.visitors.forEach(visitor => {
                if(Array.isArray(visitor.dishes)) {
                  visitor.dishes.forEach(dish => {
                    if(Array.isArray(dish)) {
                      dish.forEach(item => {
                        temp_cart_items.push(item);
                      });
                    }
                  });
                }
            })
          }
          console.log(temp_cart_items)
          if(SeparateBillHelper.cheackIsDifferentTaxClassesItemAvailable(temp_cart_items)) {
            this.messageService.add({ severity: 'error', summary: "Can't add fixed value discount if vat and gst both taxes item available.", detail: '' });
            return;
          }
      }

      if(!this.pinNum && !this.isManager){
        this.messageService.add({ severity: 'error', summary: 'Enter your pin number', detail: '' });
        return;
      }
      var userList = JSON.parse(localStorage.getItem('shop_user_list')) || [];
      var managerList = userList.filter((i: any) => (i.role=='manager' || i.role=='owner'));
      var validUser = [];
      if(this.isManager){
        this.handleCustomerInfo();
        this.decision= (this.discount_type == "cash_discount") ? 'cash_discount' : 'done';
        this.modalRef.hide();
      }else{
        if(managerList.length>0 ){
          validUser = managerList.filter((i: any) => i.pin === Md5.hashStr(this.pinNum.toString()));
          if(validUser.length > 0){
              this.handleCustomerInfo();
              this.decision=(this.discount_type == "cash_discount") ? 'cash_discount' : 'done';
              this.modalRef.hide();
          }else{
            this.messageService.add({ severity: 'error', summary: 'Entered wrong pin', detail: '' });
          }
        }else{
           this.messageService.add({ severity: 'error', summary: 'No manager found in shop user list', detail: '' });
        }
      }
    }else{
      if(!this.selected_discount || this.selected_discount === undefined || this.selected_discount == 'undefined'){
        this.messageService.add({ severity: 'error', summary: 'Select any discount', detail: '' });
        return;
      }
      this.validateDiscount();
    }
  }

  verifyOTP(){
    if(this.user_otp && this.otp){
      if(this.otp.toString() == this.user_otp){
        this.otp_verified = true;
        // this.show_otp = false;
        this.clearInterval();
        this.messageService.add({ severity: 'success', summary: 'Mobile Verified', detail: '' });
      }else{
        this.messageService.add({ severity: 'error', summary: 'Invalid OTP', detail: '' });
      }
    }
  }
  
  sentOTP(){
    if(this.mobile_no && this.mobile_no.toString().trim().length>=10){
      let param = {
        mobile_no: this.mobile_no,
      };
  
      this.isLoadingShow = true;
      this.commonService.verifyMobile(param).subscribe((res) => {
        var result = res.json();
        if(result.status == 0 && result.result.verification_code){
            this.otp = result.result.verification_code;
            this.show_otp = true;
            this.messageService.add({ severity: 'success', summary: 'OTP sent on Mobile', detail: '' });
        }else{
            this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: '' });
        }
        this.isLoadingShow = false;
      },err =>{
          this.isLoadingShow = false;
          this.messageService.add({ severity: 'error', summary: 'Something went wrong', detail: '' });
      });
    }
  }

  startResendTimer(minutes:number = 2, seconds:number = 0) {
    this.clearInterval();
    this.resendInterval = setInterval(() => {
      if (seconds > 0) {
        seconds--;
        this.timer = minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
      } else if (minutes >= 1) {
        minutes--;
        seconds = 59;
        this.timer = minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);
      } else {
        this.clearInterval();
      }
    }, 1000);
  }

  clearInterval() {
    if (this.resendInterval) {
      clearInterval(this.resendInterval);
      this.resendInterval = undefined;
      this.otp = undefined;
    }
  }

  onChangeMobileNo(e:any){
    if(navigator.onLine && e && e.trim().length>=10){
      let sendData = {
        'mobile_no': ''+e,
        'local_user_id': '',
        'shop_code':localStorage.getItem('shop_code')
      }
      this.customerService.contact_search(sendData).subscribe((res) => {
          let userData = res.json();
          if(userData.result && userData.result.details && userData.result.details.length>0){

            let auth_token = userData.result.details[0].auth_token;
            let local_user_id = userData.result.details[0].local_user_id;

            this.customerService.info(auth_token).subscribe((response) => {
              let info = response.json();
              if(info.result){
                this.customerInfo.consumer_name = info.result.consumer_name;
                this.customerInfo.local_user_id = info.result.local_user_id;
                this.customerInfo.email = info.result.email;
                this.customerInfo.zip_code = info.result.zip_code;
                this.customerInfo.address_1 = info.result.address;
                this.customerInfo.city = info.result.city;
                this.customerInfo.state = info.result.state;
                this.customerInfo.last_purchase = info.result.last_purchase;
                this.customerInfo.last_visited = info.result.last_visited;
                this.customerInfo.rewards_points = info.result.rewards_points;
                this.customerInfo.consumer_groups = info.result.consumer_groups;
                this.customerInfo.credit_limit = info.result.credit_limit;
                this.customerInfo.mobile_no = info.result.phone;
              
                // this.setState();
                // this.setConsumerGroups();
              }
            });
          }else{
            this.customerInfo.consumer_name = "";
            this.customerInfo.local_user_id = "";
            this.customerInfo.email = "";
            this.customerInfo.zip_code = "";
            this.customerInfo.address_1 = "";
            this.customerInfo.city = "";
            this.customerInfo.state = "";
            this.customerInfo.credit_limit = null;
            this.customerInfo.last_purchase = 0;
            this.customerInfo.last_visited = new Date();
            this.customerInfo.rewards_points = 0;
            this.customerInfo.GSTIN = "";
            this.customerInfo.companyName = "";
            this.customerInfo.mobile_no = e;
            // this.setState();
            // this.setConsumerGroups();
          }

      });
    }
  }

  handleCustomerInfo() {
    this.configsBoxComponent.submitCustomerInfo(false,this.customerInfo,null,null);
  }
}
