/**
 * (c) OneGreenDiary Software Pvt. Ltd.
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform,
 *    solely reside with the third-party.  
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Once OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without
 *    prior agreement with OneGreenDiary Software Pvt. Ltd.
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';



import { APIHelper } from '../api-helper';

@Injectable()
export class MakePaymentService {
    constructor(private http: Http) { }

    payment(order_id:string, shop_code:string, subTotalAmount:number, totalAmount:number, 
            paymentType:string, splitPayment:any,splitPaymentDetail:any,
            serviceTax:number=0, serviceCharge:number=0, deliveryCharge:number=0,
            discountAmount:number, rewardsAmount:number=0, cardAmount:number=0,
             diaryAmount:number=0,advanceAmount:number=0,outstandingAmount:number=0,rounding_value:number,shop_user_id:number,rewards_pt:number,
             give_rewards:boolean,discount_reason:string,discount_phone_no:any,total_other_discount_amount:number=0,cash_discount_amount:number=0) {

        if (paymentType === APIHelper.CARD_PAYMENT_TYPE) { 
            cardAmount = totalAmount;
        }


        var reqData = {
            'card_amount': cardAmount,  // capture card amount
            'diary_amount': diaryAmount, // capture diary amount 
            'outstanding':outstandingAmount,
            'advanced':advanceAmount,
            'rewards_amount': rewardsAmount, // capture redeemed rewards
            'discount_amount': discountAmount,
            'total_other_discount_amount': total_other_discount_amount,
            'cash_discount_amount': cash_discount_amount,
            'discount_reason': discount_reason,
            'discount_phone_no': discount_phone_no,
            'paid_amount': totalAmount,
            'valued_at': totalAmount,
            'order_id': order_id,
            'rounding_value':rounding_value,
            'sub_total': subTotalAmount,
            'service_charge': serviceCharge, // compute service charge
            'payment_type':paymentType,//payment type
            'service_tax': serviceTax, // compute service tax
            'delivery_charge': deliveryCharge, // get delivery charge
            'redeem_amount': 0, // capture redeem amount 
            'offer_amount': 0, // any offer amount (from coupon)
            'offer_code': '', // the offer code used for above offer amount
            'split_payment': splitPayment, 
            'split_payment_details': splitPaymentDetail, 
            'shop_code': shop_code,
            'shop_user_id':shop_user_id,
            'rewards_pt':rewards_pt,
            'give_rewards':give_rewards,
        };
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_MAKE_PAYMENT, reqData);
    }


    cancelSelectedOrder(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_FOR_CANCEL_SELECTED_ORDER,data);
    }

    getSelectedOrderInfo(data:any){
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_SELECTED_ORDER_INFO,data);
    }
}
