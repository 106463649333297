/// <reference types="@types/googlemaps" />
import { Component,ElementRef, NgZone, OnInit, ViewChild , Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd  } from '@angular/router';
import { APIHelper } from '../helpers/api-helper';
import { CustomerService } from '../helpers/services/customer.service';
import { PagesComponent } from '../pages.component';
import { StateGuardService } from '../state-guard.service';
import { CustomerMessage } from '../customer-message.service';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import {BaseModalComponent} from '../helpers/base-modal.component';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { CommonService } from '../helpers/services/common.service';
// import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { MembershipService } from '../helpers/services/membership.service';
import { EmployerModal } from '../employer-modal/employer-modal.component';
import { findLast } from 'lodash-es';

declare var $:any;

// declare var google: any;

@Component({
  selector: 'app-configs-box',
  templateUrl: './configs-box.component.html',
  styleUrls: ['./configs-box.component.css'],
  providers:[CommonService]
})
export class ConfigsBoxComponent  extends BaseModalComponent  implements OnInit {
  
  shop_configuration:any= JSON.parse(localStorage.getItem("shop_configuration")) || null;

  isDateTimeShow:boolean=false;
  isChangeGeometryData:boolean=false;
  isItFoodOrderingPage:boolean=false;
  isLoadingShow:boolean=false;
  isPartnerListShow:boolean=false;
  isAddressRequired:boolean=true;
  isMobileNoRequired:boolean=true;
  isRestaurantFlag:boolean=true;

  latitude:any;
  longitude:any;
  msgs: Message[] = [];
  settings:any={position:"bottom"};
  partnerList:any=[];
  selectedPartners:any=[];
  employerList: any = [];
  selectedEmployer: any = null;
  staffList: any = [];
  selectedStaffUser:any = null;
  gstStateList: any = [];  
  selectedState: any = null;
  subscription: Subscription;
  defaultVisitor = {
    auth_token : APIHelper.DEFAULT_TOKEN,
    consumer_name: "",          
    lastVisit: "",
    lastCheck: 0,
    lastCheckCurrency: "INR",
    email: "",
    mobile_no: "",
    local_user_id: "",
    points: 0,
    place: "",
    distance: '',
    places : [
      {id: 1, name: 'Here'},
      {id: 2, name: 'Home Delivery'},
      {id: 3, name: 'Pickup'}
    ]
  }

  @ViewChild("search",{static: false})
  public searchElementRef: ElementRef;

  customer_info:any = JSON.parse(JSON.stringify(this.defaultVisitor));
  @Output() deliveryType: EventEmitter<number> = new EventEmitter();
  @Output() geometryData: EventEmitter<any> = new EventEmitter();
  constructor(private commonService:CommonService,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,
    private messageService: MessageService,private route: ActivatedRoute,private stateGuard: StateGuardService,
    private router: Router, private customerService: CustomerService,private customerMessageService: CustomerMessage,
    private membershipService:MembershipService,public modalRef: BsModalRef,private modalService: BsModalService) {
    super(modalRef);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        let index = event.url.indexOf('food-ordering');
        if(index == -1){
          this.isItFoodOrderingPage =false;
        }else{
          this.isItFoodOrderingPage =true;
        }
      }
    });
  }

  ngOnInit() {    
    let self = this;
    if(!this.shop_configuration){
      this.router.navigate(['verify-pin']);
      return;
    }
    this.settings = {
      text: "Select Partners",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class"
    };

    $(function() {
        $("#phone").keypress(function(event) {
            if (event.which != 8 && event.which != 0 && (event.which < 48 || event.which > 57)) {
                return false;
            }
        });
    });
    
    this.defaultVisitor = self.route.snapshot.params.type;

    if(self.route.snapshot.params.type == 'home' || self.route.snapshot.params.type == 'pickup'){
      this.isPartnerListShow = true;
    }else{
      this.isPartnerListShow = false;
    }
    setTimeout(function(){
      //self.getCurrentCustomerBasicInfo();
      self.customer_info.place = self.route.snapshot.params.type;
      self.changeType(self.customer_info.place);
    },1000);

    if(localStorage.getItem('isRestaurant') == 'false'){
      this.isRestaurantFlag = false
    }
    
    this.getStaffUserList();
    this.getPartnerList();
    this.getEmployersList();
    this.getGSTStateList();
    

    if(navigator.onLine) {
      var csub:any= this.customerMessageService.getCustomerInfo().subscribe(message => {
        if(message.is_partial_change) {
          let tempCI = JSON.parse(JSON.stringify(message.CustomerInfo));
          for(var x in tempCI) { this.customer_info[x] = tempCI[x]; }        
        } else {
          this.customer_info = JSON.parse(JSON.stringify(message.CustomerInfo));
        }
        
        this.setState();
        this.setSelectedPartners();
        this.setSelectedEmployer();
      });
    }

    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();

          this.isChangeGeometryData = true;


          this.geometryData.emit({'lat':this.latitude,'lng':this.longitude});


          let tempData = {};
          for (var i = 0; i < place.address_components.length; i++) {
              var addressType = place.address_components[i].types[0];
              tempData[addressType] = place.address_components[i].long_name;
          }
          this.customer_info.address_1 = place.formatted_address;
          this.customer_info.city = tempData['administrative_area_level_2'];
          this.customer_info.state = tempData['administrative_area_level_1'];
          this.customer_info.country = tempData['country'];
          this.customer_info.zip_code = tempData['postal_code'];          
        });
      });
     }).catch(e =>{
       console.log(e);
     });
  }

  getCurrentCustomerBasicInfo(){
    let sData = {};
    let current_customer_info = JSON.parse(localStorage.getItem('current_customer_info'));
    if(navigator.onLine && current_customer_info && current_customer_info.auth_token){
      this.customerService.info(current_customer_info.auth_token).subscribe((res) => {
        var result = res.json();      
        if (result.status === 0 && current_customer_info.auth_token != APIHelper.DEFAULT_TOKEN) {            
          sData = {
            auth_token:  current_customer_info.auth_token,
            consumer_name: (result.result.consumer_name)? result.result.consumer_name : "",
            email: (result.result.email)? result.result.email : "",
            mobile_no: result.result.phone ? result.result.phone : this.defaultVisitor.mobile_no,
            zip_code: result.result.zip_code ? result.result.zip_code : null,
            address_1: result.result.address ? result.result.address : "",
            city: result.result.city ? result.result.city : "",
            state: result.result.state ? result.result.state : "",
            last_purchase: (result.result.last_purchase)? result.result.last_purchase :0,
            last_visited: (result.result.last_visited)? result.result.last_visited : new Date(),
            rewards_points: (result.result.rewards_points)? result.result.rewards_points:0,
            diary_amt: (result.result.diary_amt)? result.result.diary_amt:0,
            consumer_groups: (result.result.consumer_groups)? result.result.consumer_groups:[],
            credit_limit: (result.result.credit_limit)? result.result.credit_limit:null
          }            
        }
        this.customer_info = Object.assign(this.customer_info,sData);                  
      },error => {
        this.messageService.add({ severity: 'error', summary: (navigator.onLine)? 'You are offline' : "Internal server error", detail: 'Try again.' });
      });
    }    
  }

  getPartnerList(){
    this.partnerList = JSON.parse(localStorage.getItem("partner_list")) || [];    
  }

  getStaffUserList() {
    let tempStaffList:any = [];
    if(localStorage.getItem('shop_user_list')){
      let list = JSON.parse(localStorage.getItem('shop_user_list'));
      if(list.length>0){
        list.forEach(staff=>{
          tempStaffList.push({id:staff.id,name:staff.name});
        });
      }
    }

    this.staffList = tempStaffList;
  }

  getGSTStateList() {
    this.gstStateList = JSON.parse(localStorage.getItem("gst_state_codes")) || [];         
  }

  getZipCodeInformation(zip_code) {
    return new Promise((resolve,reject) => {
      if(!zip_code) {
        return resolve(null);
      } else {
        this.customerService.getDetailByPinCode(zip_code).subscribe((res:any) => {
          res = res.json();
          if(res && res[0] && res[0].PostOffice && res[0].PostOffice[0] && res[0].PostOffice[0].State) {
            let state = res[0].PostOffice[0].State.toUpperCase();
            let city = res[0].PostOffice[0].District ?  res[0].PostOffice[0].District.toUpperCase() : "";
            return resolve({state:state,city:city})
          }
          return resolve(null);
        }, error => {
          return resolve(null);
        });
      }
    });
  }

  onSelectState(event) {    
    if(event && event.state_code) {
      this.selectedState = event;  
      this.customer_info.state = event.name;      
    }
  }

  setState() {
    if(this.customer_info.state) {
      for(var i=0;i<this.gstStateList.length;i++) {
        if(this.customer_info.state.toLowerCase().indexOf(this.gstStateList[i].state.toLowerCase()) != -1 ) {
          this.selectedState = this.gstStateList[i];
          break;
        }
      }
    }
  }

  setSelectedPartners() {
    if(Array.isArray(this.customer_info.selectedPartners)) {
      this.selectedPartners =  this.customer_info.selectedPartners;      
    }
  }

  setSelectedEmployer() {
    if(this.customer_info.selectedEmployer) {
      this.selectedEmployer =  this.customer_info.selectedEmployer;      
    }
  }

  getEmployersList() {
    this.employerList = JSON.parse(localStorage.getItem("employer_list")) || [];         
  }

  onSelectCompany(event) {    
    if(event && event.employer_code) {
      this.selectedEmployer = event;  
      this.customer_info.company_name = this.selectedEmployer.name;
    }
  }

  onSelectStaffUser(event) {
    if(event && event.id) {
      this.selectedStaffUser = event;  
      localStorage.setItem('selected_staff_user', this.selectedStaffUser.name)
      //this.customer_info.staff_user_id = this.selectedStaffUser.id;
    }
  }

  openEmployerModal() {
    var tempEmployer = {employer_name:"",employer_code:"",address:"",city:"",tax_number:"",pin_code:"",state:""};
    if(this.selectedEmployer && this.selectedEmployer.employer_code) {
       tempEmployer = JSON.parse(JSON.stringify(this.selectedEmployer));
    }
    this.modalRef = this.modalService.show(EmployerModal, {class: 'common-modal employer-modal', animated: false,keyboard: false,backdrop: true,ignoreBackdropClick: true});
    this.modalRef.content.decision = "";
    this.modalRef.content.employer = tempEmployer;

    var currSub:Subscription = this.modalRef.content.onHide.subscribe((result:boolean) => {
      currSub.unsubscribe();      
      if (this.modalRef.content.decision === 'add') {
          this.modalRef.content.employer.name =  this.modalRef.content.employer.employer_name;
          this.selectedEmployer = JSON.parse(JSON.stringify(this.modalRef.content.employer))
          this.employerList.push(JSON.parse(JSON.stringify(this.selectedEmployer)));
          localStorage.setItem("employer_list",JSON.stringify(this.employerList));
      } else if (this.modalRef.content.decision === 'update') {
          this.modalRef.content.employer.name =  this.modalRef.content.employer.employer_name;
          this.selectedEmployer = (JSON.parse(JSON.stringify(this.modalRef.content.employer)));
          for(var i=0;i<this.employerList.length;i++) {            
            if(this.employerList[i].employer_code == this.selectedEmployer.employer_code) {
              this.employerList[i] = JSON.parse(JSON.stringify(this.selectedEmployer));
            }
          }
          localStorage.setItem("employer_list",JSON.stringify(this.employerList));
      }      
    });
  }

  deliveryDateChanged(newDate){
    this.customer_info.delivery_date= new Date(newDate);
  }

  changeType (type) {
    if(type == 'Home Delivery'){
      type='home';
    }
    type = type.toLowerCase();
    if(type == 'home'){
      $('#address_require_icon').text('*');
      this.isAddressRequired =true;
    }else{  
      $('#address_require_icon').text('');
      this.isAddressRequired = false;
    }

    if(type == "pickup") {
      this.isMobileNoRequired = false;
    } else {
      this.isMobileNoRequired = true;
    }

    if(type == 'home' || type == 'pickup'){
        this.isPartnerListShow = true;
        this.isDateTimeShow = true;
        this.getDeliveryTime();
    }else{
      this.isPartnerListShow = false;
      this.isDateTimeShow = false;
    }
    if(type =='home'){
      this.customer_info.place = 'Home Delivery';
    }else if(type =='pickup'){
      this.customer_info.place = 'Pickup';
    }else if(type =='here'){
      this.customer_info.place = 'Here';
    }else{
      this.customer_info.place = type;
    }
    this.deliveryType.emit(type);
    this.router.navigate(["/pages/register/place-selection/", type]);
  }

  getDeliveryTime(){

    this.customer_info.delivery_date = new Date ();
    let date = new Date(this.customer_info.delivery_date);
    date.setMinutes(this.customer_info.delivery_date.getMinutes() + 30 );
    let hour = date.getHours();
    let minute = date.getMinutes();

    let newTime = hour +":"+minute;
    if(minute.toString().length == 1){
        newTime = hour +":0"+minute;
    }
    if(hour.toString().length == 1){
        newTime = "0"+hour +":"+minute;
    }
    if(hour.toString().length == 1 && minute.toString().length == 1){
        newTime = "0"+hour +":0"+minute;
    }
    this.customer_info.delivery_time = newTime;
  }


  onChangeMobileNo(e:any){
    if(navigator.onLine &&  e && e.trim().length>=10){
      let sendData = {
        'mobile_no': ''+e,
        'local_user_id': '',
        'shop_code':localStorage.getItem('shop_code')
      }
      this.customerService.contact_search(sendData).subscribe((res) => {
          let userData = res.json();
          if(userData.result && userData.result.details && userData.result.details.length>0){

            let auth_token = userData.result.details[0].auth_token;
            let local_user_id = userData.result.details[0].local_user_id;

            this.customerService.info(auth_token).subscribe((response) => {
              let info = response.json();
              if(info.result){
                this.customer_info.auth_token = auth_token;
                this.customer_info.consumer_name = info.result.consumer_name;
                this.customer_info.local_user_id = local_user_id;
                this.customer_info.email = info.result.email;
                this.customer_info.zip_code = info.result.zip_code;
                this.customer_info.address_1 = info.result.address;
                this.customer_info.city = info.result.city;
                this.customer_info.state = info.result.state;
                this.customer_info.last_purchase = info.result.last_purchase;
                this.customer_info.last_visited = info.result.last_visited;
                this.customer_info.rewards_points = info.result.rewards_points;
                this.customer_info.diary_amt = info.result.diary_amt;


                if(info.result.employer_code && this.employerList.length>0) {
                  let empInfo = findLast(this.employerList,(emp:any) => { return emp.employer_code == info.result.employer_code})
                  if(empInfo) {
                    this.onSelectCompany(empInfo);
                  }                  
                }
              }
              this.setState();
            });
          }else{
            this.customer_info.auth_token = APIHelper.DEFAULT_TOKEN;
            this.customer_info.consumer_name = "";
            this.customer_info.email = "";
            this.customer_info.local_user_id = "";
            this.customer_info.zip_code = "";
            this.customer_info.address_1 = "";
            this.customer_info.city = "";
            this.customer_info.state = "";
            this.customer_info.last_purchase = 0;
            this.customer_info.last_visited = new Date();
            this.customer_info.rewards_points = 0;
            this.customer_info.diary_amt = 0;
            this.customer_info.company_name = "";
            this.setState();
          }

      });
    }
  }

  onChangeLocalUserId(e:any){
    if(navigator.onLine && e && e.trim().length>=4){
      let sendData = {
        'mobile_no': '',        
        'local_user_id': ''+e,
        'shop_code':localStorage.getItem('shop_code')
      }
      
      this.isLoadingShow = true;
      this.customerService.contact_search(sendData).subscribe((res) => {
          let userData = res.json();
          if(userData.result && userData.result.details && userData.result.details.length>0){

            let auth_token = userData.result.details[0].auth_token;

            this.customerService.info(auth_token).subscribe((response) => {
              let info = response.json();
              if(info.result){                
                if(this.customer_info.mobile_no && this.customer_info.mobile_no.toString().length == 10) {
                  if(this.customer_info.mobile_no != info.result.phone) {
                    this.messageService.add({ severity: 'error', summary: e + ' registered with '+info.result.phone, detail: 'It will reset other user local user id.' });
                  }
                } else {
                  this.customer_info.auth_token = auth_token;
                  this.customer_info.consumer_name = info.result.consumer_name;
                  this.customer_info.mobile_no = info.result.phone;
                  //this.customer_info.local_user_id = info.result.local_user_id;
                  this.customer_info.email = info.result.email;
                  this.customer_info.zip_code = info.result.zip_code;
                  this.customer_info.address_1 = info.result.address;
                  this.customer_info.city = info.result.city;
                  this.customer_info.state = info.result.state;
                  this.customer_info.last_purchase = info.result.last_purchase;
                  this.customer_info.last_visited = info.result.last_visited;
                  this.customer_info.rewards_points = info.result.rewards_points;
                  this.customer_info.diary_amt = info.result.diary_amt;

                  if(info.result.employer_code && this.employerList.length>0) {
                    let empInfo = findLast(this.employerList,(emp:any) => { return emp.employer_code == info.result.employer_code})
                    if(empInfo) {
                      this.onSelectCompany(empInfo);
                    }                  
                  }
                  this.setState();
                }                
              }
              this.isLoadingShow = false;
            }, error=> {
              this.isLoadingShow = false;
            });
          }else{            
            this.messageService.add({ severity: 'info', summary: 'The number is not registered with mobile number. To register please enter valid mobile number', detail: '' });
            this.isLoadingShow = false;
          }
      });
    } else if(!navigator.onLine) {
      this.isLoadingShow = false;
      this.messageService.add({ severity: 'error', summary: "can't add local user id on offline mode", detail: '' });
    }
  }

  redirectToFoodOrdering(setData,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,delivery_date,delivery_time,flagForOffline,userInfo,GSTIN,distance,user_id) {
      this.initializeTable(delivery_type_code);

      if(flagForOffline){
        this.setAuthTokenForOfflineOrder(setData,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,userInfo,delivery_date,delivery_time);
      }else{
        this.setAuthToken(setData,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,GSTIN,distance,user_id,delivery_date,delivery_time);
      }
      this.setDeliveryDate(delivery_date,delivery_time);
      this.router.navigate(["/pages/register/food-ordering"])
  }

  initializeTable(delivery_type_code) {
    let device_type = JSON.parse(localStorage.getItem('device_key'));              
    let terminal_name = (device_type && device_type.terminal_name)? device_type.terminal_name: null;
    let teminal_id  = (device_type && device_type.terminal_id)? device_type.terminal_id: null;
    let temp_client_reference_code = terminal_name+"-"+teminal_id+'/WBP' + (new Date()).getTime();                                      
    let location = "Home delivery"
    if(delivery_type_code == APIHelper.PICK_UP) {
      location = "pickup";        
    }else if(delivery_type_code == APIHelper.IMMEDIATE) {
      location = "In Store";        
    }
    let tempCurrentUnit = {                             
      location: location,
      tables: [0],
      covers: 1,
      activePhase: {
        name: "green",
        timeStamp: new Date().valueOf(),
        timerText: "00:00:00"
      },
      visitors: [
        {
          dishes: [[]],
          kot:[],
          temp_client_reference_code: terminal_name+"-"+teminal_id+'/WBP' + (new Date()).getTime()         
        }]                             
    }
    this.stateGuard.entrust('selects-box.component:currentUnit', tempCurrentUnit);
  }

  setAuthToken(token,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,GSTIN,distance,user_id,delivery_date,delivery_time){    
    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    tempUnit.token = token;
    tempUnit.lock_index = 0;
    tempUnit.user_id = user_id;
    tempUnit.selected_partner_list = selectedPartners;
    tempUnit.selected_employer = selectedEmployer;
    tempUnit.assigny = (selectedStaffUser && selectedStaffUser.id) || '';
    tempUnit.delivery_type = delivery_type_code;
    this.setUserInfo(token,delivery_type_code,GSTIN,distance);
    if(delivery_date && delivery_time) {
      this.setDeliveryDate(delivery_date,delivery_time);
    }
  }


  setUserInfo(token,place,GSTIN,distance){
    if(navigator.onLine) {
      this.customerService.info(token).subscribe((res) => {
        var result = res.json(); 
        var sData:any = {}    
        if (result.status === 0) {
          if(token != APIHelper.DEFAULT_TOKEN){
            sData = {
              auth_token: token,
              user_id: (result.result.user_id)? result.result.user_id : "",
              consumer_name: (result.result.consumer_name)? result.result.consumer_name : "",
              email: (result.result.email)? result.result.email : "",
              mobile_no:(result.result.phone) ? result.result.phone : (result.result.alt_address && result.result.alt_address.length>0 && result.result.alt_address[(result.result.alt_address.length - 1)].contact_no)? result.result.alt_address[(result.result.alt_address.length - 1)].contact_no :  '',
              zip_code: result.result.zip_code ? result.result.zip_code  : null,
              address_1: result.result.address ? result.result.address  : "",
              city: result.result.city,              
              state: result.result.state,              
              last_purchase: (result.result.last_purchase)? result.result.last_purchase :0,
              last_visited: (result.result.last_visited)? result.result.last_visited : new Date(),
              rewards_points: (result.result.rewards_points)? result.result.rewards_points:0,
              diary_amt: (result.result.diary_amt)? result.result.diary_amt:0,
              GSTIN: (GSTIN)? GSTIN:null,
              points: (result.result.rewards_points)? result.result.rewards_points:0,
              lastCheck: (result.result.last_purchase)? result.result.last_purchase :0,
              lastVisit: (result.result.last_visited)? result.result.last_visited : new Date(),
              distance:distance
            }

            if(place == APIHelper.IMMEDIATE){
              sData['place'] = 'here';
            }else if(place == APIHelper.PICK_UP){
              sData['place'] = 'pickup';
            }else if(place == APIHelper.HOME_DELIVERY){
              sData['place'] = 'home';
            }

            let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
            tempUnit.userInfo = sData;
            if(this.selectedStaffUser) {
              tempUnit.assigny = this.selectedStaffUser.id;  
            }
            
            if(this.shop_configuration && this.shop_configuration.enable_membership) {
              this.membershipService.getUserMembershipInfo(result.result.user_id).subscribe((membershipInfo) => {
                var memResult = membershipInfo.json();                         
                if (memResult.status === 0) {
                    var available_balance = memResult.result.user_membership.payment_info.available_balance;
                    var membership_name = memResult.result.user_membership.membership_info.membership_name;
                    var membership_status = memResult.result.user_membership.enroll_info.membership_status;
                    
                    sData["membership_name"] = membership_name;
                    sData["available_membership_order_balance"] = available_balance;
                    sData["membership_status"] = membership_status;
                    
                    if(available_balance && memResult.result.user_membership.membership_info.is_fee_order_linked) {
                        var discount_list = JSON.parse(localStorage.getItem("discount_list")) || [];
                        if(Array.isArray(discount_list)) {
                          for(var i=0;i<discount_list.length;i++) {
                            if(Array.isArray(discount_list[i].discount_specifics.restrictions.membership_plans) && 
                              discount_list[i].discount_specifics.restrictions.membership_discount_type == "NUMBER_OF_ORDER" &&
                              discount_list[i].discount_specifics.restrictions.membership_plans.indexOf(memResult.result.user_membership.membership_info.id) != -1) {
                              sData["available_membership_order_count"] = available_balance/discount_list[i].discount_specifics.amount;
                            }
                          }
                        }
                    }
                }
                this.customerMessageService.sendCustomerInfo(sData,false);
              },err => {
                this.customerMessageService.sendCustomerInfo(sData,false);  
              }); 
            } else {
              this.customerMessageService.sendCustomerInfo(sData,false);  
            }
          }
        }
      });
    }

  }

  setAuthTokenForOfflineOrder(data,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,userInfo,delivery_date,delivery_time){
    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    tempUnit.register_customer_info_flag = true;
    tempUnit.register_customer_data = data;
    tempUnit.selected_partner_list = selectedPartners;
    tempUnit.selected_employer = selectedEmployer;
    tempUnit.assigny = (selectedStaffUser && selectedStaffUser.id) || '';
    tempUnit.delivery_type = delivery_type_code;
    tempUnit.lock_index = 0;
    tempUnit.userInfo = userInfo;
    if(delivery_date && delivery_time) {
      this.setDeliveryDate(delivery_date,delivery_time);
    }
  }

  setDeliveryDate(date,time){

    let deliveryDate = new Date(date);
    let setDate = "";
    var dd   = deliveryDate.getDate()+'';
    var mm   = (deliveryDate.getMonth()+1)+'';
    var yyyy = deliveryDate.getFullYear();

    if(dd.length == 1)  { dd='0'+dd } 
    if(mm.length == 1)  { mm='0'+mm }

    setDate = yyyy+'/'+mm+'/'+dd+' '+time;
    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    tempUnit.delivery_date = new Date(setDate);
    localStorage.setItem('delivery_date',setDate);
  }


  submitInfo(isDataFromModal,customer_info,distance,place_type){
    let tempZipCode = customer_info.zip_code;
    this.getZipCodeInformation(tempZipCode).then((zipCodeInfo:any) => {
      if(zipCodeInfo && (zipCodeInfo.state || zipCodeInfo.city)) {
        if(zipCodeInfo.state) { customer_info.state = zipCodeInfo.state; }

        if(zipCodeInfo.city) { customer_info.city = zipCodeInfo.city; }
      }

      let lon1 = (this.shop_configuration.shop_info && this.shop_configuration.shop_info.longitude)?  this.shop_configuration.shop_info.longitude : undefined;
      let lat1 = (this.shop_configuration.shop_info && this.shop_configuration.shop_info.latitude)?  this.shop_configuration.shop_info.latitude : undefined;
      lat1 = (typeof lat1 == 'string')? parseFloat(lat1) : lat1;
      lon1 = (typeof lon1 == 'string')? parseFloat(lon1) : lon1;
      if(distance){
      }else{
        if(this.isChangeGeometryData && lat1 && lon1){
          let lon2 = this.longitude;
          let lat2 = this.latitude;
          let R = 6371;
          let lat1Radian = lat1 * (Math.PI / 180);
          let lat2Radian = lat2 * (Math.PI / 180);
          let latRadianDiff = (lat2 - lat1) * (Math.PI / 180);
          let lonRadianDiff = (lon2 - lon1) * (Math.PI / 180);
          let a = (Math.sin(latRadianDiff / 2) * Math.sin(latRadianDiff / 2)) 
                   + ( Math.cos(lat1Radian) * Math.cos(lat2Radian) * Math.sin(lonRadianDiff / 2) * Math.sin(lonRadianDiff / 2));
          let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

          let d = R * c;
          if(d>0){
            distance = d.toFixed(2) + " kms"
          }
        }else{
          distance = "";
        }
      }

      let mobile_no,local_user_id,email,last_purchase,rewards_points,diary_amt,last_visited,name,address,anniversary,birdthday,child_birdthday,spouse_birdthday,delivery_date,delivery_time,credit_limit;
      let shop_code = localStorage.getItem('shop_code');    
      let current_place = "here";
      let GSTIN = null;
      let selectedPartners = [];
      let selectedEmployer = null;
      let selectedStaffUser = null;
      let selectedConsumerGroups = null;


      mobile_no = customer_info.mobile_no;
      local_user_id = customer_info.local_user_id;
      email = (customer_info.email) ? customer_info.email : null;
      name = (customer_info.consumer_name) ? customer_info.consumer_name : "Guest";
        
      address = {
        'address': (((customer_info.address_1) ? customer_info.address_1 : "") + ((customer_info.address_2) ? ", "+customer_info.address_2 : "")),
        'city': (customer_info.city) ? customer_info.city : "",
        'state': (customer_info.state) ? customer_info.state : "",
        'zip_code': (customer_info.zip_code) ? customer_info.zip_code : "",
        'contact_no': customer_info.mobile_no,
        'mobile_no': customer_info.mobile_no,
        'phone': customer_info.mobile_no
      }

      delivery_date = customer_info.delivery_date? customer_info.delivery_date : null;
      delivery_time = customer_info.delivery_time? customer_info.delivery_time : "00:00";

      if(isDataFromModal) {
        credit_limit = customer_info.credit_limit;
        selectedPartners = (customer_info.selectedPartners) ? customer_info.selectedPartners : [];
        selectedEmployer = (customer_info.selectedEmployer) ? customer_info.selectedEmployer : null;
        selectedStaffUser = (customer_info.selectedStaffUser) ? customer_info.selectedStaffUser : null;
        this.selectedState = (customer_info.selectedState) ? customer_info.selectedState : null;
        GSTIN = customer_info.GSTIN? customer_info.GSTIN : null;        

        anniversary = customer_info.anniversary? customer_info.anniversary : null;
        birdthday = customer_info.birdthday? customer_info.birdthday : null;
        child_birdthday = customer_info.child_birdthday? customer_info.child_birdthday : null;
        spouse_birdthday = customer_info.spouse_birdthday? customer_info.spouse_birdthday : null;        
        current_place = place_type;

        selectedConsumerGroups = customer_info.consumer_groups;
      } else {
        selectedPartners = this.selectedPartners;
        selectedEmployer = this.selectedEmployer;
        selectedStaffUser = this.selectedStaffUser;
        GSTIN = selectedEmployer && selectedEmployer.tax_number ? selectedEmployer.tax_number : "";

        current_place = customer_info.place;
        last_purchase = customer_info.last_purchase;
        last_visited = customer_info.last_visited;
        rewards_points = customer_info.rewards_points;
        diary_amt = customer_info.diary_amt;
      }

      current_place = (current_place)? current_place.toLowerCase() : 'here';
      let sData:any = {
        name: name,
        consumer_name:name,
        email: "",
        mobile_no: mobile_no,
        local_user_id: local_user_id,
        zip_code: address.zip_code,
        address_1: address.address,
        city: address.city,
        state: address.state,
        lastVisit: last_visited,
        lastCheck: last_purchase,
        lastCheckCurrency: "INR",
        points: rewards_points,
        diary_amt: diary_amt,
        GSTIN:GSTIN,
        place: current_place,
        distance: (distance)?  distance :'',
        available_membership_order_balance: null,
        available_membership_order_count: null,
        membership_status: null,
        membership_name: null,
        consumer_groups:selectedConsumerGroups,
        selectedPartners:selectedPartners,
        selectedEmployer:selectedEmployer,
        selectedStaffUser:selectedStaffUser,
        places : [
          {id: 1, name: 'here'},
          {id: 2, name: 'home'},
          {id: 3, name: 'pickup'}
        ]
      };
      if(!delivery_date){
        delivery_date= new Date();
      }

      let sendData = {
        'mobile_no': ''+mobile_no,
        'local_user_id': '',
        'shop_code':localStorage.getItem('shop_code')
      }

      this.customerService.contact_search(sendData).subscribe((res) => {
          let userData = res.json();
          let user_id = 0;
          if(userData.result && userData.result.details && userData.result.details.length>0){
            let auth_token = userData.result.details[0].auth_token;
            let additional_info = {}

            sData["auth_token"] = auth_token;
            if(anniversary){
              additional_info['anniversary_date'] = anniversary;
            }
            if(birdthday){
              additional_info['birth_date'] = birdthday;
            }
            if(spouse_birdthday){
              additional_info['spouse_birth_date'] = spouse_birdthday;
            }
            if(child_birdthday){
              additional_info['child_birth_date'] = child_birdthday;
            }

            if(GSTIN){
              additional_info['gstin'] = GSTIN;
            }

            let sendData = {
              "user":{
                "address":sData.address_1,
                "city":sData.city,
                "state":sData.state,
                "email":email,
                "name":sData.name,
                "local_user_id": ''+sData.local_user_id,
                "phone": ''+sData.mobile_no,
                "zip_code":sData.zip_code,
                'shop_code':localStorage.getItem('shop_code'),
                'consumer_groups':sData.consumer_groups
              }
            }
            if(JSON.stringify(additional_info) !== '{}'){
                sendData.user['additional_info']= additional_info;
            }

            if(selectedEmployer && selectedEmployer.employer_code) {
              sendData.user['employer_code']= selectedEmployer.employer_code; 
            }

            if(credit_limit>=0) { sendData.user['credit_limit']= credit_limit; }
            this.customerService.update_customer_data(sendData,auth_token).subscribe((res) => {
              var result = res.json();
              if (result.status === 0) {

                let current_customer_info = {
                  auth_token : auth_token,
                  consumer_name : sData.name,
                  local_user_id : sData.local_user_id,
                  mobile_no : sData.mobile_no
                }

                this.customerService.addAddress(current_customer_info.auth_token,shop_code,address).then((rdata:any) => {                
                    let delivery_type_code = APIHelper.IMMEDIATE;
                    if(current_place == "home" || current_place == "home delivery") {
                      delivery_type_code = APIHelper.HOME_DELIVERY;
                    } else if(current_place == "pickup") {
                      delivery_type_code = APIHelper.PICK_UP;
                    }
                    this.customerMessageService.sendCustomerInfo(sData,false);
                    current_customer_info["alt_address"] = (rdata.status !== 0) ? [] : [address];
                    localStorage.setItem("delivery_type",delivery_type_code);
                    localStorage.setItem("current_customer_info",JSON.stringify(current_customer_info));
                    if(delivery_type_code == APIHelper.IMMEDIATE && this.isRestaurantFlag  && !this.isItFoodOrderingPage){
                      this.isLoadingShow = false;
                      localStorage.setItem('GSTIN',GSTIN);
                      localStorage.setItem('order_employer_detail',JSON.stringify(selectedEmployer));
                      localStorage.setItem('order_staff_user_detail',JSON.stringify(selectedStaffUser));
                      localStorage.setItem('order_partner_detail',JSON.stringify(selectedPartners));
                      this.messageService.add({ severity: 'success', summary: 'Customer registered successfully', detail: 'Please select table' });
                    }else{
                      if(this.isItFoodOrderingPage){
                        this.setAuthToken(auth_token,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,GSTIN,sData.distance,user_id,delivery_date,delivery_time);
                      }else{
                        this.redirectToFoodOrdering(auth_token,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,delivery_date,delivery_time,false,null,GSTIN,sData.distance,user_id);  
                      }
                    }                
                },error => {
                  this.isLoadingShow = false;
                  this.messageService.add({ severity: 'error', summary: (navigator.onLine)? 'You are offline' : "Internal server error", detail: 'Try again.' });
                });
              }else{
                this.isLoadingShow = false;
                if(result.message){
                  alert(result.message);
                }
              }
            },error => {
              this.isLoadingShow = false;
              this.messageService.add({ severity: 'error', summary: (navigator.onLine)? 'You are offline' : "Internal server error", detail: 'Try again.' });
            })
          }else{
            this.customerService.registerCustomer(mobile_no,local_user_id,name,shop_code).subscribe((res) => {
              var result = res.json();
              if (result.status === 0) {

                let auth_token = result.result.auth_token;
                let user_id = 0;
                let additional_info = {}
                if(anniversary){
                  additional_info['anniversary_date'] = anniversary;
                }
                if(birdthday){
                  additional_info['birth_date'] = birdthday;
                }
                if(spouse_birdthday){
                  additional_info['spouse_birth_date'] = spouse_birdthday;
                }
                if(child_birdthday){
                  additional_info['child_birth_date'] = child_birdthday;
                }

                if(GSTIN){
                  additional_info['gstin'] = GSTIN;
                }
                let sendData = {
                  "user":{
                    "city":sData.city,
                    "state":sData.state,
                    "email":email,
                    "name":sData.name,
                    "phone": ''+sData.mobile_no,
                    "local_user_id": ''+sData.local_user_id,
                    "zip_code":sData.zip_code,
                    'shop_code':localStorage.getItem('shop_code'),
                    'consumer_groups':sData.consumer_groups
                  }
                }
                if(JSON.stringify(additional_info) !== '{}'){
                    sendData.user['additional_info']= additional_info;
                }

                if(selectedEmployer && selectedEmployer.employer_code) {
                  sendData.user['employer_code']= selectedEmployer.employer_code; 
                }

                if(credit_limit>=0) { sendData.user['credit_limit']= credit_limit; }
                this.customerService.update_customer_data(sendData,auth_token).subscribe((res) => {
                  var resultData = res.json();
                  if (resultData.status === 0) {
                    let current_customer_info = {
                      auth_token : auth_token,
                      consumer_name : sData.name,
                      mobile_no : sData.mobile_no
                    }              
                    this.customerService.addAddress(current_customer_info.auth_token,shop_code,address).then((result:any) => {
                      let delivery_type_code = APIHelper.IMMEDIATE;
                      if(current_place == "home" || current_place == "home delivery") {
                        delivery_type_code = APIHelper.HOME_DELIVERY;
                      } else if(current_place == "pickup") {
                        delivery_type_code = APIHelper.PICK_UP;
                      }
                      this.customerMessageService.sendCustomerInfo(sData,false);
                      localStorage.setItem("delivery_type",delivery_type_code);                           
                      current_customer_info["alt_address"] = (result.status !== 0) ? [] : [address];
                      localStorage.setItem("current_customer_info",JSON.stringify(current_customer_info));
                      if(delivery_type_code == APIHelper.IMMEDIATE && this.isRestaurantFlag && !this.isItFoodOrderingPage){
                        this.isLoadingShow = false;
                        localStorage.setItem('GSTIN',GSTIN);
                        localStorage.setItem('order_employer_detail',JSON.stringify(selectedEmployer));
                        localStorage.setItem('order_staff_user_detail',JSON.stringify(selectedStaffUser));
                       localStorage.setItem('order_partner_detail',JSON.stringify(selectedPartners));
                        this.messageService.add({ severity: 'success', summary: 'Customer registered successfully', detail: 'Please select table' });
                      }else{
                        if(this.isItFoodOrderingPage){
                          this.setAuthToken(auth_token,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,GSTIN,sData.distance,user_id,delivery_date,delivery_time);
                        }else{
                          this.redirectToFoodOrdering(auth_token,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,delivery_date,delivery_time,false,null,GSTIN,sData.distance,user_id);  
                        }
                      }
                    });

                  }else{
                    this.isLoadingShow = false;
                    if(resultData.message){
                      alert(resultData.message);
                    }
                  }
                })
              }else{
                this.isLoadingShow = false;
                if(result.message){
                  alert(result.message);
                }
              }
            },error => {
              this.isLoadingShow = false;
              this.messageService.add({ severity: 'error', summary: (navigator.onLine)? 'You are offline' : "Internal server error", detail: 'Try again.' });
            })

          }
      },error => {
        this.isLoadingShow = false;
        this.messageService.add({ severity: 'error', summary: (navigator.onLine)? 'You are offline' : "Internal server error", detail: 'Try again.' });

      });

    })

  }

  setOfflineCustomerInfo(isDataFromModal,customer_info,distance,place_type){
    localStorage.setItem('register_customer_info_flag','true');

    let mobile_no,email,last_purchase,rewards_points,diary_amt,last_visited,name,address,anniversary,birdthday,child_birdthday,spouse_birdthday,delivery_date,delivery_time;
    let shop_code = localStorage.getItem('shop_code');    
    let current_place = "here";
    let selectedPartners = [];
    let selectedEmployer = null;
    let selectedStaffUser = null;
    let selectedConsumerGroups = null;
    let GSTIN = null;
    if(isDataFromModal){

      mobile_no = customer_info.mobile_no;
      email = (customer_info.email) ? customer_info.email : null;
      name = (customer_info.consumer_name) ? customer_info.consumer_name : "Guest";
      selectedPartners = (customer_info.selectedPartners) ? customer_info.selectedPartners : [];
      selectedEmployer = (customer_info.selectedEmployer) ? customer_info.selectedEmployer : null;
      selectedStaffUser = (customer_info.selectedStaffUser) ? customer_info.selectedStaffUser : null;
      GSTIN = customer_info.GSTIN? customer_info.GSTIN : null;
      address = {
        'address': (((customer_info.address_1) ? customer_info.address_1 : "") + ((customer_info.address_2) ? ", "+customer_info.address_2 : "")),
        'city': (customer_info.city) ? customer_info.city : "",
        'state': (customer_info.state) ? customer_info.state : "",
        'zip_code': (customer_info.zip_code) ? customer_info.zip_code : "",
        'contact_no': customer_info.mobile_no,
        'mobile_no': customer_info.mobile_no,
        'phone': customer_info.mobile_no
      }
      anniversary = customer_info.anniversary? customer_info.anniversary : null;
      birdthday = customer_info.birdthday? customer_info.birdthday : null;
      child_birdthday = customer_info.child_birdthday? customer_info.child_birdthday : null;
      spouse_birdthday = customer_info.spouse_birdthday? customer_info.spouse_birdthday : null;
      delivery_date = customer_info.delivery_date? customer_info.delivery_date : null;
      delivery_time = customer_info.delivery_time? customer_info.delivery_time : "00:00";

      current_place = place_type;
      selectedConsumerGroups = customer_info.consumer_groups;       
    }else{

      selectedPartners = this.selectedPartners;
      selectedEmployer = this.selectedEmployer;
      selectedStaffUser = this.selectedStaffUser;
      GSTIN = selectedEmployer && selectedEmployer.tax_number ? selectedEmployer.tax_number : null;
      mobile_no = this.customer_info.mobile_no;
      email = (this.customer_info.email) ? this.customer_info.email : null;
      name = (this.customer_info.consumer_name) ? this.customer_info.consumer_name : "Guest";
      address = {
        'address': (((this.customer_info.address_1) ? this.customer_info.address_1 : "") + ((this.customer_info.address_2) ? ", "+this.customer_info.address_2 : "")),
        'city': (this.customer_info.city) ? this.customer_info.city : "",
        'state': (this.customer_info.state) ? this.customer_info.state : "",
        'zip_code': (this.customer_info.zip_code) ? this.customer_info.zip_code : "",
        'contact_no': this.customer_info.mobile_no,
        'phone': this.customer_info.mobile_no,
        'mobile_no': this.customer_info.mobile_no
      }
      current_place = this.customer_info.place;
      delivery_date = this.customer_info.delivery_date? this.customer_info.delivery_date : null;
      delivery_time = this.customer_info.delivery_time? this.customer_info.delivery_time : "00:00";

      last_purchase = this.customer_info.last_purchase;
      last_visited = this.customer_info.last_visited;
      rewards_points = this.customer_info.rewards_points;
      diary_amt = this.customer_info.diary_amt;
    }
    current_place = (current_place) ? current_place.toLowerCase() : "";

    if(!delivery_date){
      delivery_date= new Date();
    }

    let contact_search_data = {
      'mobile_no': ''+mobile_no,
      'local_user_id': '',
      'shop_code':localStorage.getItem('shop_code')
    }

    let sData:any = {
      name: name,
      consumer_name:name,
      email: "",
      mobile_no: mobile_no,
      zip_code: address.zip_code,
      address_1: address.address,
      city: address.city,
      state: address.state,
      lastVisit: last_visited,
      lastCheck: last_purchase,
      lastCheckCurrency: "INR",
      points: rewards_points,
      diary_amt: diary_amt,
      GSTIN:GSTIN,
      place: current_place,
      distance: (distance)?  distance :'',
      consumer_groups:selectedConsumerGroups,  
      selectedPartners:selectedPartners,
      selectedEmployer:selectedEmployer,
      selectedStaffUser:selectedStaffUser,
      places : [
        {id: 1, name: 'here'},
        {id: 2, name: 'home'},
        {id: 3, name: 'pickup'}
      ]
    };
    

    let additional_info = {}
    if(anniversary){
      additional_info['anniversary_date'] = anniversary;
    }
    if(birdthday){
      additional_info['birth_date'] = birdthday;
    }
    if(spouse_birdthday){
      additional_info['spouse_birth_date'] = spouse_birdthday;
    }
    if(child_birdthday){
      additional_info['child_birth_date'] = child_birdthday;
    }
    if(GSTIN){
      additional_info['gstin'] = GSTIN;
    }

    let customer_update_data = {
      "user":{
        "city":sData.city,
        "state":sData.state,
        "email":email,
        "name":sData.name,
        "phone": ''+sData.mobile_no,
        "zip_code":sData.zip_code,
        'shop_code':localStorage.getItem('shop_code'),
        'consumer_groups':selectedConsumerGroups
      }
    }
    if(JSON.stringify(additional_info) !== '{}'){
      customer_update_data.user['additional_info']= additional_info;
    }

    let add_address = {
      'address':address
    }

    let setData = {
      'add_address':add_address,
      'customer_update_data':customer_update_data,
      'contact_search_data':contact_search_data
    }

    let delivery_type_code = APIHelper.IMMEDIATE;
    if(current_place == "home" || current_place == "home delivery") {
      delivery_type_code = APIHelper.HOME_DELIVERY;
    } else if(current_place == "pickup") {
      delivery_type_code = APIHelper.PICK_UP;
    }

    localStorage.setItem('register_customer_data',JSON.stringify(setData));
    localStorage.setItem("delivery_type",delivery_type_code);
    localStorage.setItem("current_customer_info",JSON.stringify(sData));

    if(delivery_type_code == APIHelper.IMMEDIATE && this.isRestaurantFlag  && !this.isItFoodOrderingPage){
      localStorage.setItem('GSTIN',GSTIN);
      localStorage.setItem('order_employer_detail',JSON.stringify(selectedEmployer));
      localStorage.setItem('order_staff_user_detail',JSON.stringify(selectedStaffUser));
      localStorage.setItem('order_partner_detail',JSON.stringify(selectedPartners));
    }
    this.customerMessageService.sendCustomerInfo(sData,false);

    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
    tempUnit.userInfo = sData;

    this.isLoadingShow = false;

    if(delivery_type_code == APIHelper.IMMEDIATE && this.isRestaurantFlag  && !this.isItFoodOrderingPage){
      this.messageService.add({ severity: 'success', summary: 'Customer registered successfully', detail: 'Please select table' });
    }else{
      if(this.isItFoodOrderingPage){
        this.setAuthTokenForOfflineOrder(setData,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,sData,delivery_date,delivery_time);
      }else{
        this.redirectToFoodOrdering(setData,delivery_type_code,selectedStaffUser,selectedPartners,selectedEmployer,delivery_date,delivery_time,true,sData,GSTIN,sData.distance,null);  
      }
    }    
  }

  submitCustomerInfo(isDataFromModal,customer_info,distance,place_type) {
    let index = window.location.href.indexOf('food-ordering');
    this.isItFoodOrderingPage = (index == -1) ? false : true;
    if(localStorage.getItem('isRestaurant') == 'false') { this.isRestaurantFlag = false }

    localStorage.setItem('register_customer_info_flag','false');
    localStorage.removeItem('delivery_date');
    localStorage.removeItem('GSTIN');
    if(!place_type || (isDataFromModal && customer_info.place)) { place_type = customer_info.place; }    
    let mobile_no = customer_info.mobile_no;    
    if(place_type && place_type.toLowerCase() == "pickup" && !mobile_no) { 
      this.pickUpOrderWithoutMobileNo(isDataFromModal,customer_info);
    } else if(mobile_no.toString().length > 0){
      if (navigator.onLine) {
	      this.isLoadingShow = true;
        this.submitInfo(isDataFromModal,customer_info,distance,place_type);
      }else{
        this.setOfflineCustomerInfo(isDataFromModal,customer_info,distance,place_type);
      }  
    }else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter valid phone number' });
    }    
  }


  pickUpOrderWithoutMobileNo(isDataFromModal,customer_info) {
    let delivery_type_code = APIHelper.PICK_UP;    
    let delivery_date = (isDataFromModal) ? customer_info.delivery_date : this.customer_info.delivery_date;
    let delivery_time = (isDataFromModal) ? customer_info.delivery_time : this.customer_info.delivery_time;
    let selectedPartners:any = (isDataFromModal) ? customer_info.selectedPartners : this.selectedPartners;
    let selectedEmployer: any= (isDataFromModal) ? customer_info.selectedEmployer : this.selectedEmployer;
    let selectedStaffUser: any= (isDataFromModal) ? customer_info.selectedStaffUser : this.selectedStaffUser;
    
    if(!isDataFromModal) {
      this.initializeTable(delivery_type_code);      
    }

    if(delivery_date && delivery_time) {
      this.setDeliveryDate(delivery_date,delivery_time);  
    } 

    let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");    
    if(!isDataFromModal) { tempUnit.lock_index = 0; }

    tempUnit.selected_partner_list = selectedPartners;
    tempUnit.selected_employer = selectedEmployer;
    tempUnit.delivery_type = delivery_type_code; 
    tempUnit.assigny = (selectedStaffUser && selectedStaffUser.id) || ''; 
    localStorage.setItem("delivery_type",delivery_type_code);
    let sData:any = {
      selectedEmployer:selectedEmployer,
      selectedPartners:selectedPartners,
      selectedStaffUser:selectedStaffUser,
      place:'pickup'
    }
    this.customerMessageService.sendCustomerInfo(sData,true);

    if(!isDataFromModal) {
      this.router.navigate(["/pages/register/food-ordering"]);
    }
  }

}
