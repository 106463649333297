import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cash-register',
  templateUrl: './cash-register.component.html',
  styleUrls: ['./cash-register.component.less']
})
export class CashRegisterComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
