/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewEncapsulation,ViewChild,ElementRef, NgZone, Output, EventEmitter} from '@angular/core';
import { CustomerService } from '../helpers/services/customer.service';
import { Subscription } from 'rxjs';
import {BaseModalComponent} from '../helpers/base-modal.component';
// import { } from 'googlemaps';
import { MapsAPILoader } from '@agm/core';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { EmployerModal } from '../employer-modal/employer-modal.component';

import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { APIHelper } from '../helpers/api-helper';
import { findLast } from 'lodash-es';

declare var $:any;
// declare var google: any;
// declare module 'googlemaps';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class CustomerInfoComponent extends BaseModalComponent implements OnInit {

  latitude:any;
  longitude:any;
  msgs: Message[] = [];

  @ViewChild("searchPlace",{static: false})
  public searchElement: ElementRef;

  @Output() geometryData: EventEmitter<any> = new EventEmitter();

  
  constructor(private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,public modalRef: BsModalRef,public currentModalRef: BsModalRef,private customerService:CustomerService,
    private modalService: BsModalService,private messageService: MessageService) { 
    super(currentModalRef);
  }
  public decision: string;

  settings:any={position:"bottom"};
  shop_configuration:any= JSON.parse(localStorage.getItem("shop_configuration")) || null;
  partnerList:any=[];
  selectedPartners:any=[];
  employerList: any = [];  
  selectedEmployer: any = null;
  gstStateList: any = [];  
  selectedState: any = null;
  staffList: any = [];
  selectedStaffUser:any = null;
  
  consumer_groups: any = [];
  selected_consumer_group: any = [];

  isChangeGeometryData:boolean= false;
  isRequiredCityInput:boolean= true;
  isRequiredEmailInput:boolean= true;
  isRequiredAddress1Input:boolean= true;
  isRequiredZipInput:boolean= true;
  isRequiredContactInput:boolean= true;
  isRequiredNameInput:boolean= true;

  isLoadingShow: boolean = false;
  ngOnInit() {
      this.initializeConsumerGroups();

      $('#madatory_city_icon').text('');
      $('#madatory_zipcode_icon').text('');
      $('#madatory_email_icon').text('');
      $('#madatory_address1_icon').text('');
      $('#madatory_name_icon').text('');

      this.settings = {
        text: "Select Partners",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: "myclass custom-class",
        maxHeight:100,
        badgeShowLimit:5
      };

      $(function() {
        $("#phone_number_validation").keypress(function(event) {
          if (event.which != 8 && event.which != 0 && (event.which < 48 || event.which > 57)) {
            return false;
          }
        });
      });  

      this.getStaffUserList();
      this.getPartnerList();
      this.getEmployersList();
      this.getGSTStateList();
      setTimeout(()=> {
        this.setState();
        this.setSelectedPartners();
        this.setConsumerGroups();
      },300);
      this.isRequiredEmailInput= false;
      this.isRequiredAddress1Input= false;
      this.isRequiredNameInput= false;
      this.isRequiredCityInput= false;
      this.isRequiredZipInput= false;
      
      if(localStorage.getItem('tempDeliveryType') == 'home'){
        this.getDeliveryTime();
        $('#madatory_address1_icon').text('*');
        $('#madatory_zipcode_icon').text('*');
        this.isRequiredContactInput= false;
        this.isRequiredAddress1Input= true;
        this.isRequiredZipInput= true;        
      }

      if(localStorage.getItem('tempDeliveryType') == 'pickup' || localStorage.getItem('delivery_type')==APIHelper.PICK_UP){
        this.isRequiredContactInput= false;        
      }

      this.mapsAPILoader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
          types: ["address"]
        });
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            this.isChangeGeometryData = true;
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();

            let tempData = {};
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                tempData[addressType] = place.address_components[i].long_name;
            }

            this.customerInfoModal.address_1 = place.formatted_address;
            this.customerInfoModal.city = tempData['administrative_area_level_2'];
            this.customerInfoModal.state = tempData['administrative_area_level_1'];
            this.customerInfoModal.zip_code = tempData['postal_code'];
          });
        });
      });
  }

  ngDoCheck() {
    if(this.customerInfoModal.selectedEmployer && !this.selectedEmployer)  {
      this.onSelectCompany(this.customerInfoModal.selectedEmployer )
    }
  }

  keyPress(event: any) {
   const pattern = /[0-9]/;

   let inputChar = String.fromCharCode(event.charCode);
   if (event.keyCode != 8 && !pattern.test(inputChar)) {
     event.preventDefault();
   }
  }

  initializeConsumerGroups() {
    if(this.shop_configuration && this.shop_configuration.consumer_group) {
       var temp_consumer_groups = this.shop_configuration.consumer_group.filter(cg => { return cg.is_generic; });
       this.consumer_groups = temp_consumer_groups.map(tcg => {return tcg.name});
    }
  }

  onSelectConsumerGroup(e) {
    let consumerGroupData = JSON.parse(e);
    if(consumerGroupData) {
      this.selected_consumer_group = consumerGroupData;
      this.customerInfoModal.consumer_groups = consumerGroupData;
    }
  }

  getDeliveryTime(){

    this.customerInfoModal.delivery_date = new Date ();
    let date = new Date(this.customerInfoModal.delivery_date);
    date.setMinutes(this.customerInfoModal.delivery_date.getMinutes() + 30 );
    let hour = date.getHours();
    let minute = date.getMinutes();

    let newTime = hour +":"+minute;
    if(minute.toString().length == 1){
        newTime = hour +":0"+minute;
    }
    if(hour.toString().length == 1){
        newTime = "0"+hour +":"+minute;
    }
    if(hour.toString().length == 1 && minute.toString().length == 1){
        newTime = "0"+hour +":0"+minute;
    }
    this.customerInfoModal.delivery_time = newTime;
  }

  getStaffUserList() {
    let tempStaffList = [];
    if(localStorage.getItem('shop_user_list')){
      let list = JSON.parse(localStorage.getItem('shop_user_list'));
      if(list.length>0){
        list.forEach(staff=>{
          tempStaffList.push({id:staff.id,name:staff.name});
        });
      }
    }

    this.staffList = tempStaffList;
  }

  getPartnerList(){
    this.partnerList = JSON.parse(localStorage.getItem("partner_list")) || [];        
  }

  getGSTStateList() {
    this.gstStateList = JSON.parse(localStorage.getItem("gst_state_codes")) || [];         
  }

  onSelectState(event) {    
    if(event && event.state_code) {
      this.selectedState = event;  
      this.customerInfoModal.state = event.name;      
    }
  }

  setState() {
    if(this.customerInfoModal.state) {
      for(var i=0;i<this.gstStateList.length;i++) {
        if(this.customerInfoModal.state.toLowerCase().indexOf(this.gstStateList[i].state.toLowerCase()) != -1 ) {
          this.selectedState = this.gstStateList[i];
          break;
        }
      }
    }
  }

  setSelectedPartners() {
    if(Array.isArray(this.customerInfoModal.selectedPartners)) {
      this.selectedPartners =  this.customerInfoModal.selectedPartners;      
    }
  }

  setConsumerGroups() {
    this.selected_consumer_group = [];
    if(Array.isArray(this.customerInfoModal.consumer_groups)) {
      for(var i=0;i<this.consumer_groups.length;i++) {
        if(this.customerInfoModal.consumer_groups.indexOf(this.consumer_groups[i]) != -1) {
          this.selected_consumer_group.push(this.consumer_groups[i]);
        }
      }
    }
  }

  getEmployersList() {
    this.employerList = JSON.parse(localStorage.getItem("employer_list")) || [];         
  }

  onSelectCompany(event) {    
    if(event && event.employer_code) {
      this.selectedEmployer = event;  
      this.customerInfoModal.companyName = this.selectedEmployer.name;
      this.customerInfoModal.GSTIN = this.selectedEmployer.tax_number;
    }
  }

  onSelectStaffUser(event) {    
    if(event && event.id) {
      this.selectedStaffUser = event;  
      this.customerInfoModal.selectedStaffUser = this.selectedStaffUser;
      localStorage.setItem('selected_staff_user', this.selectedStaffUser.name)
      //this.customerInfoModal.staff_user_id = this.selectedStaffUser.id;
    }
  }

  openEmployerModal() {
    var tempEmployer = {employer_name:"",employer_code:"",address:"",city:"",tax_number:"",pin_code:"",state:""};
    if(this.selectedEmployer && this.selectedEmployer.employer_code) {
       tempEmployer = JSON.parse(JSON.stringify(this.selectedEmployer));
    }
    this.modalRef = this.modalService.show(EmployerModal, {class: 'common-modal employer-modal' ,keyboard: false,backdrop: true,ignoreBackdropClick: true});
    this.modalRef.content.decision = "";
    this.modalRef.content.employer = tempEmployer;

    var currSub:Subscription = this.modalRef.content.onHide.subscribe((result:boolean) => {
      currSub.unsubscribe();      
      if (this.modalRef.content.decision === 'add') {
          this.modalRef.content.employer.name =  this.modalRef.content.employer.employer_name;
          this.selectedEmployer = JSON.parse(JSON.stringify(this.modalRef.content.employer))
          this.employerList.push(JSON.parse(JSON.stringify(this.selectedEmployer)));
          localStorage.setItem("employer_list",JSON.stringify(this.employerList));
          this.customerInfoModal.GSTIN = this.selectedEmployer.tax_number;
      } else if (this.modalRef.content.decision === 'update') {
          this.modalRef.content.employer.name =  this.modalRef.content.employer.employer_name;
          this.selectedEmployer = (JSON.parse(JSON.stringify(this.modalRef.content.employer)));
          for(var i=0;i<this.employerList.length;i++) {            
            if(this.employerList[i].employer_code == this.selectedEmployer.employer_code) {
              this.employerList[i] = JSON.parse(JSON.stringify(this.selectedEmployer));
            }
          }
          localStorage.setItem("employer_list",JSON.stringify(this.employerList));
          this.customerInfoModal.GSTIN = this.selectedEmployer.tax_number;
      }      
    });
  }

  deliveryDateChanged(newDate){
    this.customerInfoModal.delivery_date= new Date(newDate);
  }

  onChangeMobileNo(e:any){
    if(navigator.onLine && e && e.trim().length>=10){
      let sendData = {
        'mobile_no': ''+e,
        'local_user_id': '',
        'shop_code':localStorage.getItem('shop_code')
      }
      this.customerService.contact_search(sendData).subscribe((res) => {
          let userData = res.json();
          if(userData.result && userData.result.details && userData.result.details.length>0){

            let auth_token = userData.result.details[0].auth_token;
            let local_user_id = userData.result.details[0].local_user_id;

            this.customerService.info(auth_token).subscribe((response) => {
              let info = response.json();
              if(info.result){
                this.customerInfoModal.consumer_name = info.result.consumer_name;
                this.customerInfoModal.local_user_id = info.result.local_user_id;
                this.customerInfoModal.email = info.result.email;
                this.customerInfoModal.zip_code = info.result.zip_code;
                this.customerInfoModal.address_1 = info.result.address;
                this.customerInfoModal.city = info.result.city;
                this.customerInfoModal.state = info.result.state;
                this.customerInfoModal.last_purchase = info.result.last_purchase;
                this.customerInfoModal.last_visited = info.result.last_visited;
                this.customerInfoModal.rewards_points = info.result.rewards_points;
                this.customerInfoModal.consumer_groups = info.result.consumer_groups;
                this.customerInfoModal.credit_limit = info.result.credit_limit;
                if(info.result.employer_code && this.employerList.length>0) {
                  let empInfo = findLast(this.employerList,(emp:any) => { return emp.employer_code == info.result.employer_code})
                  if(empInfo) {
                    this.onSelectCompany(empInfo);
                  }                  
                }
              
                this.setState();
                this.setConsumerGroups();
              }
            });
          }else{
            this.customerInfoModal.consumer_name = "";
            this.customerInfoModal.local_user_id = "";
            this.customerInfoModal.email = "";
            this.customerInfoModal.zip_code = "";
            this.customerInfoModal.address_1 = "";
            this.customerInfoModal.city = "";
            this.customerInfoModal.state = "";
            this.customerInfoModal.credit_limit = null;
            this.customerInfoModal.last_purchase = 0;
            this.customerInfoModal.last_visited = new Date();
            this.customerInfoModal.rewards_points = 0;
            this.customerInfoModal.GSTIN = "";
            this.customerInfoModal.companyName = "";
            this.setState();
            this.setConsumerGroups();
          }

      });
    }
  }

  onChangeLocalUserId(e:any){
    if(navigator.onLine && e && e.trim().length>=4){
      let sendData = {
        'mobile_no': '',        
        'local_user_id': ''+e,
        'shop_code':localStorage.getItem('shop_code')
      }
      this.isLoadingShow = true;
      this.customerService.contact_search(sendData).subscribe((res) => {
          let userData = res.json();
          if(userData.result && userData.result.details && userData.result.details.length>0){

            let auth_token = userData.result.details[0].auth_token;

            this.customerService.info(auth_token).subscribe((response) => {
              let info = response.json();
              if(info.result){
                if(this.customerInfoModal.mobile_no && this.customerInfoModal.mobile_no.toString().length == 10) {
                  if(this.customerInfoModal.mobile_no != info.result.phone) {
                    this.messageService.add({ severity: 'error', summary: e + ' registered with '+info.result.phone, detail: 'It will reset other user local user id.' });
                  }
                } else {
                  this.customerInfoModal.consumer_name = info.result.consumer_name;
                  this.customerInfoModal.mobile_no = info.result.phone;
                  //this.customerInfoModal.local_user_id = info.result.local_user_id;
                  this.customerInfoModal.email = info.result.email;
                  this.customerInfoModal.zip_code = info.result.zip_code;
                  this.customerInfoModal.address_1 = info.result.address;
                  this.customerInfoModal.city = info.result.city;
                  this.customerInfoModal.state = info.result.state;
                  this.customerInfoModal.last_purchase = info.result.last_purchase;
                  this.customerInfoModal.last_visited = info.result.last_visited;
                  this.customerInfoModal.rewards_points = info.result.rewards_points;
                  this.customerInfoModal.consumer_groups = info.result.consumer_groups;
                  this.customerInfoModal.credit_limit = info.result.credit_limit;
                  if(info.result.employer_code && this.employerList.length>0) {
                    let empInfo = findLast(this.employerList,(emp:any) => { return emp.employer_code == info.result.employer_code})
                    if(empInfo) {
                      this.onSelectCompany(empInfo);
                    }                  
                  }

                  this.setState();
                  this.customerInfoModal.state = "";

                }
              }
              
              this.isLoadingShow = false;
            });
          } else{
            this.messageService.add({ severity: 'info', summary: 'The number is not registered with mobile number. To register please enter valid mobile number', detail: '' });
            this.isLoadingShow = false;          
          }
      });
    } else if(!navigator.onLine) {
      this.messageService.add({ severity: 'error', summary: "can't add local user id on offline mode", detail: '' });
    }
  }


  customerInfoModal = {
    consumer_name: "",
    email: "",
    mobile_no: null,
    local_user_id: null,
    zip_code: null,
    companyName: "",
    anniversary: null,
    child_birdthday: null,
    address_1: "",
    address_2: "",
    last_purchase:0,
    last_visited:new Date(),
    rewards_points:0,
    birdthday: null,
    spouse_birdthday: null,
    selectedPartners:[],
    selectedEmployer:null,
    selectedStaffUser:null,
    selectedState:null,
    delivery_date:new Date(),
    delivery_time:null,
    city: "",
    state: "",
    GSTIN:"",
    consumer_groups:null,
    credit_limit:null,
    lat:this.latitude,
    lng:this.longitude,
    isChangeGeometryData:this.isChangeGeometryData
  }

  submitCustomerInfoModal(){
    this.customerInfoModal.selectedPartners = JSON.parse(JSON.stringify(this.selectedPartners));
    this.customerInfoModal.selectedEmployer = JSON.parse(JSON.stringify(this.selectedEmployer));
    this.customerInfoModal.selectedState = JSON.parse(JSON.stringify(this.selectedState));    
    this.customerInfoModal.isChangeGeometryData = this.isChangeGeometryData;
    this.customerInfoModal.lat = this.latitude;
    this.customerInfoModal.lng = this.longitude;
    this.decision='select';
    //this.modalRef.hide()
    this.done();
  }

  done(){      
    this.close(false);
  }
  
}
