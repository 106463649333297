/**
 * (c) OneGreenDiary Software Pvt. Ltd.
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform,
 *    solely reside with the third-party.  
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Once OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without
 *    prior agreement with OneGreenDiary Software Pvt. Ltd.
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';



import { APIHelper } from '../api-helper';

@Injectable()
export class MembershipService {
    constructor(private http: Http) { }

    getMembershipPlans(params) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_MEMBERSHIP, params);
    }

    enrollUserForMembershipPlan(membership_plan_id:any,primary_user_id:any,secondary_user_ids:any[]) {

        var reqData = {
           membership_plan_id:membership_plan_id,
           primary_user_id:primary_user_id,
           secondary_user_ids:secondary_user_ids
        };
        
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_ENROLL_USER_FOR_MEMBERSHIP, reqData);
        
    }

    getUserMembershipInfo(user_id:any) {

        var reqData = {
           user_id:user_id          
        };        

        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_USER_MEMBERSHIP_DETAIL, reqData);
        
    }

    renewUserMembershipPlan(membership_id:any) {
      var reqData = {
         membership_id:membership_id
      };
      return APIHelper.getInstance(this.http).apiCall(APIHelper.API_RENEW_USER_MEMBERSHIP, reqData);
    }

    getMembershipPaymentDetail(user_id:any) {
      var reqData = "user_id="+user_id;
      return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_MEMBERSHIP_PAYMENT_DETAIL, reqData);
    }

    makeMembershipPayment(shop_code:any,user_membership_id:any,fee_paid:any,payment_details:any,tax_details:any) {
    	var reqData = {
           user_membership_id:user_membership_id,
           shop_code:shop_code,
           fee_paid:fee_paid,
           payment_details:payment_details,
           tax_details: tax_details
        };
       
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_MAKE_MEMBERSHIP_PAYMENT, reqData);	    	
    }
}
