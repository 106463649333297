/**
 * (c) OneGreenDiary Software Pvt. Ltd.
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform,
 *    solely reside with the third-party.  
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Once OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without
 *    prior agreement with OneGreenDiary Software Pvt. Ltd.
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';



import { APIHelper } from '../api-helper';

@Injectable()
export class SaveOrderService {
    constructor(private http: Http) { }

    save(cartItems:any,kotItems:any, shop_code:string, orderDeliveryTime:Date, deliveryOption:string, 
         paymentType:string=APIHelper.CASH_IN_HAND_TYPE,
         customerAuthToken:string=APIHelper.DEFAULT_TOKEN,
         table_number:any, table_covers:number, shop_section:string, 
         non_chargeable:string , comment:string, extra_charges:any[],
         createdAt:Date,partners_list:any[],employer_code:string=null,tax_number:string=null,shop_user_id:number,cancel_order:string,
         offline_order_id:string,record_item_wise_discount:boolean,cancel_order_comment:string,
         previousClientReferenceCode:string,prev_order_id:string,discount_codes:any[],applied_discount_info:any[],tax_rate:number,tax_value:number,tax_type:string,tax_breakup:any[],                      
         note:string="",bill_tax_class:string="",separate_bill_for_tax_classes:number=0,is_special_order:number=0,check_prints:any=[],
         sub_total:any,valued_at:any) {
        
        var reqData:any = {
            order_id: '',
            'delivery_date': orderDeliveryTime.toISOString(),
            'shipping_type': deliveryOption,
            'auth_token': customerAuthToken,
            'is_offline_sync':true,
            'cart_items': cartItems,
            'kot_items':kotItems,
            'payment_type':  paymentType,
            'shop_code': shop_code,
            'client_reference_code': offline_order_id,
            'table_number': table_number,
            'table_covers': table_covers,
            'shop_section': shop_section,
            'non_chargeable': (non_chargeable == "true") ? 1 : 0 ,
            'extra_charges': extra_charges,
            'createdAt' : createdAt,
            'partner_ids':partners_list,
            'employer_code':employer_code,
            'tax_number':tax_number,
            'shop_user_id':shop_user_id,
            'record_item_wise_discount':record_item_wise_discount,
            'cancel_order':(cancel_order == "true") ? 1 : 0 ,
            'cancel_order_comment':cancel_order_comment,
            'prev_client_reference_code':previousClientReferenceCode,
            'prev_order_id':prev_order_id,
            'discount_codes':discount_codes,
            'applied_discount_info':applied_discount_info,
            'tax_value':tax_value,
            'tax_rate':tax_rate,
            'tax_type':tax_type,
            'tax_breakup':tax_breakup,
            'note':note,
            'bill_tax_class':bill_tax_class,
            'separate_bill_for_tax_classes':separate_bill_for_tax_classes,
            'is_special_order': is_special_order,
            'check_prints': check_prints,
            'valued_at': valued_at,
            'sub_total': sub_total
        };
       
        if(comment){
            if(non_chargeable) {
                var commentData = (typeof comment == "string") ? JSON.parse(comment) : comment
                reqData.nocharge_comment = JSON.parse(JSON.stringify(comment));
                if(commentData && commentData.employeeId)  {
                    reqData["employee_id"] = parseInt(commentData.employeeId);
                }            
            } else {
                reqData['comment']=comment;
            }
        } else {
            reqData['comment']=comment;
        }
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_SAVE_ORDER, reqData);
    }
}
