import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'nameSearch'})
export class NameSearchPipe implements PipeTransform {
	transform(items: any[], criteria: string): any {
		if(criteria === '') {
			return items;
		} else {
			return items.filter(item => {
				criteria.toString();
				if(criteria.match( /[|<|,|>|\.|\?|\/|:|;|"|'|{|\[|}|\]|\||\\|~|`|!|@|#|\$|%|\^|&|\*|\(|\)|_|\-|\+|=]+/ ) != null) {  
					return false;
				}
				if(criteria == criteria.toUpperCase()) {
					if(!item.user_name.toUpperCase().search(criteria)) {/* || !item.mobile.search(criteria) || !item.n.toUpperCase().search(criteria)*/
						return item;
					}
				} else if(criteria == criteria.toLowerCase()) {
					if(!item.user_name.toLowerCase().search(criteria)) {/* || !item.mobile.search(criteria) || !item.n.toLowerCase().search(criteria)*/
						return item;
					}
				} else {
					if(!item.user_name.search(criteria)) {/* || !item.mobile.search(criteria) || !item.n.search(criteria)*/
						return item;
					}
				}
			});
		}
	}	
}