import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
 
@Injectable()
export class Messenger {
    private subject = new Subject<any>();
    private basketSubject = new Subject<any>();

    sendMessage(message: string) {
        this.subject.next({ text: message });
    }
 
    clearMessage() {
        this.subject.next();
    }
 
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    sendBasketComponentMessage(message: any) {
        this.basketSubject.next(message);
    }

    getBasketComponentMessage():Observable<any> {
        return this.basketSubject.asObservable();
    }
}