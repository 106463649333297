import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../pages/helpers/services/common.service';
import { RazorpayComponent } from '../../pages/helpers/razorpay.component';
import { WindowRef } from '../../pages/helpers/windowRef';
import { GeneralHelper } from '../../pages/helpers/genral-helper';

import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-license-renew-modal',
  templateUrl: './license-renew-modal.component.html',
  styleUrls: ['./license-renew-modal.component.less'],
  providers:[RazorpayComponent,WindowRef]
})
export class LicenseRenewModalComponent implements OnInit {
  
  constructor(public modalRef: BsModalRef,private commonService: CommonService,private razorpay:RazorpayComponent,
  private messageService: MessageService) { }
  public decision: string;
  public remainingDays: number=0;
  public step: number = 0;
  public renew_plan_month: any = null;
  public month_wise_subscription_value:any = [];
  public isLoadingShow: boolean = false;
  public monthly_subscription_value:any
  public yearly_subscription_value:any;
  public standard_price:any = [];
  public is_strike:boolean = false;
  public res:any = []
  public showStyle :boolean =true;
 public isBold = false;
  ngOnInit() {
    this.getData();
  }

  getData(){
    var self = this;
    setTimeout(()=>{
      self.remainingDays = this.modalRef.content.remainingDays;
    },100);
  }
  
   
  

  yes(){
    
    if(navigator.onLine){ 
        this.step = 1;      
    }else{
      alert('Internet connection is required');
    }
  }
 

  renewPlan() {
    console.log("renew plan months=>"+this.renew_plan_month)
    if(!this.renew_plan_month) {
      alert("Select a plan to renew");  
      return;
    }
    
    let temp_renew_plan_month:any = this.month_wise_subscription_value.filter(ms=> {return ms.m_c==this.renew_plan_month;})[0];    
    let plan_amount = temp_renew_plan_month.v + (temp_renew_plan_month.v * 0.18);
    this.isLoadingShow = true;
    this.razorpay.initPay(plan_amount,{ renewal_type:"custom",m_c:temp_renew_plan_month.m_c}).then((payment_id:any) => {
      if(!payment_id) {
        this.isLoadingShow = false;
        alert("payment canceled");
        return;
      }
      this.commonService.renewLicence({payment_id:payment_id,shop_code:localStorage.getItem('shop_code')}).subscribe((res) => {
          var apiRes = res.json();
          if(apiRes.status == 0) {
            let temp_date = new Date(apiRes.result.new_licence_end_date);
            let temp_date_string = temp_date.getFullYear()+"-"+(temp_date.getMonth()+1) +"-"+temp_date.getDate();            
            localStorage.setItem("license_renew_update_date",apiRes.result.new_licence_end_date);
            this.remainingDays = Math.abs(GeneralHelper.daysBetween(temp_date,new Date()));
            this.messageService.add({ severity: 'success', summary: 'Success', detail: "Licence Rewened upto "+ temp_date_string });
            this.decision='yes';
            this.modalRef.hide();
          } else {
            let message = apiRes.message ?  apiRes.message : "Error while verify payment";
            alert(message);      
          }
          this.isLoadingShow = false;
      },error => {
        console.log(error)
        alert("Something went wrong while make a payment. please try again later");      
        this.isLoadingShow = false;
      });
    })
    .catch(error => {
      alert("Something went wrong while make a payment. please try again later");      
      this.isLoadingShow = false;
    });
  }

  cancelPlan() {
    this.step = 0;
  }
}
