import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-checkbox-select',
  templateUrl: './input-checkbox-select.component.html',
  styleUrls: ['./input-checkbox-select.component.css']
})
export class InputCheckboxSelectComponent implements OnInit {

	@Input('label') label: string;
  @Input('filter_label') filter_label: string;
	@Input('remove_all_label') remove_all_label: string;
	@Input('type') type: string;
	@Input('name') name: string;
	@Input('value') value: any;
  @Input('is_mandatory') is_mandatory: string = 'false';
	@Input('placeholder') placeholder: string;
  @Input('filter_items') filter_items: any;
  @Input('disabled') disabled: boolean = false;	

	@Output() sendValue = new EventEmitter<string>();

	categories: any[] = [];

  showStAssign: boolean;  

  filterBy: string;

  constructor() { }

  ngOnInit() {
    var self = this;
  }

  

  validate(item){
    if(item.indexOf('_') != -1){
        if((this.value.indexOf(item) != -1) || (this.value.indexOf(item.split('_')[1]) != -1)){
          return true;
        }else{
          return false;
        }
    }else{
      if(this.value && this.value.indexOf(item) != -1){
        return true;
      }else{
        return false;
      }
    }
  }

  changeStores(item, index) {  
    for(var i = 0; i < this.filter_items.length; i++) {    

      if(item == this.filter_label) {
        if(this.filter_items[i] != 'Remove All' && !this.filter_items[i].toLowerCase().startsWith("all")){
          if(this.value.indexOf(this.filter_items[i]) == -1) {
            this.value.push(this.filter_items[i]); 
          }
        }
      }
      else if(item == 'Remove All'){
        this.value = [];
      }else {

        if(item == this.filter_items[i]) {
          let index = this.value.indexOf(this.filter_items[i]);
          let fData = this.value.indexOf(this.filter_items[i].split('_')[1]);          
          if(fData != -1){
            this.value.splice(fData,1);
          }else if(index != -1){
            this.value.splice(index,1);
          }else{
            this.value.push(this.filter_items[i]);
          }
        }
      }
    }

    if(this.value.length == 0) {
      this.value = [];
    }

    return this.value;
  }

  sendData() {   
    this.sendValue.emit(JSON.stringify(this.value));   
    //this.showStAssign = false;
  }

}
