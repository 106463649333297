import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-assign-staff-modal',
  templateUrl: './assign-staff-modal.component.html',
  styleUrls: ['./assign-staff-modal.component.less']
})
export class AssignStaffModalComponent implements OnInit {
  placesToAssaign: any[] = [];
  staffList: any[] = [];
  unitsStr: string;
  decision: string;
  assigny: any=undefined;
  assignStaff: any = {};


  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
  }

  done(){
    if(!this.assigny || this.assigny=='undefined'){
      alert("Select any user");
      return;
    }
    this.modalRef.hide();
  }


}
