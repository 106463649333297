import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-gift-card-modal',
  templateUrl: './gift-card-modal.component.html',
  styleUrls: ['./gift-card-modal.component.less']
})
export class GiftCardModalComponent implements OnInit {
  decision: string;
  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
  }

}
