import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { APIHelper } from '../helpers/api-helper';
import { remove } from 'lodash-es';

@Component({
  selector: 'app-cancel-order-modal',
  templateUrl: './cancel-order-modal.component.html',
  styleUrls: ['./cancel-order-modal.component.less']
})
export class CancelOrderReasonModalComponent implements OnInit {
  decision: string = '';
  selectedReason:any=undefined;
  selectedReversePaymentType:any=undefined;
  reasonList:any;
  open_from:any;
  paymentTypes:any = [];
  is_only_save_to_diary_payment: boolean = false;
  constructor(public modalRef: BsModalRef) { }
  ngOnInit() {
    this.initData();
  }

  send(){

    if(!this.selectedReason){
      alert("Select any reason");
      return;
    }
    this.decision = "send";
    this.modalRef.hide();
  }
  
  initData() {
    let tempPaymentSubClassTypes = [];    
    var tempPaymentTypes = JSON.parse(localStorage.getItem('payment_types')) || null;
    var shop_settings = JSON.parse(localStorage.getItem("shop_configuration"));
    let paymentTypes = shop_settings.payment_types;
    let payment_sub_class = shop_settings.payment_class;

    if(tempPaymentTypes && tempPaymentTypes[0]){      
        let finalList:any = [
          { name: 'CASH',sub_class: [],code:APIHelper.CASH_IN_HAND_TYPE},
          {name: 'CARD',sub_class: [],code:APIHelper.CARD_PAYMENT_TYPE}
        ];

        tempPaymentTypes.forEach((type)=>{
          if([APIHelper.SAVE_TO_DIARY_TYPE,APIHelper.CASH_IN_HAND_TYPE,APIHelper.CASH_ON_DELIVERY_TYPE].indexOf(type.payment_type_code) == -1 && 
              paymentTypes.indexOf(type.payment_type_code) != -1){

            let detail = {name:type.payment_type,code:type.payment_type_code,sub_class:[]};            
            finalList.push(detail);
          }
        });
        this.paymentTypes = finalList;
    }

    if(payment_sub_class) {
      this.paymentTypes.forEach(payment_type => {
        for(var x in payment_sub_class) {          
          if(payment_type.code == x){              
              payment_type.sub_class = payment_sub_class[x];
          }
         }
      });
    }

    var removeMainPaymentType = []
    this.paymentTypes.forEach(payment_type => {
      if(payment_type.sub_class && payment_type.sub_class.length>0) {

        let count = 0;
        payment_type.sub_class.forEach(sub_class => {          
          if(sub_class.is_active){
            removeMainPaymentType.push(payment_type.code);
            tempPaymentSubClassTypes.push({
              name:sub_class.name.toUpperCase(),
              type:payment_type.name,
              is_sub_class: true,            
              code:payment_type.code            
            });
          }
        });
      }      
    });

    this.paymentTypes = this.paymentTypes.concat(tempPaymentSubClassTypes);

    remove(this.paymentTypes, pt=> { return (removeMainPaymentType.indexOf(pt.code) != -1 && !pt.is_sub_class); });
  }

}

