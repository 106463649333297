/**
 * (c) OneGreenDiary Software Pvt. Ltd.
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform,
 *    solely reside with the third-party.  
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Once OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without
 *    prior agreement with OneGreenDiary Software Pvt. Ltd.
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';



import { APIHelper } from '../api-helper';

@Injectable()
export class DiaryService {
    constructor(private http: Http) { }

    saveToDairy(order_id:string,auth_token:string, comment:string, payment_details:any,
        diary_amount:any,is_clear:boolean,shop_code:string,diary_reference_code:any,shop_user_id:any) {
        var reqData = {
            'shop_code':shop_code,
            'diary':{
                'order_id': order_id,
                'auth_token': auth_token,
                'comment': comment,
                'payment_details': payment_details,
                'diary_amount':diary_amount,
                'diary_reference_code':diary_reference_code,
                'is_clear': is_clear,
                'shop_code': shop_code,
                'shop_user_id': shop_user_id
            }
        };
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_SAVE_TO_DIARY, reqData);
    }

    diaryDetail(auth_token:string, from_date:string, to_date:any,shop_code:string) {
        var reqData = {
            'shop_code':shop_code,
            'from_date':from_date,
            'to_date':to_date,
            'diary':{
                'auth_token': auth_token,
            }
        };      
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_GET_DIARY_DETAIL, reqData);
    }
}
