import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { StateGuardService } from '../state-guard.service';
import { PaymentComponent } from '../payment-component/payment.component';
import { Subscription } from 'rxjs';
import { Messenger } from '../messenger.service';

@Component({
  selector: 'app-screen-keys',
  templateUrl: './screen-keys.component.html',
  styleUrls: ['./screen-keys.component.less'],
  providers:[PaymentComponent]
})
export class ScreenKeysComponent implements OnInit {

  currencySymbol:any;
  digitalKeysModel = [{face: "1", id: "one"},{face: "2", id: "two"},
                      {face: "3", id: "three"},{face: "4", id: "four"},
                      {face: "5", id: "five"},{face: "6", id: "six"},
                      {face: "", innerAction: "backspace", id: "backspace"},
                      {face: "7", id: "seven"},{face: "8", id: "eight"},
                      {face: "9", id: "nine"},{face: "0", id: "zero"},
                      {face: "."},
                      {face: "", innerAction: "refresh", id: "refresh"}];

  alphaKeysModel = [{face: "q"},{face: "w"},{face: "e"},{face: "r"},
                    {face: "t"},{face: "y"},{face: "u"},{face: "i"},
                    {face: "o"},{face: "p"},{face: "a"},{face: "s"},
                    {face: "d"},{face: "f"},{face: "g"},{face: "h"},
                    {face: "j"},{face: "k"},
                    {face: "", innerAction: "backspace", id: "backspace"},
                    {face: "l"},{face: "z"},{face: "x"},{face: "c"},
                    {face: "v"},{face: "b"},{face: "n"},{face: "m"},
                    {face: ".", id: "dot"},
                    {face: "", innerAction: "refresh", id: "refresh"}];

  public keysModel = this.digitalKeysModel;
  private case: boolean = true;
  message: any = {text: ''};
  subscription: Subscription;
  @Input("kstate") state: any;
  payedEnough: boolean;
  billPressed: boolean = false;
  @Output("key-press") keyPress: EventEmitter<any> = new EventEmitter()
  @Output("change-value") changeValue: EventEmitter<any> = new EventEmitter()
  constructor(private paymentComponent:PaymentComponent, private stateGuard: StateGuardService, private messageService: Messenger) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
      this.payedEnough = parseFloat(message.text) <= 0;
    });
 }
  press (key) {
    var input = this.stateGuard.obtain("screen-keys.directive:currentInput"),
        value = input ? input.value : '',
        position = input ? this.getCaretPosition(input) : 0,
        incomingValue = this.case ? key.face : key.face.toUpperCase();
      
    if (key.action) {
      if(key.action == 'bill' || key.action == 'print'){
        this.billPressed = true;
      }
      this.keyPress.emit(key);
    } 
    if (key.innerAction === "abc") {
      this.keysModel = this.keysModel === this.alphaKeysModel ? this.digitalKeysModel : this.alphaKeysModel;
    }
    if (key.innerAction === "caps") {
      this.case = !this.case;
    } 
    if (input) {
      if (key.innerAction === "backspace") {
        input.value = value.substring(0, position - 1) + value.substring(position, value.length);
        input.setSelectionRange(position - 1, position - 1);
      } else if (key.innerAction === "boom") {
        input.value = key.value;
      } else if (key.innerAction === "refresh") {
        input.value = "";
      } else {
        input.value = value.substring(0, position) + incomingValue + value.substring(position, value.length);
        input.setSelectionRange(position + 1, position + 1);
      }
      this.changeValue.emit(input.value);
      input.focus(); 
    }
  }

  print(){
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.payedEnough = parseInt(message.text) <= 0;
    });
    if(this.payedEnough){
      this.paymentComponent.reactOnKeys({face: '', action: 'bill'});
    }
  }
  printBill(){
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.payedEnough = parseInt(message.text) <= 0;
    });
    if(this.payedEnough){
      this.paymentComponent.reactOnKeys({face: '', action: 'print'});
    }
  }

  ngOnInit() {
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shop_config.currency;
  }

  private getCaretPosition (oField) {
    var iCaretPos = 0;

    if (document["selection"]) {
      oField.focus();
      var oSel = document["selection"].createRange();
      oSel.moveStart('character', -oField.value.length);
      iCaretPos = oSel.text.length;
    } else if (oField.selectionStart || oField.selectionStart == '0') {
      iCaretPos = oField.selectionStart;
    }

    return iCaretPos;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
