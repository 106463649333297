import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-offers-modal',
  templateUrl: './offers-modal.component.html',
  styleUrls: ['./offers-modal.component.less']
})
export class OffersModalComponent implements OnInit {
  decision: string;
  public offers = [{
    name: "Pasta La Pizza",
    subTitle: "Traditional Italian Pasta",
    date: new Date().valueOf(),
    selected: false
  },{
    name: "Pizza Hut",
    subTitle: "Any Large Pizza",
    date: new Date().valueOf(),
    selected: false
  },{
    name: "Natural",
    subTitle: "Any Ice Cream",
    date: new Date().valueOf(),
    selected: false
  },{
    name: "Chocolate Biclate",
    subTitle: "Chocolate Cake",
    date: new Date().valueOf(),
    selected: false
  },{
    name: "Starbucks",
    subTitle: "Crene Egg Milkshake",
    date: new Date().valueOf(),
    selected: false
  }];
  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
  }

}
