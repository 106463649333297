import { Injectable, Inject } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { Http, Headers } from '@angular/http';
import { APIHelper } from '../api-helper';


@Injectable()
export class CashRegisterService {

    constructor(private http:Http) {}

    cashIn(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CASH_IN, data);
    }
    
    generateReport(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CASH_REGISTER_GENERATE_REPORT, data);
    }

    cashOut(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CASH_OUT, data);
    }

    saveReport(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CASH_REGISTER_SAVE_REPORT, data);
    }

    saveStockReport(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_SAVE_STOCK_REPORT, data);
    }

    getCashRegisterReportList(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CASH_REGISTER_REPORT_LIST, data);
    }

    getCashRegisterList(data: any) {
        return APIHelper.getInstance(this.http).apiCall(APIHelper.API_CASH_REGISTER_LIST, data);
    }
    

}