import { Component, Input, OnInit, HostListener,AfterContentChecked, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';
import { APIHelper } from './helpers/api-helper';
import { StateGuardService } from './state-guard.service';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { BasketComponent } from './basket/basket.component';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CommonService } from './helpers/services/common.service';
import { CustomerService } from './helpers/services/customer.service';
import { ConfigsBoxComponent } from './configs-box/configs-box.component';
import { PaymentComponent } from './payment-component/payment.component';
import { CustomerMessage } from './customer-message.service';
import { UpdateInventoryMessage } from './update-inventory-message.service';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { PaymentHelper } from './helpers/payment-helper';
import { SaveOrderService } from './helpers/services/save-order.service';
import { MakePaymentService } from './helpers/services/make-payment.service';
import { DiaryService } from './helpers/services/diary.service';
import { CashRegisterService } from './helpers/services/cash-register.service';
import { SpecialOrderService } from './helpers/services/special-order.service';
import { ShopLoginService } from './helpers/shopLogin.service';

import { PlacesMessage } from './places-message.service';
import { HoldOrderModal } from './hold-order-modal/hold-order-modal.component';
import { LicenseRenewModalComponent } from './license-renew-modal/license-renew-modal.component';
import { CaptainAppService } from '../pages/helpers/services/captain-app.service';
import { SelectsBoxComponent } from './selects-box/selects-box.component';
import { CaptainAppHelper } from './helpers/captain-app-helper';
import { FirebaseMessagingService } from './helpers/services/firebase-messaging.service';
import {BaseModalComponent} from './helpers/base-modal.component';
import { ConfirmationModal } from './confirmation-modal/confirmation-modal.component';
/*import { RecommenderService } from './helpers/services/recommender.service'*/
import { ThirdPartyOrderService } from './helpers/services/third-party-orders.service';
import { RecentOrdersService } from './helpers/services/recent-orders.service';
import { ThirdPartyDeliveryTimeModalComponent } from './third-party-delivery-time-modal/third-party-delivery-time-modal.component';
import { ThirdPartyRejectOrderReasonModalComponent } from './third-party-reject-order-reason-modal/third-party-reject-order-reason-modal.component';
import { EnterDiaryModalComponent } from './enter-diary-modal/enter-diary-modal.component';
import { DiaryDetailModalComponent } from './diary-detail-modal/diary-detail-modal.component';
import { BaseConfig } from './helpers/api-config';
import { findLast } from 'lodash-es';
import { OpenCashRegisterModalComponent } from './open-cash-register-modal/open-cash-register-modal.component';

import { WebSocketService } from './helpers/services/websocket.service';

declare var $:any;

@Component({
  selector: 'pages-root',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent extends BaseModalComponent  implements OnInit, AfterContentChecked  {
  isLoadingShow : boolean = false;
  title = 'app';
  delType: number = 1;
  msgs: Message[] = [];
  notificationList: any = [];
  showSB: boolean = false;
  currencySymbol:any;
  notification:any;
  isUpdateAvailable: boolean = false;
  isNotificationShow: boolean = false;
  isItFoodOrderingPage: boolean = false;
  remainingDays: number = 0;
  shopConfig: any;
  paymentHelper: PaymentHelper;
  selectedDeliveryType:any;
  subscription: Subscription;
  third_party_new_order_count: number = 0;
  audio:any = new Audio();  
  show_order_popup: boolean = false;
  selectedOrderDetails: any;
  pendingOrderList: any = [];
  screenIdleTimeoutTimerObj: any;
  constructor(private route: ActivatedRoute,
    private stateGuard: StateGuardService,
    private commonService: CommonService,
    private customerService:CustomerService,
    private updateInventoryService:UpdateInventoryMessage,
    private customerMessageService: CustomerMessage,
    private configsBoxComponent:ConfigsBoxComponent,
    private messageService: MessageService,
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private basketComponent:BasketComponent,
    private saveOrderService: SaveOrderService,
    private makePaymentService: MakePaymentService,
    private diaryService: DiaryService,
    private cashRegisterService: CashRegisterService,
    private specialOrderService:SpecialOrderService,    
    private placesMessage:PlacesMessage,
    private captainAppService:CaptainAppService,
    private paymentComponent: PaymentComponent,
    private selectBoxComponent: SelectsBoxComponent,
    private captainAppHelper: CaptainAppHelper,
    private msg: FirebaseMessagingService,
    private zone: NgZone,
    /*private cdr: ChangeDetectorRef,*/
    /*private recommenderService: RecommenderService,*/
    private _thirdPartyOrderService: ThirdPartyOrderService,
    private recentOrdersService:RecentOrdersService,
    private shopLoginService:ShopLoginService,
    private router: Router,
    private webSocketService: WebSocketService) {
    super(modalRef);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        let index = event.url.indexOf('food-ordering');
        if(index == -1){
          this.isItFoodOrderingPage =false;
        }else{
          this.isItFoodOrderingPage =true;
        }
      }
    });
  }
  defaultVisitor = {
    auth_token : APIHelper.DEFAULT_TOKEN,
    consumer_name: "",          
    lastVisit: "",
    lastCheck: 0,
    lastCheckCurrency: "INR",
    email: "",
    mobile_no: "",
    points: 0,
    diary_amt:0,
    place: "here",
    distance: '',
    places : [
      {id: 1, name: 'here'},
      {id: 2, name: 'home'},
      {id: 3, name: 'pickup'}
    ]
  }
  visitorArtefacts:any= JSON.parse(JSON.stringify(this.defaultVisitor));   
  public subscriptions : Subscription[] = [];
  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  validateLicense(){
    if(navigator.onLine) {
      let shop_code= localStorage.getItem('shop_code');
      this.commonService.validateLicense(shop_code).subscribe( (res) => {
        let result = res.json();
        var currDate = new Date();
        localStorage.setItem('license_renew_update_date',currDate.toISOString());
        if (result.status === 0 && result.result.merchant_licence[0]) {
          this.remainingDays = result.result.merchant_licence[0].remainingDays + 1;
          let month_wise_subscription_value = result.result.merchant_licence[0].month_wise_subscription_value ;
          let annual_subscription_value = result.result.merchant_licence[0].annual_subscription_value;
          let monthly_subscription_value = result.result.merchant_licence[0].monthly_subscription_value;

          if(this.remainingDays <= 7){
            this.showModalForLicenseRenew({days:this.remainingDays,month_wise_subscription_value:month_wise_subscription_value,annual_subscription_value:annual_subscription_value,monthly_subscription_value:monthly_subscription_value});
          }
        }
      });
    }
  }

  getRecommendationModel(){
		console.log("checkForgetRecommendationModel")

		let license_renew_update_date = localStorage.getItem('license_renew_update_date');
		if(license_renew_update_date){
		  let lastUpdateDate = new Date(license_renew_update_date);
		  let date = new Date()
		  var dd   = date.getDate()+'';
		  var mm   = (date.getMonth()+1)+'';
		  var yyyy = date.getFullYear();
		  if(dd.length == 1)  { dd='0'+dd } 
		  if(mm.length == 1)  { mm='0'+mm }
		  let str = yyyy+'/'+mm+'/'+dd;
		  let curr_date = new Date(str);
	
		  if(lastUpdateDate < curr_date){
			/*this.recommenderService.getmodel();*/
		  }
		}
              // send websocket message to get tables
              setTimeout(() => this.webSocketService.sendMessage({ type: 'get_tables' }) 
              , 2000);
	  }

  showModalForLicenseRenew(data){
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'skip') {
      }else if(this.modalRef.content.decision === 'yes'){
        this.remainingDays = this.modalRef.content.remainingDays;
        if(this.remainingDays <= 0){
          this.router.navigate(['']);
        }
      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(LicenseRenewModalComponent, {class: 'common-modal',keyboard: false,
        backdrop: true,ignoreBackdropClick: true});
    this.modalRef.content.decision = "";
    this.modalRef.content.remainingDays = data.days;
    this.modalRef.content.month_wise_subscription_value = data.month_wise_subscription_value;
    this.modalRef.content.annual_subscription_value = data.annual_subscription_value;
    this.modalRef.content.monthly_subscription_value = data.monthly_subscription_value;

  }


  ngOnInit() {

    let self = this;
    self.getCustomerBasicInfo().then(result => {}).catch(error => {});

    if (localStorage.getItem('shop_configuration')) {
      this.shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
      this.currencySymbol = this.shopConfig.currency;
    }

    $(document).ready(function() {
        self.setIdleScreenTimeout();
        $(document).click(function(event){                    
          self.setIdleScreenTimeout();
          if($("#diary_options")) {
            $("#diary_options").css("display","none");
          }
        });
        
        $("#diary_amt_wrapper").click(function(e){     
          e.stopPropagation();
        });        
    });

    this.getThirdPartyNewOrdersCount();
    this.validateLicense();
    if(navigator.onLine){
      this.getRecommendationModel();


    }
    

    this.msg.listenNotification();
    this.subscription = this.msg.getNotification().subscribe(message => {
      let data = JSON.parse(JSON.stringify(message));
      if(data && data.notification && data.notification.notification){
        if(data.notification && data.notification.data && ["NOTIFY_MERCHANT_NEW_ORDER"].indexOf(data.notification.data.event_type) != -1) {
          this.pendingOrderList.push(data.notification.data.order_id);
          if(!this.show_order_popup) {
            this.getOrderDetail(data.notification.data.order_id);
          }
        } else {
          self.isNotificationShow = true;
          self.notificationList.unshift( data.notification);                      
          var timerForNotification = setTimeout(()=>{
            clearTimeout(timerForNotification);          
            this.removeNotification(null);          
          },10000);
        }
        this.audio.src = "../../assets/sounds/bell_2.mp3";
        this.audio.load();
        this.audio.play();
      }
    });

    this.subscription = this.captainAppHelper.getCaptainAppNotification().subscribe(message => {
      let data = JSON.parse(JSON.stringify(message));
      if(data && data.notification && data.notification.notification){
        self.isNotificationShow = true;
        self.notificationList.unshift( data.notification);        
        let localConfig = JSON.parse(localStorage.getItem('local_configuration')) || {};
        if(!localConfig || localConfig.disable_captain_notifications_sound != true) {
          this.audio.src = "../../assets/sounds/captain_app_checkout_bell.mp3";
          this.audio.load();
          this.audio.play();
        }
        var timerForNotification = setTimeout(()=>{
          clearTimeout(timerForNotification);          
          this.removeNotification(null);          
        },10000);
      }
    });

    // if (this.shopConfig.enable_tables) {
    //   this.stateGuard.entrust("selects-box.component:currentUnit", this.hardcodedUnit);
    // } else {
      let device_type = JSON.parse(localStorage.getItem('device_key'));              
      let terminal_name = (device_type && device_type.terminal_name)? device_type.terminal_name: null;
      let teminal_id  = (device_type && device_type.terminal_id)? device_type.terminal_id: null;            
      let temp_client_reference_code = terminal_name+"-"+teminal_id+'/WBP' + (new Date()).getTime();

      this.hardcodedUnit2["visitors"][0]["temp_client_reference_code"] = temp_client_reference_code;
      this.stateGuard.entrust("selects-box.component:currentUnit", this.hardcodedUnit2);
    // }

    this.subscription = this.customerMessageService.getCustomerInfo().subscribe(message => {       
     
      if(message.is_partial_change) {
        let tempCI = JSON.parse(JSON.stringify(message.CustomerInfo));
        for(var x in tempCI) { this.visitorArtefacts[x] = tempCI[x]; }        
      } else {
        this.visitorArtefacts = JSON.parse(JSON.stringify(message.CustomerInfo));
      }
      this.selectedDeliveryType = this.visitorArtefacts.place;
    });


    this.subscription = this.updateInventoryService.getUpdateAvailableInfo().subscribe(message => {
      this.isUpdateAvailable = JSON.parse(JSON.stringify(message.UpdateInfo));
    });

    this.paymentHelper = PaymentHelper.getInstance(this.shopConfig,this.customerService, this.saveOrderService, this.makePaymentService, this.diaryService,this.cashRegisterService,this.specialOrderService);    

    this.stateGuard.entrust('basket.component:offUnits', []);  
    $(document).ready(function() {
        window.onbeforeunload = function() {
          let currentUnit:any = self.stateGuard.obtain("selects-box.component:currentUnit") || [];    
          if(currentUnit) {
            let tempPlace = self.stateGuard.obtain('selects-box.component:places');
            let isRestaurant = localStorage.getItem('isRestaurant');
            let items: any = [];
            if(currentUnit.visitors && currentUnit.visitors[0].dishes[0].length>0){ 
                currentUnit.visitors.forEach((seat, index) => {      
                  if(seat.dishes && seat.dishes[0]) {
                    items = items.concat(seat.dishes[0]);
                  }            
                }); 
            }       

            //for put order on old in case of not a restaurant or order is parked
            ///put on hold
            let tempUnit = JSON.parse(JSON.stringify(currentUnit));
            self.stateGuard.amend('basket.component:offUnits', function (offUnits) {
              if(items && items.length != 0 && (isRestaurant == 'false' || tempUnit.isParked) && !tempUnit.isOnHold) {               
                tempUnit.isOnHold = true;
                tempUnit.isFromOnHold = false;
                tempUnit.isParked = false;
                offUnits.push(tempUnit);
              }             
              localStorage.setItem('offUnits',JSON.stringify(offUnits));
            });        
              
        
            //for save current order in case of restaurant
            if(items && items.length != 0 && isRestaurant == 'true' && !currentUnit.isOnHold && !currentUnit.isParked){        
              //save order
              let tempUnit = JSON.parse(JSON.stringify(currentUnit));        
              tempUnit.isOnHold = false;
              tempUnit.isFromOnHold = true;        
              tempUnit.isParked = false;        
              localStorage.setItem('saved_order',JSON.stringify(tempUnit));        
              localStorage.setItem('is_page_refresh','true');        
            } else {
              localStorage.setItem('saved_order',"");
            }                   
            
            //for save current places is case of restaurant
            if(isRestaurant  == "true" && tempPlace) {
              localStorage.setItem('saved_places',JSON.stringify(tempPlace));
              
            } 
          }
        }

        window.onload = function() {
          if(!navigator.onLine){
            self.router.navigate(['verify-pin']);
          }else{

            let tempOffUnits = localStorage.getItem('offUnits');
            if(tempOffUnits) {
              tempOffUnits = JSON.parse(tempOffUnits);
            }
            if(Array.isArray(tempOffUnits) && tempOffUnits.length >0){        
              self.stateGuard.entrust('basket.component:offUnits',tempOffUnits);
            }
            if(localStorage.getItem('isRestaurant') == 'true'){
              let tempPlace = JSON.parse(localStorage.getItem('saved_places'));
              
              if(tempPlace) {
                self.stateGuard.entrust("selects-box.component:places",tempPlace);
                self.placesMessage.sendPlacesInfo(tempPlace);

              }
              if(localStorage.getItem('saved_order')){
                let saved_order = JSON.parse(localStorage.getItem('saved_order'));
                self.stateGuard.entrust("selects-box.component:currentUnit",saved_order);            
              } else {
                setTimeout(function(){
                  //self.paymentComponent.clearCustomerInfo(false);
                },1000)
                //self.router.navigate(['/pages/register/place-selection/here']);    
              }          
            } else {
              setTimeout(function(){
                //self.paymentComponent.clearCustomerInfo(false);
              },1000)
              //self.router.navigate(['/pages/register/place-selection/here']);     
            }
          }    
        }
    });  
    if(localStorage.getItem('isRestaurant') != 'false') {
      setTimeout(function(){
        self.captainAppHelper.getCaptainTableList();       //TODO: Calling captain app table list (Websocket ui logic will hit)
      },2000)
    }

    let _outscope = this;
    var sessionID = localStorage.getItem("session_id");
    setInterval(function() {
      _outscope.shopLoginService.getUser({session_id: sessionID})
				.subscribe(res => {
					if(JSON.parse(res["_body"]).status == 0) {
            _outscope.logout();
					}
				}, err => { console.log(err); });
    }, 300000)
  }

  ngAfterContentChecked() {
    //this.cdr.detectChanges();
  }

  logout(){
    var self = this;
    let loggedinUser = JSON.parse(localStorage.getItem('loggedInUser'));
    let id = JSON.parse(localStorage.getItem('device_key'));
    let terminal_id = (id && id.terminal_id)? id.terminal_id : 0;
    let sendData = {
      'user_name':(loggedinUser && loggedinUser.user_name)? loggedinUser.user_name : null,
      'shop_code':localStorage.getItem('shop_code'),
      'terminal_id': (typeof terminal_id == 'string')? parseInt(terminal_id) : terminal_id,
      'push_record_id':localStorage.getItem('push_record_id')
    }

    this.shopLoginService.logout(sendData).subscribe((res) => {
        var apiRes = res.json();
        if (apiRes.status === 0) {
          this.selectBoxComponent.createTableSections().then( (flag) =>{
            localStorage.clear();
            setTimeout(() => {
              self.stateGuard.clear();
              window.location.reload();
            },1000);
            //localStorage.setItem('resetPlaces','true');
            this.router.navigate(['login']);
          });
        } else {
          console.log(apiRes.message);
          this.router.navigate(['login']);
        }
    });
  }

  setIdleScreenTimeout() {
    if(this.screenIdleTimeoutTimerObj) {
      clearTimeout(this.screenIdleTimeoutTimerObj);
      this.screenIdleTimeoutTimerObj = null;
    }

    let idleScreenTimeout:any = localStorage.getItem("idle_screen_timeout");
    if(idleScreenTimeout) {
      let idstScreen = parseInt(idleScreenTimeout);
      if(idstScreen>0) {
        this.screenIdleTimeoutTimerObj = setTimeout(() => {
          if(window.location && window.location.href.indexOf("verify-pin") == -1) {
            this.zone.run(() => {
              this.router.navigate(['verify-pin']);
            });
          }
        },idstScreen*1000);
      }
    }    
  }

  onDiaryClick() {
    if(!navigator.onLine) {
      this.messageService.add({ severity: 'error', summary: "Can't use this functionality in offline", detail: '' });                  
      return
    }

    if(this.visitorArtefacts && this.visitorArtefacts.auth_token && this.visitorArtefacts.auth_token != "00000000000000000000000000000000") {
      $("#diary_options").css("display","table");    
    }
  }

  onClickViewDiary() {    
    if(!navigator.onLine) {
      this.messageService.add({ severity: 'error', summary: "Can't use this functionality in offline", detail: '' });                  
      return
    }

    if(this.visitorArtefacts && this.visitorArtefacts.auth_token && this.visitorArtefacts.auth_token != "00000000000000000000000000000000") {
      this.modalRef = this.modalService.show(DiaryDetailModalComponent,{ class: 'common-modal diary-detail-modal' });
      this.modalRef.content.decision = "";
      this.modalRef.content.customer_auth_token = JSON.parse(JSON.stringify(this.visitorArtefacts.auth_token));
    } else {
      this.messageService.add({ severity: 'error', summary: 'Do not found register number.', detail: ' Please first register a number' });            
    }
  }



  onClickClearDiary() {
    if(!navigator.onLine) {
      this.messageService.add({ severity: 'error', summary: "Can't use this functionality in offline", detail: '' });                  
      return
    }

    this.openCashRegister().then((result:any) => {
      if(!result) {
        this.messageService.add({ severity: 'error', summary: 'Open cash register.', detail: ' Please first add opening balance for cash register' });                    
        return;
      }

      if(this.visitorArtefacts && this.visitorArtefacts.auth_token && this.visitorArtefacts.auth_token != "00000000000000000000000000000000") {
        this.modalRef = this.modalService.show(EnterDiaryModalComponent,{ class: 'centred-modal' });
        this.modalRef.content.decision = "";
        this.modalRef.content.customer_auth_token = JSON.parse(JSON.stringify(this.visitorArtefacts.auth_token));
        this.modalRef.content.type = "clear_diary";      
        let cSub:Subscription = this.modalService.onHide.subscribe(() => {
          if (this.modalRef.content.decision === 'done') {
            this.visitorArtefacts.diary_amt = this.modalRef.content.updated_diary_amount;
            this.customerMessageService.sendCustomerInfo(JSON.parse(JSON.stringify(this.visitorArtefacts)),false);
            let currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');              
            if(currentUnit) { currentUnit.userInfo = JSON.parse(JSON.stringify(this.visitorArtefacts)); }
          }
          cSub.unsubscribe();
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Do not found register number.', detail: ' Please first register a number' });            
      }
    })
  }

  onClickAddAdvance() {
    if(!navigator.onLine) {
      this.messageService.add({ severity: 'error', summary: "Can't use this functionality in offline", detail: '' });                  
      return
    }

    this.openCashRegister().then((result:any) => {
      if(!result) {
        this.messageService.add({ severity: 'error', summary: 'Open cash register.', detail: ' Please first add opening balance for cash register' });                    
        return;
      }

      if(this.visitorArtefacts && this.visitorArtefacts.auth_token && this.visitorArtefacts.auth_token != "00000000000000000000000000000000") {
        $("#diary_options").css("display","none");
        this.modalRef = this.modalService.show(EnterDiaryModalComponent,{ class: 'centred-modal' });
        this.modalRef.content.decision = "";
        this.modalRef.content.customer_auth_token = JSON.parse(JSON.stringify(this.visitorArtefacts.auth_token));
        this.modalRef.content.type = "advance";
        let cSub:Subscription = this.modalService.onHide.subscribe(() => {
          if (this.modalRef.content.decision === 'done') {
            this.visitorArtefacts.diary_amt = this.modalRef.content.updated_diary_amount;
            this.customerMessageService.sendCustomerInfo(JSON.parse(JSON.stringify(this.visitorArtefacts)),false);
            let currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');              
            if(currentUnit) { currentUnit.userInfo = JSON.parse(JSON.stringify(this.visitorArtefacts)); }
          }
          cSub.unsubscribe();
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Do not found register number.', detail: ' Please first register a number' });            
      }
    })
  }

  onClickPushNotification(event,notification) {
    if(event && event.target && ["accept_btn","reject_btn"].indexOf(event.target.id) != -1) {
      return;
    } else {
      this.removeNotification(notification);
      if(notification && notification.data && ["NOTIFY_MERCHANT_NEW_ORDER"].indexOf(notification.data.event_type) != -1) {
        this.router.navigate(['/pages/recent-orders',{
          "order_id":notification.data.order_id,
          "event_type":notification.data.event_type
        }]);
      }
    }
  }

  getOrderDetail(order_id) {    
    let sendData = {
      'shop_code':localStorage.getItem('shop_code'),
      'order_id':order_id,
      'getLocalizeName':true
    }

    this.recentOrdersService.getSelectedOrderInfo(sendData).subscribe((res) => {
      let ordersData = res.json();
      if(ordersData.result){
        let shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
        if(typeof ordersData.result.order.comment == 'string' || ordersData.result.order.nocharge_comment){              
          if(ordersData.result.order.comment || ordersData.result.order.nocharge_comment){                
            if(ordersData.result.order.nocharge_comment) {
              ordersData.result.order.comment = {noChargeNote:ordersData.result.order.nocharge_comment};
              if(ordersData.result.order.employee_id && ordersData.result.order.employee_id != -1) {
                ordersData.result.order.comment["employeeId"] = ordersData.result.order.employee_id;
              }
            } else {
              try {
                ordersData.result.order.comment = (ordersData.result.order.comment) ? JSON.parse(ordersData.result.order.comment) : "";
              } catch(e) {
                ordersData.result.order.comment = "";
              }
            }
            
            if(ordersData.result.order.comment.employeeId){
              if(localStorage.getItem('shop_user_list')){
                let userList = JSON.parse(localStorage.getItem('shop_user_list'));
                for (var i = 0; i < userList.length; ++i) {
                  if(userList[i].id.toString() == ordersData.result.order.comment.employeeId.toString()){
                    ordersData.result.order.comment['emp_name'] = userList[i].name;
                    break;
                  }
                }
              }
            }
          }else{
            ordersData.result.order.comment = null;
          }              
        }else{
          ordersData.result.order.comment = (ordersData.result.order.comment);
        }
        let total_item_count = 0;
        let data = {
          'order':ordersData.result.order,
          'user':(ordersData.result.user.length>0)? ordersData.result.user[0] : {},
          'items':(ordersData.result.items.length>0)? ordersData.result.items : [],
          'discount_info':(ordersData.result.discount_info.length>0)? ordersData.result.discount_info : [],
          'diary_history':(ordersData.result.diary_history.length>0)? ordersData.result.diary_history : [],
          'rider_data':(ordersData.result.rider_data)? ordersData.result.rider_data : null
        }


        let groupTaxes = [];
        data.items.forEach(function(item : any) {
          total_item_count += (item.item_count) ? item.item_count : 0;
          item.tax_breakup.forEach(function(f : any) {
            var present = false;
            for(var calcTax of groupTaxes) {
              if(calcTax !== undefined) {
                if(f['breakup_name'] === calcTax['breakup_name'] && f['rate'] === calcTax['rate']){
                  present = true;
                  if(!ordersData.result.order.is_order_cancelled){
                    calcTax['value'] = calcTax['value'] + f['value'];
                  }
                }
              }
            }
            if(!present) {
              groupTaxes.push(JSON.parse(JSON.stringify(f)));
            }
          });
        });

        ordersData.result.total_item_count = total_item_count;
      
        data.order.group_tax = groupTaxes;
        if(!Array.isArray(data.order.tax_breakup) || data.order.tax_breakup.legnth==0) {
          data.order.tax_breakup = groupTaxes;  
        }            

        if(data.user.profile_img_url){
          data.user.profile_img_url = BaseConfig.config.API+data.user.profile_img_url;
        }

        data.user.phone = (data.user.alt_address && data.user.alt_address.length>0)? (data.user.alt_address[data.user.alt_address.length - 1].contact_no) : (data.user.phone) ? data.user.phone  : null ;
        data.user.address = (data.user.alt_address && data.user.alt_address.length>0)? (data.user.alt_address[data.user.alt_address.length - 1].address) : null;

        ordersData.result.diary_history.forEach(data=>{
          if(data.comment == 'credit' || data.comment == 'credit_due'){
            data['title'] = 'Credit Due Amount';
          }else if(data.comment == 'credit_void'){
            data['title'] = 'Credit Void';
          }else if(data.comment == 'used'  || data.comment == 'used_due'){
            data['title'] = 'Pay from diary';
          }else if(data.comment == 'used_void'){
            data['title'] = 'Pay from diary void';
          }else if(data.comment == 'outstanding' || data.comment == 'outstanding_due'){
            data['title'] ="Outstanding Amount";
          }else if(data.comment == 'advance' || data.comment == 'advance_due'){
            data['title'] ="Advance Amount";
          }else{
            data['title'] = data.comment;
          }
        })

        let payment_list = [];
        if(data.order.split_payment_details){
          for (var key in data.order.split_payment_details) {
            if (data.order.split_payment_details.hasOwnProperty(key)) {

              if(Array.isArray(data.order.split_payment_details[key].payment_sub_class_info) && data.order.split_payment_details[key].payment_sub_class_info.length>0){
                data.order.split_payment_details[key].payment_sub_class_info.forEach( data =>{
                  let tempPaymentDetail ={
                    title:data.sub_class_name,
                    amount:data.amount,
                    payment_code:key
                  }
                  if(key != APIHelper.SAVE_TO_DIARY_TYPE){
                    payment_list.push(tempPaymentDetail);
                  }
                });
              }else {
                let obj ={
                  title:data.order.split_payment_details[key].name,
                  amount:data.order.split_payment_details[key].amount,
                  payment_code:key
                }
                if(key != APIHelper.SAVE_TO_DIARY_TYPE){
                  payment_list.push(obj);
                }
              }
            }
          }
        }

        let is_clear_dairy_amount = false;
        ordersData.result.diary_history.forEach(data=>{
          if(data.comment == 'clear'){
            is_clear_dairy_amount = true;
            let list = data.payment_details;
            if(list){
              for (var key in list) {
                if (list.hasOwnProperty(key)) {
                  if(Array.isArray(list[key].payment_sub_class_info) && list[key].payment_sub_class_info.length>0){
                    list[key].payment_sub_class_info.forEach( data =>{
                      let tempPaymentDetail ={
                        title:data.sub_class_name,
                        amount:data.amount,
                        payment_code:key
                      }

                      let is_found = false;
                      payment_list.forEach(payment=>{
                        if(payment.title == tempPaymentDetail.title){
                          payment.amount += tempPaymentDetail.amount;
                          is_found = true;
                        }
                      })

                      if(!is_found){
                        payment_list.push(tempPaymentDetail);
                      }
                    });
                  }else {
                    let obj ={
                      title:list[key].name,
                      amount:list[key].amount,
                      payment_code:key
                    }

                    let is_found = false;
                    payment_list.forEach(paymentData=>{
                      if(paymentData.title == obj.title){
                        paymentData.amount += obj.amount;
                        is_found = true;
                      }
                    })
                    if(!is_found){
                      payment_list.push(obj);
                    }
                  }
                }
              }
            }
          } 
        })

        if(is_clear_dairy_amount){
          ordersData.result.diary_history.forEach(data=>{
            if(data.comment == 'used'){
              let obj ={
                title:'Pay from diary',
                amount:data.diary_amount,
                payment_code:'PAY_FROM_DAIRY'
              }
              payment_list.push(obj);
            }
          })
        }

        let assignToId = data.order.shop_user_id;
        if(assignToId == -1){
          data.order['assignTo'] = null;
        }else{
          let shop_user_list = JSON.parse(localStorage.getItem('shop_user_list'));
          for (var i = 0; i < shop_user_list.length; ++i) {
            if(shop_user_list[i].id == assignToId){
              data.order['assignTo'] = {
                  name:shop_user_list[i].name,
                  id:shop_user_list[i].id
                }
            }
          }
        }

        if(data.order.is_order_cancelled){
          data.order.order_status = APIHelper.ORDER_COMPLETED;
          data.order.shipping_type = APIHelper.IMMEDIATE;
        }

        data.order['assign'] = null;
        let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
        if(loggedInUser) {
          data.order['assign'] = {
            'id':loggedInUser.id,
            'name':loggedInUser.name
          };
        }

        data.order.split_payment = payment_list;
        this.selectedOrderDetails = data;
        this.show_order_popup = true;
      }
    });
  }

  closeOrderPopup(event) {
    this.show_order_popup = false;
    this.selectedOrderDetails = null;
    let foundIndex = this.pendingOrderList.findIndex((order_id:any) => { return event && event.order_id == order_id; });
    if(foundIndex != -1) { this.pendingOrderList.splice(foundIndex,1); }
    if(this.pendingOrderList[0]) {
      this.getOrderDetail(this.pendingOrderList[0]);
    }    
  }

  showDeliveryTimeModal(notification) {
    let notification_data = notification.data;
    if(notification_data.third_party_partner == "ZOMATO") {
      this.modalRef = this.modalService.show(ThirdPartyDeliveryTimeModalComponent, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.delivery_time = 30;
      this.modalRef.content.prep_time = 30;
      let cSub:Subscription = this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'done') {
          this.acceptOrder(notification_data.order_id,this.modalRef.content.delivery_time,this.modalRef.content.prep_time);
        }
        cSub.unsubscribe();
      });
    } else {
      this.acceptOrder(notification_data.order_id,this.modalRef.content.delivery_time,this.modalRef.content.prep_time);
    }
    this.removeNotification(notification);

  }

  showRejectOrderReasonModal(notification) {
    let notification_data = notification.data;

    console.log('notification_data:', notification_data);

    if(notification_data.third_party_partner == "ZOMATO" || notification_data.third_party_partner == "urban_piper") {
      this.modalRef = this.modalService.show(ThirdPartyRejectOrderReasonModalComponent, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.third_party_partner = notification_data.third_party_partner;
      if(notification_data.third_party_order_other_information) {
        try {
          notification_data.third_party_order_other_information = JSON.parse(notification_data.third_party_order_other_information )
          if(notification_data.third_party_order_other_information.time_to_penalty_map && notification_data.third_party_order_other_information.time_to_penalty_map.length>0) {
            this.modalRef.content.time_to_penalty_map = notification_data.third_party_order_other_information.time_to_penalty_map;
          }
        }catch(e) { console.log(e)}
      }
      
      this.modalRef.content.order_created_at = new Date(notification_data.createdAt); 
      let cSub:Subscription = this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'done') {
          this.rejectOrder(notification_data.order_id,this.modalRef.content.reject_reason);
        }
        cSub.unsubscribe();
      });
    } else {
      this.rejectOrder(notification_data.order_id,"");
    }
    this.removeNotification(notification);

  }  

  acceptOrder(order_id,delivery_time,prep_time){
    let sendData = {
      'shop_code':localStorage.getItem('shop_code'),
      'order_id':order_id,
      'delivery_time':delivery_time,
      'prep_time':prep_time
    }

    this.recentOrdersService.acceptSelectedOrderInfo(sendData).subscribe((res) => {
      let ordersData = res.json();
      if(ordersData.status!==0){
        alert(ordersData.message);
      } else {
        //success
        this.messageService.add({ severity: 'success', summary: order_id+ ' order accepted successfully.', detail: '' });

      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Internal server error.', detail: '' });
    });
  }

  rejectOrder(order_id,rejection_message){
    let sendData = {
      'shop_code':localStorage.getItem('shop_code'),
      'order_id':order_id,
      'rejection_message':rejection_message,
      'order_status': 'REJECT'
    }
    this.recentOrdersService.rejectSelectedOrder(sendData).subscribe((res) => {
      let ordersData = res.json();
      if(ordersData.status!==0){
        alert(ordersData.message);
      }else{
        //success
        this.messageService.add({ severity: 'success', summary: order_id+ ' order rejected successfully.', detail: '' });        
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Internal server error.', detail: '' });
    });
  }

  removeNotification(notification) {    
    if(this.notificationList.length > 0 ){
      if(notification) {
        let findIndex = this.notificationList.findIndex(noti => { return noti.notification.body == notification.notification.body})
        if(findIndex >=0) {
          this.notificationList.splice(length);  
          if(this.audio) {
            this.audio.pause();
          }
        }
      } else {
        let length = (this.notificationList.length - 1);
        this.notificationList.splice(length);        
      }      
    }

    if(this.notificationList.length == 0){
      this.isNotificationShow = false;
    }
  }
  getThirdPartyNewOrdersCount() {    
    var shop_code= localStorage.getItem("shop_code");
    var is_online_partner_available= localStorage.getItem("is_online_partner_available");
    
    if(navigator.onLine && shop_code && is_online_partner_available == "1") {            
        this._thirdPartyOrderService.getThirdPartyNewOrderCount("shop_code="+shop_code).subscribe((res:any) => {
            var result = res.json();      
            if(result.status == 0) {
              this.third_party_new_order_count = result.result.count;
              localStorage.setItem("third_party_new_order_count",JSON.stringify(this.third_party_new_order_count));
            }          
            var ctime = setTimeout(() => {
              clearTimeout(ctime);
              this.getThirdPartyNewOrdersCount();
            },25000)
          },error => {
            var ctime = setTimeout(() => {
              clearTimeout(ctime);
              this.getThirdPartyNewOrdersCount();
            },25000)
          })      
    } else if(shop_code && is_online_partner_available == "1") {
      var ctime = setTimeout(() => {
        clearTimeout(ctime);
        this.getThirdPartyNewOrdersCount();
      },25000)
    }
  }
 
  setDeliveryType(deliveryType){

    this.selectedDeliveryType = deliveryType;
    //this.visitorArtefacts.place = deliveryType;
  }

  getCustomerBasicInfo(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        let sData:any = {};
        let current_customer_info:any = localStorage.getItem('current_customer_info');
        if(current_customer_info) { current_customer_info = JSON.parse(current_customer_info); }
        if(navigator.onLine && current_customer_info && current_customer_info.auth_token){          
          let delivery_type = localStorage.getItem('delivery_type');
          if(delivery_type){
            if(delivery_type == APIHelper.HOME_DELIVERY){
              this.visitorArtefacts.place = 'home';
            }else if(delivery_type == APIHelper.PICK_UP){
              this.visitorArtefacts.place = 'pickup';
            }else if(delivery_type == APIHelper.IMMEDIATE){
              this.visitorArtefacts.place = 'here';
            }
          }
          if(current_customer_info.auth_token != APIHelper.DEFAULT_TOKEN){
            this.customerService.info(current_customer_info.auth_token).subscribe((res) => {
              var result = res.json();      
              if (result.status === 0) {
                sData = {
                  consumer_name: (result.result.consumer_name)? result.result.consumer_name : "",
                  local_user_id: (result.result.local_user_id)? result.result.local_user_id : "",
                  email: (result.result.email)? result.result.email : "",
                  mobile_no: result.result.phone ? result.result.phone :  this.defaultVisitor.mobile_no,
                  zip_code: result.result.zip_code ? result.result.zip_code  : null,
                  address_1: result.result.address ? result.result.address : "",
                  city: result.result.city,
                  state: result.result.state,
                  last_purchase: (result.result.last_purchase)? result.result.last_purchase :0,
                  last_visited: (result.result.last_visited)? result.result.last_visited : new Date(),
                  rewards_points: (result.result.rewards_points)? result.result.rewards_points:0,
                  consumer_groups: (result.result.consumer_groups)? result.result.consumer_groups:null,
                  credit_limit: (result.result.credit_limit >= 0)? result.result.credit_limit:null,
                  employer_code: (result.result.employer_code)? result.result.employer_code:null
                }
                this.visitorArtefacts = Object.assign(this.visitorArtefacts,sData);                

                localStorage.setItem("current_customer_info",JSON.stringify(Object.assign(current_customer_info,sData)));
                resolve(sData);
              }
            },error => {
              reject(error);
            });
          } else {
            //for default guest user
            localStorage.setItem("current_customer_info",JSON.stringify(Object.assign(current_customer_info,sData)));
            resolve(sData);
          }
        } else {
          resolve({});
        }
    });
  }

  openCustomerInfoModal(params) {    
    let self = this;    
    let currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');              
    self.modalRef = self.modalService.show(CustomerInfoComponent, { class: 'common-modal' });
    self.modalRef.content.customerInfoModal.selectedPartners = (currentUnit && Array.isArray(currentUnit.selected_partner_list)) ? JSON.parse(JSON.stringify(currentUnit.selected_partner_list)) : [];    
    
    if(params && params.delivery_type) {
      let tempDeliveryType = self.visitorArtefacts.place;
      if(params.delivery_type == 'pickup') {tempDeliveryType = 'pickup'}
      if(params.delivery_type == 'home') {tempDeliveryType = 'home'}
      console.log("tempDeliveryType",tempDeliveryType);
      self.visitorArtefacts.place = tempDeliveryType
    }
    if(navigator.onLine) {
      this.isLoadingShow = true;      
      let cMobileNo = "";
      if(currentUnit && currentUnit.userInfo && currentUnit.userInfo.mobile_no) { cMobileNo =  currentUnit.userInfo.mobile_no; }
      this.getCustomerBasicInfo().then((result) => {
        self.modalRef.content.decision = "";
        self.modalRef.content.customerInfoModal.consumer_name = (result.consumer_name ) ? result.consumer_name : "";
        self.modalRef.content.customerInfoModal.email = (result.email) ? result.email : "";
        self.modalRef.content.customerInfoModal.local_user_id = (result.local_user_id) ? result.local_user_id : "";
        self.modalRef.content.customerInfoModal.mobile_no = (result.mobile_no) ? result.mobile_no : (cMobileNo) ? cMobileNo : "";
        self.modalRef.content.customerInfoModal.zip_code = (result.zip_code) ? result.zip_code : "";
        self.modalRef.content.customerInfoModal.address_1 = (result.address_1) ? result.address_1 : "";
        self.modalRef.content.customerInfoModal.city = (result.city)? result.city : ""; 
        self.modalRef.content.customerInfoModal.state = (result.state)? result.state : ""; 
        self.modalRef.content.customerInfoModal.consumer_groups = (result.consumer_groups)? result.consumer_groups : null; 
        self.modalRef.content.customerInfoModal.credit_limit = (result.credit_limit>=0)? result.credit_limit : null;         
        //select default employer
        if(result.employer_code) {
          let employerList = JSON.parse(localStorage.getItem("employer_list")) || [];
          if(employerList && employerList.length>0 ) {
            let empInfo = findLast(employerList,(emp:any) => { return emp.employer_code == result.employer_code})
            if(empInfo) {
              self.modalRef.content.customerInfoModal.selectedEmployer = empInfo;
            }
          }
        }
        self.isLoadingShow = false;
        self.handleOnHideCustomerInfoModal( self.modalRef);
      }).catch(error=> {
        console.log(error);        
        self.modalRef.content.decision = "";
        self.isLoadingShow = false;
        self.handleOnHideCustomerInfoModal( self.modalRef);
      });  
    } else {      
      self.modalRef.content.decision = "";
      self.handleOnHideCustomerInfoModal( self.modalRef);
    }   
  }

  openCashRegister(){

    return new Promise<boolean>((resolve,reject)=>{
      if(!localStorage.getItem('isCashRegister') || localStorage.getItem('isCashRegister')== 'false') {
        this.modalRef = this.modalService.show(OpenCashRegisterModalComponent, {class: 'centred-modal'});
        this.modalRef.content.amount = undefined;
        this.modalRef.content.notes = '';
        this.modalRef.content.decision = "";
        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
          if (this.modalRef.content.decision === 'submit') {
            this.unsubscribe();
            return resolve(true);
          }else{
            this.unsubscribe();
            return resolve(false);
          }
        }));
      } else {
        return resolve(true);
      }      
    })
  }

  handleOnHideCustomerInfoModal(modalRef) {
    let self = this;    
     var curSub:Subscription = modalRef.content.onHide.subscribe((result:boolean) => {
      curSub.unsubscribe();
      self.visitorArtefacts.distance = "- kms";
      if (modalRef.content.decision === 'select') {        
          modalRef.content.customerInfoModal.place = self.visitorArtefacts.place;          
          let zipCode = modalRef.content.customerInfoModal.zip_code;
          let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
          let lon1 = (shop_config.shop_info && shop_config.shop_info.longitude)?  shop_config.shop_info.longitude : undefined;
          let lat1 = (shop_config.shop_info && shop_config.shop_info.latitude)?  shop_config.shop_info.latitude : undefined;
          lat1 = (typeof lat1 == 'string')? parseFloat(lat1) : lat1;
          lon1 = (typeof lon1 == 'string')? parseFloat(lon1) : lon1;
          if(modalRef.content.customerInfoModal.isChangeGeometryData){

            let lon2 = modalRef.content.customerInfoModal.lng;
            let lat2 = modalRef.content.customerInfoModal.lat;
            let R = 6371;
            let lat1Radian = lat1 * (Math.PI / 180);
            let lat2Radian = lat2 * (Math.PI / 180);
            let latRadianDiff = (lat2 - lat1) * (Math.PI / 180);
            let lonRadianDiff = (lon2 - lon1) * (Math.PI / 180);
            let a = (Math.sin(latRadianDiff / 2) * Math.sin(latRadianDiff / 2)) 
                     + ( Math.cos(lat1Radian) * Math.cos(lat2Radian) * Math.sin(lonRadianDiff / 2) * Math.sin(lonRadianDiff / 2));
            let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            let d = R * c;

            if(d>0){
              self.visitorArtefacts.distance = d.toFixed(2) + " kms"
            }
            self.configsBoxComponent.submitCustomerInfo(true,modalRef.content.customerInfoModal,self.visitorArtefacts.distance,this.selectedDeliveryType);

          }else{
              if(zipCode){
                self.commonService.getLattitudeLongitudeCode(zipCode).subscribe(response =>{
                  if(response.results && response.results.length > 0){
                    let lon2 = response.results[0].geometry.location.lng;
                    let lat2 = response.results[0].geometry.location.lat;
                    let R = 6371;
                    let lat1Radian = lat1 * (Math.PI / 180);
                    let lat2Radian = lat2 * (Math.PI / 180);
                    let latRadianDiff = (lat2 - lat1) * (Math.PI / 180);
                    let lonRadianDiff = (lon2 - lon1) * (Math.PI / 180);
                    let a = (Math.sin(latRadianDiff / 2) * Math.sin(latRadianDiff / 2)) 
                             + ( Math.cos(lat1Radian) * Math.cos(lat2Radian) * Math.sin(lonRadianDiff / 2) * Math.sin(lonRadianDiff / 2));
                    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    let d = R * c;  

                    if(d>0){
                      self.visitorArtefacts.distance = d.toFixed(2) + " kms"
                    }
                  }
                  self.configsBoxComponent.submitCustomerInfo(true,modalRef.content.customerInfoModal,self.visitorArtefacts.distance,this.selectedDeliveryType);

                },err => {
                  self.visitorArtefacts.distance = "- kms";
                  self.configsBoxComponent.submitCustomerInfo(true,modalRef.content.customerInfoModal,null,this.selectedDeliveryType);
                });
              }else{
                self.configsBoxComponent.submitCustomerInfo(true,modalRef.content.customerInfoModal,null,this.selectedDeliveryType);
              }
          }
      }      
    });
  }

  hardcodedUnit = {
    location: "Garden",
    tables: [1],
    covers: 1,
    activePhase: {
      name: "green",
      timeStamp: new Date().valueOf(),
      timerText: "00:00:00"
    },
    visitors: [{
      dishes: [
        [],[]
      ]
    },
    {
      dishes: [
        [],[]
      ]
    }]
  };

  hardcodedUnit2 = {
    location: "Shop",
    tables: [0],
    covers: 1,
    activePhase: {
      name: "green",
      timeStamp: new Date().valueOf(),
      timerText: "00:00:00"
    },
    visitors: [
      {
        dishes: [[]],
        kot:[]
      }]
  };

  hardcodedLocations = [
    {
      name: "Garden",
      chosen: true,
      units: [this.hardcodedUnit,
      { location: "Garden", tables: [2], assigny: '', chosen: false },
      {
        location: "Garden",
        tables: [3, 4],
        assigny: '',
        chosen: false,
        covers: 2,
        visitors: [{
          dishes: [
            [{
              name: "Sandwiches & Wraps Recipes",
              amount: 1,
              price: 29,
              course: "c1"
            }], []
          ]
        }, {
          dishes: [
            [{
              name: "Potato, Squash & Goat Cheese Gratin",
              amount: 1,
              price: 77,
              course: "c1"
            }], []
          ]
        }],
        activePhase: {
          name: "green",
          timeStamp: 1505650982528,
          timerText: "00:00:00"
        }
      }],
      mergeOpen: false
    },
    // {
    //   name: "Patio",
    //   chosen: false,
    //   units: [{ location: "Patio", tables: [5], assigny: '', chosen: false, visitors: [] },
    //   { location: "Patio", tables: [6], assigny: '', chosen: false, visitors: [] },
    //   { location: "Patio", tables: [7], assigny: '', chosen: false, visitors: [] },
    //   { location: "Patio", tables: [8], assigny: '', chosen: false, visitors: [] }],
    //   mergeOpen: false
    // },
    // {
    //   name: "Shop",
    //   chosen: false,
    //   units: [this.hardcodedUnit2, { location: "Shop", tables: [1], assigny: '', chosen: false, visitors: [] },
    //   { location: "Shop", tables: [1], assigny: '', chosen: false, visitors: [] },
    //   { location: "Shop", tables: [0], assigny: '', chosen: false, visitors: [] },
    //   { location: "Shop", tables: [0], assigny: '', chosen: false, visitors: [] }],
    //   mergeOpen: false
    // },
    // {
    //   name: "1 Floor",
    //   chosen: false,
    //   units: [{ location: "1 Floor", tables: [9], assigny: '', chosen: false, visitors: [] },
    //   { location: "1 Floor", tables: [10], assigny: '', chosen: false, visitors: [] },
    //   { location: "1 Floor", tables: [11], assigny: '', chosen: false, visitors: [] }],
    //   mergeOpen: false
    // },
    // {
    //   name: "2 Floor",
    //   chosen: false,
    //   mergeOpen: false,
    //   units: [{ location: "2 Floor", tables: [12], assigny: '', chosen: false, visitors: [] },
    //   { location: "2 Floor", tables: [13], assigny: '', chosen: false, visitors: [] },
    //   { location: "2 Floor", tables: [14], assigny: '', chosen: false, visitors: [] }]
    // },
    {
      name: "3 Floor",
      chosen: false,
      mergeOpen: false,
      units: [{ location: "3 Floor", tables: [15], assigny: '', chosen: false, visitors: [] },
      { location: "3 Floor", tables: [16], assigny: '', chosen: false, visitors: [] },
      { location: "3 Floor", tables: [17], assigny: '', chosen: false, visitors: [] }]
    },
    {
      name: "4 Floor",
      chosen: false,
      mergeOpen: false,
      units: [{ location: "4 Floor", tables: [18], assigny: '', chosen: false, visitors: [] },
      { location: "4 Floor", tables: [19], assigny: '', chosen: false, visitors: [] },
      { location: "4 Floor", tables: [20], assigny: '', chosen: false, visitors: [] }]
    },
    {
      name: "5 Floor",
      chosen: false,
      mergeOpen: false,
      units: [{ location: "5 Floor", tables: [21], assigny: '', chosen: false, visitors: [] },
      { location: "5 Floor", tables: [22], assigny: '', chosen: false, visitors: [] },
      { location: "5 Floor", tables: [23], assigny: '', chosen: false, visitors: [] }]
    },
    {
      name: "6 Floor",
      chosen: false,
      mergeOpen: false,
      units: [{ location: "6 Floor", tables: [24], assigny: '', chosen: false, visitors: [] },
      { location: "6 Floor", tables: [25], assigny: '', chosen: false, visitors: [] },
      { location: "6 Floor", tables: [26], assigny: '', chosen: false, visitors: [] }]
    }
  ]

  showSidebar() {
    this.orderStatusHandling().then((r) => {
      this.showSB = true;
    })
  }

  onSignOut() {
    this.orderStatusHandling().then((r) => {
      setTimeout(() => {
        this.router.navigate(['verify-pin']);
      },200)
    });
  }

  orderStatusHandling() {
    return new Promise((resolve,reject) => {
      let currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
      let is_kot_order = false;
      if(currentUnit && currentUnit.visitors && currentUnit.visitors[0]) {
        for(var i=0;i<currentUnit.visitors.length;i++) {
          if(currentUnit.visitors[i] && currentUnit.visitors[i].kot && currentUnit.visitors[i].kot.length > 0) {
            is_kot_order = true;
            break;
          }
        }
      }
      
      let isRestaurant = localStorage.getItem('isRestaurant');
      if(isRestaurant == 'true' && this.isItFoodOrderingPage){

        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
          if(['park','hold'].includes(this.modalRef.content.decision)) {
              this.putOnHold(true).then((r) => {
                return resolve(true);
              })
          } else  if (this.modalRef.content.decision === 'save') {

            let tempPlace = this.stateGuard.obtain('selects-box.component:places');

            currentUnit.isOnHold = false;
            currentUnit.isFromOnHold = true;
            this.captainAppHelper.syncTable(currentUnit,false);
            localStorage.setItem('saved_order',JSON.stringify(currentUnit));
            localStorage.setItem('saved_places',JSON.stringify(tempPlace));
            this.messageService.add({ severity: 'success', summary: 'Order has been saved successfully.', detail: '' });

            this.basketComponent.clearCustomerInfo();
            this.router.navigate(['/pages/register/place-selection/here']);
            setTimeout(() => {
              return resolve(true);
            }, 100);
          } else if(this.modalRef.content.decision === 'clear'){
            localStorage.setItem('saved_order',"");
            this.messageService.add({ severity: 'success', summary: 'Order has been cleared successfully.', detail: '' });
            this.basketComponent.clearCart(false); 
            this.router.navigate(['/pages/register/place-selection/here']);  
            setTimeout(() => {
              this.showSB = true;      
            }, 100);
          } else if (this.modalRef.content.decision === 'cancel') {       
            this.basketComponent.cancelOrder(0).then(isOk => {
              if(isOk) {
                this.paymentComponent.putBillInBackground('other');
                setTimeout(() => {
                  return resolve(true);
                }, 400);
              } else {
                this.messageService.add({ severity: 'error', summary: "order not canceld", detail: '' });
              }
            })
            .catch(e => {
              console.log(e);
              this.messageService.add({ severity: 'error', summary: "order not canceld", detail: '' });
            })
          }
          
          this.unsubscribe();
        }));
     
        this.modalRef = this.modalService.show(HoldOrderModal, {class: 'common-modal'});
        this.modalRef.content.decision = "";
        let isHoldAvailable = [APIHelper.PICK_UP,APIHelper.HOME_DELIVERY].includes(currentUnit.delivery_type);
        this.modalRef.content.text = (currentUnit.isParked || currentUnit.isOnHold || isHoldAvailable) ? ((!is_kot_order)? 'Do you want to clear this order or hold this order?' : "Do you want to resume this order or park this order?") : "Do you want to discard the order or save this order?";
        this.modalRef.content.type = (currentUnit.isParked || currentUnit.isOnHold || isHoldAvailable) ? ((!is_kot_order)? 'hold' : 'park_resume') : (!is_kot_order) ? "save" : "cancel";
      this.modalRef.content.decision = "";
      this.modalRef.content.text = (currentUnit.isParked || currentUnit.isOnHold) ? (!is_kot_order ? 'Do you want to clear this order or hold this order?' : "Do you want to resume this order or park this order?") : "Do you want to discard the order or save this order?";
      this.modalRef.content.type = (currentUnit.isParked || currentUnit.isOnHold) ? (!is_kot_order ? 'hold' : 'park_resume') : (!is_kot_order) ? "save" : "cancel";
      this.modalRef.content.decision = "";
      this.modalRef.content.text = (currentUnit.isParked || currentUnit.isOnHold) ? (!is_kot_order ? 'Do you want to clear this order or hold this order?' : "Do you want to resume this order or park this order?") : "Do you want to discard the order or save this order?";
      this.modalRef.content.type = (currentUnit.isParked || currentUnit.isOnHold) ? (!is_kot_order ? 'hold' : 'park_resume') : (!is_kot_order) ? "save" : "cancel";
      }else if (isRestaurant == 'false' && this.isItFoodOrderingPage){

        this.subscriptions.push(this.modalService.onHide.subscribe(() => {

          if(['park','hold'].includes(this.modalRef.content.decision)) {
              this.putOnHold(true).then((r) => {
                return resolve(true);
              })
          }else if(this.modalRef.content.decision === 'clear'){
              this.basketComponent.clearCart(false); 
              this.messageService.add({ severity: 'success', summary: 'Order has been cleared successfully.', detail: '' });
              this.router.navigate(['/pages/register/place-selection/here']);  
          }
          setTimeout(() => {
            return resolve(true);
          }, 100);
          this.unsubscribe();
        }));
        this.modalRef = this.modalService.show(HoldOrderModal, {class: 'common-modal'});
        this.modalRef.content.decision = "";
        this.modalRef.content.text = !is_kot_order ? "Do you want to clear this order or hold this order?" : "Do you want to resume this order or park this order?";
        this.modalRef.content.type = !is_kot_order ? "hold" : "park_resume";
      }else{
        setTimeout(() => {
          return resolve(true);
        }, 100);
      }
    });
  }

  putOnHold (isClearCart) {
    return new Promise((resolve,reject) => {
      let unit = this.stateGuard.obtain("selects-box.component:currentUnit");
      if(!unit) {
        return resolve(false);
      }
      let tempUnit = JSON.parse(JSON.stringify(unit));
      let items: any = [];
      tempUnit.visitors.forEach((seat, index) => {      
        if(seat.dishes && seat.dishes[0]) {
          items = items.concat(seat.dishes[0]);
        }            
      });
      if(!items || items.length == 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No items in cart' });
        return resolve(false);
      }

      localStorage.setItem('saved_order',"");    

      if(tempUnit.isOnHold) {
        this.messageService.add({ severity: 'success', summary: 'Order has been put on hold, recall it from park button.', detail: '' });
        this.router.navigate(['/pages/register/place-selection/here']);        
        return resolve(true);
      }

      tempUnit.isOnHold = true;
      tempUnit.isFromOnHold = false;
      tempUnit.isParked = false;
      this.stateGuard.amend('basket.component:offUnits', function (offUnits) {
        offUnits.push(tempUnit);
        localStorage.setItem('offUnits',JSON.stringify(offUnits));
      });
      if(isClearCart) {
        this.basketComponent.clearCart(false);
      }
      this.messageService.add({ severity: 'success', summary: 'Order has been put on hold, recall it from park button.', detail: '' });
      this.router.navigate(['/pages/register/place-selection/here']);        
      return resolve(true);
    })
  }

  getModel(){
    /*this.recommenderService.getmodel();*/
  }
}
