import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-third-party-rider-info-modal',
  templateUrl: './third-party-rider-info-modal.component.html',
  styleUrls: ['./third-party-rider-info-modal.component.less']
})
export class ThirdPartyRiderInfoModalComponent implements OnInit {
  decision: string;
  order_type: string;
  rider_name = "";
  rider_phone_number = "";
  
  constructor(public modalRef: BsModalRef,public messageService:MessageService) { }
  
  ngOnInit() {
  } 
  
  done(){    
    if(["ecom","ecom_subscription"].indexOf(this.order_type) != -1) {
      if(!this.rider_phone_number) {
        this.messageService.add({ severity: 'error', summary: "please add all data", detail: '' });
        return;
      }
    } else if(!this.rider_name || !this.rider_phone_number) {
      this.messageService.add({ severity: 'error', summary: "please add all data", detail: '' });
      return
    }    
    
    this.decision='done';
    this.modalRef.hide()
  }

}
