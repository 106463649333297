import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

declare var $:any;

@Component({
  selector: 'app-exception-modal',
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.less']
})
export class ExceptionModal implements OnInit {
  
  constructor(public modalRef: BsModalRef) { }
  public shopUserList: any = [];

  public decision: string;
  isExceptionNoCharge:boolean= true;

  excepitonInfoModal = {
    "note":"",
    "selectedUser":null,
    "selectedException":null
  }

  ngOnInit() {
    this.shopUserList = JSON.parse(localStorage.getItem('shop_user_list'));
    this.checkExceptionType();
  }

  checkExceptionType(){
    var self = this;
    setTimeout(()=>{
      if(self.excepitonInfoModal.selectedException == 'employee_acct'){
        self.isExceptionNoCharge = false;
      }
    },100);
  }

  submitExceptionInfoModal(){
    if(this.isExceptionNoCharge && this.excepitonInfoModal.note.trim() == ''){
      alert('Please enter valid note');
      return;
    }
    this.decision='submit';
    this.modalRef.hide();
  }
}
