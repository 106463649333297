import { Injectable } from '@angular/core';
import { initializeApp } from "firebase/app";
import { BaseConfig } from '../api-config';
import { Observable,Subject } from 'rxjs';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from '../../../../environments/environment';

initializeApp(environment.firebase);

/*let messaging;
try {
  messaging = firebase.messaging();
} catch(e) {
  console.log("handled error",e)
}*/

@Injectable()
export class FirebaseMessagingService {

  private messageSource = new Subject<any>();
  constructor() {}

  getPermission(){
    return new Promise<any>((resolve,reject) => {
      const messaging = getMessaging();
      getToken(messaging, { vapidKey: environment.firebase.vapidKey}).then(
         (currentToken) => {
            if (currentToken) {              
              return resolve(currentToken);
            } else {
              console.log('No registration token available. Request permission to generate one.');
              return resolve(null);
            }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          return resolve(null);
        });
    })
  }

  getNotification(): Observable<any> {
    return this.messageSource.asObservable();
  }

  listenNotification() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("Notification received: ", payload);
      if('Notification' in window) {
        var notification = new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon:"../../assets/images/favicon.png"
        });
      }
      this.messageSource.next({ notification: payload});
    });
  }

}
