import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
 
@Injectable()
export class CustomerMessage {
    private subject = new Subject<any>();
 
    sendCustomerInfo(message: string,is_partial_change: boolean) {
        this.subject.next({ CustomerInfo: message, is_partial_change: is_partial_change });
    }

    clearCustomerInfo() {
        this.subject.next();
    }

    getCustomerInfo(): Observable<any> {
        return this.subject.asObservable();
    }
}