import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InventoryModalComponent } from '../inventory-modal/inventory-modal.component';
import { SpecialOrderModalComponent } from '../special-order-modal/special-order-modal.component';
import { SpecialOrderService } from '../helpers/services/special-order.service';
import { CancelKotItemReasonModal } from '../kot-item-cancel-reason-modal/kot-item-cancel-reason-modal.component';
import { StateGuardService } from '../state-guard.service';
import { Subscription } from 'rxjs';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { Dish } from '../dish';
import { APIHelper } from '../helpers/api-helper';
import { KotHelper } from '../helpers/kot-helper';
import { NgForm } from '@angular/forms';
import { ShopCategoriesItemsService } from '../helpers/services/shop-categories-items.service';
import { CommonService } from '../../pages/helpers/services/common.service';


@Component({
  selector: 'dish-details',
  templateUrl: './dish-details.component.html',
  styleUrls: ['./dish-details.component.less']
})
export class DishDetailsComponent implements OnInit {

  @Input() dish: any;
  @Input() discount: any = "0";
  @Input() visitorIndex: any;
  @Input() special_order_flag: number = 0; // 1=user available, 2=user available and one item already has special order information, 0= no user available
  @Output() editDish: EventEmitter<any> = new EventEmitter();
	@Output() editDishItemCount: EventEmitter<any> = new EventEmitter();
  @Output() hide: EventEmitter<any> = new EventEmitter();
	@Output() updateDishForSpecialOrder: EventEmitter<any> = new EventEmitter();
  public modalRef: BsModalRef;
  public isReadOnly: boolean = true;
  public isPriceUpdate: boolean = false;
  public has_variants: boolean = false;
  public isOnlyModifier: boolean = false;
  public subscriptions: Subscription[] = [];
  public quantity:string='';
  public note:string='';
  public price:string='';
  public data:number=0;
  public isOnline = false;
  public description: string = "";
  public can_be_sold_loose: boolean = false;
  public reasonList = [
        {
          'text':'Damaged',
          'id': APIHelper.KOT_DAMAGED
        },
        {
          'text':'Customer Cancelled',
          'id': APIHelper.KOT_CUSTOMER_CANCELLED
        },
        {
          'text':'Delivery Delayed',
          'id': APIHelper.KOT_DELIVERY_DELAYED
        },
        {
          'text':'Other',
          'id': APIHelper.KOT_OTHER
        }
  ];
  phase: number = 1;
  isPercentsDiscount: boolean;
  currencySymbol:any;
  selecteDish:any;
  constructor(private messageService:MessageService,private stateGuard:StateGuardService,
              private kotHelper: KotHelper,
              private modalService: BsModalService,
              private commonService: CommonService,
              private specialOrderService: SpecialOrderService,
              private shopCategoryItemService: ShopCategoriesItemsService) { }

  ngOnInit() {

    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    this.currencySymbol = shop_config.currency;
    var self = this;
    this.isOnline = navigator.onLine;
    if(this.dish.selected_variant_item) {
      this.description = (this.dish.selected_variant_item.description) ? JSON.parse(JSON.stringify(this.dish.selected_variant_item.description)) : "";
      this.can_be_sold_loose = this.dish.selected_variant_item.can_be_sold_loose ? true : false;
    } else {
      this.description = (this.dish.description) ? JSON.parse(JSON.stringify(this.dish.description)) : "";
      this.can_be_sold_loose = this.dish.can_be_sold_loose ? true : false;
    }

  }

 
  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  
  showSpecialOrderModal (dish: Dish) {
    let tempDish = (dish.selected_variant_item)? JSON.parse(JSON.stringify(dish.selected_variant_item)) : JSON.parse(JSON.stringify(dish));    
    if(!tempDish.default_unit_price && tempDish.default_unit_price != 0 ) {
      tempDish.default_unit_price = parseFloat(tempDish.unit_price);
    }

    if(!tempDish.default_unit_value && tempDish.default_unit_value != 0 ) {
      tempDish.default_unit_value = tempDish.unit_value ? tempDish.unit_value : 1;
    }
    
    this.specialOrderService.getSupplierList().subscribe((res) => {
        var apiRes = res.json();
        var supplierList = [];
        if (apiRes.status === 0) {
            supplierList = apiRes.result;
        }
        
        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
          if (this.modalRef.content.decision === 'done') {
            let specialOrderInfo = this.modalRef.content.special_order_details ? JSON.parse(JSON.stringify(this.modalRef.content.special_order_details)) : null;            
            if(specialOrderInfo && !specialOrderInfo.supplier_id) { specialOrderInfo = null; }
            if(this.dish.selected_variant_item) {
              this.dish.selected_variant_item.special_order_information = specialOrderInfo;
              this.dish.selected_variant_item.note = specialOrderInfo.item_note;

            } else {
              this.dish.special_order_information = specialOrderInfo;
            }
            this.description = specialOrderInfo.item_note;
            this.dish.description = specialOrderInfo.item_note;
            this.dish.note = specialOrderInfo.item_note;
            this.updateDishForSpecialOrder.emit({specialOrderInfo:specialOrderInfo});
          }
          this.unsubscribe();
        }));
        this.modalRef = this.modalService.show(SpecialOrderModalComponent, {class: 'common-modal'});
        this.modalRef.content.decision = "";
        this.modalRef.content.supplierList = supplierList;
        this.modalRef.content.currencySymbol = this.currencySymbol;
        this.modalRef.content.dish = JSON.parse(JSON.stringify(tempDish));
        this.modalRef.content.special_order_details = (tempDish.special_order_information && tempDish.special_order_information.supplier_id) ? JSON.parse(JSON.stringify(tempDish.special_order_information)) : null;
    },err => {
      this.messageService.add({ severity: 'error', summary: 'Get error while fetching supplier list', detail: '' });
    });
  }

  showInventoryModal (dish: Dish) {
    let tempDish = (dish.selected_variant_item)? JSON.parse(JSON.stringify(dish.selected_variant_item)) : JSON.parse(JSON.stringify(dish));
    let sendData = {
      'item_code':tempDish.item_code
    }
    this.commonService.getItemStock(sendData).subscribe((res) => {
        var apiRes = res.json();
        var itemStockList= []
        if (apiRes.status === 0) {
            itemStockList = apiRes.result;
        }

        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
          if (this.modalRef.content.decision === 'done') {
          }
          this.unsubscribe();
        }));
        this.modalRef = this.modalService.show(InventoryModalComponent, {class: 'common-modal'});
        this.modalRef.content.decision = "";
        this.modalRef.content.dish = tempDish;
        this.modalRef.content.currencySymbol = this.currencySymbol;        
        this.modalRef.content.item_stock_list = itemStockList;
    },err => {
      this.messageService.add({ severity: 'error', summary: 'Internal server error', detail: '' });
    });
  }

  keyPress(event: any) {
     const pattern = /[0-9]/;
     let inputChar = String.fromCharCode(event.charCode);     
     if (event.keyCode != 8 && event.keyCode != 45 && event.keyCode != 46 && !pattern.test(inputChar)) {
       event.preventDefault();
     }
  }

  itemQuantityChange(event: any) {
     const pattern = /[0-9]/;
     let inputChar = String.fromCharCode(event.charCode);
     if (event.keyCode != 8  && event.keyCode != 45 && (!this.can_be_sold_loose || (this.can_be_sold_loose && event.keyCode != 46)) && !pattern.test(inputChar)) {
       event.preventDefault();
     }
  }

  modelChanged(e:any){
    if(e != 0 && !e){
      e = 1;
    }
    if(this.dish.tracking_batch_serial_item_quantity || e>this.dish.tracking_batch_serial_item_quantity) {
      e = 1;
      setTimeout(()=>{

        if(this.dish.tempData.temp_item_count>this.dish.tracking_batch_serial_item_quantity) {
          this.dish.tempData.temp_item_count = 1;
          this.messageService.add({ severity: 'error', summary: 'only '+this.dish.tracking_batch_serial_item_quantity +" item available for tracking number " + this.dish.tracking_number, detail: '' });
        } else {
          if(e && !this.dish.isKotfound){
            this.editDishItemCount.emit({quantity:e});
          }
        }
      },100)
    } else if(!this.dish.isKotfound){
      this.editDishItemCount.emit({quantity:e});
    }
  }

  editDishDetails () {
    this.isReadOnly = false;
    this.phase = 2;
  }

  done(event:any){
    if(this.dish.isKotfound && event.selected_variant_item  && this.dish.selected_variant_item
          && ((event.selected_variant_item.item_code == this.dish.selected_variant_item.item_code && event.selected_variant_item.item_count < this.dish.kot_item_count) || event.selected_variant_item.item_code != this.dish.selected_variant_item.item_code)){
       this.getReason(event);
    }else{
      Object.assign(this.dish,event);
      delete this.dish.isEditFromcart;
      this.dish.expanded = !this.dish.expanded;
      this.has_variants = false;
    }
  }

  getReason(event:any){

    this.modalRef = this.modalService.show(CancelKotItemReasonModal, {class: 'common-modal'});
    this.modalRef.content.decision = "";
    this.modalRef.content.reasonList = this.reasonList;
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
        let data = '';
        if (this.modalRef.content.decision === 'send') {

          data = this.modalRef.content.selectedReason;
          let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
          if(!tempUnit.visitors[this.visitorIndex].deleted_items){
            tempUnit.visitors[this.visitorIndex].deleted_items = [];
          }

          if(this.dish && this.dish.selected_variant_item.item_code && event && event.selected_variant_item &&
                 this.dish.selected_variant_item.item_code == event.selected_variant_item.item_code){
            let diff = this.dish.selected_variant_item.item_count - event.selected_variant_item.item_count;
            let item = JSON.parse(JSON.stringify(this.dish.selected_variant_item));
            item.item_count = diff * (-1);
            item['delete_reason'] =  data;
            item['is_billed'] = false;
            tempUnit.visitors[this.visitorIndex].deleted_items.push(item);
          }else if(this.dish && this.dish.selected_variant_item.item_code && event && event.selected_variant_item &&
                 this.dish.selected_variant_item.item_code != event.selected_variant_item.item_code){
            let diff = this.dish.selected_variant_item.item_count;
            let item = JSON.parse(JSON.stringify(this.dish.selected_variant_item));
            item.item_count = diff * (-1);
            item['delete_reason'] =  data;
            item['is_billed'] = false;
            tempUnit.visitors[this.visitorIndex].deleted_items.push(item);
          }
          Object.assign(this.dish,event);
          delete this.dish.isEditFromcart;
          this.dish.expanded = !this.dish.expanded;
          this.has_variants = false;

        }else{
          this.messageService.add({ severity: 'success', summary: 'Item is not cancelled', detail: '' });
          this.dish.tempData.temp_item_count = this.dish.item_count;
          delete this.dish.isEditFromcart;
          this.dish.expanded = !this.dish.expanded;
          this.has_variants = false;
        }
        this.unsubscribe();
    }));
  }

  editVariantItems(){
    this.dish['isEditFromcart'] = true;
    this.selecteDish = JSON.parse(JSON.stringify(this.dish));
    this.isOnlyModifier = this.dish.isOnlyModifier;
    this.has_variants = true;
  }

  hideDishDetails (dish, form) {

    if(this.isPriceUpdate){
      let shop_code = localStorage.getItem('shop_code');
      var tItem = (dish.selected_variant_item) ? dish.selected_variant_item : dish
      let sendData = {
        'item_code':tItem.item_code,
        'shop_code':shop_code,
        'per_unit_price':form.value.price
      }

      var currentUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
      if(localStorage.getItem('isRestaurant') == 'true' && currentUnit && currentUnit.location) {

        if(Array.isArray(tItem.section_wise_price)) {
          tItem.section_wise_price.forEach(section_price_detail => {
            if(currentUnit.location == section_price_detail.section) {
              section_price_detail.unit_cost = (tItem.unit_cost || tItem.unit_cost == 0) ? parseFloat(tItem.unit_cost) : section_price_detail.unit_cost;
              section_price_detail.unit_price = (tItem.unit_price || tItem.unit_price == 0) ? parseFloat(tItem.unit_price) : section_price_detail.unit_price;
            }
          })
        }

        sendData["section_wise_price"] = tItem.section_wise_price;
      }

      this.shopCategoryItemService.updateItems(sendData).subscribe((res) => {
        let resData = res.json();
        if(resData.status !== 0){
          this.messageService.add({ severity: 'error', summary: resData.message, detail: "" });
        }
      });
    }

    if(form.value.quantity != 0 && !form.value.quantity){
      form.value.quantity = 1;
    }

    var mergeData = {discountUnits: (this.isPercentsDiscount ? "%" : this.currencySymbol),kot_item_count:dish.kot_item_count};
    if(!dish.selected_variant_item && dish.item_from == "captain") {
      mergeData["quantity"] = dish.tempData.temp_item_count;
    }
    this.editDish.emit(Object.assign(mergeData, form.value));
    this.isReadOnly = true;
    this.phase = 1;
    this.discount =  (form.value.discount) ? form.value.discount : '0';
    this.isPercentsDiscount = (mergeData.discountUnits == "%") ? true : false;
    this.hide.emit();
  }

}
