import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {

	@Input('label') label: string = '.';
	@Input('type') type: string = 'text';
	@Input('name') name: string;
  @Input('value') value: any;
  @Input('placeholder') placeholder: string;
	@Input('disabled') disabled: boolean = false;

	@Output() sendValue = new EventEmitter<string>();


  constructor() { }

  ngOnInit() {
    if(this.type == 'date') {
      let year, month, day;
      year = this.value.getFullYear() + '';
      month = this.value.getMonth() + 1 + '';
      if(month.length < 2) { month = '0' + month };
      day = this.value.getDate() + '';
      if(day.length < 2) { day = '0' + day };
      year = this.value.getFullYear();
      this.value = year + '-' + month + '-' + day;
    }
  }

  sendData() {
    this.sendValue.emit(this.value);
  }

  @HostListener('change') onChange() {
    if(this.type == 'date') {
      this.sendData();
    }
  }

  @HostListener('click') onClick() {
    if(this.type == 'date') {
      this.sendData();
    }
  }

}
