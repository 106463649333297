import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-close-shop-modal',
  templateUrl: './close-shop-modal.component.html',
  styleUrls: ['./close-shop-modal.component.less']
})
export class CloseShopModal implements OnInit {
  
  constructor(public modalRef: BsModalRef) { }
  public decision: string;

  ngOnInit() {
  }
  
}
