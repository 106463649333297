export class SeparateBillHelper {

    public static cheackIsDifferentTaxClassesItemAvailable(cart_items) {
		var result = false;
		var ctilen = cart_items.length;
		var tax_classes = []
		for(var i=0;i<ctilen;i++) {
			var tItem = (cart_items[i].selected_variant_item) ? cart_items[i].selected_variant_item : cart_items[i];
			if(tItem && Array.isArray(tItem.tax_settings) && tItem.tax_settings.length > 0){
				if(!tItem.tax_settings[0].tax_class) {
					tItem.tax_settings[0].tax_class = "GST";
				}
				
		      	if(tax_classes.indexOf(tItem.tax_settings[0].tax_class) == -1) {
		      		tax_classes.push(tItem.tax_settings[0].tax_class);
		      	}
		        if(tax_classes.length>1) {
		        	result = true;
		        	break;
		        }
		      
		    }
		}
		return result;
	}	
}