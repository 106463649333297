import { Component, Input, OnInit, HostListener, OnDestroy } from '@angular/core';
import { WebSocketService } from './pages/helpers/services/websocket.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerInfoComponent } from './pages/customer-info/customer-info.component';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { StateGuardService } from './pages/state-guard.service';
import { CaptainAppHelper } from './pages/helpers/captain-app-helper';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  tablesData: any = [];

  title = 'app';
  delType: number = 1;
  modalRef: BsModalRef;
  showSB: boolean = false;
  constructor(private route: ActivatedRoute,
    private stateGuard:StateGuardService,
    private modalService: BsModalService,
    private webSocketService: WebSocketService,
    private captainAppHelper: CaptainAppHelper) {
  }

  public subscriptions: Subscription[] = [];


  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  ngOnInit() { 

    // Subscribe to WebSocket messages
    // this.subscriptions.push(
    //   this.webSocketService.getMessages().subscribe((msg) => {
    //     console.log('Received from server:', msg);

    //     if (msg.type === 'get_tables_response') {
    //       this.tablesData = msg.data;
    //       this.captainAppHelper.handleWebSocketResponse(message.data);
    //     }

    //   })
    // );

    // send a message to the server
    //  this.webSocketService.sendMessage({ type: 'get_tables' });
 

    // Listen for WebSocket updates
    this.webSocketService.getMessages().subscribe((message) => {
      console.log('WebSocket message received:', message);

      if (message.type === 'get_tables_response') {
        console.log(`Tables data: ${message}`);
      }
    });
  
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.webSocketService.closeConnection();
  }
  
  

  showSidebar() {
    setTimeout(() => {
      this.showSB = true;
      console.log('slider');
    }, 100);
  }

}