import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-select-seat-modal',
  templateUrl: './select-seat-modal.component.html',
  styleUrls: ['./select-seat-modal.component.less']
})
export class SelectSeatModalComponent implements OnInit {
  decision: string = '';
  unit: Unit = {visitors: []};
  choosensMap = new Array(this.unit.visitors.length).fill(false);
  currentVisitorIndex:any;
  selectedIndex:number = undefined;
  constructor(public modalRef: BsModalRef,private messageService:MessageService) { }
  getResult () {
    var choosenVisitors = this.unit.visitors.filter((seat, index) => {
      return this.choosensMap[index] ? seat : false;
    });
    return {
      choosenVisitors: choosenVisitors,
      unit: this.unit
    }
  }
  ngOnInit() {
  }

  send(){
    if(!this.selectedIndex){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'First select any seat' });
      return;
    }
    this.decision='send';
    this.modalRef.hide();
  }

  selectSeat(index){
    this.selectedIndex = index;
  }
}
export interface Unit {
  visitors
}
