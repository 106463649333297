import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-input-select-object',
  templateUrl: './input-select-object.component.html',
  styleUrls: ['./input-select-object.component.css']
})
export class InputSelectObjectComponent implements OnInit {

	@Input('label') label: string;
	@Input('type') type: string;
	@Input('name') name: string;
  @Input('is_mandatory') is_mandatory: string = 'false';
	@Input('value') value: any;
	@Input('placeholder') placeholder: string;
  @Input('filter_items') filter_items: any;
	@Input('disabled') disabled: boolean = false;
  @Input('isShowAddMore') isShowAddMore: boolean = false;
  @Input('page_class') page_class: any = "";
  
  value_list:any;

  @Output() sendValue = new EventEmitter<string>();
	@Output() addMore = new EventEmitter<string>();

  showItems: boolean;
  filterBy: any = {name:''};

  constructor() { }

  ngOnInit() {

  }

  @HostListener('window:click') onClick() {
    this.showItems = false;
  }

  ngOnChanges(changeRecord: any) {
    if(changeRecord.value && changeRecord.value && changeRecord.value.currentValue && changeRecord.value.currentValue.name){
      this.value_list = changeRecord.value.currentValue.name;
    } else {
      if(!Array.isArray(this.filter_items) || this.filter_items.length == 0) {
        this.value_list = null;
      }
    }
  }

  showSelect() {
    setTimeout(() => {
      this.showItems = true;
    }, 100);
  }

  sendData(type) {    
    if(type == 'click') {
      if(this.value && this.value.name != "ADD NEW"){
        let name = this.value.name;
        this.value_list = name;
      }  
    } else {
      this.value = null;
    }

  	this.sendValue.emit(this.value);
  }

  addMoreClick() {
    this.addMore.emit("");
  }

}
