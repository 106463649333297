import { Component, OnInit, Input, Output, EventEmitter,ViewChild, ViewEncapsulation,OnDestroy, NgModule } from '@angular/core';
import { Subscription , Observable} from 'rxjs';
import { Messenger } from '../messenger.service';
import {AutoComplete} from 'primeng/autocomplete';
import { MessageService } from 'primeng/api';
import { StateGuardService } from '../state-guard.service';
declare var $:any;

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.less'],
  encapsulation: ViewEncapsulation.None
})



export class AutocompleteComponent implements OnInit {
  @Input() artefacts;
  @Input() quantityFlag;
  @Input() isResetAutocomplete;
  @ViewChild('autoCompleteObject',{static: false})autoCompleteObject:AutoComplete;

  @Output() action: EventEmitter<any> = new EventEmitter();
  message: any = {text: ''};
  searchParam = { text: "" };
  items:any = [];
  isImmutable: boolean = false;
  // allItems: any = [];
  subscription: Subscription;  
  autoType: any = "normal";

  constructor(private messageService: Messenger ,private messagePopup: MessageService, private stateGuard: StateGuardService) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message; 
      if (this.artefacts.way && this.artefacts.way.length) {
        this.items = this.analyse(JSON.parse(JSON.stringify(this.artefacts.data)), message.text);  
      } else {
        this.items = [...this.artefacts.data];
      }
      // this.allItems = [...this.items];
    });
  }

  ngOnChanges(changeRecord: any) {
    if(this.isResetAutocomplete) {
      this.searchParam.text = "";
      this.isResetAutocomplete = false;      
    }
  }

  ngOnInit() {
    this.stateGuard.entrust('autocomplete.component:isBarcodeVariant', {isBarcodeVariant:false , variant_name:"" ,bar_code:""});
    this.analyse(JSON.parse(JSON.stringify(this.artefacts.data)), this.message.text);
    var local_config = JSON.parse(localStorage.getItem('local_configuration')) || {};
    if(local_config.enable_grid_view_item_search) {
      this.autoType = "grid-view";
    }
  }

  reactOnSearchChange(event) {
    // console.log("search query changed to =>"+event.query);
    // this.searchParam.text = "";
    // 1 => chk for *123*
    // 2 => if * present then find d item and do 3 else normal flow (uncommented statement below)
    // 3 => if item not present, show popup msg and set this.searchParam.text = "" else 4 
    // 4 => if this.quantityFlag off, call add(item) & check for modifiers & variants else 5
    // 5 => substitute barcode in this.searchParam.text with the item name and call add(item) & check for modifiers & variants
    if(event.query.charAt(0) == "*" && event.query.length > 1 && event.query.charAt(event.query.length - 1) == "*"){
      let searchText = JSON.parse(JSON.stringify(event.query)).slice(1,-1).trim();
      if(searchText) {
        let barcode_item = this.analyse(JSON.parse(JSON.stringify(this.artefacts.data)), event.query);
        if(barcode_item.length > 0) {          
          if(!this.quantityFlag) {
            this.add(barcode_item[0]);
          } else {
            this.searchParam.text = "";
            this.add(barcode_item[0]);
          }
        } else {
          console.log("There is no item with this barcode number.");
          this.searchParam.text = "";
          this.messagePopup.add({ severity: 'error', summary: 'No Item for barcode', detail: 'There is no item with this barcode number.' });
        }
      }
    }else{
      this.messageService.sendMessage(event.query);
    }
  }

  onKeyBoardChange() {
    this.autoCompleteObject.inputEL.nativeElement.dispatchEvent(new Event('focus'));  
    this.autoCompleteObject.inputEL.nativeElement.focus();
    this.autoCompleteObject.inputEL.nativeElement.click();    
    this.autoCompleteObject.inputEL.nativeElement.dispatchEvent(new Event('keydown'));
    this.autoCompleteObject.inputEL.nativeElement.dispatchEvent(new Event('input'));
    this.autoCompleteObject.inputEL.nativeElement.dispatchEvent(new Event('keyup'));
  }

  add (item) {
    if(!this.quantityFlag){
      this.searchParam.text = ""
    }
    this.action.emit(item);
  }
  
  analyse (data, searchText) {
    let isBarcode = false;    
    if(searchText.charAt(0) == "*" && searchText.length > 1 && searchText.charAt(searchText.length - 1) == "*"){
      isBarcode = true;
      searchText = searchText.slice(1,-1);

      if(!searchText.trim()) {
        return [];
      }
    }

    var itemLayer = [data],
        suitableItems = [];

    this.artefacts.way.forEach(artefact => {      
      if (typeof artefact === 'string') {      
        itemLayer = this.walk(itemLayer, artefact);       
      } else if(Array.isArray(artefact)){
        itemLayer = this.extractFields(itemLayer, artefact,this.artefacts.auto_type);
      }
    });

    itemLayer.forEach(item => {      
      this.artefacts.way.forEach(artefact => {      
        if (Array.isArray(artefact)) {
          artefact.forEach(searchFields => {            
            if(searchFields.type =="string") {
              if(searchFields.match == "contain") {                
                if ( item[searchFields.field] && 
                   ( 
                     (item[searchFields.field].toLowerCase().indexOf(searchText.toLowerCase()) + 1) || 
                     (this.extractFirstWordFromString(item[searchFields.field].toLowerCase()) == searchText.toLowerCase())
                   )
                   ) {
                  suitableItems.push(item);
                }    
              } else if(searchFields.match == "exact"){
                if (item[searchFields.field] && item[searchFields.field].toLowerCase() == searchText.toLowerCase()) {
                  suitableItems.push(item);
                }
              }
            } else if(searchFields.type =="number") {
              if(searchFields.match == "contain") {
                if (item[searchFields.field] && item[searchFields.field].toString().indexOf(searchText.toLowerCase()) + 1) {
                  suitableItems.push(item);
                }    
              } else if(searchFields.match == "exact"){
                if(isBarcode){
                  //to avoid searching for a barcode in short_key field i.e if searchText is a barcode then match only with bar_code field
                  if(searchFields.field == "bar_code"){                      
                      let barcodeBatchSeparator = localStorage.getItem('barcode_batch_separator');
                      let barcodeWeightSeparator = localStorage.getItem('barcode_weight_separator');

                      let dividedBarcode = this.divideBarcode(searchText.toUpperCase());
                      let barcodeText = dividedBarcode["barcode"] || "";
                      let batchText = dividedBarcode[barcodeBatchSeparator] || "";
                      let weightText = dividedBarcode[barcodeWeightSeparator] || "";                      

                      if (item[searchFields.field] && item[searchFields.field] > 0 && item[searchFields.field].toString() == barcodeText) {
                        item.barcode_data = {
                          batch: batchText,
                          count: (parseFloat(weightText))/1000 || 1
                        }
                        suitableItems.push(item);
                      } else if(item["item_list"] && item["item_list"].length > 0){
                        //item["selected_variant_item"] &&
                        //search bar_code in variants
                        item["item_list"].forEach(itm => {
                          if(itm.bar_code == barcodeText){
                            let barcode_data = {
                              batch: batchText,
                              count: (parseFloat(weightText))/1000 || 1
                            }
                            item.barcode_data = barcode_data;
                            this.stateGuard.entrust('autocomplete.component:isBarcodeVariant', {
                              isBarcodeVariant:true , 
                              variant_attributes: itm["attributes"].filter((a:any) => a.is_variant_attribute).map((a:any) => a.attribute_code), 
                              bar_code:itm["bar_code"],
                            });
                            suitableItems.push(item);
                          }
                        })
                    } 
                  }
                }else{
                  //to avoid searching a short_code in bar_code field i.e if searchText is a short_key then match only with short_code field
                  if(searchFields.field == "short_key"){
                    if (item[searchFields.field] && item[searchFields.field] > 0 && item[searchFields.field].toString() == searchText.toLowerCase()) {
                      suitableItems.push(item);
                    }
                  }
                }
              }
            } 
          })
        }
      })
    });
    return suitableItems;
  }

  divideBarcode(str) {
    let result = {};
    let tempStr = "";
    let splitFrom = "";
    for(var i=0,fLen=str.length;i<fLen;i++) {        
        if(!/^\d$/.test(str[i])) {
            if(splitFrom) {
              result[splitFrom] = tempStr;
            } else {
              result["barcode"] = tempStr;
            }
            tempStr = "";
            splitFrom = str[i];
        } else {
            tempStr += str[i];
        }    
    }

    if(splitFrom) {
      result[splitFrom] = tempStr;
    } else {
      result["barcode"] = tempStr;
    }

    return result;
  }

  capitalize(str){
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }
  extractFirstWordFromString(str) {
    str = str.toLowerCase();
    let result = [];
    let isSpace = true;
    for (var i=0; i<str.length; i++){        
        if (str[i] == ' ') {
          isSpace = true;
        } else if (str[i] != ' ' && isSpace == true) {
          result.push(str[i]);
          isSpace = false;
        }
    }
 
    return result.join("");
  }
  extractFields(entity,hook,type) {
    if(this.stateGuard.obtain('entity')){
    }else{
      this.stateGuard.entrust('entity', JSON.stringify(entity));
    }
    
    // console.log("entity:"+JSON.stringify(entity));
    var extractedFieldsData = [];
    if (Array.isArray(entity) && Array.isArray(hook)) {
      entity.forEach(itemObj => {
        if(typeof itemObj == "object") {
          var item = {};
          if(type == "shop_item_search") {            
            item["text"] = this.capitalize(itemObj['item_name']) + " ( "+ itemObj['unit_value'] + " " + (itemObj['unit'] ? itemObj['unit'].toUpperCase() : itemObj['unit']) +" ) " + " ( In Stock: " + itemObj['quantity'] + " )"   + " ( Price: " + itemObj['unit_price'] + " )";            
            item["item_unique_code"] = itemObj["item_unique_code"];
            item["category_code"] = itemObj["category_code"];
            item["bar_code"] = itemObj['bar_code'];
            // console.log("barcode:"+itemObj['bar_code']);
            if(itemObj['selected_variant_item']){
              item["selected_variant_item"] = itemObj['selected_variant_item'];
            }
            item["item_list"] = itemObj['item_list'];
            item["item_name"] = itemObj['item_name'];
          }

          hook.forEach(fieldInfo => {
            if(itemObj[fieldInfo.field]) {
              item[fieldInfo.field] = itemObj[fieldInfo.field];
            }
          });

          if(item != '{}') {
            extractedFieldsData.push(item)
          }
        }
      });
    } 

    return extractedFieldsData;   
  }
  walk (entity, hook) {
    var innerEntity = [], assembled = [];

    if (Array.isArray(entity)) {
      let buffer = [];
      entity.forEach(layer => {
        buffer = [...buffer, ...this.walk(layer, hook)];
      });
      innerEntity = buffer;
    } else {
      innerEntity = entity[hook];
    }

    return innerEntity;
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSelect(item:any) {
    setTimeout(() => {
      this.onEnter(null,item)
    },100)
  }

  onEnter(event:any,selectedItem) {
    if(Array.isArray(this.items) && this.items.length == 1) {
      if(this.quantityFlag) {
        this.searchParam.text = this.items[0]
      }
      this.add(this.items[0])          
      this.items = [];
    } else if(selectedItem && this.items.length>0){
      if(this.quantityFlag) {
        this.searchParam.text = selectedItem
      }
      this.add(selectedItem);
      this.items = [];
    }
  }
}
