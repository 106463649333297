import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent implements OnInit {

	@Input('label') label: string;
  @Input('value') value: boolean;

  constructor() { }

  ngOnInit() {

  }

}
