import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StateGuardService } from '../state-guard.service';

@Component({
  selector: 'park-modal',
  templateUrl: './park-modal.component.html',
  styleUrls: ['./park-modal.component.less']
})
export class ParkModalComponent implements OnInit {
  constructor(public modalRef: BsModalRef, private stateGuard: StateGuardService) { }
  decision: string;
  offUnits: Array<any>;
  selectedHoldUnit;
  public currencySymbol:any;
  ngOnInit() {
    //this.offUnits = this.stateGuard.obtain('basket.component:offUnits');    
    let shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
    if(shopConfig) {
      this.currencySymbol = shopConfig.currency;
    }
  }


  getGeneralPrice(current_offunit) {
    var price = 0;
    current_offunit.visitors.forEach(visitor => {
      visitor.dishes.forEach(course => {
        course.forEach(dish => { 
          if(dish.selected_variant_item) {
            price += dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count
            if(dish.selected_variant_item.attributes) {
              dish.selected_variant_item.attributes.forEach(attr => {                
                price += attr.unit_price * attr.count * dish.selected_variant_item.item_count;
              });
            }            
            if(dish.selected_variant_item.discount && dish.selected_variant_item.discount > 0) {
              price -= (dish.selected_variant_item.discountUnits == "%") ? ((dish.selected_variant_item.unit_price * dish.selected_variant_item.item_count * dish.selected_variant_item.discount) / 100) : dish.selected_variant_item.discount;
            }            
          } else {
            price += dish.unit_price * dish.item_count 
            if(dish.discount && dish.discount > 0) {
              price -= (dish.discountUnits == "%") ? ((dish.unit_price * dish.item_count * dish.discount) / 100) : dish.discount;
            }            
          }          
        });
      });
    });
    return price;
  }

  
}
