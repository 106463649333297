import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecentOrdersService } from '../helpers/services/recent-orders.service';
import { APIHelper } from '../helpers/api-helper';
import { BaseConfig } from '../helpers/api-config';
import { MessageService } from 'primeng/api';
import { ThirdPartyDeliveryTimeModalComponent } from '../third-party-delivery-time-modal/third-party-delivery-time-modal.component';
import { ThirdPartyRejectOrderReasonModalComponent } from '../third-party-reject-order-reason-modal/third-party-reject-order-reason-modal.component';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PrintHelper } from '../helpers/print-helper';

declare var _: any;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

	body = document.getElementsByTagName("body")[0];

	//filter values
	search: string = '';
	sorting: string = '';
	beginDate: Date = new Date();
	endDate: Date = new Date();
  pageNo:number = 1;
  public loading = false;    
  limit:number = 10;
  selectedOrderType:string='ALL';
  searched_order_status: any="";
  isPrevFlag:boolean = false;
  isRestaurantFlag:boolean = true;
  loadingForDetail:boolean = false;
  isForNonCompleteOrders:boolean = false;
  deep_link_order_data:any;
  orders_list_flag:boolean = false;
  orders_list_detail_flag:boolean = false;
  isNextFlag:boolean = false;
  currencySymbol:any;
  alterStylesForNext:any = {};
  show_order_popup: boolean = false;  
  alterStylesForPrev:any = {
        'cursor': 'not-allowed',
        'opacity': '0.65'
      };
  orders_list:any=[];
	temp_orders_list:any=[];
  selectedOrderDetails: any;
  shop_start_time:any;
  shop_close_time:any;  
  search_by_list: any = ["ANY","BILL_NUMBER","DELIVERY_DATE","ORDER_STATUS"];
  status_list: any = [
      {name:"ALL" , code: ''},
      {name:"ORDER_ON_HOLD" , code: 'ORDS0001'},
      {name:"ORDER_PENDING" , code: 'ORDS0005'},
      {name:"ORDER_NEW" , code: 'ORDS0007'},
      {name:"ORDER_COMPLETED" , code: 'ORDS0008'},
      {name:"ORDER_APPROVED" , code: 'ORDS0002'},
      {name:"ORDER_DELIVERED" , code: 'ORDS0004'},
      {name:"ORDER_REJECTED" , code: 'ORDS0003'},
      {name:"ORDER_CANCELED" , code: 'ORDS0003'},
      {name:"ORDER_EDITED" , code: 'ORDS0009'},
      {name:"ORDER_DRAFT" , code: 'ORDS0010'},
      {name:"ORDER_PICKEDUP" , code: 'ORDS0011'},
      {name:"ORDER_ASSIGNED_TO_RIDER" , code: 'ORDS0012'},
      {name:"ORDER_READY" , code: 'ORDS0013'},
      {name:"ORDER_DISPATCHED" , code: 'ORDS0014'}
  ];
  search_by:any = "";
  edit_items: boolean;
  modalRef: BsModalRef;
  local_configuration:any = JSON.parse(localStorage.getItem("local_configuration")) || {enable_dispatch_print: false,enable_customer_address_print:false};

  constructor(private route: ActivatedRoute, private router: Router, private cdRef:ChangeDetectorRef,
            private messageService:MessageService,private recentOrdersService:RecentOrdersService,
            private modalService: BsModalService,private printHelper:PrintHelper,) { 
  }

  getAllOrdersInfo(data){

    this.loading = true;
    if (navigator.onLine) {
        this.loading = true;
        this.orders_list = [];
        this.recentOrdersService.getAllOrdersInfo(data).subscribe((res) => {
          let ordersData = res.json();
          if(ordersData.result){            
            let temp_limit =  ordersData.result.length;//in non completed order we need to have only conditional orders
            
            this.orders_list = ordersData.result;
            this.orders_list.forEach(order => {
              order.is_order_sync = true;
              order.partner_names = (order.partner_ids ? order.partner_ids.map((op:any) => { return op.name; }) : []).join(",");
              order.partner_short_codes = (order.partner_ids ? order.partner_ids.map((op:any) => { return op.partner_short_code ? op.partner_short_code : ''; }) : []).join(",");
              order.order_status_obj = this.getOrderStatus(order,order.order_status);
            })
            if(this.orders_list.length>0){
              this.loading = false;
              //this.addOfflineOrders(false);
            }else{
              this.orders_list = [];
              this.loading = false;
              this.messageService.add({ severity: 'info', summary: 'Information', detail: 'No orders found' });
              //this.addOfflineOrders(true);
            }
            if(temp_limit >= this.limit){
              this.alterStylesForNext={};
              this.isNextFlag = true;
            }else{
              this.isNextFlag = false;
              this.alterStylesForNext={
                'cursor': 'not-allowed',
                'opacity': '0.65'
              }
            }
          }else{
            this.loading = false;
          }
        },error => {
          this.loading = false;
          this.messageService.add({ severity: 'error', summary: 'Internal server error', detail: '' });
        });

    }else{
        //this.addOfflineOrders(true);
    }
  }

  cancelOrderComplete(){
    var self = this;
    setTimeout(()=>{
      self.orders_list = [];
      self.initialiseData();
    },2000);
  }


  initialiseData(){

    this.body.setAttribute("class", "scrolling-view");
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration')) || {};
    this.currencySymbol = shop_config.currency;

    if(localStorage.getItem('isRestaurant') == 'false'){
      this.isRestaurantFlag = false
    }

    this.fetchNewRecords(0);
    
    /*if(localStorage.getItem('temp_orders_list')){
      let todays_order_list = JSON.parse(localStorage.getItem('temp_orders_list'));
      this.orders_list = todays_order_list;            
    }

    if(this.orders_list.length>0){
      this.tempAddOfflineOrders(false);
    }else{
      this.tempAddOfflineOrders(true);
    }*/
    
  }

  fetchNewRecords(num){
    this.pageNo = this.pageNo + num;
    if(this.pageNo > 1){
      this.isPrevFlag = true;
      this.alterStylesForPrev={}
    }else{
      this.isPrevFlag = false;
      this.alterStylesForPrev={
        'cursor': 'not-allowed',
        'opacity': '0.65'
      }
    }

    if(this.isForNonCompleteOrders){
      let sendData = {
        'shop_code':localStorage.getItem('shop_code'),
        'page':this.pageNo,
        'limit':this.limit
      }
      sendData['shipping_type_filter'] =  ''+APIHelper.HOME_DELIVERY+","+ APIHelper.PICK_UP;
      this.getAllOrdersInfo(sendData);
    }else{
      let sendData = this.getParamForFilter();
      this.getAllOrdersInfo(sendData);
    }
  }

  ngOnInit() {
    let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
    if(!shop_config){
      this.router.navigate(['verify-pin']);
      return;
    }
    this.initialiseData();
  }

  ngAfterViewChecked() {    
    // this is added for solve the issue of Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked     
    this.cdRef.detectChanges();
  }



  tempAddOfflineOrders(flag){

    let list = [];
    let offlineOrderList = JSON.parse(localStorage.getItem('bill_queue'));
    if(offlineOrderList && offlineOrderList.length>0){

        offlineOrderList.forEach(data=>{
          this.orders_list.unshift(data);
        });  
    }

    if(this.orders_list.length>0){
      if(flag){
          this.loading = false;
          //this.getSelectedOrderInfo(this.orders_list[0]);
      }
      this.loading = false;
      this.tempGetSelectedOrderInfo(this.orders_list[0]);
    }else{
      this.loading = false;
    }


    this.temp_orders_list = this.orders_list;

  }


  tempGetSelectedOrderInfo(orderInfo){
    let id = orderInfo.order_id;    
    this.selectedOrderDetails = orderInfo;
    this.orders_list_flag = true;
    this.orders_list_detail_flag = true;
    this.loading = false;
    this.loadingForDetail = false;
  }



  refreshPage(){
    this.isForNonCompleteOrders = false;
    this.orders_list = [];
    this.orders_list_flag = false;
    this.orders_list_detail_flag = false;
    this.selectedOrderDetails=null;
    this.pageNo = 1;
    let sendData = this.getParamForFilter();
    this.getAllOrdersInfo(sendData);
  }

  addOfflineOrders(flag){

    let list = [];
    let offlineOrderList = JSON.parse(localStorage.getItem('bill_queue'));
    let tempofflineOrderList = offlineOrderList;
    if(offlineOrderList && offlineOrderList.length>0){

      if(this.search && this.search!=""){
          list = offlineOrderList.filter((data) =>{
            if ((data.order_id && data.order_id.toLowerCase() == this.search.toLowerCase()) ||
              (data.user_name && data.user_name.toLowerCase() == this.search.toLowerCase())) {
              return true;
            }else{
              return false;
            }

          });
      }else{
        list = tempofflineOrderList;
      }

      let shop_config = JSON.parse(localStorage.getItem('shop_configuration'));
      let open_time = (shop_config.shop_open_time)? shop_config.shop_open_time : "00:00";
      let close_time = (shop_config.shop_close_time)? shop_config.shop_close_time : "23:59";
      let beginDate = new Date(this.beginDate);
      let endDate = new Date(this.endDate);
      let tempOpenList = (open_time.split(':').length==2)? open_time.split(':') : ["00","00"];
      let tempCloseList = (close_time.split(':').length==2)? close_time.split(':') : ["23","59"];

      tempOpenList.forEach(data=>{
        if(data.length == 1){
          data = "0"+data;
        }
      });

      tempCloseList.forEach(data=>{
        if(data.length == 1){
          data = "0"+data;
        }
      });

     /* beginDate.setHours(parseInt(tempOpenList[0]));
      beginDate.setMinutes(parseInt(tempOpenList[1]));
      endDate.setHours(parseInt(tempCloseList[0]));
      endDate.setMinutes(parseInt(tempCloseList[1]));*/
      beginDate.setHours(0);
      beginDate.setMinutes(0);
      endDate.setHours(23);
      endDate.setMinutes(59);

      let finalList:any = [];
      let startTime = beginDate.getTime();
      let endTime = endDate.getTime();

      if(list && list.length>0){
        finalList = list.filter(data=>{
          let time = (new Date(data.delivery_date)).getTime();

          if(time >= startTime && time <= endTime){
            return true;
          }else{
            return false;
          }
        });
      }
      if(finalList && finalList.length>0){
        finalList.forEach(data=>{
          this.orders_list.unshift(data);
        });  
      }
    }

    if(this.orders_list.length>0){
      this.loading = false;
      this.getSelectedOrderInfo(this.orders_list[0]);
    }else{
      this.loading = false;
    }
  }

  getTypeCode(name){
    return APIHelper[name];
  }

  getOrderStatus(order,code) {
    let order_status =  {
      name: "NEW",
      color:"black"
    }

    if(code == this.getTypeCode('ORDER_COMPLETED')) {
      order_status["name"] = "COMPLETED";
      order_status["color"] = "#63b12d";
      order_status["background_color"] = "transparent";
    } else if(code == this.getTypeCode('ORDER_NEW')) {
      order_status["name"] = "NEW";
      order_status["color"] = "#63b12d";
      order_status["background_color"] = "rgba(255, 140, 0, 0.5)";
    } else if(code == this.getTypeCode('ORDER_APPROVED')) {
      order_status["name"] = "APPROVED";
      order_status["color"] = "#63b12d";
      order_status["background_color"] = "rgba(255, 255, 0, 0.3)";
    } else if(code == this.getTypeCode('ORDER_DELIVERED')) {
      order_status["name"] = "DELIVERED";
      order_status["color"] = "#63b12d";      
      order_status["background_color"] = "rgba(101, 178, 46, 0.3)";
    } else if(code == this.getTypeCode('ORDER_REJECTED')) {
      order_status["name"] = "REJECTED";
      order_status["color"] = "#ff4d00";
      order_status["background_color"] = "rgba(101, 178, 46, 0.3)";
    } else if(code == this.getTypeCode('ORDER_EDITED')) {
      order_status["name"] = "EDITED";
      order_status["color"] = "#2c93ff";
      order_status["background_color"] = "#2c93ff";
    } else if(code == this.getTypeCode('ORDER_DRAFT')) {
      order_status["name"] = "DRAFT";
      order_status["color"] = "#2c93ff";
      order_status["background_color"] = "#2c93ff";
    } else if(code == this.getTypeCode('ORDER_READY')) {
      order_status["name"] = "READY TO PICKUP";
      order_status["color"] = "#2c93ff";
      order_status["background_color"] = "rgba(77, 77, 203, 0.3)";
    } else if(code == this.getTypeCode('ORDER_PICKEDUP')) {
      order_status["name"] = "PICKED UP";
      order_status["color"] = "#63b12d";
      order_status["background_color"] = "rgba(77, 77, 203, 0.3)";
    } else if(code == this.getTypeCode('ORDER_ASSIGNED_TO_RIDER')) {
      order_status["name"] = "HAND OVER TO RIDER";
      order_status["color"] = "#63b12d";
      order_status["background_color"] = "rgba(77, 77, 203, 0.3)";
    }

    if([this.getTypeCode('ORDER_REJECTED'),this.getTypeCode('ORDER_COMPLETED'),this.getTypeCode('ORDER_DELIVERED')].indexOf(code) == -1 && order.delivery_date) {
      let dData:any = new Date(order.delivery_date);
      let cDate:any = new Date()
      let timeDiff:any  =  (dData - cDate)/(1000*60);
      if(timeDiff>0 && timeDiff <= 60) {
        order_status["background_color"] = "rgba(128, 0, 128, 0.3)";
      } else if(timeDiff <=0) {
        order_status["background_color"] = "rgba(255, 0, 0, 0.3)";
      }
    }

    if(!order.is_special_order) {
      order_status["background_color"] = "transparent";
    }
   
    return order_status;
  }

  setOrderInfoInOrderList(order) {
    let orderInfo = {
      "order_id":order.order.order_id,
      "user_name":order.user[0].name,
      "user_phone":order.user[0].phone,
      "valued_at":order.order.valued_at,
      "order_status":order.order.order_status,
      "total_item_count":1,
      "shipping_type":order.order.shipping_type,
      "delivery_date":order.order.delivery_date,
      "createdAt":order.order.createdAt,
      "payment_type_summary":[],
      "client_reference_code":order.order.client_reference_code
    }

    this.orders_list=[orderInfo];
  }

  getSelectedOrderInfo(orderInfo){
    this.edit_items = false;
    let id = orderInfo.order_id;

    if(orderInfo.isOfflineOrder || orderInfo.is_full_detail_available){
      this.selectedOrderDetails = orderInfo;
      this.orders_list_flag = true;
      this.orders_list_detail_flag = true;
      this.loading = false;
      this.loadingForDetail = false;      
    } else {

      /*let orderData = this.temp_orders_list.filter(order => order.order_id == id);
      if(orderData && orderData[0]){
        this.selectedOrderDetails = orderData[0];

        this.orders_list_flag = true;
        this.orders_list_detail_flag = true;
        this.loading = false;
        this.loadingForDetail = false;

      }else{*/
        let sendData = {
          'shop_code':localStorage.getItem('shop_code'),
          'order_id':id,
          'getLocalizeName':true
        }
         
        this.loadingForDetail = true;

        this.recentOrdersService.getSelectedOrderInfo(sendData).subscribe((res) => {
          let ordersData = res.json();
          if(ordersData.result){
            let shopConfig = JSON.parse(localStorage.getItem('shop_configuration'));
            if(typeof ordersData.result.order.comment == 'string' || ordersData.result.order.nocharge_comment){              
              if(ordersData.result.order.comment || ordersData.result.order.nocharge_comment){                
                if(ordersData.result.order.nocharge_comment) {
                  ordersData.result.order.comment = {noChargeNote:ordersData.result.order.nocharge_comment};
                  if(ordersData.result.order.employee_id && ordersData.result.order.employee_id != -1) {
                    ordersData.result.order.comment["employeeId"] = ordersData.result.order.employee_id;
                  }
                } else {
                  try {
                    ordersData.result.order.comment = (ordersData.result.order.comment) ? JSON.parse(ordersData.result.order.comment) : "";
                  } catch(e) {
                    ordersData.result.order.comment = "";
                  }
                }
                
                if(ordersData.result.order.comment.employeeId){
                  if(localStorage.getItem('shop_user_list')){
                    let userList = JSON.parse(localStorage.getItem('shop_user_list'));
                    for (var i = 0; i < userList.length; ++i) {
                      if(userList[i].id.toString() == ordersData.result.order.comment.employeeId.toString()){
                        ordersData.result.order.comment['emp_name'] = userList[i].name;
                        break;
                      }
                    }
                  }
                }
              }else{
                ordersData.result.order.comment = null;
              }              
            }else{
              ordersData.result.order.comment = (ordersData.result.order.comment);
            }
            let total_item_count = 0;
            let data = {
              'order':ordersData.result.order,
              'user':(ordersData.result.user.length>0)? ordersData.result.user[0] : {},
              'items':(ordersData.result.items.length>0)? ordersData.result.items : [],
              'discount_info':(ordersData.result.discount_info.length>0)? ordersData.result.discount_info : [],
              'diary_history':(ordersData.result.diary_history.length>0)? ordersData.result.diary_history : [],
              'rider_data':(ordersData.result.rider_data)? ordersData.result.rider_data : null
            }


            let groupTaxes = [];
            data.items.forEach(function(item : any) {
              total_item_count += (item.item_count) ? item.item_count : 0;
              item.tax_breakup.forEach(function(f : any) {
                /*if(!ordersData.result.order.is_order_cancelled){
                  f['breakup_name'] = f['breakup_name']//+' @'+f['rate'];
                }*/
                var present = false;
                for(var calcTax of groupTaxes) {
                  if(calcTax !== undefined) {
                    if(f['breakup_name'] === calcTax['breakup_name'] && f['rate'] === calcTax['rate']){
                      present = true;
                      if(!ordersData.result.order.is_order_cancelled){
                        calcTax['value'] = calcTax['value'] + f['value'];
                      }
                    }
                  }
                }
                if(!present) {
                  groupTaxes.push(JSON.parse(JSON.stringify(f)));
                }
              });
            });

            if(this.deep_link_order_data && this.deep_link_order_data.order_id) {
              ordersData.result.total_item_count = total_item_count;
              this.setOrderInfoInOrderList(ordersData.result);
            }
            
            data.order.group_tax = groupTaxes;
            if(!Array.isArray(data.order.tax_breakup) || data.order.tax_breakup.legnth==0) {
              data.order.tax_breakup = groupTaxes;  
            }            

            if(data.user.profile_img_url){
              data.user.profile_img_url = BaseConfig.config.API+data.user.profile_img_url;
            }

            data.user.phone = (data.user.alt_address && data.user.alt_address.length>0 && data.user.alt_address[data.user.alt_address.length - 1].contact_no)? (data.user.alt_address[data.user.alt_address.length - 1].contact_no) : (data.user.phone) ? data.user.phone  : null ;
            data.user.address = data.user.address ? data.user.address : (data.user.alt_address && data.user.alt_address.length>0)? (data.user.alt_address[data.user.alt_address.length - 1].address) : null;

            ordersData.result.diary_history.forEach(data=>{
              if(data.comment == 'credit' || data.comment == 'credit_due'){
                data['title'] = 'Credit Due Amount';
              }else if(data.comment == 'credit_void'){
                data['title'] = 'Credit Void';
              }else if(data.comment == 'used'  || data.comment == 'used_due'){
                data['title'] = 'Pay from diary';
              }else if(data.comment == 'used_void'){
                data['title'] = 'Pay from diary void';
              }else if(data.comment == 'outstanding' || data.comment == 'outstanding_due'){
                data['title'] ="Outstanding Amount";
              }else if(data.comment == 'advance' || data.comment == 'advance_due'){
                data['title'] ="Advance Amount";
              }else if(data.comment == 'credit_note_advance'){
                data['title'] ="Credit Note Amount";
              }else{
                data['title'] = data.comment;
              }
            })

            let payment_list = [];
            if(data.order.split_payment_details){
              for (var key in data.order.split_payment_details) {
                if (data.order.split_payment_details.hasOwnProperty(key)) {

                  if(Array.isArray(data.order.split_payment_details[key].payment_sub_class_info) && data.order.split_payment_details[key].payment_sub_class_info.length>0){
                    data.order.split_payment_details[key].payment_sub_class_info.forEach( data =>{
                      let tempPaymentDetail ={
                        title:data.sub_class_name,
                        amount:data.amount,
                        payment_code:key
                      }
                      if(key == APIHelper.SAVE_TO_DIARY_TYPE){
                        
                      }else{
                        payment_list.push(tempPaymentDetail);
                      }
                    });
                  }else {
                    let obj ={
                      title:data.order.split_payment_details[key].name,
                      amount:data.order.split_payment_details[key].amount,
                      payment_code:key
                    }
                    if(key == APIHelper.SAVE_TO_DIARY_TYPE){                     
                    }else{
                      payment_list.push(obj);
                    }
                  }
                }
              }
            }

            let is_clear_dairy_amount = false;
            ordersData.result.diary_history.forEach(data=>{
              if(data.comment == 'clear'){
                is_clear_dairy_amount = true;
                let list = data.payment_details;
                if(list){
                  for (var key in list) {
                    if (list.hasOwnProperty(key)) {
                      if(Array.isArray(list[key].payment_sub_class_info) && list[key].payment_sub_class_info.length>0){
                        list[key].payment_sub_class_info.forEach( data =>{
                          let tempPaymentDetail ={
                            title:data.sub_class_name,
                            amount:data.amount,
                            payment_code:key
                          }

                          let is_found = false;
                          payment_list.forEach(payment=>{
                            if(payment.title == tempPaymentDetail.title){
                              payment.amount += tempPaymentDetail.amount;
                              is_found = true;
                            }
                          })

                          if(!is_found){
                            payment_list.push(tempPaymentDetail);
                          }
                        });
                      }else {
                        let obj ={
                          title:list[key].name,
                          amount:list[key].amount,
                          payment_code:key
                        }

                        let is_found = false;
                        payment_list.forEach(paymentData=>{
                          if(paymentData.title == obj.title){
                            paymentData.amount += obj.amount;
                            is_found = true;
                          }
                        })
                        if(!is_found){
                          payment_list.push(obj);
                        }
                      }
                    }
                  }
                }
              } 
            })
            if(is_clear_dairy_amount){
              ordersData.result.diary_history.forEach(data=>{
                if(data.comment == 'used'){
                  let obj ={
                    title:'Pay from diary',
                    amount:data.diary_amount,
                    payment_code:'PAY_FROM_DAIRY'
                  }
                  payment_list.push(obj);
                }
              })
            }

            let assignToId = data.order.shop_user_id;
            if(assignToId == -1){
              data.order['assignTo'] = null;
            }else{
              let shop_user_list = JSON.parse(localStorage.getItem('shop_user_list'));
              for (var i = 0; i < shop_user_list.length; ++i) {
                if(shop_user_list[i].id == assignToId){
                  data.order['assignTo'] = {
                      name:shop_user_list[i].name,
                      id:shop_user_list[i].id
                    }
                }
              }
            }

            if(data.order.is_order_cancelled){
              data.order.order_status = APIHelper.ORDER_COMPLETED;
              data.order.shipping_type = APIHelper.IMMEDIATE;
            }

            data.order['assign'] = null;
              let loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
              if(loggedInUser){
                data.order['assign'] = {
                  'id':loggedInUser.id,
                  'name':loggedInUser.name
                };
              }
            data.order.split_payment = payment_list;
            this.selectedOrderDetails = data;
            this.show_order_popup = true;
            this.loading = false;
            this.loadingForDetail = false;
          }
        });
      }
    /*}*/
    //console.log("$$ ",this.orders_list)
  }

  closeOrderPopup(event) {
    this.show_order_popup = false;
    this.selectedOrderDetails = null;
    if(event && event.order_status) {
      this.orders_list.forEach((order:any) => {
        if(order.order_id == event.order_id) {
          this.changeOrderStatus(event.order_id,event.order_status);
          order.order_status = event.order_status;        
          order.order_status_obj = this.getOrderStatus(event,event.order_status);
          this.changeStatusOfSubOrder(order);
        }
      });
    }
  }

  changeStatusOfSubOrder(order) {
    if(order.order_status == APIHelper.ORDER_COMPLETED || order.order_status == APIHelper.ORDER_APPROVED || order.order_status == APIHelper.ORDER_REJECTED) {
      this.orders_list.forEach((suborder:any) => {
        if(suborder.reference_order_id == order.order_id) {
          let nStatus = order.order_status;
          if(order.order_status == APIHelper.ORDER_COMPLETED) {
            nStatus = APIHelper.ORDER_APPROVED;
          }
          this.changeOrderStatus(suborder.order_id,nStatus);
          suborder.order_status = nStatus;        
          suborder.order_status_obj = this.getOrderStatus(suborder,suborder.order_status);
        }
      });
    }
  }

  getSubTotalAmount(items){
    var total = 0;
    var totalItemDiscount = 0
    items.forEach(dish => { 
      total += dish.item_price * dish.item_count;
      if(dish.attributes){
        dish.attributes.forEach((attr) => {
          total += attr.price ;
        });        
      }
      /*if(dish.discount) {
        total -= parseFloat(dish.discount);
      }*/
    });
    return total;
  }

  sortRecentOrders(e:any) {
    this.isForNonCompleteOrders = false;
    this.sorting = e;
    if(this.sorting == 'Delivery'){
      this.selectedOrderType = 'ST0002';
    }else if(this.sorting == 'Pick Up'){
      this.selectedOrderType = 'ST0001';
    }else if(this.sorting == 'In Store'){
      this.selectedOrderType = 'ST0003';
    }else{
      this.selectedOrderType = 'ALL';
    }
    this.isPrevFlag = false;
    this.alterStylesForPrev={
        'cursor': 'not-allowed',
        'opacity': '0.65'
    }
    this.pageNo = 1;
    let sendData = this.getParamForFilter();
    this.getAllOrdersInfo(sendData);
  }

  searchRecentOrdersByStatus(e:any) {
    this.isForNonCompleteOrders = false;
    this.searched_order_status = e;
    
    this.isPrevFlag = false;
    this.alterStylesForPrev={
        'cursor': 'not-allowed',
        'opacity': '0.65'
    }
    this.pageNo = 1;
    this.search_by = "ORDER_STATUS";
    let sendData = this.getParamForFilter();
    this.getAllOrdersInfo(sendData);
  }

  getParamForFilter() {

    let from_date = new Date(this.beginDate);
    let to_date = new Date(this.endDate);
    from_date.setHours(0);
    from_date.setMinutes(0);
    from_date.setSeconds(0);
    to_date.setHours(23);
    to_date.setMinutes(59);
    to_date.setSeconds(59);

    let sData = {
      'page':this.pageNo,
      'limit':this.limit,
      'from_date_filter': from_date,
      'to_date_filter': to_date,
      'search_by':this.search_by,
      'shop_code':localStorage.getItem('shop_code')
    }

    if(sData.search_by == 'ORDER_STATUS' && this.searched_order_status && this.searched_order_status.code) {
      sData["order_status"] = this.searched_order_status.code;
    }

    let shipping_type_code = null;

    if(this.sorting == 'Pick Up'){
      shipping_type_code = 'ST0001'
    }else if(this.sorting == 'Delivery'){
      shipping_type_code = 'ST0002'
    }else if(this.sorting == 'In Store'){
      shipping_type_code = 'ST0003'
    }

    if(shipping_type_code){
      if(this.sorting && this.sorting != ""){
        sData['shipping_type_filter'] = shipping_type_code;
      }  
    }
    
    if(this.search && this.search != ""){
      sData['search_filter'] = this.search;
    }
    return sData;
  }

  fetchOrderDetail(order_id) {
    this.getSelectedOrderInfo({order_id:order_id});
  }

  searchRecentOrders(e:any){
    this.isForNonCompleteOrders = false;
    this.search = e;
    this.isPrevFlag = false;
    this.alterStylesForPrev={
        'cursor': 'not-allowed',
        'opacity': '0.65'
    }
    if (navigator.onLine) {
      this.pageNo = 1;
      let sendData = this.getParamForFilter();
      this.getAllOrdersInfo(sendData);
    }else{

      let list = []
      let offlineOrderList = JSON.parse(localStorage.getItem('temp_orders_list'));
      let tempofflineOrderList = offlineOrderList;
    
      if(offlineOrderList && offlineOrderList.length>0){

        if(this.search && this.search!=""){
            list = offlineOrderList.filter((data) =>{
              if ((data.order_id && data.order_id.toLowerCase() == this.search.toLowerCase()) || 
                (data.order.client_reference_code && data.order.client_reference_code !='NA' && data.order.client_reference_code.split('/')[0] == this.search) ||
                (data.user_name && data.user_name.toLowerCase() == this.search.toLowerCase())) {
                return true;
              }else{
                return false;
              }

            });
        }else{
          list = tempofflineOrderList;
        }
        this.orders_list = list;
        if(this.orders_list && this.orders_list[0])
          this.selectedOrderDetails = this.orders_list[0];
        }
    }
  }

  changedFromAndToDate(e:any){
    this.isForNonCompleteOrders = false;
    this.beginDate = e.from_date;
    this.endDate = e.end_date;

    this.isPrevFlag = false;
    this.alterStylesForPrev={
        'cursor': 'not-allowed',
        'opacity': '0.65'
    }
    this.pageNo = 1;
    let sendData = this.getParamForFilter();
    this.getAllOrdersInfo(sendData);
  }

  getInitialData(){

    let from_date = new Date();
    let to_date = new Date();
    from_date.setHours(0);
    from_date.setMinutes(0);
    from_date.setSeconds(0);
    to_date.setHours(23);
    to_date.setMinutes(59);
    to_date.setSeconds(59);
    return {
      'page':this.pageNo,
      'limit':this.limit,
      'from_date_filter': from_date,
      'to_date_filter':to_date,
      'shop_code':localStorage.getItem('shop_code')
    }
  }

  sorting_list: string[] = [
  	'All', 'Pick Up', 'Delivery', 'In Store'
  ];

  dateFilter(date) {
    if(this.beginDate && this.endDate) {
      if(date > this.beginDate && date < this.endDate || date.toDateString() == this.beginDate.toDateString() || date.toDateString() == this.endDate.toDateString()) {
        return true;
      } else { return false; }
    } else if(this.beginDate) {
      if(date > this.beginDate || date.toDateString() == this.beginDate.toDateString()) {
        return true;
      } else { return false; }
    } else if(this.endDate) {
      if(date < this.endDate || date.toDateString() == this.endDate.toDateString()) {
        return true;
      } else { return false; }
    }
  }

  getBeforeHours(order_date:any){
    if(!order_date) {
      return "";
    }
    let date1 = new Date().getTime();
    let date2 = new Date(order_date).getTime();
    let diff = (date1 - date2);
    let min:any =(diff / (60000));
    let hour:any = Math.floor((min) / 60);
    let sendVal = hour+' hours';
    if(min < 60){
      sendVal = Math.floor(min)+' minutes';
    }
    return sendVal;
  }

  sortingFilter(order) {
    if(this.isForNonCompleteOrders){
      let delivery_date = new Date(order.delivery_date);
      
      if(/*(this.shop_start_time<delivery_date && delivery_date<this.shop_close_time) && */
        (order.shipping_type == APIHelper.HOME_DELIVERY || order.shipping_type == APIHelper.PICK_UP) && 
        (order.order_status == APIHelper.ORDER_APPROVED || order.order_status == APIHelper.ORDER_DELIVERED || order.order_status == APIHelper.ORDER_NEW) && 
        (order.order_id.indexOf('CAN') == -1)) {
        return true;
      } else {
        return false;
      }
    }else{
      if(this.selectedOrderType == order.shipping_type || this.selectedOrderType == 'ALL') {
        return true;
      } else {
        return false;
      }
    }  
  }

  showDeliveryTimeModal(order,type) {
    if(order.third_party_order_id) {
      this.modalRef = this.modalService.show(ThirdPartyDeliveryTimeModalComponent, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.delivery_time = 30;
      this.modalRef.content.prep_time = 30;
      let cSub:Subscription = this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'done') {
          this.acceptOrder(order,this.modalRef.content.delivery_time,this.modalRef.content.prep_time,type);
        }
        cSub.unsubscribe();
      });
    } else {
      this.acceptOrder(order,0,0,type);
    }
  }

  acceptOrder(order,delivery_time,prep_time,type){
    this.edit_items = false;
    let sendData = {
      'shop_code':localStorage.getItem('shop_code'),
      'order_id':order.order_id,
      'delivery_time':delivery_time,
      'prep_time':prep_time,
      'partner_short_code': order.partner_short_codes,
      'partner_name': order.partner_names,
      'order_status': this.getTypeCode('ORDER_APPROVEDF'),
    }
    this.loading = true;
    this.recentOrdersService.acceptSelectedOrderInfo(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if(ordersData.status!==0 && ordersData.code != "-164"){
        alert(ordersData.message);
      }else{
        this.messageService.add({ severity: 'success', summary:'Accept Order', detail: 'Order accepted successfully.'});
        let orderUStatus = APIHelper.ORDER_APPROVED;
        if(!order.reference_order_id && order.order_type == "ecom_subscription") {
          orderUStatus = APIHelper.ORDER_COMPLETED;
        }

        this.changeOrderStatus(order.order_id,orderUStatus);
        order.order_status = orderUStatus;        
        order.order_status_obj = this.getOrderStatus(order,order.order_status);
        let copyOfOrder = JSON.parse(JSON.stringify(order));
        copyOfOrder.order_status = APIHelper.ORDER_APPROVED;
        this.changeStatusOfSubOrder(copyOfOrder);
      }
    },error => {
      this.messageService.add({ severity: 'error', summary:'Accept Order', detail: 'Check your internet connection.'});
      this.loading = false;
    });
  }

  rejectOrder(order,rejection_message){
    this.edit_items = false;
    let sendData = {
      'shop_code':localStorage.getItem('shop_code'),
      'order_id':order.order_id,
      'rejection_message': rejection_message,
      'partner_short_code': order.partner_short_codes,
      'partner_name': order.partner_names,
      'order_status': this.getTypeCode('ORDER_REJECTED'),
    }

    this.loading = true;
    this.recentOrdersService.rejectSelectedOrder(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if(ordersData.status!==0 && ordersData.code != "-165"){
        alert(ordersData.message);
      }else{
        this.messageService.add({ severity: 'success', summary:'Reject Order', detail: 'Order rejected successfully.'});
        this.changeOrderStatus(order.order_id,APIHelper.ORDER_REJECTED);        
        order.order_status = APIHelper.ORDER_REJECTED;
        order.order_status_obj = this.getOrderStatus(order,order.order_status);

        let ordersList = JSON.parse(localStorage.getItem('not_completed_orders')) || [];
        let tempList = JSON.parse(JSON.stringify(ordersList));
        for (var i = 0; i < tempList.length; ++i) {
          if(tempList[i].order_id == order.order_id){
            ordersList.splice(i,1);
            localStorage.setItem('not_completed_orders',JSON.stringify(ordersList));
            break;
          }
        }

        this.changeStatusOfSubOrder(order);
      }
    },error => {
      this.messageService.add({ severity: 'error', summary:'Reject Order', detail: 'Check your internet connection.'});      
      this.loading = false;
    });
  }

  deliverOrder(order){
    let sendData = {
      'shop_code':localStorage.getItem('shop_code'),
      'order_id':order.order_id,
      'partner_short_code': order.partner_short_codes,
      'partner_name': order.partner_names,
      'order_status': this.getTypeCode('ORDER_DELIVERED'),
    }
    this.loading = true;
    this.recentOrdersService.deliverSelectedOrderInfo(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if(ordersData.status!==0){
        alert(ordersData.message);
      }else{
        this.messageService.add({ severity: 'success', summary:'Success', detail: 'Order marked as delivered'})
        this.changeOrderStatus(order.order_id,APIHelper.ORDER_DELIVERED);
        order.order_status = APIHelper.ORDER_DELIVERED;
        order.order_status_obj = this.getOrderStatus(order,order.order_status);
      }
    },error => {
      this.messageService.add({ severity: 'error', summary:'Deliver Order', detail: 'Check your internet connection.'});      
      this.loading = false;
    });
  }

  completeOrder(order){
    let sendData = {
      'shop_code':localStorage.getItem('shop_code'),
      'order_id':order.order_id,
      'partner_short_code': order.partner_short_codes,
      'partner_name': order.partner_names,
      'order_status': this.getTypeCode('ORDER_COMPLETED'),
    }
    this.loading = true;
    this.recentOrdersService.completeSelectedOrderInfo(sendData).subscribe((res) => {
      this.loading = false;
      let ordersData = res.json();
      if(ordersData.status == 0  || ordersData.code == "-188"){
        this.messageService.add({ severity: 'success', summary:'Success', detail: 'Order marked as delivered and completed'})       
        
        this.changeOrderStatus(order.order_id,APIHelper.ORDER_COMPLETED);
        order.order_status = APIHelper.ORDER_COMPLETED;
        order.order_status_obj = this.getOrderStatus(order,order.order_status);

        let id = ordersData.result.completedOrder.order_id;
        let ordersList = JSON.parse(localStorage.getItem('not_completed_orders')) || [];
        let tempList = JSON.parse(JSON.stringify(ordersList));
        for (var i = 0; i < tempList.length; ++i) {
          if(tempList[i].order_id == id){
            ordersList.splice(i,1);
            localStorage.setItem('not_completed_orders',JSON.stringify(ordersList));
            break;
          }
        }
      } else {
        alert(ordersData.message ? ordersData.message : "internal server error.");
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary:'Complete Order', detail: 'Check your internet connection.'});            
      this.loading = false;
    });
  }

  showRejectOrderReasonModal(order) {
    console.log('showRejectOrderReasonModal=>', order);
    if(order.third_party_order_id) {
      this.modalRef = this.modalService.show(ThirdPartyRejectOrderReasonModalComponent, {class: 'centred-modal'});
      this.modalRef.content.decision = "";
      if (order.partner_ids && order.partner_ids.length > 0) {
        this.modalRef.content.third_party_partner = order.partner_ids[0].partner_short_code;
        console.log('Order:', order.partner_ids[0].partner_short_code);
      }
      if(order.third_party_order_other_information && order.third_party_order_other_information.time_to_penalty_map && order.third_party_order_other_information.time_to_penalty_map.length>0) {
        this.modalRef.content.time_to_penalty_map = order.third_party_order_other_information.tim_to_penalty_map;
      }
      this.modalRef.content.order_created_at = new Date(order.createdAt); 
      let cSub:Subscription = this.modalService.onHide.subscribe(() => {
        if (this.modalRef.content.decision === 'done') {
          this.rejectOrder(order,this.modalRef.content.reject_reason);
        }
        cSub.unsubscribe();
      });
    } else {
      this.rejectOrder(order,"");
    }
  }
    
  changeOrderStatus(id,status){
    let orders = JSON.parse(localStorage.getItem('temp_orders_list')) || [];
    orders.forEach(temp_order =>{
      if(temp_order.order.order_id == id){
        temp_order.order.order_status = status;
      }
    });
    localStorage.setItem('temp_orders_list',JSON.stringify(orders));
  }

  chnageOrderSelfServiceStatus(id,status){
    let orders = JSON.parse(localStorage.getItem('temp_orders_list')) || [];
    orders.forEach(temp_order =>{
      if(temp_order.order.order_id == id){
        temp_order.order.self_service_order_status = status;
      }
    });
    localStorage.setItem('temp_orders_list',JSON.stringify(orders));
  }

  printCustomerDetail(order) {
    let billInfo = JSON.parse(JSON.stringify(order));
    let bill_no = "";
    if(billInfo.order_id){
      billInfo['order_id_info'] = billInfo.order_id.split("/");
      if(billInfo['order_id_info'][1]){
        bill_no = billInfo['order_id_info'][1]
      }
    }

    let printData = {
      customer_name:  billInfo.user ? billInfo.user.name : null,
      customer_address:  billInfo.user ? billInfo.user.address : null,
      customer_phone:  billInfo.user ? billInfo.user.phone : null,
      bill_number: bill_no
    }

    this.printHelper.fireCustomerAddressPrint(printData);
  }
}
