import { Component, OnInit, Output,ViewChild, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { GiftCardModalComponent } from '../gift-card-modal/gift-card-modal.component';
import { Subscription } from 'rxjs';
import { OTModalComponent } from '../otmodal/otmodal.component';
import { StateGuardService } from '../state-guard.service';
import { ParkModalComponent } from '../park-modal/park-modal.component';
import { Router } from '@angular/router';
import { OffersModalComponent } from '../offers-modal/offers-modal.component';
import { APIHelper } from '../helpers/api-helper';
import { DiscountsModalComponent } from '../discounts-modal/discounts-modal.component';
import { PagesComponent } from '../pages.component';
import { CustomerInfoComponent } from '../customer-info/customer-info.component';
import { HoldOrderModal } from '../hold-order-modal/hold-order-modal.component';
import { CancelKotItemReasonModal } from '../kot-item-cancel-reason-modal/kot-item-cancel-reason-modal.component';
import { ExceptionModal } from '../exception-modal/exception.component';
import { BasketComponent } from '../basket/basket.component';
import { PaymentComponent } from '../payment-component/payment.component';
import { CustomerService } from '../helpers/services/customer.service';
import { KotHelper } from '../helpers/kot-helper';
import { CaptainAppHelper } from '../helpers/captain-app-helper';
import { Message } from 'primeng/api';
import { CustomerMessage } from '../customer-message.service';
import { MessageService } from 'primeng/api';
import { CaptainAppService } from '../../pages/helpers/services/captain-app.service';
import { SelectsBoxComponent } from '../selects-box/selects-box.component';
import { Messenger } from '../messenger.service';
import { MenuComponent } from '../menu/menu.component';
import { CommonService } from '../helpers/services/common.service';

declare var $: any;

@Component({
  selector: 'app-options-box',
  templateUrl: './options-box.component.html',
  styleUrls: ['./options-box.component.less'],
})
export class OptionsBoxComponent implements OnInit {
  @Output() paymentState: EventEmitter<boolean> = new EventEmitter();
  @Output() customerInfo: EventEmitter<string> = new EventEmitter();

  offUnits;
  public modalRef: BsModalRef;
  isItFoodOrderingPage: boolean = true;
  buttonsEnabled: any;
  currency: any;
  deliveryEditor: boolean = false;
  isLoadingShow: boolean = false;
  isRestaurantFlag: boolean = true;
  guestsEditorOn: boolean = false;
  exceptionEditorOn: boolean = false;
  shopConfiguration: any = {};
  constructor(private modalService: BsModalService,
              private route: ActivatedRoute,
              private customerMessageService:CustomerMessage,
              private messageService:MessageService,
              private stateGuard: StateGuardService,
              private pagesComponent: PagesComponent,
              private router: Router,
              private selectsBoxComponent:SelectsBoxComponent,
              private captainAppService:CaptainAppService,
              private kotHelper: KotHelper,
              private customerService:CustomerService,
              private basketComponent: BasketComponent,
              private paymentComponent:PaymentComponent,
              private captainAppHelper:CaptainAppHelper,
              private commonService:CommonService,
              private basketMessageService: Messenger,
              private menuComponent: MenuComponent) {
    route.paramMap.subscribe(
      params => {
        this.isItFoodOrderingPage = this.route.snapshot.url && this.route.snapshot.url[0] && this.route.snapshot.url[0].path === "food-ordering" ? true : false;
        this.buttonsEnabled = {
          home: this.isItFoodOrderingPage,
          tables: this.isItFoodOrderingPage,
          covers: this.isItFoodOrderingPage,
          delivery: this.isItFoodOrderingPage,
          hold: this.isItFoodOrderingPage,
          park: true,
          offers: true,
          exception: true,
          employee_acct: this.isItFoodOrderingPage,
          no_charge: this.isItFoodOrderingPage,
          quickAction: this.isItFoodOrderingPage,
          edit: this.isItFoodOrderingPage,
          discount: this.isItFoodOrderingPage,
          gift: this.isItFoodOrderingPage,
          quick_cash: this.isItFoodOrderingPage,
          quick_card: this.isItFoodOrderingPage,
          ot: (localStorage.getItem('isRestaurant') == 'true' && this.isItFoodOrderingPage) ? true : false,
          payment: this.isItFoodOrderingPage
        }
      }
    );

    this.isItFoodOrderingPage = this.route.snapshot.url && this.route.snapshot.url[0] && this.route.snapshot.url[0].path === "food-ordering" ? true : false;
  }

  public currentUnit;
  public subscriptions: Subscription[] = [];
  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnInit() {
    var self = this;
    self.currentUnit = self.stateGuard.obtain("selects-box.component:currentUnit");

    this.shopConfiguration = JSON.parse(localStorage.getItem('shop_configuration')) || {};
    this.currency = this.shopConfiguration.currency;
    this.offUnits = this.stateGuard.obtain('basket.component:offUnits') || [];


    if(!this.offUnits || (Array.isArray(this.offUnits) && this.offUnits.length == 0)){
      this.stateGuard.entrust("basket.component:offUnits", (localStorage.getItem('offUnits') && localStorage.getItem('offUnits') != 'undefined' && localStorage.getItem('offUnits') != 'null' && JSON.parse(localStorage.getItem('offUnits'))) ? JSON.parse(localStorage.getItem('offUnits')) : []);
      this.offUnits = this.stateGuard.obtain("basket.component:offUnits") || [];
    }
    // console.log('basket.component:offUnits: '+JSON.stringify(this.stateGuard.obtain('basket.component:offUnits')))
    

    if(localStorage.getItem('shop_configuration') && this.offUnits && this.offUnits.length > 0){
      let enable_tables = JSON.parse(localStorage.getItem('shop_configuration')).enable_tables;
      this.offUnits.enable_tables = enable_tables;
    }    

    if(localStorage.getItem('isRestaurant') == 'false'){
      this.isRestaurantFlag = false
    } else if(self.currentUnit.isParked || self.currentUnit.isOnHold || self.currentUnit.delivery_type == APIHelper.PICK_UP || self.currentUnit.delivery_type == APIHelper.HOME_DELIVERY) {
      this.buttonsEnabled.tables = false;
      //this.buttonsEnabled.ot = false;
      if(self.currentUnit.isParked){
        this.buttonsEnabled.ot = false;
      }
      if(self.currentUnit.delivery_type == APIHelper.PICK_UP || self.currentUnit.delivery_type == APIHelper.HOME_DELIVERY) {
        this.buttonsEnabled.covers = false;
      }
    }
  }

  getCustomerInfo(token, place) {
    if(navigator.onLine) {
      this.customerService.info(token).subscribe((res) => {
        var result = res.json();

        var sData: any = {}
        if (result.status === 0) {
          if (token != APIHelper.DEFAULT_TOKEN) {
            sData = {
              auth_token: token,
              consumer_name: (result.result.consumer_name) ? result.result.consumer_name : "",
              email: (result.result.email) ? result.result.email : "",
              mobile_no: (result.result.alt_address && result.result.alt_address.length > 0 && result.result.alt_address[(result.result.alt_address.length - 1)].contact_no) ? result.result.alt_address[(result.result.alt_address.length - 1)].contact_no : '',
              zip_code: (result.result.alt_address && result.result.alt_address.length > 0 && result.result.alt_address[(result.result.alt_address.length - 1)].zip_code) ? result.result.alt_address[(result.result.alt_address.length - 1)].zip_code : null,
              address_1: (result.result.alt_address && result.result.alt_address.length > 0 && result.result.alt_address[(result.result.alt_address.length - 1)].address) ? result.result.alt_address[(result.result.alt_address.length - 1)].address : "",
              city: result.result.city,
              state: result.result.state,
              last_purchase: (result.result.last_purchase) ? result.result.last_purchase : 0,
              last_visited: (result.result.last_visited) ? result.result.last_visited : new Date(),
              rewards_points: (result.result.rewards_points) ? result.result.rewards_points : 0,
              diary_amt: (result.result.diary_amt) ? result.result.diary_amt : 0,
              points: (result.result.rewards_points) ? result.result.rewards_points : 0,
              lastCheck: (result.result.last_purchase) ? result.result.last_purchase : 0,
              lastVisit: (result.result.last_visited) ? result.result.last_visited : new Date(),
            }
            if (place == APIHelper.IMMEDIATE) {
              sData['place'] = 'here';
            } else if (place == APIHelper.PICK_UP) {
              sData['place'] = 'pickup';
            } else if (place == APIHelper.HOME_DELIVERY) {
              sData['place'] = 'home';
            }
            this.customerMessageService.sendCustomerInfo(sData,false);
          }
        }
      });
    }
  }

  onPaymentClick(type, selectedException) {
    let tempUnit = JSON.parse(JSON.stringify(this.stateGuard.obtain("selects-box.component:currentUnit") || []));
    if(!this.shopConfiguration || !this.shopConfiguration || !this.shopConfiguration.shop_code) {
      this.shopConfiguration = JSON.parse(localStorage.getItem('shop_configuration')) || {};
    }
    if(this.shopConfiguration && this.shopConfiguration.is_consumer_phone_required && tempUnit && ((tempUnit.userInfo && (!tempUnit.userInfo.mobile_no || tempUnit.userInfo.mobile_no == "0000000000")) || !tempUnit.userInfo )) {
      this.messageService.add({ severity: 'error', summary: 'Register customer', detail: 'Please first register the customer by phone number.' });
      return;
    }

    var is_membership_item_available = 0;    
    if (!selectedException || (selectedException && ['no_charge','employee_acct'].indexOf(selectedException) != -1)) {
      if (tempUnit && tempUnit.delivery_type && [APIHelper.HOME_DELIVERY,APIHelper.PICK_UP].indexOf(tempUnit.delivery_type) != -1 && tempUnit.visitors && tempUnit.visitors.length > 0){
        if(this.shopConfiguration) {
          if(tempUnit.delivery_type == APIHelper.HOME_DELIVERY && !this.shopConfiguration.is_available_home_delivery) {
            this.messageService.add({ severity: 'error', summary: 'Home Delivery', detail: 'Home Delivery not enable' });
            return;
          } else if(tempUnit.delivery_type == APIHelper.HOME_DELIVERY && !this.shopConfiguration.is_available_home_delivery) {
            this.messageService.add({ severity: 'error', summary: 'PICK UP', detail: 'pickup order not enable' });
            return;
          }
        }

        let order_total = 0;    
        for (var i = 0; i < tempUnit.visitors.length; i++) {
          if (tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
            tempUnit.visitors[i].dishes[0].forEach(item => {
              order_total += ((item.unit_price) ? parseFloat(item.unit_price) : 0) * ((item.item_count) ? parseFloat(item.item_count) : 0)
            })
          }
        }

        if(tempUnit.delivery_type == APIHelper.HOME_DELIVERY  && order_total<this.shopConfiguration.home_delivery_purchase_limit) {
          this.messageService.add({ severity: 'error', summary: 'Low purchase amount', detail: 'Your purchase amount is too low for delivery.' });
          return;
        } else if(tempUnit.delivery_type == APIHelper.PICK_UP  && order_total<this.shopConfiguration.pickup_purchase_limit) {
          this.messageService.add({ severity: 'error', summary: 'Low purchase amount', detail: 'Your purchase amount is too low for pickup the order.' });
          return;
        }
      }
      if (tempUnit.visitors && tempUnit.visitors.length > 0 && tempUnit.visitors[0].dishes[0].length > 0) {

        if(tempUnit.visitors.length > 1) {
          let is_item_added = false;
          for (var i = 0; i < tempUnit.visitors.length; i++) {
            if (i != 0 && tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
              is_item_added = true;
              break;
            }
          }

          for (var i = 0; i < tempUnit.visitors.length; i++) {
            if (i != 0 && tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
              is_item_added = true;
              break;
            }
          }

          if (is_item_added) {
            this.messageService.add({ severity: 'error', summary: 'Default cart', detail: 'Please assign default cart items to respective seats' });
            return;
          }
        }

        for (var i = 0; i < tempUnit.visitors.length; i++) {
          if (tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
            tempUnit.visitors[i].dishes[0].forEach(item => {              
              if(item.category_code == "MEMBERSHIP") {is_membership_item_available = 1;}
            })
            if(is_membership_item_available) {break;}
          }
        }

        if(!navigator.onLine && is_membership_item_available) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "For membership item purchase internet neccessary" });
          return;
        }

        if(is_membership_item_available && ((!tempUnit.token || tempUnit.token == "00000000000000000000000000000000") || !tempUnit.userInfo.user_id)) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please first register the customer by phone number." });
          return;
        }        
      }
    }

    let is_item_added = false;
    if (tempUnit && tempUnit.visitors) {
      for (var i = 0; i < tempUnit.visitors.length; i++) {
        if (tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
          is_item_added = true;
          break;
        }
      }
    }
    if (!tempUnit.is_cancel_order_from_cart && !is_item_added) {
      this.messageService.add({ severity: 'info', summary: 'Information', detail: 'First Add items in cart' });
      return;
    } 

    let orderDetails = this.stateGuard.obtain("payment.component:orderDetail");
    if (type == "payment") {
        this.router.navigate(['/pages/register/food-ordering/payment']);
    } else if(orderDetails && orderDetails.orderTotal >=0){

      if (type == 'other') {
        this.isLoadingShow = true;
        this.paymentComponent.putBillInBackground(type).then(isDone => {this.isLoadingShow = isDone});
      } else if (type == "cash" || type == "card") {    

        
          this.buttonsEnabled.quick_cash = false;
          this.buttonsEnabled.quick_card = false;
          this.isLoadingShow = true;
          this.paymentComponent.putBillInBackground(type).then(isDone => {
            this.isLoadingShow = isDone;
            //this.buttonsEnabled.quick_cash = true;
            //this.buttonsEnabled.quick_card = true;
          });      
      } else if (type == 'exception') {

        this.exceptionEditorOn = !this.exceptionEditorOn;
        this.modalRef = this.modalService.show(ExceptionModal, { class: 'common-modal' });
        this.modalRef.content.decision = "";
        this.modalRef.content.excepitonInfoModal.note = "";
        this.modalRef.content.excepitonInfoModal.title = 'No Charge';
        this.modalRef.content.excepitonInfoModal.selectedUser = null;
        this.modalRef.content.excepitonInfoModal.selectedException = selectedException;

        this.subscriptions.push(this.modalService.onHide.subscribe(() => {
          if (this.modalRef.content.decision === 'submit') {
            this.isLoadingShow = true;
            let comment = this.modalRef.content.excepitonInfoModal.note;
            let userId = null;
            if (this.modalRef.content.excepitonInfoModal.selectedUser) {
              userId = this.modalRef.content.excepitonInfoModal.selectedUser;
            }
            var noChargeDetails = { "noChargeNote": comment, "employeeId": userId };
            let unit = this.stateGuard.obtain("selects-box.component:currentUnit");
            unit.noChargeFlag = 'true';
            unit.noChargeNote = JSON.stringify(noChargeDetails);
            debugger
            this.paymentComponent.putBillInBackground('other').then(isDone => {this.isLoadingShow = isDone});
          }
          this.unsubscribe();
        }));
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Credit Note Checkout', detail: 'Use payment button to checkout this order.' });
      return;
    }
  }

  makeQuickPayment(type) {

    let currentConsumer = JSON.parse(localStorage.getItem('current_customer_info'));
    if (navigator.onLine && currentConsumer) {
      this.customerService.info(currentConsumer.auth_token).subscribe((res) => {
        var result = res.json();
        if (result.status === 0) {
          let sendData = {
            'customer_auth_token': currentConsumer.auth_token,
            'name': result.result.consumer_name,
            'profile_img_url': result.result.profile_img_url,
            'phone': (result.result.alt_address && result.result.alt_address.length > 0) ? result.result.alt_address[result.result.alt_address.length - 1].contact_no : "",
            'rewards_pts': result.result.rewards_points,
            'address': (result.result.alt_address && result.result.alt_address.length > 0) ? result.result.alt_address[result.result.alt_address.length - 1].address : ""
          }
          //this.paymentComponent.putBillInBackground(type,sendData).then(isDone => {this.isLoadingShow = isDone});
        } else {
          alert(result.message);
        }
      });
    }
  }

  quickCashPayment() {
    //this.makeQuickPayment('cash');
    let tempUnit = JSON.parse(JSON.stringify(this.stateGuard.obtain("selects-box.component:currentUnit") || []));
    if (tempUnit && tempUnit.visitors && tempUnit.visitors[0] && Array.isArray(tempUnit.visitors[0].dishes) && tempUnit.visitors[0].dishes[0].length > 0) {
      this.paymentComponent.putBillInBackground('cash').then(isDone => {this.isLoadingShow = isDone});
    }
    else {
      alert("Cart can't be empty");
      return;
    }
  }

  quickCardPayment() {
    //this.makeQuickPayment('card');
    //this.paymentComponent.putBillInBackground('card').then(isDone => {this.isLoadingShow = isDone});
    let tempUnit = JSON.parse(JSON.stringify(this.stateGuard.obtain("selects-box.component:currentUnit") || []));
    if (tempUnit && tempUnit.visitors && tempUnit.visitors[0] && Array.isArray(tempUnit.visitors[0].dishes) && tempUnit.visitors[0].dishes[0].length > 0) {
      this.paymentComponent.putBillInBackground('card').then(isDone => {this.isLoadingShow = isDone});
    }
    else {
      alert("Cart can't be empty");
      return;
    }
  }

  select_delivery(deliveryType) {
    this.deliveryEditor = !this.deliveryEditor;
    localStorage.setItem('tempDeliveryType', deliveryType);

    if(deliveryType == "home") {
      let delivery_type_code = APIHelper.HOME_DELIVERY;
      localStorage.setItem("delivery_type",delivery_type_code);
    } else if(deliveryType == "pickup") {
      let delivery_type_code = APIHelper.PICK_UP;
      localStorage.setItem("delivery_type",delivery_type_code);
    }
    this.pagesComponent.setDeliveryType(deliveryType);    
    this.pagesComponent.openCustomerInfoModal({delivery_type: deliveryType});
  }

  getDeliverytype() {
    let type = localStorage.getItem('delivery_type');
    if (type) {
      if (type == APIHelper.HOME_DELIVERY) {
        return 'home';
      } else if (type == APIHelper.PICK_UP) {
        return 'pickup';
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getNonCompleteOrderCount() {
    let ordersList = [];
    try {
      ordersList = JSON.parse(localStorage.getItem('not_completed_orders'));
    } catch (e) {
      ordersList = [];
    }
    if (ordersList === null) ordersList = [];

    let third_party_new_order_count:any = 0;
    try {
      third_party_new_order_count = JSON.parse(localStorage.getItem('third_party_new_order_count')) || 0;
    } catch (e) {
      
    }
    return ordersList.length + ((third_party_new_order_count) ? parseInt(third_party_new_order_count) : 0);
  }

  onHomeBtnClick() {
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {

      if (this.modalRef.content.decision === 'park') { //hold
        this.putOnHold(true);
      } else if (this.modalRef.content.decision === 'clear') {
        this.basketComponent.clearCart(false);
        this.router.navigate(['/pages/register/place-selection/here']);
      } else if (this.modalRef.content.decision === 'cancel') {
        
        this.basketComponent.cancelOrder(0).then(isOk => {
          if(isOk) {
            this.onPaymentClick('other',true);
          } else {
            this.messageService.add({ severity: 'error', summary: "order is not cancelled", detail: '' });
          }
        })
        .catch(e => {
          console.log(e);
          this.messageService.add({ severity: 'error', summary: "order is not cancelled", detail: '' });
        })
      }
      this.unsubscribe();
      // this.menuComponent.recommendedItemList = []
      this.menuComponent.recommenderExpanded = false
    }));
    this.modalRef = this.modalService.show(HoldOrderModal, { class: 'common-modal' });
    this.modalRef.content.decision = "";
    this.modalRef.content.text = "Do you want to clear this order or park this order?";
    /*this.modalRef.content.type = "park_clear"; //park*/

    this.currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');

    let is_kot_order = false;
    if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]) {
      for(var i=0;i<this.currentUnit.visitors.length;i++) {
        if(this.currentUnit.visitors[i] && this.currentUnit.visitors[i].kot && this.currentUnit.visitors[i].kot.length > 0) {
          is_kot_order = true;
          break;
        }
      }
    }
    
    this.modalRef.content.type = (!is_kot_order) ? "park_clear" : "cancel";
    /*var self = this;
    this.basketComponent.clearCart(false);    
    self.router.navigate(['/pages/register/place-selection/here']);*/
  }


  saveOrder() {
    let tempPlace = this.stateGuard.obtain('selects-box.component:places');
    this.currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
    this.currentUnit.isOnHold = false;
    this.currentUnit.isFromOnHold = true;
    localStorage.setItem('saved_order', JSON.stringify(this.currentUnit));
    localStorage.setItem('saved_places', JSON.stringify(tempPlace));
  }

  onTableBtnClick() {
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'save') {

        let tempPlace = this.stateGuard.obtain('selects-box.component:places');
        this.currentUnit.isOnHold = false;
        this.currentUnit.isFromOnHold = true;
        this.captainAppHelper.syncTable(this.currentUnit,false);
        localStorage.setItem('saved_order', JSON.stringify(this.currentUnit));
        localStorage.setItem('saved_places', JSON.stringify(tempPlace));
        this.basketComponent.clearCustomerInfo();
        this.router.navigate(['/pages/register/place-selection/here']);
      } else if (this.modalRef.content.decision === 'clear') {
        localStorage.setItem('saved_order', "");

        /*let sendData = {
            section_name               : this.currentUnit.location,
            tables                     : this.currentUnit.tables,
            covers                     : 0,
            assigny                    : this.currentUnit.assigny,
            table_status               : 'idle',
            lock_index                 : this.currentUnit.lock_index, 
            temp_client_reference_code : null,
            kot_request_number         : 0,
            kot_request_description    : "",
            started_at                 : null, 
            is_clear                   : true,
            kot_ready_number           : 0,
            is_checkout_call           : false,
            visitors                   : []
        }
        this.captainAppHelper.syncParticularTable(sendData,false);*/
        this.basketComponent.clearCart(false); 
        this.router.navigate(['/pages/register/place-selection/here']);  
      } else if (this.modalRef.content.decision === 'cancel') {
        
        this.basketComponent.cancelOrder(0).then(isOk => {
          if(isOk) {
            this.onPaymentClick('other',true);
          } else {
            this.messageService.add({ severity: 'error', summary: "order is not cancelled", detail: '' });
          }
        })
        .catch(e => {
          console.log(e);
          this.messageService.add({ severity: 'error', summary: "order is not cancelled", detail: '' });
        })
      }
      this.unsubscribe();
      // this.menuComponent.recommendedItemList = []
    }));
    this.modalRef = this.modalService.show(HoldOrderModal, {class: 'common-modal'});
    this.modalRef.content.decision = "";
    this.modalRef.content.text = "Do you want to discard the order or save this order?";
    this.currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');

    let is_kot_order = false;
    if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]) {
      for(var i=0;i<this.currentUnit.visitors.length;i++) {
        if(this.currentUnit.visitors[i] && this.currentUnit.visitors[i].kot && this.currentUnit.visitors[i].kot.length > 0) {
          is_kot_order = true;
          break;
        }
      }
    }
    
    this.modalRef.content.type = (!is_kot_order) ? "save" : "cancel";
    
  }
 

  showGiftCardModal () {
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'done') {
      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(GiftCardModalComponent, {class: 'common-modal'});
    this.modalRef.content.decision = "";
  }
  showDiscountsModal () {
    let is_membership_item_available = 0;
    let unit = this.stateGuard.obtain("selects-box.component:currentUnit");
    
    if(!unit) { 
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "No cart found." });
      return; 
    }

    let tempUnit = JSON.parse(JSON.stringify(unit));    
    for (var i = 0; i < tempUnit.visitors.length; i++) {
      if (tempUnit.visitors[i].dishes && tempUnit.visitors[i].dishes[0].length > 0) {
        tempUnit.visitors[i].dishes[0].forEach(item => {              
          if(item.category_code == "MEMBERSHIP") {is_membership_item_available = 1;}
        })
        if(is_membership_item_available) {break;}
      }
    }

    if(is_membership_item_available) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "For Membership Item discount can not apply." });
      return;
    }

    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'done') {
        let discountUnit = (this.modalRef.content.isPercentsDiscount) ? "%" : this.currency;
        let discountAmount = (this.modalRef.content.discount) ? this.modalRef.content.discount : 0;
        let discountReason = (this.modalRef.content.discount_reason) ? this.modalRef.content.discount_reason : null;
        let discount_phone_no = (this.modalRef.content.otp_verification && this.modalRef.content.otp_verified) ? this.modalRef.content.mobile_no : null;
        this.currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
        this.currentUnit.discount = discountAmount;
        this.currentUnit.discountUnit = discountUnit;
        this.currentUnit.discount_reason = discountReason;
        this.currentUnit.discount_phone_no = discount_phone_no;
      }else if (this.modalRef.content.decision === 'cash_discount') {
        let cashDiscountUnit = (this.modalRef.content.isPercentsDiscount) ? "%" : this.currency;
        let cashDiscountAmount = (this.modalRef.content.discount) ? this.modalRef.content.discount : 0;
        let discount_phone_no = (this.modalRef.content.otp_verification && this.modalRef.content.otp_verified) ? this.modalRef.content.mobile_no : null;
        this.currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
        this.currentUnit.cash_discount = cashDiscountAmount;
        this.currentUnit.cashDiscountUnit = cashDiscountUnit;
        this.currentUnit.discount_phone_no = discount_phone_no;
      }else if(this.modalRef.content.decision === 'applied_discount'){
        let discountReason = (this.modalRef.content.discount_reason) ? this.modalRef.content.discount_reason : null;
        let discount_phone_no = (this.modalRef.content.otp_verification && this.modalRef.content.otp_verified) ? this.modalRef.content.mobile_no : null;
        let orderDetails = this.stateGuard.obtain("payment.component:orderDetail");
        orderDetails.discount_reason = discountReason;
        orderDetails.discount_phone_no = discount_phone_no;
        this.basketComponent.checkForDiscount();

      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(DiscountsModalComponent, {class: 'common-modal'});
    this.modalRef.content.decision = "";
  }
  showParkModal () {
    let self = this;
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'select' && this.modalRef.content.selectedHoldUnit) {        
        this.modalRef.content.selectedHoldUnit.isOnHold = false;        
        this.modalRef.content.selectedHoldUnit.isFromOnHold = false;  
        this.modalRef.content.selectedHoldUnit.chosen = true;        
        this.modalRef.content.selectedHoldUnit.isParked = true;  
        this.currentUnit = this.modalRef.content.selectedHoldUnit;
        let token = (this.currentUnit && this.currentUnit.token)? this.currentUnit.token : APIHelper.DEFAULT_TOKEN;
        let place = (this.currentUnit && this.currentUnit.delivery_type)? this.currentUnit.delivery_type : APIHelper.IMMEDIATE;
        localStorage.setItem('delivery_type',place);
        let userInfo = (this.currentUnit && this.currentUnit.userInfo)? this.currentUnit.userInfo : null;
        if(userInfo){
          this.customerMessageService.sendCustomerInfo(userInfo,false);
        }else{
          this.getCustomerInfo(token,place);
        }
        this.stateGuard.amend('basket.component:offUnits', function (offUnits) {          
          var findIndex = offUnits.findIndex(unit => {
            return (unit == self.modalRef.content.selectedHoldUnit);
          });

          if(findIndex != -1) {          
            offUnits.splice(findIndex,1);
          }

          localStorage.setItem('offUnits',JSON.stringify(offUnits));
        });        
        this.stateGuard.entrust("selects-box.component:currentUnit", this.modalRef.content.selectedHoldUnit)
        this.router.navigate(['/pages/register/food-ordering']);
      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(ParkModalComponent, {class: 'park-modal centred-modal'});        
    this.modalRef.content.offUnits = this.stateGuard.obtain("basket.component:offUnits") || [];    
    this.modalRef.content.decision = "";
  }
  onParkBtnClick() {  
    if(this.isItFoodOrderingPage){
      this.subscriptions.push(this.modalService.onHide.subscribe(() => {
          if (['hold','park'].includes(this.modalRef.content.decision)) {
            this.putOnHold(true);
          }else if(this.modalRef.content.decision === 'clear'){
            this.basketComponent.clearCart(false);
            this.router.navigate(['/pages/register/place-selection/here']);            
          }
          this.unsubscribe();    
          if(this.modalRef.content.decision != "resume") {
            this.showParkModal();
          }
      }));      
      let currentUnit = this.stateGuard.obtain('selects-box.component:currentUnit');
      let is_kot_order = false;
      if(currentUnit && currentUnit.visitors && currentUnit.visitors[0]) {
        for(var i=0;i<currentUnit.visitors.length;i++) {
          if(currentUnit.visitors[i] && currentUnit.visitors[i].kot && currentUnit.visitors[i].kot.length > 0) {
            is_kot_order = true;
            break;
          }
        }
      }

      this.modalRef = this.modalService.show(HoldOrderModal, {class: 'common-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.text = !is_kot_order ? 'Do you want to clear this order or hold this order?' : "Do you want to resume this order or park this order?"
      this.modalRef.content.type = is_kot_order ? 'park_resume' : "hold";
    }else{      
      this.showParkModal();
    } 
  }

  onClickOT(flag,index){
    var selfServiceOrKitchenPrinterPresent = false;
    let printerData = JSON.parse(localStorage.getItem('shop_printers'));
    if(printerData){
      var printers_list = printerData;
      var printerFound = false;
      for(var i = 0 ; i < printers_list.length ; i++){
       if(printers_list[i].print_type.indexOf('self_service') != -1 || printers_list[i].print_type.indexOf('kitchen') != -1 ){
         selfServiceOrKitchenPrinterPresent = true;
         break;
       }
      }
    }
    if((localStorage.getItem('isRestaurant') == 'false')){
      if(selfServiceOrKitchenPrinterPresent){
        // let tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
        // console.log("BEFORE CREATING KOT:"+JSON.stringify(tempUnit));

        // this.paymentComponent.createKOTobject();
        this.kotHelper.createOTforShopWithoutTables(flag,index).then((newKOTResult) =>{
          if(newKOTResult.is_new_kot_created) {
            if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]) {
              this.currentUnit.visitors.forEach(visitor => {
                if(visitor.dishes && Array.isArray(visitor.dishes[0])) {
                  visitor.dishes[0].forEach(dish => {                      
                    dish.isKotfound = true;                      
                  });
                }
              });
            }
            this.paymentComponent.prepareToPrintKOT(newKOTResult.newKot, this.currentUnit.covers);
            this.parkOrResume();
          } else {
            this.messageService.add({ severity: 'error', summary: "OT cannot be placed, no new items in the cart", detail: '' });
          }
        })

        // tempUnit = this.stateGuard.obtain("selects-box.component:currentUnit");
        // console.log("AFTER CREATING KOT:"+JSON.stringify(tempUnit));
      }else{
        alert("Neither self service nor kitchen printer found")
      }
    }else{
        var tempCurrentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") 
        // console.log("PRE KOT CREATION:"+JSON.stringify(tempCurrentUnit));

        this.kotHelper.onClickOT(flag,index).then((data) =>{
          tempCurrentUnit = this.stateGuard.obtain("selects-box.component:currentUnit") 
          // console.log("POST KOT CREATION:"+JSON.stringify(tempCurrentUnit));          
          if(data && data.is_new_kot_created) {
            if(this.currentUnit && this.currentUnit.visitors && this.currentUnit.visitors[0]) {
              this.currentUnit.visitors.forEach(visitor => {
                if(visitor.dishes && Array.isArray(visitor.dishes[0])) {
                  visitor.dishes[0].forEach(dish => {                      
                    dish.isKotfound = true;                      
                  });
                }
              });
            }
            this.kotHelper.prepareToPrintKOT();            
            if(tempCurrentUnit.delivery_type == APIHelper.IMMEDIATE && !tempCurrentUnit.isParked && !tempCurrentUnit.isOnHold){
              this.captainAppHelper.syncTable(tempCurrentUnit,false);
              this.basketComponent.clearCustomerInfo()
              this.router.navigate(['/pages/register/place-selection/here']);
            } else {
              this.parkOrResume();
            }
          } else {
            this.messageService.add({ severity: 'error', summary: "OT cannot be placed, no new items in the cart", detail: '' });
          }          
      })
    }
  }


  parkOrResume() {      
    if(this.isItFoodOrderingPage){
      this.subscriptions.push(this.modalService.onHide.subscribe(() => {

          if (this.modalRef.content.decision === 'park') { //hold
            this.putOnHold(true);
          }else if(this.modalRef.content.decision === 'resume'){ //clear
            this.basketMessageService.sendBasketComponentMessage({isResetCartEnable : 'false'});            
            // this.basketComponent.clearCart(false);
            // this.router.navigate(['/pages/register/place-selection/here']);
          }
          this.unsubscribe();
          // this.showParkModal();
      }));
      this.modalRef = this.modalService.show(HoldOrderModal, {class: 'common-modal'});
      this.modalRef.content.decision = "";
      this.modalRef.content.text = "Do you want to resume this order or park this order?";
      this.modalRef.content.type = "park_resume"; //hold
    }/*else{
      this.showParkModal();
    } */
  }

  onClickNoSell(){
    let unit = this.stateGuard.obtain("selects-box.component:currentUnit");
    if(unit.isFromOnHold){
        this.currentUnit.isOnHold = false;
        this.currentUnit.isFromOnHold = true;
        localStorage.setItem('saved_order', JSON.stringify(this.currentUnit));
        localStorage.setItem('saved_places', JSON.stringify(unit));
        this.basketComponent.clearCustomerInfo();
        this.router.navigate(['/pages/register/place-selection/here']);
    }else if(unit.isParked){
      this.putOnHold(true);
    }else{
      this.basketComponent.clearCart(false);
      this.router.navigate(['/pages/register/place-selection/here']);
    }
    
  }

  putOnHold (isClearCart) {           
    let unit = this.stateGuard.obtain("selects-box.component:currentUnit");
    
    if(!unit) {
      return;
    }

    let tempUnit = JSON.parse(JSON.stringify(unit));
    let items: any = [];
    tempUnit.visitors.forEach((seat, index) => {      
      if(seat.dishes && seat.dishes[0]) {
        items = items.concat(seat.dishes[0]);
      }            
    });
    if(!items || items.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No items in cart' });
      return
    }

    localStorage.setItem('saved_order',"");    

    if(tempUnit.isOnHold) {      
      this.router.navigate(['/pages/register/place-selection/here']);        
      return;
    }

    tempUnit.isOnHold = true;
    tempUnit.isFromOnHold = false;
    tempUnit.isParked = false;
    this.stateGuard.amend('basket.component:offUnits', function (offUnits) {
      offUnits.push(tempUnit);
      localStorage.setItem('offUnits',JSON.stringify(offUnits));
    });

    if(isClearCart) {
      this.basketComponent.clearCart(false);
    }

    this.router.navigate(['/pages/register/place-selection/here']);        
  }

  showUncompleteOrders(){

    if(navigator.onLine){
      this.router.navigate(['/pages/recent-orders',{'isForNonCompleteOrders':'true'}]);
    }else{
      this.messageService.add({ severity: 'info', summary: 'Information', detail: 'Internet is not available' });
      return;
    }
  }

  showOffersModal () {
    this.subscriptions.push(this.modalService.onHide.subscribe(() => {
      if (this.modalRef.content.decision === 'done') {

      }
      this.unsubscribe();
    }));
    this.modalRef = this.modalService.show(OffersModalComponent, {class: 'offers-modal common-modal'});
    this.modalRef.content.decision = "";
  }
  openPayment() {
    this.paymentState.emit(true);
  }
  editGuestAmount (direction) {
    var targetIndex = 0, weakest;    
    this.guestsEditorOn = false;
    if (direction === "+") {  
      let device_type = JSON.parse(localStorage.getItem('device_key'));              
      let terminal_name = (device_type && device_type.terminal_name)? device_type.terminal_name: null;      
      let teminal_id  = (device_type && device_type.terminal_id)? device_type.terminal_id: null;      
      let temp_client_reference_code = terminal_name+"-"+teminal_id+'/WBP' + (new Date()).getTime();               
      this.currentUnit.visitors.push({dishes: [[],[]],kot:[],temp_client_reference_code:temp_client_reference_code });
    } else {
      if (!this.currentUnit.visitors.length || this.currentUnit.visitors.length<=1) {
        return; //this.messageService.add({ severity: 'error', summary: "", detail: '' });
      }
      weakest = this.currentUnit.visitors[0].dishes.length;
      this.currentUnit.visitors.forEach((visitor, index) => {
        if (!visitor.dishes.length) {
          targetIndex = index;
          return;
        }
        let dishesNum = visitor.dishes.length;
        if (weakest >= dishesNum) {
          weakest = dishesNum;
          targetIndex = index;
        }
      });
      this.currentUnit.visitors.splice(targetIndex, 1);
    }
    this.currentUnit.covers = this.currentUnit.visitors.length;
  }

  
}
