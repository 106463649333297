// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  	production: false,
  	firebase: {
	    apiKey: "AIzaSyCJAzRal28wMRPrFeBtbhU4pUjNxjhm6RI",
		authDomain: "testwebpush-56f6f.firebaseapp.com",
		databaseURL: "https://testwebpush-56f6f.firebaseio.com",
		projectId: "testwebpush-56f6f",
		storageBucket: "testwebpush-56f6f.appspot.com",
		messagingSenderId: "761532239539",
		appId: "1:761532239539:web:1ff5198ef15413807ceac6",
	    vapidKey: "BElXsqH8vZ5Mo0lWwWU4QQM9isxvTwn1engXwr1dCGOkt7phU67MAMIjiDVm9rSkzueJWItKuLEx8i0cjv8Iq_I"
	}
};

