import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.less']
})
export class KeyboardComponent implements OnInit {
  @Output("keyboard-action") keyPress: EventEmitter<any> = new EventEmitter()
  capsed: boolean = false;
  constructor() { }

  ngOnInit() {}
  model = [{keys: ["q","w","e","r","t","y","u","i","o","p"]},
           {keys: ["a","s","d","f","g","h","j","k","l"]},
           {keys: ["z","x","c","v","b","n","m","."]}];

  keyPressed(key) {
    this.keyPress.emit(this.capsed && typeof key === "string" ? key.toUpperCase() : key);
  }

}
