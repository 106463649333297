import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { APIHelper } from "../../helpers/api-helper";
import { ReportService } from "../../helpers/services/report.service";
import { ReportHelper } from "../../helpers/report-helper";
import { GeneralHelper } from '../../helpers/genral-helper';
import { forOwn, groupBy, union, keys, sumBy, remove } from 'lodash-es';
import { MessageService } from "primeng/api";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SalesPrintChecksModalComponent } from "./sales-print-checks-modal/sales-print-checks-modal.component";
import { Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.css"],
  providers: [ReportService]
})
export class SalesComponent implements OnInit {
  public subscriptions: Subscription[] = [];
  public modalRef: BsModalRef;

  printerUtility: boolean;

  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
  public consumerReportData: any;
  public reportData: any;
  public currencySymbol: any;
  public currencyCode: any;
  public isDataLoad: boolean = false;
  public isClearDiaryFullBlockShow: boolean = false;
  public isAdvanceDiaryFullBlockShow: boolean = false;
  public reportHelper: ReportHelper = new ReportHelper(this.http);
  public consumerReportDate: any;
  public shopConfiguration: any;
  public merchantProfile: any;
  public isOnline: boolean = false;
  public isRestaurant =
    localStorage.getItem("isRestaurant") == "true" ? true : false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private report: ReportService,
    private modalService: BsModalService,
    private messageService: MessageService
  ) { }
  ngOnInit() {
    this.shopConfiguration = JSON.parse(
      localStorage.getItem("shop_configuration")
    );
    if (!this.shopConfiguration) {
      this.router.navigate(["verify-pin"]);
      return;
    }
    this.merchantProfile = JSON.parse(localStorage.getItem("merchant_profile"));
    var date = new Date();
    var mm: any = new Date(date.toDateString()).getMonth();
    mm = mm + 1;
    if (mm.toString().length === 1) {
      mm = "0" + mm;
    }
    var dd: any = new Date(date.toDateString()).getDate();
    var yy: any = new Date(date.toDateString()).getFullYear();
    if (dd.toString().length === 1) {
      dd = "0" + dd;
    }
    var shop_code = localStorage.getItem("shop_code");
    var from_date = yy + "-" + mm + "-" + dd + " 00:00:00";
    var to_date = yy + "-" + mm + "-" + dd + " 23:59:59";

    let shop_config = JSON.parse(localStorage.getItem("shop_configuration"));
    this.currencySymbol = shop_config.currency;
    this.currencyCode = shop_config.currency_symbol;

    this.isOnline = navigator.onLine;
    if (!this.isOnline) {
      this.isDataLoad = false;
      /*this.getOfflineReport().then(result => {
                this.consumerReportData = result;
                let tempObj = {
                    open_cash : 0,
                    close_cash : 0
                }
                let shop_open_close_data = JSON.parse(localStorage.getItem('all_cash_register_sessions')) || [];
                if(Array.isArray(shop_open_close_data) && shop_open_close_data[0]){
                    tempObj.open_cash = shop_open_close_data[0].total_expected;
                    tempObj.close_data = shop_open_close_data[(shop_open_close_data.length - 1)].actual_cash;
                }
                this.consumerReportData['shop_open_close_data'] = tempObj;
                      
            })*/
    } else {
      this.report
        .getSalesSummaryReport(shop_code, from_date, to_date)
        .subscribe(report => {
          var res = report.json();
          if (res.status == 0) {
            if (res.result && res.result[0]) {
              if (res.result[0].normal_order_payment_summary) {
                let msCode = [];
                for (var x in res.result[0].normal_order_payment_summary) {
                  if (res.result[0].normal_order_payment_summary[x].is_sub_class == true) {
                    msCode.push(res.result[0].normal_order_payment_summary[x].code);
                  }
                }

                remove((res.result[0].normal_order_payment_summary), (nos: any) => { return nos.is_sub_class == false && msCode.indexOf(nos.code) != -1 });
              }
              this.consumerReportData = res.result[0];
              this.consumerReportData.grand_rounding_total = GeneralHelper.toFloatFixedDecimal(this.consumerReportData.grand_rounding_total);
              this.isDataLoad = true;
              // console.log(JSON.stringify(this.consumerReportData));
            }
            if (res.date) {
              if (res.date.from_date) {
                var tempDate = new Date(res.date.from_date);
                res.date.from_date =
                  tempDate.toLocaleDateString() +
                  " " +
                  tempDate.toLocaleTimeString();
              }

              if (res.date.to_date) {
                var tempDate = new Date(res.date.to_date);
                res.date.to_date =
                  tempDate.toLocaleDateString() +
                  " " +
                  tempDate.toLocaleTimeString();
              }
              this.consumerReportDate = res.date;
              var tempDate = new Date();
              this.consumerReportDate.date =
                tempDate.toLocaleDateString() +
                " " +
                tempDate.toLocaleTimeString();
            }
          }
          //this.consumerReportDate = res.date;
        });
    }
  }

  getDairyAmount(data: any[]) {
    let amount = 0;
    if (Array.isArray(data)) {
      data.forEach(it => {
        if (it.is_main_class) {
          amount += it.amount;
        }
      });
    }
    return amount;
  }

  getOfflineReport(): Promise<any> {
    let self = this;
    return new Promise<any>(function (resolve, reject) {
      let shopConfig: any =
        JSON.parse(localStorage.getItem("shop_configuration")) || {};
      let placedOrder: any =
        JSON.parse(localStorage.getItem("temp_orders_list")) || [];
      let offlineOrder: any =
        JSON.parse(localStorage.getItem("bill_queue")) || [];
      let paymentTypes =
        JSON.parse(localStorage.getItem("payment_types")) || [];
      let paymentSubClassInfoObj = shopConfig.payment_class || {};
      let shopOrders = [];
      let groupedOrderInfo = [];
      let kotItems = [];
      let rejectedOrders = [];

      if (!Array.isArray(placedOrder)) {
        placedOrder = [];
      }
      if (!Array.isArray(offlineOrder)) {
        offlineOrder = [];
      }

      placedOrder = placedOrder.concat(offlineOrder);
      placedOrder.forEach(
        (order: {
          order: {
            diary_history: any;
            discount_info: any;
            items: any;
            user: any;
          };
          diary_history: any;
          discount_info: any;
          items: any;
          user: any;
        }) => {
          order.order.diary_history = order.diary_history;
          order.order.discount_info = order.discount_info;
          order.order.items = order.items;
          order.order.user = order.user;
          shopOrders.push(order.order);
        }
      );

      placedOrder.forEach(
        (order: { kot_items: any[]; order_status: string }) => {
          if (order.kot_items && order.kot_items.length > 0) {
            order.kot_items.forEach((kot_item: { items: any[] }) => {
              if (kot_item.items && kot_item.items.length > 0) {
                kot_item.items.forEach((item: { is_billed: any }) => {
                  if (!item.is_billed) {
                    kotItems.push(item);
                  }
                });
              }
            });
          }
          if (order.order_status == APIHelper.ORDER_REJECTED) {
            rejectedOrders.push(order);
          }
        }
      );

      var total_rejected_order_amount = 0.0;
      var total_rejected_order_count = rejectedOrders.length;
      for (var i = 0; i < total_rejected_order_count; i++) {
        total_rejected_order_amount += Math.abs(rejectedOrders[i].valued_at);
      }

      var normal_order_summary = {};
      var no_change_orders_summary = {};
      var employee_orders_summary = {};
      var cancel_orders_summary = {};

      var normalShopOrders = shopOrders.filter(order => {
        return (
          order.order_id.indexOf("CAN-") == -1 &&
          !order.prev_client_reference_code &&
          order.order_id.indexOf("NC-") == -1 &&
          (!order.employee_id || order.employee_id == -1)
        );
      });

      var cancelShopOrders = shopOrders.filter(order => {
        return (
          order.order_id.indexOf("CAN-") != -1 &&
          order.prev_client_reference_code
        );
      });

      var employeeShopOrders = shopOrders.filter(order => {
        return (
          order.order_id.indexOf("CAN-") == -1 &&
          order.employee_id &&
          order.employee_id != -1
        );
      });

      var noChargeShopOrders = shopOrders.filter(order => {
        return (
          order.order_id.indexOf("CAN-") == -1 &&
          order.order_id.indexOf("NC-") != -1 &&
          order.employee_id == -1
        );
      });

      var allOrders = [];
      allOrders.push(normalShopOrders);
      allOrders.push(employeeShopOrders);
      allOrders.push(noChargeShopOrders);
      allOrders.push(cancelShopOrders);
      var allOrdersLock = allOrders.length;

      self.reportHelper
        .getKotDeletedItemDetail(kotItems)
        .then(result => {
          var cancel_kot_order_total = 0;
          var cancel_kot_order_item_count_total = 0;

          var home_delivery_order_total = 0;
          var home_delivery_order_count = 0;

          var pickup_order_total = 0;
          var pickup_order_count = 0;

          normalShopOrders.forEach(order => {
            if (order.shipping_type == APIHelper.HOME_DELIVERY) {
              home_delivery_order_total += order.valued_at;
              home_delivery_order_count++;
            }

            if (order.shipping_type == APIHelper.PICK_UP) {
              pickup_order_total += order.valued_at;
              pickup_order_count++;
            }
          });

          forOwn(
            result,
            (kotItems: { total_price: number; item_list: any[] }) => {
              cancel_kot_order_total += kotItems.total_price;
              cancel_kot_order_item_count_total += kotItems.item_list.reduce(
                function (a: any, b: { item_count: any }) {
                  return a + b.item_count;
                },
                0
              );
            }
          );
          allOrders.forEach((orderDetails, index) => {
            var consumerList = [],
              others_amount = 0.0,
              cash_amount = 0.0,
              card_amount = 0.0,
              total = 0.0,
              payment_type_summary = {},
              grand_cash_total = 0.0,
              grand_card_total = 0.0,
              grand_diary_total = 0.0,
              grand_other_total = 0.0,
              grand_total = 0.0,
              total_unique_users = [],
              total_extra_charges = [],
              grand_advance_total = 0.0,
              grand_discount_total = 0.0;

            if (orderDetails.length > 0) {
              let temp_extra_charges = [];
              orderDetails.forEach((orderRes: { extra_charges: any }) => {
                var extra_charges = orderRes.extra_charges;
                temp_extra_charges = temp_extra_charges.concat(extra_charges);
              });
              var extraChargesDetails = groupBy(
                temp_extra_charges,
                function (extraCharge: { charge_name: any }) {
                  return extraCharge.charge_name;
                }
              );

              forOwn(
                extraChargesDetails,
                (extraCharge: any, charge_name: any) => {
                  var charge_sum = sumBy(extraCharge, function (chargeDetail: {
                    value: string;
                  }) {
                    return chargeDetail.value
                      ? parseFloat(chargeDetail.value)
                      : 0;
                  });
                  total_extra_charges.push({
                    charge_name: charge_name,
                    total_charge: charge_sum
                  });
                }
              );
              var userGroupedOrders = groupBy(
                orderDetails,
                function (orderRes: { user_id: any }) {
                  return orderRes.user_id;
                }
              );
              var merchantOrderIds = keys(
                groupBy(orderDetails, function (orderRes: { order_id: any }) {
                  return orderRes.order_id;
                })
              );

              var userIds = Object.keys(userGroupedOrders);
              var userGroupedOrdersLock = userIds.length;
              var itemDetails = [];
              orderDetails.forEach(
                (o: { items: any; user: { phone: any } }) => {
                  itemDetails = itemDetails.concat(o.items);
                  if (o.user && o.user.phone) {
                    total_unique_users = union(total_unique_users, [
                      o.user.phone
                    ]);
                  }
                }
              );
              self.reportHelper
                .getItemsCounts(itemDetails)
                .then(resultItemInfo => {
                  self.reportHelper
                    .orderSummaryPaymentTypes(orderDetails, paymentTypes)
                    .then(function (ordSum) {
                      self.reportHelper
                        .orderSummaryPaymentSubTypes(orderDetails, paymentTypes)
                        .then(function (paymentSubTypeOrdSum) {
                          self.reportHelper
                            .getUserExpensesByOrderIdS(orderDetails)
                            .then(function (totalExpenses) {
                              console.log("asdasdsad");

                              grand_diary_total += 0;
                              grand_advance_total += totalExpenses.advanced
                                ? totalExpenses.advanced
                                : 0.0;
                              grand_discount_total += totalExpenses.discount_amount
                                ? totalExpenses.discount_amount
                                : 0.0;

                              orderDetails.forEach(
                                (o: { discount_info: any[] }) => {
                                  if (Array.isArray(o.discount_info)) {
                                    o.discount_info.forEach(
                                      (dis: { claimed_value: any }) => {
                                        grand_discount_total += dis.claimed_value
                                          ? dis.claimed_value
                                          : 0;
                                      }
                                    );
                                  }
                                }
                              );

                              grand_total += ordSum.total;

                              var grand_tax_total = sumBy(
                                resultItemInfo.item_tax_details,
                                function (taxObj: { total_tax: any }) {
                                  return taxObj.total_tax
                                    ? taxObj.total_tax
                                    : 0;
                                }
                              );
                              var grand_extra_charge_total = sumBy(
                                total_extra_charges,
                                function (extraCharge: { total_charge: any }) {
                                  return extraCharge.total_charge
                                    ? extraCharge.total_charge
                                    : 0;
                                }
                              );

                              var order_details = {
                                total_no_consumers:
                                  total_unique_users.length + 1,
                                total_items_sold: resultItemInfo.item_sold,
                                total_items_discount: grand_discount_total,
                                item_tax_details:
                                  resultItemInfo.item_tax_details,
                                grand_shop_wise_tax_total: 0,
                                grand_tax_extra_charge_total:
                                  grand_tax_total + grand_extra_charge_total,
                                extra_charges: total_extra_charges,
                                total_earnings: grand_total,
                                total_profit: resultItemInfo.profit,
                                grand_total: grand_total,
                                clear_diary_amount: [],
                                grand_diary_total: grand_diary_total,
                                grand_advance_total: grand_advance_total,
                                grand_cashback_total: 0,
                                avg_table_cover_value: 0,
                                total_order_count: merchantOrderIds.length
                                  ? merchantOrderIds.length
                                  : 0,
                                item_summary_by_kitchen_department: []
                              };

                              ordSum.payment_summary.forEach(
                                (main_payment_type: { [x: string]: any[] }) => {
                                  for (var y in main_payment_type) {
                                    if (y && payment_type_summary[y]) {
                                      payment_type_summary[y]["amount"] +=
                                        main_payment_type[y][1];
                                    } else {
                                      payment_type_summary[y] = {
                                        code: y,
                                        name: main_payment_type[y][0],
                                        amount: main_payment_type[y][1]
                                      };
                                    }

                                    //add defualt payment sub class
                                    if (paymentSubClassInfoObj[y]) {
                                      paymentSubClassInfoObj[y].forEach(
                                        (paymentSubClass: {
                                          name: string | number;
                                        }) => {
                                          if (
                                            !payment_type_summary[
                                            paymentSubClass.name
                                            ]
                                          ) {
                                            payment_type_summary[
                                              paymentSubClass.name
                                            ] = {
                                              code: y,
                                              name: paymentSubClass.name,
                                              amount: 0
                                            };
                                          }
                                        }
                                      );
                                    }

                                    //add amount for payment sub class
                                    if (paymentSubTypeOrdSum[y]) {
                                      for (var x in paymentSubTypeOrdSum[y]) {
                                        if (payment_type_summary[x]) {
                                          payment_type_summary[x]["amount"] +=
                                            paymentSubTypeOrdSum[y][x];
                                        } else {
                                          payment_type_summary[x] = {
                                            code: y,
                                            name: x,
                                            amount: paymentSubTypeOrdSum[y][x]
                                          };
                                        }
                                      }
                                    }
                                  }
                                }
                              );

                              var tempPaymentTypeSummary = [];
                              for (var x in payment_type_summary) {
                                tempPaymentTypeSummary.push(
                                  payment_type_summary[x]
                                );
                              }
                              order_details[
                                "payment_type_summary"
                              ] = tempPaymentTypeSummary;

                              if (index == 0) {
                                order_details[
                                  "total_rejected_order_count"
                                ] = total_rejected_order_count;
                                order_details[
                                  "total_rejected_order_amount"
                                ] = total_rejected_order_amount;
                                order_details[
                                  "cancel_kot_order_total"
                                ] = cancel_kot_order_total;
                                order_details[
                                  "cancel_kot_order_item_count_total"
                                ] = cancel_kot_order_item_count_total;
                                order_details[
                                  "home_delivery_order_count"
                                ] = home_delivery_order_count;
                                order_details[
                                  "home_delivery_order_total"
                                ] = home_delivery_order_total;
                                order_details[
                                  "pickup_order_count"
                                ] = pickup_order_count;
                                order_details[
                                  "pickup_order_total"
                                ] = pickup_order_total;
                                order_details[
                                  "pickup_order_total"
                                ] = pickup_order_total;
                                order_details["partner_summary"] = [];
                                normal_order_summary = order_details;
                              } else if (index == 1) {
                                employee_orders_summary = order_details;
                              } else if (index == 2) {
                                no_change_orders_summary = order_details;
                              } else if (index == 3) {
                                order_details.grand_total = Math.abs(
                                  order_details.grand_total
                                );
                                order_details.grand_tax_extra_charge_total = Math.abs(
                                  order_details.grand_tax_extra_charge_total
                                );
                                cancel_orders_summary = order_details;
                              }

                              allOrdersLock--;

                              if (allOrdersLock == 0) {
                                sendResponse();
                              }
                            });
                        });
                    });
                });
            } else {
              var order_details = {
                total_no_consumers: 0,
                total_items_sold: 0,
                total_items_discount: 0,
                extra_charges: [],
                item_tax_details: [],
                total_earnings: 0,
                total_profit: 0,
                grand_total: 0,
                clear_diary_amount: {},
                grand_diary_total: 0,
                grand_advance_total: 0,
                total_order_count: 0,
                item_summary_by_kitchen_department: []
              };

              var tempPaymentTypeSummary = [];
              for (var x in payment_type_summary) {
                tempPaymentTypeSummary.push(payment_type_summary[x]);
              }
              order_details["payment_type_summary"] = tempPaymentTypeSummary;

              if (index == 0) {
                order_details[
                  "total_rejected_order_count"
                ] = total_rejected_order_count;
                order_details[
                  "total_rejected_order_amount"
                ] = total_rejected_order_amount;
                order_details[
                  "cancel_kot_order_total"
                ] = cancel_kot_order_total;
                order_details[
                  "cancel_kot_order_item_count_total"
                ] = cancel_kot_order_item_count_total;
                order_details[
                  "home_delivery_order_count"
                ] = home_delivery_order_count;
                order_details[
                  "home_delivery_order_total"
                ] = home_delivery_order_total;
                order_details["pickup_order_count"] = pickup_order_count;
                order_details["pickup_order_total"] = pickup_order_total;
                normal_order_summary = order_details;
              } else if (index == 1) {
                employee_orders_summary = order_details;
              } else if (index == 2) {
                no_change_orders_summary = order_details;
              } else if (index == 3) {
                order_details.grand_total = Math.abs(order_details.grand_total);
                cancel_orders_summary = order_details;
              }

              allOrdersLock--;

              if (allOrdersLock == 0) {
                sendResponse();
              }
            }
          });
        })
        .catch(error => {
          console.log(error);
        });

      function sendResponse() {
        //adding total net sales
        var grand_tax_extra_charge_total = normal_order_summary[
          "grand_tax_extra_charge_total"
        ]
          ? normal_order_summary["grand_tax_extra_charge_total"]
          : 0;
        var grand_cancel_order_tax_extra_charge_total = cancel_orders_summary[
          "grand_tax_extra_charge_total"
        ]
          ? cancel_orders_summary["grand_tax_extra_charge_total"]
          : 0;
        var grand_cancel_order_total = cancel_orders_summary["grand_total"]
          ? cancel_orders_summary["grand_total"]
          : 0;
        normal_order_summary["total_net_sales"] =
          normal_order_summary["grand_total"] -
          grand_cancel_order_total -
          grand_tax_extra_charge_total -
          grand_cancel_order_tax_extra_charge_total;

        //calculate avg per order
        var total_orders =
          normal_order_summary["total_order_count"] -
          cancel_orders_summary["total_order_count"];
        normal_order_summary["avg_net_sales_per_order"] =
          total_orders > 0
            ? normal_order_summary["total_net_sales"] / total_orders
            : 0;

        var response_data = normal_order_summary;
        response_data["employee_orders_summary"] = employee_orders_summary;
        response_data["no_change_orders_summary"] = no_change_orders_summary;
        response_data["cancel_orders_summary"] = cancel_orders_summary;
        response_data["membership_payment_order_count"] = 0;
        response_data["membership_payment_order_total"] = 0;
        return resolve(response_data);
      }
    });
  }

  prepareToPrintSalesSummaryReport() {
    if (this.consumerReportData) {
      var printerObj = this.reportHelper.checkPrinters();
      if (localStorage.getItem('PRINT_UTILITY')) {
        this.printerUtility = JSON.parse(localStorage.getItem('PRINT_UTILITY'));
      }
      if (this.printerUtility) {
        var flag = this.printSalesReportFlags;
        flag.salesDetailsFlag = this.getSalesDetailsFlag();
        flag.TotalCasherSum = this.getTotalCasherSum();
        flag.totalClearDairyAmt = this.getDairyAmount(this.consumerReportData.clear_diary_summary);
        flag.totalAdvanceDairyAmt = this.getDairyAmount(this.consumerReportData.advance_diary_summary);
        flag.itemWiseTax = this.shopConfiguration.item_wise_tax;
        this.reportHelper.printSummaryReportOnUtility(
          this.consumerReportData,
          this.consumerReportDate,
          flag,
          this.currencySymbol
        );
      } else if (printerObj) {
          if (printerObj.wifi_present) {
            var flag = this.printSalesReportFlags;
            flag.salesDetailsFlag = this.getSalesDetailsFlag();
            flag.TotalCasherSum = this.getTotalCasherSum();
            flag.totalClearDairyAmt = this.getDairyAmount(this.consumerReportData.clear_diary_summary);
            flag.totalAdvanceDairyAmt = this.getDairyAmount(this.consumerReportData.advance_diary_summary);
            this.reportHelper.printSalesSummaryReportonWifi(
              this.consumerReportData,
              this.consumerReportDate,
              flag
            );
          } else if (printerObj.USB_present) {
            let printContents = document.getElementById("reportDiv").innerHTML;
            this.reportHelper.printUSBReport(printContents);
          } else {
            console.log(
              "Neither USB not Wifi printer available for printing the report"
            );
            alert(
              "Neither USB not Wifi printer available for printing the report"
            );
          }
        } else {
          console.log("Printer data could not be fetched");
          alert("Printer data could not be fetched at this time");
          // this.messageService.add({ severity: 'success', summary: 'Printers not found', detail: 'Printer data could not be fetched' });
        }
    } else {
      this.messageService.add({
        severity: "success",
        summary: "Report data not available at this time",
        detail: "Report data not available at this time"
      });
    }
  }

  public printSalesReportFlags = JSON.parse(
    localStorage.getItem("printSalesReportFlags")
  ) || {
    salesDetails: false,
    grossDetails: false,
    totalTax: false,
    netSale: false,
    discount: false,
    totalItemsSold: false,
    totalQuantitySold: false,
    sectionWisePricing: false,
    categoryWiseSale: false,
    itemWiseSale: false,
    staffSummary: false,
    clearDiaryDetails: false,
    advanceDiaryDetails: false,
    cashierDetails: false,
    showAllPaymentTypes: false,
    openingDrawerCash: false,
    cashinDrawer: false,
    department: false,
    ordersAltered: false,
    itemAtrribute: false
    // others: false,
    // billWiseReports: false
  };

  openSalesPrintReportChecksModal() {
    this.modalRef = this.modalService.show(SalesPrintChecksModalComponent, {
      class: "centred-modal"
    });
    this.modalRef.content.Flag = this.printSalesReportFlags;
    this.subscriptions.push(
      this.modalService.onHide.subscribe(() => {
        //console.log(this.printSalesReportFlags);
        if (this.modalRef.content.decision === "ok") {
          localStorage.setItem(
            "printSalesReportFlags",
            JSON.stringify(this.printSalesReportFlags)
          );
          this.prepareToPrintSalesSummaryReport();
        }
        this.unsubscribe();
      })
    );
  }

  getCurrencySymbol() {
    if (this.shopConfiguration.currency) {
      var currency = this.shopConfiguration.currency;
      if (currency.toUpperCase() == "INR") {
        return "Rs.";
      } else {
        var currency_symbol = this.shopConfiguration.currency_symbol;
        var symbol = currency_symbol.toLowerCase();
        if (symbol.charAt(0) === "u") {
          symbol = symbol.split("u")[1];
          symbol = String.fromCharCode(parseInt(symbol, 16));
        }
        return symbol;
      }
    } else {
      return "";
    }
  }

  getSalesDetailsFlag() {
    return (
      this.printSalesReportFlags.grossDetails ||
      this.printSalesReportFlags.totalTax ||
      this.printSalesReportFlags.netSale ||
      this.printSalesReportFlags.discount ||
      this.printSalesReportFlags.totalItemsSold ||
      this.printSalesReportFlags.totalQuantitySold ||
      this.printSalesReportFlags.checkPrints
    );
  }

  getTotalCasherSum() {
    let total = 0;
    for (let paymentDetail of this.consumerReportData
      .normal_order_payment_summary) {
      total += paymentDetail.amount;
    }
    return total;
  }
}
